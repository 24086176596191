import { Injectable } from '@angular/core';
import { Editor, toWidget } from 'ckeditor5';
import { SignaturePlugin } from '../../plugins/signature/signature-plugin';
import { SignatureUtilsService } from '../../utils/signature/signature-utils.service';

@Injectable({
    providedIn: 'root'
})
export class SignatureModelToEditorViewConverterService {

    private utilsService: SignatureUtilsService;

    constructor() {
        this.utilsService = new SignatureUtilsService();
    }

    public configureConverters(editor: Editor) {
        const conversion = editor.conversion;

        conversion.for("editingDowncast").elementToElement({
            model: SignaturePlugin.pluginModelName,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.utilsService.createSignatureView(editor, modelItem, viewWriter);
                return toWidget(widgetElement, viewWriter);
            },
        });
    }
}
