import { Editor, Element, Item, ViewElement, Writer } from "ckeditor5";
import { BaseLinkedFieldCommand } from "./base-linked-field-command";

export default class NavigateLinkedFieldCommand extends BaseLinkedFieldCommand {

    constructor(editor: Editor) {
        super(editor);
    }

    public override execute(id: string): void {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);

            const inputModel: Item | null = this.getElementWithId(range, id)?.item as Item;

            if (!inputModel) {
                return;
            }

            const viewElement = editor.editing.mapper.toViewElement(inputModel as Element);

            if (!viewElement) {
                return;
            }

            this.scrollToElement(viewElement);
            const selectionRange = writer.createRangeOn(inputModel);
            writer.setSelection(selectionRange);

        });
    }

    public override refresh(): void {
        super.refresh();

        if (!this.isEnabled) {
            this.enableCommandIfCursorInsideInputContent();
        }
    }

    private scrollToElement(viewElement: ViewElement): void {
        const domElement = this.editor.editing.view.domConverter.viewToDom(viewElement);

        if (domElement) {

            domElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    }
}
