<ng-container [ngSwitch]="action">
    <ng-container *ngSwitchCase="NodeTreeUserAction.Menu">
        <ctbox-menu [menuList]="menuList" [menuIcon]="menuIcon" [isDisabled]="isTreeLocked" [hideIfEmpty]="true">
            <ng-container ngProjectAs="menuTitle" [ngTemplateOutlet]="lockedTreeTitle"></ng-container>
        </ctbox-menu>
    </ng-container>

    <ng-container *ngSwitchCase="NodeTreeUserAction.ImageCheck">
        <app-ctbox-tree-action-image [node]="node"></app-ctbox-tree-action-image>
    </ng-container>

    <ng-container *ngSwitchCase="NodeTreeUserAction.Slider">
        <mat-slide-toggle [checked]="node.isChecked" [disabled]="isTreeLocked" (change)="nodeChange($event)"></mat-slide-toggle>
    </ng-container>

    <ng-container *ngSwitchCase="NodeTreeUserAction.ButtonsCheckedAll">
        <ctbox-button buttonType="secondary" buttonColor="permission-assign" buttonText="Asignar todas" i18n-buttonText="@@AsignarTodasLasCarpertas" [isDisabled]="isTreeLocked" (buttonClick)="checkAll()"></ctbox-button>
        <ctbox-button buttonType="secondary" buttonColor="permission-discard" buttonText="Retirar todas" i18n-buttonText="@@RetirarTodasLasCarpertas" [isDisabled]="isTreeLocked" (buttonClick)="uncheckAll()"></ctbox-button>
    </ng-container>
    <ng-container *ngSwitchCase="NodeTreeUserAction.Button">
        <ctbox-button buttonIcon="post_add" buttonColor="info" [isDisabled]="isTreeLocked" (buttonClick)="selectNodeButton()" matTooltip="Crear documento"></ctbox-button>
    </ng-container>
    <ng-container *ngSwitchCase="NodeTreeUserAction.Info">
        ({{nodeInfo}})
    </ng-container>

    <ng-container *ngSwitchDefault></ng-container>
</ng-container>
<ng-template #lockedTreeTitle>
    <ng-container *ngIf="isTreeLocked">
        <ctbox-button buttonIcon="lock" buttonColor="error" [buttonText]="menuTitleLocked"></ctbox-button>
    </ng-container>
</ng-template>