import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class TypeUserService {

    public guestUser = 'GUEST';
    public registeredUser = 'REGISTERED';
    public administrator = 'ADMINISTRATOR';
    public documents = 'DOCUMENTS';
    public clausesAndtemplates = 'CLAUSESANDTEMPLATES';
    public templatesLld = 'TEMPLATESLLD';
    public smartform = 'SMARTFORM';
    public templateInquire = 'TEMPLATEINQUIRE';
    public templateManageAndClauses = 'TEMPLATEMANAGEANDCLAUSES';

    constructor() { }

}

export const TypeUserValues = {
    GUEST_USER : 'GUEST',
    REGISTERED_USER : 'REGISTERED',
    ADMINISTRATOR : 'ADMINISTRATOR',
    DOCUMENTS : 'DOCUMENTS',
    CLAUSES_AND_TEMPLATES : 'CLAUSESANDTEMPLATES',
    TEMPLATES_LLD : 'TEMPLATESLLD',
    SMARTFORM : 'SMARTFORM',
    TEMPLATEINQUIRE: 'TEMPLATEINQUIRE',
    TEMPLATEMANAGEANDCLAUSES: 'TEMPLATEMANAGEANDCLAUSES'
};
