import { Directive } from '@angular/core';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { FolderResultErrorCode } from '../../../../core/shared/enums/folder-result-error-code.enum';
import { FolderPostOperationActions } from 'src/app/core/shared/enums/folder-post-operation-actions.enum';

@Directive()
export abstract class BaseFileFolderDirective {

    constructor(
        protected readonly genericDialogService: GenericDialogService
    ) { }

    protected handleCommonError(error: any): Promise<FolderPostOperationActions> {
        const promiseError = new Promise<FolderPostOperationActions>((resolve) => {

            const genericErrorMessage = $localize`:@@ErrorGenericoArbolPlantillas:Se ha producido un error al realizar la operación.`;
            const messageOutdated = $localize`:@@ArbolDeCarpetasNoSincronizadoMensaje:El árbol de carpetas no está sincronizado. Se refrescará automáticamente.`;

            let actionAfter = FolderPostOperationActions.NO_ACTION;
            let message = genericErrorMessage;

            if (error.status === 404) {
                message = messageOutdated;
                actionAfter = FolderPostOperationActions.REFRESH;
            }

            const { errorCode } = error.error;
            switch (errorCode as FolderResultErrorCode) {
                case FolderResultErrorCode.TREE_LOCKED:
                    actionAfter = FolderPostOperationActions.CANCEL;
                    break;

                case FolderResultErrorCode.TREE_OUTDATED:
                    message = messageOutdated;
                    actionAfter = FolderPostOperationActions.REFRESH;
                    break;

                case FolderResultErrorCode.NAME_ALREADY_USED:
                    message = $localize`:@@NuevaCarpetaErrorMismoNombreAlGuardarMensaje:Ya existe otra carpeta con el mismo nombre. Introduce otro nombre, por favor.`;
                    actionAfter = FolderPostOperationActions.CANCEL;
                    break;

                case FolderResultErrorCode.HAS_NOT_MODULE_PERMISSION:
                    message = $localize`:@@MoverCarpetaACarpetaErrorFaltaPermisos:No tienes permisos para modificar esta carpeta.`;
                    actionAfter = FolderPostOperationActions.CANCEL;
                    break;

                case FolderResultErrorCode.HAS_NOT_PERMISSION_TO_MOVE:
                case FolderResultErrorCode.MOVING_INTO_SELF:
                    message = genericErrorMessage;
                    actionAfter = FolderPostOperationActions.CANCEL;
                    break;
            }

            this.genericDialogService.showMessage(message).afterClosed().subscribe(() => resolve(actionAfter));
        });

        return promiseError;
    }
}
