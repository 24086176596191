import { Component } from '@angular/core';

@Component({
  selector: 'app-loading-spinner',
  templateUrl: './loading-spinner.component.html'
})
export class LoadingSpinnerComponent {

    public template = '<div class="spinner"><div class="preload-icon"><div class="bounce1"></div><div class="bounce2"></div><div class="bounce3"></div></div></div>';

}
