import { OptionalFragmentCheckPlugin } from '../../plugins/optional-fragment-check/optional-fragment-check-plugin';
import { Element, Writer } from 'ckeditor5';
import { OptionalFragmentCheckCommand } from './optional-fragment-check-command';

export default class DeleteOptionalFragmentCheckCommand extends OptionalFragmentCheckCommand {

    public override execute(id: string): void {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);

            const optionalFragmentModel: Element = this.getElementWithId(range, id).item as Element;

            let contentToMove: Element;
            for (const child of optionalFragmentModel.getChildren()) {
                const childElement = child as Element;
                if (childElement.name === OptionalFragmentCheckPlugin.MODEL_ENTITIES.content.model) {
                    contentToMove = childElement;
                    break;
                }
            }

            this.moveContentModelAfterOptionalFragmentComponent(writer, contentToMove, optionalFragmentModel);
            this.removeContentDivAndExtractChildrenToParent(writer, contentToMove);
            writer.remove(optionalFragmentModel);

        });
    }

    private moveContentModelAfterOptionalFragmentComponent(writer: Writer, contentToMove: Element, containerModel: Element): void {
        writer.insert(contentToMove, containerModel, 'after');
    }

    private removeContentDivAndExtractChildrenToParent(writer: Writer, contentToMove: Element): void {
        writer.unwrap(contentToMove);
    }
}
