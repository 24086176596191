<ng-container [ngSwitch]="fileState">
    <ng-container *ngSwitchCase="fileStatusCode.UPLOADED_DRAFT">
        <ng-container *ngTemplateOutlet="updateFileState"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="fileStatusCode.UPLOADED_PENDING">
        <ng-container *ngTemplateOutlet="updateFileState"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="fileStatusCode.UPLOADED_EXPIRED">
        <ng-container *ngTemplateOutlet="updateFileState"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="fileStatusCode.UPLOADED_INFORCE">
        <ng-container *ngTemplateOutlet="updateFileState"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="fileStatusCode.DOWNLOADED_DRAFT">
        <ng-container *ngTemplateOutlet="downloadedFileState"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="fileStatusCode.DOWNLOADED_PENDING">
        <ng-container *ngTemplateOutlet="downloadedFileState"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="fileStatusCode.DOWNLOADED_EXPIRED">
        <ng-container *ngTemplateOutlet="downloadedFileState"></ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="fileStatusCode.DOWNLOADED_INFORCE">
        <ng-container *ngTemplateOutlet="downloadedFileState"></ng-container>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <ctbox-button buttonType="text" customClass="text-default" buttonColor="state" buttonIcon="circle" [buttonText]="getTextButton(fileState)"></ctbox-button>
    </ng-container>
</ng-container>

<ng-template #updateFileState>
    <ctbox-help [helpType]="'actionHelp'">
        <ng-container ngProjectAs="titleHelp">
            <ctbox-button buttonType="text" customClass="text-default" buttonIcon="settings" buttonColor="delete" [buttonText]="getTextButton(fileState)"></ctbox-button>
        </ng-container>
        <ng-container ngProjectAs="bodyHelp">
            <ctbox-help-body [element]="folderItem" [mode]="'light'" [styleClass]="'actualizar'" [titleBody]="textForUpdate" [itemList]="updateItemList(folderItem)"></ctbox-help-body>
        </ng-container>
    </ctbox-help>
</ng-template>

<ng-template #downloadedFileState>
    <ctbox-help [helpType]="'actionHelp'">
        <ng-container ngProjectAs="titleHelp">
            <ctbox-button buttonType="text" customClass="text-default" buttonIcon="download" buttonColor="true" [buttonText]="getTextButton(fileState)"></ctbox-button>
        </ng-container>
        <ng-container ngProjectAs="bodyHelp">
            <ctbox-help-body [element]="folderItem" [mode]="'light'" [styleClass]="'success'" [titleBody]="textForDownload" [itemList]="dowloadedItemList(folderItem)"></ctbox-help-body>
        </ng-container>
    </ctbox-help>
</ng-template>

