import { isValueEmptyValidator } from 'src/app/core/shared/validators/valueEmptyValidator';
import { ValidatorRegexConstant } from './validator-regex-constant';

const NIE_LETTERS = 'TRWAGMYFPDXBNJZSQVHLCKE';
const FIRST_CHAR = 0
const POSITION_CONTROL_LETTER = 8

export function NIEValidator(nie: string): boolean {
    return !isValueEmptyValidator(nie) &&
    ValidatorRegexConstant.CUSTOM_NIE_REGEX.test(nie) &&
    validateNIE(nie);
}

function control(numbers: string, letter: string, lettersControl): boolean {
    const dniNumber = parseInt(numbers);
    const remainder = dniNumber % lettersControl.length;
    const expectedLetter = lettersControl[remainder];

    return letter.toUpperCase() === expectedLetter || !isNaN(parseInt(letter));
}

function validateNIE(nieValue: string): boolean {
    const nieFirstLetter = nieValue.charAt(FIRST_CHAR);
    const nieNumbers = nieValue.slice(FIRST_CHAR + 1, POSITION_CONTROL_LETTER);
    const nieControlLetter = nieValue.charAt(POSITION_CONTROL_LETTER);
    const letterMap: { [key: string]: string } = { 'X': '0', 'Y': '1', 'Z': '2' };
    const firstLetterValue = letterMap[nieFirstLetter];

    return control(firstLetterValue + nieNumbers, nieControlLetter, NIE_LETTERS);
}
