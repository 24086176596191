import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { DocumentClauseDTO } from 'src/app/api/model/documentClauseDTO';
import { DocumentClauseService } from 'src/app/api';
import { IClauseDocumentRevisionService } from './clause-document-revision.service.interface';

@Injectable({
    providedIn: 'root'
})
export class ClauseDocumentRevisionService extends IClauseDocumentRevisionService {

    // First one is documentClauseId
    public stateDocuments = new BehaviorSubject<Map<string, string>>(new Map<string, string>());
    public waitForFinishDocuments = new BehaviorSubject<string[]>([]);

    private applied = 'Applied';
    private keep = 'Keep';
    private pending = 'Pending';
    private indexMap = new Map<string, number>();

    constructor(private documentsClauseService: DocumentClauseService) {
        super();
    }

    public getCurrentStateDocumentsSubscription(): BehaviorSubject<Map<string, string>> {
        return this.stateDocuments;
    }

    public UpdateStateDocument(stateDocument: Map<string, string>) {
        this.stateDocuments.next(stateDocument);
        this.updateIndexMap();
    }

    public getCurrentStateDocuments(): Map<string, string> {
        return this.stateDocuments.getValue();
    }

    public getState(documentClause: DocumentClauseDTO): string {
        return this.stateDocuments.getValue().get(documentClause.id);
    }

    public getNumPendingDocuments(): number {
        const states = Array.from(this.stateDocuments.getValue().values());
        return states.filter(state => state === this.pending).length;
    }

    public getNumWaitForFinishedDocuments(): number {
        return this.waitForFinishDocuments.getValue().length;
    }

    public getIndexDocumentClause(documentClause: DocumentClauseDTO): number {
        if (!documentClause) {
            return -1;
        }

        return this.indexMap.get(documentClause.id);
    }

    public getNextDocumentClauseId(currentIndex: number): string {
        let nextIndex = currentIndex + 1;
        if (this.indexMap.size < 2) {
            nextIndex = 0;
        }

        if (nextIndex < 0) {
            nextIndex = 0;
        }

        if (!this.getIdByIndex(nextIndex)) {
            nextIndex = 0;
        }

        return this.getIdByIndex(nextIndex);
    }

    public getPreviousDocumentClauseId(currentIndex: number): string {
        let nextIndex = currentIndex - 1;

        if (nextIndex < 0) {
            nextIndex = this.indexMap.size - 1;
        }

        if (!this.getIdByIndex(nextIndex)) {
            nextIndex = 0;
        }

        return this.getIdByIndex(nextIndex);
    }

    public isPending(documentClause: DocumentClauseDTO): boolean {
        if (documentClause === undefined) {
            return false;
        }

        return this.getCurrentStateDocuments().has(documentClause.id) &&
            this.getCurrentStateDocuments().get(documentClause.id) === this.pending;
    }

    public isWaitForFinish(documentClause: DocumentClauseDTO): boolean {
        if (documentClause === undefined) {
            return false;
        }

        return this.waitForFinishDocuments.getValue().find(documentId => documentId === documentClause.id) !== undefined;
    }

    public isApplied(documentClause: DocumentClauseDTO): boolean {
        if (documentClause === undefined) {
            return false;
        }

        return this.getCurrentStateDocuments().has(documentClause.id) &&
            this.getCurrentStateDocuments().get(documentClause.id) === this.applied;
    }

    public isKeep(documentClause: DocumentClauseDTO): boolean {
        if (documentClause === undefined) {
            return false;
        }

        return this.getCurrentStateDocuments().has(documentClause.id) &&
            this.getCurrentStateDocuments().get(documentClause.id) === this.keep;
    }

    public setPending(documentClause: DocumentClauseDTO): void {
        const current = this.getCurrentStateDocuments();
        current.set(documentClause.id, this.pending);
        this.UpdateStateDocument(current);
    }

    public setWaitForFinished(documentClause: DocumentClauseDTO): void {
        if (this.isWaitForFinish(documentClause)) {
            return;
        }

        const waitForFinihedDocuments = this.waitForFinishDocuments.getValue();
        waitForFinihedDocuments.push(documentClause.id);
        this.waitForFinishDocuments.next(waitForFinihedDocuments);

    }

    public setUnwaitForFinished(documentClause: DocumentClauseDTO): void {
        if (!this.isWaitForFinish(documentClause)) {
            return;
        }

        const waitForFinishedDocuments = this.waitForFinishDocuments.getValue();
        const indexToRemove = waitForFinishedDocuments.findIndex(docId => docId === documentClause.id);
        waitForFinishedDocuments.splice(indexToRemove, 1);
        this.waitForFinishDocuments.next(waitForFinishedDocuments);
    }

    public setKeep(documentClause: DocumentClauseDTO): void {
        const current = this.getCurrentStateDocuments();
        current.set(documentClause.id, this.keep);
        this.UpdateStateDocument(current);
    }

    public setApplied(documentClause: DocumentClauseDTO): void {
        const current = this.getCurrentStateDocuments();
        current.set(documentClause.id, this.applied);
        this.UpdateStateDocument(current);
    }

    public getFirstDocumentClauseId(currentIndex: number): string {
        return this.getIdByIndex(0);
    }

    public getLastDocumentClauseId(currentIndex: number): string {
        return this.getIdByIndex(this.indexMap.size - 1);
    }

    public loadPendingCBDocumentsForClause(clauseId: string): void {
        this.documentsClauseService.documentClausesSearchClauseClauseIdGet(clauseId).subscribe(values => {
            const stateDocument = new Map<string, string>();
            this.UpdateStateDocument(stateDocument);
            values.forEach(element => {
                this.setPending(element);
            });
        });
    }

    public orderIndex(orderedData: DocumentClauseDTO[]): void {
        const orderedIds = orderedData.map((element) => {
            return element.id;
        });

        this.updateMapByIds(orderedIds);
    }

    private updateMapByIds(ids: string[]) {
        for (let i = 0; i < ids.length; i++) {
            this.indexMap.set(ids[i], i);
        }
    }

    private updateIndexMap() {
        this.indexMap.clear();
        const ids = Array.from(this.stateDocuments.getValue().keys());
        this.updateMapByIds(ids);
    }

    private getIdByIndex(index: number): string {
        const foundValue = [...this.indexMap].find(([key, val]) => val === index);
        if (!foundValue) {
            return undefined;
        }

        return foundValue[0];
    }

}
