import { Inject, Injectable } from '@angular/core';
import { filter, map, mergeMap } from 'rxjs/operators';
import { NavigationEnd, Router, ActivatedRoute, Data, NavigationStart } from '@angular/router';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { ISectionStyleConfigService } from './section-style-config.service.interface';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class SectionStyleConfigService extends ISectionStyleConfigService {

    private valuesFromData$ = new BehaviorSubject<Data>(this.initValuesFromData());

    constructor(private router: Router,
                private route: ActivatedRoute,
                @Inject(DOCUMENT) private document: any) {
                    super();
    }

    private initValuesFromData(): Subscription {
        const data$ = this.getData$();
        return data$
        .subscribe(data => {
            this.valuesFromData$.next(data);
        });
    }

    public getData$(): Observable<Data> {
        return this.router.events.pipe(
            filter(event => event instanceof NavigationEnd || event instanceof NavigationStart),
            map(() => this.route),
            map(route => {
                while (route.firstChild) { route = route.firstChild; }
                return route;
            }),
            filter(route => route.outlet === 'primary'),
            mergeMap(route => route.data)
        );
    }

    public getValuesFromData$(): Observable<Data> {
        return this.valuesFromData$.asObservable();
    }

    public addBodyClass(className: string): void {
        this.document.body.classList.add(className);
    }

    public removeBodyClass(className: string): void {
        this.document.body.classList.remove(className);
    }

    public toogleBodyClassByModule(moduleName: string, className: string): void {
        moduleName === className ? this.addBodyClass(moduleName) : this.removeBodyClass(className);
    }
}
