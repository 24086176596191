import { Injectable } from '@angular/core';
import { PagesWithContextualHelp } from 'src/app/core/shared/enums/pages-with-contextual-help.enum';
import { IContextualHelpService } from './contextual-help.service.interface';

@Injectable({
    providedIn: 'root'
})
export class ContextualHelpService extends IContextualHelpService {

    public getContextualPageToShow(selectorCurrentPageToShowHelp: string): PagesWithContextualHelp {
        switch (selectorCurrentPageToShowHelp) {
            case 'app-my-documents-list': return PagesWithContextualHelp.ContractsListAll;
            case 'app-documents-list-draft': return PagesWithContextualHelp.ContractsListDraft;
            case 'app-documents-list-revision': return PagesWithContextualHelp.ContractsListReview;
            case 'app-documents-completed-list': return PagesWithContextualHelp.ContractsListComplete;
            case 'app-documents-signing-list': return PagesWithContextualHelp.ContractsListSendToSign;
            case 'app-documents-signed-list': return PagesWithContextualHelp.ContractsListSigned;
            case 'app-document-edition-main': return PagesWithContextualHelp.ContractsEdition;
            case 'app-my-templates-list': return PagesWithContextualHelp.TemplatesList;
            case 'app-template-main': return PagesWithContextualHelp.TemplatesEdition;
            case 'app-my-clauses-list': return PagesWithContextualHelp.ClausesList;
            case 'app-create-new-clause': return PagesWithContextualHelp.ClausesEdition;
            case 'app-administration-library': return PagesWithContextualHelp.AdministrationLibrary;
        }
        return null;
    }

}
