
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export abstract class IMediaQueryService {
  abstract getCurrentScreenSize$(): Observable<string>;
}

