import { Injectable } from '@angular/core';
import { IHtmlValidateService as IValidateHtmlService } from './validate-html.service.interface';
import { HtmlService } from '../html.service';

@Injectable({
    providedIn: 'root',
})
export class ValidateHtmlService extends HtmlService implements IValidateHtmlService {

    public validateDocument(documentToValidate: string): boolean {
        const domParser = new DOMParser();
        const document = domParser.parseFromString(documentToValidate, 'text/html');
        const allInputsFilled = this.areAllInputsFilled(document);
        const allSelectsFilled = this.areAllSelectFilled(document);
        const allRadiusButtonFilled = this.areAllRadiusSelected(document);

        return allInputsFilled && allSelectsFilled && allRadiusButtonFilled;
    }

    public hasInvalidElements(documentToValidate: string): boolean {
        const domParser = new DOMParser();
        const document = domParser.parseFromString(documentToValidate, 'text/html');
        return document.querySelector('*[data-is-valid="false"]') !== null;
    }

    public areAllInputsFilled(domNode: Document): boolean {
        const arrayInputs = domNode.querySelectorAll('input[type=text]');
        let result = true;
        arrayInputs.forEach(element => {
            const textInElement = (element as HTMLInputElement).value;

            const isEmpty = textInElement.trim() === '';
            result = result && !isEmpty;
        });

        return result;
    }

    public areAllRadiusSelected(domNode: Document): boolean {
        const allRadioGroupsDivs = domNode.getElementsByClassName('rGroup');
        for (let i = 0; i < allRadioGroupsDivs.length; i++) {
            const radioGroup = allRadioGroupsDivs[i] as HTMLElement;
            if (!radioGroup.querySelector('input[type=radio]')) {
                continue;
            }
            const idGroup = (radioGroup.querySelector('input[type=radio]') as HTMLInputElement).name;

            if (!this.radioHasOptionSelected(radioGroup, idGroup)) {
                return false;
            }
        }

        return true;
    }

    public areAllSelectFilled(domNode: Document): boolean {
        const arraySelects = domNode.querySelectorAll('select');
        let result = true;
        arraySelects.forEach(element => {
            if (element.selectedIndex < 0 && element.selectedIndex >= element.options.length) {
                result = false;
                return;
            }

            const textInElement = element.options[element.selectedIndex]?.text;

            const isEmpty = textInElement === undefined || textInElement.trim() === '';
            result = result && !isEmpty;
        });

        return result;
    }
}
