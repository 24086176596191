import { Component, Input } from '@angular/core';
import { HelpItemsListElement } from './models/help-items-list-element.model';

@Component({
    selector: 'ctbox-help-body',
    templateUrl: './ctbox-help-body.component.html',
    styleUrls: ['./ctbox-help-body.component.scss']
})
export class CtboxHelpBodyComponent {

    @Input() element: any;
    @Input() creationUser: string;
    @Input() lastModificationUser: string;
    @Input() sharedByUser: string;

    @Input() mode = 'dark';
    @Input() styleClass: string;

    @Input() titleBody: string;
    @Input() labelItem: string;
    @Input() textItem: string;
    @Input() itemList: Array<HelpItemsListElement> = [];

    public clickAction(itemElement: HelpItemsListElement) {
        itemElement.action(this.element);
    }
}
