export enum KeyCode {
    BACKSPACE = 8,
    SHIFT = 16,
    CRTL = 17,
    ALT = 18,
    DELETE = 46,
    META = 91 // Windows/Command
}

export enum EspecialKeyCode {
    BACKSPACE = 8,
    TAB = 9,
    ENTER = 13,
    SHIFT = 16,
    CTRL = 17,
    ALT = 18,
    PAUSE_BREAK = 19,
    CAPS_LOCK = 20,
    ESCAPE = 27,
    PAGE_UP = 33,
    PAGE_DOWN = 34,
    END = 35,
    HOME = 36,
    LEFT_ARROW = 37,
    UP_ARROW = 38,
    RIGHT_ARROW = 39,
    DOWN_ARROW = 40,
    INSERT = 45,
    COPY = 67,
    PASTE = 86,
    CUT = 87,
    UNDO = 90,
    LEFT_META = 91, // Windows/Command key
    RIGHT_META = 92, // Windows/Command key
    SELECT = 93,
    F1 = 112,
    F2 = 113,
    F3 = 114,
    F4 = 115,
    F5 = 116,
    F6 = 117,
    F7 = 118,
    F8 = 119,
    F9 = 120,
    F10 = 121,
    F11 = 122,
    F12 = 123,
    NUM_LOCK = 144,
    SCROLL_LOCK = 145,
}
