import { Directive, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { FolderItemInformationDto, MasterData, UserDTO, UserInfoDTO } from 'src/app/api';
import { DateFormatPipe } from 'src/app/shared/pipes/date-format.pipe';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { FileStatusCodes } from 'src/app/core/cmc/enums/file-status.enum';
import { HelpItemsListElement } from 'src/app/shared/components/ctbox-help-body/models/help-items-list-element.model';
import { IFileStateService } from 'src/app/core/shared/services/templates/file-state/file-state.interface';
import { UpdateFileActionCodes } from 'src/app/api/model/UpdateFileActionCodes';
import { AuthorizeService } from 'src/app/core/shared/services/authorize/authorize.service';
import { takeUntil } from 'rxjs/operators';
import { FileStatusApplicationDto } from 'src/app/api/model/fileStatusApplicationDto';

@Directive()
export abstract class FileStateComponentDirective implements OnInit, OnChanges, OnDestroy {

    @Input() folderItem: FolderItemInformationDto;
    @Output() downloadCanceled = new EventEmitter<string>();

    public abstract titleForDocument: string;

    public folderItemState: MasterData;
    public folderItemUpdateState: MasterData;
    public folderItemStatusApplication: FileStatusApplicationDto;

    public lastModificationDate: Date;
    public isDraft: boolean;
    public fileState = FileStatusCodes.ERROR;

    public get fileStatusCode(): typeof FileStatusCodes {
        return FileStatusCodes;
    }

    private onDestroy$ = new EventEmitter<void>();
    private userInfo: UserInfoDTO;

    private get fileUpdateActionsCode(): typeof UpdateFileActionCodes {
        return UpdateFileActionCodes;
    }

    private readonly cancelDownloadMessage = $localize`:@@CancelarActualizacion:Cancelar actualización`;

    constructor(
        protected genericDialogService: GenericDialogService,
        protected fileStateService: IFileStateService,
        protected readonly loginService: AuthorizeService
    ) { }

    public ngOnInit(): void {
        this.loginService.getUserInfo()
            .pipe(takeUntil(this.onDestroy$)).subscribe((userInfo: UserInfoDTO) => {
                this.userInfo = userInfo;
            });

        this.setFileState();
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.template) {
            this.setFileState();
        }
    }

    protected abstract getTextButton(fileState: FileStatusCodes): string;

    public createItemList(element: FolderItemInformationDto): Array<HelpItemsListElement> {
        const actionList = [];
        const templateRequestedForMessage = $localize`:@@EstadoFicheroSolicitadaPor:${this.titleForDocument} solicitada por: `;
        actionList.push(new HelpItemsListElement(templateRequestedForMessage,
            element.creationUserFullName + ' (' + element.creationUserEmail + ')'));
        return actionList;
    }

    public dowloadedItemList(element: FolderItemInformationDto): Array<HelpItemsListElement> {
        const downloadedInformation = element.fileUpdateInformation
            .find(tui => tui.updateFileAction.code === this.fileUpdateActionsCode.DOWNLOADED);
        const datePipe = new DateFormatPipe();
        const date = datePipe.transform(downloadedInformation.date);
        const actionList = [];
        actionList.push(new HelpItemsListElement($localize`:@@EstadoFicheroDescargadaPor:${this.titleForDocument} descargada por:`,
            downloadedInformation.user.email));

        if (this.showCancelDownloadButton(downloadedInformation.user)) {
            actionList.push(new HelpItemsListElement($localize`:@@EstadoFicheroFechaDescarga:Fecha de descarga: `, date, 'success',
                'file_download_off', this.cancelDownloadMessage, this.cancelDownload));
        } else {
            actionList.push(new HelpItemsListElement($localize`:@@EstadoFicheroFechaDescarga:Fecha de descarga: `, date, 'success'));
        }
        return actionList;
    }

    public updateItemList(element: FolderItemInformationDto): Array<HelpItemsListElement> {
        const updateInformation = element.fileUpdateInformation
            .find(tui => tui.updateFileAction.code === this.fileUpdateActionsCode.UPLOADED);
        const datePipe = new DateFormatPipe();
        const date = datePipe.transform(updateInformation.date);
        const actionList = [];
        actionList.push(new HelpItemsListElement($localize`:@@EstadoFicheroDescargadaPor:${this.titleForDocument} descargada por:`,
            updateInformation.user.email));
        actionList.push(new HelpItemsListElement($localize`:@@EstadoFicheroFechaModificacion:Fecha de modificación: `, date, 'success'));

        return actionList;
    }

    public cancelDownload = (element: FolderItemInformationDto) => {
        this.downloadCanceled.emit(element.id);
    }

    public isStateUpdate(): boolean {
        switch (this.fileState) {
            case this.fileStatusCode.UPLOADED_DRAFT:
            case this.fileStatusCode.UPLOADED_PENDING:
            case this.fileStatusCode.UPLOADED_INFORCE:
            case this.fileStatusCode.UPLOADED_EXPIRED:
            case this.fileStatusCode.DOWNLOADED_PENDING:
            case this.fileStatusCode.DOWNLOADED_DRAFT:
            case this.fileStatusCode.DOWNLOADED_INFORCE:
            case this.fileStatusCode.DOWNLOADED_EXPIRED:
                return true;
            default:
                return false;
        }
    }

    private showCancelDownloadButton(downloaderUser: UserDTO): boolean {
        return downloaderUser.id === this.userInfo.guid;
    }

    private setFileState() {
        if (!this.folderItem) {
            return;
        }

        this.folderItemState = this.folderItem.fileStatus;
        this.folderItemStatusApplication = this.folderItem.fileStatusApplication;
        this.folderItemUpdateState = this.fileStateService
            .getUploadActionsCodeByPreference(this.folderItem.fileUpdateInformation)?.updateFileAction;
        this.isDraft = this.folderItem.isDraft;
        this.lastModificationDate =  this.folderItem.modificationTimeStamp;

        this.fileState = this.fileStateService.mapToFileStatusCodes(this.folderItemState, this.folderItemStatusApplication,
            this.folderItemUpdateState);
    }
}
