import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { IJsonPatchDocument } from '../model/json-patch.model';
import { TemplateFolderDto } from '../model/cmc/templateFolderDto';
import { ApiOperation, ApiProcessorService, ApiRequest, ApiResponse } from './api-processor.service';
import { UrlTree } from '@angular/router';
import { RequestQueryParamsService } from './requestQueryParams.service';
import { ExpandRequestModel } from '../model/request-model/expand-request.model';

@Injectable()
export class ManagerTemplatesFoldersService {

    protected basePath = '/api/manager-management/templates-folders';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(
        private readonly apiProcessorService: ApiProcessorService,
        private readonly mapRequestQueryParams: RequestQueryParamsService
    ) { }

    public getAll(loadContent: boolean = true): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;

        let params = '';

        if (loadContent) {
            const mapRequest: ExpandRequestModel = {
                expands: ['content']
            };
            params = '?' + this.mapRequestQueryParams.getQueryParamsByExpandRequest(mapRequest);
        }

        apiRequest.endpointUrl = `${this.basePath}` + params;

        return this.apiProcessorService.proccessRequest<TemplateFolderDto[]>(apiRequest);
    }

    public get(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}/${id}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public deleteTemplateFolder(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = `${this.basePath}/${id}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public createTemplateFolder(templateFolder: TemplateFolderDto): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = templateFolder;
        apiRequest.endpointUrl = this.basePath;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public patchTemplateFolder(id: string, jsonPatchDocument: Array<IJsonPatchDocument>):
        Observable<ApiResponse<TemplateFolderDto> | UrlTree> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PATCH;
        apiRequest.endpointUrl = `${this.basePath}/${id}`;
        apiRequest.body = jsonPatchDocument;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public uploadFile(templateFolderId: string, file: File): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${this.basePath}/upload`;

        const reqBody = new FormData();
        reqBody.append('file', file, file.name);
        apiRequest.body = reqBody;

        return this.apiProcessorService.postActionAsFormData(reqBody, `${this.basePath}/${templateFolderId}/upload-file`);
    }
}
