/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ClauseFolderDto } from './clause-folder-dto.model';
import { ClauseVersionDTO } from './clauseVersionDTO';
import { FileStatusApplicationDto } from './fileStatusApplicationDto';
import { FileUpdateInformationDto } from './fileUpdateInformationDto';
import { MasterData } from './masterData';
import { UserDTO } from './userDTO';
import { UserClausePermissionsDto } from './user-clause-permissions-dto.model';
import { WarningChangesClauseDTO } from './warningChangesClauseDTO';

export interface ClauseDTO {
    id?: string;
    name?: string;
    description?: string;
    creationTimeStamp?: Date;
    modificationTimeStamp?: Date;
    creationUser?: UserDTO;
    lastModificationUser?: UserDTO;
    clauseFolder?: ClauseFolderDto;
    buyer?: UserDTO;
    clauseFolderId?: string;
    iddOrigin?: string;
    userPermissions?: UserClausePermissionsDto;
    warningChanges?: WarningChangesClauseDTO;
    html?: string;
    clauseStatusId?: string;
    clauseStatus?: MasterData;
    clauseStatusApplication?: FileStatusApplicationDto;
    clauseVersions?: ClauseVersionDTO[];
    currentVersion?: number;
    fileUpdateInformation?: FileUpdateInformationDto[];
    hasAppliedRegulations?: boolean;
    isEmbedded?: boolean;
}
