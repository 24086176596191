import { Injectable } from '@angular/core';
import { Conversion, DowncastConversionApi, DowncastWriter, Element } from 'ckeditor5';
import { OptionalFragmentCheckPlugin } from '../../plugins/optional-fragment-check/optional-fragment-check-plugin';
import { GlobalConstant } from '../../models/base/global-constant';

@Injectable({
    providedIn: 'root'
})
export class OptionalFragmentCheckModelToDataViewConverterService {

    public configureConverters(conversion: Conversion): void {
        this.containerConversion(conversion);
        this.checkConversion(conversion);
        this.descriptionConversion(conversion);
        this.contentConversion(conversion);
    }

    private containerConversion(conversion: Conversion): void {
        conversion.for("dataDowncast").elementToElement({
            model: OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.model,
            view: (modelItem: Element, downcastConversionApi: DowncastConversionApi) => {
                const writer = downcastConversionApi.writer;
                const id = modelItem.getAttribute(GlobalConstant.ATTRIBUTE_ID);
                const modelElem = writer.createContainerElement(
                    GlobalConstant.LABEL_DIV,
                    {
                        class: [OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.dataView],
                        id
                    });
                return modelElem;
                }
        });

    }

    private checkConversion(conversion: Conversion): void {
        conversion.for("dataDowncast").elementToElement({
            model: {
                name: OptionalFragmentCheckPlugin.MODEL_ENTITIES.check.model,
                attributes: [OptionalFragmentCheckPlugin.MODEL_ENTITIES.checkAttribute.model]
            },
            view: (modelItem, { writer }:DowncastConversionApi) =>
            {
                const id = modelItem.getAttribute(GlobalConstant.ATTRIBUTE_ID);
                const name = id;
                const isChecked = modelItem.getAttribute(OptionalFragmentCheckPlugin.MODEL_ENTITIES.checkAttribute.model);

                const modelElem = writer.createContainerElement('input', {
                    id,
                    name,
                    type: 'checkbox',
                    ...(isChecked && { checked: true })
                });

                return modelElem;
            }
        });
    }

    private descriptionConversion(conversion: Conversion): void {
        conversion.for("dataDowncast").elementToElement({
            model:  OptionalFragmentCheckPlugin.MODEL_ENTITIES.description.model,
            view: (_modelItem, { writer }: DowncastConversionApi) =>
                writer.createContainerElement(GlobalConstant.LABEL_DIV,
                {
                    class:  OptionalFragmentCheckPlugin.MODEL_ENTITIES.description.dataView,
                })
        });
    }

    private contentConversion(conversion: Conversion): void {
        conversion.for("dataDowncast").elementToStructure({
            model:  OptionalFragmentCheckPlugin.MODEL_ENTITIES.content.model,
            view: (_modelItem, { writer }: DowncastConversionApi) => {
                return writer.createSlot();
            }
        });
    }
}
