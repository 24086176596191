import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FileStateComponentDirective } from '../file-state.component';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { IFileStateService } from 'src/app/core/shared/services/templates/file-state/file-state.interface';
import { AuthorizeService } from 'src/app/core/shared/services/authorize/authorize.service';
import { FileStatusCodes } from 'src/app/core/cmc/enums/file-status.enum';
import { DateDayOnlyFormatPipe } from 'src/app/shared/pipes/date-day-only-format.pipe';

@Component({
  selector: 'app-update-file-state',
  templateUrl: './update-file-state.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class UpdateFileStateComponent extends FileStateComponentDirective implements OnInit {

    @Input() titleForDocument: string;

    public textForDownload: string;
    public textForUpdate: string;

    constructor(
        protected genericDialogService: GenericDialogService,
        protected fileStateService: IFileStateService,
        protected readonly loginService: AuthorizeService
    ) {
        super(genericDialogService, fileStateService, loginService);
    }

    public ngOnInit(): void {
        this.textForDownload = $localize`:@@FicheroDescargado:${this.titleForDocument} descargada`;
        this.textForUpdate = $localize`:@@FicheroEnProcesoDeActualizacion:${this.titleForDocument} en proceso de actualización`;
        super.ngOnInit();
    }

    protected getTextButton(fileState: FileStatusCodes) {
        switch (fileState) {
            case FileStatusCodes.CREATION:
                return '';
            case FileStatusCodes.DOWNLOADED_DRAFT:
            case FileStatusCodes.DOWNLOADED_PENDING:
            case FileStatusCodes.DOWNLOADED_INFORCE:
            case FileStatusCodes.DOWNLOADED_EXPIRED:
            case FileStatusCodes.UPLOADED_DRAFT:
            case FileStatusCodes.UPLOADED_PENDING:
            case FileStatusCodes.UPLOADED_INFORCE:
            case FileStatusCodes.UPLOADED_EXPIRED:
                return $localize`:@@EstadoArchivoActualización:En actualización`;
            default:
                return this.getLastUpdateDate();
        }
    }

    private getLastUpdateDate(): string{
        if (!!this.lastModificationDate){
            return new DateDayOnlyFormatPipe().transform(this.lastModificationDate);
        }

        return $localize`:@@EstadoArchivoError:Error`;
    }
}
