import { Injectable, EventEmitter } from '@angular/core';
import { PermissionsUserByUserDTO } from 'src/app/api';

@Injectable({
    providedIn: 'root'
})
export class AdministrationPermissionsDataService {

    public permissionsSave$ = new EventEmitter<PermissionsUserByUserDTO[]>();
    public permissionsCancel$ = new EventEmitter<boolean>();
    public permissionsChange$ = new EventEmitter<void>();
    public treeLockChange$ = new EventEmitter<{locked: boolean, lockedByCurrentUser: boolean}>();
    public treeLockExpired$ = new EventEmitter<boolean>(false);
    public refreshNecessary$ = new EventEmitter<void>();
    public cancelWithLoad$ = new EventEmitter<boolean>();
    public doAfterSucessfulSave$ = new EventEmitter<void>();

    constructor() { }
}
