import { Command, Range, Writer } from "ckeditor5";

export default class ClauseDeleteCommand extends Command {

    public override execute(): void {
        const { editor } = this;
        const { selection } = editor.model.document;

        editor.model.change((writer: Writer) => {
            const firstPosition = selection.getFirstPosition();
            if (firstPosition) {
                const range = new Range(firstPosition, firstPosition.getShiftedBy(1));
                writer.remove(range);
            }
        });
    }
}
