<div class="stepper {{orientation}}">
    <div class="stepper-header">
        <mat-stepper *ngIf="steps" #stepper [class]="stepperClass" [selectedIndex]="currentPosition" [orientation]="orientation">
            <mat-step *ngFor="let step of steps; let i = index" [label]="step.label" [completed]="currentPosition === i"></mat-step>
        </mat-stepper>
    </div>
    <div class="stepper-body">
        <div class="stepper-step">
            <div class="stepper-step-content">
                <ng-component #componentContainer></ng-component>
            </div>
            <div class="stepper-step-buttons" *ngFor="let step of steps; let i = index" [ngClass]="{'hidden' : (currentPosition !== i)}">
                <ctbox-button *ngIf="step.showBackButton" buttonType="secondary" buttonText="Volver" i18n-buttonText="@@Volver" (buttonClick)="back()"></ctbox-button>
                <ctbox-button *ngIf="step.showNextButton" buttonType="primary" [buttonText]="step.textNextButton" (buttonClick)="next()" [isDisabled]="isDisabledNextButton()"></ctbox-button>
                <ctbox-button *ngIf="step.showCancelButton" buttonType="secondary" buttonText="Cancelar" i18n-buttonText="@@Cancelar" (buttonClick)="cancelProcess()"></ctbox-button>
                <ctbox-button *ngIf="step.showCloseButton" buttonType="secondary" buttonText="Cerrar" i18n-buttonText="@@Cerrar" (buttonClick)="close()"></ctbox-button>
            </div>
        </div>

    </div>
</div>
