<ng-container *ngIf="!helpType">
    <div class="help-title" cdkOverlayOrigin #trigger="cdkOverlayOrigin" (mouseout)="closeHelp()" (mouseleave)="closeHelp()" (mouseenter)="openHelp()">
        <ng-container *ngTemplateOutlet="titleHelp"></ng-container>
    </div>
    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen" [cdkConnectedOverlayScrollStrategy]="scrollStrategy">
        <ng-container *ngTemplateOutlet="bodyHelp"></ng-container>
    </ng-template>
</ng-container>
<ng-container *ngIf="helpType">
    <div class="help-title" #menuHelp="matMenuTrigger" [matMenuTriggerFor]="menu" (mouseover)="openMenu(menuHelp)">
        <ng-container *ngTemplateOutlet="titleHelp"></ng-container>
    </div>
    <mat-menu class="help-body mat-elevation-z0" #menu="matMenu" [overlapTrigger]="false">
        <ng-container *ngTemplateOutlet="bodyHelp"></ng-container>
    </mat-menu>
</ng-container>
<ng-template #titleHelp>
    <ng-content select="titleHelp"></ng-content>
</ng-template>
<ng-template #bodyHelp>
    <ng-content select="bodyHelp"></ng-content>
</ng-template>
