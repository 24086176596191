/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ErrorTrazabilityOperationDTO } from '../model/errorTrazabilityOperationDTO';
import { TraceEntityAllDTO } from '../model/traceEntityAllDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TrazabilityService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trazabilityDocumentGet(documentId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TraceEntityAllDTO>>;
    public trazabilityDocumentGet(documentId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TraceEntityAllDTO>>>;
    public trazabilityDocumentGet(documentId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TraceEntityAllDTO>>>;
    public trazabilityDocumentGet(documentId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentId !== undefined && documentId !== null) {
            queryParameters = queryParameters.set('documentId', <any>documentId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TraceEntityAllDTO>>('get',`${this.basePath}/trazability/document`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trazabilityErrorDescriptionGet(observe?: 'body', reportProgress?: boolean): Observable<ErrorTrazabilityOperationDTO>;
    public trazabilityErrorDescriptionGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ErrorTrazabilityOperationDTO>>;
    public trazabilityErrorDescriptionGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ErrorTrazabilityOperationDTO>>;
    public trazabilityErrorDescriptionGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ErrorTrazabilityOperationDTO>('get',`${this.basePath}/trazability/error-description`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trazabilityGet(observe?: 'body', reportProgress?: boolean): Observable<Array<TraceEntityAllDTO>>;
    public trazabilityGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TraceEntityAllDTO>>>;
    public trazabilityGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TraceEntityAllDTO>>>;
    public trazabilityGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TraceEntityAllDTO>>('get',`${this.basePath}/trazability`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param documentId 
     * @param userId 
     * @param traceId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public trazabilityTraceGet(documentId?: string, userId?: string, traceId?: string, observe?: 'body', reportProgress?: boolean): Observable<TraceEntityAllDTO>;
    public trazabilityTraceGet(documentId?: string, userId?: string, traceId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TraceEntityAllDTO>>;
    public trazabilityTraceGet(documentId?: string, userId?: string, traceId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TraceEntityAllDTO>>;
    public trazabilityTraceGet(documentId?: string, userId?: string, traceId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {




        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentId !== undefined && documentId !== null) {
            queryParameters = queryParameters.set('documentId', <any>documentId);
        }
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }
        if (traceId !== undefined && traceId !== null) {
            queryParameters = queryParameters.set('traceId', <any>traceId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TraceEntityAllDTO>('get',`${this.basePath}/trazability/trace`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
