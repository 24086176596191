import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { invalidCharactersValidator } from 'src/app/core/shared/validators/formValidators/invalidCharactersValidator';
import { aISelectionModel, AiSelectionType } from './aISelectionModel';

@Component({
    selector: 'app-ctbox-ai-selection.component',
    templateUrl: './ctbox-ai-selection.component.html',
    styleUrls: ['./ctbox-ai-selection.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CtboxAiSelectionComponent implements OnInit {

    @Input() public parentGroup: UntypedFormGroup;
    @Input() public controlName: string;

    selectedDocumentType: 'option1';
    documentTypes = [
        { value: 'Acuerdos de confidencialidad (NDA)', viewValue: 'Acuerdos de confidencialidad (NDA)' },
        { value: 'Carta de intención (LOI) o cartas de crédito', viewValue: 'Carta de intención (LOI) o cartas de crédito' },
        { value: 'Contratos y acuerdos', viewValue: 'Contratos y acuerdos' },
        { value: 'Declaración jurada', viewValue: 'Declaración jurada' },
        { value: 'Demandas judiciales', viewValue: 'Demandas judiciales' },
        { value: 'Documentos legales corporativos', viewValue: 'Documentos legales corporativos' },
        { value: 'Escrituras públicas', viewValue: 'Escrituras públicas' },
        { value: 'Estatutos', viewValue: 'Estatutos' },
        { value: 'Memorandos de entendimiento legal', viewValue: 'Memorandos de entendimiento legal' },
        { value: 'Poderes notariales', viewValue: 'Poderes notariales' },
        { value: 'Pólizas de seguro', viewValue: 'Pólizas de seguro' },
        { value: 'Reclamaciones', viewValue: 'Reclamaciones' }
      ];

    public aISelection: aISelectionModel | null;

    public form: UntypedFormGroup;

    constructor() {    }

    public ngOnInit(): void {
        this.initializeFormGroup();
    }

    private initializeFormGroup(): void {
        this.form = new UntypedFormGroup(
            {
                description: new UntypedFormControl('', [Validators.maxLength(500), invalidCharactersValidator]),
                documentType: new UntypedFormControl('', [Validators.maxLength(500), invalidCharactersValidator]),
                documentPurpose: new UntypedFormControl('', [Validators.maxLength(500), invalidCharactersValidator]),
                documentParts: new UntypedFormControl('', [Validators.maxLength(500), invalidCharactersValidator]),
                documentDuration: new UntypedFormControl('', [Validators.maxLength(500), invalidCharactersValidator]),
                documentObligations: new UntypedFormControl('', [Validators.maxLength(500), invalidCharactersValidator]),
                documentOther: new UntypedFormControl('', [Validators.maxLength(500), invalidCharactersValidator])
            }
        );
    }

    public getDescription(): void {
        this.aISelection.description = this.form.controls.description.value;
    }

    public setDocumentType(): void {
        this.aISelection.documentType = this.selectedDocumentType;
    }

    public setDocumentPurpose(): void {
        this.aISelection.documentPurpose = this.form.controls.documentPurpose.value;
    }

    public setDocumentParts(): void {
        this.aISelection.documentParts = this.form.controls.documentParts.value;
    }

    public setDocumentDuration(): void {
        this.aISelection.documentDuration = this.form.controls.documentDuration.value;
    }

    public setDocumentObligations(): void {
        this.aISelection.documentObligations = this.form.controls.documentObligations.value;
    }

    public setDocumentOther(): void {
        this.aISelection.documentOther = this.form.controls.documentOther.value;
    }



    public setAiSelectionType(type: string): void {
        if (!this.aISelection) {
            this.aISelection = {} as aISelectionModel;
        }

        switch ( type ) {
            case  'CREATE':
                this.aISelection.aiSelectionType = AiSelectionType.CREATE;
                break;
            case 'TRANSLATE':
                this.aISelection.aiSelectionType = AiSelectionType.TRANSLATE;
                break;
            case 'SUMMARIZE':
                this.aISelection.aiSelectionType = AiSelectionType.SUMMARIZE;
                break;
            case 'FIX':
                    this.aISelection.aiSelectionType = AiSelectionType.FIX;
                    break;
            case 'EXTRACTLINKS':
                    this.aISelection.aiSelectionType = AiSelectionType.EXTRACTLINKS;
                    break;
            default:
                this.aISelection = null;
                break;
         }
    }

    public GetAiType(): string {
        return String(this.aISelection.aiSelectionType);
    }
}
