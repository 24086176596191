import { FileDownloadDto } from 'src/app/api/model/fileDownloadDto';
import { Injectable } from '@angular/core';
import { IFileDownloadService } from './file-download.service.interface';

@Injectable({
    providedIn: 'root'
})
export class FileDownloadService extends IFileDownloadService {

    public downloadFileFromFileDownload(fileDownload: FileDownloadDto) {
        this.downloadFile( fileDownload.content, fileDownload.contentType,
                           fileDownload.name, fileDownload.extension);
    }

    public downloadFile(content: string, contentType: string, fileName: string, fileExtension: string): void {

        const binaryString = window.atob(content);
        const bytes = new Uint8Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            bytes[i] = binaryString.charCodeAt(i);
        }

        const byteArray = new Uint8Array(bytes);
        const blob = new Blob([byteArray], { type: contentType });
        this.saveFromBrowser(blob, fileName, fileExtension);
    }

    public saveFromBrowser(blob: Blob, fileName: string, fileExtension: string): void {
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${fileName}.${fileExtension}`;
        link.setAttribute('download', `${fileName}.${fileExtension}`);
        link.click();
        window.URL.revokeObjectURL(url);
    }
}
