import { Inject, Injectable, Optional } from "@angular/core";
import { Configuration } from '../configuration';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { BASE_PATH } from "../variables";
import { Observable } from "rxjs";
import { ExpandRequestModel } from "../model/request-model/expand-request.model";
import { RequestQueryParamsService } from "./requestQueryParams.service";
import { SearchTemplateFoldersResultDto } from "../model/cmc/searchTemplateFoldersResultDto";

@Injectable()
export class TemplateFolderSearchService {
    basePath: string = '/';
    configuration: Configuration;
    defaultHeaders = new HttpHeaders();

    constructor(protected httpClient: HttpClient,
        private readonly mapRequestQueryParams: RequestQueryParamsService,
        @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    search(searchTerm: string, loadContent: boolean) : Observable<SearchTemplateFoldersResultDto> {
        let headers = this.defaultHeaders;
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (!!httpHeaderAcceptSelected) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        let params = '';
        if (!!httpHeaderAcceptSelected) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);

            if (loadContent) {
                const mapRequest: ExpandRequestModel = {
                    expands: ['content']
                };
                params = '&' + this.mapRequestQueryParams.getQueryParamsByExpandRequest(mapRequest);
            }
        }

        searchTerm = encodeURIComponent(searchTerm);

        return this.httpClient.request<SearchTemplateFoldersResultDto>('get',`${this.basePath}/api/inquire-management/templates-folders/search?searchTerm=${searchTerm}` + params,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers
            }
        );
    }

    searchManagement(searchTerm: string, loadContent: boolean) : Observable<SearchTemplateFoldersResultDto> {
        let headers = this.defaultHeaders;
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        let params = '';
        if (!!httpHeaderAcceptSelected) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);

            if (loadContent) {
                const mapRequest: ExpandRequestModel = {
                    expands: ['content']
                };
                params = '&' + this.mapRequestQueryParams.getQueryParamsByExpandRequest(mapRequest);
            }
        }

        searchTerm = encodeURIComponent(searchTerm);

        return this.httpClient.request<SearchTemplateFoldersResultDto>('get',`${this.basePath}/api/manager-management/templates-folders/search?searchTerm=${searchTerm}`+ params,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers
            }
        );
    }

}
