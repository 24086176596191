import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface ComponentUnlockDeactivate {
    unlock: () => boolean | Observable<boolean>;
}

@Injectable()
    export class UserFileLockGuard  {

    public canDeactivate(component: ComponentUnlockDeactivate): boolean | Observable<boolean> {
        if (component == null) {
            return true;
        }

        return component.unlock();
    }
  }
