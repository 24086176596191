<div class="viewer">
    <div class="viewer-header ">
        <div class="viewer-title">
            <div class="viewer-title-label" i18n="@@DocumentoClausulaClausulaEditadaTitulo">Título de la cláusula modificada:</div>
            <div class="viewer-title-name">{{ clauseName }}</div>
        </div>
        <div class="viewer-pagination">
            <ctbox-button buttonColor="secondary" buttonIcon="keyboard_double_arrow_left" (buttonClick)="navegateFirstDocument()"></ctbox-button>
            <ctbox-button buttonColor="secondary" buttonIcon="keyboard_arrow_left" (buttonClick)="navegatePreviousDocument()"></ctbox-button>
            <span class="viewer-pagination-number">{{indexDocument + 1}}/{{totalDocuments}}</span>
            <ctbox-button buttonColor="secondary" buttonIcon="keyboard_arrow_right" (buttonClick)="navegateNextDocument()"></ctbox-button>
            <ctbox-button buttonColor="secondary" buttonIcon="keyboard_double_arrow_right" (buttonClick)="navegateLastDocument()"></ctbox-button>
        </div>
    </div>
    <div class="viewer-body">
        <div class="viewer-html wk-document">
            <app-html-viewer #clauseViewer [html]="clauseOriginal" className="original-clause" (htmlContentChange)="clauseChanged($event)" [addScrollSm]="true" [replaceFilledInput]="false"></app-html-viewer>
        </div>
        <div class="viewer-header">
            <div class="viewer-title">
                <div class="viewer-title-label" i18n="@@DocumentoClausulaActual">Documento actual:</div>
                <div class="viewer-title-name">{{ documentClause.document.name }}</div>
            </div>
            <ctbox-button buttonType="secondary" buttonText="Localiza la cláusula original" i18n-buttonText="@@DocumentoClausulaLocalizaClausula" (buttonClick)="goToClauseInDocument()"></ctbox-button>
        </div>
        <div class="viewer-html overflow background wk-document">
            <app-html-viewer #documentViewer [html]="documentClause.document.html" [markClauseWithId]="documentClause.clause.id" [readOnly]="true" [addScrollMd]="isPending(documentClause)" [addScrollLg]="!isPending(documentClause)"></app-html-viewer>
        </div>
    </div>
    <div class="viewer-footer">
        <div class="viewer-actions">
            <ng-container *ngIf="isPending(documentClause)">
                <ctbox-button buttonType="primary" buttonText="Aplicar cambios" i18n-buttonText="@@AplicarCambiosClausulaDocumento" [buttonColor]="buttonColorAcceptChanges" (buttonClick)="acceptChanges(documentClause)" [isDisabled]="isAcceptChangesDisabled(documentClause)"></ctbox-button>
                <ctbox-button buttonType="secondary" buttonText="Mantener original" i18n-buttonText="@@MantenerOriginalClausulaDocumento" (buttonClick)="rejectChanges(documentClause)" [isDisabled]="isRejectChangesDisabled(documentClause)"></ctbox-button>
            </ng-container>
            <ng-container *ngIf="isWaitingForFinish(documentClause)">
                <ctbox-button buttonType="primary" buttonText="Finalizar" i18n-buttonText="@@Finalizar" (buttonClick)="finish()"></ctbox-button>
            </ng-container>
        </div>
    </div>
</div>
