import { Injectable } from '@angular/core';

import { TemplateDocumentDTO } from 'src/app/api';

@Injectable({
    providedIn: 'root'
})
export abstract class ITemplatePreviewService {
    abstract orderBy(data: TemplateDocumentDTO, sortHeaderId: string): any;
    abstract orderListBy(data: TemplateDocumentDTO[], sortHeaderId: string): TemplateDocumentDTO[];
}
