import { Injectable } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { Subject } from 'rxjs';

@Injectable()
export class PaginatorTraductionService implements MatPaginatorIntl {

    public changes = new Subject<void>();

    // For internationalization, the `$localize` function from
    // the `@angular/localize` package can be used.
    public firstPageLabel = $localize`:@@PaginadorPrimeraPagina:Primera página`;
    public itemsPerPageLabel = '';
    public lastPageLabel = $localize`:@@PaginadorUltimaPagina:Última página`;

    // You can set labels to an arbitrary string too, or dynamically compute
    // it through other third-party internationalization libraries.
    public nextPageLabel = $localize`:@@PaginadorPaginaSiguiente:Página siguiente`;
    public previousPageLabel = $localize`:@@PaginadorPaginaAnterior:Página anterior`;

    public getRangeLabel(page: number, pageSize: number, length: number): string {
        if (length === 0) {
            return '';
        }
        const firstIndex = page * pageSize;
        const lastIndex = (firstIndex + pageSize) < length ? firstIndex + pageSize : length;
        return $localize`:@@PaginadorNumeroPaginas:Del ${firstIndex + 1} al ${lastIndex} de ${length}`;
    }
}
