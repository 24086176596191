import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorHandler, NgModule } from '@angular/core';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { CookieService } from 'ngx-cookie-service';
import { ITreeStandardService } from 'src/app/shared/components/ctbox-tree/service/tree-standard-service.interface';
import { TreeStandardService } from 'src/app/shared/components/ctbox-tree/service/tree-standard.service';
import { GlobalErrorHandler } from './errors/global-error-handler';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { AuthorizeService } from './services/authorize/authorize.service';
import { ClauseDocumentRevisionService } from './services/clauses/clause-document-revision/clause-document-revision.service';
import { IClauseDocumentRevisionService } from './services/clauses/clause-document-revision/clause-document-revision.service.interface';
import { ClausesNavigationService } from '../standard/services/clauses/clause-navigation/clauses-navigation.service';
import { IClausesNavigationService } from '../standard/services/clauses/clause-navigation/clauses-navigation.service.interface';
import { ClausesDocumentRevisionListService } from './services/clauses/clauses-document-revision-list/clauses-document-revision-list.service';
import { IClausesDocumentRevisionListService } from './services/clauses/clauses-document-revision-list/clauses-document-revision-list.service.interface';
import { ClausesFolderTreeService } from './services/clauses/clauses-folder-tree/clauses-folder-tree.service';
import { ClausesFolderService } from './services/clauses/clauses-folder/clauses-folder.service';
import { IClausesFolderService } from './services/clauses/clauses-folder/clauses-folder.service.interface';
import { ClausesPreviewService } from './services/clauses/clauses-preview/clauses-preview.service';
import { IClausesPreviewService } from './services/clauses/clauses-preview/clauses-preview.service.interface';
import { ClausesWarningService } from './services/clauses/clauses-warning/clauses-warning.service';
import { IClausesWarningService } from './services/clauses/clauses-warning/clauses-warning.service.interface';
import { ContextualHelpService } from './services/contextual-help/contextual-help.service';
import { IContextualHelpService } from './services/contextual-help/contextual-help.service.interface';
import { CustomIconService } from './services/custom-icon-service/custom-icon.service';
import { HtmlComparerService } from './services/html-comparer/html-comparer.service';
import { IHtmlComparerService } from './services/html-comparer/html-comparer.service.interface';
import { MediaQueryService } from './services/media-query/media-query.service';
import { IMediaQueryService } from './services/media-query/media-query.service.interface';
import { PaginatorTraductionService } from './services/paginator-traduction/paginator-traduction.service';
import { PublicLinksService } from './services/public-links/public-links.service';
import { RouteStateService } from './services/route-state/route-state.service';
import { SectionStyleConfigService } from './services/section-style-config/section-style-config.service';
import { ISectionStyleConfigService } from './services/section-style-config/section-style-config.service.interface';
import { SendDataService } from './services/send-data/send-data.service';
import { SmartFormConvertService } from './services/smartform/smartform-convert/smartform-convert.service';
import { ISmartFormConvertService } from './services/smartform/smartform-convert/smartform-convert.service.interface';
import { SmartformParamsService } from './services/smartform/smartform-params/smartform-params.service';
import { ISmartformParamsService } from './services/smartform/smartform-params/smartform-params.service.interface';
import { TableOfContentsForComponentsService } from './services/table-of-content-for-components/table-of-contents-for-components.service';
import { ITableOfContentsForComponentsService } from './services/table-of-content-for-components/table-of-contents-for-components.service.interface';
import { TemplateDocumentRevisionService } from './services/templates/template-document-revision/template-document-revision.service';
import { ITemplateDocumentRevisionService } from './services/templates/template-document-revision/template-document-revision.service.interface';
import { TemplatePreviewService } from './services/templates/template-preview/template-preview.service';
import { ITemplatePreviewService } from './services/templates/template-preview/template-preview.service.interface';
import { TemplatesConvertService } from './services/templates/templates-convert/templates-convert.service';
import { ITemplatesConvertService } from './services/templates/templates-convert/templates-convert.service.interface';
import { TemplatesDocumentRevisionListService } from './services/templates/templates-document-revision-list/templates-document-revision-list.service';
import { ITemplatesDocumentRevisionListService } from './services/templates/templates-document-revision-list/templates-document-revision-list.service.interface';
import { TemplatesFolderService } from './services/templates/templates-folder/templates-folder.service';
import { ITemplatesFolderService } from './services/templates/templates-folder/templates-folder.service.interface';
import { TemplateListService } from './services/templates/templates-list/template-list.service';
import { ITemplateListService } from './services/templates/templates-list/template-list.service.interface';
import { TypeUserService } from './services/type-user/type-user.service';
import { VideoManagementService } from './services/video-management/video-management.service';
import { IVideoManagementService } from './services/video-management/video-management.service.interface';
import { WarningNotificacionService } from './services/warning-notification/warning-notification.service';
import { IWarningNotificacionService } from './services/warning-notification/warning-notification.service.interface';
import { PendingChangesWithUnlockTreeGuard } from './guards/pending-changes-with-unlock-tree.guard';
import { PendingChangesGuard } from './guards/pending-changes.guard';
import { IAdministrationPermissionDocumentsFoldersService } from './services/administration/administration-permission-documents-folder/administration-permission-documents-folders.service.interface';
import { AdministrationPermissionDocumentsFoldersService } from './services/administration/administration-permission-documents-folder/administration-permission-documents-folders.service';
import { AdministrationPermissionClausesFoldersService } from './services/administration/administration-permission-clauses-folders/administration-permission-clauses-folders.service';
import { IAdministrationPermissionClausesFoldersService } from './services/administration/administration-permission-clauses-folders/administration-permission-clauses-folders.service.interface';
import { AdministrationPermissionTemplatesFoldersService } from './services/administration/administration-permission-templates-folders/administration-permission-templates-folders.service';
import { IAdministrationPermissionTemplatesFoldersService } from './services/administration/administration-permission-templates-folders/administration-permission-templates-folders.service.interface';
import { TemplatesFolderTreeService } from './services/templates/templates-folder-tree/template-folder-tree/templates-folder-tree.service';
import { AdministrationPermissionsDataService } from './services/administration/administration-permissions-data/administration-permissions-data.service';
import { TemplateFolderWithContentActionsService } from './services/templates/templates-folder-tree/template-folder-with-content/templates-folder-with-content.actions.service';
import { ClauseFolderWithContentActionsService } from './services/clauses/clauses-folder-tree/clauses-folder-with-content/clauses-folder-with-content.actions.service';
import { UserFileLockGuard } from './guards/user-file-lock.guard';
import { TemplatesChangesService } from './services/templates/templates-changes.service';
import { IFileStateService } from './services/templates/file-state/file-state.interface';
import { FileStateService } from './services/templates/file-state/file-state.service';
import { ClausesChangesService } from './services/clauses/clauses-changes.service';
import { FileStepperDataService } from './services/file-stepper-data.service';
import { StepperGuard } from './guards/stepper.guard';
import { TransformCleanHtmlService } from './services/html/transform-clean-html/transform-clean-html.service';
import { ITransformCleanHtmlService } from './services/html/transform-clean-html/transform-clean-html.service.interface';
import { SearchResultModelFactory } from './utils/search-result-model.factory';
import { IFileDownloadService } from './services/file-download/file-download.service.interface';
import { FileDownloadService } from './services/file-download/file-download.service';
import { IDateService } from './services/date/date.service.interface';
import { DateService } from './services/date/date.service';
import { SearchResultFileStorageService } from './services/search-result/search-result-file-storage.service';
import { IHtmlEventsService } from './services/html-events/html-events.service.interface';
import { HtmlEventsService } from './services/html-events/html-events.service';
import { IClauseCreationService } from '../standard/services/clauses/clause-creation/clause-creation.service.interface';
import { ClauseCreationService } from '../standard/services/clauses/clause-creation/clause-creation.service';
import { IDocumentsFolderService } from '../standard/services/documents/documents-folder/documents-folder.service.interface';
import { DocumentsFolderService } from '../standard/services/documents/documents-folder/documents-folder.service';
import { DocumentsFolderTreeService } from '../standard/services/documents/documents-folder-tree/documents-folder-tree.service';
import { IDocumentsFolderTreeService } from '../standard/services/documents/documents-folder-tree/documents-folder-tree.service.interface';
import { IDocumentsNavigationService } from '../standard/services/documents/documents-navigation/documents-navigation.service.interface';
import { DocumentsNavigationService } from '../standard/services/documents/documents-navigation/documents-navigation.service';
import { LegalAlertsSearchService } from './services/legal-alert-search/legal-alerts-search.service';
import { GenericDialogService } from './services/generic-dialog/generic-dialog.service';
import { IHtmlValidateService } from './services/html/html-validate/validate-html.service.interface';
import { ValidateHtmlService } from './services/html/html-validate/validate-html.service';

const COMMON_SERVICE_PROVIDERS = [
    // Guards
    PendingChangesWithUnlockTreeGuard,
    PendingChangesGuard,
    UserFileLockGuard,
    StepperGuard,
    // Services
    AdministrationPermissionsDataService,
    AuthorizeService,
    ClausesChangesService,
    CookieService,
    CustomIconService,
    LegalAlertsSearchService,
    PublicLinksService,
    RouteStateService,
    SendDataService,
    TemplatesChangesService,
    TypeUserService,
    FileStepperDataService,
    SearchResultFileStorageService,
    GenericDialogService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: IAdministrationPermissionDocumentsFoldersService, useClass: AdministrationPermissionDocumentsFoldersService },
    { provide: IAdministrationPermissionTemplatesFoldersService, useClass: AdministrationPermissionTemplatesFoldersService },
    { provide: IAdministrationPermissionClausesFoldersService, useClass: AdministrationPermissionClausesFoldersService },
    { provide: IClauseCreationService, useClass: ClauseCreationService },
    { provide: IClauseDocumentRevisionService, useClass: ClauseDocumentRevisionService },
    { provide: IClausesDocumentRevisionListService, useClass: ClausesDocumentRevisionListService },
    { provide: IClausesFolderService, useClass: ClausesFolderService },
    { provide: IClausesNavigationService, useClass: ClausesNavigationService },
    { provide: IClausesPreviewService, useClass: ClausesPreviewService },
    { provide: IClausesWarningService, useClass: ClausesWarningService },
    { provide: IContextualHelpService, useClass: ContextualHelpService },
    { provide: IHtmlComparerService, useClass: HtmlComparerService },
    { provide: IHtmlEventsService, useClass: HtmlEventsService},
    { provide: IHtmlValidateService, useClass: ValidateHtmlService },
    { provide: IMediaQueryService, useClass: MediaQueryService },
    { provide: ISectionStyleConfigService, useClass: SectionStyleConfigService },
    { provide: ISmartFormConvertService, useClass: SmartFormConvertService },
    { provide: ISmartformParamsService, useClass: SmartformParamsService },
    { provide: ITableOfContentsForComponentsService, useClass: TableOfContentsForComponentsService },
    { provide: ITemplateDocumentRevisionService, useClass: TemplateDocumentRevisionService },
    { provide: ITemplateListService, useClass: TemplateListService },
    { provide: ITemplatePreviewService, useClass: TemplatePreviewService },
    { provide: ITemplatesConvertService, useClass: TemplatesConvertService },
    { provide: ITemplatesDocumentRevisionListService, useClass: TemplatesDocumentRevisionListService },
    { provide: ITemplatesFolderService, useClass: TemplatesFolderService },
    { provide: 'ITemplatesFolderTreeService', useClass: TemplatesFolderTreeService },
    { provide: 'ITemplatesFolderHierarchyTreeActionsService', useClass: TemplateFolderWithContentActionsService },
    { provide: 'IClausesFolderTreeService', useClass: ClausesFolderTreeService },
    { provide: 'IClausesFolderHierarchyTreeActionsService', useClass: ClauseFolderWithContentActionsService },
    { provide: 'ISearchResultModelFactory', useClass: SearchResultModelFactory },
    { provide: IFileStateService, useClass: FileStateService },
    { provide: ITreeStandardService, useClass: TreeStandardService },
    { provide: ITransformCleanHtmlService, useClass: TransformCleanHtmlService },
    { provide: IVideoManagementService, useClass: VideoManagementService },
    { provide: IWarningNotificacionService, useClass: WarningNotificacionService },
    { provide: MatPaginatorIntl, useClass: PaginatorTraductionService },
    { provide: IFileDownloadService, useClass: FileDownloadService },
    { provide: IDateService, useClass: DateService },

    // TO DO: esto deberia estar en el modulo de estandar pero si no no funciona
    { provide: IDocumentsFolderService, useClass: DocumentsFolderService },
    { provide: IDocumentsFolderTreeService, useClass: DocumentsFolderTreeService },
    { provide: IDocumentsNavigationService, useClass: DocumentsNavigationService },

];

@NgModule({
    imports: [
        CommonModule
    ],
    providers: [
        ...COMMON_SERVICE_PROVIDERS
    ]
})
export class SharedCoreModule { }
