import { TableOfContentsDTO } from 'src/app/api';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { Observable } from 'rxjs';
import { SelectionModel } from '@angular/cdk/collections';

export abstract class ITableOfContentsForComponentsService {
    abstract createTreeFromTemplateToc(table: TableOfContentsDTO): FileNode[];
    abstract createTreeFromClauseToc(table: TableOfContentsDTO): FileNode[];

    abstract setCurrentExpansionModel(selectionModel: SelectionModel<string>): void;
    abstract getCurrentExpansionModel(): Observable<SelectionModel<string>>;
}
