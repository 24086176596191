<ctbox-sidenav [customClass]="'modal-tree-text'" [hasbuttonFolded]="false" class="modal-tree">
    <ng-container ngProjectAs="sideNav">
        <app-clause-tree-modal class="folder-tree-side-nav tree-modal" [selectedNodeId]="currentNodeId" [showClauses]="true" [foldersNodeActions]="rootActions"
            [clausesNodeActions]="clausesNodeActions" (checkedNodeTree)="checkedNodeAction($event)" (selectedNodeTree)="selectedNodeAction($event)">
        </app-clause-tree-modal>
    </ng-container>
    <ng-container ngProjectAs="sideNavContent">
        <div class="modal-tree-status"></div>
        <div class="modal-tree-html wk-document" [innerHTML]="clause.description | sanitizeHtml"></div>
    </ng-container>
</ctbox-sidenav>
