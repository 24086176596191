import { Injectable } from "@angular/core";
import { ViewContainerElement, ViewElement, ViewText, DowncastWriter } from "ckeditor5";
import { InputPlugin } from "../../plugins/input/input-plugin";
import { GlobalConstant } from "../../models/base/global-constant";
import InputDialogView from "../../ui/input/input-dialog-view.directive";
import { InputUtilsService } from "./input-utils.service";

@Injectable({
    providedIn: "root",
})
export class InputEditionViewUtilsService {

    private InputUtils: InputUtilsService;

    private inputWarningNotValidMaxLength = $localize`:@@NoValidoMas100caracteres:El número máximo de caracteres para este campo es 100`
    private inputWarningNotValid =  $localize`:@@NoValido: no válido`;
    private phoneText =  $localize`:@@EtiquetaTelefonoValor:Teléfono`;

    constructor() {
        this.InputUtils = new InputUtilsService();
    }

    public getInputValue(parentInputElement: ViewElement): string {
        const nameModelElement = parentInputElement.getChild(0)!;
        const child = !!nameModelElement && (nameModelElement as ViewElement).getChild(0) ? (nameModelElement as ViewElement).getChild(0) : null;

        let valueInInput = '';
        if (!!child && child.is('$text')) {
            valueInInput = (child as ViewText).data;
        }

        return valueInInput;
    }

    public applyValidationToInputEditingView(writer: DowncastWriter, contentElement: ViewContainerElement): void {
        const value = this.getInputValue(contentElement);
        const pattern = contentElement?.getAttribute(InputPlugin.MODEL_ENTITIES.pattern.editionView)!;
        const isValid = this.InputUtils.isDataValid(value, pattern);
        if (isValid) {
            const hasClassNotValid = contentElement.hasClass(InputPlugin.ATTRIBUTE_INPUT_IS_NOT_VALID_CLASS_EDITION_VIEW);
            if (hasClassNotValid) {
                writer.removeClass(InputPlugin.ATTRIBUTE_INPUT_IS_NOT_VALID_CLASS_EDITION_VIEW, contentElement);
                writer.addClass(InputPlugin.ATTRIBUTE_INPUT_IS_VALID_CLASS_EDITION_VIEW, contentElement);
            }
        } else {
            const hasClassValid = contentElement.hasClass(InputPlugin.ATTRIBUTE_INPUT_IS_VALID_CLASS_EDITION_VIEW);
            const errorMessage = this.getErrorMessage(pattern);
            writer.setAttribute('data-error-message', errorMessage, contentElement);
            if (hasClassValid) {
                writer.removeClass(InputPlugin.ATTRIBUTE_INPUT_IS_VALID_CLASS_EDITION_VIEW, contentElement);
                writer.addClass(InputPlugin.ATTRIBUTE_INPUT_IS_NOT_VALID_CLASS_EDITION_VIEW, contentElement);
            }
        }
    }

    private getErrorMessage(pattern: string): string {
        switch (pattern) {
            case GlobalConstant.UNDEFINED:
                return this.inputWarningNotValidMaxLength;
            case GlobalConstant.PHONE:
                return this.phoneText + this.inputWarningNotValid;
            default:
                return InputDialogView.PATTERNS[pattern] + this.inputWarningNotValid
        }
    }
}
