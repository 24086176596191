/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DocType } from './docType';
import { SignatureStatus } from './signatureStatus';
import { SignatureUserLocationParametersDTO } from './signatureUserLocationParametersDTO';

export interface SignatureUserDTO { 
    id?: string;
    documentSignatureId: string;
    document?: string;
    documentName?: string;
    documentType?: DocType;
    status?: SignatureStatus;
    fullName?: string;
    email?: string;
    role?: string;
    order?: number;
    isAwaitingForSigning?: boolean;
    signatureUserLocationParameters?: SignatureUserLocationParametersDTO;
    vidSignerUserId?: string;
}