import { NodeTreeNodeType } from '../enums/node-tree-node-type.enum';
import { NodeTreeActionType } from '../enums/node-tree-action-type.enum';

export class FileNode {
    public id: string;
    public children: FileNode[];
    public value: string;
    public type: NodeTreeNodeType;
    public parentId: string;
    public isChecked: boolean;
    public actions: NodeTreeActionType[];
    public valueHighlighted?: string;
    public additionalData?: any;

    public static findNodeById(tree: FileNode[], fileId: string): FileNode | undefined {
        for (const node of tree) {
            if (node.id === fileId) {
                return node;
            }

            if (node.children && node.children.length > 0) {
                const foundInChildren = this.findNodeById(node.children, fileId);
                if (foundInChildren) {
                    return foundInChildren;
                }
            }
        }

        return undefined;
    }

}
