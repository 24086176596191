import { ApiOperation, ApiProcessorService, ApiRequest } from './api-processor.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class ManagerClausesDownloadService {

    private basePath = '/api/manager-management/clauses';

    constructor(private readonly apiProcessorService: ApiProcessorService) {
    }

    public get(clauseId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${this.basePath}/${clauseId}/download`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public cancel(clauseId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${this.basePath}/${clauseId}/cancel-download`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
