export enum NodeTreeActionType {
        Selected = 'Selected',
        Delete = 'Delete',
        Rename = 'Rename',
        RenameStart = 'RenameStart',
        Create = 'Create',
        Move = 'Move',
        MoveTo = 'MoveTo',
        MoveFromOutside = 'MoveFromOutside',
        Checked = 'Checked',
        CheckedAll = 'CheckedAll',
        NodeButton = 'NodeButton',
        SelectedLink = 'SelectedLink',
        CanCreateContent = 'CanCreateContent',
        CanSeeContent = 'CanSeeContent',
        Download = 'Download',
        DownloadSelected = 'DownloadSelected',
        DeleteSelected = 'DeleteSelected',
        DownloadSelectedUpdate = 'DownloadSelectedUpdate'
}
