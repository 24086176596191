<div class="list-info">
    <div class="list-info-header">
        <div class="list-info-title" i18n="@@PlantillaDocumentoDescripcionTitulo">Lista de documentos y plantillas a revisar afectados por la modificación de la plantilla</div>
        <div class="list-info-data">
            <span class="list-info-data-number">{{pendingDocuments}}/{{totalDocuments}} </span>
            <span class="list-info-data-label" i18n="@@DocumentosPendientesListadoPlantillasDocumentos">documentos pendientes</span>
        </div>
    </div>
    <div class="list-info-table">
        <table *ngIf="dataSource && dataSource.data.length !== 0" mat-table [dataSource]="dataSource" matSort matSortActive="actionsColumn" matSortDirection="desc" matSortDisableClear class="table-list">
            <caption i18n="@@DocumenosPendientesTituloPlantillasDocuments">Documentos pendientes</caption>
            <ng-container matColumnDef="icon">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <mat-icon class="state template-document" i18n-matTooltip="@@PlantillaDocumento" matTooltip="Plantilla/Documento"></mat-icon>
                </th>
                <td mat-cell *matCellDef="let element">
                    <ctbox-button buttonIcon="circle" customClass="filled no-border type template" *ngIf="element.document?.documentTypes.code === documentTypeCodes.Template" i18n-matTooltip="@@IconoPlantilla" matTooltip="Plantilla"></ctbox-button>
                    <ctbox-button buttonIcon="circle" customClass="filled no-border type document" *ngIf="element.document?.documentTypes.code !== documentTypeCodes.Template" i18n-matTooltip="@@IconoDocumento" matTooltip="Documento"></ctbox-button>
                </td>
            </ng-container>
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@PlantillaDocumentoNombreTitulo">Nombre</th>
                <td mat-cell *matCellDef="let element">
                    <div class="mat-cell-flex">
                        <a class="link" (click)="goToPreviewChanges(element)">{{element.document.name}}</a>
                    </div>
                </td>
            </ng-container>
            <ng-container matColumnDef="modificationTimeStamp">
                <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@PlantillaDocumentoFechaModificacionTitulo">Modificación</th>
                <td mat-cell *matCellDef="let element">{{element.document.modificationTimeStamp | dateFormat}}</td>
            </ng-container>
            <ng-container matColumnDef="lastModificationUser">
                <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@PlantillaDocumentoAutorTitulo">Autor</th>
                <td mat-cell *matCellDef="let element">
                    <ctbox-help *ngIf="element.document.lastModificationUser !== null">
                        <ng-container ngProjectAs="titleHelp">
                            {{element.document.lastModificationUser.surname + ', ' + element.document.lastModificationUser.name}}
                        </ng-container>
                        <ng-container ngProjectAs="bodyHelp">
                            <ctbox-help-body [lastModificationUser]="element.document.lastModificationUser.surname + ', ' + element.document.lastModificationUser.name"></ctbox-help-body>
                        </ng-container>
                    </ctbox-help>
                </td>
            </ng-container>
            <ng-container matColumnDef="actionsColumn">
                <th mat-header-cell *matHeaderCellDef mat-sort-header i18n="@@PlantillaDocumentoAccionesTitulo">Acciones</th>
                <td mat-cell *matCellDef="let element">
                    <div class="mat-cell-flex">
                        <ng-container *ngIf="!isReviewDisabled(element)">
                            <ctbox-button buttonType="secondary" buttonText="Revisar" i18n-buttonText="@@RevisarCambiosPlantillaDocumento" (buttonClick)="goToPreviewChanges(element)"></ctbox-button>
                        </ng-container>
                        <ng-container *ngIf="isReviewDisabled(element)">
                            <ctbox-help>
                                <ng-container ngProjectAs="titleHelp">
                                    <ctbox-button buttonType="secondary" buttonText="Revisar" i18n-buttonText="@@RevisarCambiosPlantillaDocumento" [isDisabled]="true"></ctbox-button>
                                </ng-container>
                                <ng-container ngProjectAs="bodyHelp">
                                    <ctbox-help-body>
                                        <ctbox-notification [state]="notificationState.Forbid" [additionalClass]="'dark'" [content]="forbidEditMessage"></ctbox-notification>
                                    </ctbox-help-body>
                                </ng-container>
                            </ctbox-help>
                            <ctbox-button buttonIcon="visibility" buttonColor="info" (buttonClick)="goToPreviewChanges(element)" matTooltip="Visualizar" i18n-matTooltip="@@Visualizar"></ctbox-button>
                        </ng-container>
                    </div>
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"> </tr>
        </table>
    </div>
</div>