import { Injectable } from '@angular/core';
import { ClauseDTO, DocumentClauseService, UserClausePermissionsDto, WarningChangesClauseDTO } from 'src/app/api';
import { ClausesDocumentRevisionListComponent } from 'src/app/pages/standard/clauses-library/clauses-document-revision-list/clauses-document-revision-list.component';
import { IClausesWarningService } from './clauses-warning.service.interface';
import { IClauseDocumentRevisionService } from 'src/app/core/shared/services/clauses/clause-document-revision/clause-document-revision.service.interface';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { ClauseLldRevisionPreviewComponent } from 'src/app/pages/standard/clauses-library/clause-lld-revision-preview/clause-lld-revision-preview.component';

@Injectable({
    providedIn: 'root'
})
export class ClausesWarningService extends IClausesWarningService {

    constructor(public genericDialogService: GenericDialogService,
                public clauseDocumentRevisionService: IClauseDocumentRevisionService,
                public documentsService: DocumentClauseService) {
        super();
    }
    public pendingDocuments: number;
    public totalDocuments: number;

    public setPendingDocuments(clause: ClauseDTO): void {
        this.pendingDocuments = this.clauseDocumentRevisionService.getNumPendingDocuments();
        this.totalDocuments = this.clauseDocumentRevisionService.getCurrentStateDocuments().size;
    }

    public showClausesWarnings(userPermissions: UserClausePermissionsDto, warningChanges: WarningChangesClauseDTO): boolean {
        return (warningChanges?.hasTemplateLLDPendingRevision && userPermissions?.canSeeTemplateChanges) ||
            warningChanges?.hasDocumentsPendingRevision;
    }

    public openClauseDocumentListPreview(clauseToEdit: ClauseDTO, pendingDocuments?: number): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.setPendingDocuments(clauseToEdit);

            const config = this.genericDialogService.getBigDialogConfig();
            const data: any = {
                template: ClausesDocumentRevisionListComponent,
                dialogTitle: $localize`:@@ClausulaDocumentoTitulo:Revisa los cambios por modificación de la cláusula origen`,
                dataSourceComponent: clauseToEdit,
                secondaryButtonContentObservableName: 'isSecondaryButtonSubscription',
                secondaryButtonActionAfterCloseObservableName: 'closeIfFinished',
                displayCloseOption: true,
            };

            this.genericDialogService.openTemplateWithConfigAndData(ClausesDocumentRevisionListComponent, config, data)
                .afterClosed().subscribe(() => {
                    resolve(this.closeClauseDocumentListPreview(data.dataSourceComponent));
                });
        });
    }

    public openClauseLldRevisionPreviewComponent(clauseToEdit: ClauseDTO): void {
        const config = this.genericDialogService.getBigDialogConfig();
        const data: any = {
            template: ClauseLldRevisionPreviewComponent,
            dialogTitle: $localize`:@@ClausulaDocumentoTitulo:Revisa los cambios por modificación de la cláusula origen`,
            dialogButton: $localize`:@@ClausulaRevisadaPlantillaDocumento:Cláusula revisada`,
            dialogCloseButon: $localize`:@@GuardarPlantillaDocumento:Guardar`,
            dataSourceComponent: clauseToEdit,
            primaryButtonContentObservableName: 'isPrimaryButtonEnableObservable',
            secondaryButtonContentObservableName: 'isSecondaryButtonEnableObservable',
            primaryButtonActionBeforeCloseObservableName: 'doReviewClauseObservable',
            primaryButtonActionAfterCloseObservableName: 'doCloseObservable',
            secondaryButtonActionBeforeCloseObservableName: 'doSaveChangesObservable',
            displayButtonBarObservableName: 'displayButtonBarObservable',
            dialogContentGetCloseResultPropertyName: 'pendingClauses',
            displayCloseOption: true,
            displayButtonBar: true,
        };

        this.genericDialogService.openTemplateWithConfigAndData(ClauseLldRevisionPreviewComponent, config, data)
            .afterClosed().subscribe((result) => {
                if (result > 0) {
                    const message = 'No has terminado de revisar la cláusula afectada';
                    this.genericDialogService.showMessage(message);
                }
            });
    }

    private closeClauseDocumentListPreview(clauseToEdit: ClauseDTO, message?: any): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.setPendingDocuments(clauseToEdit);

            if (this.pendingDocuments > 0) {
                message = $localize`:@@ClausulaDocumentosPendientesAlCerrarMensaje:Las cláusulas se han actualizado correctamente en los documentos afectados. Sin embargo, tienes aún pendientes de revisar `
                    + ' "' + this.pendingDocuments + '" ' + $localize`:@@ClausulaDocumentosConClausulas: documento/s con cláusula`
                    + ': ' + clauseToEdit.name;
            } else if (this.pendingDocuments < 1) {
                message = $localize`:@@NoPendientesAlCerrarMensaje:Ha revisado todos los documentos pendientes.`;
            }
            this.genericDialogService.showMessage(message).afterClosed().subscribe(() => {
                resolve(this.pendingDocuments < 1);
            });
        });
    }
}
