import { Component, Inject, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-ctbox-ai-link-info.component',
    templateUrl: './ctbox-ai-link-info.component.html',
    styleUrls: ['./ctbox-ai-link-info.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CtboxAiLinkInfoComponent implements OnInit {

    @Input() public links: string;

    constructor(@Inject(MAT_DIALOG_DATA) public data) {
        this.links = data.links;
    }

    public ngOnInit(): void {
        if (this.links) {
            this.links = this.links.replace(/(?:\r\n|\r|\n)/g, '<br>');
        }
    }
}
