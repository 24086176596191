import { Injectable } from '@angular/core';
import { ApiOperation, ApiProcessorService, ApiRequest } from './api-processor.service';
import { HttpHeaders } from '@angular/common/http';
import { Configuration } from '../configuration';
import { Observable } from 'rxjs';

@Injectable()
export class InquireTemplatesService {

    private basePath = '/api/inquire-management/templates';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(private readonly apiProcessorService: ApiProcessorService) { }

    public getAll(startDate: string, endDate: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.basePath }?startDate=${startDate}&endDate=${endDate}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
