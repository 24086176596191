/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MasterData } from './masterData';

export interface DocumentWorkflowDto { 
    id?: string;
    documentId: string;
    fullName?: string;
    email?: string;
    emailBody?: string;
    subject?: string;
    order?: number;
    expirationDays: number;
    expirationDate?: Date;
    workflowStatusId?: string;
    workflowStatus?: MasterData;
    workflowTypeId?: string;
    workflowType?: MasterData;
    permissionType?: Array<MasterData>;
}