import { Component, OnInit } from '@angular/core';
import { TrainingVideosCodes } from 'src/app/core/shared/enums/training-videos.enum';
import { IVideoManagementService } from 'src/app/core/shared/services/video-management/video-management.service.interface';

@Component({
    selector: 'ctbox-video-home',
    templateUrl: './ctbox-video-home.component.html',
    styleUrls: ['./ctbox-video-home.component.scss'],
})
export class CtboxVideoHomeComponent implements OnInit {

    public srcVideo: string;

    constructor(private videoManagement: IVideoManagementService) { }

    public ngOnInit(): void {
        this.srcVideo = this.videoManagement.getUrlOption(TrainingVideosCodes.VideoLanding);
    }
}

