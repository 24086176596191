/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { InForceTypeCodes } from './inForceTypeCodes';
import { MasterData } from './masterData';
import { TraceTypeDTO } from './traceTypeDTO';
import { WorkflowPermissionType } from './workflowPermissionType';

export interface TraceEntityAllDTO { 
    userId?: string;
    userName?: string;
    userEmail?: string;
    operationDate?: Date;
    traceType?: TraceTypeDTO;
    documentId?: string;
    documentName?: string;
    emailShared?: string;
    nameShared?: string;
    isShared?: boolean;
    isRevisor?: boolean;
    cancelFromValidateId?: boolean;
    newState?: MasterData;
    oldState?: MasterData;
    newInForce?: InForceTypeCodes;
    oldInForce?: InForceTypeCodes;
    description?: string;
    traceAllId?: string;
    parentTraceAllId?: string;
    childTraces?: Array<TraceEntityAllDTO>;
    versionId?: string;
    versionName?: string;
    oldVersionName?: string;
    userNameRejectedFromValidateId?: string;
    userEmailRejectedFromValidateId?: string;
    rejectionCause?: string;
    expirationTitle?: string;
    expirationEmails?: Array<string>;
    expirationSigningDate?: Date;
    workflowPermission?: Array<WorkflowPermissionType>;
    documentWorkflowId?: string;
}