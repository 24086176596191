<div class="c-help">
    <div class="c-help-header">
        <h3 class="c-help-header-title c-help-clauses">Biblioteca de cláusulas</h3>
        <p class="c-help-header-text">Redacta y trabaja con tu propio repositorio de modelos de cláusulas legales que más utilizas en tus contratos y acuerdos, accesibles para toda tu organización y aprovecha todo el valor que te los modelos de cláusulas de laleydigital que podrás adaptar a tu propio estilo construyendo y vinculando tus propias cláusulas con las plantillas y documentos que desees.</p>
    </div>
    <div class="c-help-content">
        <mat-tab-group mat-align-tabs="start" class="c-help clauses">
            <mat-tab>
                <ng-template mat-tab-label>Mis cláusulas</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <p class="c-help-summary">En este espacio tienes una vista global de las cláusulas creadas por ti <mat-icon class="inline warning">person</mat-icon> y las creadas por usuarios de tu corporación <mat-icon class="inline info">apartment</mat-icon></p>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Acciones</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="edit" buttonColor="info"></ctbox-button></div>
                                <p><strong>Editar:</strong> Para editar el contenido de una cláusula.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="delete" buttonColor="delete"></ctbox-button></div>
                                <p><strong>Borrar:</strong> Para eliminar cláusulas.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="download" buttonColor="info"></ctbox-button></div>
                                <p><strong>Descargar:</strong> Para guardar una copia en tu equipo de una cláusula.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Organización de carpetas</h4>
                        <ul class="c-help-list">
                            <li><strong>Cláusulas de mi corporación:</strong> Este es el Espacio de tu corporación, donde podrás encontrar los modelos de cláusulas creados por ti y por tus compañeros de trabajo. A la hora de trabajar con las carpetas podrás hacer las siguientes tareas:</li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><app-tree-contextual-help module="clauses"> </app-tree-contextual-help></div>
                                <ul class="c-help-list">
                                    <li>Distinguirás fácilmente las cláusulas creadas por ti de las creadas por otros compañeros de tu corporación:</li>
                                    <li class="c-help-list-flexrow"><mat-icon class="warning">person</mat-icon> Cáusulas elaboradas por ti.</li>
                                    <li class="c-help-list-flexrow"><mat-icon class="info">apartment</mat-icon> Cláusulas elaboradas por tu corporación.</li>
                                </ul>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <ctbox-button buttonIcon="more_vert" buttonColor="secondary"></ctbox-button>
                                    <div class="ctbox-elevation">
                                        <ctbox-button buttonType="mat-menu-item" buttonColor="secondary" buttonText="Nueva Carpeta"></ctbox-button>
                                        <ctbox-button buttonType="mat-menu-item" buttonColor="secondary" buttonText="Renombrar Carpeta"></ctbox-button>
                                        <ctbox-button buttonType="mat-menu-item" buttonColor="secondary" buttonText="Borrar Carpeta"></ctbox-button>
                                    </div>
                                </div>
                                <ul class="c-help-list">
                                    <li><strong>Crear una nueva carpeta:</strong> Permite crear una carpeta dentro de la carpeta seleccionada.</li>
                                    <li><strong>Renombrar una carpeta ya existente:</strong> Permite renombrar la carpeta seleccionada.</li>
                                    <li><strong>Borrar una carpeta ya existente: </strong> Eliminar permanentemente esta carpeta y todas las que contiene.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Nueva cláusula</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <p class="c-help-summary">Trabajar con modelos de cláusulas es una forma sencilla y ágil de redactar un documento legal. En ContractBOX puedes crear tus propias cláusulas que después podrás incorporar en plantillas y documentos y te permitirán aprovechar todas las ventajas del módulo de alertas ContractBOX al día. </p>
                    </div>
                    <div class="c-help-block">
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <div class="ctbox-cards-group">
                                        <div class="ctbox-card">
                                            <a class="ctbox-card-link from-tdc clauses help"></a>
                                        </div>
                                    </div>
                                </div>
                                <p><strong>Partir de un modelo:</strong> Utiliza uno de los modelos de laleydigital para crear tu cláusula.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <div class="ctbox-cards-group">
                                        <div class="ctbox-card">
                                            <a class="ctbox-card-link from-file clauses help"></a>
                                        </div>
                                    </div>
                                </div>
                                <p><strong>Subir un documento:</strong> Sube tu modelo de cláusula en formato docx.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <div class="ctbox-cards-group">
                                        <div class="ctbox-card">
                                            <a class="ctbox-card-link from-new clauses help"></a>
                                        </div>
                                    </div>
                                </div>
                                <p><strong>Redactar una cláusula:</strong> Empezarás con un documento en blanco.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <p class="c-help-list-flexrow"><mat-icon class="info">info</mat-icon> Ten en cuenta que al crear una cláusula y guardarla en Mis Claúsulas la compartirás con el resto de usuarios de tu corporación.</p>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>