<div class="content-message">
    <div class="message">
        <div class="message-icon">
            <ctbox-button buttonIcon="link_off" buttonColor="delete" customClass="big"></ctbox-button>
        </div>
        <p class="text" i18n="@@NoExisteLaPagina">No existe la página solicitada.</p>
        <div class="message-icon button">
            <ctbox-button buttonType="secondary" buttonIcon="play_arrow" buttonText="Ir a pagina de inicio" i18n-buttonText="@@IrAPaginaDeInicio" (buttonClick)="goToInit()"></ctbox-button>
        </div>
    </div>
</div>