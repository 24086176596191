import { OptionalFragmentCheckPlugin } from '../../plugins/optional-fragment-check/optional-fragment-check-plugin';
import { Injectable } from '@angular/core';
import { Conversion, Element, UpcastConversionApi, UpcastDispatcher, ViewConsumable, ViewElement, Writer } from 'ckeditor5';
import { GlobalConstant } from '../../models/base/global-constant';

@Injectable({
    providedIn: 'root'
})
export class OptionalFragmentCheckDataViewToModelConverterService {

    private positionCheckDescription = 1;
    private positionCheckContent = 2;

    constructor() {
    }

    public configureConverters(conversion: Conversion): void {
        this.containerAndContentConversion(conversion);
        this.inputConversion(conversion);
    }

    private containerAndContentConversion(conversion: Conversion): void {
        conversion.for("upcast").add((dispatcher: UpcastDispatcher) => {
            dispatcher.on('element:div', (_evt, data, conversionApi) => {
                const {
                    consumable, writer, safeInsert, convertItem, convertChildren, updateConversionResult
                } = conversionApi;

                const viewItem = data.viewItem;
                const containerToConvert = { name: true, classes: OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.dataView };
                const checkViewElement = { name: true, 'type': 'checkbox' };

                if (!consumable.test(viewItem, containerToConvert) || viewItem.childCount < 2) {
                    return;
                }

                const firstChildItem = viewItem.getChild(0);
                if (!firstChildItem.is('element', 'input') || !consumable.test(firstChildItem, checkViewElement)) {
                    return;
                }

                const id = firstChildItem.getAttribute('id');

                const embeddedIn = viewItem.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN) ? viewItem.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN) : "undefined";

                const containerModelElement = writer.createElement(OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.model, {
                    'id': id,
                    'data-embedded-in': embeddedIn
                });

                if (!safeInsert(containerModelElement, data.modelCursor)) {
                    return;
                }

                consumable.consume(viewItem, containerModelElement);

                this.convertCheckNode(convertItem, firstChildItem, containerModelElement, consumable);
                this.convertDescriptionNode(viewItem, containerModelElement, conversionApi);

                this.convertContentNode(writer, viewItem, containerModelElement, convertChildren);
                updateConversionResult(containerModelElement, data);

            }, { priority: 'high' });
        });
    }

    private convertContentNode(writer: Writer, viewItem, containerModelElement: Element, convertChildren): Element {
        const contentElement = writer.createElement(OptionalFragmentCheckPlugin.MODEL_ENTITIES.content.model);
        writer.append(contentElement, containerModelElement);
        convertChildren(viewItem, contentElement);

        return contentElement;
    }

    private convertCheckNode(convertItem, firstChildItem, containerModelElement: Element, consumable: ViewConsumable): void {
        const checkObject = convertItem(firstChildItem, containerModelElement);
        consumable.consume(firstChildItem, checkObject);
    }

    private convertDescriptionNode(viewItem, containerModelElement: Element, conversionApi: UpcastConversionApi): void {
        const descriptionObjectInSecondNode = this.convertSecondNodeAsDescriptionNode(viewItem, containerModelElement, conversionApi);

        if (!!descriptionObjectInSecondNode) {
            return
        }

        this.convertNextNodeAsDescriptionNode(viewItem, containerModelElement, conversionApi);
    }

    private convertSecondNodeAsDescriptionNode(viewItem, containerModelElement: Element, conversionApi: UpcastConversionApi): Element {
        const { consumable, writer, convertChildren } = conversionApi;

        const secondChildItem = viewItem.getChild(this.positionCheckDescription);
        const descriptionViewElement = { name: true, classes: OptionalFragmentCheckPlugin.MODEL_ENTITIES.description.dataView };

        let descriptionObject: Element;
        let isCurrentNodeDivHDesc = secondChildItem.is('element', 'div') && consumable.test(secondChildItem, descriptionViewElement);
        if (isCurrentNodeDivHDesc) {
            consumable.consume(secondChildItem, secondChildItem);
            descriptionObject = writer.createElement(OptionalFragmentCheckPlugin.MODEL_ENTITIES.description.model);
            convertChildren(secondChildItem, descriptionObject);
            writer.append(descriptionObject, containerModelElement);
        }

        return descriptionObject;
    }

    private convertNextNodeAsDescriptionNode(viewItem, containerModelElement: Element, conversionApi: UpcastConversionApi): void {
        const { consumable, writer, convertChildren } = conversionApi;
        const descriptionViewElement = { name: true, classes: OptionalFragmentCheckPlugin.MODEL_ENTITIES.description.dataView };
        const spanDescElement = { name: true, classes: OptionalFragmentCheckPlugin.MODEL_ENTITIES.labelCheck.dataView };
        const secondChildItem = viewItem.getChild(this.positionCheckDescription);

        if (!secondChildItem.is('element', 'span') || !consumable.test(secondChildItem, spanDescElement)) {
            return null;
        }

        consumable.consume(secondChildItem, secondChildItem);
        let childToConsumeInDescription = secondChildItem;
        if (viewItem.childCount >= this.positionCheckContent + 1) {
            const thirdChildItem = viewItem.getChild(this.positionCheckContent);

            const isCurrentNodeDivHDesc = thirdChildItem.is('element', 'div') && consumable.test(thirdChildItem, descriptionViewElement);
            if (isCurrentNodeDivHDesc) {
                childToConsumeInDescription = thirdChildItem;
                consumable.consume(thirdChildItem, thirdChildItem);
            }
        }

        const descriptionObject = writer.createElement(OptionalFragmentCheckPlugin.MODEL_ENTITIES.description.model);
        writer.append(descriptionObject, containerModelElement);
        convertChildren(childToConsumeInDescription, descriptionObject);
    }

    private inputConversion(conversion: Conversion): void {
        conversion.for("upcast").elementToElement({
            view: {
                name: "input",
            },
            model: (viewElement: ViewElement, { writer }) => {
                const isCheckType = viewElement.getAttribute('type') === 'checkbox';
                if (!isCheckType) {
                    return;
                }

                const isChecked = !!viewElement.getAttribute(OptionalFragmentCheckPlugin.MODEL_ENTITIES.checkAttribute.dataView);
                const id = viewElement.getAttribute('id');
                const name = viewElement.getAttribute('name');

                return writer.createElement(OptionalFragmentCheckPlugin.MODEL_ENTITIES.check.model, {
                    'checked': isChecked,
                    'id': id,
                    'data-name': name
                });
            },
            converterPriority: 'high'
        });
    }
}
