import { Component, EventEmitter, Input, Output, OnInit, ViewChild } from '@angular/core';

import { v4 as uuidv4 } from 'uuid';
import { IFileStepper } from './file-stepper.model';
import { FileStepperDataService } from 'src/app/core/shared/services/file-stepper-data.service';
import { takeUntil } from 'rxjs/operators';
@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.scss']
})
export class UploadFileComponent implements OnInit {
    @Input() public allowedExtensions = [];
    @Input() public maxMgFileSize = 20;
    @Input() public allowDragAndDrop = true;

    @Output() public uploadedFiles = new EventEmitter<IFileStepper[]>();

    @ViewChild('fileAttachments') fileAttachments: any;

    private onDestroy$ = new EventEmitter<void>();
    private files: IFileStepper[] = [];
    private defaultValueGenerateVersion = false;

    public constructor( private readonly fileStepperDataService: FileStepperDataService) {
    }

    public ngOnInit(): void {
        this.fileStepperDataService.getRemoveFile()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((fileToRemove: IFileStepper) => {
            this.fileAttachments.nativeElement.value = '';
            this.files = this.files.filter((file) => file.name !== fileToRemove.name);
        });

        this.fileStepperDataService.getDefaultValueGenerateVersion()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((defaultValueGenerateVersion: boolean) => {
            this.defaultValueGenerateVersion = defaultValueGenerateVersion;
        });
    }

    public onFilesUploaded(event: any): void {
        const files: FileList = event.target.files;
        this.selectFiles(files);
    }

    public onFileDropped(files: FileList) {
        this.selectFiles(files);
    }

    public fileBrowseHandler(files: FileList) {
        this.selectFiles(files);
    }

    public getAllowedExtensionsAsString(): string {
        return this.allowedExtensions.join(', ');
    }

    private selectFiles(files: FileList): void {
        const selectedFiles: IFileStepper[] = [];
        for (let i = 0; i < files.length; i++) {

            const uploadFile: File = files.item(i);
            const file: IFileStepper = {
                id: uuidv4(),
                content: uploadFile,
                hasValidSize: this.isValidSize(uploadFile),
                hasValidExtension: this.isValidExtension(uploadFile),
                size: uploadFile.size,
                name: uploadFile.name,
                extension: this.getFileExtension(uploadFile),
                generateVersion: this.defaultValueGenerateVersion,
                error: [],
                warning: []
            };
            this.files.push(file);
            selectedFiles.push(file);
        }
        if (selectedFiles.length > 0 ){
            this.uploadedFiles.emit(selectedFiles);
        }
        this.fileAttachments.nativeElement.value = '';
    }

    private isValidExtension(file: File): boolean {
        return this.allowedExtensions.some(ext => file.name.endsWith(ext));
    }

    private isValidSize(file: File): boolean {
        return this.calculateSizeInMegabytes(file) <= this.maxMgFileSize;
    }

    private calculateSizeInMegabytes(file: File): number {
        return file.size / 1024 / 1024;
    }

    private getFileExtension(file: File): string {
        const fileName = file.name;
        const lastDotIndex = fileName.lastIndexOf('.');

        if (lastDotIndex === -1) {
            return '';
        }

        return fileName.slice(lastDotIndex + 1);
    }
}
