import { Component, OnInit } from '@angular/core';
import { IUploadedFilesResult } from 'src/app/core/shared/models/uploaded-files-result.model';
import { formatBytes } from 'src/app/core/shared/utils/file-size-extension';
import { IStepComponent } from '../models/step-component.model';
import { FileStepperDataService } from 'src/app/core/shared/services/file-stepper-data.service';

@Component({
  selector: 'app-uploaded-files-result-stepper',
  templateUrl: './uploaded-files-result-stepper.component.html',
  styleUrls: ['./uploaded-files-result-stepper.component.scss']
})
export class UploadedFilesResultStepperComponent implements IStepComponent {

    public uploadedFilesResult: IUploadedFilesResult;
    public isStepValid = true;

    constructor(private readonly fileStepperDataService: FileStepperDataService) {
        this.fileStepperDataService.getResultUploadedFiles().subscribe((uploadedFilesResult: IUploadedFilesResult) => {
            this.uploadedFilesResult = uploadedFilesResult;
        });

        this.fileStepperDataService.setSelectedFolderId(null);
    }

    public getTotalFilesSent(): number {
        return this.uploadedFilesResult ? this.uploadedFilesResult.failedUploadedFiles.length + this.uploadedFilesResult.successfullyUploadedFiles.length : 0;
    }

    public getTotalSuccessFiles(): number {
        return this.uploadedFilesResult ? this.uploadedFilesResult.successfullyUploadedFiles.length : 0;
    }

    public getTotalFailedFiles(): number {
        return this.uploadedFilesResult ? this.uploadedFilesResult.failedUploadedFiles.length : 0;
    }

    public getFormattedSize(size: number): string {
        return formatBytes(size);
    }
}
