import { Injectable } from '@angular/core';
import { DowncastWriter, ViewContainerElement, ViewEditableElement } from 'ckeditor5';
import { GlobalConstant } from '../../models/base/global-constant';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';

@Injectable({
    providedIn: 'root'
})
export class RadioEditionViewUtilsService {

    constructor() { }

    public createRadiusEditorView(modelItem: any, viewWriter: DowncastWriter): ViewContainerElement {
        const optionsCount = modelItem.getAttribute(RadioPlugin.MODEL_ENTITIES.optionCount.model);
        const id = modelItem.getAttribute(GlobalConstant.ATTRIBUTE_ID);
        const containerElement = viewWriter.createContainerElement(
            GlobalConstant.LABEL_DIV,
            {
                id,
                'data-options-count': optionsCount
            }
        );
        viewWriter.addClass([RadioPlugin.MODEL_ENTITIES.container.editionView, GlobalConstant.CONTAINER_CLASS_EDITION_VIEW], containerElement);
        return containerElement;
    }

    public createOptionEditionView(modelItem: any, viewWriter: DowncastWriter): ViewContainerElement {
        const dataPosition = modelItem.getAttribute(RadioPlugin.MODEL_ENTITIES.optionPosition.model);

        return viewWriter.createContainerElement(GlobalConstant.LABEL_DIV,
            {
                class: [RadioPlugin.MODEL_ENTITIES.option.editionView],
                'data-position': dataPosition
            });
    }

    public createRadiusElementEditionView(modelItem: any, viewWriter: DowncastWriter): ViewContainerElement {
        const nameGroup = modelItem.getAttribute(RadioPlugin.MODEL_ENTITIES.groupName.model);
        const checked = modelItem.getAttribute(RadioPlugin.MODEL_ENTITIES.checked.model) ? modelItem.getAttribute(RadioPlugin.MODEL_ENTITIES.checked.model).toString() : undefined;

        const viewElement = viewWriter.createContainerElement('img', {
            class: [RadioPlugin.MODEL_ENTITIES.input.editionView],
            type: 'radio',
            name: nameGroup,
            ...( ( checked && checked === "true" ) ? {
                    checked: true,
                    src: "assets/images/radio-button-checked.png"
                } : {
                    src: "assets/images/radio-button.png"
                })
        });

        return viewElement;
    }

    public createDescriptionElementEditionView(viewWriter: DowncastWriter): ViewEditableElement {
        return viewWriter.createEditableElement(GlobalConstant.LABEL_DIV, {
            class: [RadioPlugin.MODEL_ENTITIES.description.editionView],
        });
    }

    public createContentElementEditionView(viewWriter: DowncastWriter): ViewEditableElement {
        return viewWriter.createEditableElement(GlobalConstant.LABEL_DIV,
            {
                class: [RadioPlugin.MODEL_ENTITIES.content.editionView],
            });
    }
}
