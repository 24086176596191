export function formatBytes(bytes: number): string {
    if (isNaN(bytes) || bytes === 0) {
        return '0 Bytes';
    }

    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const size = 1024;
    const i = Math.floor(Math.log(bytes) / Math.log(size));
    const formattedSize = (bytes / Math.pow(size, i)).toFixed(2);

    return `${formattedSize} ${sizes[i]}`;
}
