import { Injectable } from '@angular/core';
import { ITableOfContentsForComponentsService } from './table-of-contents-for-components.service.interface';
import { TableOfContentsDTO, TableOfContentsNodeDTO } from 'src/app/api';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { BehaviorSubject, Observable } from 'rxjs';
import { NodeTreeNodeType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-node-type.enum';
import { SelectionModel } from '@angular/cdk/collections';

@Injectable({
    providedIn: 'root'
})
export class TableOfContentsForComponentsService extends ITableOfContentsForComponentsService {

    private currentExpansionModel = new BehaviorSubject<SelectionModel<string>>(new SelectionModel<string>(true));

    public getCurrentExpansionModel(): Observable<SelectionModel<string>> {
        return this.currentExpansionModel.asObservable();
    }

    public setCurrentExpansionModel(selectionModel: SelectionModel<string>): void {
        this.currentExpansionModel.next(selectionModel);
    }

    public createTreeFromTemplateToc(table: TableOfContentsDTO): FileNode[] {
        const actionsNodeContent: NodeTreeActionType[] = [NodeTreeActionType.NodeButton, NodeTreeActionType.Selected];
        const actionsNodeFolder: NodeTreeActionType[] = [];
        return this.createTreeFromToc(table, actionsNodeContent, actionsNodeFolder);
    }

    public createTreeFromClauseToc(table: TableOfContentsDTO): FileNode[] {
        const actionsNodeContent: NodeTreeActionType[] = [NodeTreeActionType.Selected];
        const actionsNodeFolder: NodeTreeActionType[] = [];
        return this.createTreeFromToc(table, actionsNodeContent, actionsNodeFolder);
    }

    private createTreeFromToc(table: TableOfContentsDTO, actionsNode: NodeTreeActionType[],
                              actionsFolder: NodeTreeActionType[]): FileNode[] {
        const tree: FileNode[] = [];
        const typeDefault = NodeTreeNodeType.ToCFile;
        table.contents.forEach(element => {
            const childNode = this.transformTableContentToNode(element, actionsNode, actionsFolder, typeDefault);
            tree.push(childNode);
        });

        return tree;
    }

    private transformTableContentToNode(contentNode: TableOfContentsNodeDTO, actionsNode: NodeTreeActionType[],
                                        actionsFolder: NodeTreeActionType[], typeDefault: NodeTreeNodeType): FileNode {
        const type = this.typeOfContent(contentNode, typeDefault);
        const node: FileNode = {
            id: contentNode.id,
            value: contentNode.description,
            type,
            children: [],
            parentId: contentNode.parentId,
            actions: this.permissionType(type, actionsNode, actionsFolder),
            isChecked: true
        };
        if (contentNode.contents.length > 0) {
            contentNode.contents.forEach(childContent => {
                const child = this.transformTableContentToNode(childContent, actionsNode, actionsFolder, typeDefault);
                node.children.push(child);
            });
        }

        return node;
    }

    private permissionType(type: NodeTreeNodeType, permissionsContent: NodeTreeActionType[],
                           permissionsFolder: NodeTreeActionType[]): NodeTreeActionType[] {
        switch (type) {
            case NodeTreeNodeType.ToCFile:
            case NodeTreeNodeType.Template:
            case NodeTreeNodeType.Clause:
            case NodeTreeNodeType.Smartform:
                return permissionsContent;
            case NodeTreeNodeType.Folder:
                return permissionsFolder;
            default:
                return [];
        }
    }

    private typeOfContent(contentNode: TableOfContentsNodeDTO, typeDefault: NodeTreeNodeType): NodeTreeNodeType {
        if (contentNode.contents.length > 0) {
            return NodeTreeNodeType.Folder;
        }
        if (contentNode.isSmartForm) {
            return NodeTreeNodeType.Smartform;
        }
        return typeDefault;
    }
}
