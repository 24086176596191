import { Component } from '@angular/core';

@Component({
    selector: 'app-my-clauses-contextual-help',
    templateUrl: './my-clauses-contextual-help.component.html'
})

export class MyClausesContextualHelpComponent {

}
