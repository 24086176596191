import { FolderItemInformationDto } from 'src/app/api';

export class HelpItemsListElement {
    constructor(
        public label: string,
        public text: string,
        public actionStyle?: string,
        public actionIcon?: string,
        public actionText?: string,
        public action?: (element: any) => void
    ) { }
}
