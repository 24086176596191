export class CustomizeConfiguration {
    public customizeId: string;
    public customizeIdName: string;
    public customizeName: string;
    public customizeLogoName: string;
    public customizeClassProduct: string;
    public customizeProductName: string;
    public customizeProductLogoName: string;
    public customizeShowAvatars: boolean;
    public customizeShowSimilarity: boolean;
    public loginMode: string;
    public showNotifications: boolean;
    public homePage: string;
}
