import { BehaviorSubject } from 'rxjs';

import { DocumentClauseDTO } from 'src/app/api';

export abstract class IClauseDocumentRevisionService {
    abstract getCurrentStateDocumentsSubscription(): BehaviorSubject<Map<string, string>>;
    abstract UpdateStateDocument(stateDocument: Map<string, string>);
    abstract getCurrentStateDocuments(): Map<string, string>;
    abstract loadPendingCBDocumentsForClause(clauseId: string): void;
    abstract getNumPendingDocuments(): number;
    abstract getNumWaitForFinishedDocuments(): number;
    abstract getIndexDocumentClause(documentClause: DocumentClauseDTO): number;
    abstract getState(documentClause: DocumentClauseDTO): string;
    abstract isPending(documentClause: DocumentClauseDTO): boolean;
    abstract isApplied(documentClause: DocumentClauseDTO): boolean;
    abstract isKeep(documentClause: DocumentClauseDTO): boolean;
    abstract isWaitForFinish(documentClause: DocumentClauseDTO): boolean;
    abstract setPending(documentClause: DocumentClauseDTO): void;
    abstract setKeep(documentClause: DocumentClauseDTO): void;
    abstract setApplied(documentClause: DocumentClauseDTO): void;
    abstract setWaitForFinished(documentClause: DocumentClauseDTO): void;
    abstract setUnwaitForFinished(documentClause: DocumentClauseDTO): void;
    abstract getNextDocumentClauseId(currentId: number): string;
    abstract getPreviousDocumentClauseId(currentId: number): string;
    abstract getFirstDocumentClauseId(currentIndex: number): string;
    abstract getLastDocumentClauseId(currentIndex: number): string;
    abstract orderIndex(orderedData: DocumentClauseDTO[]): void;
}
