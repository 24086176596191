import { Component, HostBinding, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { VersionInfo } from 'src/app/api/model/versionInfo';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { CtboxVersionAddCommentComponent } from 'src/app/shared/components/ctbox-versions/version-add-comment/version-add-comment.component';

@Component({
    selector: 'version-list',
    templateUrl: './version-list.component.html'
})
export class CtboxVersionsListComponent implements OnInit {

    @HostBinding('class') componentClass: string;

    @Input() versionsList: VersionInfo[];

    @Output() notifyNewVersionSelected = new EventEmitter<string>();
    @Output() notifyVersionInfoHasChanged = new EventEmitter<{ title: string, comment: string }>();

    constructor(private readonly genericDialogService: GenericDialogService) {
    }

    public ngOnInit(): void {
        this.componentClass = 'versions-list-component';
    }

    public addComment(version: VersionInfo): void {
        const config = this.genericDialogService.getSmallDialogConfig();
        const data: any = {
            template: CtboxVersionAddCommentComponent,
            dialogTitle: `${$localize`:@@Version:Versión`} ${version.label}`,
            dataSourceComponent: version,
            displayButtonBar: true,
            dialogButton: $localize`:@@Aceptar:Aceptar`,
            dialogCloseButon: $localize`:@@Cancelar:Cancelar`,
            displayCloseOption: true,
            dialogContentGetResultPropertyName: 'values',
            primaryButtonContentObservableName: 'canSaveVersion'
        };

        const dialog = this.genericDialogService.openTemplateWithConfigAndData(data.template, config, data);
        dialog.afterClosed().subscribe((result) => {
            if (!result || (data.dataSourceComponent.comment == result.comment && data.dataSourceComponent.title == result.title)) {
                return;
            }

            const versionInfo = {
                title: result.title,
                comment: result.comment
            };

            this.notifyVersionInfoHasChanged.emit(versionInfo);
        });

    }

    public hasTitle(infoTitle: string): boolean{
        if (infoTitle && infoTitle.length > 0) {
            return true;
        }
        return false;
    }

    public hasComment(infoComment: boolean): boolean{
        return infoComment;
    }

    public buttonColorHasComment(infoTitle: string, infoComment: boolean): string {
        if (this.hasTitle(infoTitle) || this.hasComment(infoComment))
        {
            return 'info';
        }
    }

    public onClickVersion(id: string): void {
        this.notifyNewVersionSelected.emit(id);
    }
}
