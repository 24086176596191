<ng-container *ngIf="parentForm" [formGroup]="parentForm">
    <div class="uploaded-file-list" formArrayName="{{ controlName }}">
        <div class="uploaded-file-item" *ngFor="let formGroupContainer of childrenControls.controls; index as i" [ngClass]="{'warning': hasUploadedFileItemError(i)}">
            <ng-container [formGroup]="formGroupContainer">
                <div class="uploaded-file-item-container">
                    <div class="uploaded-file-item-info">
                        <div class="uploaded-file-item-name" [id]="'name' + controlName + i"> {{ getFormControl('name', i).value }}</div>
                        <div class="uploaded-file-item-size" [id]="'size' + controlName + i"> {{ formatBytesView(getFormControl('size', i).value) }}</div>
                    </div>
                    <div *ngIf="hasUploadedFileItemError(i)" class="uploaded-file-item-error">
                        <mat-error *ngIf="fileErrorExists(i)">
                            {{getErrorMessage(i)}}
                        </mat-error>
                        <mat-error *ngIf="fileNameAlreadyExists(i)" i18n="@@ValidacionMensajeIgualNombreArchivo">
                            Ya existe un fichero con el mismo nombre.
                        </mat-error>
                        <mat-error *ngIf="fileIdAlreadyExists(i) && !fileNameAlreadyExists(i)" i18n="@@ErrorMismoIdentificadorSubirFichero">
                            Ya existe un fichero con el mismo identificador.
                        </mat-error>
                        <mat-error *ngIf="hasFileNameEmpty(i)" i18n="@@IntroduceNombre">
                            Debes introducir un nombre
                        </mat-error>
                        <mat-error *ngIf="hasFileNameInvalidCharacters(i)">
                            {{getInvalidCaracters(i)}}
                        </mat-error>
                        <mat-error *ngIf="hasFileNameMaxLength(i)" i18n="@@ValidacionMensajeNombreLongitud200">
                            La longitud no puede superar 200 caracteres.
                        </mat-error>
                        <mat-error *ngIf="!hasValidExtension(i)" i18n="@@ValidacionMensajeExtensionDocx">
                            Extensión no válida. Sólo se admiten ficheros .docx
                        </mat-error>
                        <mat-error *ngIf="!hasValidSize(i)" i18n="@@ValidacionMensajeTamanoMB">
                            Este fichero supera los {{maxMgFileSize}} MB permitidos
                        </mat-error>
                    </div>
                    <div class="uploade-file-item-checkbox">
                        <!-- TODO Intercambiar cuando CMS admita subir fichero sin crear version -->
                        <mat-checkbox *ngIf="canGenerateVersion" [disabled]="true" (change)="onGenerateVersionChange(i, $event)" formControlName="generateVersion">
                            <!-- <mat-checkbox *ngIf="canGenerateVersion"(change)="onGenerateVersionChange(i, $event)" formControlName="generateVersion"> -->
                            <label i18n="@@GenerarVersionFicheroSubido"> Generar versión </label>
                        </mat-checkbox>
                    </div>
                </div>
                <div class="uploaded-file-item-button">
                    <ctbox-button buttonIcon="delete" buttonColor="delete" (click)="remove(i)"></ctbox-button>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>
