/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ClauseDTO } from '../model/clauseDTO';
import { DashboardDocumentNextToSignDTO } from '../model/dashboardDocumentNextToSignDTO';
import { DashboardDocumentToReviewDTO } from '../model/dashboardDocumentToReviewDTO';
import { DocumentsStateInfoDTO } from '../model/documentsStateInfoDTO';
import { TemplateDTO } from '../model/templateDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DashboardService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardLastClausesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<ClauseDTO>>;
    public dashboardLastClausesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<ClauseDTO>>>;
    public dashboardLastClausesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<ClauseDTO>>>;
    public dashboardLastClausesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<ClauseDTO>>('get',`${this.basePath}/dashboard/last-clauses`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardLastTemplatesGet(observe?: 'body', reportProgress?: boolean): Observable<Array<TemplateDTO>>;
    public dashboardLastTemplatesGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TemplateDTO>>>;
    public dashboardLastTemplatesGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TemplateDTO>>>;
    public dashboardLastTemplatesGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TemplateDTO>>('get',`${this.basePath}/dashboard/last-templates`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardMyDocumentsStateGet(observe?: 'body', reportProgress?: boolean): Observable<DocumentsStateInfoDTO>;
    public dashboardMyDocumentsStateGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentsStateInfoDTO>>;
    public dashboardMyDocumentsStateGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentsStateInfoDTO>>;
    public dashboardMyDocumentsStateGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentsStateInfoDTO>('get',`${this.basePath}/dashboard/my-documents-state`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardNextFinishInReviewGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DashboardDocumentToReviewDTO>>;
    public dashboardNextFinishInReviewGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DashboardDocumentToReviewDTO>>>;
    public dashboardNextFinishInReviewGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DashboardDocumentToReviewDTO>>>;
    public dashboardNextFinishInReviewGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DashboardDocumentToReviewDTO>>('get',`${this.basePath}/dashboard/next-finish-in-review`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardNextPendingMySignatureGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DashboardDocumentNextToSignDTO>>;
    public dashboardNextPendingMySignatureGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DashboardDocumentNextToSignDTO>>>;
    public dashboardNextPendingMySignatureGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DashboardDocumentNextToSignDTO>>>;
    public dashboardNextPendingMySignatureGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DashboardDocumentNextToSignDTO>>('get',`${this.basePath}/dashboard/next-pending-my-signature`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardOurDocumentsStateGet(observe?: 'body', reportProgress?: boolean): Observable<DocumentsStateInfoDTO>;
    public dashboardOurDocumentsStateGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentsStateInfoDTO>>;
    public dashboardOurDocumentsStateGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentsStateInfoDTO>>;
    public dashboardOurDocumentsStateGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentsStateInfoDTO>('get',`${this.basePath}/dashboard/our-documents-state`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardTotalInReviewGet(observe?: 'body', reportProgress?: boolean): Observable<number>;
    public dashboardTotalInReviewGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public dashboardTotalInReviewGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public dashboardTotalInReviewGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<number>('get',`${this.basePath}/dashboard/total-in-review`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public dashboardTotalPendingMySignatureGet(observe?: 'body', reportProgress?: boolean): Observable<number>;
    public dashboardTotalPendingMySignatureGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<number>>;
    public dashboardTotalPendingMySignatureGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<number>>;
    public dashboardTotalPendingMySignatureGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<number>('get',`${this.basePath}/dashboard/total-pending-my-signature`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
