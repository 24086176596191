<div class="signature-box" id="signature" [ngClass]="{'disabled': !isDocLoaded}" #signature cdkDrag [cdkDragDisabled]="!canEdit && !isPreview"
    cdkDragBoundary="#pdfContainer" [cdkDragFreeDragPosition]="signInitialPosition" [ngStyle]="{'width': widthSignature, 'height': heightSignature,
    'left': xSignature, 'top': ySignature, 'transform': transformSignature}" (cdkDragStarted)="dragStarted()" (cdkDragEnded)="dragEnded()">
    <div class="signature-box-sign" [ngClass]="{'seted': !isPreview}">
        <div class="signature-box-copy">{{previewMessage}}</div>
        <div class="signature-box-role">[{{signatureUser?.role}}]</div>
    </div>
    <div class="signature-box-buttons">
        <ctbox-button buttonIcon="edit" buttonColor="info" [matTooltip]="matTooltipEdit" (buttonClick)="editSigner()"></ctbox-button>
        <ctbox-button buttonIcon="delete" buttonColor="delete" [matTooltip]="matTooltipDelete" (buttonClick)="deleteSigner()"></ctbox-button>
    </div>
</div>
