import { Injectable } from '@angular/core';
import { DocumentDTO, TemplateDocumentDTO, TemplateDTO } from 'src/app/api';
import { IWarningNotificacionService } from './warning-notification.service.interface';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { ITemplateDocumentRevisionService } from 'src/app/core/shared/services/templates/template-document-revision/template-document-revision.service.interface';
import { TemplateRevisionPreviewComponent } from 'src/app/shared/components/template-revision-preview/template-revision-preview.component';
import { TemplateLldRevisionPreviewComponent } from 'src/app/shared/components/template-lld-revision-preview/template-lld-revision-preview.component';
import { DocumentClauseRevisionPreviewComponent } from 'src/app/shared/components/document-clause-revision-preview/document-clause-revision-preview.component';

@Injectable({
    providedIn: 'root'
})
export class WarningNotificacionService implements IWarningNotificacionService {

    public pendingDocuments: number;
    public totalDocuments: number;

    constructor(public genericDialogService: GenericDialogService,
                public templateDocumentRevisionService: ITemplateDocumentRevisionService) {
    }

    public setPendingDocuments(): void {
        this.pendingDocuments = this.templateDocumentRevisionService.getNumPendingDocuments();
        this.totalDocuments = this.templateDocumentRevisionService.getCurrentStateDocuments().size;
    }

    public showClausesPendingDocument(document?: DocumentDTO): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            const modalTitle = $localize`:@@DocumentosClausulasDocumentoTitulo:Revisa los cambios en el documento actual por modificación de la cláusula origen`;
            const config = this.genericDialogService.getBigDialogConfig();
            const data: any = {
                template: DocumentClauseRevisionPreviewComponent,
                dialogTitle: modalTitle,
                secondaryButtonContentObservableName: 'isReviewDisabledSubscription',
                displayCloseOption: true,
                dataSourceComponent: document,
            };

            this.genericDialogService.openTemplateWithConfigAndData(data.template, config, data).afterClosed().subscribe((result) => {
                if (result > 0) {
                    const message = $localize`@@NotificacionDocumentoSinRevisar:No has terminado de revisar el documento afectado`;
                    this.genericDialogService.showMessage(message).afterClosed().subscribe(() => {
                        resolve(false);
                    });
                }
                resolve(true);
            });
        });
    }

    public showClausesPendingTemplate(document: TemplateDTO): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            const modalTitle = $localize`:@@DocumentosClausulasPlantillaTitulo:Plantilla con cláusulas pendientes de revisar`;
            const config = this.genericDialogService.getBigDialogConfig();
            const data: any = {
                template: DocumentClauseRevisionPreviewComponent,
                dialogTitle: modalTitle,
                dataSourceComponent: document,
                secondaryButtonContentObservableName: 'isReviewDisabledSubscription',
                displayCloseOption: true,
            };

            this.genericDialogService.openTemplateWithConfigAndData(data.template, config, data).afterClosed().subscribe((result) => {
                if (result > 0) {
                    const message = $localize`@@NotificacionPlantillaSinRevisar:No has terminado de revisar la plantilla afectada`;
                    this.genericDialogService.showMessage(message).afterClosed().subscribe(() => {
                        resolve(false);
                    });
                }
                resolve(true);
            });
        });
    }

    public showTemplatesPendingPreview(documentId: string): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            const modalTitle = $localize`:@@ModificacionDePlantillaPreviewDocumentoTitulo:Revisa los cambios en el documento actual por modificación de la plantilla origen`;
            const config = this.genericDialogService.getBigDialogConfig();
            const data: any = {
                template: TemplateRevisionPreviewComponent,
                dialogTitle: modalTitle,
                dataSourceComponent: documentId,
                secondaryButtonContentObservableName: 'isReviewDisabledSubscription',
                displayCloseOption: true,
            };

            this.genericDialogService.openTemplateWithConfigAndData(data.template, config, data).afterClosed().subscribe((result) => {
                if (result > 0) {
                    const message = $localize`@@NotificacionDocumentoSinRevisar:No has terminado de revisar el documento afectado`;
                    this.genericDialogService.showMessage(message).afterClosed().subscribe(() => {
                        resolve(false);
                    });
                }
                resolve(true);
            });
        });
    }

    public showTemplatesPendingPreviewTemplate(templateDocument: TemplateDocumentDTO, fromTemplateDocument?: boolean): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            const modalTitle = $localize`:@@ModificacionDePlantillaPreviewPlantillaTitulo:Revisa los cambios en la plantilla actual por modificación de la plantilla origen`;
            const config = this.genericDialogService.getBigDialogConfig();
            const data: any = {
                template: TemplateRevisionPreviewComponent,
                dialogTitle: modalTitle,
                dataSourceComponent: templateDocument.id,
                templateDocumentId: templateDocument.id,
                fromTemplateDocument,
                secondaryButtonContentObservableName: 'isReviewDisabledSubscription',
                displayCloseOption: true,
            };

            this.genericDialogService.openTemplateWithConfigAndData(data.template, config, data).afterClosed().subscribe(() => {
                resolve(this.closeTemplatesPending(templateDocument.template));
            });
        });
    }

    private closeTemplatesPending(templateToEdit: TemplateDTO, message?: any): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            this.setPendingDocuments();

            if (this.pendingDocuments > 0) {
                message = $localize`:@@PlantillaDocumentosPendientesAlCerrarMensaje:Los documentos se han actualizado correctamente. Sin embargo, tienes aún pendientes de revisar ` +
                    ' "' + this.pendingDocuments + '" ' + $localize`:@@PlantillaDocumentosConPlantilla: documento/s con la plantilla: `
                    + templateToEdit.name;
            } else if (this.pendingDocuments < 1) {
                message = $localize`:@@NoPendientesAlCerrarMensaje:Ha revisado todos los documentos pendientes.`;
            }
            this.genericDialogService.showMessage(message).afterClosed().subscribe(() => {
                resolve(this.pendingDocuments < 1);
            });
        });
    }


    public openTemplateLldRevisionPreviewTemplate(TemplateToEdit: TemplateDTO): void {
        const config = this.genericDialogService.getBigDialogConfig();
        const data: any = {
            template: TemplateLldRevisionPreviewComponent,
            dialogTitle: $localize`:@@PlantillaDocumentoTituloLLD:Revisa los cambios por modificación de la plantilla origen`,
            dataSourceComponent: TemplateToEdit,
            secondaryButtonContentObservableName: 'isReviewDisabledSubscription',
            displayCloseOption: true,
        };

        this.genericDialogService.openTemplateWithConfigAndData(TemplateLldRevisionPreviewComponent, config, data)
            .afterClosed().subscribe((result) => {
                if (result > 0) {
                    const message = '@@NotificacionPlantillaSinRevisar:No has terminado de revisar la plantilla afectada';
                    this.genericDialogService.showMessage(message);
                }
            });
    }

    public openTemplateLldRevisionPreviewDocument(document: DocumentDTO): void {
        const config = this.genericDialogService.getBigDialogConfig();
        const data: any = {
            template: TemplateLldRevisionPreviewComponent,
            dialogTitle: $localize`:@@PlantillaDocumentoTituloLLD:Revisa los cambios por modificación de la plantilla origen`,
            dataSourceComponent: document,
            isDocumentATemplate: true,
            secondaryButtonContentObservableName: 'isReviewDisabledSubscription',
            displayCloseOption: true,
        };

        this.genericDialogService.openTemplateWithConfigAndData(TemplateLldRevisionPreviewComponent, config, data)
            .afterClosed().subscribe((result) => {
                if (result > 0) {
                    const message = '@@NotificacionDocumentoSinRevisar:No has terminado de revisar el documento afectado';
                    this.genericDialogService.showMessage(message);
                }
            });
    }
}
