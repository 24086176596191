<div class="c-help">
    <div class="c-help-header">
        <h3 class="c-help-header-title c-help-administration">Panel de administración</h3>
        <p class="c-help-header-text">En este módulo podrás administrar y gestionar los permisos relacionados con los módulos de contractBOX, personalizando por usuario los accesos y tareas que se podrán realizar dentro de Plantillas y Cláusulas, Documentos y Administrador.</p>
    </div>
    <div class="c-help-content">
        <mat-tab-group mat-align-tabs="start" class="c-help administration">
            <mat-tab>
                <ng-template mat-tab-label>Documentos</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <p class="c-help-summary">En esta pestaña se podrán administrar los permisos relacionados con las tareas de acceso, edición y redacción de documentos.</p>
                        <h4 class="c-help-subtitle">Acceso:</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><mat-slide-toggle checked="true" disabled class="help"></mat-slide-toggle></div>
                                <p>El usuario que tenga este check activado podrá acceder al espacio Documentos de ContractBOX.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><mat-slide-toggle checked="false" disabled class="help"></mat-slide-toggle></div>
                                <p>El usuario que tenga este check activado no podrá acceder al espacio Documentos de ContractBOX.</p>
                            </li>
                            <li class=" c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonColor="permission-assign" buttonText="Asignar a todos" i18n-buttonText="@@AsignarTodosDocumentos"></ctbox-button></div>
                                <p>Activa esta opción para que todos los usuarios de tu corporación puedanver los documentos de la organización.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonColor="permission-discard" buttonText="Retirar a todos" i18n-buttonText="@@RetirarTodosDocumentos"></ctbox-button></div>
                                <p>Activa esta opción para que ningún usuario de tu corporación pueda ver los documentos de la organización.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Creación de documentos a partir de plantillas de laleydigital:</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><mat-slide-toggle checked="true" disabled class="help"></mat-slide-toggle></div>
                                <p>El usuario que tenga este check activado podrá redactar y crear documentos en ContractBOX.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><mat-slide-toggle checked="false" disabled class="help"></mat-slide-toggle></div>
                                <p>El usuario que tenga este check activado NO podrá redactar y crear documentos en ContractBOX, tan sólo revisarlos, compartirlos y firmarlos.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonColor="permission-assign" buttonText="Asignar a todos" i18n-buttonText="@@AsignarTodosDocumentos"></ctbox-button></div>
                                <p>Activa esta opción para que todos los usuarios de tu corporación puedan crear y redactar documentos en tu corporación.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonColor="permission-discard" buttonText="Retirar a todos" i18n-buttonText="@@RetirarTodosDocumentos"></ctbox-button></div>
                                <p>Activa esta opción para que ningún los usuarios de tu corporación puedan crear y redactar documentos en tu corporación.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <ctbox-button buttonType="primary" buttonIcon="save" buttonText="Guardar" i18n-buttonText="@@Guardar" buttonColor="administration"></ctbox-button>
                                    <ctbox-button buttonType="secondary" buttonText="Cancelar" buttonColor="administration"></ctbox-button>
                                </div>
                                <p>Una vez finalices la asignación o cancelación de permisos pulsa en guardar para que se apliquen los cambios en ContractBOX.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Permisos en carpetas de documentos de la organización: </h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><mat-slide-toggle checked="true" disabled class="help"></mat-slide-toggle></div>
                                <p>El usuario que tenga este check activado podrá crear, mover, renombrar o eliminar carpetas dentro del nivel de carpetas donde aparece el botón activado y sus descendientes.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><mat-slide-toggle checked="false" disabled class="help"></mat-slide-toggle></div>
                                <p>El usuario que tenga este check activado NO podrá crear, mover, renombrar o eliminar carpetas dentro del nivel de carpetas donde aparece el botón activado y sus descendientes.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonColor="permission-assign" buttonText="Asignar a todos" i18n-buttonText="@@AsignarTodosDocumentos"></ctbox-button></div>
                                <p>Activa esta opción para asignar permisos en todo el árbol de carpetas.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonColor="permission-discard" buttonText="Retirar a todos" i18n-buttonText="@@RetirarTodosDocumentos"></ctbox-button></div>
                                <p>Activa esta opción para cancelar permisos en todo el árbol de carpetas.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <ctbox-button buttonType="primary" buttonIcon="save" buttonText="Guardar" i18n-buttonText="@@Guardar" buttonColor="administration"></ctbox-button>
                                    <ctbox-button buttonType="secondary" buttonText="Cancelar" i18n-buttonText="@@Cancelar" buttonColor="administration"></ctbox-button>
                                </div>
                                <p>Una vez finalices la asignación o cancelación de permisos pulsa en guardar para que se apliquen los cambios en ContractBOX.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Plantillas y Cláusulas</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <p class="c-help-summary"> En esta pestaña se podrán administrar los permisos relacionados con las tareas de acceso, edición y redacción de plantillas. </p>
                        <h4 class="c-help-subtitle">Acceso:</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><mat-slide-toggle checked="true" disabled class="help"></mat-slide-toggle></div>
                                <p>El usuario que tenga este check activado podrá acceder al espacio Plantillas de ContractBOX.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><mat-slide-toggle checked="false" disabled class="help"></mat-slide-toggle></div>
                                <p>El usuario que tenga este check activado NO podrá acceder al espacio Plantillas de ContractBOX.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <ctbox-button buttonType="secondary" buttonColor="permission-assign" buttonText="Asignar a todos" i18n-buttonText="@@AsignarTodosDocumentos"></ctbox-button>
                                </div>
                                <p>Activa esta opción para que todos los usuarios de tu corporación puedan ver las plantillas y cláusulas de la organización.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <ctbox-button buttonType="secondary" buttonColor="permission-discard" buttonText="Retirar a todos" i18n-buttonText="@@RetirarTodosDocumentos"></ctbox-button>
                                </div>
                                <p>Activa esta opción para que ningún usuario de tu corporación pueda ver las plantillas y cláusulas de la organización.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <ctbox-button buttonType="primary" buttonIcon="save" buttonText="Guardar" i18n-buttonText="@@Guardar" buttonColor="administration"></ctbox-button>
                                    <ctbox-button buttonType="secondary" buttonText="Cancelar" i18n-buttonText="@@Cancelar" buttonColor="administration"></ctbox-button>
                                </div>
                                <p>Una vez finalices la asignación o cancelación de permisos pulsa en guardar para que se apliquen los cambios en ContractBOX.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Administrador</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <p class="c-help-summary">En esta pestaña se podrán gestionar los permisos de administrador en ContractBOX. </p>
                        <h4 class="c-help-subtitle">Acceso:</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><mat-slide-toggle checked="true" disabled class="help"></mat-slide-toggle></div>
                                <p>El usuario que tenga este check activado será el usuario administrador de ContractBOX y por tanto visualizará el panel de administración.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><mat-slide-toggle checked="false" disabled class="help"></mat-slide-toggle></div>
                                <p>El usuario que tenga este check activado NO podrá acceder al panel de administración.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonColor="permission-assign" buttonText="Asignar a todos" i18n-buttonText="@@AsignarTodosDocumentos"></ctbox-button></div>
                                <p>Activa esta opción para que todos los usuarios de tu corporación puedan acceder al panel de administración de permisos en ContractBOX como administradores.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonColor="permission-discard" buttonText="Retirar a los demás usuarios" i18n-buttonText="@@RetirarDemasUsuariosAdministrador"></ctbox-button></div>
                                <p>Activa esta opción para que ningún usuario de tu corporación pueda acceder al panel de administración de permisos en ContractBOX como administrador.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <ctbox-button buttonType="primary" buttonIcon="save" buttonText="Guardar" i18n-buttonText="@@Guardar" buttonColor="administration"></ctbox-button>
                                    <ctbox-button buttonType="secondary" buttonText="Cancelar" i18n-buttonText="@@Cancelar" buttonColor="administration"></ctbox-button>
                                </div>
                                <p>Una vez finalices la asignación o cancelación de permisos pulsa en guardar para que se apliquen los cambios en ContractBOX.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>