<div class="resend">
    <div class="resend-intro" *ngIf="hasTwoOptionsEnabled()">
        <span i18n="@@SeHaCanceladoELEnvioAFirmaDe">Se ha cancelado el envío a firma de </span>
        <span class="bolder"> {{numDocumentsSendToSign}} </span>
        <span i18n="@@DocumentosDosPuntos"> documentos:</span>
    </div>
    <div class="resend-selected" *ngIf="hasOneOrMoreDocumentsReady()">
        <div class="resend-item">
            <ng-container *ngIf="hasTwoOptionsEnabled()">
                <ctbox-button buttonIconSvg="signature" buttonColor="info"></ctbox-button>
            </ng-container>
            <p><span class="bolder number">{{numDocumentsReadyToSign}}</span> <span i18n="@@DocumentosPuedenSerEnviadosDeNuevoAFirma"> documentos pueden ser enviados de nuevo a firma</span></p>
        </div>
        <div class="resend-data">
            <span i18n="@@Quieres">¿Quieres</span>
            <span i18n="@@VolverAEnviarAFirmar" class="bolder"> volver a enviar a firmar </span>
            <span i18n="@@ALasMismasPersonas"> a las mismas personas?</span>
        </div>
        <div class="resend-data" *ngIf="true">
            <p class="resend-title"><ctbox-button buttonIcon="timer" buttonColor="info"></ctbox-button> <span i18n="@@FechaExpiracion">Fecha de expiración: </span></p>
            <p class="resend-data date">{{expirationDate | date: 'dd/MM/yyyy'}}</p>
        </div>
    </div>
</div>
<div class="resend-selected" *ngIf="hasOneOrMoreDocumentsNotReady()">
    <div class="resend-item">
        <ng-container *ngIf="hasTwoOptionsEnabled()">
            <ctbox-button buttonIconSvg="signature" buttonColor="warn"></ctbox-button>
        </ng-container>
        <p>
            <span class="number">{{numDocumentsNotReadyToSign}}</span>
            <span i18n="@@DocumentosPendientesDeEnvioAFirma"> documentos pendientes de envío a firma. Estos documentos contienen otra</span>
            <span i18n="@@ClausulaSinActualizar" class="warning"> cláusula sin actualizar,</span>
            <span i18n="@@BuscaLaClausulaAfectadaParaRevisarlaYEnviarAFirmar"> busca la cláusula afectada para revisarla y podrás enviar de nuevo los documentos a firmar.</span>
        </p>
    </div>
</div>