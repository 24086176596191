import { ForceSynchronize } from './../../ctbox-tree/models/forceSynchronize.model';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormGroup, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SelectionModel } from '@angular/cdk/collections';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { NodeTreeUserAction } from 'src/app/shared/components/ctbox-tree/enums/node-tree-user-action.enum';
import { NodeTreeAction } from 'src/app/shared/components/ctbox-tree/models/node-tree-action.model';
import { IStepComponent } from '../models/step-component.model';
import { FolderItemInformationDto} from 'src/app/api';
import { FileStepperDataService } from 'src/app/core/shared/services/file-stepper-data.service';
import { takeUntil } from 'rxjs/operators';
import { IFileStepper } from '../../upload-file/file-stepper.model';

@Component({
    selector: 'app-select-folder-stepper',
    templateUrl: './select-folder-stepper.component.html',
    styleUrls: ['./select-folder-stepper.component.scss',
                '../uploaded-files-result-stepper/uploaded-files-result-stepper.component.scss']
})

export class SelectFolderStepperComponent implements OnInit, OnDestroy, IStepComponent {

    @Input() set filesContent(content: FolderItemInformationDto[]) {
        if (content) {
            this.validateForm(content);
        }
    }

    @Input() public fileNodes: FileNode[];
    @Input() public initialNodes: string[] = [];
    @Input() public treeSelectionModel = new SelectionModel<string>(true, ['0']);
    @Input() public noMatchingElementsInSearch = false;
    @Input() public forceSynchoniceExpansionModel: ForceSynchronize;
    @Input() public showSearchNavigator = false;
    @Input() public indexResultOrdered = 0;
    @Input() public totalResultOrdered = 0;

    @Output() notifyActionOnFolder = new EventEmitter<NodeTreeAction>();
    @Output() notifyIsStepValid = new EventEmitter<boolean>();
    @Output() searchTerm = new EventEmitter<string>();
    @Output() clearSearch = new EventEmitter<void>();
    @Output() searchNextResultInSearch = new EventEmitter<void>();
    @Output() searchPreviousResultInSearch = new EventEmitter<void>();

    public files: IFileStepper[];
    public isStepValid = false;
    public selectFolderForm: FormGroup;
    public actions: NodeTreeUserAction[] = [NodeTreeUserAction.Menu, NodeTreeUserAction.Check];
    public selectedFolderId: string;
    public filesWithSameName: IFileStepper[] = [];

    private onDestroy$ = new EventEmitter<any>();

    constructor(private readonly fileStepperDataService: FileStepperDataService) {
        this.fileStepperDataService.getFilesSelected()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((files: IFileStepper[]) => {
                this.files = files;
        });

        this.fileStepperDataService.getSelectedFolderId()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((folderId: string) => {
                this.selectedFolderId = folderId;
        });
    }

    public ngOnInit(): void {
        this.initializeForm();
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public doActionFromNodeEvent(nodeEvent: NodeTreeAction): void {
        this.notifyActionOnFolder.emit(nodeEvent);
    }

    public searchInTree(searchTerm: string) {
        this.searchTerm.emit(searchTerm);
    }

    public clearSearchInTree() {
        this.clearSearch.emit();
    }

    protected invalidFolder(): boolean{
        return this.selectFolderForm.get('selectedFolderId').errors?.invalidFolder === true;
    }

    private initializeForm(): void {
        this.selectFolderForm = new UntypedFormGroup({
            selectedFolderId: new UntypedFormControl()
        });
    }

    private validateForm(content: FolderItemInformationDto[]): void {
        this.filesWithSameName = this.files.filter(file =>
            content?.some(folderItem => folderItem.name === this.removeExtension(file.name)));

        if (this.filesWithSameName.length > 0) {
            this.selectFolderForm.controls.selectedFolderId.setErrors({invalidFolder: true});

        } else {
            this.selectFolderForm.controls.selectedFolderId.setErrors(null);
        }

        this.notifyIsStepValid.next(this.selectFolderForm.valid);
    }

    public searchPreviousResult(): void  {
        this.searchPreviousResultInSearch.emit();
    }

    public searchNextResult(): void {
        this.searchNextResultInSearch.emit();
    }

    private removeExtension(fileName: string): string {
        const lastDotIndex = fileName.lastIndexOf('.');
        return fileName.substring(0, lastDotIndex);
    }
}
