import { Editor, ViewElement, Writer } from 'ckeditor5';
import { Injectable } from "@angular/core";
import { InputUtilsService } from '../../utils/input/input-utils.service';
import { InputDataViewUtilsService } from '../../utils/input/input-data-view-utils.service';
import { InputPlugin } from '../../plugins/input/input-plugin';
import { GlobalConstant } from '../../models/base/global-constant';
import { LinkedFieldPlugin } from '../../plugins/linked-field/linked-field-plugin';
import { ShortTextInput } from '../../models/input/short-text-input-model';

@Injectable({
    providedIn: "root",
})
export class InputDataViewToModelConverterService {

    private utilsService: InputUtilsService;
    private dataUtilsService: InputDataViewUtilsService;

    constructor() {
        this.utilsService = new InputUtilsService();
        this.dataUtilsService = new InputDataViewUtilsService();
    }

    public configureConverter(editor: Editor): void {
        const conversion = editor.conversion;

        conversion.for('upcast').elementToElement({
            view: { name: 'input', attributes: ['type'] },
            model: (viewElement: ViewElement, { writer }: {writer: Writer }) => {
                if (viewElement.hasClass(LinkedFieldPlugin.PLUGIN_MODEL)) {
                    return null;
                }

                const typeInput = viewElement.getAttribute(InputPlugin.MODEL_ENTITIES.type.dataView);

                if (!this.utilsService.isTypeValid(typeInput)) {
                    return null;
                }

                const inputModel = this.getModel(viewElement);
                const modelElement = this.dataUtilsService.createInputStructureModel(writer, typeInput, inputModel);

                return modelElement;
            }
        });
    }

    public getModel(viewElement: ViewElement): ShortTextInput {
        const getAttribute = (attr: string) => viewElement.getAttribute(attr) ?? '';
        return {
            id: getAttribute(GlobalConstant.ATTRIBUTE_ID),
            alias: getAttribute(InputPlugin.MODEL_ENTITIES.alias.dataView),
            help: getAttribute(InputPlugin.MODEL_ENTITIES.help.dataView),
            value: getAttribute(GlobalConstant.ATTRIBUTE_VALUE),
            pattern: getAttribute(InputPlugin.MODEL_ENTITIES.pattern.dataView),
            transform: getAttribute(InputPlugin.MODEL_ENTITIES.transform.dataView),
            isValid: getAttribute(InputPlugin.MODEL_ENTITIES.isValid.dataView) === 'true',
            type: getAttribute(InputPlugin.MODEL_ENTITIES.type.dataView),
            isLinked: getAttribute(InputPlugin.MODEL_ENTITIES.isLinked.dataView) === 'true',
            embeddedIn: getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN)
        };
    }
}
