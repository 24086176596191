/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserInfoDTO {
    userLogin?: string;
    email?: string;
    guid?: string;
    name?: string;
    surname?: string;
    isAdmin?: boolean;
    roles?: Array<string>;
    hasLicense?: boolean;
    permissions?: Array<string>;
    hasFirstDocumentCreated?: boolean;
    showAdminHelp?: boolean;
    adminGuid?: string;
    modules: number[];
}

export enum Modules {
    ContractBoxModule = 1,
    ContractBoxAlertsModule = 2,
    ContractBoxAiModule = 3
}
