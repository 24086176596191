import { Routes } from "@angular/router";
import { environment } from "src/environments/environment";
import * as standardRoutes from 'src/app/routes/client-routes/routes.STANDARD';
import * as cmcRoutes from 'src/app/routes/client-routes/routes.CMC';

export function getRoutes(): Routes {
    if (environment.clientId === 'CMC') {
        return cmcRoutes.routes;
    }
    return standardRoutes.routes;
};
