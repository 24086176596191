import { Injectable } from '@angular/core';
import { Data } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export abstract class ISectionStyleConfigService {
    abstract getData$(): Observable<Data>;
    abstract getValuesFromData$(): Observable<Data>;
    abstract addBodyClass(className: string): void;
    abstract removeBodyClass(className: string): void;
    abstract toogleBodyClassByModule(moduleName: string, className: string): void;
}
