import { Injectable } from '@angular/core';
import { Conversion, DowncastWriter, Element, ViewContainerElement } from 'ckeditor5';
import { ClausesPlugin } from '../../plugins/clause/clauses-plugin';
import { DataViewModel } from '../../models/base/data-view-model';
import { GlobalConstant } from '../../models/base/global-constant';

@Injectable({
    providedIn: 'root'
})
export class ClauseModelToDataViewConverterService {

    public configureConverters(conversion: Conversion): void {
        conversion.for("dataDowncast").elementToElement({
            model: ClausesPlugin.MODEL_ENTITIES.class.model,
            view: (modelItem: Element, { writer: viewWriter })  => {
                const dataViewModel = this.createDataViewModel(modelItem);
                return this.createView(dataViewModel, viewWriter);
            }
        });
    }

    private createDataViewModel(modelItem: Element): DataViewModel {
        return {
            id: modelItem.getAttribute(ClausesPlugin.ID) as string,
            class: ClausesPlugin.MODEL_ENTITIES.class.dataView,
            container: GlobalConstant.LABEL_DIV
        };
    }

    private createView(dataViewModel: DataViewModel, viewWriter: DowncastWriter): ViewContainerElement {
        return viewWriter.createContainerElement(dataViewModel.container,
            {
                id: dataViewModel.id,
                class: dataViewModel.class
            });
    }
}
