import { Command, Position, Range, TreeWalkerValue } from 'ckeditor5';
import { GlobalConstant } from '../models/base/global-constant';

export abstract class BaseCommand extends Command {

    public abstract labelDataModel: string;

    public override refresh(): void {
        const model = this.editor.model;
        const selection = model.document.selection;
        const position: Position | null = selection.getFirstPosition();
        if (!position) {
            return;
        }
        const allowedIn = model.schema.findAllowedParent(position, this.labelDataModel);
        this.isEnabled = allowedIn !== null;
    }

    protected getElementWithId(range: Range, id: string): TreeWalkerValue | null {
        for (const value of range.getWalker()) {
            if (value.item.hasAttribute(GlobalConstant.ATTRIBUTE_ID) && value.item.getAttribute(GlobalConstant.ATTRIBUTE_ID) === id) {
                return value;
            }
        }
        return null;
    }
}
