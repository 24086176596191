import { Directive, Inject } from "@angular/core";
import { ButtonView, Locale } from "ckeditor5";
import BaseView from "../base-view.directive";
import { ClausesPlugin } from "../../plugins/clause/clauses-plugin";
import { UI_CLASSES } from "../styles/styles-constants";

@Directive({
  selector: "app-clause-balloon-view",
})
export default class ClauseBalloonView extends BaseView {

    public goToClauseButtonView: ButtonView;

    constructor( @Inject(Locale) locale?: Locale) {
        super(locale);

        this.deleteButtonView = this.createButton(ClausesPlugin.DELETE_OPTION, null, UI_CLASSES.SVG_ICONS.DELETE);
        this.goToClauseButtonView = this.createButton(ClausesPlugin.GO_TO_CLAUSE, null, UI_CLASSES.SVG_ICONS.CLAUSE_EDITOR_ICON);

        this.items = this.createCollection([ this.goToClauseButtonView, this.deleteButtonView]);

        this.setTemplateForm("ck-clause-balloon");
    }
}
