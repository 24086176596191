<ng-container [ngSwitch]="fileState">
    <ng-container *ngSwitchCase="fileStatusCode.CREATION">
        <ctbox-help>
            <ng-container ngProjectAs="titleHelp">
                <ctbox-button buttonType="text" customClass="text-default" buttonIcon="settings" [buttonText]="getTextButton(fileState)"></ctbox-button>
            </ng-container>
            <ng-container ngProjectAs="bodyHelp">
                <ctbox-help-body [mode]="'light'" [titleBody]="'Plantilla en proceso de creación'" [itemList]="createItemList(folderItem)"></ctbox-help-body>
            </ng-container>
        </ctbox-help>
    </ng-container>
    <ng-container *ngSwitchDefault>
        <ctbox-button buttonType="text" customClass="text-default" buttonColor="state" buttonIcon="circle" [buttonText]="getTextButton(fileState)"></ctbox-button>
    </ng-container>
</ng-container>
