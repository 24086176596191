import { ApiOperation, ApiProcessorService, ApiRequest } from '../api-processor.service';
import { Observable } from 'rxjs';

export abstract class BaseLegalAlertsContentService {

    protected abstract basePath: string;

    constructor(
        protected readonly apiProcessorService: ApiProcessorService
    ) { }

    public get(id: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}/${id}`;
        apiRequest.responseType = 'text';

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
