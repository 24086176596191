import { Injectable } from '@angular/core';
import { FolderItemInformationDto, UserFolderPermissionsDto, FileStatusesCodes, FolderResultDto, SearchClauseFoldersResultDto, ClauseFolderDto } from 'src/app/api';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { NodeTreeNodeType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-node-type.enum';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { IClausesFolderHierarchyTreeActionsService } from './clauses-folder-with-content.actions.service.interface';
import { BaseClauseTreeService } from '../base-clause-folder-tree.service';
import { SelectionModel } from '@angular/cdk/collections';
import { AdditionalParam } from 'src/app/shared/components/ctbox-tree/models/additional-param.model';

@Injectable({
    providedIn: 'root'
})
export class ClauseFolderWithContentActionsService extends BaseClauseTreeService
    implements IClausesFolderHierarchyTreeActionsService {

    private readonly clauseFolderPermissions: UserFolderPermissionsDto = {
        canCreateChildren: true,
        canEditName: true,
        canSee: true,
        canDelete: true,
        canSeeContent: true,
        canCreateContent: true,
        canMoveFrom: true,
        canMoveToRoot: true,
        canMoveTo: true
    };

    public create(
        clausesFolders: ClauseFolderDto[],
        rootActions: NodeTreeActionType[],
        foldersNodeActions: NodeTreeActionType[],
        clausesNodeActions: NodeTreeActionType[]): FileNode[] {

        const rootNode: FileNode = this.createRootNode(rootActions);

        const tree: FileNode[] = [rootNode];

        clausesFolders.filter(tf => tf.clauseFolderParentId === this.EMPTY_GUID).forEach(root => {
            const childNode = this.buildFileNode(clausesFolders, root, foldersNodeActions, clausesNodeActions);
            rootNode.children.push(childNode);
        });

        rootNode.children = this.pipe.transform(rootNode.children);
        return tree;
    }

    public searchByFolderId = (

        clausesFolders: ClauseFolderDto[],
        folderId: string) => this.searchClauseFolderHierarchyTreeByFolderProperty(clausesFolders, clausesFolders, 'id', folderId)

    public searchByFolderName = (clausesFolders: ClauseFolderDto[], folderName: string) =>
        this.searchClauseFolderHierarchyTreeByFolderProperty(clausesFolders, clausesFolders, 'name', folderName)

    public highlightFolderOrContent(clauseFolderSearchResult: SearchClauseFoldersResultDto): ClauseFolderDto[] {
        const folders = clauseFolderSearchResult.folders;
        const results = clauseFolderSearchResult.folderResults;
        const allFound = this.getFoundedFoldersOrContent(folders, results);

        allFound.forEach((found: (ClauseFolderDto | FolderItemInformationDto)) => {
             const result = results.find(r => r.id === found.id);
             found.nameHighlighted = this.isNameUpdated(found.name, result.name) ? result?.searchResultHighlight.name : null ;
        });

        return folders;
    }

    public highlightNavigateNextFolderOrContent(folders: ClauseFolderDto[], results: FolderResultDto[],
                                                selectResult: FolderResultDto, isOverflow: boolean): ClauseFolderDto[] {
        const isAscending = true;
        return this.highlightNavigateFolderOrContent(folders, results,  selectResult, isAscending, isOverflow);
    }
    public highlightNavigatePreviousFolderOrContent(folders: ClauseFolderDto[], results: FolderResultDto[],
                                                    selectResult: FolderResultDto, isOverflow: boolean): ClauseFolderDto[] {
        const isAscending = false;
        return this.highlightNavigateFolderOrContent(folders, results,  selectResult, isAscending, isOverflow);
    }

    private highlightNavigateFolderOrContent(folders: ClauseFolderDto[], results: FolderResultDto[],
                                             selectResult: FolderResultDto, isAscending: boolean,
                                             isOverflow: boolean): ClauseFolderDto[] {

            const allFound = this.getFoundedFoldersOrContent(folders, results);
            const highlightTag = '<em class="highlight-navigated">';
            const noHighlightTag = '<em class="highlight">';

            let previusHighligh = allFound.find(found => found.nameHighlighted.includes(highlightTag));
            const nextHighligh = allFound.find(found => found.id === selectResult.id);

            let startCharacterIndex = 0;
            let endCharacterIndex = 0;
            let highlighName = '';

            if (previusHighligh && previusHighligh.id === nextHighligh.id) {
                startCharacterIndex = previusHighligh.nameHighlighted.indexOf(highlightTag);
                endCharacterIndex = startCharacterIndex + noHighlightTag.length;
                highlighName = previusHighligh.nameHighlighted;
            }

            if (previusHighligh) {
                const result = results.find(r => r.id === previusHighligh.id);
                let newNameReplaceClassSelectResult = result?.searchResultHighlight.name;
                newNameReplaceClassSelectResult = newNameReplaceClassSelectResult.replace(highlightTag, noHighlightTag);
                previusHighligh.nameHighlighted = newNameReplaceClassSelectResult;
            }

            if (isOverflow){
                startCharacterIndex = 0;
                endCharacterIndex = 0;
                highlighName = '';
                previusHighligh = null;
            }

            if (nextHighligh) {
                const result = results.find(r => r.id === nextHighligh.id);
                let newNameReplaceClassSelectResult = result?.searchResultHighlight.name;
                const numberOcurrences  = (newNameReplaceClassSelectResult.match(new RegExp(noHighlightTag, 'g')) || []).length +
                (newNameReplaceClassSelectResult.match(new RegExp(highlightTag, 'g')) || []).length;

                if (startCharacterIndex === endCharacterIndex && numberOcurrences < 2) {
                    newNameReplaceClassSelectResult = newNameReplaceClassSelectResult.replace(noHighlightTag, highlightTag);
                } else {
                    let newStartIndex = 0;
                    let newEndIndex = 0;
                    if (isAscending) {
                        let diffHighlightTagsLength =  highlightTag.length - noHighlightTag.length;
                        if (!previusHighligh || previusHighligh?.id !== nextHighligh?.id) {
                            diffHighlightTagsLength = 0;
                        }
                        if (!highlighName) {
                            highlighName = result.searchResultHighlight.name;
                        }
                        newStartIndex = highlighName.indexOf(noHighlightTag, startCharacterIndex) - diffHighlightTagsLength;
                        newEndIndex = newStartIndex + noHighlightTag.length;
                    } else {
                        if (!previusHighligh || previusHighligh?.id !== nextHighligh?.id) {
                            highlighName = nextHighligh.nameHighlighted;
                            newStartIndex = highlighName.lastIndexOf(noHighlightTag);
                            newEndIndex = newStartIndex + noHighlightTag.length;
                        } else {
                            const previousTagIndex = highlighName.indexOf(highlightTag);
                            newStartIndex = highlighName.substring(0, previousTagIndex).lastIndexOf(noHighlightTag, previousTagIndex);
                            newEndIndex = newStartIndex + noHighlightTag.length;
                        }
                    }
                    newNameReplaceClassSelectResult = newNameReplaceClassSelectResult.substring(0, newStartIndex) + highlightTag +
                    newNameReplaceClassSelectResult.substring(newEndIndex, newNameReplaceClassSelectResult.length);
                }
                nextHighligh.nameHighlighted = newNameReplaceClassSelectResult;
            }
            return folders;
    }

    public expandFoundResult(expansionResult: SelectionModel<string>, folders: ClauseFolderDto[],
                             results: FolderResultDto[]): SelectionModel<string> {
        const allFound = this.getFoundedFoldersOrContent(folders, results);
        expansionResult.clear();
        expansionResult.select(this.rootNodeId);
        expansionResult.select(...allFound.map(found => found.id));

        return expansionResult;
    }

    private getFoundedFoldersOrContent(folders: ClauseFolderDto[],
                                       results: FolderResultDto[]): (ClauseFolderDto | FolderItemInformationDto)[] {
        const foldersFound = folders
        .filter(folder => results.some(r => r.id === folder.id));
        const itemsFoundMulti = folders.map(folder => folder.content.filter(item => results.some(r => r.id === item.id)));
        const itemFound = [].concat(...itemsFoundMulti);

        return itemFound.concat(foldersFound);
    }

    private searchClauseFolderHierarchyTreeByFolderProperty(
        initialClausesFolders: ClauseFolderDto[],
        clausesFolders: ClauseFolderDto[],
        folderProperty: string,
        propertyValue: string): ClauseFolderDto {
        for (const clauseFolder of clausesFolders) {
            if (clauseFolder[folderProperty] === propertyValue) {
                return clauseFolder;
            }

            const children: ClauseFolderDto[] = initialClausesFolders.filter(tf => tf.clauseFolderParentId === clauseFolder.id);
            const elementFound =
                this.searchClauseFolderHierarchyTreeByFolderProperty(initialClausesFolders, children, folderProperty, propertyValue);
            if (elementFound) {
                return elementFound;
            }
        }

        return null;
    }

    private buildFileNode(
        initialClausesFolders: ClauseFolderDto[],
        clauseFolder: ClauseFolderDto,
        foldersNodeActions: NodeTreeActionType[],
        clausesNodeActions: NodeTreeActionType[]): FileNode {

        const nodeActionsFilteredByUserPermissions: NodeTreeActionType[] =
            this.getNodeActionsFilteredByUserPermissions(foldersNodeActions, this.clauseFolderPermissions);

        let clausesNodes: FileNode[] = clauseFolder.content.map((clauseInfo: FolderItemInformationDto) => {
            const noActions = clauseInfo.fileStatus?.code === FileStatusesCodes.CREATION;
            const clausesNodeActionsFiltered = noActions ? [] : clausesNodeActions;
            return this.buildClauseNode(clauseInfo, clauseFolder.id, clausesNodeActionsFiltered, false);
        });

        clausesNodes = this.pipe.transform(clausesNodes);

        const children: ClauseFolderDto[] = initialClausesFolders.filter(tf => tf.clauseFolderParentId === clauseFolder.id);
        const childrenFileNodes: FileNode[] = children.map((child: ClauseFolderDto) =>
            this.buildFileNode(initialClausesFolders, child, foldersNodeActions, clausesNodeActions));

        const node: FileNode =
            this.buildFolderNode(clauseFolder,
                nodeActionsFilteredByUserPermissions, [...childrenFileNodes, ...clausesNodes], false);

        node.children = this.pipe.transform(node.children);
        return node;
    }

    private buildFolderNode(
        clauseFolder: ClauseFolderDto,
        defaultFolderNodeActions: NodeTreeActionType[],
        children: FileNode[],
        isChecked: boolean): FileNode {
            return {
                id: clauseFolder.id,
                value: clauseFolder.name,
                type: NodeTreeNodeType.Folder,
                children,
                parentId: clauseFolder.clauseFolderParentId,
                valueHighlighted: clauseFolder.nameHighlighted ? clauseFolder.nameHighlighted : null,
                actions: [...defaultFolderNodeActions],
                isChecked
            };
    }

    private buildClauseNode(
        clause: FolderItemInformationDto,
        parentId: string,
        clauseNodeActions: NodeTreeActionType[],
        isChecked: boolean): FileNode {
            const additionalData: AdditionalParam = {
                isInCreation: clause.fileStatus.code === FileStatusesCodes.CREATION,
                isOnUpdate: clause.fileStatus.code === FileStatusesCodes.UPDATE
            };
            return {
                id: clause.id,
                value: clause.name,
                type: NodeTreeNodeType.Clause,
                children: [],
                parentId,
                valueHighlighted: clause.nameHighlighted ? clause.nameHighlighted : null,
                actions: clauseNodeActions,
                isChecked,
                additionalData
            };
    }

    private isNameUpdated(originalName: string, resultName: string): boolean {
        if (!resultName) {
            return false;
        }
        return originalName === resultName;
    }
}
