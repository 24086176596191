<form [formGroup]="fileManagementForm" class="file-upload">
    <app-upload-file class="file-upload-file" [allowedExtensions]="allowedExtensions" [maxMgFileSize]="maxMgFileSize" (uploadedFiles)="onUploadedFiles($event)"></app-upload-file>
    <div class="file-upload-result">
        <p class="file-upload-error">
            <ng-container *ngIf="maxFilesExceed()">
                <p class="file-upload-error-text">
                    <ng-container i18n="@@ValidacionMensajeMaximoNumeroFicheros">No es posible enviar más de {{maxNumberFilesAllowed}} ficheros. Revisa la lista</ng-container>
                </p>
            </ng-container>
        </p>
        <app-uploaded-file-item class="file-upload-item" #uploadedFilesComponent [parentForm]="fileManagementForm" [controlName]="'uploadedFiles'" [maxMgFileSize]="maxMgFileSize" (notifyRemoveFile)="onNotifyRemoveFile($event)" (notifyCheckedVersionFile)="notifyCheckedVersionFile($event)"></app-uploaded-file-item>
    </div>
</form>
