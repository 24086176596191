import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { CtboxGenericDialogDataComponent, dialogTypes } from 'src/app/shared/components/ctbox-generic-dialog-data/ctbox-generic-dialog-data.component';
import { CtboxInfoComponent } from 'src/app/shared/components/ctbox-info/ctbox-info.component';
import { GenericDialogConfig } from 'src/app/core/shared/models/generic-dialog-config.model';

@Injectable({ providedIn: 'root' })
export class GenericDialogService {

    public lastOpenedDialog: MatDialogRef<any>;
    public modalCancelMessage = $localize`:@@Cancelar:Cancelar`;
    public modalAcceptMessage = $localize`:@@Aceptar:Aceptar`;

    constructor(public dialog: MatDialog) { }

    public showMessage(messageText: string | string[], dialogButton?: string,): MatDialogRef<any> {
        return this.lastOpenedDialog = this.dialog.open(CtboxGenericDialogDataComponent, {
            data: {
                template: CtboxInfoComponent,
                message: messageText,
                displayButtonBar: true,
                dialogButton: dialogButton ? dialogButton : this.modalAcceptMessage,
                dialogTypes: dialogTypes.Warning,
            },
            disableClose: true
        });
    }

    public showMessageWithHeader(header: string, messageText: string | string[]): MatDialogRef<any> {
        return this.lastOpenedDialog = this.dialog.open(CtboxGenericDialogDataComponent, {
            data: {
                template: CtboxInfoComponent,
                message: messageText,
                subTitle: header,
                displayButtonBar: true,
                dialogButton: this.modalAcceptMessage,
                dialogTypes: dialogTypes.Warning,
            },
            disableClose: true
        });
    }

    public showQuestion(messageText?: string, templateComponent?,
                        dataSourceComponent?, dialogButton?: string, dialogCloseButon?: string): MatDialogRef<any> {
        return this.lastOpenedDialog = this.dialog.open(CtboxGenericDialogDataComponent, {
            data: {
                template: templateComponent ? templateComponent : CtboxInfoComponent,
                message: messageText ? messageText : '',
                dataSource: dataSourceComponent ? dataSourceComponent : '',
                displayButtonBar: true,
                dialogButton: dialogButton ? dialogButton : this.modalAcceptMessage,
                dialogCloseButon: dialogCloseButon ? dialogCloseButon : this.modalCancelMessage,
                dialogTypes: dialogTypes.Warning,
            },
            disableClose: true
        });
    }

    public openTemplate(templateComponent?, dataSourceComponent?, dialogTitle?: string,
                        displayButtonBar?: boolean, dialogButton?: string, dialogCloseButon?: string): MatDialogRef<any> {
        return this.lastOpenedDialog = this.dialog.open(CtboxGenericDialogDataComponent, {
            disableClose: true,
            minWidth: '90vw',
            minHeight: '80vh',
            data: {
                template: templateComponent ? templateComponent : CtboxInfoComponent,
                dataSource: dataSourceComponent ? dataSourceComponent : '',
                dialogTitle: dialogTitle ? dialogTitle : '',
                displayButtonBar: displayButtonBar ? displayButtonBar : false,
                dialogButton: dialogButton ? dialogButton : this.modalAcceptMessage,
                dialogCloseButon: dialogCloseButon ? dialogCloseButon : this.modalCancelMessage,
                displayCloseOption: true,
            }
        });
    }

    public openTemplateWithData(templateComponent, data: any): MatDialogRef<any> {
        const config = this.getDefaultDialogConfig();
        data.template = templateComponent;
        return this.lastOpenedDialog = this.dialog.open(CtboxGenericDialogDataComponent, {
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            maxWidth: config.maxWidth,
            maxHeight: config.maxHeight,
            minWidth: config.minWidth,
            minHeight: config.minHeight,
            data,
            disableClose: true
        });
    }

    public openTemplateWithConfigAndData(templateComponent, config: GenericDialogConfig, data: any): MatDialogRef<any> {
        data.template = templateComponent;
        return this.lastOpenedDialog = this.dialog.open(CtboxGenericDialogDataComponent, {
            backdropClass: config.backdropClass,
            panelClass: config.panelClass,
            maxWidth: config.maxWidth,
            maxHeight: config.maxHeight,
            minWidth: config.minWidth,
            minHeight: config.minHeight,
            width: config.width,
            height: config.height,
            data,
            disableClose: config.disableClose
        });
    }

    public getDefaultDialogConfig(): GenericDialogConfig {
        const defaultConfig: GenericDialogConfig = {
            backdropClass: 'cdk-overlay-top-center',
            panelClass: 'cdk-overlay-top-center',
            maxWidth: '80vw',
            maxHeight: '90vh',
            minWidth: '50vw',
            minHeight: '75vh',
            width: '50vw',
            height: '75vh',
            disableClose: true
        };

        return defaultConfig;
    }

    public getBigDialogConfig(): GenericDialogConfig {
        const defaultConfig: GenericDialogConfig = {
            backdropClass: 'XL',
            panelClass: 'XL',
            maxWidth: '90vw',
            maxHeight: '90vh',
            minWidth: '50vw',
            minHeight: '80vh',
            width: '90vw',
            height: '90vh',
            disableClose: true
        };

        return defaultConfig;
    }

    public getMediumDialogConfig(): GenericDialogConfig {
        const defaultConfig: GenericDialogConfig = {
            backdropClass: 'M',
            panelClass: 'M',
            maxWidth: '80vw',
            maxHeight: '90vh',
            minWidth: '50vw',
            minHeight: '50vh',
            width: '50vw',
            height: '60vh',
            disableClose: true
        };
        return defaultConfig;
    }

    public getSmallDialogConfig(): GenericDialogConfig {
        const defaultConfig: GenericDialogConfig = {
            backdropClass: 'S',
            panelClass: 'S',
            maxWidth: '35vw',
            maxHeight: '',
            minWidth: '',
            minHeight: '',
            width: '100%',
            height: '',
            disableClose: true
        };
        return defaultConfig;
    }
}
