import { Injectable } from '@angular/core';
import { Conversion, Element, toWidget, toWidgetEditable, ViewElement } from 'ckeditor5';
import { GlobalConstant } from '../../models/base/global-constant';
import { OptionalFragmentCheckPlugin } from '../../plugins/optional-fragment-check/optional-fragment-check-plugin';
import { PluginUtilsService } from '../../../utils/plugin-utils.service';
import { ContextEditorTypes } from '../../models/base/context-editor-types';

@Injectable({
    providedIn: 'root'
})
export class FragmentCheckModelToEditorViewConverterService {

    protected pluginUtils: PluginUtilsService;

    constructor() {
        this.pluginUtils = new PluginUtilsService();
    }

    public configureConverters(conversion: Conversion): void {
        this.containerConversion(conversion);
        this.checkConversion(conversion);
        this.descriptionConversion(conversion);
        this.contentConversion(conversion);
    }

    private createWidgetElement(widgetElement, viewWriter, modelItem, optinalFragmentPluginContext, contextEditorTypeClause): ViewElement {
        const dataEmbeddedIn = modelItem.parent.getAttribute("data-embedded-in") !== undefined ? modelItem.parent.getAttribute("data-embedded-in").toString() : "undefined";
        const isEditable = this.pluginUtils.isElementInteractableInTargetEditor(dataEmbeddedIn, optinalFragmentPluginContext, contextEditorTypeClause);
        if(isEditable) {
            return toWidgetEditable(widgetElement, viewWriter);
        } else {
            return toWidget(widgetElement, viewWriter);
        }
    }

    private containerConversion(conversion: Conversion): void {
        conversion.for("editingDowncast").elementToElement({
            model: OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.model,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const id = modelItem.getAttribute(GlobalConstant.ATTRIBUTE_ID);
                const element = viewWriter.createContainerElement(
                    GlobalConstant.LABEL_DIV,
                    {
                        id,
                    });
                    viewWriter.addClass([OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.editionView, GlobalConstant.CONTAINER_CLASS_EDITION_VIEW], element);
                return toWidget(element, viewWriter);
            },
        });
    }

    private checkConversion(conversion: Conversion): void {
        conversion.for("editingDowncast").elementToElement({
            model: {
                name: OptionalFragmentCheckPlugin.MODEL_ENTITIES.check.model,
                attributes: [OptionalFragmentCheckPlugin.MODEL_ENTITIES.checkAttribute.model]
            },
            view: (modelItem: Element, { writer: viewWriter }) => {
                const id = modelItem.getAttribute('id');
                const isChecked = !!modelItem.getAttribute(OptionalFragmentCheckPlugin.MODEL_ENTITIES.checkAttribute.model);

                const viewElement = viewWriter.createContainerElement('input', {
                    class: OptionalFragmentCheckPlugin.MODEL_ENTITIES.check.editionView,
                    id,
                    type: 'checkbox',
                    ...(isChecked && { checked: true })  // Conditionally add the 'checked' attribute
                });

                return toWidget(viewElement, viewWriter);
            },
        });
    }

    private descriptionConversion(conversion: Conversion): void {
        const optinalFragmentPluginContext = OptionalFragmentCheckPlugin.contextEditor;
        const contextEditorTypeClause = ContextEditorTypes.CLAUSE;
        conversion.for("editingDowncast").elementToElement({
            model: OptionalFragmentCheckPlugin.MODEL_ENTITIES.description.model,
            view: (modelItem: any, { writer: viewWriter }) => {
                if(modelItem.parent.name !== OptionalFragmentCheckPlugin.MODEL_ENTITIES.container.model) {
                    return;
                }

                const widgetElement = viewWriter.createEditableElement("div", {
                    class: [OptionalFragmentCheckPlugin.MODEL_ENTITIES.description.editionView],
                });

                return this.createWidgetElement(widgetElement, viewWriter, modelItem, optinalFragmentPluginContext, contextEditorTypeClause);
            },
        });
    }

    private contentConversion(conversion: Conversion): void {
        const optinalFragmentPluginContext = OptionalFragmentCheckPlugin.contextEditor;
        const contextEditorTypeClause = ContextEditorTypes.CLAUSE;
        conversion.for("editingDowncast").elementToElement({
            model: OptionalFragmentCheckPlugin.MODEL_ENTITIES.content.editionView,
            view: (modelItem: any, { writer: viewWriter }) => {
                const widgetElement = viewWriter.createEditableElement("div", {
                    class: [OptionalFragmentCheckPlugin.MODEL_ENTITIES.content.editionView],
                });

                return this.createWidgetElement(widgetElement, viewWriter, modelItem, optinalFragmentPluginContext, contextEditorTypeClause);
            },
        });
    }
}
