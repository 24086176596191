import { Injectable } from '@angular/core';

declare let CKEDITOR: any;

@Injectable({
    providedIn: 'root'
})
export class CkeditorFormsSelectService {
    private currentSelect: any;

    public configureSelectDobleClick(editorInstance: any) {
        editorInstance.on('doubleclick', (evt) => {
            const element = evt.data.element;
            if (element.getName() !== 'select') {
                return;
            }

            this.currentSelect = element;
            editorInstance.getSelection().selectElement(element);
        });
    }

    public configureSelect(): void {
        if (CKEDITOR.dialog.exists('select')) {
            return;
        }
        const context = this;


        CKEDITOR.dialog.add('select', (editor) => {

            // Add a new option to a SELECT object (combo or list).
            function addOption(combo, optionText, optionValue, documentObject, index) {
                combo = getSelect(combo);
                let oOption;
                if (documentObject) {
                    oOption = documentObject.createElement('OPTION');
                } else {
                    oOption = document.createElement('OPTION');
                }

                if (combo && oOption && oOption.getName() === 'option') {
                    if (CKEDITOR.env.ie) {
                        if (!isNaN(parseInt(index, 10))) {
                            combo.$.options.add(oOption.$, index);
                        } else {
                            combo.$.options.add(oOption.$);
                        }

                        oOption.$.innerHTML = optionText.length > 0 ? optionText : '';
                        oOption.$.value = optionValue;
                    } else {
                        if (index !== null && index < combo.getChildCount()) {
                            combo.getChild(index < 0 ? 0 : index).insertBeforeMe(oOption);
                        } else {
                            combo.append(oOption);
                        }

                        oOption.setText(optionText.length > 0 ? optionText : '');
                        oOption.setValue(optionValue);
                    }
                } else {
                    return false;
                }

                return oOption;
            }

            // Remove all selected options from a SELECT object.
            function removeSelectedOptions(combo) {
                combo = getSelect(combo);

                // Save the selected index
                const iSelectedIndex = getSelectedIndex(combo);

                // Remove all selected options.
                for (let i = combo.getChildren().count() - 1; i >= 0; i--) {
                    if (combo.getChild(i).$.selected) {
                        combo.getChild(i).remove();
                    }
                }

                // Reset the selection based on the original selected index.
                setSelectedIndex(combo, iSelectedIndex);
            }

            // Modify option  from a SELECT object.
            function modifyOption(combo, index, title, value) {
                combo = getSelect(combo);
                if (index < 0) {
                    return false;
                }
                const child = combo.getChild(index);
                child.setText(title);
                child.setValue(value);
                return child;
            }

            function removeAllOptions(combo) {
                combo = getSelect(combo);
                while (combo.getChild(0) && combo.getChild(0).remove()) {

                }
            }

            // Moves the selected option by a number of steps (also negative).
            function changeOptionPosition(combo, steps, documentObject) {
                combo = getSelect(combo);
                const iActualIndex = getSelectedIndex(combo);
                if (iActualIndex < 0) {
                    return false;
                }

                let iFinalIndex = iActualIndex + steps;
                iFinalIndex = (iFinalIndex < 0) ? 0 : iFinalIndex;
                iFinalIndex = (iFinalIndex >= combo.getChildCount()) ? combo.getChildCount() - 1 : iFinalIndex;

                if (iActualIndex === iFinalIndex) {
                    return false;
                }

                let oOption = combo.getChild(iActualIndex);
                const sText = oOption.getText();
                const sValue = oOption.getValue();

                oOption.remove();

                oOption = addOption(combo, sText, sValue, (!documentObject) ? null : documentObject, iFinalIndex);
                setSelectedIndex(combo, iFinalIndex);
                return oOption;
            }

            function getSelectedIndex(combo) {
                combo = getSelect(combo);
                return combo ? combo.$.selectedIndex : -1;
            }

            function setSelectedIndex(combo, index) {
                combo = getSelect(combo);
                if (index < 0) {
                    return null;
                }

                const count = combo.getChildren().count();
                combo.$.selectedIndex = (index >= count) ? (count - 1) : index;
                return combo;
            }

            function getOptions(combo) {
                combo = getSelect(combo);
                return combo ? combo.getChildren() : false;
            }

            function getSelect(obj) {
                if (obj && obj.domId && obj.getInputElement().$) { // Dialog element.
                    return obj.getInputElement();
                } else if (obj && obj.$) {
                    return obj;
                }
                return false;
            }

            function correctOptions(element) {
                removeEmptyOptionsAndSetIds(element);

                const emptyOption = document.createElement('option');
                element.$.insertBefore(emptyOption, element.$.firstChild);
                element.$.firstChild.setAttribute('value', '');
                element.$.firstChild.setAttribute('selected', 'selected');
            }

            function removeEmptyOptionsAndSetIds(element) {
                const elementId = element.$.id;
                let i = 0;

                while (element.getChild(i)) {
                    if (element.getChild(i).$.value === '') {
                        element.getChild(i).remove();
                    } else {
                        element.getChild(i).$.value = `${elementId}-${i + 1}`;
                        i++;
                    }
                }
            }

            function getRandomId(element) {
                const randomId = 'I' + Math.floor(Math.random() * (10000 - 100 + 1) + 100);

                element.setAttribute('id', randomId);
                element.setAttribute('name', randomId);
            }

            return {
                title: 'Insertar desplegable',
                minWidth: CKEDITOR.env.ie ? 460 : 395,
                minHeight: CKEDITOR.env.ie ? 320 : 300,
                getModel(editorModel) {
                    const element = editorModel.getSelection().getSelectedElement();

                    if (element && element.getName() === 'select') {
                        return element;
                    }

                    return null;
                },
                onShow() {
                    this.setupContent('clear');

                    let element = this.getModel(this.getParentEditor());
                    if (!element) {
                        element = context.currentSelect;
                    }

                    if (element) {
                        context.currentSelect = null;
                        this.setupContent(element.getName(), element);

                        // Load Options into dialog.
                        const objOptions = getOptions(element);
                        for (let i = 0; i < objOptions.count(); i++) {
                            this.setupContent('option', objOptions.getItem(i));
                        }
                    }
                },
                onOk() {
                    const editorOnOk = this.getParentEditor();
                    let element = this.getModel(editorOnOk);
                    const isInsertMode = this.getMode(editorOnOk) === CKEDITOR.dialog.CREATION_MODE;

                    if (isInsertMode) {
                        element = editorOnOk.document.createElement('select');
                        getRandomId(element);
                        element.$.addEventListener('mousedown', (event) => {
                            element.$.setAttribute('contenteditable', 'true');
                            event.stopPropagation();
                        }, true);
                        element.$.addEventListener('change', (event) => {
                            const text = element.$.value;
                            for (const option of element.$.options) {
                                if (option.getAttribute('value') === text) {
                                    option.setAttribute('selected', 'selected');
                                } else {
                                    option.removeAttribute('selected');
                                }
                            }
                            element.$.setAttribute('value', text);
                            editorOnOk.fire('change');
                        }, true);
                    }

                    this.commitContent(element);
                    correctOptions(element);

                    if (isInsertMode) {
                        editorOnOk.insertElement(element);
                        if (CKEDITOR.env.ie) {
                            const sel = editorOnOk.getSelection();
                            const bms = sel.createBookmarks();
                            setTimeout(() => {
                                sel.selectBookmarks(bms);
                            }, 0);
                        }
                    }
                },
                contents: [{
                    id: 'info',
                    label: editor.lang.forms.select.selectInfo,
                    title: editor.lang.forms.select.selectInfo,
                    accessKey: '',
                    elements: [
                        {
                            type: 'html',
                            html: '<span>' + $localize`:@@ValoresDesplegable:Valores del desplegable` + '</span>'
                        },
                        {
                            type: 'hbox',
                            widths: ['100%', '0%', '100px'],
                            className: 'cke_dialog_forms_select_order',
                            children: [{
                                type: 'vbox',
                                children: [{
                                    id: 'txtOptName',
                                    type: 'text',
                                    label: editor.lang.forms.select.opText,
                                    style: 'width:100%',
                                    setup(name) {
                                        if (name === 'clear') {
                                            this.setValue('');
                                        }
                                    }
                                },
                                {
                                    type: 'select',
                                    id: 'cmbName',
                                    label: '',
                                    title: '',
                                    size: 5,
                                    style: 'width:350px;height:75px',
                                    items: [],
                                    onChange() {
                                        const dialog = this.getDialog();
                                        const values = dialog.getContentElement('info', 'cmbValue');
                                        const optName = dialog.getContentElement('info', 'txtOptName');
                                        const optValue = dialog.getContentElement('info', 'txtOptValue');
                                        const iIndex = getSelectedIndex(this);

                                        setSelectedIndex(values, iIndex);
                                        optName.setValue(this.getValue());
                                        optValue.setValue(values.getValue());
                                    },
                                    setup(name, element) {
                                        if (name === 'clear') {
                                            removeAllOptions(this);
                                        } else if (name === 'option') {
                                            addOption(this, element.getText(), element.getText(),
                                                this.getDialog().getParentEditor().document, 0);
                                        }
                                    },
                                    commit(element) {
                                        const dialog = this.getDialog();
                                        const optionsNames = getOptions(this);
                                        const optionsValues = getOptions(dialog.getContentElement('info', 'cmbValue'));

                                        removeAllOptions(element);

                                        for (let i = 0; i < optionsNames.count(); i++) {
                                            addOption(element, optionsNames.getItem(i).getValue(),
                                                optionsValues.getItem(i).getValue(), dialog.getParentEditor().document, i);
                                        }
                                    }
                                }]
                            },
                            {
                                type: 'vbox',
                                children: [{
                                    id: 'txtOptValue',
                                    type: 'text',
                                    label: editor.lang.forms.select.opValue,
                                    style: 'width:115px;display:none',
                                    setup(name) {
                                        if (name === 'clear') {
                                            this.setValue('');
                                        }
                                    }
                                },
                                {
                                    type: 'select',
                                    id: 'cmbValue',
                                    label: '',
                                    size: 5,
                                    style: 'width:115px;height:75px;display:none',
                                    items: [],
                                    onChange() {
                                        const dialog = this.getDialog();
                                        const names = dialog.getContentElement('info', 'cmbName');
                                        const optName = dialog.getContentElement('info', 'txtOptName');
                                        const optValue = dialog.getContentElement('info', 'txtOptValue');
                                        const iIndex = getSelectedIndex(this);

                                        setSelectedIndex(names, iIndex);
                                        optName.setValue(names.getValue());
                                        optValue.setValue(this.getValue());
                                    },
                                    setup(name, element) {
                                        if (name === 'clear') {
                                            removeAllOptions(this);
                                        } else if (name === 'option') {
                                            const oValue = element.getValue();
                                            addOption(this, oValue, oValue, this.getDialog().getParentEditor().document, 0);
                                        }
                                    }
                                }]
                            },
                            {
                                type: 'vbox',
                                padding: 5,
                                children: [{
                                    type: 'button',
                                    id: 'btnAdd',
                                    label: editor.lang.forms.select.btnAdd,
                                    title: editor.lang.forms.select.btnAdd,
                                    style: 'width:100%;',
                                    onClick() {
                                        // Add new option.
                                        const dialog = this.getDialog();
                                        const optName = dialog.getContentElement('info', 'txtOptName');
                                        const optValue = dialog.getContentElement('info', 'txtOptValue');
                                        const names = dialog.getContentElement('info', 'cmbName');
                                        const values = dialog.getContentElement('info', 'cmbValue');

                                        addOption(names, optName.getValue(), optName.getValue(), dialog.getParentEditor().document, 0);
                                        addOption(values, optName.getValue(), optName.getValue(), dialog.getParentEditor().document, 0);

                                        optName.setValue('');
                                        optValue.setValue('');
                                    }
                                },
                                {
                                    type: 'button',
                                    id: 'btnModify',
                                    label: editor.lang.forms.select.btnModify,
                                    title: editor.lang.forms.select.btnModify,
                                    style: 'width:100%;',
                                    onClick() {
                                        // Modify selected option.
                                        const dialog = this.getDialog();
                                        const optName = dialog.getContentElement('info', 'txtOptName');
                                        const optValue = dialog.getContentElement('info', 'txtOptValue');
                                        const names = dialog.getContentElement('info', 'cmbName');
                                        const values = dialog.getContentElement('info', 'cmbValue');
                                        const iIndex = getSelectedIndex(names);

                                        if (iIndex >= 0) {
                                            modifyOption(names, iIndex, optName.getValue(), optName.getValue());
                                            modifyOption(values, iIndex, optValue.getValue(), optValue.getValue());
                                        }
                                    }
                                },
                                {
                                    type: 'button',
                                    id: 'btnUp',
                                    style: 'width:100%;',
                                    label: editor.lang.forms.select.btnUp,
                                    title: editor.lang.forms.select.btnUp,
                                    onClick() {
                                        // Move up.
                                        const dialog = this.getDialog();
                                        const names = dialog.getContentElement('info', 'cmbName');
                                        const values = dialog.getContentElement('info', 'cmbValue');

                                        changeOptionPosition(names, -1, dialog.getParentEditor().document);
                                        changeOptionPosition(values, -1, dialog.getParentEditor().document);
                                    }
                                },
                                {
                                    type: 'button',
                                    id: 'btnDown',
                                    style: 'width:100%;',
                                    label: editor.lang.forms.select.btnDown,
                                    title: editor.lang.forms.select.btnDown,
                                    onClick() {
                                        // Move down.
                                        const dialog = this.getDialog();
                                        const names = dialog.getContentElement('info', 'cmbName');
                                        const values = dialog.getContentElement('info', 'cmbValue');

                                        changeOptionPosition(names, 1, dialog.getParentEditor().document);
                                        changeOptionPosition(values, 1, dialog.getParentEditor().document);
                                    }
                                },
                                {
                                    type: 'button',
                                    id: 'btnDelete',
                                    style: 'width:100%;',
                                    label: editor.lang.forms.select.btnDelete,
                                    title: editor.lang.forms.select.btnDelete,
                                    onClick() {
                                        // Delete option.
                                        const dialog = this.getDialog();
                                        const names = dialog.getContentElement('info', 'cmbName');
                                        const values = dialog.getContentElement('info', 'cmbValue');
                                        const optName = dialog.getContentElement('info', 'txtOptName');
                                        const optValue = dialog.getContentElement('info', 'txtOptValue');

                                        removeSelectedOptions(names);
                                        removeSelectedOptions(values);

                                        optName.setValue('');
                                        optValue.setValue('');
                                    }
                                }]
                            }]
                        }
                    ]
                }]
            };
        });
    }
}

