/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PermissionUserDocumentFolderDto } from './permissionUserDocumentFolderDto';
import { UserDTO } from './userDTO';

export interface PermissionsUserByUserDTO { 
    userId?: string;
    user?: UserDTO;
    isRegistered?: boolean;
    isGuest?: boolean;
    hasAdministratorPermission?: boolean;
    hasDocumentsPermission?: boolean;
    hasClausesAndTemplatesPermission?: boolean;
    hasTemplatesLLDPermission?: boolean;
    hasSmartformPermission?: boolean;
    documentFolderPermissions?: Array<PermissionUserDocumentFolderDto>;
}