export enum FileStatusApplicationCodes {
    CREATION = 1,
    ERROR = 2,
    PENDING = 3,
    INFORCE = 4,
    EXPIRED = 5,
    DRAFT = 6,
    AVAILABLE = 7
}

export interface FileStatusApplicationDto {
    code?: FileStatusApplicationCodes;
    description?: string;
}
