import { DocumentStatusCodes } from 'src/app/core/standard/enums/documents/document-status.enum';
import { DocumentFileDTO, DocumentFolderDto, DocumentsInfoDTO, MasterData, UserInfoDTO, UserPermissionsOnDocumentDTO } from 'src/app/api';
import { DocumentDTO } from 'src/app/api/model/documentDTO';
import { SignatureStatus } from 'src/app/api/model/signatureStatus';
import { UserDTO } from 'src/app/api/model/userDTO';
import { Observable } from 'rxjs';

export abstract class IDocumentListService {

    abstract getAuthorSurnameToOrder(surname: UserDTO): string;
    abstract getSignerSurnameToOrder(document: DocumentDTO): string;
    abstract getContractFolderToOrder(contractFolder: DocumentFolderDto, currentUserHasSameBuyer: boolean,
                                      isCommon: boolean): string;
    abstract getActionToOrder(userPermissions: UserPermissionsOnDocumentDTO): number;
    abstract getIconToOrder(document: DocumentDTO, userinfo: UserInfoDTO): number;
    abstract getIconTypeToOrder(document: DocumentDTO): number;
    abstract getExpirationDate(document: DocumentDTO, userInfo: UserInfoDTO): Date;
    abstract getTabToNavigate(state: DocumentStatusCodes): string;
    abstract all(): string;
    abstract myDocumentsTab(): string;
    abstract ourDocumentsTab(): string;
    abstract sharedWithMeTab(): string;
    abstract sharedByMeTab(): string;
    abstract getSignatureUserSigned(): SignatureStatus;
    abstract getSignatureUserUnsigned(): SignatureStatus;
    abstract getSignatureUserRejectd(): SignatureStatus;
    abstract getDocumentSignatureSigned(): SignatureStatus;
    abstract getDocumentSignatureUnsigned(): SignatureStatus;
    abstract getDocumentSignatureReject(): SignatureStatus;
    abstract getAlertsToOrder(document: DocumentDTO): number;
    abstract getDocumentStatusToOrder(documentStatus: MasterData): DocumentStatusCodes;
    abstract isDocumentEditable(document: DocumentDTO): boolean;
    abstract isDocumentFileEditable(documentFile: DocumentFileDTO): boolean;
    abstract isDocumentSignedWithSignatureService(document: DocumentDTO): boolean;
    abstract showChangeStateOption(document: DocumentDTO): boolean;
    abstract setDocumentInfo(documentInfo: DocumentsInfoDTO): void;
    abstract getDocumentInfoObservable(): Observable<DocumentsInfoDTO>;
    abstract retrieveDocumentInfo(): void;
}
