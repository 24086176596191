import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class HtmlService {

    protected radioHasOptionSelected(radioGroupElement: HTMLElement, idGroup: string): boolean {
        const allRadioButtons = radioGroupElement.querySelectorAll('input[name=' + idGroup + ']');
        for (let i = 0; i < allRadioButtons.length; i++) {
            if ((allRadioButtons[i] as HTMLInputElement).checked) {
                return true;
            }
        }

        return false;
    }
}
