import { Element, Range, TreeWalkerValue, Writer } from 'ckeditor5';
import { GlobalConstant } from '../../models/base/global-constant';
import { RadioElements, RadioModel } from '../../models/radio/radio-model';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';
import { RadioBaseCommand } from './radio-base-command';

interface RadioModelRefs {
    radioOptionModel: TreeWalkerValue | null;
    radioModel: TreeWalkerValue | null;
}

export default class AddOptionsCommand extends RadioBaseCommand {

    private readonly helpTextOptionMessage = $localize`:@@TextoAyudaOpcionRadioPlugin:Texto ayuda Opción`;
    private readonly contentOptionMessage = $localize`:@@ContenidoOpcionRadioPlugin:Contenido Opción`;

    public override execute(model: RadioModel, totalOptionsInRadioGroup: number): void {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);

            const radioModelRefs = this.findRadioModel(range, model);
            this.setEachOptionConfig(model, totalOptionsInRadioGroup, writer, radioModelRefs.radioOptionModel!);

            writer.setAttribute(RadioPlugin.MODEL_ENTITIES.optionCount.model, totalOptionsInRadioGroup, radioModelRefs.radioModel!.item);
        });
    }

    private setEachOptionConfig(model: RadioModel, totalOptionsInRadioGroup: number, writer: Writer, radioOptionModel: TreeWalkerValue) {
        for (let index = model.optionsCount!; index < totalOptionsInRadioGroup; index++) {

            const option = this.createOption(writer, index);
            const check = this.createCheck(writer, model);
            const description = this.createDescription(writer);
            const content = this.createContent(writer);
            const radioElements: RadioElements = { description, content, option, check };

            this.addOptionText(writer, index, radioElements);
            this.addOptionToWriter(writer, radioOptionModel, radioElements);
        }
    }

    private createContent(writer: Writer): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.content.model, {});
    }

    private createDescription(writer: Writer): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.description.model, {});
    }

    private createCheck(writer: Writer, model: RadioModel): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.input.model, {
            'dataName': model.id
        });
    }

    private createOption(writer: Writer, index: number): Element {
        return writer.createElement(RadioPlugin.MODEL_ENTITIES.option.model, {
            'position': index.toString()
        });
    }

    protected findRadioModel(range: Range, model: RadioModel): RadioModelRefs {
        const radioModelRefs: RadioModelRefs = { radioOptionModel: null, radioModel: null };
        for (const value of range.getWalker()) {
            if (value.item.hasAttribute(RadioPlugin.MODEL_ENTITIES.groupName.model) && value.item.getAttribute(RadioPlugin.MODEL_ENTITIES.groupName.model) === model.id && !radioModelRefs.radioOptionModel) {
                radioModelRefs.radioOptionModel = value;
            }
            if (value.item.hasAttribute(GlobalConstant.ATTRIBUTE_ID) && value.item.getAttribute(GlobalConstant.ATTRIBUTE_ID) === model.id && !radioModelRefs.radioModel) {
                radioModelRefs.radioModel = value;
            }
            if (!!radioModelRefs.radioOptionModel && !!radioModelRefs.radioModel) {
                return radioModelRefs;
            }
        }
        return null;
    }

    private addOptionText(writer: Writer, index: number, radioElements: RadioElements) {
        const descriptionDefaultText = writer.createText(`(${this.helpTextOptionMessage} ${index + 1})`);
        const descriptionDefaultTextElement = writer.createElement('paragraph');
        const contentDefaultTextElement = writer.createElement('paragraph');
        const contentDefaultText = writer.createText(`${this.contentOptionMessage} ${index + 1}`);

        writer.append(descriptionDefaultText, descriptionDefaultTextElement);
        writer.append(descriptionDefaultTextElement, radioElements.description);
        writer.append(contentDefaultText, contentDefaultTextElement);
        writer.append(contentDefaultTextElement, radioElements.content);
    }

    private addOptionToWriter(writer: Writer, radioOptionModel: any, radioElements: RadioElements) {
        writer.append(radioElements.check, radioElements.option);
        writer.append(radioElements.description, radioElements.option);
        writer.append(radioElements.content, radioElements.option);
        writer.append(radioElements.option, radioOptionModel!.item.parent!.parent!);
    }
}
