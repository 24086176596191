import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';

export function valueNotInGroupValidator(group: string[], valueThatCanBeRepeated: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = (control.value as string);
        if (value === null) {
            return null;
        }
        if (valueThatCanBeRepeated && value.trim().toLowerCase() === valueThatCanBeRepeated.trim().toLowerCase()) {
            return null;
        }
        if (group.find(g => g.trim() === value.trim()) !== undefined) {
            return {valueNotInGroup: {value: control.value}};
        }
        return null;
    };
}

