import { Injectable } from '@angular/core';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';
import { Conversion, EventInfo, UpcastDispatcher, ViewElement } from 'ckeditor5';
import { RadioDataViewUtilsService } from '../../utils/radio/radio-data-view-utils.service';

@Injectable({
    providedIn: 'root'
})
export class RadioDataViewToModelConverterService {

    private dataUtilsService: RadioDataViewUtilsService;

    constructor() {
        this.dataUtilsService = new RadioDataViewUtilsService();
    }

    public configureConverters(conversion: Conversion) {
        this.containerConversion(conversion);
        this.attributeOptionInContainerConversion(conversion);
        this.attributeCheckedConversion(conversion);
        this.optionAndContentConversion(conversion);
        this.inputConversion(conversion);
        this.descriptionConversion(conversion);
    }

    private descriptionConversion(conversion: Conversion) {
        conversion.for("upcast").elementToElement({
            view: {
                name: "div",
                classes: [RadioPlugin.MODEL_ENTITIES.description.dataView],
            },
            model: (viewElement: ViewElement, { writer }) => {
                return this.dataUtilsService.createDescriptionModelFromDataView(viewElement, writer);
            },
        });
    }

    private optionAndContentConversion(conversion: Conversion) {
        conversion.for("upcast").add((dispatcher: UpcastDispatcher) => {
            dispatcher.on('element:div', (_evt: EventInfo, data, conversionApi) => {
                const {
                    consumable, writer, safeInsert, convertItem, convertChildren, updateConversionResult
                } = conversionApi;

                const { viewItem } = data;

                if (!viewItem.hasClass(RadioPlugin.MODEL_ENTITIES.option.dataView) || viewItem.childCount < 2) {
                    return;
                }

                const [firstChildItem, secondChildItem] = [viewItem.getChild(0), viewItem.getChild(1)];

                if (!firstChildItem.is('element', 'input') || !consumable.test(firstChildItem, { name: true, classes: RadioPlugin.MODEL_ENTITIES.input.dataView })) {
                    return;
                }

                const position = this.dataUtilsService.getPositionFromOptionView(viewItem);
                const optionModelElement = writer.createElement(RadioPlugin.MODEL_ENTITIES.option.model, { position } );

                if (!safeInsert(optionModelElement, data.modelCursor)) {
                    return;
                }

                consumable.consume(viewItem, { name: true, classes: RadioPlugin.MODEL_ENTITIES.option.dataView });
                const inputObject = convertItem(firstChildItem, optionModelElement);
                consumable.consume(firstChildItem, { name: true, classes: RadioPlugin.MODEL_ENTITIES.input.dataView });

                if (secondChildItem.is('element', 'div') && secondChildItem.hasClass(RadioPlugin.MODEL_ENTITIES.description.dataView)
                        && consumable.test(secondChildItem, { name: true, classes: RadioPlugin.MODEL_ENTITIES.description.dataView })) {
                    convertItem(secondChildItem, inputObject.modelCursor);
                    consumable.consume(secondChildItem, { name: true, classes: RadioPlugin.MODEL_ENTITIES.description.dataView });
                }

                const contentElement = writer.createElement(RadioPlugin.MODEL_ENTITIES.content.model);

                writer.append(contentElement, optionModelElement);
                convertChildren(viewItem, contentElement);
                updateConversionResult(optionModelElement, data);
            }, { priority: 'high' });
        });
    }

    private attributeCheckedConversion(conversion: Conversion) {
        conversion.for('upcast').attributeToAttribute({
            view: RadioPlugin.MODEL_ENTITIES.checked.dataView,
            model: RadioPlugin.MODEL_ENTITIES.checked.model
        });
    }

    private inputConversion(conversion: Conversion): void {
        conversion.for("upcast").elementToElement({
            view: {
                name: "input",
                classes: [RadioPlugin.MODEL_ENTITIES.input.dataView],
            },
            model: (viewElement: ViewElement, { writer }) => {
                return this.dataUtilsService.createRadioModelFromDataView(viewElement, writer);
            },
        });
    }

    private attributeOptionInContainerConversion(conversion: Conversion) {
        conversion.for('upcast').attributeToAttribute({
            view: RadioPlugin.MODEL_ENTITIES.optionCount.dataView,
            model: RadioPlugin.MODEL_ENTITIES.optionCount.model
        });
    }

    private containerConversion(conversion: Conversion) {
        conversion.for("upcast").elementToElement({
            view: {
                name: "div",
                classes: [RadioPlugin.MODEL_ENTITIES.container.dataView],
            },
            model: (viewElement: ViewElement, { writer }) => {
                return this.dataUtilsService.createModelFromDataView(viewElement, writer);
            },
        });
    }
}
