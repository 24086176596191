import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable }  from 'rxjs';
import { Configuration } from '../configuration';
import { ApiOperation, ApiProcessorService, ApiRequest } from './api-processor.service';
import { ClauseVersionDTO } from '../model/models';
import { IJsonPatchDocument } from '../model/json-patch.model';

@Injectable()
export class ClauseVersionService {

    protected basePath = '/api/manager-management/clause-versions';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(private readonly apiProcessorService: ApiProcessorService) {
    }

    public createClauseVersion(clauseVersion: ClauseVersionDTO): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = clauseVersion;
        apiRequest.endpointUrl = this.basePath;

        return this.apiProcessorService.proccessRequest<ClauseVersionDTO[]>(apiRequest);
    }

    public patchClauseVersion(clauseVersionId: string, jsonPatchDocument: Array<IJsonPatchDocument>): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PATCH;
        apiRequest.body = jsonPatchDocument;
        apiRequest.endpointUrl = `${this.basePath}/${clauseVersionId}`;

        return this.apiProcessorService.proccessRequest<ClauseVersionDTO[]>(apiRequest);
    }

    public getClauseVersion(clauseVersionId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}/${clauseVersionId}`;

        return this.apiProcessorService.proccessRequest<ClauseVersionDTO[]>(apiRequest);
    }
}
