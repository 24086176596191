export enum NodeTreeNodeType {
    Folder = 'folder',
    Smartform = 'smartform',
    Template = 'template',
    Clause = 'clause',
    MyDocuments = 'mydocuments',
    OurDocuments = 'ourdocuments',
    Shared = 'shared',
    ToCFile = 'tocfile'
}
