import { Injectable } from '@angular/core';

declare let CKEDITOR: any;

@Injectable({
  providedIn: 'root'
})
export class CkeditorFormsRadioService {


  configureRadio() {
    if(CKEDITOR.dialog.exists('radio')){
      return;
    }

    CKEDITOR.dialog.add( 'radio', ( editor ) => {
      return {
        title: 'Insertar párrafos alternativos',
        minWidth: 350,
        minHeight: 50,
        getModel(editorModel) {
          const element = editorModel.getSelection().getSelectedElement();

          if ( element && element.getName() === 'input' && element.getAttribute( 'type' ) === 'radio' ) {
            return element;
          }

          return null;
        },
        onShow() {
          const element = this.getModel( this.getParentEditor() );
          if ( element ) {
            this.setupContent( element );
          }
        },
        onOk() {
          const editorOnOk = this.getParentEditor();
          let element = this.getModel( editorOnOk );

          if ( !element ) {
            element = editorOnOk.document.createElement( 'input' );
            element.setAttribute( 'type', 'radio' );

            element.$.addEventListener('click', (event) => {

              event.stopPropagation();
            }, true);

            element.$.addEventListener('change', (event) => {
              if(event.returnValue){
                element.setAttribute('checked', 'true' );
              }else{
                element.removeAttribute( 'checked' );
              }
            }, true);


            editorOnOk.insertElement( element );


          }

          this.commitContent( { element } );
        },
        contents: [ {
          id: 'info',
          label: editor.lang.forms.checkboxAndRadio.radioTitle,
          title: editor.lang.forms.checkboxAndRadio.radioTitle,
          elements: [{
            id: 'name',
            type: 'text',
            label: 'Incluir denominación del grupo de opciones',
            'default': '',
            accessKey: 'N',
            setup: function( element ) {
              this.setValue( element.data( 'cke-saved-name' ) || element.getAttribute( 'name' ) || '' );
            },
            commit: function( data ) {
              var element = data.element;

              if ( this.getValue() )
              {
                element.data( 'cke-saved-name', this.getValue() );
                element.setAttribute( 'name', this.getValue() );
              }
              else {
                element.data( 'cke-saved-name', false );
                element.removeAttribute( 'name' );
              }
            }
          },
          {
            id: 'checked',
            type: 'checkbox',
            label: editor.lang.forms.checkboxAndRadio.selected,
            default: '',
            accessKey: 'S',
            value: 'checked',
            setup( element ) {
              this.setValue( element.getAttribute( 'checked' ) );
            },
            commit( data ) {
              const element = data.element;

              if ( !CKEDITOR.env.ie ) {
                const value = this.getValue();
                // Blink/Webkit needs to change checked property, not attribute. (https://dev.ckeditor.com/ticket/12465)
                if ( CKEDITOR.env.webkit ) {
                  element.$.checked = value;
                }

                if ( value ) {
                  element.setAttribute( 'checked', 'checked' );
                } else {
                  element.removeAttribute( 'checked' );
                }
              } else {
                const isElementChecked = element.getAttribute( 'checked' );
                const isChecked = !!this.getValue();

                if ( isElementChecked !== isChecked ) {
                  const replace = CKEDITOR.dom.element.createFromHtml( '<input type="radio"' + ( isChecked ? ' checked="checked"' : '' ) +
                    '></input>', editor.document );
                  element.copyAttributes( replace, { type: 1, checked: 1 } );
                  replace.replace( element );

                  // Ugly hack which fix IE issues with radiobuttons (#834).
                  if ( isChecked ) {
                    replace.setAttribute( 'checked', 'checked' );
                  }

                  editor.getSelection().selectElement( replace );
                  data.element = replace;
                }
              }
            }
          },
          {
            id: 'required',
            type: 'checkbox',
            label: editor.lang.forms.checkboxAndRadio.required,
            default: '',
            accessKey: 'Q',
            value: 'required',
            setup: CKEDITOR.plugins.forms._setupRequiredAttribute,
            commit( data ) {
              const element = data.element;
              if ( this.getValue() ) {
                element.setAttribute( 'required', 'required' );
              } else {
                element.removeAttribute( 'required' );
              }
            }
          } ]
        } ]
      };
    } );
  }


}
