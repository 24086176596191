import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LegalAlertsSearchService {

  private searchTerm$ = new Subject<string>();

  constructor() {
   }

  public doSearch(searchTerm: string) {
    this.searchTerm$.next(searchTerm);
  }

  public getSearchSubscription(): Observable<string> {
    return this.searchTerm$.asObservable();
  }
}
