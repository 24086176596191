/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ErrorTemplateOperationDTO } from '../model/errorTemplateOperationDTO';
import { TemplateDTO } from '../model/templateDTO';
import { TemplateDTOOperation } from '../model/templateDTOOperation';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class TemplateService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesDelete(id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public templatesDelete(id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public templatesDelete(id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public templatesDelete(id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/templates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesErrorDescriptionGet(observe?: 'body', reportProgress?: boolean): Observable<ErrorTemplateOperationDTO>;
    public templatesErrorDescriptionGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ErrorTemplateOperationDTO>>;
    public templatesErrorDescriptionGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ErrorTemplateOperationDTO>>;
    public templatesErrorDescriptionGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ErrorTemplateOperationDTO>('get',`${this.basePath}/templates/error-description`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param folderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesGet(folderId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TemplateDTO>>;
    public templatesGet(folderId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TemplateDTO>>>;
    public templatesGet(folderId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TemplateDTO>>>;
    public templatesGet(folderId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (folderId !== undefined && folderId !== null) {
            queryParameters = queryParameters.set('folderId', <any>folderId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TemplateDTO>>('get',`${this.basePath}/templates`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param folderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesIdFolderFolderIdPut(id: string, folderId: string, observe?: 'body', reportProgress?: boolean): Observable<TemplateDTO>;
    public templatesIdFolderFolderIdPut(id: string, folderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateDTO>>;
    public templatesIdFolderFolderIdPut(id: string, folderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateDTO>>;
    public templatesIdFolderFolderIdPut(id: string, folderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling templatesIdFolderFolderIdPut.');
        }

        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling templatesIdFolderFolderIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TemplateDTO>('put',`${this.basePath}/templates/${encodeURIComponent(String(id))}/folder/${encodeURIComponent(String(folderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<TemplateDTO>;
    public templatesIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateDTO>>;
    public templatesIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateDTO>>;
    public templatesIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling templatesIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TemplateDTO>('get',`${this.basePath}/templates/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesIdPatch(id: string, body?: Array<TemplateDTOOperation>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public templatesIdPatch(id: string, body?: Array<TemplateDTOOperation>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public templatesIdPatch(id: string, body?: Array<TemplateDTOOperation>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public templatesIdPatch(id: string, body?: Array<TemplateDTOOperation>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling templatesIdPatch.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<any>('patch',`${this.basePath}/templates/${encodeURIComponent(String(id))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesPost(body?: TemplateDTO, observe?: 'body', reportProgress?: boolean): Observable<TemplateDTO>;
    public templatesPost(body?: TemplateDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateDTO>>;
    public templatesPost(body?: TemplateDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateDTO>>;
    public templatesPost(body?: TemplateDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TemplateDTO>('post',`${this.basePath}/templates`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesPut(body?: TemplateDTO, observe?: 'body', reportProgress?: boolean): Observable<TemplateDTO>;
    public templatesPut(body?: TemplateDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateDTO>>;
    public templatesPut(body?: TemplateDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateDTO>>;
    public templatesPut(body?: TemplateDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TemplateDTO>('put',`${this.basePath}/templates`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesSaveAndReviewedLldPendingPut(body?: TemplateDTO, observe?: 'body', reportProgress?: boolean): Observable<TemplateDTO>;
    public templatesSaveAndReviewedLldPendingPut(body?: TemplateDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateDTO>>;
    public templatesSaveAndReviewedLldPendingPut(body?: TemplateDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateDTO>>;
    public templatesSaveAndReviewedLldPendingPut(body?: TemplateDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TemplateDTO>('put',`${this.basePath}/templates/save-and-reviewed-lld-pending`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesSaveAndReviewedPut(body?: TemplateDTO, observe?: 'body', reportProgress?: boolean): Observable<TemplateDTO>;
    public templatesSaveAndReviewedPut(body?: TemplateDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateDTO>>;
    public templatesSaveAndReviewedPut(body?: TemplateDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateDTO>>;
    public templatesSaveAndReviewedPut(body?: TemplateDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<TemplateDTO>('put',`${this.basePath}/templates/save-and-reviewed`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param searchTerm
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesSearchGet(searchTerm?: string): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchTerm !== undefined && searchTerm !== null) {
            queryParameters = queryParameters.set('searchTerm', <any>searchTerm);
        }

        let headers = this.defaultHeaders;

        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.request<Array<TemplateDTO>>('get',`${this.basePath}/templates/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers
            }
        );
    }

    /**
     *
     *
     * @param name
     * @param folderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesSearchNameNameFolderIdFolderIdGet(name: string, folderId: string, observe?: 'body', reportProgress?: boolean): Observable<TemplateDTO>;
    public templatesSearchNameNameFolderIdFolderIdGet(name: string, folderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateDTO>>;
    public templatesSearchNameNameFolderIdFolderIdGet(name: string, folderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateDTO>>;
    public templatesSearchNameNameFolderIdFolderIdGet(name: string, folderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling templatesSearchNameNameFolderIdFolderIdGet.');
        }

        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling templatesSearchNameNameFolderIdFolderIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TemplateDTO>('get',`${this.basePath}/templates/search/name/${encodeURIComponent(String(name))}/folderId/${encodeURIComponent(String(folderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesUpdateMetadatasPost(observe?: 'body', reportProgress?: boolean): Observable<any>;
    public templatesUpdateMetadatasPost(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public templatesUpdateMetadatasPost(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public templatesUpdateMetadatasPost(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/templates/update-metadatas`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
