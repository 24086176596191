import { Injectable } from '@angular/core';
import { Observable, forkJoin, BehaviorSubject } from 'rxjs';
import { DocumentDTO, DocumentFolderService, OperationType, UserInfoDTO } from 'src/app/api';
import { DocumentFolderDtoOperation } from 'src/app/api/model/documentFolderDtoOperation';
import { FileFlatNode } from 'src/app/shared/components/ctbox-tree/models/file-flat-node.model';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { IDocumentsFolderTreeService } from '../documents-folder-tree/documents-folder-tree.service.interface';
import { IDocumentsFolderService } from './documents-folder.service.interface';

@Injectable({
    providedIn: 'root'
})
export class DocumentsFolderService implements IDocumentsFolderService {

    private currentFolderAndOwnerId = new BehaviorSubject<{ folderId: string, ownerId: string }>(
        { folderId: '', ownerId: '' });
    private currentFolderName = new BehaviorSubject<string>('');
    private noPermissionId = '00000000-0000-0000-0000-000000000000';

    constructor(private documentFolderService: DocumentFolderService,
                private documentsFolderTreeService: IDocumentsFolderTreeService) { }

    public setCurrentFolderName(folderName: string): void {
        return this.currentFolderName.next(folderName);
    }

    public getCurrentFolderName(): Observable<string> {
        return this.currentFolderName.asObservable();
    }

    public setCurrentFolderAndOwnerId(folderId: string, ownerId: string): void {
        this.currentFolderAndOwnerId.next({ folderId, ownerId });
    }

    public getCurrentFolderId(): string {
        return this.currentFolderAndOwnerId.value?.folderId;
    }

    public getCurrentOwnerId(): string {
        return this.currentFolderAndOwnerId.value?.ownerId;
    }

    public getOperationReplaceDocumentsFolder(valueToChange: string, newValue: any): DocumentFolderDtoOperation[] {
        const operations: DocumentFolderDtoOperation[] = [];
        const operation: DocumentFolderDtoOperation = {
            operationType: OperationType.NUMBER_0,
            op: 'replace',
            path: valueToChange,
            value: newValue
        };

        operations.push(operation);
        return operations;
    }

    public loadFolderTree(myDocumentsRootNodeActions: NodeTreeActionType[], ourDocumentsRootNodeActions: NodeTreeActionType[],
                          sharedRootNodeActions: NodeTreeActionType[], nodeActions: NodeTreeActionType[],
                          userInfoGuid: string): Observable<FileNode[]> {
        const obs: Observable<FileNode[]> = new Observable<FileNode[]>((observer) => {
            const myDocumentsFolderObservable = this.documentFolderService.documentFoldersGet(userInfoGuid);
            const ourDocumentsFolderObservable = this.documentFolderService.documentFoldersGet();

            forkJoin([myDocumentsFolderObservable, ourDocumentsFolderObservable]).subscribe(([myDocumentsFolder, ourDocumentsFolder]) => {
                const myDocumentsNode = this.documentsFolderTreeService.createMyDocumentsNode(myDocumentsFolder,
                    myDocumentsRootNodeActions, nodeActions, userInfoGuid);
                const ourDocumentsNode = this.documentsFolderTreeService.createOurDocumentsNode(ourDocumentsFolder,
                    ourDocumentsRootNodeActions, nodeActions);
                const documentsFolderTree = this.documentsFolderTreeService.createCompleteTree(myDocumentsNode, ourDocumentsNode,
                    sharedRootNodeActions);

                observer.next(documentsFolderTree);
            });
        });

        return obs;
    }

    public isCurrentFolderValid(node: FileFlatNode): boolean {
        return node && node.id && node.id !== this.documentsFolderTreeService.getMyDocumentsNodeId() &&
            node.id !== this.documentsFolderTreeService.getOurDocumentsNodeId() &&
            node.id !== this.documentsFolderTreeService.getSharedNodeId();
    }

    public getCorrectedFolderName(originalFolderName: string, hasFolderAccess: boolean, isCommon: boolean): string {
        if (!hasFolderAccess) {
            return this.documentsFolderTreeService.getSharedNodeName();
        }

        if (originalFolderName === '' || originalFolderName === null) {
            if (isCommon) {
                return this.documentsFolderTreeService.getOurDocumentsNodeName();
            }
            return this.documentsFolderTreeService.getMyDocumentsNodeName();
        }

        return originalFolderName;
    }

    public hasDocumenttAccessToContractFolder(document: DocumentDTO, userInfo: UserInfoDTO): boolean {
        return document.hasCurrentUserSameBuyer &&
            ((document.folderOwnerUser !== null && userInfo &&  userInfo.guid === document.folderOwnerUserId) ||
                document.documentFolderId !== this.noPermissionId);
    }
}
