import { Component, OnInit, ViewEncapsulation, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { ExportFileExtension } from 'src/app/api/model/filesExportDto';

@Component({
    selector: 'app-ctbox-export-result',
    templateUrl: './ctbox-export-result.component.html',
    styleUrls: ['./ctbox-export-result.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CtboxExportResultComponent implements OnInit {

    @Input() hasSelectedText = '';

    public get exportFileExtension(): typeof ExportFileExtension {
        return ExportFileExtension;
    }

    public exportResultForm: UntypedFormGroup;

    public exportSettings = {
        exportFileExtension: ExportFileExtension.Docx
    };

    constructor() {
    }

    public ngOnInit() {
        this.initFormModel();
    }

    public initFormModel(): void {
        this.exportResultForm = new UntypedFormGroup(
            {
                types: new UntypedFormControl(null, [Validators.required])
            }
        );
    }

    public typeChange(event: MatRadioChange){
        const exportFileExtension: number = +event.value;
        this.exportSettings.exportFileExtension = exportFileExtension;
    }
}
