<div class="resend">
    <div class="resend-intro">
        <span i18n="@@Quieres">¿Quieres</span>
        <span i18n="@@VolverAEnviarAFirmar" class="bolder"> volver a enviar a firmar </span>
        <span i18n="@@ALasMismasPersonas"> a las mismas personas?</span>
    </div>
    <div class="resend-selected">
        <div class="resend-data">
            <p class="resend-title"><ctbox-button buttonIcon="timer" buttonColor="info"></ctbox-button> <span i18n="@@FechaExpiracion">Fecha de expiración: </span></p>
            <p class="resend-data date">{{expirationDate | date: 'dd/MM/yyyy'}}</p>
        </div>
    </div>
</div>