/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ContractDTO } from '../model/contractDTO';
import { ErrorDocumentOperationDTO } from '../model/errorDocumentOperationDTO';
import { RelatedDocumentsContractDTO } from '../model/relatedDocumentsContractDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ContractService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsDelete(id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public contractsDelete(id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public contractsDelete(id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public contractsDelete(id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/contracts`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsErrorDescriptionGet(observe?: 'body', reportProgress?: boolean): Observable<ErrorDocumentOperationDTO>;
    public contractsErrorDescriptionGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ErrorDocumentOperationDTO>>;
    public contractsErrorDescriptionGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ErrorDocumentOperationDTO>>;
    public contractsErrorDescriptionGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ErrorDocumentOperationDTO>('get',`${this.basePath}/contracts/error-description`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param folderId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsIdFolderFolderIdPut(id: string, folderId: string, observe?: 'body', reportProgress?: boolean): Observable<ContractDTO>;
    public contractsIdFolderFolderIdPut(id: string, folderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContractDTO>>;
    public contractsIdFolderFolderIdPut(id: string, folderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContractDTO>>;
    public contractsIdFolderFolderIdPut(id: string, folderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contractsIdFolderFolderIdPut.');
        }

        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling contractsIdFolderFolderIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ContractDTO>('put',`${this.basePath}/contracts/${encodeURIComponent(String(id))}/folder/${encodeURIComponent(String(folderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<ContractDTO>;
    public contractsIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContractDTO>>;
    public contractsIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContractDTO>>;
    public contractsIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contractsIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ContractDTO>('get',`${this.basePath}/contracts/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsIdRelatedDocumentsGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<RelatedDocumentsContractDTO>;
    public contractsIdRelatedDocumentsGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<RelatedDocumentsContractDTO>>;
    public contractsIdRelatedDocumentsGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<RelatedDocumentsContractDTO>>;
    public contractsIdRelatedDocumentsGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contractsIdRelatedDocumentsGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<RelatedDocumentsContractDTO>('get',`${this.basePath}/contracts/${encodeURIComponent(String(id))}/related-documents`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsIdToCommonRootFolderPut(id: string, observe?: 'body', reportProgress?: boolean): Observable<ContractDTO>;
    public contractsIdToCommonRootFolderPut(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContractDTO>>;
    public contractsIdToCommonRootFolderPut(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContractDTO>>;
    public contractsIdToCommonRootFolderPut(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contractsIdToCommonRootFolderPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ContractDTO>('put',`${this.basePath}/contracts/${encodeURIComponent(String(id))}/to-common-root-folder`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsIdToRootFolderPut(id: string, observe?: 'body', reportProgress?: boolean): Observable<ContractDTO>;
    public contractsIdToRootFolderPut(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContractDTO>>;
    public contractsIdToRootFolderPut(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContractDTO>>;
    public contractsIdToRootFolderPut(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contractsIdToRootFolderPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ContractDTO>('put',`${this.basePath}/contracts/${encodeURIComponent(String(id))}/to-root-folder`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param data 
     * @param file 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsNotEditableContractPost(data?: string, file?: Blob, observe?: 'body', reportProgress?: boolean): Observable<ContractDTO>;
    public contractsNotEditableContractPost(data?: string, file?: Blob, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContractDTO>>;
    public contractsNotEditableContractPost(data?: string, file?: Blob, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContractDTO>>;
    public contractsNotEditableContractPost(data?: string, file?: Blob, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'multipart/form-data'
        ];

        const canConsumeForm = this.canConsumeForm(consumes);

        let formParams: { append(param: string, value: any): void; };
        let useForm = false;
        let convertFormParamsToString = false;
        // use FormData to transmit files using content-type "multipart/form-data"
        // see https://stackoverflow.com/questions/4007969/application-x-www-form-urlencoded-or-multipart-form-data
        useForm = canConsumeForm;
        if (useForm) {
            formParams = new FormData();
        } else {
            formParams = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        }

        if (data !== undefined) {
            formParams = formParams.append('data', <any>data) as any || formParams;
        }
        if (file !== undefined) {
            formParams = formParams.append('file', <any>file) as any || formParams;
        }

        return this.httpClient.request<ContractDTO>('post',`${this.basePath}/contracts/notEditableContract`,
            {
                body: convertFormParamsToString ? formParams.toString() : formParams,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param originTemplateId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsOriginTemplateIdPost(originTemplateId: string, body?: ContractDTO, observe?: 'body', reportProgress?: boolean): Observable<ContractDTO>;
    public contractsOriginTemplateIdPost(originTemplateId: string, body?: ContractDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContractDTO>>;
    public contractsOriginTemplateIdPost(originTemplateId: string, body?: ContractDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContractDTO>>;
    public contractsOriginTemplateIdPost(originTemplateId: string, body?: ContractDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (originTemplateId === null || originTemplateId === undefined) {
            throw new Error('Required parameter originTemplateId was null or undefined when calling contractsOriginTemplateIdPost.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ContractDTO>('post',`${this.basePath}/contracts/${encodeURIComponent(String(originTemplateId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsPost(body?: ContractDTO, observe?: 'body', reportProgress?: boolean): Observable<ContractDTO>;
    public contractsPost(body?: ContractDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContractDTO>>;
    public contractsPost(body?: ContractDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContractDTO>>;
    public contractsPost(body?: ContractDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ContractDTO>('post',`${this.basePath}/contracts`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsPut(body?: ContractDTO, observe?: 'body', reportProgress?: boolean): Observable<ContractDTO>;
    public contractsPut(body?: ContractDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ContractDTO>>;
    public contractsPut(body?: ContractDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ContractDTO>>;
    public contractsPut(body?: ContractDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<ContractDTO>('put',`${this.basePath}/contracts`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
