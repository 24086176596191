<div *ngIf="state" class="notification {{ additionalClass }}" [ngClass]="state" role="alert">
    <mat-icon *ngIf="iconClass">{{ iconClass }}</mat-icon>
    <div class="notification-container">
        <div *ngIf="title" class="notification-title {{ additionalClass }}">{{title}}</div>
        <div *ngIf="content" class="notification-content {{ additionalClass }}">
            <a *ngIf="routerLink && content" class="rlink" (click)="goToLink()" [routerLink]="[]">
                {{ content }}
            </a>
            <a *ngIf="url && content" class="link" (click)="goToLink()">
                {{ content }}
            </a>
            <span class="notification-label"> {{ label }} </span>
            <ng-container *ngIf="!url && !routerLink">
                {{ content }}
            </ng-container>
        </div>
    </div>
</div>