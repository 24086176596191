import { Command } from "ckeditor5";
import { ClausesPlugin } from "../../plugins/clause/clauses-plugin";

export default class ClauseGoToCommand extends Command {

    public override execute(idClausula?: string): void {
        if (!idClausula || !idClausula.includes('id_')) {
            return;
        }
        const [, guid] = idClausula.split('_');
        ClausesPlugin.clausesNavigationService.goToEditionClause(guid);
    }
}
