import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export abstract class IDateService {

    abstract addDays(date: Date, days: number): Date;

    abstract addMonths(date: Date, months: number): Date;

    abstract addYears(date: Date, years: number): Date;

    abstract getCurrentDateWithoutTime(): Date;

    abstract getDateWithoutTime(date: Date): Date;

    abstract formatDate(date: any): Date;
}
