import { View, Locale, ViewCollection, FocusableView } from 'ckeditor5';
import { Directive, Inject } from '@angular/core';
import { SelectMultipleViewModel } from '../../models/select/select-multiple-view.model';

@Directive({
    selector: 'app-select-option-view',
})
export default class SelectOptionView extends View {

    public declare class: string | null;
    public readonly children: ViewCollection<FocusableView>;
    public declare _role: string | null;
    public declare _ariaLabelledBy: string | null;
    public declare isSelected: boolean;
    public declare isVisible: boolean;

    private currentValue = '';

    constructor(
        @Inject(Locale) locale?: Locale,
        @Inject(String) option?: string
    ) {
        super(locale);
        this.children = this.createCollection();
        this.currentValue = option;

        this.initializeProperties();
        this.initializeAccessibility();
        this.initializeTemplate(option);
    }

    public get value(): string {
        return this.currentValue;
    }

    private initializeProperties(model?: SelectMultipleViewModel): void {
		this.set('class', model?.class || null);
    }

    private initializeAccessibility( ): void {
        this.set('_role', null);
        this.set('_ariaLabelledBy', null);
    }

    private initializeTemplate( option: string): void {
        const bind = this.bindTemplate;
        this.set( 'isVisible', true );
        this.set( 'isSelected', false );
        bind.if( 'isSelected', 'selected' )

        this.setTemplate({
            tag: 'option',
            attributes: {
                class: [
                    'ck',
                    'ck-select-option__item',
                    bind.if( 'isVisible', 'ck-hidden', value => !value ),
                    bind.if( 'isSelected', 'ck-select-option__item-selected' )
                ],
                value: option,
            },
            children: [
                {
                    text: option
                }
            ],
        });
    }
}
