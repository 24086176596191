/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { PermissionUserDocumentFolderDto } from '../model/permissionUserDocumentFolderDto';
import { PermissionsUserByUserDTO } from '../model/permissionsUserByUserDTO';
import { UserDTO } from '../model/userDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class PermissionUserService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public permissionUserAdminContactGet(observe?: 'body', reportProgress?: boolean): Observable<Array<UserDTO>>;
    public permissionUserAdminContactGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserDTO>>>;
    public permissionUserAdminContactGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserDTO>>>;
    public permissionUserAdminContactGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserDTO>>('get',`${this.basePath}/permissionUser/admin-contact`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public permissionUserAllGet(observe?: 'body', reportProgress?: boolean): Observable<Array<PermissionsUserByUserDTO>>;
    public permissionUserAllGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PermissionsUserByUserDTO>>>;
    public permissionUserAllGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PermissionsUserByUserDTO>>>;
    public permissionUserAllGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<PermissionsUserByUserDTO>>('get',`${this.basePath}/permissionUser/all`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userId 
     * @param userId2 
     * @param userName 
     * @param userSurname 
     * @param userEmail 
     * @param userPhone 
     * @param isRegistered 
     * @param isGuest 
     * @param hasAdministratorPermission 
     * @param hasDocumentsPermission 
     * @param hasClausesAndTemplatesPermission 
     * @param hasTemplatesLLDPermission 
     * @param hasSmartformPermission 
     * @param documentFolderPermissions 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public permissionUserDelete(userId?: string, userId2?: string, userName?: string, userSurname?: string, userEmail?: string, userPhone?: string, isRegistered?: boolean, isGuest?: boolean, hasAdministratorPermission?: boolean, hasDocumentsPermission?: boolean, hasClausesAndTemplatesPermission?: boolean, hasTemplatesLLDPermission?: boolean, hasSmartformPermission?: boolean, documentFolderPermissions?: Array<PermissionUserDocumentFolderDto>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public permissionUserDelete(userId?: string, userId2?: string, userName?: string, userSurname?: string, userEmail?: string, userPhone?: string, isRegistered?: boolean, isGuest?: boolean, hasAdministratorPermission?: boolean, hasDocumentsPermission?: boolean, hasClausesAndTemplatesPermission?: boolean, hasTemplatesLLDPermission?: boolean, hasSmartformPermission?: boolean, documentFolderPermissions?: Array<PermissionUserDocumentFolderDto>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public permissionUserDelete(userId?: string, userId2?: string, userName?: string, userSurname?: string, userEmail?: string, userPhone?: string, isRegistered?: boolean, isGuest?: boolean, hasAdministratorPermission?: boolean, hasDocumentsPermission?: boolean, hasClausesAndTemplatesPermission?: boolean, hasTemplatesLLDPermission?: boolean, hasSmartformPermission?: boolean, documentFolderPermissions?: Array<PermissionUserDocumentFolderDto>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public permissionUserDelete(userId?: string, userId2?: string, userName?: string, userSurname?: string, userEmail?: string, userPhone?: string, isRegistered?: boolean, isGuest?: boolean, hasAdministratorPermission?: boolean, hasDocumentsPermission?: boolean, hasClausesAndTemplatesPermission?: boolean, hasTemplatesLLDPermission?: boolean, hasSmartformPermission?: boolean, documentFolderPermissions?: Array<PermissionUserDocumentFolderDto>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {















        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('UserId', <any>userId);
        }
        if (userId2 !== undefined && userId2 !== null) {
            queryParameters = queryParameters.set('User.Id', <any>userId2);
        }
        if (userName !== undefined && userName !== null) {
            queryParameters = queryParameters.set('User.Name', <any>userName);
        }
        if (userSurname !== undefined && userSurname !== null) {
            queryParameters = queryParameters.set('User.Surname', <any>userSurname);
        }
        if (userEmail !== undefined && userEmail !== null) {
            queryParameters = queryParameters.set('User.Email', <any>userEmail);
        }
        if (userPhone !== undefined && userPhone !== null) {
            queryParameters = queryParameters.set('User.Phone', <any>userPhone);
        }
        if (isRegistered !== undefined && isRegistered !== null) {
            queryParameters = queryParameters.set('IsRegistered', <any>isRegistered);
        }
        if (isGuest !== undefined && isGuest !== null) {
            queryParameters = queryParameters.set('IsGuest', <any>isGuest);
        }
        if (hasAdministratorPermission !== undefined && hasAdministratorPermission !== null) {
            queryParameters = queryParameters.set('HasAdministratorPermission', <any>hasAdministratorPermission);
        }
        if (hasDocumentsPermission !== undefined && hasDocumentsPermission !== null) {
            queryParameters = queryParameters.set('HasDocumentsPermission', <any>hasDocumentsPermission);
        }
        if (hasClausesAndTemplatesPermission !== undefined && hasClausesAndTemplatesPermission !== null) {
            queryParameters = queryParameters.set('HasClausesAndTemplatesPermission', <any>hasClausesAndTemplatesPermission);
        }
        if (hasTemplatesLLDPermission !== undefined && hasTemplatesLLDPermission !== null) {
            queryParameters = queryParameters.set('HasTemplatesLLDPermission', <any>hasTemplatesLLDPermission);
        }
        if (hasSmartformPermission !== undefined && hasSmartformPermission !== null) {
            queryParameters = queryParameters.set('HasSmartformPermission', <any>hasSmartformPermission);
        }
        if (documentFolderPermissions) {
            documentFolderPermissions.forEach((element) => {
                queryParameters = queryParameters.append('DocumentFolderPermissions', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/permissionUser`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param userId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public permissionUserGet(userId?: string, observe?: 'body', reportProgress?: boolean): Observable<PermissionsUserByUserDTO>;
    public permissionUserGet(userId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<PermissionsUserByUserDTO>>;
    public permissionUserGet(userId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<PermissionsUserByUserDTO>>;
    public permissionUserGet(userId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (userId !== undefined && userId !== null) {
            queryParameters = queryParameters.set('userId', <any>userId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<PermissionsUserByUserDTO>('get',`${this.basePath}/permissionUser`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param showAdminHelp 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public permissionUserShowAdminHelpPut(showAdminHelp?: boolean, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public permissionUserShowAdminHelpPut(showAdminHelp?: boolean, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public permissionUserShowAdminHelpPut(showAdminHelp?: boolean, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public permissionUserShowAdminHelpPut(showAdminHelp?: boolean, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (showAdminHelp !== undefined && showAdminHelp !== null) {
            queryParameters = queryParameters.set('showAdminHelp', <any>showAdminHelp);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/permissionUser/show-admin-help`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public permissionUserUsersPermissionsPut(body?: Array<PermissionsUserByUserDTO>, observe?: 'body', reportProgress?: boolean): Observable<Array<PermissionsUserByUserDTO>>;
    public permissionUserUsersPermissionsPut(body?: Array<PermissionsUserByUserDTO>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<PermissionsUserByUserDTO>>>;
    public permissionUserUsersPermissionsPut(body?: Array<PermissionsUserByUserDTO>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<PermissionsUserByUserDTO>>>;
    public permissionUserUsersPermissionsPut(body?: Array<PermissionsUserByUserDTO>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<PermissionsUserByUserDTO>>('put',`${this.basePath}/permissionUser/users-permissions`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
