import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { ApiProcessorService, ApiRequest, ApiOperation } from './api-processor.service';

@Injectable()
export class DocumentsConverterService {

    protected basePath = '/api/file-management/files';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(
        private readonly apiProcessorService: ApiProcessorService) {
    }

    public post(html: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = JSON.stringify(html);
        apiRequest.endpointUrl = `${this.basePath}/convert-html-to-word`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public generateResultView(html: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = JSON.stringify(html);
        apiRequest.endpointUrl = `${this.basePath}/convert-html-to-word-pagination`;
        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
