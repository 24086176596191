import { Component, Input, OnInit } from '@angular/core';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { NodeTreeNodeType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-node-type.enum';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { NodeTreeUserAction } from 'src/app/shared/components/ctbox-tree/enums/node-tree-user-action.enum';

@Component({
    selector: 'app-tree-contextual-help',
    templateUrl: './tree-contextual-help.component.html',
    styleUrls: ['./tree-contextual-help.component.scss']
})
export class TreeContextualHelpComponent implements OnInit {

    @Input() module: string;

    public dataTreeSource: FileNode[];

    public dataTreeClauses: FileNode[];
    public dataTreeTemplates: FileNode[];
    public dataTreeDocuments: FileNode[];
    public actions: NodeTreeUserAction[] = [];

    constructor() {
        this.dataTreeClauses =
            [
                {
                    "id": "0",
                    "value": "Cláusulas de mi corporación",
                    "children": [
                        {
                            "id": "1",
                            "value": "Cláusulas de seguros",
                            "type": NodeTreeNodeType.Folder,
                            "children": [],
                            "parentId": null,
                            "actions": [
                                NodeTreeActionType.Create,
                                NodeTreeActionType.Delete,
                                NodeTreeActionType.Rename,
                            ],
                            "isChecked": true
                        },
                        {
                            "id": "2",
                            "value": "Cláusulas esenciales",
                            "type": NodeTreeNodeType.Folder,
                            "children": [],
                            "parentId": null,
                            "actions": [
                                NodeTreeActionType.Create,
                                NodeTreeActionType.Delete,
                                NodeTreeActionType.Rename,
                            ],
                            "isChecked": true
                        }
                    ],
                    "type": NodeTreeNodeType.Folder,
                    "parentId": "",
                    "actions": [
                        NodeTreeActionType.Create,
                        NodeTreeActionType.Delete,
                        NodeTreeActionType.Rename,
                    ],
                    "isChecked": true
                }
            ];

        this.dataTreeTemplates =
            [
                {
                    "id": "0",
                    "value": "Plantillas de mi corporación",
                    "children": [
                        {
                            "id": "1",
                            "value": "Plantillas de contratos",
                            "type": NodeTreeNodeType.Folder,
                            "children": [],
                            "parentId": null,
                            "actions": [
                                NodeTreeActionType.Create,
                                NodeTreeActionType.Delete,
                                NodeTreeActionType.Rename,
                            ],
                            "isChecked": true
                        },
                        {
                            "id": "2",
                            "value": "Plantillas de laboral",
                            "type": NodeTreeNodeType.Folder,
                            "children": [],
                            "parentId": null,
                            "actions": [
                                NodeTreeActionType.Create,
                                NodeTreeActionType.Delete,
                                NodeTreeActionType.Rename,
                            ],
                            "isChecked": true
                        },
                        {
                            "id": "3",
                            "value": "Plantillas de penal",
                            "type": NodeTreeNodeType.Folder,
                            "children": [],
                            "parentId": null,
                            "actions": [
                                NodeTreeActionType.Create,
                                NodeTreeActionType.Delete,
                                NodeTreeActionType.Rename,
                            ],
                            "isChecked": true
                        },
                        {
                            "id": "4",
                            "value": "Plantillas de social",
                            "type": NodeTreeNodeType.Folder,
                            "children": [],
                            "parentId": null,
                            "actions": [
                                NodeTreeActionType.Create,
                                NodeTreeActionType.Delete,
                                NodeTreeActionType.Rename,
                            ],
                            "isChecked": true
                        }
                    ],
                    "type": NodeTreeNodeType.Folder,
                    "parentId": "",
                    "actions": [
                        NodeTreeActionType.Create,
                        NodeTreeActionType.Delete,
                        NodeTreeActionType.Rename,
                    ],
                    "isChecked": true
                }
            ];


        this.dataTreeDocuments =
            [
                {
                    "id": "0",
                    "value": "Mis documentos",
                    "children": [
                        {
                            "id": "1",
                            "value": "Laboral",
                            "type": NodeTreeNodeType.Folder,
                            "children": [],
                            "parentId": null,
                            "actions": [
                                NodeTreeActionType.Create,
                                NodeTreeActionType.Delete,
                                NodeTreeActionType.Rename,
                            ],
                            "isChecked": true
                        },
                        {
                            "id": "2",
                            "value": "Penal",
                            "type": NodeTreeNodeType.Folder,
                            "children": [],
                            "parentId": null,
                            "actions": [
                                NodeTreeActionType.Create,
                                NodeTreeActionType.Delete,
                                NodeTreeActionType.Rename,
                            ],
                            "isChecked": true
                        }
                    ],
                    "type": NodeTreeNodeType.MyDocuments,
                    "parentId": "",
                    "actions": [
                        NodeTreeActionType.Create,
                        NodeTreeActionType.Delete,
                        NodeTreeActionType.Rename,
                    ],
                    "isChecked": true
                },
                {
                    "id": "2",
                    "value": "Nuestros documentos",
                    "children": [
                        {
                            "id": "1",
                            "value": "Arrendamientos",
                            "type": NodeTreeNodeType.Folder,
                            "children": [],
                            "parentId": null,
                            "actions": [
                                NodeTreeActionType.Create,
                                NodeTreeActionType.Delete,
                                NodeTreeActionType.Rename,
                            ],
                            "isChecked": true
                        },
                        {
                            "id": "2",
                            "value": "Publicidad",
                            "type": NodeTreeNodeType.Folder,
                            "children": [],
                            "parentId": null,
                            "actions": [
                                NodeTreeActionType.Create,
                                NodeTreeActionType.Delete,
                                NodeTreeActionType.Rename,
                            ],
                            "isChecked": true
                        }
                    ],
                    "type": NodeTreeNodeType.OurDocuments,
                    "parentId": "",
                    "actions": [
                        NodeTreeActionType.Create,
                        NodeTreeActionType.Delete,
                        NodeTreeActionType.Rename,
                    ],
                    "isChecked": true
                },
                {
                    "id": "3",
                    "value": "Compartidos",
                    "children": [],
                    "type": NodeTreeNodeType.Shared,
                    "parentId": "",
                    "actions": [],
                    "isChecked": true
                }


            ];

        this.actions = [NodeTreeUserAction.Menu]


    }

    public ngOnInit(): void {

        switch (this.module) {
            case 'clauses': this.dataTreeSource = this.dataTreeClauses; break;
            case 'templates': this.dataTreeSource = this.dataTreeTemplates; break;
            case 'documents': this.dataTreeSource = this.dataTreeDocuments; break;
            case 'clausulas': this.dataTreeSource = this.dataTreeClauses; break;
            default:
                break;
        }
    }




}
