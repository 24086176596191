import { Component, EventEmitter, Input, OnDestroy } from '@angular/core';
import { IStepComponent } from '../models/step-component.model';
import { FileStepperDataService } from 'src/app/core/shared/services/file-stepper-data.service';
import { takeUntil } from 'rxjs/operators';
import { IFileStepper } from '../../upload-file/file-stepper.model';

@Component({
  selector: 'app-upload-file-stepper',
  templateUrl: './upload-file-stepper.component.html'
})
export class UploadFileStepperComponent implements OnDestroy, IStepComponent {

    public isStepValid = false;

    public files: IFileStepper[] = [];

    private onDestroy$ = new EventEmitter<void>();

    constructor(private readonly fileStepperDataService: FileStepperDataService) {
        this.InitializeFiles();
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public InitializeFiles(): void {
        this.fileStepperDataService.getFilesSelected()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((files: IFileStepper[]) => {
            this.files = files;
        });
    }

    public onIsFileManagementFormValid(isValid: boolean): void {
        this.isStepValid = isValid;
    }

    public onNewFilesAdded(newFiles: IFileStepper[]) {
        this.fileStepperDataService.setAddFilesSelected(newFiles);
    }

    public onFileModify(fileToModify: IFileStepper) {
        const currentFiles = this.fileStepperDataService.getFilesSelectedValue();
        const index = currentFiles.findIndex(file => file.id === fileToModify.id);
        currentFiles[index] = fileToModify;
        this.fileStepperDataService.setFilesSelected(currentFiles);
    }

    public onFileRemove(fileToRemove: IFileStepper) {
        this.fileStepperDataService.setRemoveFile(fileToRemove);
    }
}
