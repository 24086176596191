<ng-container *ngIf="uploadedFilesResult">
    <div *ngIf="getTotalSuccessFiles() > 0" class="uploaded-file-result-stepper-list">
        <div class="uploaded-file-result-stepper-title">
            <ng-container i18n="@@ContenidoEnviado">Contenido enviado</ng-container>
            {{getTotalSuccessFiles()}}/{{getTotalFilesSent()}}
        </div>
        <div class="uploaded-file-result-stepper-item" *ngFor="let successFile of uploadedFilesResult.successfullyUploadedFiles">
            <div class="uploaded-file-result-stepper-item-container">
                <div class="uploaded-file-result-stepper-item-info">
                    <div class="uploaded-file-result-stepper-item-name">{{successFile.name}}</div>
                    <div class="uploaded-file-result-stepper-item-size">{{getFormattedSize(successFile.size)}}</div>
                </div>
            </div>
        </div>
    </div>

    <div *ngIf="getTotalFailedFiles() > 0" class="uploaded-file-result-stepper-list">
        <p class="uploaded-file-result-stepper-title warning">
            <ng-container i18n="@@ContenidoNoEnviado">Contenido no enviado</ng-container>
            {{getTotalFailedFiles()}}/ {{getTotalFilesSent()}}
        </p>
        <div class="uploaded-file-result-stepper-item warning" *ngFor="let failedFile of uploadedFilesResult.failedUploadedFiles">
            <div class="uploaded-file-result-stepper-item-container">
                <div class="uploaded-file-result-stepper-item-info">
                    <div class="uploaded-file-result-stepper-item-name">{{failedFile.name}}</div>
                    <div class="uploaded-file-result-stepper-item-size">{{getFormattedSize(failedFile.size)}}</div>
                </div>
            </div>
        </div>
    </div>
</ng-container>
