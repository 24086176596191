import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { TemplateDocumentService, TemplateDocumentDTO } from 'src/app/api';
import { ITemplateDocumentRevisionService } from './template-document-revision.service.interface';

@Injectable({
    providedIn: 'root'
})
export class TemplateDocumentRevisionService extends ITemplateDocumentRevisionService {

    // First one is documentTemplateId
    public stateDocuments = new BehaviorSubject<Map<string, string>>(new Map<string, string>());

    private applied = 'Applied';
    private keep = 'Keep';
    private pending = 'Pending';
    private indexMap = new Map<string, number>();

    constructor(private documentsTemplateService: TemplateDocumentService) {
        super();
    }

    public getCurrentStateDocumentsSubscription(): BehaviorSubject<Map<string, string>> {
        return this.stateDocuments;
    }

    public UpdateStateDocument(stateDocument: Map<string, string>) {
        this.stateDocuments.next(stateDocument);
        this.updateIndexMap();

    }

    public getCurrentStateDocuments(): Map<string, string> {
        return this.stateDocuments.getValue();
    }

    public getState(documentTemplate: TemplateDocumentDTO): string {
        return this.stateDocuments.getValue().get(documentTemplate.id);
    }

    public getNumPendingDocuments(): number {
        const states = Array.from(this.stateDocuments.getValue().values());
        return states.filter(state => state === this.pending).length;
    }

    public getIndexDocumentTemplate(documentTemplate: TemplateDocumentDTO): number {
        if (!documentTemplate) {
            return -1;
        }

        return this.indexMap.get(documentTemplate.id);
    }

    public getNextDocumentTemplateId(currentIndex: number): string {
        let nextIndex = currentIndex + 1;
        if (this.indexMap.size < 2) {
            nextIndex = 0;
        }

        if (nextIndex < 0) {
            nextIndex = 0;
        }

        if (!this.getIdByIndex(nextIndex)) {
            nextIndex = 0;

        }

        return this.getIdByIndex(nextIndex);
    }

    public getPreviousDocumentTemplateId(currentIndex: number): string {
        let nextIndex = currentIndex - 1;

        if (nextIndex < 0) {
            nextIndex = this.indexMap.size - 1;
        }

        if (!this.getIdByIndex(nextIndex)) {
            nextIndex = 0;

        }

        return this.getIdByIndex(nextIndex);
    }

    public isPending(documentTemplate: TemplateDocumentDTO): boolean {
        if (!documentTemplate) {
            return false;
        }

        return this.getCurrentStateDocuments().has(documentTemplate.id) &&
            this.getCurrentStateDocuments().get(documentTemplate.id) === this.pending;
    }

    public isApplied(documentTemplate: TemplateDocumentDTO): boolean {
        if (!documentTemplate) {
            return false;
        }

        return this.getCurrentStateDocuments().has(documentTemplate.id) &&
            this.getCurrentStateDocuments().get(documentTemplate.id) === this.applied;
    }

    public isKeep(documentTemplate: TemplateDocumentDTO): boolean {
        if (!documentTemplate) {
            return false;
        }

        return this.getCurrentStateDocuments().has(documentTemplate.id) &&
            this.getCurrentStateDocuments().get(documentTemplate.id) === this.keep;
    }

    public setPending(documentTemplate: TemplateDocumentDTO): void {
        const current = this.getCurrentStateDocuments();
        current.set(documentTemplate.id, this.pending);
        this.UpdateStateDocument(current);
    }

    public setKeep(documentTemplate: TemplateDocumentDTO): void {
        const current = this.getCurrentStateDocuments();
        current.set(documentTemplate.id, this.keep);
        this.UpdateStateDocument(current);
    }

    public setApplied(documentTemplate: TemplateDocumentDTO): void {
        const current = this.getCurrentStateDocuments();
        current.set(documentTemplate.id, this.applied);
        this.UpdateStateDocument(current);
    }

    public getFirstDocumentTemplateId(currentIndex: number): string {
        return this.getIdByIndex(0);
    }

    public getLastDocumentTemplateId(currentIndex: number): string {
        return this.getIdByIndex(this.indexMap.size - 1);
    }

    public loadPendingCBDocumentsForTemplate(templateId: string): void {
        this.documentsTemplateService.templateDocumentSearchPendingTemplateTemplateIdGet(templateId).subscribe(values => {

            const stateDocument = new Map<string, string>();
            this.UpdateStateDocument(stateDocument);
            values.forEach(element => {
                this.setPending(element);
            });
        });
    }

    public orderIndex(orderedData: TemplateDocumentDTO[]): void {
        const orderedIds = orderedData.map((element) => {
            return element.id;
        });

        this.updateMapByIds(orderedIds);
    }

    private updateMapByIds(ids: string[]) {
        for (let i = 0; i < ids.length; i++) {
            this.indexMap.set(ids[i], i);
        }
    }

    private updateIndexMap() {
        this.indexMap.clear();
        const ids = Array.from(this.stateDocuments.getValue().keys());
        this.updateMapByIds(ids);
    }

    private getIdByIndex(index: number): string {
        const foundValue = [...this.indexMap].find(([key, val]) => val = index);
        if (!foundValue) {
            return undefined;
        }

        return foundValue[0];
    }

}
