import { Element, Writer } from "ckeditor5";
import { BaseSelectCommand } from "./base-select-command";
import { SelectModel } from "../../models/select/select-model";
import { SelectPlugin } from "../../plugins/select/select-plugin";

export default class EditSelectCommand extends BaseSelectCommand {

    public override execute(selectModel: SelectModel): void {
        const editor = this.editor;

        editor.model.change( (writer: Writer) => {
            const range = writer.createRangeIn(this.editor.model.document.getRoot()!);
            const selectElement = this.getElementWithId(range, selectModel.id)?.item as Element;

            if(!selectElement) {
                return;
            }

            this.changeNewModelOptions(writer, selectModel, selectElement);
        } );
    }

    public override refresh(): void {

        super.refresh();
        if (this.isEnabled) {
            return;
        }

        this.enableCommandIfCursorInsideInputContent();
    }

    private changeNewModelOptions(writer: Writer, selectModel: SelectModel, selectElement: Element) {
        const elementModel = this.dataUtilsService.createSelectCompleteStructureModelWithOptions(writer, selectModel);
        const newSelectModel = elementModel.getChild(SelectPlugin.SELECT_POSITION);
        const oldSelectModel = selectElement.getChild(SelectPlugin.SELECT_POSITION);
        if (!!newSelectModel) {
            writer.append(newSelectModel, selectElement);
        }
        if (!!oldSelectModel) {
            writer.remove(oldSelectModel);
        }
    }
}
