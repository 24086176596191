import { ApiOperation, ApiProcessorService, ApiRequest } from "./api-processor.service";
import { Observable } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class TemplateAppliedRegulationService {

    private basePath = '/api/templates-management/templates/{templateId}/applied-regulations';

    constructor(private readonly apiProcessorService: ApiProcessorService) {
    }

    public get(templateId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.basePath.replace('{templateId}', templateId);
        apiRequest.responseType = 'text';

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
