<div class="versions-list">
    <div class="versions-title" i18n="@@VERSIONESDISPONIBLES">VERSIONES DISPONIBLES</div>
    <mat-selection-list #versions [multiple]="false" class="versions">
        <mat-list-option *ngFor="let version of versionsList; let i = index" [value]="version" checkboxPosition="before" [selected]="version.isSelected">
            <div class="mat-list-item-content" [ngClass]="{'selected': version.isSelected}" (click)="onClickVersion(version.id)">
                <div class="mat-list-item-data" [ngClass]="{'in-force' : i === 0 }">{{version.label}}
                    <mat-icon *ngIf="i === 0" class="info xsmall" matTooltip="Versión actual" i18n-matTooltip="@@InForceVersionText">check</mat-icon>
                </div>
                <div class="mat-list-item-button mat-button-wrapper">
                    <ctbox-button *ngIf="version.hasBeenLastAvailable" buttonIconSvg="hand" matTooltip="Última versión que estuvo disponible para consulta" i18n-matTooltip="@@InForceLastVersionText"></ctbox-button>
                    <ctbox-button buttonIcon="chat" (buttonClick)="addComment(version)" [buttonColor]="buttonColorHasComment(version.title , version.hasComment)"></ctbox-button>
                </div>
            </div>
        </mat-list-option>
    </mat-selection-list>
</div>