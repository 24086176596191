import { NodeTreeAction } from "src/app/shared/components/ctbox-tree/models/node-tree-action.model"
import { IJsonPatchDocument } from "./json-patch.model"

export interface FolderPatchModel {
    patchId: string;
    nodeAction: NodeTreeAction;
    jsonPatchDocument: IJsonPatchDocument;
    folderName?: string;
    patchAction: PatchAction;
}

export enum PatchAction {
    rename = 1,
    move = 2,
}
