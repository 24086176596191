import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { IHtmlComparerService } from './html-comparer.service.interface';

@Injectable({
    providedIn: 'root'
})
export class HtmlComparerService extends IHtmlComparerService {

    private currentDocument = new BehaviorSubject<string>('');
    private currentDocumentWithChangesMarked = new BehaviorSubject<Document>(undefined);
    private currentDocumentCleaned = new BehaviorSubject<string>('');
    private currentChanges = new BehaviorSubject<Element[]>(undefined);
    private currentChangesCount = new BehaviorSubject<number>(0);
    private currentChangesLastSpan = new BehaviorSubject<number>(0);
    private changesUndo = new BehaviorSubject<{ position: Element, change: Element }[]>([]);
    private changesRedo = new BehaviorSubject<{ position: Element, change: Element }[]>([]);

    private CLASS_GROUP_CHANGE = 'group-change';
    private CLASS_MODIFICATION = 'diffmod';
    private TAG_INSERTION = 'ins';
    private TAG_DELETE = 'del';

    public setCurrentDocumentComparation(document: string): void {
        this.currentDocument.next(document);
    }

    public getCurrentDocumentWithChangesMarkedBehaviorSubject(): BehaviorSubject<Document> {
        return this.currentDocumentWithChangesMarked;
    }

    public getCurrentDocumentCleanBehaviorSubject(): BehaviorSubject<string> {
        return this.currentDocumentCleaned;
    }

    public getCurrentDocumentClean(): string {
        return this.currentDocumentCleaned.getValue();
    }

    public getCurrentDocumentComparation(): string {
        return this.currentDocument.getValue();
    }

    public getCurrentDocumentWithChangesMarked(): string {
        if (!this.currentDocumentWithChangesMarked.getValue()) {
            return '';
        }

        return this.currentDocumentWithChangesMarked.getValue().firstElementChild.innerHTML;
    }

    public setCleanDocumentAsComparerDocument(): void {
        const domParser = new DOMParser();
        const cleanedDocument = domParser.parseFromString(this.getCurrentDocumentClean(), 'text/html');
        this.currentDocumentWithChangesMarked.next(cleanedDocument);
    }

    public calculateCurrentChanges(): void {
        const domParser = new DOMParser();
        const documentCleanToSearch = domParser.parseFromString(this.getCurrentDocumentComparation(), 'text/html');

        const changes = this.markChangesInDocument(documentCleanToSearch);

        this.currentChanges.next(Array.from(changes));
        this.currentChangesCount.next(changes.length);
        this.currentDocumentWithChangesMarked.next(documentCleanToSearch);
        this.changesUndo.next([]);
        this.changesRedo.next([]);
    }

    private markChangesInDocument(documentToMark: Document): Element[] {
        // Estructuras de cambios:
        // - Inserción <ins class="diffins"> CONTENIDO </ins>
        // - Eliminación <del class="diffdel"> CONTENIDO ELIMINADO</del>
        // - Modificacino <del class="diffmod"> CONTENIDO ANTERIOR </del><ins class="diffmod"> CONTENIDO NUEVO</ins>
        this.currentChangesLastSpan.next(0);
        return this.getChangesInDocument(documentToMark);
    }

    private getChangesInDocument(documentToGetChanges: Document): Element[] {
        const changesWithoutAggrupation = documentToGetChanges.querySelectorAll('ins, del');
        const changes = this.insertChangeSpans(documentToGetChanges, changesWithoutAggrupation);
        return Array.from(changes);
    }

    private insertChangeSpans(document: Document, changesWithoutAggrupation: NodeListOf<Element>): NodeListOf<Element> {

        for (let i = 0; i < changesWithoutAggrupation.length; i++) {
            const change = changesWithoutAggrupation[i];
            if (change.className !== this.CLASS_MODIFICATION) {
                this.insertChangeSpan(document, change);
                continue;
            }

            if (i + 1 >= changesWithoutAggrupation.length) {
                this.insertChangeSpan(document, change);
                continue;
            }

            const nextChange = changesWithoutAggrupation[i + 1];

            if (change.tagName.toUpperCase() !== this.TAG_DELETE.toUpperCase() ||
                nextChange.tagName.toUpperCase() !== this.TAG_INSERTION.toUpperCase()) {
                this.insertChangeSpan(document, change);
                continue;
            }

            this.insertChangeTwoElementsDiv(document, change, nextChange);
            i++;
        }

        const changes = document.querySelectorAll('.' + this.CLASS_GROUP_CHANGE);

        return changes;
    }

    private insertChangeSpan(document: Document, element: Element): Element {

        const divGroupNode = this.createGroupSpanElement(document);
        element.parentElement.insertBefore(divGroupNode, element);
        divGroupNode.appendChild(element);

        return divGroupNode;
    }

    private insertChangeTwoElementsDiv(document: Document, element: Element, secondElement: Element): Element {
        const parentElement = this.insertChangeSpan(document, element);
        parentElement.appendChild(secondElement);

        return parentElement;
    }

    private createGroupSpanElement(document: Document): Element {
        const currentChangeNumber = this.currentChangesLastSpan.getValue();
        const spanGroupNode = document.createElement('span');
        spanGroupNode.className = this.CLASS_GROUP_CHANGE;
        spanGroupNode.id = this.CLASS_GROUP_CHANGE + '_' + currentChangeNumber;
        this.currentChangesLastSpan.next(currentChangeNumber + 1);
        return spanGroupNode;
    }

}
