import { Directive, ElementRef, EventEmitter, Inject, Input, OnDestroy, OnInit, Output, Renderer2 } from '@angular/core';
import { CtboxNodeIdDroppedEvent } from 'src/app/shared/components/ctbox-tree/ctbox-tree-events/ctbox-node-id-dropped-event.component';
import { FileFlatNode } from 'src/app/shared/components/ctbox-tree/models/file-flat-node.model';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';

@Directive({
    selector: '[ctboxNodeDraggable]'
})
export class CtboxNodeDraggableDirective implements OnDestroy, OnInit {

    public static DATA_TRANSFER_STUB_DATA = 'some browsers enable drag-n-drop only when dataTransfer has data';

    @Input() public nodeDraggable: ElementRef;

    @Input() public node: FileFlatNode;
    @Input() public labelToMarkIsDroppable: string;
    @Input() public attributeToReadInDropElement: string;
    @Input() public canDropFromOutside: boolean;

    @Output() public dropFromOutsideWithId: EventEmitter<CtboxNodeIdDroppedEvent> = new EventEmitter();

    private nodeNativeElement: HTMLElement;
    private disposersForDragListeners: Function[] = [];

    constructor(@Inject(ElementRef) public element: ElementRef,
                @Inject(Renderer2) private renderer: Renderer2) {
        this.nodeNativeElement = element.nativeElement;
    }

    public ngOnInit(): void {
        if ( this.node?.permissions.includes(NodeTreeActionType.Move)) {
                this.renderer.setAttribute(this.nodeNativeElement, 'draggable', 'true');
        }

        if (this.node?.permissions.includes(NodeTreeActionType.MoveFromOutside) ||
                            this.node?.permissions.includes(NodeTreeActionType.Move)) {

            this.disposersForDragListeners.push(
                this.renderer.listen(this.nodeNativeElement, 'dragenter', this.handleDragEnter.bind(this))
            );
            this.disposersForDragListeners.push(
                this.renderer.listen(this.nodeNativeElement, 'dragover', this.handleDragOver.bind(this))
            );
            this.disposersForDragListeners.push(
                this.renderer.listen(this.nodeNativeElement, 'dragstart', this.handleDragStart.bind(this))
            );
            this.disposersForDragListeners.push(
                this.renderer.listen(this.nodeNativeElement, 'dragleave', this.handleDragLeave.bind(this))
            );
            this.disposersForDragListeners.push(
                this.renderer.listen(this.nodeNativeElement, 'drop', this.handleDrop.bind(this))
            );
            this.disposersForDragListeners.push(
                this.renderer.listen(this.nodeNativeElement, 'dragend', this.handleDragEnd.bind(this))
            );
        }
    }

    public ngOnDestroy(): void {
        this.disposersForDragListeners.forEach(dispose => dispose());
    }

    private handleDragStart(e: DragEvent): any {
        if (e.stopPropagation) {
            e.stopPropagation();
        }

        e.dataTransfer.setData('text', CtboxNodeDraggableDirective.DATA_TRANSFER_STUB_DATA);
        e.dataTransfer.effectAllowed = 'move';
    }

    private handleDragOver(e: DragEvent): any {
        e.preventDefault();
        e.dataTransfer.dropEffect = 'move';
    }

    private handleDragEnter(e: DragEvent): any {
        e.preventDefault();

        if (this.containsElementAt(e)) {
            this.addClass('over-drop-target');
        }
    }

    private handleDragLeave(e: DragEvent): any {
        if (!this.containsElementAt(e)) {
            this.removeClass('over-drop-target');
        }
    }

    private handleDrop(e: DragEvent): any {
        e.preventDefault();
        if (e.stopPropagation) {
            e.stopPropagation();
        }

        this.removeClass('over-drop-target');

        if (this.isDropPossible(e)) {
            if (this.canDropFromOutside) {
                const dataTransfer = e.dataTransfer.getData('text/html');
                const attributeValue = this.getAttributeValue(dataTransfer, this.labelToMarkIsDroppable, this.attributeToReadInDropElement);
                if (attributeValue !== null) {
                    return this.notifyThatIdWasDropped(attributeValue);
                }
            }
            return true;
        }

        return false;
    }

    private getAttributeValue(html: string, labelToMarkIsDroppable: string, elementAttribute: string): string {
        const tmp = document.implementation.createHTMLDocument('New').body;
        tmp.innerHTML = html;
        let useFirstGrandChild = false;

        if (tmp.firstElementChild === null) {
            return null;
        }
        if (tmp.firstElementChild.getAttribute(labelToMarkIsDroppable) !== 'true') {

            if (tmp.firstElementChild.firstElementChild === null) {
                return null;
            }

            if (tmp.firstElementChild.firstElementChild.getAttribute(labelToMarkIsDroppable) !== 'true') {   // firefox

                return null;
            } else {
                useFirstGrandChild = true;
            }
        }

        if (useFirstGrandChild) {
            return tmp.firstElementChild.firstElementChild.getAttribute(elementAttribute); // firefox
        }
        return tmp.firstElementChild.getAttribute(elementAttribute);

    }

    private isDropPossible(e: DragEvent): boolean {
        return (this.node?.permissions.includes(NodeTreeActionType.MoveFromOutside) && this.containsElementAt(e)) ||
            this.node?.permissions.includes(NodeTreeActionType.MoveTo);
    }

    private handleDragEnd(e: DragEvent): any {
        this.removeClass('over-drop-target');
    }

    private containsElementAt(e: DragEvent): boolean {
        const { x = e.clientX, y = e.clientY } = e;
        return this.nodeNativeElement.contains(document.elementFromPoint(x, y));
    }

    private addClass(className: string): void {
        const classList: DOMTokenList = this.nodeNativeElement.classList;
        classList.add(className);
    }

    private removeClass(className: string): void {
        const classList: DOMTokenList = this.nodeNativeElement.classList;
        classList.remove(className);
    }

    private notifyThatIdWasDropped(id: string): void {
        this.dropFromOutsideWithId.emit(new CtboxNodeIdDroppedEvent(this.node, id));
    }
}
