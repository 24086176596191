import { Injectable } from '@angular/core';
import { IDateService } from './date.service.interface';

@Injectable({
  providedIn: 'root'
})
export class DateService extends IDateService {

  public addDays(date: Date, days: number): Date {
    const inputDate = new Date(date);
    const result = new Date(date);
    result.setDate(inputDate.getDate() + days);
    return result;
  }

  public addMonths(date: Date, months: number): Date {
    const inputDate = new Date(date);
    let result = new Date(date);
    result.setMonth(inputDate.getMonth() + months);

    if (result.getDate() !== inputDate.getDate()) {
      result = this.addDays(result, result.getDate() * -1);
    }

    return result;
  }

  public addYears(date: Date, years: number): Date {
    const inputDate = new Date(date);
    const result = new Date(date);
    result.setFullYear(inputDate.getFullYear() + years);
    return result;
  }

  public getCurrentDateWithoutTime(): Date {
    const completeCurrentDate = new Date();
    return new Date(completeCurrentDate.getFullYear(), completeCurrentDate.getMonth(), completeCurrentDate.getDate());
  }

  public getDateWithoutTime(date: Date): Date {
    const inputDate = new Date(date);
    return new Date(inputDate.getFullYear(), inputDate.getMonth(), inputDate.getDate());
  }

  public formatDate(date: any): Date {
    if (typeof(date) === 'string') {
        date = new Date(date);
    } else if (!(date instanceof Date)) {
        date = new Date(this.getDateWithoutTime(date.toDate()));
    }

    return date;
  }
}
