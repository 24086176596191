import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documents-signed-contextual-help',
  templateUrl: './documents-signed-contextual-help.component.html'
})
export class DocumentsSignedContextualHelpComponent implements OnInit {

  public active: any;
  public activeSub: any;

  ACTIONS_TAB = 0;
  ORGANIZATION_TAB = 1;

  MYDOCUMENT_TAB = 0;
  OURDOCUMENT_TAB = 1;
  SIGNEDBYME_TAB = 2;

  constructor() { }

  ngOnInit(): void {
  }

}
