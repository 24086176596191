<footer [ngClass]="currentScreenSize">
    <div class="footer footer--top">
        <div class="footer__container">
            <ul class="footer__column footer__column--25">
                <li class="footer__item"><ng-container i18n="@@Sobre">Sobre </ng-container>{{publicLinks.brandName}}</li>
                <li class="footer__item">
                    <a class="footer__link" [href]="publicLinks.brandUrl" target="_blank" rel="noopener" i18n="@@QuienesSomos">Quiénes somos</a>
                </li>
                <li class="footer__item">
                    <a class="footer__link" [href]="publicLinks.fundationUrl" target="_blank" rel="noopener" i18n="@@Fundacion">Fundación Aranzadi LA LEY</a>
                </li>
            </ul>
            <ul class="footer__column footer__column--25">
                <li class="footer__item" i18n="@@ProductosYServicios">Productos y Servicios</li>
                <li class="footer__item">
                    <a class="footer__link" [href]="publicLinks.brandUrl" target="_blank" rel="noopener" i18n="@@Productos">Productos</a>
                </li>
                <li class="footer__item">
                    <a class="footer__link" [href]="publicLinks.trainingUrl" target="_blank" rel="noopener" i18n="@@Formacion">Formación</a>
                </li>
                <li class="footer__item">
                    <a class="footer__link" [href]="publicLinks.smartecaUrl" target="_blank" rel="noopener">smarteca</a>
                </li>
            </ul>
            <ul class="footer__column footer__column--25">
                <li class="footer__item" i18n="@@Contacto">Contacto</li>
                <li class="footer__item">
                    <a class="footer__link" [href]="publicLinks.telPhone">{{publicLinks.brandPhone}}</a>
                </li>
                <li class="footer__item">
                    <a class="footer__link" [href]="publicLinks.mailToContactClientMail">{{publicLinks.brandContactClientMail}}</a>
                </li>
                <li class="footer__item">
                    <a class="footer__link" [href]="publicLinks.advertisingUrl" target="_blank" rel="noopener" i18n="@@Publicidad">Publicidad</a>
                </li>
                <li class="footer__item">
                    <a class="footer__link" [href]="publicLinks.mainOfficeUrl" target="_blank" rel="noopener" i18n="@@DondeEstamos">Dónde estamos</a>
                </li>
                <li class="footer__item">
                    <a class="footer__link" [href]="publicLinks.shopUrl" target="_blank" rel="noopener" i18n="@@TiendaOnline">Tienda online</a>
                </li>
            </ul>
            <ul class="footer__column footer__column--25">
                <li class="footer__item" i18n="@@Siguenos">Síguenos</li>
                <li class="footer__item footer__link--rrss">
                    <ctbox-button buttonIconSvg="facebook" buttonColor="rrss" [matTooltip]="publicLinks.facebookUrl" (buttonClick)="goToLink(publicLinks.facebookUrl)"></ctbox-button>
                    <ctbox-button buttonIconSvg="twitter" buttonColor="rrss" [matTooltip]="publicLinks.twitterUrl" (buttonClick)="goToLink(publicLinks.facebookUrl)"></ctbox-button>
                    <ctbox-button buttonIconSvg="linkedin" buttonColor="rrss" [matTooltip]="publicLinks.linkedinUrl" (buttonClick)="goToLink(publicLinks.facebookUrl)"></ctbox-button>
                    <ctbox-button buttonIconSvg="youtube" buttonColor="rrss" [matTooltip]="publicLinks.youtubeUrl" (buttonClick)="goToLink(publicLinks.facebookUrl)"></ctbox-button>
                </li>
            </ul>
        </div>
        <div class="footer__container">
            <ul class="footer__column">
                <li class="footer__item">
                    <a class="footer__link footer__link--logo" [href]="publicLinks.brandUrl" target="_blank" rel="noopener"><span class="logo-branding negativ"></span></a>
                </li>
            </ul>
        </div>
    </div>
    <div class="footer footer--bottom">
        <div class="footer__container">
            <ul class="footer__column">
                <li class="footer__item"><a class="footer__link" [href]="publicLinks.brandUrl" target="_blank" rel="noopener">&copy; {{publicLinks.brandNameCopyRight}}</a></li>
            </ul>
        </div>
        <div class="footer__container">
            <ul class="footer__column footer__column--25">
                <li class="footer__item">
                    <a class="footer__link" [href]="publicLinks.legalNoticeUrl" target="_blank" rel="noopener" i18n="@@AvisoLegal">Aviso legal</a>
                </li>
                <li class="footer__item">
                    <a class="footer__link" href="javascript:openQuantcast();" i18n="@@GestionarCookies">Gestionar cookies</a>
                </li>
            </ul>
            <ul class="footer__column footer__column--25">
                <li class="footer__item">
                    <a class="footer__link" [href]="publicLinks.privacyNoticeUrl" target="_blank" rel="noopener" i18n="@@PoliticaPrivacidad">Política de privacidad</a>
                </li>
                <li class="footer__item">
                    <a class="footer__link" [href]="publicLinks.cookiesNoticeUrl" target="_blank" rel="noopener" i18n="@@PoliticaCookies">Política de cookies</a>
                </li>
            </ul>
            <ul class="footer__column footer__column--25">
                <li class="footer__item">
                    <a class="footer__link" [href]="publicLinks.qualityEnviromentUrl" target="_blank" rel="noopener" i18n="@@CalidadYMedioambiente">Calidad y Medioambiente</a>
                </li>
                <li class="footer__item">
                    <a class="footer__link" [href]="publicLinks.privacyNoticeUrl" target="_blank" rel="noopener" i18n="@@TratamientoDatos">Tratamiento de datos</a>
                </li>
            </ul>
            <ul class="footer__column footer__column--25">
                <li class="footer__item">
                    <a class="footer__link" [href]="publicLinks.aenorUrl" target="_blank" rel="noopener">
                        <img class="footer__img" src="assets/images/landing-footer-logo-aenor.png" alt="AENOR - Asociación Española de Normalización y Certificación">
                    </a>
                    <a class="footer__link" [href]="publicLinks.masfamiliaUrl" target="_blank" rel="noopener">
                        <img class="footer__img" src="assets/images/landing-footer-logo-efr.png" alt="Certificado EFR">
                    </a>
                    <a class="footer__link" [href]="publicLinks.empleoygarantiajuvenilUrl" target="_blank" rel="noopener">
                        <img class="footer__img" src="assets/images/landing-footer-logo-EGJ.png" alt="Estrategia de Emprendimiento y Empleo Joven. Garantía Juvenil">
                    </a>
                </li>
            </ul>
        </div>
        <div class="footer__container">
            <ul class="footer__column">
                <li class="footer__item footer__item--msg">
                    <p><ng-container i18n="@@FooterDisclaimer">Los productos que se integran en un paquete comercial, conjuntamente con una Base de datos y/o una publicación, pueden ser adquiridos también de forma individual. Puede obtener las condiciones comerciales aplicables a la venta separada de estos productos llamando al 91 903 90 27. En el caso de publicaciones en papel o digitales o productos de e-learning, puede también consultar estos precios en </ng-container> <a class="footer__link--msg" [href]="publicLinks.shopUrl" target="_blank" rel="noopener">Tienda LA LEY</a></p>
                </li>
            </ul>
        </div>
    </div>
</footer>