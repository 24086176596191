/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type TraceTypeDTO = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17 | 18 | 19 | 20 | 21 | 22 | 23 | 24 | 25 | 26 | 27 | 28;

export const TraceTypeDTO = {
    NUMBER_0: 0 as TraceTypeDTO,
    NUMBER_1: 1 as TraceTypeDTO,
    NUMBER_2: 2 as TraceTypeDTO,
    NUMBER_3: 3 as TraceTypeDTO,
    NUMBER_4: 4 as TraceTypeDTO,
    NUMBER_5: 5 as TraceTypeDTO,
    NUMBER_6: 6 as TraceTypeDTO,
    NUMBER_7: 7 as TraceTypeDTO,
    NUMBER_8: 8 as TraceTypeDTO,
    NUMBER_9: 9 as TraceTypeDTO,
    NUMBER_10: 10 as TraceTypeDTO,
    NUMBER_11: 11 as TraceTypeDTO,
    NUMBER_12: 12 as TraceTypeDTO,
    NUMBER_13: 13 as TraceTypeDTO,
    NUMBER_14: 14 as TraceTypeDTO,
    NUMBER_15: 15 as TraceTypeDTO,
    NUMBER_16: 16 as TraceTypeDTO,
    NUMBER_17: 17 as TraceTypeDTO,
    NUMBER_18: 18 as TraceTypeDTO,
    NUMBER_19: 19 as TraceTypeDTO,
    NUMBER_20: 20 as TraceTypeDTO,
    NUMBER_21: 21 as TraceTypeDTO,
    NUMBER_22: 22 as TraceTypeDTO,
    NUMBER_23: 23 as TraceTypeDTO,
    NUMBER_24: 24 as TraceTypeDTO,
    NUMBER_25: 25 as TraceTypeDTO,
    NUMBER_26: 26 as TraceTypeDTO,
    NUMBER_27: 27 as TraceTypeDTO,
    NUMBER_28: 28 as TraceTypeDTO
};