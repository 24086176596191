import { View, Locale } from 'ckeditor5';
import { Directive, Inject } from '@angular/core';

@Directive({
    selector: 'app-image-view',
})
export default class ImageView extends View {

    public readonly imageSrc: string;
    public readonly altText: string;

    constructor(
        @Inject(Locale) locale: Locale,
        @Inject('ImageSrc') imageSrc: string,
        @Inject('AltText') altText: string
    ) {
        super(locale);

        this.imageSrc = imageSrc;
        this.altText = altText;

        this.setTemplate({
            tag: 'img',
            attributes: {
                src: this.imageSrc,
                alt: this.altText,
                class: 'image-view'
            }
        });
    }
}
