export enum FileStatusCodes {
    ERROR = 'error',
    AVAILABLE = 'available',
    CREATION = 'creation',
    INFORCE = 'inforce',
    EXPIRED = 'expired',
    PENDING = 'pending',
    DRAFT = 'draft',
    UPLOADED_DRAFT = 'uploaded-draft',
    UPLOADED_PENDING = 'uploaded-pending',
    UPLOADED_INFORCE = 'uploaded-inforce',
    UPLOADED_EXPIRED = 'uploaded-expired',
    DOWNLOADED_PENDING = 'downloaded-pending',
    DOWNLOADED_DRAFT = 'downloaded-draft',
    DOWNLOADED_INFORCE = 'downloaded-inforce',
    DOWNLOADED_EXPIRED = 'downloaded-expired'
}
