import { Injectable } from '@angular/core';
import { Editor, ViewContainerElement } from 'ckeditor5';
import { PluginUtilsService } from '../../../utils/plugin-utils.service';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';

@Injectable({
    providedIn: 'root'
})
export class RadioUtilsService {

    private pluginUtils: PluginUtilsService;

    constructor() {
        this.pluginUtils = new PluginUtilsService();
    }

    public getId(radiusElement: ViewContainerElement): string {
        return radiusElement?.getAttribute('id')!;
    }

    public getSelectedRadiusElement(editor: Editor): ViewContainerElement | null {
        return this.pluginUtils.getSelectedContainerWithClass(editor, RadioPlugin.MODEL_ENTITIES.container.editionView);
    }
}
