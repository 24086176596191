<mat-tree [dataSource]="dataSource" [treeControl]="treeControl" cdkDropList (cdkDropListDropped)="drop($event)" [class]="customClass">
    <mat-tree-node *matTreeNodeDef="let node" [attr.data-nodeId]="node.id" [attr.data-parentNodeId]="node.parentId" matTreeNodeToggle matTreeNodePadding matTreeNodePaddingIndent="24" [matTreeNodePadding]="node.level" [ngClass]="{ 'node-selected': node.isSelected() }"
        cdkDrag [cdkDragData]="node" [cdkDragDisabled]="isDragDisabled(node)">
        <ctbox-tree-node class="mat-tree-node-container {{node?.type}} " [classNodeActions]="actions[0]" [node]="node" [actions]="actions" [treeControl]="treeControl" [canDropFromOutside]="canDropFromOutside" [labelToMarkIsDroppable]="labelToMarkIsDroppable"
            [attributeToReadInDropElement]="attributeToReadInDropElement" [expansionModel]="expansionModel" [isTreeLocked]="isTreeLocked" [eventEdit]="eventEdit.asObservable()" (onNodeAction)="doNodeAction($event)">
        </ctbox-tree-node>
        <ctbox-tree-actions [node]="node" [actions]="actions" [isTreeLocked]="isTreeLocked" [menuList]="defineMenuList(node)" [updateNodeObservable]="nodeActionRefresh$" (onNodeAction)="doNodeAction($event)"></ctbox-tree-actions>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasNoContent" [attr.data-nodeId]="node.id" [attr.data-parentNodeId]="node.parentId" matTreeNodePadding matTreeNodePaddingIndent="24" [matTreeNodePadding]="node.level" [ngClass]="{ 'node-selected': node.isSelected() }">
        <form [formGroup]="treeForm" (keydown.enter)="updateNode(node)" (focusout)="updateNode(node)" (keydown.escape)="deleteNewEmptyNode(node)">
            <app-ctbox-validate-name #nodeName [parentGroup]="treeForm" [controlName]="'name'" [classInput]="'ctbox-tree'" [showLabel]="false"></app-ctbox-validate-name>
        </form>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: hasChild" [attr.data-nodeId]="node.id" [attr.data-parentNodeId]="node.parentId" matTreeNodePadding matTreeNodePaddingIndent="24" [matTreeNodePadding]="node.level" [ngClass]="{ 'node-selected': node.isSelected() }"
        cdkDrag [cdkDragData]="node" [cdkDragDisabled]="isDragDisabled(node)">
    <ctbox-tree-node class="mat-tree-node-container {{node?.type}}" [classNodeActions]="actions[0]" [node]="node" [actions]="actions" [treeControl]="treeControl" [canDropFromOutside]="canDropFromOutside" [labelToMarkIsDroppable]="labelToMarkIsDroppable"
        [attributeToReadInDropElement]="attributeToReadInDropElement" [expansionModel]="expansionModel" [isTreeLocked]="isTreeLocked" [eventEdit]="eventEdit.asObservable()" (onNodeAction)="doNodeAction($event)" (expandNode)="expandFolder($event)">
        </ctbox-tree-node>
        <ctbox-tree-actions [node]="node" [actions]="actions" [isTreeLocked]="isTreeLocked" [menuList]="defineMenuList(node)" [updateNodeObservable]="nodeActionRefresh$" (onNodeAction)="doNodeAction($event)"></ctbox-tree-actions>
    </mat-tree-node>
</mat-tree>
