/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { UserDTO } from './userDTO';
import { UserFolderPermissionsDto } from './userFolderPermissionsDto';

export interface DocumentFolderDto {
    id?: string;
    name?: string;
    documentFolderParentId?: string;
    childrenFolder?: Array<DocumentFolderDto>;
    order?: number;
    ownerId?: string;
    owner?: UserDTO;
    userPermissions?: UserFolderPermissionsDto;
}
