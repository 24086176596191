/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { PermissionUserHierarchyDto } from './permissionUserHierarchyDto';
import { UserDTO } from './userDTO';


export interface PermissionsUserByUserCmcDto {
    userId?: string;
    user?: UserDTO;
    isRegistered?: boolean;
    hasAdministratorPermission?: boolean;
    hasTemplateInquirePermission?: boolean;
    hasTemplateManageAndClausePermission?: boolean;
    templateFolderPermissions?: Array<PermissionUserHierarchyDto>;
}
