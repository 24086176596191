import {
    AfterViewChecked, ChangeDetectorRef, Component, EventEmitter, Input,
    OnChanges, OnDestroy, OnInit, Output, SimpleChanges
} from '@angular/core';
import HtmlDiff from 'htmldiff-js';
import { IHtmlComparerService } from 'src/app/core/shared/services/html-comparer/html-comparer.service.interface';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-html-comparer',
    templateUrl: './html-comparer.component.html'
})
export class HtmlComparerComponent implements OnInit, AfterViewChecked, OnDestroy, OnChanges {

    @Input() currentHtml: string;
    @Input() newHtml: string;
    @Input() addScrollSm: boolean;
    @Input() addScrollMd: boolean;
    @Input() addScrollLg: boolean;

    @Input() replaceFilledInput: boolean;
    @Input() cssModalClassName: string;
    @Input() alreadyInComparation: boolean;

    @Output() modifiedHtml = new EventEmitter<string>();

    currentDocumentWitChangesMarked = '';
    currentChangeIndex = 0;
    currentChangesNumber = 0;

    currentDocumentWithChangesMarkedSubscription: Subscription;
    currentDocumentCleanSubscription: Subscription;

    comparedHtml: string;
    cleanHtml: string;

    constructor(private cdRef: ChangeDetectorRef,
        private comparerService: IHtmlComparerService
    ) {
        this.comparedHtml = this.currentHtml;
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.currentHtml || changes.newHtml) {
            this.compareChanges();
        }

    }

    ngOnInit(): void {
        this.addScrollSm = false;
        this.addScrollMd = false;
        this.addScrollLg = false;

        this.currentDocumentWithChangesMarkedSubscription = this.comparerService
            .getCurrentDocumentWithChangesMarkedBehaviorSubject().subscribe((documentComparer: Document) => {
                this.currentDocumentWitChangesMarked = this.comparerService.getCurrentDocumentWithChangesMarked();
            });

        this.currentDocumentCleanSubscription = this.comparerService
            .getCurrentDocumentCleanBehaviorSubject().subscribe((documentCleaned: string) => {
                if (documentCleaned.trim() === '') {
                    this.cleanHtml = this.currentHtml;
                    return;
                }
                this.cleanHtml = documentCleaned;
                this.notifyChange();
            });

        if (this.alreadyInComparation) {
            this.comparedHtml = this.comparerService.getCurrentDocumentComparation();
            return;
        }

        this.compareChanges();
    }

    ngAfterViewChecked() {
        this.cdRef.detectChanges();
    }

    ngOnDestroy() {
        if (this.currentDocumentWithChangesMarkedSubscription) {

            this.currentDocumentWithChangesMarkedSubscription.unsubscribe();
        }

        if (this.currentDocumentCleanSubscription) {
            this.currentDocumentCleanSubscription.unsubscribe();
        }
    }

    public setCleanDocumentAsComparerDocument() {
        this.comparerService.setCleanDocumentAsComparerDocument();
    }

    public compareChanges() {
        this.comparedHtml = HtmlDiff.execute(this.currentHtml, this.newHtml);
        this.comparerService.setCurrentDocumentComparation(this.comparedHtml);
        this.comparerService.calculateCurrentChanges();
    }

    public notifyChange() {
        this.modifiedHtml.emit(this.cleanHtml);
    }

}
