import { InputPlugin } from '../../plugins/input/input-plugin';
import { BaseCommand } from '../base-command';

export abstract class BaseLinkedFieldCommand extends BaseCommand {

    labelDataModel = InputPlugin.MODEL_ENTITIES.container.model;

    protected enableCommandIfCursorInsideInputContent(): void {
        const model = this.editor.model;
        const selection = model.document.selection;
        const position = selection.getFirstPosition();
        const element = position.parent;
        if (!element || !element.is('element')) {
            this.isEnabled = false;
            return;
        }

        this.isEnabled = position.parent?.name === InputPlugin.MODEL_ENTITIES.content.model;
    }
}
