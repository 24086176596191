export enum DocumentEditionTabs {
    sheet = 'sheet',
    text = 'text',
    expirations = 'expirations',
    versions = 'versions',
    related = 'related',
    workflow = 'workflow',
    share = 'share',
    configureSigning = 'configure-signing',
    signingState = 'signing-state',
    history = 'history',
    download = 'download'
}
