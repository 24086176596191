import { SignatureUserLocationParametersDTO } from 'src/app/api/model/signatureUserLocationParametersDTO';
import { Component, Input, OnInit, SimpleChanges, OnChanges, ViewChild, ElementRef,OnDestroy,ViewEncapsulation } from '@angular/core';
import { PdfViewerComponent } from 'ng2-pdf-viewer';
import { DocType, DocumentDTO, DocumentSignatureDTO, SignatureUserDTO } from 'src/app/api';
import { IDocumentSignatureNoEditableService } from 'src/app/core/standard/services/documents/document-signature-no-editable/document-signature-no-editable.service.interface';
import { IDocumentEditionService } from 'src/app/pages/standard/contracts-library/services/document-edition/document-edition.service.interface';
import { ThemePalette } from '@angular/material/core';
import { LegacyProgressBarMode as ProgressBarMode } from '@angular/material/legacy-progress-bar';
import { Subscription } from 'rxjs';

@Component({
    selector: 'app-cb-pdf-viewer',
    templateUrl: './cb-pdf-viewer.component.html',
    styleUrls: ['./cb-pdf-viewer.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class CBPdfViewerComponent implements OnInit, OnChanges, OnDestroy {
    @Input() canAddSigner: boolean;
    @Input() pdfFile: File;
    @Input() mainAncestorComponent: ElementRef;
    @Input() canEdit: boolean;

    @ViewChild('docViewer') docViewer: PdfViewerComponent;
    @ViewChild('pdfContainer') pdfContainer!: ElementRef;

    public isPreviewEnable = false;
    public signatureUserPreview: SignatureUserDTO;
    public shouldShowSignatureWithRole: boolean = false;
    public isDocumentAlreadyLoadedInViewer: boolean = false;
    public pdfSrc: ArrayBuffer;
    public progressBarValue: number = 0;
    public documentSignature: DocumentSignatureDTO;
    public mode: ProgressBarMode = 'determinate';
    public color: ThemePalette = 'primary';

    private readonly ARRAY_BUFFER_METHOD = 'arrayBuffer';
    private readonly signatureDefaultText = $localize`:@@FirmantePorDefecto:Firmante`;
    private readonly guidEmpty = '00000000-0000-0000-0000-000000000000';

    private filePagesRendered = new Set<number>();
    private documentSignatureNoEditableSubscription: Subscription;
    private documentSubscription: Subscription;

    constructor(private documentSignatureNoEditableService: IDocumentSignatureNoEditableService,
                private documentEditionService: IDocumentEditionService) {}

    public ngOnInit(): void {
        this.initializeComponent();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.pdfFile) {
            this.readFile();
        }
    }

    public ngOnDestroy(): void {
        if (this.documentSubscription) {
            this.documentSubscription.unsubscribe();
        }
        if (this.documentSignatureNoEditableSubscription) {
            this.documentSignatureNoEditableSubscription.unsubscribe();
        }
    }

    public hasUnsavedChanges(): boolean {
        return this.documentSignatureNoEditableService.getHasChangesInFile();
    }

    public resetUnsavedChanges(): void {
        this.documentSignatureNoEditableService.resetHasChangesInFile();
    }

    public textLayerRendered(e: { source: any, pageNumber: number, numTextDivs: number }): void {
        setTimeout(() => {
            this.filePagesRendered.add(e.pageNumber);
            this.progressBarValue = (100 * this.filePagesRendered.size) / this.docViewer.pdfViewer.pagesCount;
            this.shouldShowSignatureWithRole = true;
            this.isDocumentAlreadyLoadedInViewer = true;
            this.addClassOnViewerReady();
            this.filePagesRendered.clear();
        });
    }

    public addPreviewSigner(): void {
        const signatureUserLocationParam = this.getSignatureUserLocationParam();
        const documentSignature = this.getDocumentSignature();

        this.signatureUserPreview = this.createSignatureUserPreview(signatureUserLocationParam, documentSignature);
        this.isPreviewEnable = true;
    }

    public afterPreviewEnd(): void {
        this.isPreviewEnable = false;
        this.signatureUserPreview = null;
    }

    public deleteSigner(signerToDelete: SignatureUserDTO) {
        this.documentSignatureNoEditableService.removeSignatureUser(signerToDelete?.role);
    }

    private getDocumentSignature(): DocumentSignatureDTO {
        let documentSignature = this.documentSignatureNoEditableService.getCurrentDocumentSignature();

        if (documentSignature !== null) {
            return documentSignature;
        }

        this.documentSubscription = this.documentEditionService.getDocumentSubscription()
            .subscribe((document: DocumentDTO) => {
                if (document && !document.documentSignature) {
                    const newDocumentSignature: DocumentSignatureDTO = {
                        docStatus: 2,
                        documentId: document.id,
                        downloaded: false,
                        isSequential: true,
                    };

                    document.documentSignature = newDocumentSignature;
                    documentSignature = newDocumentSignature;
                    this.documentSignature = documentSignature;
                }

                this.documentSignatureNoEditableService.setCurrentDocumentSignature(document?.documentSignature);
            });

        return documentSignature;
    }

    private createSignatureUserPreview(signatureUserLocationParam: any, documentSignature: DocumentSignatureDTO): any {
        return {
            role: this.documentSignatureNoEditableService.getDefaultRolNotInSigners(this.signatureDefaultText),
            documentSignatureId: this.getSignatureId(documentSignature),
            signatureUserLocationParameters: signatureUserLocationParam,
            order: this.getSignatureOrder(documentSignature),
            documentType: DocType.NUMBER_0
        };
    }

    private getSignatureOrder(documentSignature: DocumentSignatureDTO): number {
        return documentSignature.signaturesUsers ? documentSignature.signaturesUsers.length : 0;
    }

    private getSignatureId(documentSignature: DocumentSignatureDTO): string {
        return documentSignature.id ? documentSignature.id : this.guidEmpty;
    }

    private addClassOnViewerReady(): void {
        const ng2PdfViewerContainer = this.pdfContainer.nativeElement.querySelector('.ng2-pdf-viewer-container');
        if (ng2PdfViewerContainer) {
            ng2PdfViewerContainer.classList.add('pdf-viewer-document-loaded');
        }
    }

    private readFile(): void {
        if (!this.pdfFile) {
            this.pdfSrc = new ArrayBuffer(0);
            return;
        }

        this.isDocumentAlreadyLoadedInViewer = false;
        this.filePagesRendered.clear();

        this.pdfFile[this.ARRAY_BUFFER_METHOD]().then((buffer: ArrayBuffer) => {
            this.pdfSrc = buffer;
        });
    }

    private getSignatureUserLocationParam(): SignatureUserLocationParametersDTO {
        return {  page: 1, posX: 1, posY: 1, sizeX: 60, sizeY: 23 };
    }

    private initializeComponent(): void {
        this.readFile();

        this.documentSubscription = this.documentEditionService.getDocumentSubscription()
            .subscribe((document: DocumentDTO) => {
                this.documentSignatureNoEditableService.setCurrentDocumentSignature(document.documentSignature);
                this.documentSignature = document.documentSignature;
                this.documentSignatureNoEditableService.resetHasChangesInFile();
            });

        this.documentSignatureNoEditableSubscription = this.documentSignatureNoEditableService.getDocumentSignatureSubscription()
            .subscribe((documentSignature: DocumentSignatureDTO) => {
                this.documentSignature = documentSignature;
            });
    }

}
