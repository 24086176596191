import { Injectable } from '@angular/core';
import { TrainingVideosCodes } from 'src/app/core/shared/enums/training-videos.enum';
import { environment } from 'src/environments/environment';
import { IVideoManagementService } from './video-management.service.interface';

@Injectable({
    providedIn: 'root'
})
export class VideoManagementService extends IVideoManagementService {

    private VIMEO_IFRAME_CREATION = 'https://vimeo.com/691895994';
    private VIMEO_IFRAME_LANDING = 'https://vimeo.com/711290765';
    private VIMEO_IFRAME_HOW_WORK_WITH_TEMPLATES = 'https://vimeo.com/691896293';
    private VIMEO_IFRAME_HOW_CREATE_YOUR_LIBRARY_OF_CLAUSES = 'https://vimeo.com/691896686';
    private VIMEO_IFRAME_HOW_SHARE_DOCUMENTS = 'https://vimeo.com/691897072';
    private VIMEO_IFRAME_HOW_REVIEW_DOCUMENTS = 'https://vimeo.com/691897319';
    private VIMEO_IFRAME_SAVE_DOCUMENT_VERSION = 'https://vimeo.com/693144635';
    private VIMEO_IFRAME_HOW_SIGN_DOCUMENTS = 'https://vimeo.com/700372613';
    private VIMEO_IFRAME_HOW_CREATE_ALERTS_TEMPLATES = 'https://vimeo.com/730901068';
    private VIMEO_IFRAME_HOW_CREATE_ALERTS_CLAUSES = 'https://vimeo.com/730901207';
    private VIMEO_IFRAME_HOW_MANAGE_PERMISSIONS = 'https://vimeo.com/748681768';
    private options = [
        {
            id: TrainingVideosCodes.VideoLanding,
            url: this.getVideoIframe(this.VIMEO_IFRAME_LANDING)
        },
        {
            id: TrainingVideosCodes.CreationDocument,
            url: this.getVideoIframe(this.VIMEO_IFRAME_CREATION)
        },
        {
            id: TrainingVideosCodes.WorkWithTemplates,
            url: this.getVideoIframe(this.VIMEO_IFRAME_HOW_WORK_WITH_TEMPLATES)
        },
        {
            id: TrainingVideosCodes.CreateYourLibraryOfClauses,
            url: this.getVideoIframe(this.VIMEO_IFRAME_HOW_CREATE_YOUR_LIBRARY_OF_CLAUSES)
        },
        {
            id: TrainingVideosCodes.ShareDocuments,
            url: this.getVideoIframe(this.VIMEO_IFRAME_HOW_SHARE_DOCUMENTS)
        },
        {
            id: TrainingVideosCodes.ReviewDocuments,
            url: this.getVideoIframe(this.VIMEO_IFRAME_HOW_REVIEW_DOCUMENTS)
        },
        {
            id: TrainingVideosCodes.SaveDocumentVersion,
            url: this.getVideoIframe(this.VIMEO_IFRAME_SAVE_DOCUMENT_VERSION)
        },
        {
            id: TrainingVideosCodes.SignDocuments,
            url: this.getVideoIframe(this.VIMEO_IFRAME_HOW_SIGN_DOCUMENTS)
        },
        {
            id: TrainingVideosCodes.AlertsTemplates,
            url: this.getVideoIframe(this.VIMEO_IFRAME_HOW_CREATE_ALERTS_TEMPLATES)
        },
        {
            id: TrainingVideosCodes.AlertsClauses,
            url: this.getVideoIframe(this.VIMEO_IFRAME_HOW_CREATE_ALERTS_CLAUSES)
        },
        {
            id: TrainingVideosCodes.ManagePermissions,
            url: this.getVideoIframe(this.VIMEO_IFRAME_HOW_MANAGE_PERMISSIONS)
        },
    ];

    public getUrlOption(id: number): string {
        return this.options.find(o => o.id === id)?.url;
    }

    public getAllTrainingOptions(): { id: number, url: string }[] {
        return this.options.filter(o => o.id !== TrainingVideosCodes.VideoLanding);
    }

    private getVideoIframe(url: string): string {
        if (url === null) {
            return '';
        }
        const re = /\/\/(?:www\.)?vimeo.com\/([0-9a-z\-_]+)/i;
        const matches = re.exec(url);
        const videoKeyInSite = 1;
        return environment.vimeoURL + matches[videoKeyInSite];
    }

}
