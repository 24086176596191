<mat-accordion class="workflow" [multi]="true" #accordion="matAccordion" [ngClass]="modeEdition">
    <mat-expansion-panel class="workflow mat-elevation-z" [disabled]="workflow?.isDisabled" [expanded]="workflow?.isExpanded" (opened)="beforePanelOpened(workflow)" (closed)="beforePanelClosed(workflow)" *ngFor="let workflow of workflowList" #mapanel="matExpansionPanel">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <ng-container *ngIf="isEditable">
                    <mat-checkbox (change)="checkAction($event, mapanel)">
                        <label>{{workflow?.title}}</label>
                    </mat-checkbox>
                </ng-container>
                <label *ngIf="!isEditable">{{workflow?.title}}</label>
            </mat-panel-title>
            <mat-panel-description>
                <p>{{workflow?.description}}</p>
            </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="workflow-item" *ngFor="let user of workflow.datasource; let i =index" cdkDrag [cdkDragDisabled]="!isEditable" cdkDragLockAxis="y">
            <ctbox-workflow-user class="workflow-user {{modeEdition}}" [modeEdition]="modeEdition" [user]="user" [index]="i" [parentGroup]="workflow.parentGroup" [workFlowStarted]="workFlowStarted" [workFlowTitle]="workflow?.title" [numUsers]="workflow.datasource.length"
                (resumeWorkflowSelected)="resumeWorkflow()"></ctbox-workflow-user>
        </div>
        <mat-action-row *ngIf="workflow?.isExpanded && isEditable" class="workflow-button">
            <ctbox-button buttonType="secondary" buttonIcon="add" buttonText="Añadir persona" i18n-buttonText="@@AñadirPersona"></ctbox-button>
        </mat-action-row>
    </mat-expansion-panel>
</mat-accordion>