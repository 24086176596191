import { Injectable } from '@angular/core';
import { Conversion, Element, toWidget, toWidgetEditable, ViewElement } from 'ckeditor5';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';
import { RadioEditionViewUtilsService } from '../../utils/radio/radio-edition-view-utils.service';
import { PluginUtilsService } from '../../../utils/plugin-utils.service';
import { ContextEditorTypes } from '../../models/base/context-editor-types';

@Injectable({
    providedIn: 'root'
})
export class RadioModelToEditorViewConverterService {

    private editorUtilsService: RadioEditionViewUtilsService;
    protected pluginUtils: PluginUtilsService;

    constructor() {
        this.editorUtilsService = new RadioEditionViewUtilsService();
        this.pluginUtils = new PluginUtilsService();
    }

    public configureConverters(conversion: Conversion) {
        this.containerConversion(conversion);
        this.optionCountAttributeConversion(conversion);
        this.optionConversion(conversion);
        this.inputConversion(conversion);
        this.descriptionConversion(conversion);
        this.contentConversion(conversion);
    }


    private optionCountAttributeConversion(conversion: Conversion) {
        conversion.for('editingDowncast').attributeToAttribute({
            model: RadioPlugin.MODEL_ENTITIES.optionCount.model,
            view: RadioPlugin.MODEL_ENTITIES.optionCount.editionView
        });
    }

    private createWidgetElement(widgetElement, viewWriter, modelItem, radioPluginContext, contextEditorTypeClause): ViewElement {
        const dataEmbeddedIn = modelItem.parent.parent.getAttribute("data-embedded-in") !== undefined ? modelItem.parent.parent.getAttribute("data-embedded-in").toString() : "undefined";
        const isEditable = this.pluginUtils.isElementInteractableInTargetEditor(dataEmbeddedIn, radioPluginContext, contextEditorTypeClause);
        if (isEditable) {
            return toWidgetEditable(widgetElement, viewWriter);
        } else {
            return toWidget(widgetElement, viewWriter);
        }
    }

    private contentConversion(conversion: Conversion) {
        const radioPluginContext = RadioPlugin.contextEditor;
        const contextEditorTypeClause = ContextEditorTypes.CLAUSE;
        conversion.for("editingDowncast").elementToElement({
            model: RadioPlugin.MODEL_ENTITIES.content.model,
            view: (modelItem: any, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createContentElementEditionView(
                    viewWriter
                );
                return this.createWidgetElement(widgetElement, viewWriter, modelItem, radioPluginContext, contextEditorTypeClause);
            },
        });
    }

    private descriptionConversion(conversion: Conversion) {
        const radioPluginContext = RadioPlugin.contextEditor;
        const contextEditorTypeClause = ContextEditorTypes.CLAUSE;
        conversion.for("editingDowncast").elementToElement({
            model: RadioPlugin.MODEL_ENTITIES.description.model,
            view: (modelItem: any, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createDescriptionElementEditionView(viewWriter);
                return this.createWidgetElement(widgetElement, viewWriter, modelItem, radioPluginContext, contextEditorTypeClause);
            },
        });
    }

    private inputConversion(conversion: Conversion) {
        conversion.for("editingDowncast").elementToElement({
            model: RadioPlugin.MODEL_ENTITIES.input.model,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createRadiusElementEditionView(
                    modelItem,
                    viewWriter
                );

                return toWidget(widgetElement, viewWriter);
            },
        });
    }

    private optionConversion(conversion: Conversion) {
        conversion.for("editingDowncast").elementToElement({
            model: RadioPlugin.MODEL_ENTITIES.option.model,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createOptionEditionView(
                    modelItem,
                    viewWriter
                );

                return toWidget(widgetElement, viewWriter);
            },
        });
    }

    private containerConversion(conversion: Conversion) {
        conversion.for("editingDowncast").elementToElement({
            model: RadioPlugin.MODEL_ENTITIES.container.model,
            view: (modelItem: Element, { writer: viewWriter }) => {
                const widgetElement = this.editorUtilsService.createRadiusEditorView(
                    modelItem,
                    viewWriter
                );

                return toWidget(widgetElement, viewWriter);
            },
        });
    }
}
