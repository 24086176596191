import { Directive, Inject } from "@angular/core";
import { ButtonView, Locale } from "ckeditor5";
import { UI_CLASSES } from "../styles/styles-constants";
import BaseView from "../base-view.directive";
import { BaseInputModel } from "../../models/input/base-input-model";

@Directive({
    selector: "app-linked-field-balloon-view",
})
export default class LinkedFieldBalloonView extends BaseView {

    public navigateButtonView: ButtonView;
    public editButtonView: ButtonView;

    public inputModelToEdit: BaseInputModel = {
        id: '',
        alias: ''
    };

    private NAVIGATE_OPTION =  $localize`:@@PluginLinkedFieldBalloonBotonNavegar:NAVEGAR`;
    private DELETE_OPTION =  $localize`:@@PluginLinkedFieldBalloonBotonBorrar:BORRAR`;
    private EDIT_OPTION =  $localize`:@@PluginLinkedFieldBalloonBotonEditar:EDITAR`;

    constructor( @Inject(Locale) locale?: Locale) {
        super(locale);

        this.navigateButtonView = this.createButton(this.NAVIGATE_OPTION, null, UI_CLASSES.SVG_ICONS.LINK);
        this.editButtonView = this.createButton(this.EDIT_OPTION, null, UI_CLASSES.SVG_ICONS.EDIT);
        this.deleteButtonView = this.createButton(this.DELETE_OPTION, null, UI_CLASSES.SVG_ICONS.DELETE);

        this.items = this.createCollection([ this.navigateButtonView, this.editButtonView, this.deleteButtonView ]);

        this.setTemplateForm("ck-linked-field-balloon");
    }

    public ngOnDestroy(): void {
        super.destroy();

        this.focusTracker.destroy();
        this.keystrokes.destroy();
    }

    public get id(): string {
        return this.inputModelToEdit.id!;
    }

    public set InputModel(value: BaseInputModel) {
        this.inputModelToEdit = value;
    }

   public resetFormStatus(): void {
       this.inputModelToEdit = {
           id: '',
           alias: ''
       }
    }
}

export type LinkedFieldBalloonValidatorCallback = (form: LinkedFieldBalloonView) => string | undefined;
