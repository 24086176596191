import { Component, OnInit, OnDestroy, HostBinding } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';
import { SectionStyleConfigService } from './core/shared/services/section-style-config/section-style-config.service';
import { CustomIconService } from './core/shared/services/custom-icon-service/custom-icon.service';
import { CustomizeConfiguration } from '../config/customisation';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

    public imgLogo = 'assets/images/logos/';
    public customizeId: string;
    public customizeIdName: string;
    public customizeName: string;
    public customizeLogoName: string;
    public customizeProductName: string;
    public customizeProductLogoName: string;
    public customizeclassProduct: string;

    private classModule: string;
    private destroy$ = new Subject<void>();

    @HostBinding('class') get ClassModule() {
        return this.classModule;
    }

    constructor(
        private titleService: Title,
        private sectionStyleConfigService: SectionStyleConfigService,
        private customIconService: CustomIconService,
        private readonly customisation: CustomizeConfiguration
    ) {
        this.customizeId = this.customisation.customizeId;
        this.customizeIdName = this.customisation.customizeIdName;
        this.customizeName = this.customisation.customizeName;
        this.customizeclassProduct = this.customisation.customizeClassProduct;
        this.customizeLogoName = this.imgLogo + this.customisation.customizeLogoName;
        this.customizeProductName = this.customisation.customizeProductName;
        this.customizeProductLogoName = this.imgLogo + this.customisation.customizeProductLogoName;
    }

    public ngOnInit(): void {
        this.customizeTitleApp(this.customizeProductName);
        this.customizeFavicon(this.customizeclassProduct);
        this.sectionStyleConfigService.addBodyClass(this.customizeclassProduct);
        this.sectionStyleConfigService.getData$().pipe(takeUntil(this.destroy$)).subscribe(data => {
            this.sectionStyleConfigService.toogleBodyClassByModule(data.module, 'root');
            this.classModule = this.customizeclassProduct + ' ' + data.module;

        });
        this.customIconService.init();

    }

    public ngOnDestroy(): void {
        this.destroy$.next();
        this.destroy$.complete();
    }

    public customizeTitleApp(title: string): void {
        this.titleService.setTitle(title);
    }


    public customizeFavicon(product: string): void {
        const favicon: any = document.querySelector(`link[rel*='icon']`) || document.createElement('link');
        favicon.type = 'image/x-icon'
        favicon.rel = 'shortcut icon'
        const href = 'assets/images/' + product + '/favicon.ico';
        favicon.href = href || 'base64'
        document.getElementsByTagName('head')[0].appendChild(favicon)
    }
}
