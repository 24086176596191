import { Pipe, PipeTransform } from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';

@Pipe({
    name: 'decodeHtml'
})
export class DecodeHtml implements PipeTransform {

    transform(html: string): SafeHtml {
        let txt = document.createElement("textarea");
        txt.innerHTML = html;
        return txt.value;
    }
}
