import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { SearchResultType } from 'src/app/core/shared/models/search-result-type';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { SearchResultFileStorageService } from 'src/app/core/shared/services/search-result/search-result-file-storage.service';

@Component({
    selector: 'app-search-box-files',
    templateUrl: './search-box-files.component.html',
})
export class SearchBoxFilesComponent {
    @Input() searchTerm: string;
    @Input() fileType: SearchResultType = SearchResultType.InquireTemplates;

    private searchResultPath: string;
    private searchResultTemplatePath = 'templates-library/templates-search-result-list';
    private searchResultTemplateManagerPath = 'templates-management-library/templates-search-result-list';
    private searchResultClausePath = 'clauses-library/clauses-search-result-list';

    constructor(
        private genericDialogService: GenericDialogService,
        private router: Router,
        private readonly searchResultFileStorageService: SearchResultFileStorageService
    ) { }

    public search(searchTerm: string) {
        if (searchTerm === '' || searchTerm === null) {
            const message = $localize`:@@ErrorBusquedaVaciaMensaje:Introduce un término de búsqueda`;
            this.genericDialogService.showMessage(message);
            return;
        }

        switch (this.fileType) {
            case SearchResultType.Clauses:
                this.searchResultPath = this.searchResultClausePath;
                break;
            case SearchResultType.ManagerTemplates:
                this.searchResultPath = this.searchResultTemplateManagerPath;
                break;
            case SearchResultType.InquireTemplates:
            default:
                this.searchResultPath = this.searchResultTemplatePath;
        }

        this.searchResultFileStorageService.resetSearchResultValues(this.fileType);
        this.router.navigate([this.searchResultPath], { queryParams: { searchTerm } });
    }
}
