import { NodeTreeActionType } from '../enums/node-tree-action-type.enum';
import { Injectable } from '@angular/core';
import { ITreeStandardService } from './tree-standard-service.interface';
import { FileFlatNode } from '../models/file-flat-node.model';
import { NodeTreeAction } from '../models/node-tree-action.model';
import { AdditionalParam } from '../models/additional-param.model';
import { FileNode } from '../models/file-node.model';

@Injectable({
    providedIn: 'root'
})

export class TreeStandardService extends ITreeStandardService {

    public createNodeTreeAction(node: FileFlatNode, action: NodeTreeActionType): NodeTreeAction {
        return this.createNodeTreeActionWithCallbacks(node, action, null, null, null);
    }

    public createNodeTreeActionWithAdditionalParam(node: FileFlatNode, action: NodeTreeActionType,
                                                   actionParam: AdditionalParam): NodeTreeAction {
        return this.createNodeTreeActionWithCallbacks(node, action, actionParam, null, null);
    }

    public createNodeTreeActionWithCallbacks(node: FileFlatNode, action: NodeTreeActionType, additionalParam: AdditionalParam,
                                             successfulActionResult: (node: FileFlatNode) => void,
                                             failActionResult: (node: FileFlatNode) => void): NodeTreeAction {
        const nodeTreeAction: NodeTreeAction = {
            node,
            typeEvent: action,
            additionalParam,
            successfulAction: successfulActionResult,
            failAction: failActionResult
        };

        return nodeTreeAction;
    }

    public createAdditionalParamWithDroppedObjectId(droppedObjectId?: string): AdditionalParam {
        return this.createAdditionalParam(null, null, droppedObjectId, null);
    }

    public createAdditionalParamWithDestinationNode(destinationNode?: FileNode): AdditionalParam {
        return this.createAdditionalParam(null, destinationNode, null, null);
    }

    public createAdditionalParamWithIsChecked(isChecked?: boolean): AdditionalParam {
        return this.createAdditionalParam(null, null, null, isChecked);
    }

    public createAdditionalParamWithCheckedNode(isChecked?: boolean, checkedNodes?: Array<string>): AdditionalParam {
        return this.createAdditionalParam(checkedNodes, null, null, isChecked);
    }

    private createAdditionalParam(checkedNodes?: Array<string>, destinationNode?: FileNode,
                                  droppedObjectId?: string, isChecked?: boolean): AdditionalParam {
        const additionalParam: AdditionalParam = {
            checkedNodes,
            destinationNode,
            droppedObjectId,
            isChecked
        };
        return additionalParam;
    }
}
