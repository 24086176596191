import { Injectable } from '@angular/core';
import { TemplateDocumentDTO } from 'src/app/api';
import { UserPermissionsOnDocumentDTO } from 'src/app/api/model/userPermissionsOnDocumentDTO';
import { DocumentTypeCodes } from 'src/app/core/shared/enums/document-type-codes.enum';
import { ITemplatesDocumentRevisionListService } from '../templates-document-revision-list/templates-document-revision-list.service.interface';
import { ITemplatePreviewService } from './template-preview.service.interface';

@Injectable({
    providedIn: 'root'
})
export class TemplatePreviewService implements ITemplatePreviewService {

    constructor(private templateDocumentRevisionListService: ITemplatesDocumentRevisionListService) {
    }

    public orderBy(templateDocument: TemplateDocumentDTO, sortHeaderId: string): any {
      switch (sortHeaderId) {
          case 'icon':
              return this.getIconToOrder(templateDocument);
            case 'lastModificationUser':
                return this.templateDocumentRevisionListService.getAuthorSurnameToOrder(templateDocument.document[sortHeaderId]);
            case  'name':
            case  'modificationTimeStamp':
                return templateDocument.document[sortHeaderId];
            case 'actionsColumn':
                return this.getActionToOrder(templateDocument.document?.userPermissions);

      }

      return templateDocument[sortHeaderId];
    }

    private  getActionToOrder( userPermissions: UserPermissionsOnDocumentDTO): number {
        let actionsValue = 0;

        if (userPermissions === null) {
            return actionsValue;
        }

        if (userPermissions.canEdit) {
            actionsValue += 2;
        }

        if (userPermissions.canReviewTemplateChanges) {
            actionsValue += 5;
        }

        return actionsValue;
      }

    private getIconToOrder( templateDocument: TemplateDocumentDTO): number {
        let iconValue = 0;

        if (templateDocument === null) {
            return iconValue;
        }

        if (templateDocument.document?.documentTypes?.code === DocumentTypeCodes.Template) {
            iconValue += 2;
        }

        if (templateDocument.document?.documentTypes?.code !== DocumentTypeCodes.Template) {
            iconValue += 3;
        }

        return iconValue;
    }

    public orderListBy(templateDocument: TemplateDocumentDTO[], sortHeaderId: string): TemplateDocumentDTO[] {
        return templateDocument.sort((a, b) => this.orderBy(a, sortHeaderId) - this.orderBy(b, sortHeaderId));
    }
}
