import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ShowHideElementsService {

  constructor() { }

  public hideElement( element: Element): string {
    const display = 'display: ';
    const hidden = display +'hidden;';
    let currentStyle = element.getAttribute('style');
    const regExp = new RegExp(display + '(\w)+;');
    let matches = currentStyle.match(regExp);
    let oldState = '';

    if(matches) {
      currentStyle =  currentStyle.replace(regExp, hidden);
      const matchStartIndex =  currentStyle.indexOf(matches[0]) + display.length;
      const matchLength = matches[0].length;
      const matchWithoutStateLength = (display + ';').length;
      const lengthState = matchLength - matchWithoutStateLength;
      oldState = currentStyle.substring(matchStartIndex, lengthState);
    } else {
      currentStyle = currentStyle + hidden;
    }

    element.setAttribute('style', currentStyle);

    return oldState;
  }
  public restoreElement( element: Element, oldState: string): void {
    let replaceStyle = '';
    if(oldState !== ''){
      replaceStyle = 'display: ' + oldState + ';';
    }
    let currentStyle = element.getAttribute('style');
    const hiddenStyle = 'display: hidden;';

    if(!currentStyle.search(hiddenStyle)) {
      return;
    }

    currentStyle =  currentStyle.replace(hiddenStyle, replaceStyle);
    element.setAttribute('style', currentStyle);
  }
}
