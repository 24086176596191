import { Component, Input, SimpleChanges, OnChanges, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { SectionStyleConfigService } from 'src/app/core/shared/services/section-style-config/section-style-config.service';


@Component({
    selector: 'ctbox-sidenav',
    templateUrl: './ctbox-sidenav.component.html'
})
export class CtboxSidenavComponent implements OnInit, OnChanges, AfterViewInit {

    @ViewChild('sidenav') sidenav: MatSidenav;

    @Input() public customClass: string;
    @Input() public buttonIconOpem = 'keyboard_double_arrow_left';
    @Input() public buttonIconFolded = 'folder';
    @Input() public isFolderTreeOpen = true;
    @Input() public hasbuttonFolded = true;

    public data$ = this.sectionStyleConfigService.getValuesFromData$();
    public buttonIconToggle = 'folder';
    public isFolded = false;

    constructor(private sectionStyleConfigService: SectionStyleConfigService) { }

    public ngOnInit(): void {
        this.isFolded = !this.sidenav.opened;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.isFolderTreeOpen) {
            this.toogleSideNav();
        }
    }

    public ngAfterViewInit() {
        this.toogleSideNav();
    }

    public sidenavToggle() {
        this.sidenav.toggle();
        this.isFolded = !this.sidenav.opened;
        this.buttonIconToggle = this.sidenav.opened ? this.buttonIconOpem : this.buttonIconFolded;
    }

    private toogleSideNav() {
        if (this.isFolderTreeOpen === true || this.isFolderTreeOpen === undefined) {
            this.sidenav.open();
        } else {
            this.sidenav.close();
        }
        this.isFolded = !this.sidenav.opened;
        this.buttonIconToggle = this.sidenav.opened ? this.buttonIconOpem : this.buttonIconFolded;
    }
}
