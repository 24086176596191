<div class="info">
    <div class="info-header" *ngIf="icon">
        <mat-icon class="big">{{icon}}</mat-icon>
    </div>
    <div *ngIf="title" class="info-title"> {{ title }} </div>
    <div *ngIf="subTitle" class="info-subtitle"> {{ subTitle }} </div>
    <div class="info-message" *ngIf="message && !hasSeveralLines">
        {{ message }}
    </div>
    <div class="info-message" *ngIf="message && hasSeveralLines">
        <p *ngFor="let line of message"> {{ line }}</p>
    </div>
</div>
