import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Configuration } from '../configuration';
import { ApiOperation, ApiProcessorService, ApiRequest, ApiResponse } from './api-processor.service';
import { IJsonPatchDocument } from '../model/json-patch.model';
import { Observable } from 'rxjs';

@Injectable()
export class ClausesService {

    protected basePath = '/clauses';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(private readonly apiProcessorService: ApiProcessorService) {
    }

    public patch(id: string, jsonPatchDocument: Array<IJsonPatchDocument>): Observable<any>{
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PATCH;
        apiRequest.endpointUrl = `${this.basePath}/${id}`;
        apiRequest.body = jsonPatchDocument;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
