import { Injectable } from '@angular/core';
import { ClauseFolderDto } from 'src/app/api';

@Injectable()
export class ClauseFolderStorage {
    private folders: ClauseFolderDto[];

    public setFolders(folders: ClauseFolderDto[]): void {
        this.folders = folders;
    }

    public getClauseFolders(): ClauseFolderDto[] {
        return this.folders;
    }
}
