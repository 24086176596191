<mat-sidenav-container class="content-main with-title-tabs with-sidenav resized flex-row border-left versions" [ngClass]="{'folded': isFolded, 'opened': !isFolded}" autosize>
    <mat-sidenav class="versions-sidenav" mode="side" #sideversion opened [ngClass]="{'folded': isFolded, 'opened': !isFolded}">
        <version-list (notifyNewVersionSelected)="onNotifyNewVersionSelected($event)" (notifyVersionInfoHasChanged)="onNotifyVersionInfoHasChanged($event)" [versionsList]="versions"></version-list>
    </mat-sidenav>
    <mat-sidenav-content class="versions-editor">
        <div class="actions template">
            <div class="actions-bar">
                <div *ngIf="showComparerVersionButton" class="actions-buttons">
                    <ctbox-button buttonIcon="expand_more"
                        [buttonIconPositionRight]="true"
                        buttonText="Comparar con versión anterior"
                        i18n-buttonText="@@CompararVersionAnterior"
                        customClass="border"
                        [matMenuTriggerFor]="menu">
                    </ctbox-button>
                    <mat-menu #menu="matMenu" class="filter-state">
                        <button mat-menu-item *ngFor="let version of versionsAvailableToCompare" (click)="onClickShowVersionsCompareResult(version)">
                            {{version.label}}
                        </button>
                    </mat-menu>
                </div>
            </div>
        </div>
        <div class="content-main with-tabs-title-tabs-actions with-sidenav border-default">
            <app-html-viewer class="versions-editor" [html]="htmlVersion" [addScrollSm]="true" [replaceFilledInput]="false"></app-html-viewer>
        </div>
    </mat-sidenav-content>
</mat-sidenav-container>
