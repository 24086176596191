import { Injectable } from "@angular/core";
import { ButtonView, DecoupledEditor, View, } from "ckeditor5";
import { PluginConfig } from "../models/editor-plugin-config";
import { BasePlugin } from "../custom-plugins/plugins/base/base-plugin";

@Injectable({
    providedIn: "root",
})
export class EditorUtilsService {

    public enableDisablePlugins(editorInstance: DecoupledEditor, configuration: Array<PluginConfig>): void {
        for (const config of configuration) {
            this.enableDisablePlugin(editorInstance, config);
        }
    }

    public showToolbarButton(editorInstance: DecoupledEditor, label: string): void {
        this.setToolbarButtonVisibility(editorInstance, label, true);
    }

    public hideToolbarButton(editorInstance: DecoupledEditor, label: string): void {
        this.setToolbarButtonVisibility(editorInstance, label, false);
    }

    private setToolbarButtonVisibility(editorInstance: DecoupledEditor, label: string, isVisible: boolean): void {
        const button = this.getToolbarButton(editorInstance, label);
        if (button) {
            button.isVisible = isVisible;
        }
    }

    private enableDisablePlugin(editorInstance: DecoupledEditor, configuration: PluginConfig) {
        const plugin = editorInstance?.plugins.get(configuration.pluginName) as BasePlugin;

        if (!plugin) {
            return;
        }

        if (configuration.isEnabled) {
            plugin.clearForceDisabled(configuration.lockId);
            this.showToolbarButton(editorInstance, configuration.toolbarTooltip);
        } else {
            plugin.forceDisabled(configuration.lockId);
            this.hideToolbarButton(editorInstance, configuration.toolbarTooltip);
        }
    }

    private finderButtonByLabel = (view: View, label: string): boolean => {
        const button = view as ButtonView;
        return button?.label === label;
    };

    private getToolbarButton(editorInstance: DecoupledEditor, label: string): ButtonView | null {
        const toolbarElement = editorInstance.ui.view.toolbar.items.find(
            (item: View<HTMLElement>) => this.finderButtonByLabel(item, label)
        );
        return toolbarElement as ButtonView || null;
    }
}
