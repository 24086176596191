import { Component, EventEmitter, Input, Output, OnInit, OnDestroy, OnChanges, SimpleChanges, SimpleChange, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { debounceTime } from 'rxjs/operators';
import { Subject, Subscription } from 'rxjs';
import moment from 'moment';
import { isNumber } from 'lodash';

@Component({
    selector: 'ctbox-search-list',
    templateUrl: './ctbox-search-list.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtboxSearchListComponent<T> implements OnDestroy, OnInit, OnChanges {
    @Input() public set visibleFields(values: { [key: string]: {value: boolean, valueName: string}; }) {
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                this._visibleColumns[key] = { isVisible: values[key].value };
            }
        }
    }

    @Input() public set visibleColumns(values: { [key: string]: { isVisible: boolean }; }){
        if (values) {
            this._visibleColumns = values;
        }
    }

    @Input() public dataSource: MatTableDataSource<T>;
    @Input() public listLabel: string;
    @Input() public searchboxLabel: string;
    @Input() public debounceTime: number;
    @Input() public customGetValue: (obj: any, key: string) => string;
    @Input() public isDisabled = false;

    @Output() public searchPerformed: EventEmitter<string>;

    public lastTerm = '';
    private readonly searchValueKey = 'searchValue';

    private searchSubscription: Subscription;
    private readonly searchUpdated = new Subject<string>();

    private timeOut: NodeJS.Timer = null;
    private _visibleColumns: { [key: string]: { isVisible: boolean }; } = {};

    public constructor(private cdr: ChangeDetectorRef) {
        this.searchPerformed = new EventEmitter();
        this.debounceTime = 500;
    }

    public ngOnInit(): void {
        this.searchSubscription = this.searchUpdated.pipe(
            debounceTime(this.debounceTime)
        ).subscribe(() => this.searchPerformed.emit());
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['dataSource']) {
            const dataSourceControl: SimpleChange = changes.dataSource;
            const value = dataSourceControl.currentValue;
            if (value) {
                this.dataSource = value;
            }
        }

        if (changes['customGetValue']) {
            this.customGetValue = changes['customGetValue'].currentValue;
        }
    }

    public ngOnDestroy(): void {
        if (this.searchSubscription) {
            this.searchSubscription.unsubscribe();
        }
    }

    public onSearchUpdated(value: string): void {
        if (this.timeOut !== null){
            clearTimeout(this.timeOut);
        }
        const timer = this.dataSource.data.length > 5000 ? 500 : 1;

        this.timeOut  = setTimeout(() => {
            if (this.dataSource.paginator) {
                this.dataSource.paginator.firstPage();
            }

            this.lastTerm = value;
            const newFilter = this.getNewFilter(value);
            this.dataSource.filter = newFilter;
            this.searchUpdated.next(value);
            this.cdr.markForCheck();
            this.timeOut = null;
        }, timer);

    }

    public clearSearchField(): void {
        this.lastTerm = '';
        const filter = JSON.parse(this.dataSource.filter);
        filter[this.searchValueKey] = '';
        this.dataSource.filter = JSON.stringify(filter);
        this.searchUpdated.next(this.lastTerm);
        this.cdr.markForCheck();
    }

    public getFilterPredicate(data: any, filter: string): boolean {
        try {
            filter = JSON.parse(filter)[this.searchValueKey];
            if (!filter){
                return true;
            }
            const keys = Object.keys(data);

            for (const key of keys) {

                if (!this.columnIsVisible(key)){
                    continue;
                }
                let match = false;
                const value = this.getValue(data, key);

                if (!value) {
                    continue;
                }
                const parsedValue = value.toString().trim();
                if (isNumber(value)) {
                    match = parsedValue.includes(filter);
                }
                else {
                    if (parsedValue.includes('-') && (isNumber(filter) || filter.includes('/'))) {
                        const date = moment(value, moment.ISO_8601, true);

                        if (date.isValid()) {
                            match = this.dateContainsFilter(date, filter);
                        }
                        else {
                            match = this.matchText(parsedValue, filter);
                        }
                    }
                    else {
                        match = this.matchText(parsedValue, filter);
                    }
                }

                if (match) {
                    return true;
                }
            }
            return false;
        } catch (error) {
            return true;
        }
    }

    private matchText(text: string, filter: string): boolean{
        const parsedValue = this.stringWithNoAccents(text);
        return parsedValue.includes(filter);
    }

    private getNewFilter(value: string): string{
        let newFilter = {};
        const validFilterStatus = typeof (this.dataSource.filter) !== 'undefined' &&
            this.dataSource.filter !== null &&
            this.dataSource.filter !== '';
        if (validFilterStatus) {
            newFilter = JSON.parse(this.dataSource.filter);
        }
        newFilter[this.searchValueKey] = this.stringWithNoAccents(value.trim());

        return JSON.stringify(newFilter);
    }

    private columnIsVisible(col: string): boolean{
        if (!this._visibleColumns || this._visibleColumns.length) {
            return true;
        }

        return this._visibleColumns[col] ? this._visibleColumns[col].isVisible : false;
    }

    private dateContainsFilter(data: moment.Moment, filter: string): boolean{
        return data.format('DD/MM/YYYY').includes(filter);
    }

    private getValue(obj: any, key: string): string {
        if (this.customGetValue){
            return this.customGetValue(obj, key);
        }
        return obj[key];
    }

    private stringWithNoAccents(input: string): string {
        input = input.toLowerCase();
        return input.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
    }
}
