export class RowDropdownViewModel {
    name: string;
    titleText?: string;
    helpText?: string;
    iconSvg?: string;
}

export class DropdownViewModel {
    titleText?: string;
    helpText?: string;
    iconSvg?: string;
    buttonRowDropdownViewModel?: RowDropdownViewModel[] = []
}
