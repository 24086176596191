import { Editor, Element, TreeWalkerValue, Writer } from "ckeditor5";
import { InputBaseCommand } from "./input-base-command";
import { InputPlugin } from "../../plugins/input/input-plugin";
import { ShortTextInput } from "../../models/input/short-text-input-model";
import { InputUtilsService } from "../../utils/input/input-utils.service";

export default class EditInputCommand extends InputBaseCommand {

    private inputUtilsService: InputUtilsService;

    constructor(editor: Editor){
        super(editor);

        this.inputUtilsService = new InputUtilsService();
    }

    public override execute(inputModel: ShortTextInput): void {
        const editor = this.editor;
        if (!inputModel.id) {
            return;
        }

        editor.model.change( (writer: Writer) => {
            const range = writer.createRangeIn(this.editor.model.document.getRoot()!);
            const inputElement = this.getElementWithId(range, inputModel.id!)?.item as Element;
            if (inputElement) {
                this.setInputAttributes(writer, inputModel, inputElement);
            }
        });
    }

    public override refresh(): void {
        super.refresh();

        if (!this.isEnabled) {
            this.enableCommandIfCursorInsideInputContent();
        }
    }

    private setInputAttributes(writer: Writer, inputModel: ShortTextInput, inputElement: Element): void {
        const previusAlias = inputElement.getAttribute(InputPlugin.MODEL_ENTITIES.alias.model).toString();

        const attributes = {
            [InputPlugin.MODEL_ENTITIES.alias.model]: inputModel.alias,
            [InputPlugin.MODEL_ENTITIES.help.model]: inputModel.help,
            [InputPlugin.MODEL_ENTITIES.type.model]: inputModel.type,
            [InputPlugin.MODEL_ENTITIES.pattern.model]: inputModel.pattern,
            [InputPlugin.MODEL_ENTITIES.transform.model]: inputModel.transform,
            [InputPlugin.MODEL_ENTITIES.isValid.model]: true
        };

        for (const [key, value] of Object.entries(attributes)) {
            writer.setAttribute(key, value, inputElement);
        }

        writer.setAttribute(InputPlugin.MODEL_ENTITIES.help.model, inputModel.help, inputElement.getChild(0));

        this.setAliasInLinkedFields(previusAlias, inputModel);
        this.setAtributtesInLinkedFields(inputModel);
    }

    private setAtributtesInLinkedFields(inputModel: ShortTextInput): void {
        const alias = inputModel.alias;
        if (!alias) {
            return;
        }

        const inputsAndLinkedFieldsModel = this.inputUtilsService.getLinkedFieldsElementModelWithAlias(this.editor, alias);
        this.editor.model.change((writer: Writer)  => {
            inputsAndLinkedFieldsModel.forEach((input: TreeWalkerValue) => {
                writer.setAttribute(InputPlugin.MODEL_ENTITIES.help.model, inputModel.help, input.item);
                writer.setAttribute(InputPlugin.MODEL_ENTITIES.pattern.model, (inputModel as ShortTextInput).pattern, input.item);
                writer.setAttribute(InputPlugin.MODEL_ENTITIES.transform.model, (inputModel as ShortTextInput).transform, input.item);
                writer.setAttribute(InputPlugin.MODEL_ENTITIES.help.model, inputModel.help, (input.item as Element).getChild(0));
            });
        });
    }

    private setAliasInLinkedFields(previousAlias: string, inputModel: ShortTextInput): void {
        const alias = inputModel.alias;
        if (!alias) {
            return;
        }

        const inputsAndLinkedFieldsModel = this.inputUtilsService.getLinkedFieldsElementModelWithAlias(this.editor, previousAlias);
        this.editor.model.change((writer: Writer)  => {
            inputsAndLinkedFieldsModel.forEach((input: TreeWalkerValue) => {
                previousAlias !== null && writer.setAttribute(InputPlugin.MODEL_ENTITIES.alias.model, inputModel.alias, input.item);
            });
        });
    }
}
