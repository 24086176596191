import { MasterData } from 'src/app/api';
import { BaseEntityDto } from './baseEntityDto';
import { FileStatusApplicationDto } from './fileStatusApplicationDto';
import { FileUpdateInformationDto } from './fileUpdateInformationDto';

export interface FolderItemInformationDto extends BaseEntityDto {
    name?: string;
    creationTimeStamp: Date;
    modificationTimeStamp: Date;
    inForceStartDate?: Date;
    inForceEndDate?: Date;
    fileStatus: MasterData;
    fileStatusApplication: FileStatusApplicationDto;
    fileUpdateInformation?: FileUpdateInformationDto[];
    creationUserFullName?: string;
    creationUserEmail?: string;
    versionsNumber?: number;
    nameHighlighted?: string;
    isEmbedded?: boolean;
    isDraft?: boolean;
    iddOrigin?: string;
}
