import { HttpHeaders } from '@angular/common/http';
import { Configuration } from '../configuration';
import { ApiOperation, ApiProcessorService, ApiRequest } from './api-processor.service';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable()
export class InquireTemplatesFoldersService {

    private basePath = '/api/inquire-management/templates-folders';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(private readonly apiProcessorService: ApiProcessorService) {
    }

    public getAll(): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.basePath;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public get(folderId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}/${folderId}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public getRootFolders(): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}/root-folders`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
