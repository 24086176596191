import { Directive, Inject } from '@angular/core';
import { ButtonView, createLabeledInputText, EventInfo, InputTextView, LabeledFieldView, Locale} from 'ckeditor5';
import FormRowView from '../../../utils/form-row-view.directive';
import BaseView from '../base-view.directive';
import { SelectModel } from '../../models/select/select-model';
import SelectMultipleView from '../view/select-multiple-view.directive';
import { SelectMultipleViewModel } from '../../models/select/select-multiple-view.model';
import { ShortTextInput } from '../../models/input/short-text-input-model';

@Directive({
    selector: 'app-select-dialog-form-view',
})
export default class SelectDialogView extends BaseView {

    public textInputView: LabeledFieldView<InputTextView>;
    public optionsView: SelectMultipleView;

    private addButtonView: ButtonView;
    private modifyButtonView: ButtonView;
    private upButtonView: ButtonView;
    private downButtonView: ButtonView;
    private removeButtonView: ButtonView;
    private saveButtonView: ButtonView;
    private cancelButtonView: ButtonView;

    private labelOptions = $localize`:@@PluginDesplegableEdicionEtiquetaTexto:Valores del desplegable `;
    private labelSelectValues = $localize`:@@PluginDesplegableEdicionEtiquetaValoresDesplegable:Valores del desplegable `;
    private labelAddButton = $localize`:@@PluginDesplegableEdicionEtiquetaAgregar:Agregar`;
    private labelModifyButton = $localize`:@@PluginDesplegableEdicionEtiquetaModificar:Modificar`;
    private labelUpButton = $localize`:@@PluginDesplegableEdicionEtiquetaSubir:Subir`;
    private labelDownButton = $localize`:@@PluginDesplegableEdicionEtiquetaBajar:Bajar`;
    private labelRemoveButton = $localize`:@@PluginDesplegableEdicionEtiquetaEliminar:Eliminar`;

    private validatorsOptionTextField: Array<SelectDialogOptionTextFormViewCallback> = [];
    private validatorsOptionArrayField: Array<SelectDialogOptionArrayFormViewCallback> = [];
    private readonly DEFAULT_INDEX_SELECTED = -1;
    private currentOptionIndexSelected = this.DEFAULT_INDEX_SELECTED;

    private readonly ID_INPUT_PREFFIX = 'ck-select-';

    private modelToEdit: SelectModel = {
        id: '',
        options: []
    };
    private isEditing = false;

    constructor(
        @Inject(Array<SelectDialogOptionTextFormViewCallback>) validatorsOptionField: Array<SelectDialogOptionTextFormViewCallback>,
        @Inject(Array<SelectDialogOptionArrayFormViewCallback>) validatorsOptionArrayField: Array<SelectDialogOptionArrayFormViewCallback>,
        @Inject(Locale) locale?: Locale,
        @Inject(ShortTextInput) defaultModel?: SelectModel,
    ) {
        super(locale);

        this.initializeModel(defaultModel);
        this.initializeValidators(validatorsOptionField, validatorsOptionArrayField);
        this.initializeViews(locale, defaultModel);
        this.initializeButtons();
        this.initializeRows(locale);

        this.setTemplateForm('ck-select-form');
        this.setEvents();
    }

    private initializeModel(defaultModel?: SelectModel): void {
        if (defaultModel) {
            this.modelToEdit = JSON.parse(JSON.stringify(defaultModel));
        }
        this.isEditing = !!this.modelToEdit.id;
    }

    private initializeValidators(validatorsOptionTextField: Array<SelectDialogOptionTextFormViewCallback>,
                                 validatorsOptionArrayField: Array<SelectDialogOptionArrayFormViewCallback>): void {
        this.validatorsOptionTextField = validatorsOptionTextField;
        this.validatorsOptionArrayField = validatorsOptionArrayField;
    }

    private initializeViews(locale: Locale, defaultModel: SelectModel): void {
        this.initializeTextInput();
        this.initializeSelectorMulti(defaultModel, locale);
    }

    private initializeSelectorMulti(defaultModel: SelectModel, locale: Locale): void {
        const selectMultipleViewModel: SelectMultipleViewModel = {
            titleText: this.labelSelectValues,
            options: defaultModel.options
        };

        this.optionsView = new SelectMultipleView(locale, selectMultipleViewModel);
        this.listenTo(this.optionsView, 'change', this.handleChangeEvent.bind(this));
    }

    private handleChangeEvent(event: EventInfo, data: { srcElement: HTMLSelectElement, preventDefault: () => void, stopPropagation: () => void }): void {
            this.currentOptionIndexSelected = data.srcElement.selectedIndex;
            const currentOptionValue = this.optionsView.options[this.currentOptionIndexSelected];
            this.setOptionTextInLabel(currentOptionValue);
            data.preventDefault();
            data.stopPropagation();
            event.stop();
            event.return = true;
    }

    private initializeTextInput(): void {
        this.textInputView = this.createValueInput(this.labelOptions, this.modelToEdit?.value);
    }

    private initializeButtons(): void {
        const showTextInButton = true;
        this.addButtonView = this.createButton(this.labelAddButton, 'ck-rectangle-button', null, showTextInButton);
        this.addButtonView.delegate('execute').to(this, 'addOption');

        this.modifyButtonView = this.createButton(this.labelModifyButton, 'ck-rectangle-button',null, showTextInButton);
        this.modifyButtonView.delegate('execute').to(this, 'modifyOption');

        this.upButtonView = this.createButton(this.labelUpButton, 'ck-rectangle-button', null, showTextInButton);
        this.upButtonView.delegate('execute').to(this, 'moveUpOption');

        this.downButtonView = this.createButton(this.labelDownButton, 'ck-rectangle-button' ,null, showTextInButton);
        this.downButtonView.delegate('execute').to(this, 'moveDownOption');

        this.removeButtonView = this.createButton(this.labelRemoveButton, 'ck-rectangle-button', null, showTextInButton);
        this.removeButtonView.delegate('execute').to(this, 'removeOption');

        this.saveButtonView = this.createButton(this.submitButtonMessage, 'ck-button-save input-refillable', null, showTextInButton);
        this.saveButtonView.type = 'submit';

        this.cancelButtonView = this.createButton(this.cancelButtonMessage, 'ck-button-cancel input-refillable', null, showTextInButton);
        this.cancelButtonView.delegate('execute').to(this, 'cancel');
    }

    private initializeRows(locale: Locale): void {
        const rowOption = new FormRowView(locale,
             {
                labelView: undefined,
                children: [
                    this.textInputView,
                    this.addButtonView,
                ],
                class: 'current-option'
            }
        );

        const rowButtons = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.modifyButtonView,
                this.upButtonView,
                this.downButtonView,
                this.removeButtonView
            ],
            class: 'modify-options'
        });


        const rowCurrentOptions = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.optionsView,
                rowButtons,
            ],
            class: 'modify-options-select'
        });

        const rowFooter = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.saveButtonView,
                this.cancelButtonView
            ],
            class: 'footer-buttons'
        })

        this.items = this.createCollection([
            rowOption,
            rowCurrentOptions,
            rowFooter
        ]);
    }

    public override focus(): void {
        this.textInputView.focus();
        const inputElement = this.textInputView.fieldView.element as HTMLInputElement;
        this.moveToFirstPositionElement(inputElement);
    }

    public get id(): string {
        return this.modelToEdit.id!;
    }

    public set selectModel(value: SelectModel) {
        this.modelToEdit =  JSON.parse(JSON.stringify(value));
        this.isEditing = !!this.modelToEdit.id;
        this.updateFields();
    }

    public get selectModel(): SelectModel {
        if (this.isInCreation()) {
            this.modelToEdit.id = this.generateId(this.modelToEdit.id);
        }

        this.modelToEdit.options = this.getOptions();;

        return this.modelToEdit;
    }

    public areAllFieldsValid(): boolean {
        return this.areValidOptions(this.optionsView, this.textInputView, this.validatorsOptionArrayField);
    }

    public isInCreation(): boolean {
        return !this.isEditing;
    }

    public selectDefaultField(): void {
        if (!this.textInputView?.fieldView) {
            return;
        }

        this.textInputView.fieldView.select();
    }

    public resetValidation(): void {
        this.textInputView.errorText = null;
    }

    public resetFormStatus(): void {
        this.resetValidation();

        this.modelToEdit.id = '';
        this.modelToEdit.value = '';
        this.modelToEdit.options = [];

        this.cleanTextInOption();
        this.cleanOptions();

        this.isEditing = false;
        this.updateFields();
    }

    private getValidOptions<T>(
        context: this, options: T,
        fieldInputView: LabeledFieldView<InputTextView>,
        validatorField: Array<(context: this, options: T, storedOptions?: string[]) => string | null>,
        storedOptions?: string[]
    ): boolean {
        for (const validator of validatorField) {
            const errorText = validator(context, options, storedOptions);

            if (errorText) {
                fieldInputView.errorText = errorText;
                return false;
            }
        }

        fieldInputView.errorText = '';
        return true;
    }

    private areValidOptions(
        selectView : SelectMultipleView,
        fieldInputView: LabeledFieldView<InputTextView>,
        validatorField: Array<SelectDialogOptionArrayFormViewCallback>
    ): boolean {
        const options = selectView.options;
        return this.getValidOptions(this, options, fieldInputView, validatorField);
    }

    private isValidOption(
        fieldInputView: LabeledFieldView<InputTextView>,
        validatorField: Array<SelectDialogOptionTextFormViewCallback>,
        storedOptions: string[]
    ): boolean {
        const option = fieldInputView.fieldView.element.value;
        return this.getValidOptions(this, option, fieldInputView, validatorField, storedOptions);
    }

    private moveToFirstPositionElement(element: HTMLInputElement): void {
        if (element) {
            element.setSelectionRange(0, 0);
        }
    }

    private setEvents(): void {
        this.on('addOption',        this.handleAddOption.bind(this));
        this.on('modifyOption',     this.handleModifyOption.bind(this));
        this.on('moveUpOption',     this.handleMoveUpOption.bind(this));
        this.on('moveDownOption',   this.handleMoveDownOption.bind(this));
        this.on('removeOption',     this.handleRemoveOption.bind(this));
    }

    private cleanTextInOption(): void {
        this.setOptionTextInLabel('');
    }

    private cleanOptions(): void {
        this.optionsView.clean();
        this.currentOptionIndexSelected = this.DEFAULT_INDEX_SELECTED;
    }

    private getOptions(): string[] {
        return this.optionsView.options;
    }

    private handleAddOption(_event: FocusEvent): void {
        const optionIsValid = this.isValidOption(this.textInputView, this.validatorsOptionTextField, this.getOptions());
        if (!optionIsValid) {
            return;
        }

       const optionValue = this.getOptionTextInLabel();
       this.optionsView.addOption(optionValue);
       this.cleanTextInOption();
    }

    private handleModifyOption(_event: FocusEvent): void {
        const optionIsValid = this.isValidOption(this.textInputView, this.validatorsOptionTextField, this.getOptions());
        if (!optionIsValid && this.currentOptionIndexSelected > 0) {
            return;
        }

        const optionValue = this.getOptionTextInLabel();
        this.optionsView.modifyOption(optionValue, this.currentOptionIndexSelected);
        this.cleanTextInOption();
    }

    private handleMoveUpOption(_event: FocusEvent): void {
        const isMoved = this.optionsView.moveUpOption(this.currentOptionIndexSelected);
        if(!isMoved) {
            return;
        }

        this.currentOptionIndexSelected--;
    }

    private handleMoveDownOption(_event: FocusEvent): void {
        const isMoved = this.optionsView.moveDownOption(this.currentOptionIndexSelected);
        if(!isMoved) {
            return;
        }

        this.currentOptionIndexSelected++;
    }

    private handleRemoveOption(_event: FocusEvent): void {
        const newIndex = this.optionsView.removeOption(this.currentOptionIndexSelected);
        this.currentOptionIndexSelected = newIndex;
        this.cleanTextInOption();
    }

    private updateFields(): void {
        this.cleanTextInOption();
        const defaultModel: SelectMultipleViewModel = {
            titleText: this.labelSelectValues,
            options: this.modelToEdit.options
        };

        this.optionsView.updateModel(defaultModel);
    }

    private createValueInput(label: string, defaultValue?: string): LabeledFieldView<InputTextView> {
        const labeledInput = new LabeledFieldView(
            this.locale,
            createLabeledInputText
        );

        labeledInput.label = label;
        if (defaultValue) {
            labeledInput.fieldView.value = defaultValue;
        }

        labeledInput.fieldView.on('input', (_value: any) => {
            this.resetValidation();
        });

        return labeledInput;
    }

    private generateId(currentId: string | null | undefined): string {
        return !!currentId ? currentId : `${this.ID_INPUT_PREFFIX}${crypto.randomUUID()}`;
    }

    private getOptionTextInLabel(): string {
        return this.textInputView.fieldView.element.value;
    }

    private setOptionTextInLabel(optionText: string): void {
        this.textInputView.fieldView.element.focus();
        this.textInputView.fieldView.element.value =  optionText;

        this.textInputView.fieldView.element.addEventListener('blur', () => {
            this.textInputView.fieldView.element.focus();
        });


    }
}

export type SelectDialogOptionArrayFormViewCallback = (form: SelectDialogView, options: string[]) => string | undefined;
export type SelectDialogOptionTextFormViewCallback = (form: SelectDialogView, option: string, otherOptions: string[]) => string | undefined;
