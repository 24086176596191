import { AfterViewInit, Component, ComponentRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { IStep } from './models/step.model';
import { MatStepper } from '@angular/material/stepper';
import { IStepComponent } from './models/step-component.model';
import { ILoadingStep } from 'src/app/shared/components/stepper/models/loading-step.model';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-stepper',
    templateUrl: './stepper.component.html',
    styleUrls: ['./stepper.component.scss']

})
export class StepperComponent implements AfterViewInit, OnDestroy {

    @ViewChild('stepper') stepper: MatStepper;
    @ViewChild('componentContainer', { read: ViewContainerRef }) componentContainer: ViewContainerRef;

    @Input() public steps: IStep[];
    @Input() public stepperClass: string;
    @Input() public orientation = 'horizontal';

    @Output() public notifyCancelProcess = new EventEmitter();
    @Output() public notifyCloseProcess = new EventEmitter();

    public dynamicComponentRefs: ComponentRef<any>[] = [];
    public componentState: any[] = [];
    public currentPosition = 0;

    private onDestroy$ = new EventEmitter<void>();

    public constructor(private viewContainerRef: ViewContainerRef) { }

    public ngAfterViewInit(): void {
        this.loadComponent(this.steps[0].component);
        this.saveComponentState(this.currentPosition);
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public loadComponent(component: any): void {
        this.componentContainer.clear();
        const componentFactory = this.viewContainerRef.createComponent(component);
        const componentState = this.componentState[this.currentPosition];

        if (componentState) {
            this.uploadOldComponentState(componentFactory, componentState);
        } else {
            this.uploadNewComponentState(componentFactory);
        }

        this.setCurrentLoadingStep();
    }

    public next(): void {
        if (this.currentPosition < this.steps.length - 1) {
            if (this.steps[this.currentPosition].triggerBeforeNextStep) {
                this.dynamicComponentRefs[this.currentPosition].instance.triggerBeforeNextStep();
            }
            this.saveComponentState(this.currentPosition);
            this.currentPosition++;
            this.loadComponent(this.steps[this.currentPosition].component);
        }
    }

    public back(): void {
        if (this.currentPosition > 0) {
            this.saveComponentState(this.currentPosition);
            this.currentPosition--;
            this.loadComponent(this.steps[this.currentPosition].component);
        }
    }

    public isComponentValid(): boolean {
        if (this.dynamicComponentRefs.length === 0) {
            return false;
        }
        const stepComponent: IStepComponent = this.getCurrenStepComponent();
        if (!stepComponent) {
            return false;
        }

        return stepComponent.isStepValid;
    }

    public isDisabledNextButton(): boolean {
        return !this.isComponentValid();
    }

    public shouldShowNextButton(): boolean {
        return this.getCurrentStep()?.showNextButton;
    }

    public shouldShowBackButton(): boolean {
        return this.getCurrentStep()?.showBackButton;
    }

    public shouldShowCancelButton(): boolean {
        return this.getCurrentStep()?.showCancelButton;
    }

    public shouldShowCloseButton(): boolean {
        return this.getCurrentStep()?.showCloseButton;
    }

    public getNextButtonText(): string {
        const currentStep = this.getCurrentStep();
        if (!currentStep) {
            return '';
        }

        return currentStep.textNextButton;
    }

    public cancelProcess(): void {
       this.notifyCancelProcess.emit();
    }

    public close(): void {
        this.notifyCloseProcess.emit();
    }

    private uploadNewComponentState(componentRef: any): void {
        this.componentContainer.insert(componentRef.hostView);
        this.dynamicComponentRefs.push(componentRef);
    }

    private uploadOldComponentState(componentRef: any, componentState: any): void {
        const instance = componentRef.instance;
        Object.assign(instance, componentState);
        this.componentContainer.insert(componentRef.hostView);
        this.dynamicComponentRefs.splice(this.currentPosition, 1);
        this.dynamicComponentRefs.splice(this.currentPosition, 0, componentRef);
    }

    private saveComponentState(index: number): void {
        this.componentState[index] = { ...this.dynamicComponentRefs[index].instance };
    }

    private getCurrenStepComponent(): IStepComponent {
        return this.dynamicComponentRefs[this.currentPosition].instance;
    }

    private getCurrentStep(): IStep {
        return this.steps[this.currentPosition];
    }

    private setCurrentLoadingStep(): void {
        const loadingStep: ILoadingStep = this.getCurrentLoadingStep();
        if (!loadingStep) {
            return;
        }

        loadingStep.isFinished
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(() => {
                this.next();
        });
    }

    private getCurrentLoadingStep(): ILoadingStep {
        return this.dynamicComponentRefs[this.currentPosition].instance;
    }
}
