import { TreeWalkerValue, Writer, Element, Item } from 'ckeditor5';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';
import { BaseCommand } from '../base-command';

const SET_SELECTION_AFTER = 'after';

export abstract class RadioBaseCommand extends BaseCommand {

    labelDataModel = RadioPlugin.MODEL_ENTITIES.container.model;

    protected moveOptionsOutsideRadio(radioModel: TreeWalkerValue, positionToMove: number, writer: Writer) {
        const optionToMove = this.getOptionToMove(radioModel, positionToMove);
        const contentToMove = this.getContentToMove(optionToMove);

        this.moveContentModelAfterRadioComponent(writer, contentToMove!, radioModel.item);
        this.removeContentDivAndExtractChildrenToParent(writer, contentToMove);
        writer.remove(optionToMove!);
        return { optionToMove, contentToMove };
    }

    private moveContentModelAfterRadioComponent(writer: Writer, contentToMove: Item, radioModel: Item) {
        writer.insert(contentToMove, radioModel, SET_SELECTION_AFTER);
    }

    private removeContentDivAndExtractChildrenToParent(writer: Writer, contentToMove: any) {
        writer.unwrap(contentToMove);
    }

    private getContentToMove(optionToMove: any): Element | null {
        for (const child of optionToMove.getChildren()) {
            if (child.name === RadioPlugin.MODEL_ENTITIES.content.model) {
                return child;
            }
        }
        return null;
    }

    private getOptionToMove(radioModel: any, positionToMove: number): Element | null {
        for (const child of radioModel.item.getChildren()) {
            if (child.hasAttribute(RadioPlugin.MODEL_ENTITIES.optionPosition.model) &&
                child.getAttribute(RadioPlugin.MODEL_ENTITIES.optionPosition.model) === positionToMove.toString()) {
                return child;
            }
        }
        return null;
    }

}
