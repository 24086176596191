import { BehaviorSubject } from 'rxjs';
import { TemplateDocumentDTO } from 'src/app/api';

export abstract class ITemplateDocumentRevisionService {
    abstract getCurrentStateDocumentsSubscription(): BehaviorSubject<Map<string, string>>;
    abstract UpdateStateDocument(stateDocument: Map<string, string>);
    abstract getCurrentStateDocuments(): Map<string, string>;
    abstract loadPendingCBDocumentsForTemplate(clauseId: string): void;
    abstract getNumPendingDocuments(): number;
    abstract getIndexDocumentTemplate(documentTemplate: TemplateDocumentDTO): number;
    abstract getState(documentTemplate: TemplateDocumentDTO): string;
    abstract isPending(documentTemplate: TemplateDocumentDTO): boolean;
    abstract isApplied(documentTemplate: TemplateDocumentDTO): boolean;
    abstract isKeep(documentTemplate: TemplateDocumentDTO): boolean;
    abstract setPending(documentTemplate: TemplateDocumentDTO): void;
    abstract setKeep(documentTemplate: TemplateDocumentDTO): void;
    abstract setApplied(documentTemplate: TemplateDocumentDTO): void;
    abstract getNextDocumentTemplateId(currentId: number): string;
    abstract getPreviousDocumentTemplateId(currentId: number): string;
    abstract getFirstDocumentTemplateId(currentIndex: number): string;
    abstract getLastDocumentTemplateId(currentIndex: number): string;
    abstract orderIndex(orderedData: TemplateDocumentDTO[]): void;
}
