import { TemplateDTO, HierarchyModelDto, SearchResultDto, TemplateFolderDTO, ClauseDTO, DocumentDTO } from 'src/app/api';
import { ISearchResultModelFactory } from '../interfaces/search-result-model-factory.interface';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class SearchResultModelFactory implements ISearchResultModelFactory {

    public constructor() {}

    public buildSearchResultDocument(document: DocumentDTO): SearchResultDto {
        return {
            id: document.id,
            name: document.name,
            hierarchy: this.mapToHierarchyModel(document.documentFolder, []),
            extraData: JSON.stringify({ document: document })
        };
    }

    public buildSearchResultTemplate(template: TemplateDTO): SearchResultDto {
        return {
            id: template.id,
            name: template.name,
            hierarchy: this.mapToHierarchyModel(template.templateFolder, []),
            extraData: JSON.stringify({templateSheet: template.templateSheet,
                templateStatus: template.templateStatus,
                templateStatusApplication: template.templateStatusApplication,
                lastModificationDate: template.modificationTimeStamp })
        };
    }

    public buildSearchResultClause(clause: ClauseDTO): SearchResultDto {
        return {
            id: clause.id,
            name: clause.name,
            hierarchy: this.mapToHierarchyModel(clause.clauseFolder, []),
            extraData: JSON.stringify({
                clauseStatus: clause.clauseStatus,
                clauseStatusApplication: clause.clauseStatusApplication,
                lastModificationDate: clause.modificationTimeStamp })
        };
    }

    private mapToHierarchyModel(templateFolder: TemplateFolderDTO, hierarchies: HierarchyModelDto[]): HierarchyModelDto[] {
        if (!templateFolder) {
            return hierarchies;
        }

        const hierarchyModel = {
            id: templateFolder.id,
            name: templateFolder.name,
        };

        const subHierarchies = this.mapToHierarchyModel(templateFolder.templateFolderParent, hierarchies);
        hierarchies.push(hierarchyModel);

        return subHierarchies;
    }
}
