import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { ClauseDTO, DocumentDTO, TemplateDTO } from 'src/app/api';
import { CtboxActionButton } from './models/ctbox-action-button.model';
import { DocumentStatusCodes } from 'src/app/core/standard/enums/documents/document-status.enum';

@Component({
    selector: 'ctbox-actions-buttons',
    templateUrl: './ctbox-actions-buttons.component.html'
})
export class CtboxActionsButtonsComponent implements OnInit, OnChanges {

    @Input() element: DocumentDTO | TemplateDTO | ClauseDTO;
    @Input() actionList: Array<CtboxActionButton> = [];
    @Input() showChangeStateOption: boolean;
    @Input() justifyContentCenter: boolean;

    @Output() reloadDocument = new EventEmitter<void>();
    @Output() documentNumberChanged = new EventEmitter<void>();
    @Output() documentStateChange = new EventEmitter<{ document: DocumentDTO, state: DocumentStatusCodes }>();

    public elementAsDocument: DocumentDTO;

    constructor() { }

    public ngOnInit(): void {
        this.elementIsDocumentDTO();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.element) {
            this.elementIsDocumentDTO();

        }
    }

    public clickAction(action: CtboxActionButton) {
        action.action(this.element);
    }

    public trackList = (index) => {
        return index;
    }

    public isElementInstanceOfDocument(): boolean {
        return !!(this.element as DocumentDTO).documentStatus;
    }

    private elementIsDocumentDTO() {
        if (!this.isElementInstanceOfDocument()) {
            this.elementAsDocument = null;
            return;
        }
        this.elementAsDocument = this.element as DocumentDTO;
    }

    public documentStateChangeEvent(value: { document: DocumentDTO, state: DocumentStatusCodes }) {
        this.documentStateChange.emit(value);
    }

}
