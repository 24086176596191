<form [formGroup]="exportForm" class="export-document">
    <div class="form-group-block">
        <div id="exportOptions" class="form-group-flex fcol coment">
            <h3 class="text_label" i18n="@@ExportOptions">Opciones</h3>
            <mat-radio-group class="form-group-flex fcol" (change)="optionChange($event)" formControlName="options" required>
                <mat-radio-button class="mat-radio-indent view on" value={{exportType.CompleteDocument}} [checked]="!hasSelectedText">
                    <ng-container class="mat-label" i18n="@@ExportFullDocument">Documento completo</ng-container>
                </mat-radio-button>
                <mat-radio-button class="mat-radio-indent view on" value={{exportType.SelectedText}} [checked]="hasSelectedText" [disabled]="!hasSelectedText">
                    <ng-container class="mat-label" i18n="@@ExportSelectedText">Texto seleccionado</ng-container>
                </mat-radio-button>
                <mat-radio-button class="mat-radio-indent view on" value={{exportType.ConsumerDocument}}>
                    <ng-container class="mat-label" i18n="@@ExportForConsumer">Documento para consumidor</ng-container>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div id="exportFormat" class="form-group-flex fcol coment">
            <h3 class="text_label" i18n="@@ExportFormat">Formato</h3>
            <mat-radio-group class="form-group-flex fcol" (change)="typeChange($event)" formControlName="types" required>
                <mat-radio-button class="mat-radio-indent view on" value={{exportFileExtension.Docx}} [checked]="true">
                    <ng-container class="mat-label" i18n="@@WordFormat">WORD</ng-container>
                </mat-radio-button>
                <mat-radio-button class="mat-radio-indent view on" value={{exportFileExtension.Pdf}}>
                    <ng-container class="mat-label" i18n="@@PdfFormat">PDF</ng-container>
                </mat-radio-button>
                <mat-radio-button class="mat-radio-indent view on" value={{exportFileExtension.Html}}>
                    <ng-container class="mat-label" i18n="@@HtmlFormat">HTML</ng-container>
                </mat-radio-button>
            </mat-radio-group>
        </div>
        <div id="exportComments" class="form-group-flex fcol coment">
            <h3 class="text_label"><mat-label class="form-group-label" i18n="@@ExportComments">Comentarios</mat-label></h3>
            <mat-form-field class="coment format-textarea">
                <textarea matInput [formControlName]="'comments'" [(ngModel)]="values.comment"></textarea>
                <mat-error>
                    <span *ngIf="exportForm.controls.comments.errors?.maxlength" i18n="@@ValidacionMensajeNombreLongitud">La longitud no puede superar {{MAX_LENGTH_COMMENT}} caracteres.</span>
                    <span *ngIf="exportForm.controls.comments.errors?.invalidCharacters"><span i18n="@@caracteresInvalidos">Caracteres inválidos introducidos: </span> {{exportForm.controls.comments.errors.invalidCharacters}}
                    </span>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</form>