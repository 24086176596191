import { Injectable } from '@angular/core';

declare let CKEDITOR: any;

@Injectable({
  providedIn: 'root'
})
export class CkeditorPluginTableToolsService {

    isArray: any;
    public configureTableToolsPlugin(editorInstance: any): void {
        this.configurePlugin(editorInstance);
    }

    private configurePlugin(editorInstance: any) {
        CKEDITOR.plugins.tabletools.insertRow = this.insertRow;

        const context = this;
        this.addCmd(editorInstance, 'rowInsertBefore', this.createDef({
            requiredContent: 'table',
            exec( editor ) {
                const selection = editor.getSelection();
                const cells = context.getSelectedCells( selection, null );

                context.insertRow(cells, true );
            }
        } ) );

        this.addCmd(editorInstance, 'rowInsertAfter', this.createDef({
            requiredContent: 'table',
            exec( editor ) {
                const selection = editor.getSelection();
                const cells = context.getSelectedCells( selection, null );

                context.insertRow(cells, false );
            }
        } ) );

    }

    public addCmd( editorInstance, name, def ) {
        const cmd = editorInstance.addCommand( name, def );
        editorInstance.addFeature( cmd );
    }

    public createDef( def ): any {
        return CKEDITOR.tools.extend( def || {}, {
            contextSensitive: 1,
            refresh:  (editor, path ) => {

            }
        });
    }

    public setState(cmd: any, path) {
        cmd.setState( path.contains( { td: 1, th: 1 }, 1 ) ? CKEDITOR.TRISTATE_OFF : CKEDITOR.TRISTATE_DISABLED );
    }

    public getSelectedCells( selection, table ) {
        const retval = [];
        const database = {};

        if ( !selection ) {
            return retval;
        }


        const ranges = selection.getRanges();
        for ( let i = 0; i < ranges.length; i++ ) {
            const range = ranges[ i ];

            if ( range.collapsed ) {
                // Walker does not handle collapsed ranges yet - fall back to old API.
                const startNode = range.getCommonAncestor();
                const nearestCell = startNode.getAscendant( { td: 1, th: 1 }, true );

                if ( nearestCell && this.isInTable( nearestCell, table ) ) {
                    retval.push( nearestCell );
                }
            } else {
                const walker = new CKEDITOR.dom.walker( range );
                let node;

                walker.guard = this.moveOutOfCellGuard;

                while ( walker.next()) {
                    node = walker.next();
                    // If may be possible for us to have a range like this:
                    // <td>^1</td><td>^2</td>
                    // The 2nd td shouldn't be included.
                    //
                    // So we have to take care to include a td we've entered only when we've
                    // walked into its children.

                    if ( node.type !== CKEDITOR.NODE_ELEMENT || !node.is( CKEDITOR.dtd.table ) ) {
                        const parent = node.getAscendant( { td: 1, th: 1 }, true );

                        if ( parent && !parent.getCustomData( 'selected_cell' ) && this.isInTable( parent, table ) ) {
                            CKEDITOR.dom.element.setMarker( database, parent, 'selected_cell', true );
                            retval.push( parent );
                        }
                    }
                }
            }
        }

        CKEDITOR.dom.element.clearAllMarkers( database );

        return retval;
    }

    public insertRow(selectionOrCells, insertBefore: boolean) {
        let cells = selectionOrCells;
        let firstCell = CKEDITOR.tools.isArray(selectionOrCells ) ? cells[ 0 ] : null;
        let table = CKEDITOR.tools.isArray(selectionOrCells ) ?  firstCell.getAscendant( 'table' ) : null;
        cells = CKEDITOR.tools.isArray(selectionOrCells ) ? selectionOrCells : this.getSelectedCells( selectionOrCells, table );
        firstCell = cells[ 0 ];
        table = firstCell.getAscendant( 'table' );
        const doc = firstCell.getDocument();
        const startRow = cells[ 0 ].getParent();
        const startRowIndex = startRow.$.rowIndex;
        const lastCell = cells[ cells.length - 1 ];
        const endRowIndex = lastCell.getParent().$.rowIndex + lastCell.$.rowSpan - 1;
        const endRow = new CKEDITOR.dom.element( table.$.rows[ endRowIndex ] );
        const rowIndex = insertBefore ? startRowIndex : endRowIndex;
        const row = insertBefore ? startRow : endRow;
        const map = CKEDITOR.tools.buildTableMap( table );
        const cloneRow = map[ rowIndex ];
        const nextRow = insertBefore ? map[ rowIndex - 1 ] : map[ rowIndex + 1 ];
        const width = map[ 0 ].length;
        const newRow = doc.createElement( 'tr' );

        row.copyAttributes(newRow);

        for ( let i = 0; cloneRow[ i ] && i < width; i++ ) {
            let cell;
            // Check whether there's a spanning row here, do not break it.
            if ( cloneRow[ i ].rowSpan > 1 && nextRow && cloneRow[ i ] === nextRow[ i ] ) {
                cell = cloneRow[ i ];
                cell.rowSpan += 1;
            } else {
                cell = new CKEDITOR.dom.element( cloneRow[ i ] ).clone();
                cell.removeAttribute( 'rowSpan' );
                cell.appendBogus();
                newRow.append( cell );
                cell = cell.$;
            }

            i += cell.colSpan - 1;
        }

        insertBefore ? newRow.insertBefore( row ) : newRow.insertAfter( row );

        return newRow;
    }

    public isInTable( cell, table ) {
        if ( !table ) {
            return true;
        }

        return table.contains( cell ) && cell.getAscendant( 'table', true ).equals( table );
    }

    private moveOutOfCellGuard( node, retval, database ) {
        const cellNodeRegex = /^(?:td|th)$/;

        // Apply to the first cell only.
        if ( retval.length > 0 ) {
            return;
        }

        // If we are exiting from the first </td>, then the td should definitely be
        // included.
        if ( node.type === CKEDITOR.NODE_ELEMENT && cellNodeRegex.test( node.getName() ) && !node.getCustomData( 'selected_cell' ) ) {
            CKEDITOR.dom.element.setMarker( database, node, 'selected_cell', true );
            retval.push( node );
        }
    }
}


