/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { HierarchyModelDto } from './hierarchyModel';
import { MasterData } from './masterData';
import { SearchResultHighlightDto } from './searchResultHighlightDto';

export interface SearchResultDto {
    id?: string;
    name?: string;
    fileStatus?: MasterData;
    hierarchy?: Array<HierarchyModelDto>;
    searchResultHighlight?: SearchResultHighlightDto;
    extraData?: string;
}
