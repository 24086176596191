<div class="c-help">
    <div class="c-help-header">
        <h3 class="c-help-header-title c-help-templates">Vista de plantilla</h3>
        <p class="c-help-header-text">Diseña y trabaja con tu propio repositorio de modelos de escritos y documentos legales que más utilizas en tu día a día, accesibles para toda tu organización y tus clientes y aprovecha todo el valor que te ofrecen las más de 10.000 plantillas de laleydigital que podrás adaptar al caso concreto y a tu propio estilo construyendo tus propias plantillas.</p>
    </div>
    <div class="c-help-content">
        <mat-tab-group mat-align-tabs="start" class="c-help templates">
            <mat-tab>
                <ng-template mat-tab-label>Acciones, alertas y notificaciones</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="edit" buttonColor="secondary"></ctbox-button></div>
                                <p><strong>Editar nombre de la plantilla:</strong> Cuando creas una plantilla en ContractBOX le asignarás un nombre de archivo que posteriormente podrás cambiar en este icono.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <div class="updates-button warning">
                                        <ctbox-button buttonIcon="warning" buttonColor="warning" customClass="filled warning"></ctbox-button>
                                        <ctbox-button buttonIcon="expand_more" buttonColor="secondary" customClass="border-left"></ctbox-button>
                                    </div>
                                </div>
                                <p><strong>Alertas de plantillas:</strong> Este símbolo aparece cuando hay alertas asociadas a la plantilla que estás utilizando, indicándote que ha sufrido cambios en parte o en su totalidad. Si pulsas en el icono podrás identificar y aplicar los cambios fácilmente.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-notification state="forbid"></ctbox-notification></div>
                                <p><strong>Permisos:</strong> Este símbolo indica que no tienes permisos de edición en esta plantilla.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Edición de la plantilla</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <p class="c-help-summary"> Crear un modelo de escrito legal es mucho más sencillo gracias al editor de texto pensado para la redacción de este tipo de plantillas con algunas funcionalidades exclusivas que te ayudarán a enriquecerlas. Son las siguientes: </p>
                        <h4 class="c-help-subtitle">Opciones de redacción:</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__textfield_icon">&nbsp;</span></div>
                                <p><strong>Insertar campo para rellenar:</strong> Para incluir casillas a rellenar con datos relativos al caso concreto.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__select_icon">&nbsp;</span></div>
                                <p><strong>Insertar desplegable:</strong> Para incluir combos desplegables con varias opciones en un mismo punto de una frase.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__checkbox_icon">&nbsp;</span></div>
                                <p><strong>Insertar texto opcional:</strong> Para incluir textos o párrafos optativos, que no necesariamente deben quedarse en la redacción final del documento.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__radio_icon">&nbsp;</span></div>
                                <p><strong>Insertar párrafos alternativos:</strong> Para dejar abierta la redacción de un párrafo o texto que puede presentar más de una opción de redacción.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Incorporación de cláusulas:</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__insertclauseandmodal_icon">&nbsp;</span></div>
                                <p><strong>Insertar cláusula nueva:</strong> Permite crear e insertar una cláusula en el documento.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon  cke_button__clausefromlibrary_icon">&nbsp;</span></div>
                                <p><strong>Insertar cláusula de la biblioteca:</strong> Permite añadir una cláusula ya existente de la biblioteca de cláusulas.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Insertar espacio para firma:</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img wysiwyg"><span class="cke_button_icon cke_button__insertsignatureandmodal_icon">&nbsp;</span></div>
                                <p>Permite añadir un espacio de firma en el documento mejorando su formato, de forma que cuando se envíe posteriormente el documento a firma, se identifique claramente el lugar donde va a ir.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Opciones de guardado:</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <div class="save-container">
                                        <ctbox-button buttonType="primary" buttonIcon="save" buttonText="Guardar" i18n-buttonText="@@Guardar"></ctbox-button>
                                        <ctbox-button buttonType="primary" buttonIcon="expand_more" customClass="mat-stroked-button border-left"></ctbox-button>
                                        <div class="ctbox-elevation">
                                            <ctbox-button buttonType="mat-menu-item" buttonColor="secondary" buttonText="Guardar como plantilla"></ctbox-button>
                                        </div>
                                    </div>
                                </div>
                                <ul class="c-help-list">
                                    <li><strong>Guardar:</strong> Permite guardar los cambios realizados en el documento. Se mantendrá tanto el nombre como la ubicación actual.</li>
                                    <li><strong>Guardar cómo:</strong> Permite guardar los cambios realizados en el documento. Se podrá elegir una nueva ubicación y se podrá cambiar el nombre del documento.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>