/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface UserPermissionsOnDocumentDTO { 
    canEdit?: boolean;
    canEditName?: boolean;
    canDeleteComments?: boolean;
    canAcceptOrRejectTrackChanges?: boolean;
    canShare?: boolean;
    canSendToSign?: boolean;
    canSave?: boolean;
    canSaveSheet?: boolean;
    canSaveAs?: boolean;
    canSaveAsTemplate?: boolean;
    canSaveAndFinishRevision?: boolean;
    canComment?: boolean;
    canSaveAndFinishFieldEdition?: boolean;
    canChangeState?: boolean;
    canAcceptOrRejectClauseChanges?: boolean;
    canDeleteDocument?: boolean;
    canCancelSignature?: boolean;
    canSeeClauseChanges?: boolean;
    canAddClauses?: boolean;
    canSeeTemplateChanges?: boolean;
    canSeeTemplateLldChanges?: boolean;
    canReviewTemplateChanges?: boolean;
    canReviewTemplateLldChanges?: boolean;
    canSeeRecord?: boolean;
    canCreateVersion?: boolean;
    canSeeVersions?: boolean;
    canEditVersionName?: boolean;
    canDeleteVersion?: boolean;
    canSaveFromVersion?: boolean;
    canCreateAnnexed?: boolean;
    canDeleteAnnexed?: boolean;
    canDownload?: boolean;
    canOnlyFillTextInputs?: boolean;
    canChangeFolder?: boolean;
    canSeeWorkflow?: boolean;
    canStartCancelWorkflow?: boolean;
    canAcceptOrRejectInWorkflow?: boolean;
}