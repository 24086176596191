import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable }  from 'rxjs';
import { Configuration } from '../configuration';
import { IJsonPatchDocument } from '../model/json-patch.model';
import { ApiOperation, ApiProcessorService, ApiRequest, ApiResponse } from './api-processor.service';
import { UrlTree } from '@angular/router';
import { ClauseDTO } from '../model/clauseDTO';
import { CompareVersionIdsDTO } from '../model/compareVersionIdsDTO';
import { ExportResultFormat, FilesExportDto } from '../model/filesExportDto';
import { FilesDownloadDto } from '../model/filesDownloadDto';
import { ClauseFolderDto } from '../model/clause-folder-dto.model';

@Injectable()
export class ManagerClauseService {

    protected basePath = '/api/manager-management/clauses';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(private readonly apiProcessorService: ApiProcessorService) {
    }

    public patch(id: string, jsonPatchDocument: Array<IJsonPatchDocument>): Observable<ApiResponse<ClauseFolderDto> | UrlTree>{
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PATCH;
        apiRequest.endpointUrl = `${this.basePath}/${id}`;
        apiRequest.body = jsonPatchDocument;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public post(clause: ClauseDTO): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = clause;
        apiRequest.endpointUrl = this.basePath;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public put(clause: ClauseDTO): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.body = clause;
        apiRequest.endpointUrl = this.basePath;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public get(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl =  `${ this.basePath }/${id}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public getFilteredByNameAndFolderId(name: string, folderId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl =  `${ this.basePath }?name=${name}&folderId=${folderId}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public compareHtmlVersions(clauseId: string, compareVersionIdsDTO: CompareVersionIdsDTO): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = compareVersionIdsDTO;
        apiRequest.endpointUrl =  `${ this.basePath }/${clauseId}/compare-versions`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public generateExportFile(fileExport: FilesExportDto): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${this.basePath}/export`;
        apiRequest.body = fileExport;

        if (fileExport.exportSettings.exportResultFormat === ExportResultFormat.HierarchyFolderZip) {
            apiRequest.responseType = 'blob';
        }

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public generateWordExportFile(folderId: string, fileDownloadDto: FilesDownloadDto): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${this.basePath}/${folderId}/download-files-to-update`;
        apiRequest.body = fileDownloadDto;
        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public validate( file: File): Observable<any> {

        const filesFormData: FormData = new FormData();
        filesFormData.append('file', file, file.name);

        const url = `${this.basePath}/validate`;

        return this.apiProcessorService.postActionAsFormData(filesFormData, url);
    }

    public upload( file: File): Observable<any> {
        const filesFormData: FormData = new FormData();
        filesFormData.append('file', file, file.name);

        const url = `${this.basePath}/send-to-update`;

        return this.apiProcessorService.postActionAsFormData(filesFormData, url);
    }

    public uploadNewVersion( file: File): Observable<any> {
        const filesFormData: FormData = new FormData();
        filesFormData.append('file', file, file.name);

        const url = `${this.basePath}/send-to-update-new-version`;

        return this.apiProcessorService.postActionAsFormData(filesFormData, url);
    }

}

export interface ICargarModel {
    buyerId: string;
    docsUri: string;
    ctrlUri: string;
}
