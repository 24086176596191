import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiOperation, ApiProcessorService, ApiRequest } from './api-processor.service';

@Injectable()
export class AuthenticationService {
    protected basePath = '/api/authenticated';

    public constructor(private readonly apiProcessorService: ApiProcessorService) {
    }

    public isAuthenticated(): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.basePath;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
