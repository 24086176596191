
import { Injectable } from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Observable } from 'rxjs';
import { IMediaQueryService } from './media-query.service.interface';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})

export class MediaQueryService extends IMediaQueryService {

    private displayNameMap = new Map([
        [Breakpoints.XSmall, 'XSmall'],
        [Breakpoints.Small, 'Small'],
        [Breakpoints.Medium, 'Medium'],
        [Breakpoints.Large, 'Large'],
        [Breakpoints.XLarge, 'XLarge'],
    ]);

    constructor(private breakpointObserver: BreakpointObserver) {
        super();
    }

    public getCurrentScreenSize$(): Observable<string> {
        return this.breakpointObserver
            .observe([Breakpoints.XSmall,
            Breakpoints.Small,
            Breakpoints.Medium,
            Breakpoints.Large,
            Breakpoints.XLarge,])
            .pipe(
                map(value => {
                    for (const query of Object.keys(value?.breakpoints)) {
                        if (value?.breakpoints[query]) {
                            return this.displayNameMap.get(query) ?? 'Unknown';
                        }
                    }
                })
            );
    }
}

