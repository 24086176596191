import { BaseSelectCommand } from "./base-select-command";
import { SelectModel } from "../../models/select/select-model";

export default class AddSelectCommand extends BaseSelectCommand {

    public override execute(model: SelectModel): void {
        const editor = this.editor;

        this.setModelId(model);

        editor.model.change(writer => {
            const elementModel = this.dataUtilsService.createSelectCompleteStructureModelWithOptions(writer, model);
            editor.model.insertObject( elementModel , null, null, {setSelection: 'after'} );
        } );
    }
}
