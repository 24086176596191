<ng-container *ngIf="size==('XSmall') || size==('Small'); then thenBlock else tabs"></ng-container>
<ng-template #thenBlock>
    <ctbox-button buttonIcon="menu" customClass="XSmall menu" [matMenuTriggerFor]="tabsMenu"></ctbox-button>
    <mat-menu #tabsMenu="matMenu" class="landing-tabs {{customize}}" [overlapTrigger]="false">
        <ng-container *ngTemplateOutlet="tabs"></ng-container>
    </mat-menu>
</ng-template>
<ng-template #tabs>
    <nav mat-tab-nav-bar class="menu" [ngClass]="size" [disableRipple]="true" [disablePagination]="true">
        <ng-container *ngFor="let item of navLinks">
            <ng-container *ngxPermissionsOnly="item.permission">
                <ng-container *ngIf="(item.condition | async) !== null && (item.condition | async)?.hasLicense">
                    <ng-container *ngTemplateOutlet="menuItem"></ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngxPermissionsOnly="item.guest">
                <ng-container *ngIf="(item.condition | async) !== null && (item.condition | async)?.hasLicense && this.getShared(item)">
                    <ng-container *ngTemplateOutlet="menuItem"></ng-container>
                </ng-container>
            </ng-container>
            <ng-container *ngxPermissionsExcept="item.exception">
                <ng-container *ngIf="(item.condition | async) !== null && (item.condition | async)?.hasLicense && this.getShared(item); else exceptionBlock">
                    <ng-container *ngTemplateOutlet="menuItem"></ng-container>
                </ng-container>
                <ng-template #exceptionBlock>
                    <div class="disabled" title="No tienes permisos para acceder a este módulo" i18n-title="@@PantallaInicialNoTienesPermisoModulo" data-toggle="tooltip">
                        <ng-container *ngTemplateOutlet="menuItem; context:{disabled: true}"></ng-container>
                    </div>
                </ng-template>
            </ng-container>
            <ng-template #menuItem let-disabled="disabled">
                <a mat-tab-link [disableRipple]="true" [disabled]="disabled" [class]="item.class + ' ' + customize" routerLinkActive #rla="routerLinkActive" [routerLink]="item.path" [active]="rla.isActive" [active]="isViewInitialized && isLinkActive(rla)" [ngClass]="{'active' : isLinkActive(rla)}"
                    (click)="goSelectedLink(item.path)">{{item.label}}</a>
            </ng-template>
        </ng-container>
        <div mat-tab-link class="logo" [ngClass]="size" *ngIf="customize === 'ctbox'">
            <a href="#" class="logo-contractBox negative"></a>
        </div>
    </nav>
</ng-template>
