<ng-container *ngIf="doubleIconHeader; else doubleIconTemplate">
    <div class="icon-head" [matTooltip]="iconHeaderTooltip">
        <mat-icon class="head top xxs {{firstIconClass}}">{{firstIconName}}</mat-icon>
        <span class="sep">/</span>
        <mat-icon class="head bottom xxs {{secondIconClass}}">{{secondIconName}}</mat-icon>
    </div>
</ng-container>
<ng-template #doubleIconTemplate>
    <div class="icon-cell">
        <mat-icon class="{{iconClass}}" [matTooltip]="iconTooltip">{{iconName}}</mat-icon>
    </div>
</ng-template>