import { Directive, Inject } from "@angular/core";
import { ButtonView,  Locale } from "ckeditor5";
import BaseView from "../base-view.directive";
import { UI_CLASSES } from "../styles/styles-constants";

@Directive({
    selector: "app-radio-balloon-view",
})
export default class RadioBalloonView extends BaseView {

    public editOptionsButtonView: ButtonView;

    private radioId = '';
    private optionsCount = 0;

    private readonly editOptionsnMessage = $localize`:@@EditarOpcionesRadioPlugin:Editar opciones`;
    private readonly deleteMessage = $localize`:@@BorrarRadioPlugin:Borrar`;

    constructor(
        @Inject(Locale) locale?: Locale,
        @Inject(String) radioId?: string,
        @Inject(Number) optionsCount?: number
    ) {
        super(locale);

        this.radioId = radioId!;
        this.optionsCount = optionsCount!;

        this.editOptionsButtonView = this.createButton(this.editOptionsnMessage, null, UI_CLASSES.SVG_ICONS.EDIT);
        this.deleteButtonView = this.createButton(this.deleteMessage, null, UI_CLASSES.SVG_ICONS.DELETE);

        this.items = this.createCollection([this.editOptionsButtonView, this.deleteButtonView]);

        this.setTemplateForm("ck-radio-balloon");
    }

    public get id(): string {
        return this.radioId;
    }

    public set id(value: string) {
        this.radioId = value;
    }

    public get totalOptions(): number {
        return this.optionsCount;
    }

    public set totalOptions(value: number) {
        this.optionsCount = value;
    }
}
