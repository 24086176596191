import { View, Locale, IconView } from 'ckeditor5';
import { Directive, Inject } from '@angular/core';

@Directive({
    selector: 'app-info-view',
  })
export default class InfoView extends View {

    public readonly tooltipText: string;
    public readonly iconSvg: string;

    constructor(
        @Inject(Locale) locale: Locale,
        @Inject('TooltipText') tooltipText: string,
        @Inject('IconSvg') iconSvg: string
    ) {
        super( locale );

        this.tooltipText = tooltipText;
        this.iconSvg = iconSvg;

        this.setTemplate({
            tag: 'span',
            attributes: {
                class: 'ck-icon-info'
            },
            children: [
                this.createIconView(),
                {
                    tag: 'span',
                    attributes: {
                        class: 'tooltip-text'
                    },
                    children: [this.tooltipText]
                }
            ]
        });

        this.on('render', () => {
            const iconElement = this.element as HTMLElement;
            const tooltipElement = iconElement.querySelector('.tooltip-text') as HTMLElement;

            iconElement.addEventListener('mouseenter', () => {
                tooltipElement.classList.add('visible');
            });

            iconElement.addEventListener('mouseleave', () => {
                tooltipElement.classList.remove('visible');
            });
        });
    }

    private createIconView(): IconView {
        const iconView = new IconView();
        iconView.content = this.iconSvg;
        return iconView;
    }
}
