import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input } from '@angular/core';

@Component({
  selector: 'app-scroll-to-top',
  templateUrl: './scroll-to-top.component.html',
  styleUrls: ['./scroll-to-top.component.scss'],
})
export class ScrollToTopComponent {
  @Input() scrollTop:string;


  constructor(@Inject(DOCUMENT) private document: Document) {
  }

  public goToTop() {        
    if (this.scrollTop === "top") {
      const element = document.getElementById(this.scrollTop);

      element.scrollTo({
          top: 0,
          behavior: 'smooth'
      });
    } else if (this.scrollTop === "html-viewer-main") {
        const element = document.querySelector('.html-viewer-main');        
        element.scrollIntoView({ behavior: "smooth"});
        element.parentElement.scrollIntoView({ behavior: "smooth"});   
     }
    else {
      const sideNavContainer = document.querySelector('mat-sidenav-content');        
      sideNavContainer.scrollTo({
        top: 0,
        behavior: 'smooth'
    })
    }
  }

}
