<div class="c-help">
    <div class="c-help-header">
        <h3 class="c-help-header-title c-help-clauses">Biblioteca de cláusulas</h3>
        <p class="c-help-header-text">Redacta y trabaja con tu propio repositorio de modelos de cláusulas legales que más utilizas en tus contratos y acuerdos, accesibles para toda tu organización y aprovecha todo el valor que te los modelos de cláusulas de laleydigital que podrás adaptar a tu propio estilo construyendo y vinculando tus propias cláusulas con las plantillas y documentos que desees.</p>
    </div>
    <div class="c-help-content">
        <mat-tab-group mat-align-tabs="start" class="c-help clauses">
            <mat-tab>
                <ng-template mat-tab-label>Acciones, alertas y notificaciones</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="edit" buttonColor="secondary"></ctbox-button></div>
                                <p><strong>Editar nombre de la cláusula:</strong> Cuando creas un modelo de cláusula en ContractBOX le asignarás un nombre de archivo que posteriormente podrás cambiar en este icono.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <div class="updates-button warning">
                                        <ctbox-button buttonIcon="warning" buttonColor="warning" customClass="filled warning"></ctbox-button>
                                        <ctbox-button buttonIcon="expand_more" buttonColor="secondary" customClass="border-left"></ctbox-button>
                                    </div>
                                </div>
                                <p><strong>Alertas de cláusulas:</strong> Este símbolo aparece cuando hay alertas asociadas a la cláusula que estás utilizando, indicándote que ha sufrido cambios y los documentos y plantillas afectados por dichos cambios. Si pulsas en el icono podrás identificar y aplicar los cambios fácilmente.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-notification state="forbid"></ctbox-notification></div>
                                <p><strong>Permisos:</strong> Este símbolo indica que no tienes permisos de edición en esta cláusula.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Edición de la cláusula</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help__edition-clause">
                        <div class="c-help-block">
                            <p class="c-help-summary"> Crear un modelo de escrito legal es mucho más sencillo gracias al editor de texto pensado para la redacción de modelos de cláusulas con algunas funcionalidades exclusivas que te ayudarán a enriquecerlas. Son las siguientes: </p>
                            <h4 class="c-help-subtitle">Opciones de redacción:</h4>
                            <ul class="c-help-list">
                                <li class="c-help-list-flexrow">
                                    <div class="c-help-img wysiwyg"><span class="cke_button_icon cke_button__textfield_icon">&nbsp;</span></div>
                                    <p><strong>Insertar campo para rellenar:</strong> Para incluir casillas a rellenar con datos relativos al caso concreto.</p>
                                </li>
                                <li class="c-help-list-flexrow">
                                    <div class="c-help-img wysiwyg"><span class="cke_button_icon cke_button__select_icon">&nbsp;</span></div>
                                    <p><strong>Insertar desplegable:</strong> Para incluir combos desplegables con varias opciones en un mismo punto de una frase.</p>
                                </li>
                                <li class="c-help-list-flexrow">
                                    <div class="c-help-img wysiwyg"><span class="cke_button_icon cke_button__checkbox_icon">&nbsp;</span></div>
                                    <p><strong>Insertar texto opcional:</strong> Para incluir textos o párrafos optativos, que no necesariamente deben quedarse en la redacción final del documento.</p>
                                </li>
                                <li class="c-help-list-flexrow">
                                    <div class="c-help-img wysiwyg"><span class="cke_button_icon cke_button__radio_icon">&nbsp;</span></div>
                                    <p><strong>Insertar párrafos alternativos:</strong> Para dejar abierta la redacción de un párrafo o texto que puede presentar más de una opción de redacción.</p>
                                </li>
                            </ul>
                        </div>
                        <div class="c-help-block">
                            <h4 class="c-help-subtitle">Opciones de guardado:</h4>
                            <ul class="c-help-list">
                                <li class="c-help-list-flexrow">
                                    <div class="c-help-img">
                                        <ctbox-button buttonType="primary" buttonIcon="save" buttonText="Guardar" i18n-buttonText="@@Guardar" buttonColor="clauses"></ctbox-button>
                                    </div>
                                    <ul class="c-help-list">
                                        <li><strong>Guardar:</strong> Permite guardar los cambios realizados en el documento. Se mantendrá tanto el nombre como la ubicación actual.</li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>