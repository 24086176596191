import { AbstractControl, ValidationErrors } from '@angular/forms';

export function emptyAndWhiteSpacesValidator(control: AbstractControl): ValidationErrors | null {
    const textInControl = control.value as string;
    if (textInControl == null || textInControl.length === 0) {
        return null;
    }

    const hasOnlyWhiteSpaces = /^\s*$/.test(textInControl);
    return hasOnlyWhiteSpaces ? { onlyWhitespaces: { value: control.value } } : null;
}
