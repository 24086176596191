export class Configuration {
  public production: boolean;
  public serverUrl: string;
  public defaultLanguage: string;
  public supportedLanguages: [];
  public adalConfig?: AdalConfiguration;
  public myAccountConfig?: MyAccountConfiguration;
  public antiforgeryConfig: AntiforgeryConfiguration;
  public loginMode: string;
  public googleTagManagerId: string;
  public maxFilesInFolderToExport: number;
}

export class AdalConfiguration {
  public tenant: string;
  public clientId: string;
  public redirectUri: string;
  public navigateToLoginRequestUrl: boolean;
  public postLoginRedirectPath: string;
  public postLogoutRedirectUri: string;
  public endpoints: Map<string, string>;
}

export class MyAccountConfiguration {
  public urlMyAccountHome: string;
  public myAccountLoginScriptLocation: string;
  public myAccountExternalLoginWithoutRegisterUrl: string;
  public myAccountExternalLoginUrl: string;
  public myAccountLogoutUrl: string;
  public myAccountLicensesAdministrationUrl: string;
  public myAccountLoginSamlUrl: string;
  public postLoginRedirectPath: string;
  public postLogoutRedirectUri: string;
}

export class AntiforgeryConfiguration {
  public requestTokenCookieName: string;
  public requestTokenHeaderName: string;
}
