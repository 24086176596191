import { Directive, ElementRef, OnInit, Input } from '@angular/core';

@Directive({
    selector: '[appResizable]'
})
export class ResizableDirective implements OnInit {

    @Input() resizableGrabWidth = 10;
    @Input() resizableMinWidth = 20;

    public dragging = false;
    private startX: number;
    private startWidth: number;

    constructor(private el: ElementRef) {

        function preventGlobalMouseEvents() {
            document.body.style['pointer-events'] = 'none';
        }

        function restoreGlobalMouseEvents() {
            document.body.style['pointer-events'] = 'auto';
        }

        const newWidth = (wid) => {
            const newWidthMax = Math.max(this.resizableMinWidth, wid);
            el.nativeElement.style.width = (newWidthMax) + 'px';
        };

        const mouseMoveG = (evt) => {
            if (!this.dragging) {
                return;
            }
            const offsetX = evt.clientX - this.startX;
            newWidth(this.startWidth + offsetX);
            evt.stopPropagation();
        };

        const mouseUpG = (evt) => {
            if (!this.dragging) {
                return;
            }
            restoreGlobalMouseEvents();
            this.dragging = false;
            evt.stopPropagation();
        };

        const mouseDown = (evt) => {
            if (this.inDragRegion(evt)) {
                this.dragging = true;
                this.startX = evt.clientX;
                this.startWidth = el.nativeElement.offsetWidth;
                preventGlobalMouseEvents();
                evt.stopPropagation();
            }
        };

        const mouseMove = (evt) => {
            if (this.inDragRegion(evt) || this.dragging) {
                el.nativeElement.style.cursor = 'col-resize';
            } else {
                el.nativeElement.style.cursor = 'default';
            }
        };

        document.addEventListener('mousemove', mouseMoveG, true);
        document.addEventListener('mouseup', mouseUpG, true);
        el.nativeElement.addEventListener('mousedown', mouseDown, true);
        el.nativeElement.addEventListener('mousemove', mouseMove, true);
    }

    public ngOnInit(): void {
        this.el.nativeElement.classList.add('resizable');
    }

    public inDragRegion(evt) {
        return this.el.nativeElement.clientWidth - evt.clientX + this.el.nativeElement.offsetLeft < this.resizableGrabWidth;
    }
}