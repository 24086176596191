import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { TemplateFolderDto, FolderItemInformationDto, FileStatusesCodes, FolderResultDto, SearchTemplateFoldersResultDto } from 'src/app/api';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { NodeTreeNodeType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-node-type.enum';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { BaseTemplateTreeService } from '../base-template-folder-tree.service';
import { ITemplatesFolderHierarchyTreeActionsService } from './templates-folder-with-content.actions.service.interface';
import { AdditionalParam } from 'src/app/shared/components/ctbox-tree/models/additional-param.model';
import { NIL as NIL_UUID } from 'uuid';

@Injectable({
    providedIn: 'root'
})
export class TemplateFolderWithContentActionsService extends BaseTemplateTreeService
    implements ITemplatesFolderHierarchyTreeActionsService {

    private EMPTY_GUID = NIL_UUID;

    public create(
        templatesFolders: TemplateFolderDto[],
        foldersNodeActions: NodeTreeActionType[],
        templatesNodeActions: NodeTreeActionType[]): FileNode[] {

        const rootActions: NodeTreeActionType[] = [NodeTreeActionType.MoveTo];
        const rootNode: FileNode = this.createRootNode(rootActions);

        const tree: FileNode[] = [rootNode];

        templatesFolders.filter(tf => tf.templateFolderParentId === this.EMPTY_GUID).forEach(root => {
            const childNode = this.buildFileNode(templatesFolders, root, foldersNodeActions, templatesNodeActions);
            rootNode.children.push(childNode);
        });

        rootNode.children = this.pipe.transform(rootNode.children);
        return tree;
    }

    public searchByFolderId = (
        templatesFolders: TemplateFolderDto[],
        folderId: string) => this.searchTemplateFolderHierarchyTreeByFolderProperty(templatesFolders, templatesFolders, 'id', folderId)

    public searchByFolderName = (templatesFolders: TemplateFolderDto[], folderName: string) =>
        this.searchTemplateFolderHierarchyTreeByFolderProperty(templatesFolders, templatesFolders, 'name', folderName)

    public highlightFolderOrContent(templateFolderSearchResult: SearchTemplateFoldersResultDto): TemplateFolderDto[] {
        const folders = templateFolderSearchResult.folders;
        const results = templateFolderSearchResult.folderResults;
        const allFound = this.getFoundedFoldersOrContent(folders, results);


        allFound.forEach((found: (TemplateFolderDto | FolderItemInformationDto)) => {
            const result = results.find(r => r.id === found.id);
            found.nameHighlighted = this.isNameUpdated(found.name, result.name) ? result?.searchResultHighlight.name : null ;
        });

        return folders;
    }
    public highlightNavigatePreviousFolderOrContent(folders: TemplateFolderDto[], results: FolderResultDto[],
                                                    selectResult: FolderResultDto, isOverflow: boolean): TemplateFolderDto[] {
        const isAscending = false;
        return this.highlightNavigateFolderOrContent(folders, results, selectResult, isAscending, isOverflow);
    }

    public highlightNavigateNextFolderOrContent(folders: TemplateFolderDto[], results: FolderResultDto[],
                                                selectResult: FolderResultDto, isOverflow: boolean): TemplateFolderDto[] {
        const isAscending = true;
        return this.highlightNavigateFolderOrContent(folders, results, selectResult, isAscending, isOverflow);
    }

    private highlightNavigateFolderOrContent(folders: TemplateFolderDto[], results: FolderResultDto[],
                                             selectResult: FolderResultDto, isAscending: boolean,
                                             isOverflow: boolean): TemplateFolderDto[] {

        const allFound = this.getFoundedFoldersOrContent(folders, results);
        const highlightTag = '<em class="highlight-navigated">';
        const noHighlightTag = '<em class="highlight">';

        let previusHighligh = allFound.find(found => found.nameHighlighted.includes(highlightTag));
        const nextHighligh = allFound.find(found => found.id === selectResult.id);

        let startCharacterIndex = 0;
        let endCharacterIndex = 0;
        let highlighName = '';

        if (previusHighligh && previusHighligh.id === nextHighligh.id) {
            startCharacterIndex = previusHighligh.nameHighlighted.indexOf(highlightTag);
            endCharacterIndex = startCharacterIndex + noHighlightTag.length;
            highlighName = previusHighligh.nameHighlighted;
        }

        if (previusHighligh) {
            const result = results.find(r => r.id === previusHighligh.id);
            let newNameReplaceClassSelectResult = result?.searchResultHighlight.name;
            newNameReplaceClassSelectResult = newNameReplaceClassSelectResult.replace(highlightTag, noHighlightTag);
            previusHighligh.nameHighlighted = newNameReplaceClassSelectResult;
        }

        if (isOverflow){
            startCharacterIndex = 0;
            endCharacterIndex = 0;
            highlighName = '';
            previusHighligh = null;
        }

        if (nextHighligh) {
            const result = results.find(r => r.id === nextHighligh.id);
            let newNameReplaceClassSelectResult = result?.searchResultHighlight.name;
            const numberOcurrences  = (newNameReplaceClassSelectResult.match(new RegExp(noHighlightTag, 'g')) || []).length +
            (newNameReplaceClassSelectResult.match(new RegExp(highlightTag, 'g')) || []).length;

            if (startCharacterIndex === endCharacterIndex && numberOcurrences < 2) {
                newNameReplaceClassSelectResult = newNameReplaceClassSelectResult.replace(noHighlightTag, highlightTag);
            } else {
                let newStartIndex = 0;
                let newEndIndex = 0;
                if (isAscending) {
                    let diffHighlightTagsLength =  highlightTag.length - noHighlightTag.length;
                    if (!previusHighligh || previusHighligh?.id !== nextHighligh?.id) {
                        diffHighlightTagsLength = 0;
                    }
                    if (!highlighName) {
                        highlighName = result.searchResultHighlight.name;
                    }
                    newStartIndex = highlighName.indexOf(noHighlightTag, startCharacterIndex) - diffHighlightTagsLength;
                    newEndIndex = newStartIndex + noHighlightTag.length;
                } else {
                    if (!previusHighligh || previusHighligh?.id !== nextHighligh?.id) {
                        highlighName = nextHighligh.nameHighlighted;
                        newStartIndex = highlighName.lastIndexOf(noHighlightTag);
                        newEndIndex = newStartIndex + noHighlightTag.length;
                    } else {
                        const previousTagIndex = highlighName.indexOf(highlightTag);
                        newStartIndex = highlighName.substring(0, previousTagIndex).lastIndexOf(noHighlightTag, previousTagIndex);
                        newEndIndex = newStartIndex + noHighlightTag.length;
                    }
                }
                newNameReplaceClassSelectResult = newNameReplaceClassSelectResult.substring(0, newStartIndex) + highlightTag +
                newNameReplaceClassSelectResult.substring(newEndIndex, newNameReplaceClassSelectResult.length);
            }
            nextHighligh.nameHighlighted = newNameReplaceClassSelectResult;
        }
        return folders;
    }

    public expandFoundResult(expansionResult: SelectionModel<string>, folders: TemplateFolderDto[],
                             results: FolderResultDto[]): SelectionModel<string> {
        const allFound = this.getFoundedFoldersOrContent(folders, results);
        expansionResult.clear();
        expansionResult.select(this.rootNodeId);
        expansionResult.select(...allFound.map(found => found.id));

        return expansionResult;
    }

    public expandFolder(expansionModel: SelectionModel<string>, folderId: string): SelectionModel<string> {
        if (expansionModel.isSelected(folderId)) {
            return expansionModel;
        }

        expansionModel.toggle(folderId);
        return expansionModel;
    }

    private getFoundedFoldersOrContent(folders: TemplateFolderDto[],
                                       results: FolderResultDto[]): (TemplateFolderDto | FolderItemInformationDto)[] {
        const foldersFound = folders
            .filter(folder => results.some(r => r.id === folder.id));
        const itemsFoundMulti = folders.map(folder => folder.content.filter(item => results.some(r => r.id === item.id)));
        const itemFound = [].concat(...itemsFoundMulti);

        return itemFound.concat(foldersFound);
    }

    private searchTemplateFolderHierarchyTreeByFolderProperty(
        initialTemplatesFolders: TemplateFolderDto[],
        templatesFolders: TemplateFolderDto[],
        folderProperty: string,
        propertyValue: string): TemplateFolderDto {
        for (const templateFolder of templatesFolders) {
            if (templateFolder[folderProperty] === propertyValue) {
                return templateFolder;
            }

            const children: TemplateFolderDto[] = initialTemplatesFolders.filter(tf => tf.templateFolderParentId === templateFolder.id);
            const elementFound =
                this.searchTemplateFolderHierarchyTreeByFolderProperty(initialTemplatesFolders, children, folderProperty, propertyValue);
            if (elementFound) {
                return elementFound;
            }
        }

        return null;
    }

    private buildFileNode(
        initialTemplatesFolders: TemplateFolderDto[],
        templateFolder: TemplateFolderDto,
        foldersNodeActions: NodeTreeActionType[],
        templatesNodeActions: NodeTreeActionType[]): FileNode {

        const nodeActionsFilteredByUserPermissions: NodeTreeActionType[] =
            this.getNodeActionsFilteredByUserPermissions(foldersNodeActions, templateFolder.userFolderPermission);

        let templatesNodes: FileNode[] = templateFolder.content.map((templateInfo: FolderItemInformationDto) => {
            const noActions = templateInfo.fileStatus?.code === FileStatusesCodes.CREATION;
            const templatesNodeActionsFiltered = noActions ? [] : templatesNodeActions;
            return this.buildTemplateNode(templateInfo, templateFolder.id, templatesNodeActionsFiltered, false);
        });

        templatesNodes = this.pipe.transform(templatesNodes);

        const children: TemplateFolderDto[] = initialTemplatesFolders.filter(tf => tf.templateFolderParentId === templateFolder.id);
        const childrenFileNodes: FileNode[] = children.map((child: TemplateFolderDto) =>
            this.buildFileNode(initialTemplatesFolders, child, foldersNodeActions, templatesNodeActions));

        const node: FileNode =
            this.buildFolderNode(templateFolder,
                nodeActionsFilteredByUserPermissions, [...childrenFileNodes, ...templatesNodes], false);

        node.children = this.pipe.transform(node.children);
        return node;
    }

    private buildFolderNode(
        templateFolder: TemplateFolderDto,

        defaultFolderNodeActions: NodeTreeActionType[],
        children: FileNode[],
        isChecked: boolean): FileNode {
        if (templateFolder.templateFolderParentId === this.EMPTY_GUID) {
            defaultFolderNodeActions =
                defaultFolderNodeActions.filter(v => v !== NodeTreeActionType.Rename && v !== NodeTreeActionType.Delete);
        }
        return {
            id: templateFolder.id,
            value: templateFolder.name,
            type: NodeTreeNodeType.Folder,
            children,
            parentId: templateFolder.templateFolderParentId,
            valueHighlighted: templateFolder.nameHighlighted ? templateFolder.nameHighlighted : null,
            actions: [...defaultFolderNodeActions],
            isChecked
        };
    }

    private buildTemplateNode(
        template: FolderItemInformationDto,
        templateFolderId: string,
        templateNodeActions: NodeTreeActionType[],
        isChecked: boolean): FileNode {
        const additionalData: AdditionalParam = {
            isInCreation: template.fileStatus.code === FileStatusesCodes.CREATION,
            isOnUpdate: template.fileStatus.code === FileStatusesCodes.UPDATE
        };
        return {
            id: template.id,
            value: template.name,
            type: NodeTreeNodeType.Template,
            children: [],
            parentId: templateFolderId,
            valueHighlighted: template.nameHighlighted ? template.nameHighlighted : null,
            actions: templateNodeActions,
            isChecked,
            additionalData
        };
    }

    private isNameUpdated(originalName: string, resultName: string): boolean {
        if (!resultName) {
            return false;
        }
        return originalName === resultName;
    }
}
