export const INHERIT_ALL_FROM_VALUES = {
    BLOCK_OBJECT: '$blockObject',
    CONTAINER: '$container',
    INLINE_OBJECT: '$inlineObject'
};

export const ALLOW_CONTENT_OF_VALUES = {
    ROOT: '$root',
    BLOCK: '$block',
    INLINE: '$inline',
    TEXT: '$text'
};

export const DISALLOW_CHILDREN = {
    IMAGE_INLINE: 'imageInline',
    IMAGE_BLOCK: 'imageBlock',
    TABLE: 'table'
};
