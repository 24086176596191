export enum DocumentStatusCodes {
    Draft = 1,
    Review = 2,
    Completed = 3,
    ForSignature = 4,
    Signed = 5,
}

export const DocumentStatusValues = {
    [DocumentStatusCodes.Draft]: 'draft',
    [DocumentStatusCodes.Review]: 'review',
    [DocumentStatusCodes.Completed]: 'completed',
    [DocumentStatusCodes.ForSignature]: 'signing',
    [DocumentStatusCodes.Signed]: 'signed'
};

export const DocumentStatusTextValues = {
    [DocumentStatusCodes.Draft]: $localize`:@@Borrador:Borrador`,
    [DocumentStatusCodes.Review]: $localize`:@@EnRevision:En revisión`,
    [DocumentStatusCodes.Completed]: $localize`:@@Completado:Completado`,
    [DocumentStatusCodes.ForSignature]: $localize`:@@EnviadosAFirma:Enviados a firma`,
    [DocumentStatusCodes.Signed]: $localize`:@@Firmado:Firmado`
};
