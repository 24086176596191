import { Injectable } from '@angular/core';
import {
    DocumentFolderDto, PermissionUserDocumentFolderDto, LastModificationFoldersService, LastModificationFoldersDTO
} from 'src/app/api';
import { AdministrationPermissionFolder } from '../../../models/administration-permission-folder.model';
import { IAdministrationPermissionDocumentsFoldersService } from './administration-permission-documents-folders.service.interface';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AdministrationPermissionDocumentsFoldersService extends IAdministrationPermissionDocumentsFoldersService {

    private currentDocumentLoadedDate = new BehaviorSubject<Date>(new Date());

    public generateFoldersFromDocumentFolders(documentFolders: DocumentFolderDto[],
                                              permissionFoldersAccess: PermissionUserDocumentFolderDto[]):
                                              AdministrationPermissionFolder[] {

        const folders: AdministrationPermissionFolder[] = [];
        documentFolders.forEach(documentFolder => {
            const adminFolder = this.getAdminFolderFromDocumentFolder(documentFolder, permissionFoldersAccess);
            folders.push(adminFolder);
        });

        return folders;
    }

    public getDocumentFoldersPermissions(permissionsFolder: AdministrationPermissionFolder[], userId: string):
                                         PermissionUserDocumentFolderDto[] {
        const permissions: PermissionUserDocumentFolderDto[] = [];
        const permissionsList = this.expandAdministrationTreeToList(permissionsFolder);

        permissionsList.forEach(element => {
            if (!element.hasAccess) {
                return;
            }
            const permissionToAccess = this.CreatePermissionUserDocumentFolderDto(element, userId);
            permissions.push(permissionToAccess);
        });

        return permissions;
    }

    public hasNewChangesDocumentFolders(lastModificationFoldersService: LastModificationFoldersService): Promise<boolean> {
        return new Promise<boolean>((resolve) => {
            lastModificationFoldersService.lastModificationFoldersGet().subscribe((data: LastModificationFoldersDTO) => {
                const needRefresh = data && new Date(data.lastModificationDocumentFolders) > this.getCurrentDocumentFolderLoadedDate();

                resolve(needRefresh);

            }, (error: any) => {
                resolve(true);
            });
        });
    }

    public updateDocumentFoldersChangesDate(): void {
        this.currentDocumentLoadedDate.next(this.getCurrentDateUTC());
    }

    private getCurrentDateUTC(): Date {
        const now = new Date();
        return new Date(now.getTime() + now.getTimezoneOffset() * 60000);
    }

    private expandAdministrationTreeToList(permissionsFolder: AdministrationPermissionFolder[]): AdministrationPermissionFolder[] {
        const permissionsList: AdministrationPermissionFolder[] = [];

        permissionsFolder.forEach(permissionFolder => {
            this.expandAdministrationTreeInList(permissionsList, permissionFolder);
        });
        return permissionsList;
    }

    private expandAdministrationTreeInList(permissionsList: AdministrationPermissionFolder[],
                                           permissionFolder: AdministrationPermissionFolder) {
        permissionsList.push(permissionFolder);
        permissionFolder.childrenFolder.forEach(childFolder => {
            this.expandAdministrationTreeInList(permissionsList, childFolder);
        });
    }

    private CreatePermissionUserDocumentFolderDto(permissionFolder: AdministrationPermissionFolder, userId: string) {
        const permission: PermissionUserDocumentFolderDto = {
            userId,
            documentFolderId: permissionFolder.id
        };
        return permission;
    }

    private getAdminFolderFromDocumentFolder(documentFolder: DocumentFolderDto,
                                             documentPermissions: PermissionUserDocumentFolderDto[]): AdministrationPermissionFolder {
        const adminFolder: AdministrationPermissionFolder = {
            id: documentFolder.id,
            name: documentFolder.name,
            folderParentId: documentFolder.documentFolderParentId,
            hasAccess: documentPermissions.find(permissions => permissions.documentFolderId === documentFolder.id) !== undefined,
            childrenFolder: []
        };

        documentFolder.childrenFolder.forEach(child => {
            const childAdminFolder = this.getAdminFolderFromDocumentFolder(child, documentPermissions);
            adminFolder.childrenFolder.push(childAdminFolder);
        });

        return adminFolder;
    }

    private getCurrentDocumentFolderLoadedDate(): Date {
        return this.currentDocumentLoadedDate.getValue();
    }
}
