/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { MasterData } from './masterData';
import { UserDTO } from './userDTO';

export interface DocumentSharedDTO { 
    id?: string;
    documentId?: string;
    isShared?: boolean;
    expiredDate?: Date;
    fullName?: string;
    email?: string;
    sharedByUserId?: string;
    sharedByUser?: UserDTO;
    lastSenderToReviewUserId?: string;
    lastSenderToReviewUser?: UserDTO;
    lastSharedDate?: Date;
    creationDate?: Date;
    isRevisor?: boolean;
    messageSubject?: string;
    messageBody?: string;
    isSameBuyer?: boolean;
    documentSharedPermissions?: Array<MasterData>;
}