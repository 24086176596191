/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DocumentSharedDTO } from '../model/documentSharedDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class ContractSharedService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param contractId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsSharedContractsContractIdDelete(contractId: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public contractsSharedContractsContractIdDelete(contractId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public contractsSharedContractsContractIdDelete(contractId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public contractsSharedContractsContractIdDelete(contractId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling contractsSharedContractsContractIdDelete.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/contracts-shared/contracts/${encodeURIComponent(String(contractId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsSharedContractsContractIdGet(contractId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSharedDTO>>;
    public contractsSharedContractsContractIdGet(contractId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSharedDTO>>>;
    public contractsSharedContractsContractIdGet(contractId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSharedDTO>>>;
    public contractsSharedContractsContractIdGet(contractId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling contractsSharedContractsContractIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentSharedDTO>>('get',`${this.basePath}/contracts-shared/contracts/${encodeURIComponent(String(contractId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param contractId 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsSharedContractsValidateEmailSharedContractIdEmailGet(contractId: string, email: string, observe?: 'body', reportProgress?: boolean): Observable<boolean>;
    public contractsSharedContractsValidateEmailSharedContractIdEmailGet(contractId: string, email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<boolean>>;
    public contractsSharedContractsValidateEmailSharedContractIdEmailGet(contractId: string, email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<boolean>>;
    public contractsSharedContractsValidateEmailSharedContractIdEmailGet(contractId: string, email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (contractId === null || contractId === undefined) {
            throw new Error('Required parameter contractId was null or undefined when calling contractsSharedContractsValidateEmailSharedContractIdEmailGet.');
        }

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling contractsSharedContractsValidateEmailSharedContractIdEmailGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<boolean>('get',`${this.basePath}/contracts-shared/contracts/validateEmailShared/${encodeURIComponent(String(contractId))}/${encodeURIComponent(String(email))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsSharedDelete(id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public contractsSharedDelete(id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public contractsSharedDelete(id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public contractsSharedDelete(id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/contracts-shared`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param id 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsSharedIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentSharedDTO>;
    public contractsSharedIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSharedDTO>>;
    public contractsSharedIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSharedDTO>>;
    public contractsSharedIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling contractsSharedIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentSharedDTO>('get',`${this.basePath}/contracts-shared/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsSharedPost(body?: DocumentSharedDTO, observe?: 'body', reportProgress?: boolean): Observable<DocumentSharedDTO>;
    public contractsSharedPost(body?: DocumentSharedDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSharedDTO>>;
    public contractsSharedPost(body?: DocumentSharedDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSharedDTO>>;
    public contractsSharedPost(body?: DocumentSharedDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentSharedDTO>('post',`${this.basePath}/contracts-shared`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public contractsSharedPut(body?: DocumentSharedDTO, observe?: 'body', reportProgress?: boolean): Observable<DocumentSharedDTO>;
    public contractsSharedPut(body?: DocumentSharedDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentSharedDTO>>;
    public contractsSharedPut(body?: DocumentSharedDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentSharedDTO>>;
    public contractsSharedPut(body?: DocumentSharedDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentSharedDTO>('put',`${this.basePath}/contracts-shared`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
