/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DocumentSheetDto } from './documentSheetDto';
import { MasterData } from './masterData';
import { OriginTemplateVersionDTO } from './originTemplateVersionDTO';
import { UserDTO } from './userDTO';
import { UserPermissionsOnDocumentDTO } from './userPermissionsOnDocumentDTO';
import { WarningChangesDTO } from './warningChangesDTO';

export interface CBDocumentDTO {
    id?: string;
    name: string;
    html?: string;
    creationTimeStamp?: Date;
    modificationTimeStamp?: Date;
    creationUser?: UserDTO;
    lastModificationUser?: UserDTO;
    buyerId?: string;
    buyer?: UserDTO;
    userPermissions?: UserPermissionsOnDocumentDTO;
    currentReviewerUserId?: string;
    iddOrigin?: string;
    warningChanges?: WarningChangesDTO;
    originTemplateVersion?: OriginTemplateVersionDTO;
    documentTypeId?: string;
    documentTypes?: MasterData;
    documentSheet?: DocumentSheetDto;
    comments?: string;
}
