import { FileFlatNode } from '../models/file-flat-node.model';
import { NodeTreeActionType } from '../enums/node-tree-action-type.enum';
import { NodeTreeAction } from '../models/node-tree-action.model';
import { AdditionalParam } from '../models/additional-param.model';
import { FileNode } from '../models/file-node.model';

export abstract class ITreeStandardService {
    abstract createNodeTreeAction(node: FileFlatNode, action: NodeTreeActionType): NodeTreeAction;
    abstract createNodeTreeActionWithAdditionalParam(node: FileFlatNode, action: NodeTreeActionType,
                                                     actionParam: AdditionalParam): NodeTreeAction;
    abstract createNodeTreeActionWithCallbacks(node: FileFlatNode, action: NodeTreeActionType, additionalParam: AdditionalParam,
                                               successfulActionResult: (node: FileFlatNode) => void,
                                               failActionResult: (node: FileFlatNode) => void): NodeTreeAction;
    abstract createAdditionalParamWithDroppedObjectId(droppedObjectId?: string): AdditionalParam;
    abstract createAdditionalParamWithDestinationNode(destinationNode?: FileNode): AdditionalParam;
    abstract createAdditionalParamWithIsChecked(isChecked?: boolean): AdditionalParam;
    abstract createAdditionalParamWithCheckedNode(isChecked?: boolean, checkedNodes?: Array<string>): AdditionalParam;
}
