<div class="help-body {{mode}}">
    <div *ngIf="titleBody" class="help-body-title">
        <div class="title" [ngClass]="styleClass">{{titleBody}}</div>
    </div>

    <div *ngFor="let item of itemList" class="help-body-item">
        <p class="label">{{item.label}}</p>
        <p class="text">{{item.text}}</p>
        <ctbox-button *ngIf="item.action" [buttonIcon]="item.actionIcon" [buttonText]="item.actionText" [customClass]="item.actionStyle" (buttonClick)="clickAction(item)"></ctbox-button>
    </div>

    <div *ngIf="creationUser" class="help-body-item">
        <p class="label" i18n="@@Autor">Autor Original</p>
        <p class="text">{{creationUser}}</p>
    </div>
    <div *ngIf="lastModificationUser" class="help-body-item">
        <p class="label" i18n="@@AutorUltimaModificacion">Ultima modificación</p>
        <p class="text">{{lastModificationUser}}</p>
    </div>
    <div *ngIf="sharedByUser" class="help-body-item">
        <p class="label">Compartido por</p>
        <p class="text">{{sharedByUser}}</p>
    </div>
    <ng-content></ng-content>
</div>