import { FileDownloadDto } from './../../../../api/model/fileDownloadDto';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export abstract class IFileDownloadService {

    abstract downloadFileFromFileDownload(downloadFile: FileDownloadDto);
    abstract downloadFile(content: string, contentType: string, fileName: string, fileExtension: string);
    abstract saveFromBrowser(blob: Blob, fileName: string, fileExtension: string);
}
