import { Editor, Item, Writer } from "ckeditor5";
import { BaseLinkedFieldCommand } from "./base-linked-field-command";

export default class DeleteLinkedFieldCommand extends BaseLinkedFieldCommand {

    constructor(editor: Editor) {
        super(editor);
    }

    public override execute(id: string): void {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);

            const inputModel: Item = this.getElementWithId(range, id)?.item as Item;

            writer.remove(inputModel);
		});
    }

    public override refresh(): void {
        super.refresh();

        if (!this.isEnabled) {
            this.enableCommandIfCursorInsideInputContent();
        }
    }
}
