import { ChangeDetectorRef, Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
    selector: '[appInteger]'
})
export class IntegerDirective {

    @Input() public maxLength = 999;

    public constructor(private control: NgControl, private elementRef: ElementRef, private changeDetectorRef: ChangeDetectorRef) {
    }

    @HostListener('blur', ['$event'])
    @HostListener('keydown', ['$event'])
    onBlur(event: any): void {
        if (event === null || typeof (event) === 'undefined') {
            return;
        }

        this.formatToInteger(this.elementRef.nativeElement.value);
    }

    private formatToInteger(value: string): void {
        value = this.convertToPositiveNumber(value);
        value = this.removeCommas(value);

        if (this.checkIsValidInteger(value)) {
            value = this.delimitNumberToMaxLength(value);
            this.elementRef.nativeElement.value = value;
            this.control.control.patchValue(value);
        } else {
            this.elementRef.nativeElement.value = '';
            this.control.control.patchValue('');
        }

        this.changeDetectorRef.detectChanges();
    }

    private convertToPositiveNumber(value: string): string {
        if (!value.startsWith('-')) {
            return value;
        }

        value = value.replace('-', '');
        return value;
    }

    private removeCommas(value: string): string {
        const decimalIndex = value.lastIndexOf('.');
        if (decimalIndex !== -1) {
            value = value.substring(0, decimalIndex);
        }

        value = value.replace(/\./g, '');
        return value;
    }

    private checkIsValidInteger(value: any): boolean {
        return !Number.isNaN(value) && Number(value) > 0;
    }

    private delimitNumberToMaxLength(value: string): string {
        const integer = +value;
        if (integer > this.maxLength) {
            value = value.substring(0, this.maxLength.toString().length);
        }

        return value;
    }
}
