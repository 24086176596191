import { Injectable } from "@angular/core";
import { Writer, Element } from "ckeditor5";
import { SelectPlugin } from "../../plugins/select/select-plugin";
import { SelectModel } from "../../models/select/select-model";

@Injectable({
    providedIn: "root",
})
export class SelectDataViewUtilsService {

    public createSelectBasicStructureModelWithoutOptions(writer: Writer, selectModel: SelectModel): Element {
        const containerElement = this.createSelectElementModel(writer,selectModel);
        const contentElement = this.createSelectContentElement(writer,selectModel);
        writer.insert(contentElement, containerElement, 0);

        return containerElement;
    }

    public createSelectCompleteStructureModelWithOptions(writer: Writer, selectModel: SelectModel): Element {
        const containerElement = this.createSelectElementModel(writer,selectModel);
        const contentElement = this.createSelectContentStructure(writer,selectModel);
        writer.insert(contentElement, containerElement, 0);

        return containerElement;
    }

    private createSelectElementModel(writer: Writer, selectModel: SelectModel): Element {
        return writer.createElement(SelectPlugin.MODEL_ENTITIES.container.model, {
            'id': selectModel.id,
            'data-embedded-in': selectModel.embeddedIn ? selectModel.embeddedIn : "undefined",
        });
    }

    private createSelectContentElement(writer: Writer, selectModel: SelectModel): Element {
        const contentElement = writer.createElement(SelectPlugin.MODEL_ENTITIES.content.model, {
            'selectedIndex': selectModel.selectedIndex
        });

        return contentElement;
    }

    private createSelectContentStructure(writer: Writer, selectModel: SelectModel): Element {
        const contentElement = writer.createElement(SelectPlugin.MODEL_ENTITIES.content.model, {
            'selectedIndex': selectModel.selectedIndex
        });
        this.addOptions(writer, selectModel, contentElement);

        return contentElement;
    }

    private addOptions(writer: Writer, selectModel: SelectModel, containerElement: Element): void {
        selectModel.options?.reverse().forEach((option: string) => {
            this.addOption(writer, option, containerElement);
        })
    }

    private addOption(writer: Writer, option: string, containerElement: Element): void {
        const optionElement = writer.createElement(SelectPlugin.MODEL_ENTITIES.option.model, {
            value: option
        });
        writer.insert(optionElement, containerElement, 0);
    }
}
