import { Injectable } from '@angular/core';

declare let CKEDITOR: any;

@Injectable({
  providedIn: 'root'
})
export class CkeditorFormsCheckBoxService {

  constructor() { }


  configureCheckBox() {
    if(CKEDITOR.dialog.exists('checkbox')){
      return;
    }

    CKEDITOR.dialog.add( 'checkbox', ( editor ) => {
      return {
        title: 'Insertar texto opcional',
        minWidth: 350,
        minHeight: 50,
        getModel( editorModel ) {
          const element = editorModel.getSelection().getSelectedElement();

          if ( element && element.getAttribute( 'type' ) === 'checkbox' ) {
            return element;
          }

          return null;
        },
        onShow() {
          const element = this.getModel( this.getParentEditor() );

          if ( element ) {
            this.setupContent( element );
          }
        },
        onOk() {
          const editorOnOk = this.getParentEditor();
          let  element = this.getModel( editorOnOk );

          if ( !element ) {
            element = editorOnOk.document.createElement( 'input' );
            element.setAttribute( 'type', 'checkbox' );
            editorOnOk.insertElement( element );

            element.$.addEventListener('click', (event) => {
                const checkedAttribute = element.getAttribute('checked')
                element.setAttribute('checked', checkedAttribute && checkedAttribute == 'true' ? 'false' : 'true' );
                event.stopPropagation();
            }, true);
          }

          this.commitContent( { element } );
        },
        contents: [ {
          id: 'info',
          label: editor.lang.forms.checkboxAndRadio.checkboxTitle,
          title: editor.lang.forms.checkboxAndRadio.checkboxTitle,
          startupFocus: 'txtName',
          elements: [
          {
            id: 'cmbSelected',
            type: 'checkbox',
            label: editor.lang.forms.checkboxAndRadio.selected,
            default: '',
            accessKey: 'S',
            value: 'checked',
            setup( element ) {
              this.setValue( element.getAttribute( 'checked' ) );
            },
            commit( data ) {
              const element = data.element;

              if ( CKEDITOR.env.ie ) {
                const isElementChecked = !!element.getAttribute( 'checked' );
                const isChecked = !!this.getValue();

                if ( isElementChecked !== isChecked ) {
                  const replace = CKEDITOR.dom.element.createFromHtml( '<input type="checkbox"' +
                  ( isChecked ? ' checked="checked"' : '' ) +'/>', editor.document );

                  element.copyAttributes( replace, { type: 1, checked: 1 } );
                  replace.replace( element );
                  editor.getSelection().selectElement( replace );
                  data.element = replace;
                }
              } else {
                const value = this.getValue();
                // Blink/Webkit needs to change checked property, not attribute. (https://dev.ckeditor.com/ticket/12465)
                if ( CKEDITOR.env.webkit ) {
                  element.$.checked = value;
                }

                if ( value ) {
                  element.setAttribute( 'checked', 'checked' );
                } else {
                  element.removeAttribute( 'checked' );
                }
              }
            }
          },
          {
            id: 'required',
            type: 'checkbox',
            label: editor.lang.forms.checkboxAndRadio.required,
            default: '',
            accessKey: 'Q',
            value: 'required',
            setup: CKEDITOR.plugins.forms._setupRequiredAttribute,
            commit( data ) {
              const element = data.element;
              if ( this.getValue() ) {
                element.setAttribute( 'required', 'required' );
              } else {
                element.removeAttribute( 'required' );
              }
            }
          } ]
        } ]
      };
    } );
  }


}
