import { Editor, toWidget, toWidgetEditable, Element as CkElement } from 'ckeditor5';
import { Injectable } from "@angular/core";
import { SelectPlugin } from '../../plugins/select/select-plugin';
import { SelectModel } from '../../models/select/select-model';

@Injectable({
    providedIn: "root",
})
export class SelectModelToEditorViewConverterService {

    public configureConverter(editor: Editor): void {
        this.configureConverterFromModelToEditorView(editor);
        this.optionsConverter(editor);
    }

    private configureConverterFromModelToEditorView(editor: Editor): void {
        this.containerConversionStructure(editor);
    }

    private containerConversionStructure(editor: Editor): void {
        editor.conversion.for('editingDowncast')
            .elementToElement({
                model: SelectPlugin.MODEL_ENTITIES.container.model,
                view: (modelElement: CkElement, { writer }) => {
                    const selectModel = this.getModel( modelElement);
                    const containerElement = writer.createContainerElement('span', {
                        'id': selectModel.id,
                        style: 'display: inline-block',
                    });

                    writer.addClass([SelectPlugin.MODEL_ENTITIES.container.editionView], containerElement);
                    return toWidget(containerElement, writer);
                }
            })
            .elementToElement({
                model: SelectPlugin.MODEL_ENTITIES.content.model,
                view: (modelElement: CkElement, { writer }) => {
                    const selectModel = this.getModel(modelElement.parent as CkElement);
                    let parentId = '';
                    const parent = modelElement.parent;
                    if(parent.is('element')) {
                        parentId = parent.getAttribute(SelectPlugin.ATTRIBUTE_ID)?.toString();
                    }
                    const selectId = `${SelectPlugin.PRE_PREFIX_ID_SELECT}${parentId}`;

                    const selectElement = writer.createEditableElement('select', {
                        id: selectId,
                        value: selectModel.value,
                    });
                    writer.addClass([SelectPlugin.MODEL_ENTITIES.content.editionView], selectElement);
                    return toWidgetEditable(selectElement, writer);
               }
           });
    }

    private optionsConverter(editor: Editor) {
        editor.conversion.for('editingDowncast').elementToElement({
                model: SelectPlugin.MODEL_ENTITIES.option.model,
                view: (modelElement: CkElement, { writer }) => {
                    const value = modelElement.getAttribute(SelectPlugin.ATTRIBUTE_VALUE);
                    const selected = modelElement.getAttribute(SelectPlugin.ATTRIBUTE_SELECTED);
                    const optionElement = writer.createContainerElement(SelectPlugin.MODEL_ENTITIES.option.editionView, {
                        class: SelectPlugin.MODEL_ENTITIES.option.editionView,
                        value: value
                    }, [writer.createText(value.toString())]);
                    if (!!selected) {
                        writer.setAttribute(SelectPlugin.ATTRIBUTE_SELECTED, selected, optionElement);
                    }
                    return optionElement;
                }
        });
    }

    private getModel(modelElement: CkElement): SelectModel {
        const id = modelElement.getAttribute(SelectPlugin.ATTRIBUTE_ID)?.toString();
        const value = modelElement.getAttribute(SelectPlugin.ATTRIBUTE_VALUE)?.toString();
        const selectedIndexText = modelElement.childCount > 0 ? modelElement.getChild(0).getAttribute(SelectPlugin.ATTRIBUTE_SELECTED_INDEX)?.toString() : '0';
        const selectedIndex = parseInt(selectedIndexText);
        const model: SelectModel = {
            id,
            value: value,
            selectedIndex: selectedIndex
        };

        return model;
    }
}
