import { HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiOperation, ApiProcessorService, ApiRequest, Configuration, MasterData } from "src/app/api";

@Injectable()
export class ExportFormatsService {

    protected basePath = '/api/masterdata-management/export-formats';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(private readonly apiProcessorService: ApiProcessorService) {  }

    public getAll(): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.basePath;

        return this.apiProcessorService.proccessRequest<MasterData[]>(apiRequest);
    }
}
