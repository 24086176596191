<ng-container *ngIf="data && isTableEmpty">
    <div class="selection-list-message warning">
        <p class="h3">{{ emptyTableMessage }}</p>
    </div>
</ng-container>
<ng-container *ngIf="data && !isTableEmpty">
    <div class="selection-list-container" [ngClass]="{'disabled': isDisabled}">
        <div class="selection-list-header">
            <div class="selection-list-check" *ngIf="showOnlySelectedCheckbox">
                <mat-checkbox [(ngModel)]="isFilterByOnlySelectedCheckboxChecked" [disabled]="isOnlySelectedCheckboxDisabled" (change)="onOnlySelectedCheckboxChanged($event.checked)">
                    Ver sólo marcados
                </mat-checkbox>
            </div>
            <div class="selection-list-label" *ngIf="listLabel">
                <label class="mat-form-field-label mat-empty mat-form-field-empty">
                    <mat-label> {{ listLabel }} </mat-label>
                    <span *ngIf="required" class="mat-placeholder-required mat-form-field-required-marker">&nbsp;*&nbsp;</span>
                </label>
            </div>
            <div class="selection-list-search" *ngIf="displaySearchbox">
                <ctbox-search-list displaySearchbox="true" [dataSource]="dataTable" [visibleColumns]="visibleColumns"></ctbox-search-list>
            </div>
        </div>
        <div class="selection-list-content" *ngIf="dataTable">
            <table mat-table matSort class="table-bg-white" [dataSource]="dataTable">
                <caption>Lista de selección</caption>
                <ng-container matColumnDef="select">
                    <th mat-header-cell *matHeaderCellDef class="mat-header-cell-check" id="thSelectionListContent">
                        <ng-template>
                            <mat-checkbox *ngIf="displayCheckAll" [disabled]="isDisabled" (change)="$event ? masterToggle() : null" [checked]="selection.hasValue() && isAllSelected()" [indeterminate]="selection.hasValue() && !isAllSelected()" [aria-label]="checkboxLabel()" matTooltip="{{ displayCheckAllLabel }}"
                                matTooltipPosition="above"></mat-checkbox>
                        </ng-template>
                    </th>
                    <td mat-cell *matCellDef="let row" class="mat-cell-check">
                        <mat-checkbox [disabled]="isDisabled || (isRowDisabled && isRowDisabled(row))" (click)="$event.stopPropagation()" (change)="$event ? toggleElement(row) : null" [checked]="selection.isSelected(row)" [aria-label]="checkboxLabel(row)"></mat-checkbox>
                    </td>
                    <ng-container *ngIf="footerDefinition">
                        <td mat-footer-cell *matFooterCellDef>{{ footerDefinition.title }}</td>
                    </ng-container>
                </ng-container>
                <ng-container *ngFor="let column of columnsDefinitionValues; let i = index">
                    <ng-container [matColumnDef]="column.name">
                        <th mat-header-cell *matHeaderCellDef id="columnTitle" [ngClass]="{'mat-header-cell-icon': column.type === 'icon' }">
                            <div class="filter">
                                <div mat-sort-header>
                                    <ng-container [ngTemplateOutlet]="renderTitle" [ngTemplateOutletContext]="{ value: column.title, type: column.type, propertyName: column.propertyName, toolTip: column.toolTip}"></ng-container>
                                </div>
                            </div>
                        </th>
                        <td mat-cell *matCellDef="let element" [ngClass]="{'columndisabled': (isRowDisabled && isRowDisabled(element)), 'mat-cell-icon': column.type === 'icon' }">
                            <ng-container [ngTemplateOutlet]="renderValue" [ngTemplateOutletContext]="{ value: element[column.name], type: column.type, propertyName: column.propertyName}"></ng-container>
                        </td>
                        <ng-container *ngIf="footerDefinition">
                            <td mat-footer-cell *matFooterCellDef>
                                <ng-container *ngIf="i === (columnsDefinitionValues.length - 1)">
                                    <ng-container [ngTemplateOutlet]="renderValue" [ngTemplateOutletContext]="{ value: footerDefinition.value, type: footerDefinition.type, propertyName: column.propertyName }"></ng-container>
                                </ng-container>
                            </td>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns" class="mat-header-color"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns" [ngClass]="statusRow(row)"></tr>
                <ng-container *ngIf="footerDefinition">
                    <tr mat-footer-row *matFooterRowDef="displayedColumns"></tr>
                </ng-container>
            </table>
        </div>
        <div class="selection-list-footer">
            <div class="mat-chip">
                <ng-container *ngIf="selection && selection.selected && displayAllSelected">
                    <mat-chip-list aria-label="selected">
                        <mat-chip color="selected" [disabled]="isDisabled">
                            <strong>{{ selection.selected.length }}</strong> &nbsp; seleccionados
                        </mat-chip>
                    </mat-chip-list>
                </ng-container>
            </div>
            <mat-paginator [hidePageSize]="true" [pageSizeOptions]="[5, 10, 25, 100]" (page)="updatePageSize()"></mat-paginator>
        </div>
    </div>
</ng-container>

<ng-template #renderTitle let-value="value" let-type="type" let-propertyName="propertyName" let-toolTip="toolTip">
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'string'" [ngTemplateOutlet]="defaultContent" [ngTemplateOutletContext]="{ value: value }"></ng-container>
        <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultContent" [ngTemplateOutletContext]="{ value: value }"></ng-container>
    </ng-container>
</ng-template>

<ng-template #renderValue let-value="value" let-type="type" let-propertyName="propertyName">
    <ng-container [ngSwitch]="type">
        <ng-container *ngSwitchCase="'currency'" [ngTemplateOutlet]="currencyContent" [ngTemplateOutletContext]="{ value: value }"></ng-container>
        <ng-container *ngSwitchCase="'string'" [ngTemplateOutlet]="defaultContent" [ngTemplateOutletContext]="{ value: value }"></ng-container>
        <ng-container *ngSwitchCase="'number'" [ngTemplateOutlet]="numberContent" [ngTemplateOutletContext]="{ value: value }"></ng-container>
        <ng-container *ngSwitchCase="'date'" [ngTemplateOutlet]="dateContent" [ngTemplateOutletContext]="{ value: value }"></ng-container>
        <ng-container *ngSwitchCase="'object'" [ngTemplateOutlet]="objectContent" [ngTemplateOutletContext]="{ value: value, propertyName: propertyName }"></ng-container>
        <ng-container *ngSwitchDefault [ngTemplateOutlet]="defaultContent" [ngTemplateOutletContext]="{ value: value }"></ng-container>
    </ng-container>
</ng-template>

<ng-template #defaultContent let-value="value">
    {{ value }}
</ng-template>

<ng-template #currencyContent let-value="value">
    {{ value | currency: 'EUR' }}
</ng-template>

<ng-template #numberContent let-value="value">
    {{ value | number }}
</ng-template>

<ng-template #objectContent let-value="value" let-propertyName="propertyName">
    <ng-container *ngIf="propertyName && value">
        {{ value[propertyName] }}
    </ng-container>
</ng-template>

<ng-template #dateContent let-value="value">
    {{ value | date: 'dd/MM/yyyy HH:mm' }}
</ng-template>