import { Injectable } from '@angular/core';
import { DocumentClauseDTO } from 'src/app/api';

@Injectable({
  providedIn: 'root'
})
export abstract class IClausesPreviewService {

  abstract orderBy(data: DocumentClauseDTO, sortHeaderId: string): any;
  abstract orderListBy(data: DocumentClauseDTO[], sortHeaderId: string): DocumentClauseDTO[];
}
