import { Directive } from '@angular/core';
import { Editor, Locale, DropdownView, EventInfo, Plugin } from 'ckeditor5';
import { UI_CLASSES } from '../../ui/styles/styles-constants';
import { DropdownViewModel, RowDropdownViewModel } from '../../ui/view/row-dropdown-view.model';
import CtboxDropdownView from '../../ui/view/dropdown-view.directive';
import { InputPlugin } from './input-plugin';
import { LinkedFieldPlugin } from '../linked-field/linked-field-plugin';

@Directive()
export class InputToolbarPlugin extends Plugin {

    public static readonly PLUGIN_NAME = 'input-toolbar-pg';
    public static readonly PLUGIN_TOOLBAR_BUTTON_NAME = 'input-toolbar-button';

    private readonly SHORT_TEXT_INPUT_BUTTON_NAME = 'ShortTextInput';
    private readonly LARGE_TEXT_INPUT_BUTTON_NAME = 'LargeTextInput';
    private readonly DATE_INPUT_BUTTON_NAME = 'DateInput';
    private readonly NUMBER_INPUT_BUTTON_NAME = 'NumberInput';
    private readonly LINKED_FIELD_INPUT_BUTTON_NAME = 'LinkedFieldInput';

    private readonly inputToolbarMessage = $localize`:@@TooltipInputPlugin:Insertar campos rellenables`;
    private readonly shortTextInputMessage = $localize`:@@PluginInputBotoneraTextoCorto:Texto Corto`;
    private readonly shortTextInputHelpMessage = $localize`:@@EjemploTextoCorto:Ej: nombre, apellido, ciudad`;
    private readonly largeTextInputMessage = $localize`:@@PluginInputBotoneraTextoLargo:Texto largo`;
    private readonly largeTextInputHelpMessage = $localize`:@@EjemploTextoLargo:Ej: comentarios, notas más extensas`;
    private readonly dateInputMessage = $localize`:@@PluginInputBotoneraFecha:Fecha`;
    private readonly dateInputHelpMessage = $localize`:@@EjemploFecha:Ej: 24/06/2024 o 24 de Junio de 2024`;
    private readonly numberInputMessage = $localize`:@@PluginInputBotoneraNumero:Número`;
    private readonly numberInputHelpMessage = $localize`:@@EjemploNumero:Números enteros o decimales`;
    private readonly linkedFieldMessage = $localize`:@@PluginInputBotoneraCampoVinculado:Campo vinculado`;
    private readonly linkedFieldHelpMessage = $localize`:@@EjemploCampoVinculado:Rellena simultáneamente usando el alias`;

    constructor(editor: Editor) {
        super(editor);
    }

    // TODO: Añadir aqui el resto de plugins conforme se vayan creando
    public static get requires() { return [InputPlugin, LinkedFieldPlugin]; }
    public static get pluginName(): string { return InputToolbarPlugin.PLUGIN_NAME; }
    public static get pluginToolbarElementName(): string { return InputToolbarPlugin.PLUGIN_TOOLBAR_BUTTON_NAME; }

    public init(): void {
        this.toolbarInteractions();
    }

    private toolbarInteractions(): void {
        const inputDropdownToolbar = this.createDropdownForToolbar();
        this.addDropdownToToolbar(inputDropdownToolbar.dropdown);
    }

    private createDropdownForToolbar(): CtboxDropdownView {
        const locale = new Locale();

        const shortTextRowView: RowDropdownViewModel = {
            name: this.SHORT_TEXT_INPUT_BUTTON_NAME,
            titleText: this.shortTextInputMessage,
            helpText: this.shortTextInputHelpMessage,
            iconSvg: UI_CLASSES.SVG_ICONS.ICON_SHORT_TEXT,
        };

        const largeTextRowView: RowDropdownViewModel = {
            name: this.LARGE_TEXT_INPUT_BUTTON_NAME,
            titleText: this.largeTextInputMessage,
            helpText: this.largeTextInputHelpMessage,
            iconSvg: UI_CLASSES.SVG_ICONS.ICON_LONG_TEXT
        };

        // TODO falta icono
        const dateRowView: RowDropdownViewModel = {
            name: this.DATE_INPUT_BUTTON_NAME,
            titleText: this.dateInputMessage,
            helpText: this.dateInputHelpMessage,
            iconSvg: ''
        };

        // TODO falta icono
        const numberRowView: RowDropdownViewModel = {
            name: this.NUMBER_INPUT_BUTTON_NAME,
            titleText: this.numberInputMessage,
            helpText: this.numberInputHelpMessage,
            iconSvg: ''
        };

        const linkedFieldRowView: RowDropdownViewModel = {
            name: this.LINKED_FIELD_INPUT_BUTTON_NAME,
            titleText: this.linkedFieldMessage,
            helpText: this.linkedFieldHelpMessage,
            iconSvg: UI_CLASSES.SVG_ICONS.LINKED_INPUTS
        };

        const modelDropdown: DropdownViewModel = {
            titleText: 'dropdown',
            helpText: this.inputToolbarMessage,
            iconSvg: UI_CLASSES.SVG_ICONS.ICON_INPUT,
            buttonRowDropdownViewModel: [shortTextRowView, largeTextRowView, linkedFieldRowView]
        };

        const dropdownView = new CtboxDropdownView(locale, modelDropdown);
        this.handlerDropdownView(dropdownView);

        return dropdownView;
    }

    private addDropdownToToolbar(inputDropDownToolbar: DropdownView): DropdownView | null {
        this.editor.ui.componentFactory.add(
            InputToolbarPlugin.pluginToolbarElementName,
            () => {
                return inputDropDownToolbar;
            });
        return null;
    }

    private handlerDropdownView(dropdownView: CtboxDropdownView): void {
        dropdownView.on('dropdownClicked', (_, name: EventInfo) => {
            let plugin;
            switch ((name.source as { model: { name: string; }; }).model.name) {
                // TODO hay que ir añadiendo conforme se vayan haciendo plugins
                case this.SHORT_TEXT_INPUT_BUTTON_NAME:
                    plugin = this.editor.plugins.get(InputPlugin.pluginName) as InputPlugin;
                    break;
                case this.LINKED_FIELD_INPUT_BUTTON_NAME:
                    plugin = this.editor.plugins.get(LinkedFieldPlugin.pluginName) as LinkedFieldPlugin;
                    break;
                default:
                    return;
            }
            plugin.toolbarExecuteOperation();
        });
    }
}
