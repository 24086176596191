import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { ApiOperation, ApiProcessorService, ApiRequest, ApiResponse } from "src/app/api";
import { TemplateSheetDto } from "src/app/api/model/cmc/templateSheetDto";
import { UrlTree } from "@angular/router";

@Injectable()
export class TemplateSheetService {

    protected basePath = '/api/templates-management/templates';

    constructor(private readonly apiProcessorService: ApiProcessorService) {  }

    public getTemplateSheet(templateId: string): Observable<ApiResponse<TemplateSheetDto> | UrlTree> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${ this.basePath }/${templateId}/sheets`;

        return this.apiProcessorService.proccessRequest<TemplateSheetDto>(apiRequest);
    }

    public createTemplateSheet(templateId: string, templateSheet: TemplateSheetDto): Observable<ApiResponse<TemplateSheetDto> | UrlTree> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = templateSheet;
        apiRequest.endpointUrl = `${ this.basePath }/${templateId}/sheets`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public updateTemplateSheet(templateId: string , templateSheet: TemplateSheetDto) : Observable<ApiResponse<TemplateSheetDto> | UrlTree> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.endpointUrl = `${this.basePath}/${templateId}/sheets`;
        apiRequest.body = templateSheet;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

}
