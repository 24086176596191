import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'dateFormat'
})
export class DateFormatPipe implements PipeTransform {

    transform(value: Date, ...args: unknown[]): string {
        if (value === null) {
            return '';
        }
        const date = moment.utc(value);
        const diffDays = moment().diff(date, 'days');
        if (Math.abs(diffDays) < 1 && moment().local().day() === date.local().day()) {
            return date.local().format('HH:mm');
        } else {
            return date.local().format('DD/MM/YYYY');
        }
    }

}
