import { AfterViewInit, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControlStatus, FormGroup, UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { FileStepperDataService } from 'src/app/core/shared/services/file-stepper-data.service';
import { IFileStepper } from '../upload-file/file-stepper.model';

@Component({
  selector: 'app-file-upload-management',
  templateUrl: './file-upload-management.component.html',
  styleUrls: ['./file-upload-management.component.scss']
})
export class FileUploadManagementComponent implements OnInit, OnDestroy, AfterViewInit {

    @Input() public maxNumberFilesAllowed = 20;
    @Input() public allowedExtensions = ['.docx'];
    @Input() public maxMgFileSize = 20;

    @Output() public isFileManagementFormValid = new EventEmitter<boolean>();
    @Output() public newFilesAdded = new EventEmitter<IFileStepper[]>();

    @Output() public fileModify = new EventEmitter<IFileStepper>();
    @Output() public fileRemove = new EventEmitter<IFileStepper>();

    public files: IFileStepper[] = [];
    public status: FormControlStatus;
    public fileManagementForm: FormGroup;

    private onDestroy$ = new EventEmitter<void>();

    public constructor(private readonly fileStepperDataService: FileStepperDataService) {
      this.initializeFormGroup();
    }

    public ngOnInit(): void {
        this.fileStepperDataService.getFilesSelected()
        .pipe(takeUntil(this.onDestroy$))
        .subscribe((files: IFileStepper[]) => {
            this.files = files;
            this.fileManagementForm.controls.numberFiles.setValue(files.length);
        });
    }

    public ngAfterViewInit(): void {
        if (this.status !== 'VALID' && this.status !== 'INVALID') {
            this.isFileManagementFormValid.next(false);
        }
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public onUploadedFiles(files: IFileStepper[]): void {
        this.notifyAddedFiles(files);
    }

    public onNotifyRemoveFile(fileToRemove: IFileStepper): void {
        const numberFiles = this.fileManagementForm.get('numberFiles').value - 1;
        this.fileManagementForm.patchValue({numberFiles});
        this.notifyRemoveFiles(fileToRemove);
    }

    public notifyCheckedVersionFile(fileToCheckVersion: IFileStepper): void {
        const file = this.files.find(file => file.id === fileToCheckVersion.id);
        file.generateVersion = fileToCheckVersion.generateVersion;
        this.notifyCheckedFiles(fileToCheckVersion);
    }

    protected maxFilesExceed(): boolean {
        return this.fileManagementForm.get('numberFiles').errors?.maxFilesExceeded === true;
    }

    private initializeFormGroup(): void {
        this.fileManagementForm = new UntypedFormGroup({
            numberFiles: new UntypedFormControl(0, [this.maxFilesValidator(this.maxNumberFilesAllowed), Validators.min(1)]),
            uploadedFiles: new UntypedFormArray([])
        });
        this.fileManagementForm.statusChanges
        .pipe(takeUntil(this.onDestroy$))
        .subscribe(status => {
            if (status !== 'VALID' && status !== 'INVALID') {
                return;
            }
            this.isFileManagementFormValid.next(status === 'VALID');
            this.status = status;
        });
    }

    private maxFilesValidator(maxFiles: number) {
        return (control: AbstractControl): { [key: string]: any } | null => {
          if (control.value > maxFiles) {
            return { maxFilesExceeded: true };
          }
          return null;
        };
    }

    private notifyAddedFiles(newFiles: IFileStepper[]): void {
        this.newFilesAdded.emit(newFiles);
    }

    private notifyRemoveFiles(fileToRemove: IFileStepper): void {
        this.fileRemove.emit(fileToRemove);
    }

    private notifyCheckedFiles(fileToModify: IFileStepper): void {
        this.fileModify.emit(fileToModify);
    }
}
