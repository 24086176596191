export class GlobalConstant {
    public static readonly CONTAINER_CLASS_EDITION_VIEW = 'cbox-container';
    public static readonly ATTRIBUTE_ID = "id";
    public static readonly ATTRIBUTE_VALUE = "value";
    public static readonly ATTRIBUTE_EMBEDDED_IN = "data-embedded-in";
    public static readonly ATTRIBUTE_SRC = "src";
    public static readonly LABEL_DIV = "div";
    public static readonly MODEL_PARAGRAPH = 'paragraph';

    public static readonly UNDEFINED = 'undefined';
    public static readonly PHONE = 'phone';
    public static readonly EMAIL = 'email';
    public static readonly DNI = 'DNI';
    public static readonly NIE = 'NIE';
    public static readonly NIF = 'NIF';
    public static readonly IBAN = 'IBAN';
    public static readonly UPPERCASE = 'uppercase';
    public static readonly LOWERCASE = 'lowercase';
    public static readonly CAMEL = 'camel';
}
