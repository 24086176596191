import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortAlphabetical'
})
export class SortAlphabetical implements PipeTransform {
    transform(array: any, field: string): any[] {
         if (!Array.isArray(array) || array.length <= 1) {
      return array;
    }

    return array.sort((a: any, b: any) => {
        const valueA = a[field].toLowerCase();
        const valueB = b[field].toLowerCase();
        return valueA.localeCompare(valueB);
    });
    }
}
