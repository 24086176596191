import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiProcessorService } from '../api-processor.service';
import { BaseClauseFolderService } from '../base/base-clause-folder.service';
import { RequestQueryParamsService } from '../requestQueryParams.service';

@Injectable()
export class ManagerClausesFoldersService extends BaseClauseFolderService {

    public override basePath = '/api/manager-management/clauses-folders';

    constructor(
        protected readonly apiProcessorService: ApiProcessorService,
        protected readonly mapRequestQueryParams: RequestQueryParamsService
    ) {
        super(apiProcessorService, mapRequestQueryParams)
    }

    public uploadFile(clauseFolderId: string, file: File): Observable<any> {
        const reqBody = new FormData();
        reqBody.append('file', file, file.name);

        return this.apiProcessorService.postActionAsFormData(reqBody, `${this.basePath}/${clauseFolderId}/upload-file`);
    }
}
