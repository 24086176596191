import { Inject, Injectable, Optional } from '@angular/core';
import { Configuration } from '../configuration';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BASE_PATH } from '../variables';
import { Observable } from 'rxjs';
import { SearchResultDto } from '../model/searchResultDto';

@Injectable()
export class TemplateSearchService {
    basePath = '/';
    configuration: Configuration;
    defaultHeaders = new HttpHeaders();

    constructor(
        protected httpClient: HttpClient,
        @Optional() @Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration
    ) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    search(searchTerm: string): Observable<SearchResultDto[]> {
        let headers = this.defaultHeaders;
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (!!httpHeaderAcceptSelected) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.request<SearchResultDto[]>('get', `${this.basePath}/api/inquire-management/templates/search?searchTerm=${searchTerm}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers
            }
        );
    }

    searchLastUpdated(startDate: string, endDate: string): Observable<SearchResultDto[]> {
        let headers = this.defaultHeaders;
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (!!httpHeaderAcceptSelected) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.request<SearchResultDto[]>('get', `${this.basePath}/api/inquire-management/templates/search/last-updated?startDate=${startDate}&endDate=${endDate}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers
            }
        );
    }

    searchManagement(searchTerm: string): Observable<SearchResultDto[]> {
        let headers = this.defaultHeaders;
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (!!httpHeaderAcceptSelected) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.request<SearchResultDto[]>('get', `${this.basePath}/api/manager-management/templates/search?searchTerm=${searchTerm}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers
            }
        );
    }
}
