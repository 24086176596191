import { EventEmitter, Injectable } from '@angular/core';
import { EditorDocumentParts } from '../models/editor-document-parts';

@Injectable({
    providedIn: 'root'
})
export abstract class ICkeditorFormsUtilsService {

    abstract configureInputsAndSelectedEditables(editorInstance: any, sendDataChange: any,
                                                 changeContentEmmiter: EventEmitter<EditorDocumentParts>,
                                                 editorDocumentParts: EditorDocumentParts);
    abstract configureInputsTextAndSelectEditablesDocument(documentToConfigure: any, editorInstance: any, sendDataChange: any,
                                                           changeContentEmmiter: EventEmitter<EditorDocumentParts>,
                                                           editorDocumentPart: EditorDocumentParts);

    abstract configureInputsAndSelectedForDocumentInVisor(documentToConfigure: any);
    abstract configureInputsAndSelectedForDocument(documentToConfigure: any, editorInstance: any,
                                                   sendDataChange: any, changeContentEmmiter: EventEmitter<EditorDocumentParts>,
                                                   editorDocumentPart: EditorDocumentParts);
    abstract configureInputsAndSelectedAsReadOnly(editorInstance: any);
    abstract configureInteractiveFormsAsReadOnly(editorInstance: any);
    abstract configureInputsAndSelectedInClausesAsDisabled(editorInstance: any);
    abstract manageAnchorLinks(editorInstance: any);
    abstract removeReadOnlyInputsTextAndSelectEditablesDocument(contentHtml: string): string;
}
