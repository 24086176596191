import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface StepperComponentDeactivate {
    hasPendingChanges: () => boolean | Observable<boolean>;
}

@Injectable()
    export class StepperGuard  {

    public canDeactivate(component: StepperComponentDeactivate): boolean | Observable<boolean> {
        if (component == null) {
            return false;
        }

        return !component.hasPendingChanges();
    }
  }
