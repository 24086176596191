import { isValueEmptyValidator } from 'src/app/core/shared/validators/valueEmptyValidator';
import { ValidatorRegexConstant } from './validator-regex-constant';

const DNI_LETTERS = 'TRWAGMYFPDXBNJZSQVHLCKE';
const FIRST_CHAR = 0;
const POSITION_CONTROL_LETTER = 8;

export function DNIValidator(dni: string): boolean {
    return !isValueEmptyValidator(dni) &&
    ValidatorRegexConstant.CUSTOM_DNI_REGEX.test(dni) &&
    validateDNI(dni);
}

function control(numbers: string, letter: string, lettersControl): boolean {
    const dniNumber = parseInt(numbers);
    const remainder = dniNumber % lettersControl.length;
    const expectedLetter = lettersControl[remainder];

    return letter.toUpperCase() === expectedLetter || !isNaN(parseInt(letter));
}

function validateDNI(dniValue: string): boolean {
    const dniNumbers = dniValue.slice(FIRST_CHAR, POSITION_CONTROL_LETTER);
    const dniLetter = dniValue.charAt(POSITION_CONTROL_LETTER);

    return control(dniNumbers, dniLetter, DNI_LETTERS);
}
