import { TreeWalkerValue, Writer } from 'ckeditor5';
import { RadioBaseCommand } from './radio-base-command';

export default class DeleteRadioCommand extends RadioBaseCommand {

    public override execute(radioId: string, totalOptionsInRadioGroup: number): void{
        const editor = this.editor;

        editor.model.change((writer: Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);

            const radioModel: TreeWalkerValue | null = this.getElementWithId(range, radioId);

            this.movePosition(writer, totalOptionsInRadioGroup, radioModel!);

            writer.remove(radioModel!.item);
        });
    }

    private movePosition(writer: Writer, totalOptionsInRadioGroup: number, radioModel: TreeWalkerValue): void {
        for (let positionToMove = totalOptionsInRadioGroup - 1; positionToMove >= 0; positionToMove--) {
            this.moveOptionsOutsideRadio(radioModel, positionToMove, writer);
        }
    }
}
