import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'dateDayHourMinSec'
})
export class DateDayHourMinSecPipe implements PipeTransform {

    transform(value: Date, ...args: unknown[]): string {

        if (value === null) {
            return '';
        }
        let option: string;
        let result: string;
        const date = moment.utc(value);
        const diffDays = moment().diff(date, 'days');
        const diffHours = moment().diff(date, 'hours');
        const diffMinutes = moment().diff(date, 'minutes');
        const diffSeconds = moment().diff(date, 'seconds');

        if (diffDays < 1) {
            if (diffHours < 1) {
                if (diffMinutes < 1) {
                    option = 'seconds';
                } else {
                    option = 'minutes';
                }
            } else {
                option = 'hours';
            }
        } else {
            option = 'days';
        }

        switch (option) {
            case 'hours':
                if (diffHours === 1) {
                    result = 'Hace ' + diffHours + ' hora';
                } else {
                    result = 'Hace ' + diffHours + ' horas';
                }
                break;
            case 'minutes':
                if (diffMinutes === 1) {
                    result = 'Hace ' + diffMinutes + ' minuto';
                } else {
                    result = 'Hace ' + diffMinutes + ' minutos';
                }
                break;
            case 'seconds':
                if (diffSeconds === 1) {
                    result = 'Hace ' + diffSeconds + ' segundo';
                } else {
                    result = 'Hace ' + diffSeconds + ' segundos';
                }
                break;
            case 'days':
            default:
                result = date.local().format('DD/MM/YYYY');
        }

        return result;
    }
}
