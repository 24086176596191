import { Conversion, Editor, Element, UpcastDispatcher, ViewElement, Writer } from 'ckeditor5';
import { Injectable } from "@angular/core";
import { SelectPlugin } from '../../plugins/select/select-plugin';
import { SelectModel } from '../../models/select/select-model';
import { SelectDataViewUtilsService } from '../../utils/select/select-data-view-utils.service';
import { PluginUtilsService } from '../../../utils/plugin-utils.service';
import { GlobalConstant } from '../../models/base/global-constant';

@Injectable({
    providedIn: "root",
})
export class SelectDataViewToModelConverterService {

    private dataUtilsService: SelectDataViewUtilsService;
    private pluginUtilsService: PluginUtilsService;

    constructor() {
        this.dataUtilsService = new SelectDataViewUtilsService();
        this.pluginUtilsService = new PluginUtilsService();
    }

    public configureConverter(editor: Editor): void {
        const conversion = editor.conversion;

        this.convertSelectNode(conversion);
        this.convertOptionNode(conversion);
    }

    private convertSelectNode(conversion: Conversion): void {
        conversion.for("upcast").add((dispatcher: UpcastDispatcher) => {
            dispatcher.on('element:select', (_evt, data, conversionApi) => {
                const { consumable, writer, safeInsert, updateConversionResult} = conversionApi;

                const { viewItem } = data;
                const selectViewElement = { name: true, 'type': SelectPlugin.MODEL_ENTITIES.container.dataView };

                if (!consumable.test(viewItem, selectViewElement)) {
                    return;
                }

                const selectModel = this.getModel(viewItem);
                const containerModel = this.dataUtilsService.createSelectBasicStructureModelWithoutOptions(writer, selectModel);
                if (!safeInsert(containerModel, data.modelCursor)) {
                    return;
                }

                consumable.consume(viewItem, containerModel);
                this.convertAllOptions(viewItem, containerModel, writer, consumable);
                updateConversionResult(containerModel, data);
            });
        });
    }

    private convertAllOptions(viewItem: ViewElement, containerModel: Element, writer: Writer, consumable: any): void {
        const options = [...viewItem.getChildren()];
        const contentModelElement = containerModel.getChild(SelectPlugin.SELECT_POSITION) as Element;
        options.forEach((optionViewElement: ViewElement) => {
            this.convertEachOption(optionViewElement, writer, contentModelElement, consumable);
        });
    }

    private convertEachOption(optionViewElement: ViewElement, writer: Writer, contentModelElement: Element, consumable: any): void {
        const text = this.pluginUtilsService.getViewTextContent(optionViewElement);
        const isSelected = optionViewElement.getAttribute(SelectPlugin.ATTRIBUTE_SELECTED);
        const optionModelElement = writer.createElement(SelectPlugin.MODEL_ENTITIES.option.model, {
            value: text
        });

        if (!!isSelected) {
            writer.setAttribute(SelectPlugin.ATTRIBUTE_SELECTED, isSelected, optionModelElement);
        }

        writer.append(optionModelElement, contentModelElement);
        consumable.consume(optionViewElement, optionModelElement);
    }

    private convertOptionNode(conversion: Conversion): void {
        conversion.for('upcast').elementToElement({
            view: {
                name: SelectPlugin.MODEL_ENTITIES.option.dataView,
            },
            model: (viewElement: ViewElement, { writer }) => {
                const text = this.pluginUtilsService.getViewTextContent(viewElement);
                const isSelected = !!viewElement.getAttribute(SelectPlugin.ATTRIBUTE_SELECTED);
                const modelElement = writer.createElement(SelectPlugin.MODEL_ENTITIES.option.model, {
                    value: text,
                    'selected': isSelected
                });
                return modelElement;
            }
        });
    }

    private getModel(selectElement: ViewElement): SelectModel {
        const id = selectElement.getAttribute(SelectPlugin.ATTRIBUTE_ID);
        const embeddedIn = selectElement.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN);
        const selectOptions = Array.from(selectElement.getChildren());
        const options: string[] = [];

        selectOptions.forEach((option: ViewElement) => {
            const child = option;
            if (child.is('view:element')) {
                const value = (child as ViewElement).getAttribute('value');
                options.push(value);
            }
        });

        const selectModel: SelectModel = {
            id,
            options,
            embeddedIn
        };

        return selectModel;
    }
}
