import { Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';

@Component({
    selector: 'ctbox-button',
    templateUrl: './ctbox-button.component.html'
})
export class CtboxButtonComponent {

    @Input() public buttonType?: string;
    @Input() public buttonIcon?: string;
    @Input() public buttonIconSvg?: string;
    @Input() public buttonIconPositionRight: boolean;
    @Input() public buttonText?: string;
    @Input() public buttonColor = 'primary';
    @Input() public buttonTheme = 'default';
    @Input() public customClass?: string;
    @Input() public isDisabled = false;

    @Output() public buttonClick: EventEmitter<void>;

    @HostBinding('attr.theme')

    get getTheme(): string {
        return this.buttonTheme;
    }

    public isButtonIcon: boolean;

    public constructor() {
        this.buttonClick = new EventEmitter();
    }

    public get setMatTypeButton(): string {
        this.isButtonIcon = ((this.buttonIcon !== '') || (this.buttonIconSvg !== ''));
        if (this.isButtonIcon && !this.buttonText) {
            return 'mat-button-icon';
        }
        switch (this.buttonType) {
            case 'mat-menu-item':
                return 'mat-menu-item';
            case 'primary':
                return 'mat-flat-button';
            case 'secondary':
                return 'mat-stroked-button';
            case 'text':
                return 'mat-button';
            default:
                return 'mat-button';
        }
    }

    public clickButton($event: any): void {
        this.buttonClick.emit($event);
    }

    public getButtonClasses() {
        return {
          'mat-button-text-right': (this.buttonText && this.isButtonIcon && !this.buttonIconPositionRight),
          'mat-button-text-left': (this.buttonText && this.isButtonIcon && this.buttonIconPositionRight),
          'filled': this.customClass?.includes('filled')
        };
    }
}
