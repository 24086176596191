import { Injectable } from '@angular/core';
import { Conversion, UpcastConversionApi, ViewElement, Writer } from 'ckeditor5';
import { ClausesPlugin } from '../../plugins/clause/clauses-plugin';

@Injectable({
    providedIn: 'root'
})
export class ClauseDataViewToModelConverterService {

    public configureConverters(conversion: Conversion): void {
        conversion.for("upcast").elementToElement({
            view: {
                name: "div",
                classes: [ClausesPlugin.MODEL_ENTITIES.class.dataView],
            },
            model: (viewElement: ViewElement, conversionApi: UpcastConversionApi ) => {
                return this.createModelElement(viewElement, conversionApi.writer);
            },
        });
    }

    private createModelElement(viewElement: ViewElement, writer: Writer): any {
        const id = viewElement.getAttribute(ClausesPlugin.ID);
        const description = viewElement.getAttribute(ClausesPlugin.DESCRIPTION);
        return writer.createElement(ClausesPlugin.MODEL_ENTITIES.class.model, { 'id': id, 'description': description });
    }
}
