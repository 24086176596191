import { Component, Input, OnInit } from '@angular/core';
import { MenuItem } from './models/menu-item.model';

@Component({
    selector: 'ctbox-menu',
    templateUrl: './ctbox-menu.component.html'
})
export class CtboxMenuComponent implements OnInit {

    @Input() menuIcon: string;
    @Input() menuList: MenuItem[];
    @Input() isDisabled: boolean;
    @Input() hideIfEmpty = false;

    constructor() {
    }

    ngOnInit(): void {
    }

    clickItem(item: MenuItem) {
        item.action();
    }

    trackList = (index) => {
        return index;
    }
}
