import { Routes } from '@angular/router';

export const routes: Routes = [
    {
        path: '',
        loadChildren: () => import('src/app/pages/cmc/cmc-zone-entrypoint.module').then(m => m.CmcZoneEntrypointModule)
    },
    {
        path: 'alerts',
        redirectTo: 'public/alerts'
    }
];
