import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export abstract class IHtmlComparerService {
    abstract setCurrentDocumentComparation(document: string): void;
    abstract getCurrentDocumentComparation(): string;
    abstract calculateCurrentChanges(): void;
    abstract getCurrentDocumentClean(): string;
    abstract getCurrentDocumentWithChangesMarked(): string;
    abstract getCurrentDocumentWithChangesMarkedBehaviorSubject(): BehaviorSubject<Document>;
    abstract getCurrentDocumentCleanBehaviorSubject(): BehaviorSubject<string>;
    abstract setCleanDocumentAsComparerDocument(): void;
}
