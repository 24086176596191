/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ErrorTemplateFolderOperationDto { 
    readonly accessNotAllowed?: string;
    readonly nameUsedAlreadyMessage?: string;
    readonly movementNotAllowedMessage?: string;
    readonly folderLocked?: string;
    readonly folderOutdated?: string;
    readonly notFound?: string;
    readonly genericErrorMessage?: string;
}