import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NotificationState } from './enums/notification-state.enum';

@Component({
    selector: 'ctbox-notification',
    templateUrl: './ctbox-notification.component.html',
    styleUrls: ['./ctbox-notification.component.scss'],
})
export class CtboxNotificationComponent {

    @Input() title: string;
    @Input() content: any;
    @Input() url: any;
    @Input() routerLink: any;
    @Input() state = NotificationState.Info;
    @Input() additionalClass: string;
    @Input() label: string;

    @Output() goToLinkEvent = new EventEmitter<boolean>(false);

    public get iconClass(): string {
        switch (this.state) {
            case NotificationState.Info:
                return 'info';
            case NotificationState.Error:
                return 'report_problem';
            case NotificationState.Forbid:
                return 'block';
            case NotificationState.Success:
                return 'check_circle';
            case NotificationState.Warning:
                return 'warning';
            case NotificationState.Alert:
                return 'history';
            case NotificationState.Lock:
                return 'lock';
            case NotificationState.Visibility:
                return 'visibility';
            case NotificationState.VisibilityOff:
                return 'visibility_off';
            case NotificationState.Update:
                return 'cycle';
            default:
                return 'info';
        }
    }

    public goToLink(): void {
        this.goToLinkEvent.emit();
    }

}
