import { ChangeDetectionStrategy, Component } from '@angular/core';
import { FileStateComponentDirective } from '../../file-state.component';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { IFileStateService } from 'src/app/core/shared/services/templates/file-state/file-state.interface';
import { AuthorizeService } from 'src/app/core/shared/services/authorize/authorize.service';
import { FileStatusCodes } from 'src/app/core/cmc/enums/file-status.enum';

@Component({
  selector: 'app-template-file-state',
  templateUrl: './template-file-state.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TemplateFileStateComponent extends FileStateComponentDirective {

    public titleForDocument = $localize`:@@Plantilla:Plantilla`;

    constructor(
        protected genericDialogService: GenericDialogService,
        protected fileStateService: IFileStateService,
        protected readonly loginService: AuthorizeService
    ) {
        super(genericDialogService, fileStateService, loginService);
    }

    protected getTextButton(fileState: FileStatusCodes) {
        return this.fileStateService.getFileStatusMessage(fileState);
    }
}
