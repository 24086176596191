import { Directive, Inject } from "@angular/core";
import { ButtonView, Locale } from "ckeditor5";
import { BaseInputModel } from "../../models/input/base-input-model";
import { UI_CLASSES } from "../styles/styles-constants";
import BaseView from "../base-view.directive";

@Directive({
    selector: "app-input-balloon-view",
})
export default class InputBalloonView extends BaseView {

    public editButtonView: ButtonView;

    public inputModelToEdit: BaseInputModel = {
        id: ''
    };

    private DELETE_OPTION =  $localize`:@@PluginInputBalloonBotonBorrar:BORRAR`;
    private EDIT_OPTION =  $localize`:@@PluginInputBalloonBotonEditar:EDITAR`;

    constructor( @Inject(Locale) locale?: Locale) {
        super(locale);

        this.editButtonView = this.createButton(this.EDIT_OPTION, null, UI_CLASSES.SVG_ICONS.EDIT);
        this.deleteButtonView = this.createButton(this.DELETE_OPTION, null, UI_CLASSES.SVG_ICONS.DELETE);

        this.items = this.createCollection([ this.editButtonView,  this.deleteButtonView ]);

        this.setTemplateForm("ck-input-balloon");
    }

    public ngOnDestroy(): void {
        super.destroy();

        this.focusTracker.destroy();
        this.keystrokes.destroy();
    }

    public get id(): string {
        return this.inputModelToEdit.id!;
    }

    public set inputModel(value: BaseInputModel) {
         this.inputModelToEdit = value;
    }

    public get alias(): string {
        return this.inputModelToEdit?.alias ? this.inputModelToEdit.alias : '';
    }

    public resetFormStatus(): void {
        this.inputModelToEdit = {
            id: ''
        }
	}
}

export type InputBalloonValidatorCallback = (form: InputBalloonView) => string | undefined;
