import { Injectable } from '@angular/core';
import { TemplateFolderDTO } from 'src/app/api';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { NodeTreeNodeType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-node-type.enum';
import { ITemplatesFolderTreeService } from './templates-folder-tree.service.interface';
import { HierarchySearchResult } from '../models/hierarchy-search-result.model';
import { BaseTemplateTreeService } from '../base-template-folder-tree.service';

@Injectable({
    providedIn: 'root'
})
export class TemplatesFolderTreeService extends BaseTemplateTreeService implements ITemplatesFolderTreeService {

    public createTreeFolder(folderDto: TemplateFolderDTO[], rootActions: NodeTreeActionType[],
                            nodeActions: NodeTreeActionType[]): FileNode[] {

        const rootNode: FileNode = this.createRootNode(rootActions);
        const tree: FileNode[] = [rootNode];

        folderDto.forEach(folderDTO => {
            const childNode = this.transformTemplateFolderToNode(folderDTO, nodeActions);
            rootNode.children.push(childNode);
        });

        rootNode.children = this.pipe.transform(rootNode.children);
        return tree;
    }

    public createTreeFolderSearchResult(folderDto: HierarchySearchResult[],
                                        nodeActions: NodeTreeActionType[],
                                        defaultCheckedNodes?: string[]): FileNode[] {
        const tree: FileNode[] = [];

        folderDto.forEach(folderDTO => {
            const folderSearch = this.transformTemplateFolderSearchResultToNode(folderDTO, nodeActions, defaultCheckedNodes);
            tree.push(folderSearch);
        });

        return this.pipe.transform(tree);
    }

    private transformTemplateFolderToNode(folder: TemplateFolderDTO, permissions: NodeTreeActionType[]): FileNode {
        const node: FileNode = {
            id: folder.id,
            value: folder.name,
            type: NodeTreeNodeType.Folder,
            children: [],
            parentId: folder.templateFolderParentId,
            actions: permissions,
            isChecked: true
        };

        folder.childrenFolder.forEach(childFolder => {
            const child = this.transformTemplateFolderToNode(childFolder, permissions);
            node.children.push(child);
        });
        node.children = this.pipe.transform(node.children);
        return node;
    }

    private transformTemplateFolderSearchResultToNode(
        folder: HierarchySearchResult,
        permissions: NodeTreeActionType[],
        defaultCheckedNodes?: string[]): FileNode {
        const node: FileNode = {
            id: folder.id,
            value: folder.name,
            type: NodeTreeNodeType.Folder,
            children: [],
            parentId: folder.parentId,
            actions: permissions,
            isChecked: (defaultCheckedNodes?.includes(folder.id)) ?? false,
            additionalData: folder.resultCount
        };

        folder.childrenFolder.forEach(childFolder => {
            const child = this.transformTemplateFolderSearchResultToNode(childFolder, permissions, defaultCheckedNodes);
            node.children.push(child);
        });
        node.children = this.pipe.transform(node.children);
        return node;
    }
}
