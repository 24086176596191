import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentDTO, DocumentSharedDTO, ExpirationDto, MasterData, UserInfoDTO } from 'src/app/api';

@Injectable({
    providedIn: 'root'
})
export abstract class IDocumentEditionService {

    abstract setCurrentDocument(document: DocumentDTO): void;
    abstract getCurrentDocument(): DocumentDTO;
    abstract getDocumentSubscription(): Observable<DocumentDTO>;
    abstract setDefaultDocument(): void;

    abstract getDocumentIdSubscription(): Observable<string>;
    abstract setDocumentId(id: string): void;

    abstract getDocumentHasChangedSubscription(): Observable<string>;
    abstract setDocumentHasChanged(documentId: string): void;

    abstract setOperationApiInProcess(inProcess: boolean);
    abstract getOperationApiInProcess(): Observable<boolean>;

    abstract setShowAddSignatureAnimation(showAddSignatureAnimation: boolean);
    abstract getShowAddSignatureAnimation(): Observable<boolean>;

    abstract setExpirations(expirations: Array<ExpirationDto>);
    abstract getExpirations(): Observable<Array<ExpirationDto>>;

    abstract getIdForName(id: string): string;
    abstract getIdForEmail(id: string): string;
    abstract getIdForDocumentType(id: string): string;
    abstract getIdForDocument(id: string): string;

    abstract getCurrentRevisorName(contract: DocumentDTO): string;

    abstract getDocumentSharedForUser(userInfo: UserInfoDTO): DocumentSharedDTO;
    abstract getDocumentSharedPermissionForUser(userInfo: UserInfoDTO): MasterData[];

    abstract generateFile(content: string, extension: string, name: string): File;
    abstract canSeeFinishFieldEdition(document: DocumentDTO): boolean;
    abstract canSeeFinishRevision(document: DocumentDTO): boolean;
    abstract canApproveRejectDocument(document: DocumentDTO): boolean;

    abstract getWorkflowErrorMessage(): string;
}
