/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ErrorDocumentFolderOperationDto { 
    readonly accessNotAllowedMessage?: string;
    readonly movementNotAllowedMessage?: string;
    readonly nameAlreadyUsedMessage?: string;
    readonly movementNotAllowedDifferentPermissionSourceAndDestinyMessage?: string;
    readonly movementFromDeniedMessage?: string;
    readonly movementToDeniedMessage?: string;
    readonly renameDeniedMessage?: string;
    readonly creationChildDeniedMessage?: string;
    readonly deleteFolderDeniedMessage?: string;
    readonly deleteFolderDeniedOtherUsersHaveDocumentsMessage?: string;
    readonly folderEditionIsCurrentlyLockedMessage?: string;
    readonly forbiddenMessage?: string;
    readonly treeOutdatedMessage?: string;
    readonly movementNotAllowedFromOurDocumentsToMyDocumentsMessage?: string;
    readonly genericErrorMessage?: string;
}