import { Component, Input } from '@angular/core';
import { PublicLinksService } from 'src/app/core/shared/services/public-links/public-links.service';

@Component({
  selector: 'ctbox-footer-landing',
  templateUrl: './ctbox-footer-landing.component.html',
  styleUrls: ['./ctbox-footer-landing.component.scss']
})
export class CtboxFooterLandingComponent {

    @Input() currentScreenSize: string;

    constructor(public publicLinks: PublicLinksService) {}

    public goToLink(link: any): void {
        this.publicLinks.goToLink(link);
    }

}
