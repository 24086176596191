<div class="c-help">
    <div class="c-help-header">
        <h3 class="c-help-header-title c-help-templates"> Plantillas</h3>
        <p class="c-help-header-text">Diseña y trabaja con tu propio repositorio de modelos de escritos y documentos legales que más utilizas en tu día a día, accesibles para toda tu organización y tus clientes y aprovecha todo el valor que te ofrecen las más de 10.000 plantillas de laleydigital que podrás adaptar al caso concreto y a tu propio estilo construyendo tus propias plantillas.</p>
    </div>
    <div class="c-help-content">
        <mat-tab-group mat-align-tabs="start" class="c-help templates">
            <mat-tab>
                <ng-template mat-tab-label>Mis plantillas</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <p class="c-help-summary">En este espacio podrás ver las plantillas creadas por ti <mat-icon class="warning">person</mat-icon> y las creadas por usuarios de tu corporación <mat-icon class="info">apartment</mat-icon> </p>
                        <h4 class="c-help-subtitle">Acciones</h4>
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="edit" buttonColor="info"></ctbox-button></div>
                                <p><strong>Editar:</strong> Para editar el contenido de una plantilla.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="delete" buttonColor="delete"></ctbox-button></div>
                                <p><strong>Borrar:</strong> Para eliminar plantillas.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="download" buttonColor="info"></ctbox-button></div>
                                <p><strong>Descargar:</strong> Para guardar una copia en tu equipo de una plantilla.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <h4 class="c-help-subtitle">Organización de carpetas</h4>
                        <ul class="c-help-list">
                            <li><strong>Plantillas de mi corporación:</strong> Este es el Espacio de tu corporación, donde podrás encontrar las plantillas creadas por ti y por tus compañeros de trabajo. A la hora de trabajar con las carpetas podrás hacer las siguientes tareas:</li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><app-tree-contextual-help module="templates"></app-tree-contextual-help></div>
                                <ul class="c-help-list">
                                    <li>Distinguirás fácilmente las plantillas creadas por ti de las creadas por otros compañeros de tu corporación:</li>
                                    <li class="c-help-list-flexrow"><mat-icon class="warning">person</mat-icon> Plantillas elaboradas por ti.</li>
                                    <li class="c-help-list-flexrow"><mat-icon class="info">apartment</mat-icon> Plantillas elaboradas por tu corporación.s</li>
                                </ul>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="more_vert" buttonColor="secondary"></ctbox-button>
                                    <div class="ctbox-elevation">
                                        <ctbox-button buttonType="mat-menu-item" buttonColor="secondary" buttonText="Nueva Carpeta"></ctbox-button>
                                        <ctbox-button buttonType="mat-menu-item" buttonColor="secondary" buttonText="Renombrar Carpeta"></ctbox-button>
                                        <ctbox-button buttonType="mat-menu-item" buttonColor="secondary" buttonText="Borrar Carpeta"></ctbox-button>
                                    </div>
                                </div>
                                <ul class="c-help-list">
                                    <li><strong>Crear una nueva carpeta:</strong> Permite crear una carpeta dentro de la carpeta seleccionada.</li>
                                    <li><strong>Renombrar una carpeta ya existente:</strong> Permite renombrar la carpeta seleccionada.</li>
                                    <li><strong>Borrar una carpeta ya existente: </strong> Eliminar permanentemente esta carpeta y todas las que contiene.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Nueva plantilla</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <p class="c-help-summary"> Trabajar con plantillas es una forma ágil e intuitiva de redactar un documento legal. En ContractBOX puedes crear tus propias plantillas, que te ayudarán a redactar tus documentos legales y te permitirán aprovechar todas las ventajas del módulo de alertas ContractBOX al día. </p>
                    </div>
                    <div class="c-help-block">
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <div class="ctbox-cards-group">
                                        <div class="ctbox-card"><a class="ctbox-card-link from-tdc templates help"></a></div>
                                    </div>
                                </div>
                                <p><strong>Partir de una plantilla:</strong> Utiliza una plantilla de laleydigital, siempre actualizadas.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <div class="ctbox-cards-group">
                                        <div class="ctbox-card"><a class="ctbox-card-link from-file templates help"></a></div>
                                    </div>
                                </div>
                                <p><strong>Subir un documento:</strong> Sube tu propia plantilla.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <div class="ctbox-cards-group">
                                        <div class="ctbox-card"><a class="ctbox-card-link from-new templates help"></a></div>
                                    </div>
                                </div>
                                <p><strong>Redactar una plantilla:</strong> Empezarás con un documento en blanco.</p>
                            </li>
                        </ul>
                    </div>
                    <div class="c-help-block">
                        <p class="c-help-list-flexrow"><mat-icon class="info">info</mat-icon> Ten en cuenta que al crear una plantilla y guardarla en Mis Plantillas la compartirás con el resto de usuarios de tu corporación.</p>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>