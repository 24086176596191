import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DocumentDTO, UserInfoDTO } from 'src/app/api';
import { DocumentFolderDtoOperation } from 'src/app/api/model/documentFolderDtoOperation';
import { FileFlatNode } from 'src/app/shared/components/ctbox-tree/models/file-flat-node.model';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';

@Injectable({
    providedIn: 'root'
  })
export abstract class IDocumentsFolderService {

    abstract setCurrentFolderName(folderName: string): void;
    abstract getCurrentFolderName(): Observable<string>;

    abstract setCurrentFolderAndOwnerId(folderId: string, ownerId: string): void;

    abstract getCurrentFolderId(): string;

    abstract getCurrentOwnerId(): string;

    abstract getOperationReplaceDocumentsFolder(valueToChange: string, newValue: any): DocumentFolderDtoOperation[];

    abstract loadFolderTree( myDocumentsRootNodeActions: NodeTreeActionType[], ourDocumentsRootNodeActions: NodeTreeActionType[],
                             sharedRootNodeActions: NodeTreeActionType[], nodeActions: NodeTreeActionType[],
                             userInfoGuid: string ): Observable<FileNode[]>;

    abstract isCurrentFolderValid(node: FileFlatNode): boolean;

    abstract getCorrectedFolderName(originalFolderName: string, hasFolderAccess: boolean, isCommon: boolean): string;

    abstract hasDocumenttAccessToContractFolder(document: DocumentDTO, userInfo: UserInfoDTO): boolean;
}
