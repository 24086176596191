import { Injectable } from '@angular/core';
import { ApiProcessorService } from '../api-processor.service';
import { BaseLegalAlertsService } from '../base/base-legal-alerts.service';

@Injectable()
export class LegalAlertsCMCService extends BaseLegalAlertsService {

    protected override basePath = '/api/manager-management/alerts';

    constructor(
        protected readonly apiProcessorService: ApiProcessorService
    ) { 
        super(apiProcessorService);
    }
}
