import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { SearchResultDto } from 'src/app/api';
import { SearchResultType } from '../../models/search-result-type';

@Injectable({
  providedIn: 'root'
})
export class SearchResultFileStorageService {
    private searchTerms: { [key: string]: BehaviorSubject<string> } = {};
    private checkedNodes: { [key: string]: BehaviorSubject<Array<string>> } = {};
    private searchResults: { [key: string]: BehaviorSubject<Array<SearchResultDto>> } = {};

    public setSearchTerm(searchTerm: string, searchResultType: SearchResultType): void {
       this.searchTerms[searchResultType] = new BehaviorSubject<string>(searchTerm);
    }

    public getSearchTerm(searchResultType: SearchResultType): string {
       return this.searchTerms.hasOwnProperty(searchResultType) ? this.searchTerms[searchResultType].getValue() : '';
    }

    public setCheckedNodes(checkedNodes: Array<string>, searchResultType: SearchResultType): void {
        this.checkedNodes[searchResultType] = new BehaviorSubject<Array<string>>(checkedNodes);
    }

    public getCheckedNodes(searchResultType: SearchResultType): Array<string> {
        return this.checkedNodes.hasOwnProperty(searchResultType) ? this.checkedNodes[searchResultType].getValue() : [];
    }

    public setSearchResult(searchResult: SearchResultDto[], searchResultType: SearchResultType): void {
        this.searchResults[searchResultType] = new BehaviorSubject<Array<SearchResultDto>>(searchResult);
    }

    public getSearchResult(searchResultType: SearchResultType): SearchResultDto[] {
       return this.searchResults.hasOwnProperty(searchResultType) ? this.searchResults[searchResultType].getValue(): [];
    }

    public resetSearchResultValues(searchResultType: SearchResultType): void {
        this.searchTerms[searchResultType] = new BehaviorSubject<string>('');
        this.checkedNodes[searchResultType] = new BehaviorSubject<string[]>([]);
        this.searchResults[searchResultType] = new BehaviorSubject<Array<SearchResultDto>>([]);
    }
}
