import { MasterData } from 'src/app/api/model/masterData';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export abstract class IDocumentStateService {
    abstract getCurrentStates(): Observable<MasterData[]>;
}
