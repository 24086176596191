<ng-container *ngIf="size==('XSmall') || size==('Small'); then thenBlock else tabs"></ng-container>
<ng-template #thenBlock>
    <ctbox-button buttonIcon="menu" customClass="XSmall" [matMenuTriggerFor]="tabsMenu"></ctbox-button>
    <mat-menu #tabsMenu="matMenu" [class]="classTabs" [overlapTrigger]="false">
        <ng-container *ngTemplateOutlet="tabs"></ng-container>
    </mat-menu>
</ng-template>
<ng-template #tabs>
    <nav fitInkBarToContent mat-tab-nav-bar [color]="classTabs" [class]="classTabs" [ngClass]="size" [disableRipple]="true" [disablePagination]="true">
        <ng-container *ngFor="let link of navLinks">
            <div mat-tab-link routerLinkActive #rla="routerLinkActive" [routerLink]="link.path" [active]="isLinkActive(rla, link)" [class]="link.class" (click)="goLinkTab(link)" [disableRipple]="true" [matTooltip]="link.tooltip" [ngClass]="{'toptext_sidebar' : IsExpandTitleContainer }">
                {{link.label}}
                <div *ngIf="IsExpandTitleContainer else notExpanded">de <span class="quotation_icon"><<</span>{{link.itemName}}<span class="quotation_icon">>></span></div>
                <ng-template #notExpanded>
                    {{link.itemName}}
                </ng-template>
                <div *ngIf=" link.badgeValue || link.badgeValue === 0" [matBadge]="link.badgeValue" [matBadgeColor]="link.class" matBadgeSize="large" class="ng-animate-disabled"></div>
            </div>
        </ng-container>
    </nav>
</ng-template>