import { Editor } from 'ckeditor5';
import { PluginUtilsService } from '../../../utils/plugin-utils.service';
import { SelectModel } from '../../models/select/select-model';
import { SelectPlugin } from '../../plugins/select/select-plugin';
import { SelectDataViewUtilsService } from '../../utils/select/select-data-view-utils.service';
import { BaseCommand } from '../base-command';

export abstract class BaseSelectCommand extends BaseCommand {
    labelDataModel = SelectPlugin.MODEL_ENTITIES.container.model;

    protected dataUtilsService: SelectDataViewUtilsService;
    protected utilsService: PluginUtilsService;

    constructor(editor: Editor) {
        super(editor);
        this.dataUtilsService = new SelectDataViewUtilsService();
        this.utilsService = new PluginUtilsService();
    }

    protected enableCommandIfCursorInsideInputContent(): void {
        const model = this.editor.model;
        const selection = model.document.selection;
        const position: any = selection.getFirstPosition();
        const element = position.parent;

        if (!element || !element.is('element')) {
            this.isEnabled = false;
            return;
        }

        const allowedIn = position.parent?.name === SelectPlugin.MODEL_ENTITIES.content.model;
        this.isEnabled = allowedIn;
    }

    protected setModelId(model: SelectModel): void {
        if (!!model.id) {
            const guid = this.utilsService.generateUUID();
            const id = `${SelectPlugin.ID_INPUT_PREFFIX}${guid}`;
            model.id = id;
        }
    }

}
