<form [formGroup]="selectFolderForm" class="select-folder-stepper">
    <div class="select-folder-stepper-tree">
        <div class="clause-folder-search select-folder-stepper-tree-search">
            <app-search-box [notMatchingElements]="noMatchingElementsInSearch" [searchEmptyLikeClearSearch]="true" [showSearchNavigator]="showSearchNavigator" [indexResultOrdered]="indexResultOrdered" [totalResultOrdered]="totalResultOrdered" (doSearch)="searchInTree($event)"
                (clearSearch)="clearSearchInTree()" (searchPreviousResult)="searchPreviousResult()" (searchNextResult)="searchNextResult()"></app-search-box>
        </div>
        <ctbox-tree *ngIf="fileNodes" [dataTree]="fileNodes" [expansionModel]="treeSelectionModel" [actions]="actions" [initialNodes]="initialNodes" [currentNodeId]="selectedFolderId" [forceSynchoniceExpansionModel]="forceSynchoniceExpansionModel" (onNodeAction)="doActionFromNodeEvent($event)"></ctbox-tree>
    </div>
    <div class="select-folder-stepper-info">
        <div class="select-folder-stepper-error">
            <ng-container *ngIf="invalidFolder()">
                <div class="select-folder-stepper-error-text">
                    <ng-container i18n="@@ValidacionMensajeIgualNombre">Hay ficheros con el mismo nombre en esta carpeta: </ng-container>
                </div>
                <div class="select-folder-stepper-error-list" *ngFor="let fileWithSameName of this.filesWithSameName">
                    <div class="uploaded-file-result-stepper-item warning">
                        <div class="uploaded-file-result-stepper-item-container">
                            <div class="uploaded-file-result-stepper-item-info">
                                <div class="uploaded-file-result-stepper-item-name">{{fileWithSameName.name}}</div>
                                <div class="uploaded-file-result-stepper-item-size">{{fileWithSameName.size | fileSizeExtensionPipe}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
        </div>
    </div>
</form>