import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { APP_BASE_HREF } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxPageScrollCoreModule } from 'ngx-page-scroll-core';
import { TimeagoModule } from 'ngx-timeago';
import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { environment } from 'src/environments/environment';
import { ApiModule, BASE_PATH, Configuration } from './api';
import { AppComponent } from './app.component';
import { SharedCoreModule } from './core/shared/shared.core.module';

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        NgxPageScrollCoreModule.forRoot({ duration: 1000 }),
        ApiModule,
        BrowserAnimationsModule,
        TimeagoModule.forRoot(),
        NgxPermissionsModule.forRoot(),
        SharedModule,
        SharedCoreModule
    ],
    providers: [
        { provide: APP_BASE_HREF, useValue: '/es/' },
        { provide: BASE_PATH, useFactory: getBaseUrl },
        { provide: Configuration, useFactory: getConfiguration },
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function getBaseUrl(): string {
    return environment.apiBaseURL;
}

export function getConfiguration(): Configuration {
    return new Configuration({ withCredentials: true });
}

declare global {
    interface Navigator {
        msSaveBlob?: (blob: any, defaultName?: string) => boolean;
    }
}
