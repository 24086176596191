export class EditorPluginConfig {
    public contextEditor: string;
    public pluginsConfig: Array<PluginConfig>;
}

export class PluginConfig {
    public pluginName: string;
    public toolbarTooltip: string;
    public isEnabled = false;
    public lockId: string;
}
