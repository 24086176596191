/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DocumentStatus } from './documentStatus';
import { SignatureUserDTO } from './signatureUserDTO';

export interface DocumentSignatureDTO { 
    id?: string;
    vidSignerDocumentId?: string;
    documentId?: string;
    name?: string;
    docStatus?: DocumentStatus;
    downloaded?: boolean;
    isSequential?: boolean;
    expiredDate?: Date;
    reminderDate?: Date;
    messageBody?: string;
    messageSubject?: string;
    signaturesUsers?: Array<SignatureUserDTO>;
    signedDocument?: string;
    reportDocument?: string;
    signedDate?: Date;
    forSignaturePreviously?: boolean;
    sendToSignByUserId?: string;
}