import { Injectable } from '@angular/core';
import { Editor, Model, Position, Range, ViewContainerElement, ViewDocumentFragment, ViewElement, ViewNode, Writer, Node, Text, ViewText, ViewDocumentSelection, TreeWalkerValue, Element as CkElement } from 'ckeditor5';
import type { PositionOptions } from 'ckeditor5/src/utils.js';
import { GlobalConstant } from '../custom-plugins/models/base/global-constant';

@Injectable({
    providedIn: 'root'
})
export class PluginUtilsService {

    public getSelectedElementWithClass(editor: Editor, classNameToIdentify: string): ViewContainerElement | null {
        const selection = editor.editing.view.document.selection;
        const selectedElement = this.getSelectedElement(selection);
        return !!selectedElement
            ? this.findElementAncestorWithClass((selectedElement as ViewNode), classNameToIdentify)
            : null;
    }

    public getSelectedContainerWithClass(editor: Editor, classNameToIdentify: string): ViewContainerElement | null {
        const selection = editor.editing.view.document.selection;
        const selectedElement = this.getSelectedElement(selection);
        return !!selectedElement
            ? this.findElementAncestorContainerWithClass((selectedElement as ViewNode), classNameToIdentify, GlobalConstant.CONTAINER_CLASS_EDITION_VIEW)
            : null;
    }

    public getElementView(editor: Editor, id: string): ViewElement | undefined {
        const viewDocument = editor.editing.view.document;
        let foundElement: ViewElement | undefined;

        editor.editing.view.change(writer => {
            const range = writer.createRangeIn(viewDocument.getRoot()!);

            for (const value of range.getWalker()) {
                if (value.item.is('element') && value.item.hasAttribute('id') && value.item.getAttribute('id') === id) {
                    foundElement = value.item as ViewElement;
                    break;
                }
            }
        });

        return foundElement;
    }

    public getElementModelWithId(editor: Editor, id: string): TreeWalkerValue | null  {
        const modelView = editor.model;
        let model: TreeWalkerValue | null = null;
        modelView.change((writer: Writer) => {
            model = this.getWalkerByWithId(editor, writer, id);
        });
        return model;
    }

    public getWalkerByWithId(editor: Editor, writer: Writer, id: string, ): TreeWalkerValue | null {
        const range = writer.createRangeIn(editor.editing.model.document.getRoot()!);
        for (const value of range.getWalker()) {
            if ( value.item.hasAttribute( GlobalConstant.ATTRIBUTE_ID ) && value.item.getAttribute( GlobalConstant.ATTRIBUTE_ID) === id ) {
               return value;
            }
        }
        return null;
    }

    public findElementAncestorWithClass(position: ViewElement | ViewNode | null, classNameToIdentify: string): ViewContainerElement | null {
        return position?.getAncestors({ includeSelf: true }).reverse().find((ancestor): ancestor is ViewContainerElement => this.isElementWithClass(ancestor, classNameToIdentify)) || null;
    }

    public showFakeVisualSelection(editor: Editor, markerName: string): void {
        const model = editor.model;

        model.change((writer: Writer) => {
            const range = model.document.selection.getFirstRange()!;

            model.markers.has(markerName) ?
                writer.updateMarker(markerName, { range }) :
                this.createMarker(writer, model, range, markerName);

        });
    }

    public hideFakeVisualSelection(editor: Editor, markerName: string): void {
        const model = editor.model;

        if (model.markers.has(markerName)) {
            model.change((writer: Writer) => {
                writer.removeMarker(markerName);
            });
        }
    }

    public getBalloonPositionData(editor: Editor, classNameInElementToPosition: string, markerName: string): Partial<PositionOptions> {
        const target: PositionOptions['target'] = this.getTarget(editor, markerName, classNameInElementToPosition);
        return { target };
    }

    public getTarget(editor: Editor, markerName: string, classNameInElementToPosition: string) {
        const view = editor.editing.view;
        const model = editor.model;
        const viewDocument = view.document;
        let target: PositionOptions['target'];

        if (model.markers?.has(markerName) && !!editor.editing.mapper.markerNameToElements(markerName)) {
            const markerViewElements = Array.from(editor.editing.mapper.markerNameToElements(markerName)!);
            const newRange = view.createRange(
                view.createPositionBefore(markerViewElements[0]),
                view.createPositionAfter(markerViewElements[markerViewElements.length - 1])
            );

            target = view.domConverter.viewRangeToDom(newRange);
        } else {
            target = () => {
                const targetElement = this.getSelectedElementWithClass(editor, classNameInElementToPosition);
                const targeto = targetElement ?
                    view.domConverter.mapViewToDom(targetElement)! :
                    view.domConverter.viewRangeToDom(viewDocument.selection.getFirstRange()!);
                return targeto;
            };
        }
        return target;
    }

    public generateUUID(): string {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0;
            const v = c === 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    public generateId(prefix: string): string {
        return `${prefix}${this.generateUUID()}`;
    }

    public getTextContent(child: Node): string {
        return child?.is('$text') ? (child as Text).data : '';
    }

    public isViewElementInteractableInTargetEditor(element: ViewContainerElement, currentEditorContext: string, targetEditorContext: string): boolean {
        return !(element.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN) === targetEditorContext && currentEditorContext !== targetEditorContext);
    }

    public isElementInteractableInTargetEditor(elementProperty: string, currentEditorContext: string, targetEditorContext: string): boolean {
        return !(elementProperty === targetEditorContext && currentEditorContext !== targetEditorContext);
    }

    public getViewTextContent(viewElement: ViewElement) {
        const firstChild = viewElement.getChild(0);
        const text = firstChild?.is('view:$text') ? (firstChild as ViewText).data : '';
        return text;
    }

    private createMarker(writer: Writer, model: Model, range: Range, markerName: string) {
        const startPosition = this.getStartPosition(range, model);

        writer.addMarker(markerName, {
            usingOperation: false,
            affectsData: false,
            range: writer.createRange(startPosition, range.end)
        });
    }

    private getStartPosition(range: Range, model: Model): Position {
        return  range.start.isAtEnd ?
            range.start.getLastMatchingPosition(({ item }) => !model.schema.isContent(item), { boundaries: range }) :
            range.start;
    }

    private isElementWithClass(node: ViewNode | ViewDocumentFragment, classNameToIdentify: string): boolean {
        return node.is('containerElement') && !!node.hasClass(classNameToIdentify);
    }

    private findElementAncestorContainerWithClass(position: ViewElement | ViewNode | null, classNameToIdentify: string, generalContainer: string): ViewContainerElement | null {
        const firstContainerFound = position?.getAncestors({ includeSelf: true }).reverse().find((ancestor): ancestor is ViewContainerElement => this.isElementWithClass(ancestor, generalContainer)) || null;
        if (!firstContainerFound?.hasClass(classNameToIdentify)) {
            return null;
        }
        return firstContainerFound;
    }

    private getSelectedElement(selection: ViewDocumentSelection): ViewNode | ViewDocumentFragment | null {
        const selectedElement = selection.getSelectedElement() || selection.getFirstPosition()?.parent;
        if (!selectedElement || selectedElement?.is('documentFragment')) {
            return null;
        }
        return selectedElement;
    }
}
