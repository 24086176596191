import { MasterData, TemplateDTO, ClauseDTO } from 'src/app/api';
import { FileStatusApplicationDto } from 'src/app/api/model/fileStatusApplicationDto';
import { FileUpdateInformationDto } from 'src/app/api/model/fileUpdateInformationDto';
import { FileStatusCodes } from 'src/app/core/cmc/enums/file-status.enum';

export abstract class IFileStateService {

    abstract getFileStatusCodeInTemplate(template: TemplateDTO): FileStatusCodes;
    abstract getFileStatusCodeInClause(clause: ClauseDTO): FileStatusCodes;
    abstract mapToFileStatusCodes(fileState: MasterData, fileStatusApplication: FileStatusApplicationDto,
                                  updateFileAction?: MasterData): FileStatusCodes;
    abstract getFileStatusMessage(fileStatus: FileStatusCodes): string;
    abstract getClauseStatusMessage(fileStatus: FileStatusCodes): string;
    abstract getFileStatusWithUpdateMessage(fileStatus: FileStatusCodes): string;
    abstract getClauseStatusWithUpdateMessage(fileStatus: FileStatusCodes): string;
    abstract getUploadActionsCodeByPreference(actions: FileUpdateInformationDto[]): FileUpdateInformationDto;
}
