<form [formGroup]="exportResultForm" class="export-document">
    <div class="form-group-block">
        <div id="exportFormat" class="form-group-flex fcol coment">
            <h3 class="text_label" i18n="@@ExportFormat">Formato</h3>
            <mat-radio-group class="form-group-flex fcol" (change)="typeChange($event)" formControlName="types" required>
                <mat-radio-button class="mat-radio-indent view on" value={{exportFileExtension.Docx}} [checked]="true">
                    <ng-container class="mat-label" i18n="@@WordFormat">WORD</ng-container>
                </mat-radio-button>
                <mat-radio-button class="mat-radio-indent view on" value={{exportFileExtension.Pdf}}>
                    <ng-container class="mat-label" i18n="@@PdfFormat">PDF</ng-container>
                </mat-radio-button>
            </mat-radio-group>
        </div>
    </div>
</form>
