import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-documents-send-to-sign-contextual-help',
  templateUrl: './documents-send-to-sign-contextual-help.component.html'
})
export class DocumentsSendToSignContextualHelpComponent implements OnInit {

  public active: any;
  public activeSub: any;

  ACTIONS_TAB = 0;
  ORGANIZATION_TAB = 1;

  MYDOCUMENT_TAB = 0;
  OURDOCUMENT_TAB = 1;
  PENDINGSIGNATURE_TAB = 2;

  constructor() { }

  ngOnInit(): void {
  }

}
