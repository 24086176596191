import { Injectable } from '@angular/core';
import { ApiProcessorService } from './api-processor.service';
import { ManagerLockedFilesService } from './managerLockedFile.service';

@Injectable()
export class ManagerLockedClausesService extends ManagerLockedFilesService {

    protected override basePath = '/api/manager-management/clauses/{entityId}';

    constructor(protected readonly apiProcessorService: ApiProcessorService) {
        super(apiProcessorService)
    }
}
