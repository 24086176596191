import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { BehaviorSubject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { VersionInfo } from 'src/app/api/model/versionInfo';
import { invalidCharactersValidator } from 'src/app/core/shared/validators/formValidators/invalidCharactersValidator';

@Component({
    selector: 'version-add-comment',
    templateUrl: './version-add-comment.component.html',
    styleUrls: ['./version-add-comment.component.scss']
})
export class CtboxVersionAddCommentComponent implements OnInit, OnDestroy {

    @Input() message: string;
    @Input() version: VersionInfo;
    @Input() hasCheck = 'false';

    public canSaveVersion = new BehaviorSubject<boolean>(false);

    public values = {
        title: '',
        comment: '',
        newVersionCheck: 'false'
    };

    public versionForm: UntypedFormGroup;

    protected MAX_LENGTH_NAME = 100;
    protected MAX_LENGTH_COMMENT = 500;

    private keyEnterCode = 13;
    private onDestroy$ = new EventEmitter<void>();

    constructor(@Inject(MAT_DIALOG_DATA) public data) {
        this.version = data.dataSourceComponent;
        this.message = data.message;
    }

    public ngOnInit(): void {
        this.initFormModel();
    }

    public ngOnDestroy() {
        this.onDestroy$.emit();
    }

    protected controlKeyEnter(event: any): void {
        if (event.charCode === this.keyEnterCode) {
            event.preventDefault();
        }
    }

    protected isBlocked(): boolean {
        if (this.hasCheck === 'false') {
            return true;
        }
        return this.stringToBoolean(this.values.newVersionCheck);
    }

    protected stringToBoolean(value: string): boolean {
        return JSON.parse(value);
    }

    protected changeOption(isChecked: boolean): void {
        this.values.newVersionCheck = String(isChecked);
        this.checkValidation();
    }

    private initFormModel(): void {
        this.versionForm = new UntypedFormGroup(
            {
                name: new UntypedFormControl(null,
                    [Validators.maxLength(this.MAX_LENGTH_NAME), invalidCharactersValidator]),
                comments: new UntypedFormControl(null,
                    [Validators.maxLength(this.MAX_LENGTH_COMMENT), invalidCharactersValidator]),
            }
        );

        this.versionForm.get('name').setValue(this.version.title);
        this.versionForm.get('comments').setValue(this.version.comment);
        this.values.title = this.version.title;
        this.values.comment = this.version.comment;

        this.versionForm.valueChanges
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(value => {
                this.values.title = value.name;
                this.values.comment = value.comments;
                this.canSaveVersion.next(this.versionForm.valid);
        });

        this.checkValidation();
    }

    private checkValidation(): void {
        if (this.stringToBoolean(this.values?.newVersionCheck)) {
            this.canSaveVersion.next(this.versionForm.valid);
            return;
        }

        this.canSaveVersion.next(true);
    }
}
