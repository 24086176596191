import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FolderItemInformationDto, FolderResultDto, SearchTemplateFoldersResultDto, UserFolderPermissionsDto } from 'src/app/api';
import { TemplateFolderDto } from 'src/app/api/model/cmc/templateFolderDto';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { NodeTreeNodeType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-node-type.enum';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { SortTypeAlphabetical } from 'src/app/shared/pipes/sort-type-alphabetical.pipe';


export abstract class BaseTemplateTreeService {

    protected currentExpansionModel = new BehaviorSubject<SelectionModel<string>>(new SelectionModel<string>(true));
    protected rootNodeId = '0';
    protected pipe = new SortTypeAlphabetical();
    protected rootNodeName = $localize`:@@NombreRaizPlantillas:Plantillas de mi corporación`;

    protected nodeTreeActionsMapper = new Map<NodeTreeActionType, string>();

    public constructor() {
        this.initializeNodeTreeActionsMapper();
    }

    protected currentTemplateFolderHierarchyTree = new Subject<TemplateFolderDto[]>();

    public getRootNodeName(): string {
        return this.rootNodeName;
    }

    public getRootNodeId(): string {
        return this.rootNodeId;
    }

    public getNodeFolder(rootNode: FileNode, folderId: string): FileNode {
        return this.getFileNode(rootNode, folderId);
    }

    public setCurrentExpansionModel(selectionModel: SelectionModel<string>): void {
        this.currentExpansionModel.next(selectionModel);
    }

    public getCurrentExpansionModel(): Observable<SelectionModel<string>> {
        return this.currentExpansionModel.asObservable();
    }

    public setCurrentTemplatesFolderHiearchyTree(templateFolderHiearchyTree: TemplateFolderDto[]): void {
        this.currentTemplateFolderHierarchyTree.next(templateFolderHiearchyTree);
    }

    public getCurrentTemplatesFolderHiearchyTree(): Observable<TemplateFolderDto[]> {
        return this.currentTemplateFolderHierarchyTree.asObservable();
    }

    public expandFolderFound(templateFolderSearch: SearchTemplateFoldersResultDto): TemplateFolderDto[] {
        const folders: TemplateFolderDto[] = templateFolderSearch.folders;
        const results: FolderResultDto[] = templateFolderSearch.folderResults;
        folders.map((folder: TemplateFolderDto) => {
            const resultForThisFolder = results.find(result => result.id === folder.id);
            if (!!resultForThisFolder) {
                folder.nameHighlighted = resultForThisFolder.searchResultHighlight.name;
            }
            folder.content.map((item: FolderItemInformationDto) => {
                const resultForThisItem = results.find(resultItem => resultItem.id === item.id);
                if (!resultForThisItem) {
                    return;
                }
                item.nameHighlighted = resultForThisItem.searchResultHighlight.name;
            });
        });

        return folders;
    }

    protected createRootNode(permissions: NodeTreeActionType[]): FileNode {
        return {
            id: this.rootNodeId,
            value: this.rootNodeName,
            children: [],
            type: NodeTreeNodeType.Folder,
            parentId: '',
            actions: permissions,
            isChecked: true
        };
    }

    protected getNodeActionsFilteredByUserPermissions(nodeActions: NodeTreeActionType[],
                                                      userPermission: UserFolderPermissionsDto): NodeTreeActionType[] {
        return nodeActions.map(na =>  {
            if (this.nodeTreeActionsMapper.has(na)) {
                const property = this.nodeTreeActionsMapper.get(na);
                if (userPermission[property]) {
                    return na;
                }
            } else {
                return na;
            }
        }).filter(Boolean);
    }

    private initializeNodeTreeActionsMapper(): void {
        this.nodeTreeActionsMapper.set(NodeTreeActionType.Rename, 'canEditName');
        this.nodeTreeActionsMapper.set(NodeTreeActionType.Create, 'canCreateChildren');
        this.nodeTreeActionsMapper.set(NodeTreeActionType.Delete, 'canDelete');
        this.nodeTreeActionsMapper.set(NodeTreeActionType.CanSeeContent, 'canSeeContent');
        this.nodeTreeActionsMapper.set(NodeTreeActionType.CanCreateContent, 'canCreateContent');
        this.nodeTreeActionsMapper.set(NodeTreeActionType.Move, 'canMoveFrom');
        this.nodeTreeActionsMapper.set(NodeTreeActionType.MoveTo, 'canMoveTo');
    }

    private getFileNode(node: FileNode, folderId: string): FileNode
    {
        const nodeFolder = node.children.find(child => child.id === folderId);

        if (nodeFolder) {
            return nodeFolder;
        }

        for (const child of node.children) {
            const nodeFolderInChildren = this.getFileNode(child, folderId);
            if (nodeFolderInChildren != null) {
                return nodeFolderInChildren;
            }
        }
        return null;
    }
}
