import { Component, Input } from '@angular/core';
import { ScrollStrategy, ScrollStrategyOptions } from '@angular/cdk/overlay';
import { MatLegacyMenuTrigger as MatMenuTrigger } from '@angular/material/legacy-menu';

@Component({
    selector: 'ctbox-help',
    templateUrl: './ctbox-help.component.html',
    styleUrls: ['./ctbox-help.component.scss']
})
export class CtboxHelpComponent {

    @Input() helpType: string;

    public isOpen = false;
    public scrollStrategy: ScrollStrategy;

    constructor(public readonly sso: ScrollStrategyOptions) {
        this.scrollStrategy = this.sso.close();
    }

    public openMenu(menu: MatMenuTrigger) {
        menu.openMenu();
    }

    public openHelp(): void {
        this.isOpen = true;
        window.addEventListener('scroll', this.scrollEvent, true);
    }

    public closeHelp(): void {
        this.isOpen = false;
        window.addEventListener('scroll', this.scrollEvent, true);
    }

    public scrollEvent = (): void => {
        this.isOpen = false;
    }

}

