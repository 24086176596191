import { Injectable } from '@angular/core';
import { Editor } from 'ckeditor5';
import { SignaturePlugin } from '../../plugins/signature/signature-plugin';
import { SignatureUtilsService } from '../../utils/signature/signature-utils.service';

@Injectable({
    providedIn: 'root'
})
export class SignatureModelToDataViewConverterService {

    private utilsService: SignatureUtilsService;

    constructor() {
        this.utilsService = new SignatureUtilsService();
    }

    public configureConverters(editor: Editor) {
        editor.conversion.for("dataDowncast").elementToElement({
            model: SignaturePlugin.pluginModelName,
            view: (modelItem, { writer: viewWriter }) =>
                this.utilsService.createSignatureView(editor, modelItem, viewWriter)
        });
    }
}
