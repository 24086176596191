export enum PagesWithContextualHelp {
    ContractsListAll = 'ContractsListAll',
    ContractsListDraft = 'ContractsListDraft',
    ContractsListReview = 'ContractsListReview',
    ContractsListComplete = 'ContractsListComplete',
    ContractsListSendToSign = 'ContractsListSendToSign',
    ContractsListSigned = 'ContractsListSigned',
    ContractsEdition = 'ContractsEdition',
    TemplatesList = 'TemplatesList',
    TemplatesEdition = 'TemplatesEdition',
    ClausesList = 'ClausesList',
    ClausesEdition = 'ClausesEdition',
    AdministrationLibrary = 'AdministrationLibrary',
}
