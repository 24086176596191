import { Directive, Inject, OnDestroy } from '@angular/core';
import { View, LabeledFieldView, ButtonView, submitHandler, ViewCollection, FocusableView, Locale, FocusTracker, FocusCycler, KeystrokeHandler, IconView, LabelView } from 'ckeditor5';

@Directive()
export default class BaseView extends View implements OnDestroy {

    public deleteButtonView: ButtonView;

    public readonly keystrokes = new KeystrokeHandler();
    public readonly focusTracker = new FocusTracker();
    protected items: ViewCollection;

    protected readonly focusables = new ViewCollection<FocusableView>();
    protected readonly focusCycler: FocusCycler;

    protected readonly submitButtonMessage = $localize`:@@AceptarCambiosRadioPlugin:Aceptar`;
    protected readonly cancelButtonMessage = $localize`:@@CancelarCambiosRadioPlugin:Cancelar`;

    constructor(
        @Inject(Locale) locale?: Locale,
    ) {
        super(locale);
        this.focusCycler = this.createFocusCycler();
    }

    public ngOnDestroy(): void {
        super.destroy();

        this.focusTracker.destroy();
        this.keystrokes.destroy();
    }

    public override render() {
        super.render();

        submitHandler({
            view: this
        });

        this.items.forEach((viewItem: View) => {
            if (viewItem instanceof LabeledFieldView || viewItem instanceof ButtonView) {
                this.focusables.add(viewItem!);
                this.focusTracker.add(viewItem.element!);
            }
        });

        this.keystrokes.listenTo(this.element!);
    }

    public focus(): void {
        this.focusCycler?.focusFirst();
    }

    protected setTemplateForm(aditionalClass: string): void {
        const classes: string[] = ['ck'];
        if(!!aditionalClass) {
            classes.push(aditionalClass);
        }
        this.setTemplate({
            tag: "form",
            attributes: {
                class: classes,
                tabindex: "-1",
            },
            children: this.items
        });
    }

    protected createFocusCycler(): FocusCycler {
        return  new FocusCycler({
            focusables: this.focusables,
            focusTracker: this.focusTracker,
            keystrokeHandler: this.keystrokes,
            actions: {
                focusPrevious: "shift + tab",
                focusNext: "tab",
            },
        });
    }

    protected createButton(label: string, className?: string, icon?: string, withText?: boolean): ButtonView {
        const button = new ButtonView();

        button.set({
            label,
            icon,
            tooltip: true,
            class: className,
            withText: !icon || withText
        });

        return button;
    }

    protected createInfoIcon(tooltipText: string, iconSvg: string): LabelView {
        const iconView = new IconView();
        iconView.content = iconSvg;

        const iconContainer = new LabelView();

        iconContainer.setTemplate({
            tag: 'span',
            attributes: {
                class: 'ck-icon-info'
            },
            children: [
                iconView,
                {
                    tag: 'span',
                    attributes: {
                        class: 'tooltip-text'
                    },
                    children: [ tooltipText ]
                }
            ]
        });

        iconContainer.on('render', () => {
            const iconElement = iconContainer.element as HTMLElement;
            const tooltipElement = iconElement.querySelector('.tooltip-text') as HTMLElement;

            iconElement.addEventListener('mouseenter', () => {
                tooltipElement.classList.add('visible');
            });

            iconElement.addEventListener('mouseleave', () => {
                tooltipElement.classList.remove('visible');
            });
        });

        return iconContainer;
    }
}
