<div class="viewer">
    <div class="viewer-header comparation">
        <div class="viewer-info size-50">
            <div class="viewer-title">
                <div class="viewer-title-label">{{clauseModifiedName}}</div>
                <div class="viewer-title-name" i18n="@@DocumentoClausulaEditadaTitulo">(Cláusula modificada)</div>
            </div>
        </div>
        <div class="viewer-info size-50">
            <div class="viewer-title">
                <div class="viewer-title-label">{{clause?.name}}</div>
                <div class="viewer-title-name" i18n="@@ClausulaActualPlantillaRevisionTitulo">(Cláusula actual)</div>
            </div>
        </div>
    </div>
    <div class="viewer-body comparation header">
        <div class="viewer-html size-50">
            <div *ngIf="templateCoText" class="viewer-comment">
                <div class="comment">
                    <div class="comment-title" i18n="@@ComentarioDeLaActualizacion">Comentario de la actualización:</div>
                    <app-html-viewer #templateViewerCO [html]="templateCoText" className="comment-text" [addScrollSm]="true" [replaceFilledInput]="false"></app-html-viewer>
                </div>
            </div>
            <app-html-viewer #templateViewer [html]="templateOriginal" [addScrollSm]="true" [replaceFilledInput]="false"></app-html-viewer>
        </div>
        <div class="viewer-html overflow size-50">
            <app-wysiwyg-editor #editor class="wysiwyg wysiwyg-main" #clauseViewer [dataEditor]="clause ? clause.description : ''" [permissionEditor]="permissionConfig"
            [defaultPluginToRemove]="['TrackChanges', 'Comments']" [pluginConfiguration]="editorPluginConfig" [resetHasUnsavedData]="resetUnsavedChangesNotifier" [isReadOnly]="isReadOnly" (editorReady)="editorReady()" >
            </app-wysiwyg-editor>
        </div>
    </div>
</div>
