import { Injectable } from '@angular/core';
import { ApiProcessorService } from '../api-processor.service';
import { BaseLegalAlertsService } from '../base/base-legal-alerts.service';

@Injectable()
export class LegalAlertsService extends BaseLegalAlertsService {

    protected override basePath = '/api/legal-alerts';

    constructor(
        protected readonly apiProcessorService: ApiProcessorService
    ) {
        super(apiProcessorService);
    }

}
