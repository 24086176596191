import { ExpandRequestModel } from "../model/request-model/expand-request.model";
import { RequestModel } from "../model/request-model/request.model";

export class RequestQueryParamsService {

    public getQueryParamsByExpandRequest(request: ExpandRequestModel): string {
        const clonnedRequest = Object.assign({}, request);
        const params = [];
        for (const item in clonnedRequest.expands.filter(item => !!item)) {
            const itemType = typeof clonnedRequest.expands[item];
            if (itemType === 'string' || itemType === 'number' || itemType === 'boolean') {
                params.push(`expands=${clonnedRequest.expands[item]}`);
            } else if (itemType === 'object') {
                params.push(`expands=${JSON.stringify(clonnedRequest.expands[item])}`);
            }
        }
        return params.join('&');
    }

    public getQueryParamsByRequest(request: RequestModel): string {
        const params = [];
        for (const property of Object.keys(request).filter(property => !!property)) {
            const itemType = typeof request[property];
            if (itemType === 'string' || itemType === 'number' || itemType === 'boolean') {
                params.push(`${property}=${request[property]}`);
            } else if (itemType === 'object') {
                params.push(`${property}=${JSON.stringify(request[property])}`);
            }
        }
        return params.join('&');
    }
}
