/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DocumentRejectReasonDto } from '../model/documentRejectReasonDto';
import { DocumentWorkflowDto } from '../model/documentWorkflowDto';
import { DocumentWorkflowDtoOperation } from '../model/documentWorkflowDtoOperation';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DocumentWorkflowService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param documentWorkflowId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentWorkflowApprovePut(documentWorkflowId?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentWorkflowDto>;
    public documentWorkflowApprovePut(documentWorkflowId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentWorkflowDto>>;
    public documentWorkflowApprovePut(documentWorkflowId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentWorkflowDto>>;
    public documentWorkflowApprovePut(documentWorkflowId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentWorkflowId !== undefined && documentWorkflowId !== null) {
            queryParameters = queryParameters.set('documentWorkflowId', <any>documentWorkflowId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentWorkflowDto>('put',`${this.basePath}/document-workflow/approve`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param documentId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentWorkflowCancelWorkflowPut(documentId: string, body?: DocumentRejectReasonDto, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentWorkflowDto>>;
    public documentWorkflowCancelWorkflowPut(documentId: string, body?: DocumentRejectReasonDto, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentWorkflowDto>>>;
    public documentWorkflowCancelWorkflowPut(documentId: string, body?: DocumentRejectReasonDto, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentWorkflowDto>>>;
    public documentWorkflowCancelWorkflowPut(documentId: string, body?: DocumentRejectReasonDto, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling documentWorkflowCancelWorkflowPut.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentId !== undefined && documentId !== null) {
            queryParameters = queryParameters.set('documentId', <any>documentId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<DocumentWorkflowDto>>('put',`${this.basePath}/document-workflow/cancel-workflow`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param documentWorkflowId 
     * @param body 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentWorkflowDocumentWorkflowIdPatch(documentWorkflowId: string, body?: Array<DocumentWorkflowDtoOperation>, observe?: 'body', reportProgress?: boolean): Observable<DocumentWorkflowDto>;
    public documentWorkflowDocumentWorkflowIdPatch(documentWorkflowId: string, body?: Array<DocumentWorkflowDtoOperation>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentWorkflowDto>>;
    public documentWorkflowDocumentWorkflowIdPatch(documentWorkflowId: string, body?: Array<DocumentWorkflowDtoOperation>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentWorkflowDto>>;
    public documentWorkflowDocumentWorkflowIdPatch(documentWorkflowId: string, body?: Array<DocumentWorkflowDtoOperation>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentWorkflowId === null || documentWorkflowId === undefined) {
            throw new Error('Required parameter documentWorkflowId was null or undefined when calling documentWorkflowDocumentWorkflowIdPatch.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentWorkflowDto>('patch',`${this.basePath}/document-workflow/${encodeURIComponent(String(documentWorkflowId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentWorkflowGet(documentId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentWorkflowDto>>;
    public documentWorkflowGet(documentId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentWorkflowDto>>>;
    public documentWorkflowGet(documentId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentWorkflowDto>>>;
    public documentWorkflowGet(documentId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling documentWorkflowGet.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentId !== undefined && documentId !== null) {
            queryParameters = queryParameters.set('documentId', <any>documentId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentWorkflowDto>>('get',`${this.basePath}/document-workflow`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param body 
     * @param documentWorkflowId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentWorkflowRefusePut(body?: DocumentRejectReasonDto, documentWorkflowId?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentWorkflowDto>;
    public documentWorkflowRefusePut(body?: DocumentRejectReasonDto, documentWorkflowId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentWorkflowDto>>;
    public documentWorkflowRefusePut(body?: DocumentRejectReasonDto, documentWorkflowId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentWorkflowDto>>;
    public documentWorkflowRefusePut(body?: DocumentRejectReasonDto, documentWorkflowId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentWorkflowId !== undefined && documentWorkflowId !== null) {
            queryParameters = queryParameters.set('documentWorkflowId', <any>documentWorkflowId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentWorkflowDto>('put',`${this.basePath}/document-workflow/refuse`,
            {
                body: body,
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentWorkflowResumeWorkflowPut(documentId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentWorkflowDto>>;
    public documentWorkflowResumeWorkflowPut(documentId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentWorkflowDto>>>;
    public documentWorkflowResumeWorkflowPut(documentId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentWorkflowDto>>>;
    public documentWorkflowResumeWorkflowPut(documentId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling documentWorkflowResumeWorkflowPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentId !== undefined && documentId !== null) {
            queryParameters = queryParameters.set('documentId', <any>documentId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentWorkflowDto>>('put',`${this.basePath}/document-workflow/resume-workflow`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param documentId 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentWorkflowStartWorkflowPut(documentId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentWorkflowDto>>;
    public documentWorkflowStartWorkflowPut(documentId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentWorkflowDto>>>;
    public documentWorkflowStartWorkflowPut(documentId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentWorkflowDto>>>;
    public documentWorkflowStartWorkflowPut(documentId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling documentWorkflowStartWorkflowPut.');
        }

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentId !== undefined && documentId !== null) {
            queryParameters = queryParameters.set('documentId', <any>documentId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentWorkflowDto>>('put',`${this.basePath}/document-workflow/start-workflow`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
