<form [formGroup]="form">
    <div class="workflow-info">
        <div class="workflow-info-subject" *ngIf="isCancelWorkflow">
            <p class="workflow-info-cancel" i18n="@@workflow-info-cancel-draft">Si cancelas el Workflow de trabajo el documento volverá al estado Borrador y se eliminarán los comentarios que hubiese.</p>
            <p class="workflow-info-cancel" i18n="@@workflow-info-cancel">Puede indicar el motivo de la cancelación, que será incluido en el mail de comunicación a los intervinientes:</p>
        </div>
        <div class="workflow-info-subject" *ngIf="isRejectWorkflow">
            <p i18n="@@workflow-info-rejection">Puede indicar el motivo del rechazo, que será incluído en el mail de comunicación al propietario del documento.</p>
        </div>
        <div class="workflow-info-message">
            <div class="form-group-flex fcol">
                <mat-form-field class="size-100">
                    <mat-label i18n="@@Comentario">Comentario</mat-label>
                    <textarea matInput #formMessage (focusout)="getComment()" rows="10" formControlName="comment" name="comment"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
</form>