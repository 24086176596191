import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { emailFormValidator } from 'src/app/core/shared/validators/formValidators/emailFormValidator';

@Component({
  selector: 'app-ctbox-form-email',
  templateUrl: './ctbox-form-email.component.html'
})
export class CtboxFormEmailComponent implements OnInit {
    @Input() public parentGroup: UntypedFormGroup;
    @Input() public controlName: string;
    @Input() public hideMailManageButtons: boolean = false;
    @Input() public set emails(newsEmails: Array<string>) {
        if (newsEmails) {
            this.setEmails(newsEmails);
        }
    }

    public get childrenControls(): UntypedFormArray {
        return this.parentGroup.get(this.controlName) as UntypedFormArray;
    }

    public ngOnInit(): void {
        if (!this.hasFormValues()) {
            this.add();
        }
    }

    public add(): void {
        (this.parentGroup.controls[this.controlName] as UntypedFormArray).push(new UntypedFormGroup({
            mailAddress: new UntypedFormControl('', [Validators.maxLength(100), emailFormValidator]),
        }));
    }

    public remove(index: number): void {
        this.childrenControls.removeAt(index);
    }

    private setEmails(emails: string[]): void {
        const items = new UntypedFormArray([]);
        for (const email of emails) {
            items.push(new UntypedFormGroup({
                mailAddress: new UntypedFormControl(email, [Validators.maxLength(100), emailFormValidator])
            }));
        }

        this.parentGroup.setControl(this.controlName, items);
    }

    private hasFormValues(): boolean {
        return this.childrenControls.length > 0;
    }
}
