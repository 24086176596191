import { Pipe, PipeTransform } from '@angular/core';
import { NodeTreeNodeType } from '../components/ctbox-tree/enums/node-tree-node-type.enum';
import { FileNode } from '../components/ctbox-tree/models/file-node.model';

@Pipe({
    name: 'sortTypeAlphabetical'
})
export class SortTypeAlphabetical implements PipeTransform {

private sizeTypes: Map<NodeTreeNodeType, number> = new Map<NodeTreeNodeType, number>([
    [NodeTreeNodeType.Clause, 1],
    [NodeTreeNodeType.Template, 2],
    [NodeTreeNodeType.Folder, 3],
    [NodeTreeNodeType.MyDocuments, 4],
    [NodeTreeNodeType.OurDocuments, 5],
    [NodeTreeNodeType.Shared, 6],
    [NodeTreeNodeType.Smartform, 7],
]
);

    transform(array: FileNode[]): FileNode[] {
        array.sort((a: FileNode, b: FileNode) => {
            if ( a.type !== b.type) {
                return this.sizeTypes.get(b.type) - this.sizeTypes.get(a.type);
            }

            return  a.value.localeCompare(b.value);
        });
        return array;
    }
}
