import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ISmartFormConvertService } from './smartform-convert.service.interface';

@Injectable({
    providedIn: 'root'
})
export class SmartFormConvertService extends ISmartFormConvertService {

    private smartFormName = new BehaviorSubject<string>('');
    private smartFormContent = new BehaviorSubject<string>('');
    private fileExtension = new BehaviorSubject<string>('');
    private file = new BehaviorSubject<File>(null);

    constructor() {
        super();
    }

    public updateSmartFormName(smartFormName: string) {
        this.smartFormName.next(smartFormName);
    }

    public getCurrentSmartFormName(): string {
        return this.smartFormName.getValue();
    }

    public updateSmartFormContent(smartFormContent: string) {
        this.smartFormContent.next(smartFormContent);
    }

    public getCurrentSmartFormContent(): string {
        return this.smartFormContent.getValue();
    }

    public getNotEditableFile(): File {
        return this.file.getValue();
    }

    public updateNotEditableFile(notEditableFile: File) {
        this.file.next(notEditableFile);
    }

    public updateSmartFormFileExtension(extension: string) {
        return this.fileExtension.next(extension);
    }
    public getNotEditableFileExtension(): string {
        return this.fileExtension.getValue();
    }
}
