export enum FileValidationStatus {
    Success,
    NameExceedsLimit,
    ExceedsSizeLimit,
    UnsupportedFileType,
    FileNotFound,
    Unauthorized,
    AlreadyUpdating,
    DuplicateIdentifier,
    PendingUpdatedClauses,
    IncorrectFileType
}
