import { Directive, Input } from '@angular/core';

@Directive({
    selector: '[appDragIntoFoldersDirective]'
})
export class DragIntoFoldersDirective {

    @Input() public canDragIntoFolders: boolean;

    constructor() { }

}
