import { ApiOperation, ApiProcessorService, ApiRequest } from "./api-processor.service";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TemplateFolderDto } from "../model/models";

@Injectable()
export class AdministratorTemplatesFoldersService {
    private basePath = '/api/administration-management/templates-folders';

    constructor(private readonly apiProcessorService: ApiProcessorService) {
    }

    public getAll(): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.basePath;

        return this.apiProcessorService.proccessRequest<TemplateFolderDto[]>(apiRequest);
    }
}
