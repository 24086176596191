import { Injectable } from '@angular/core';

declare let CKEDITOR: any;

@Injectable({
    providedIn: 'root'
})
export class CkeditorFormsTextFieldService {

    private editor: any;

    public configureTextField() {
        if (CKEDITOR.dialog.exists('textfield')) {
            return;
        }

        CKEDITOR.dialog.add('textfield', (editor) => {

            const acceptedTypes = { email: 1, text: 1, number: 1, date: 1 };

            function autoCommit(data) {
                if (!this.id) {
                    return;
                }
                const element = data.element;
                const value = this.getValue();

                value ? element.setAttribute(this.id, value) : element.removeAttribute(this.id);
            }

            function autoSetup(element) {
                const value = element.hasAttribute(this.id) && element.getAttribute(this.id);
                this.setValue(value || '');
            }


            editor.on('doubleclick', (evt) => {
                const element = evt.data.element;
                editor.getSelection().selectElement(element);
                if (element && element.is('input')) {
                    evt.stop();
                }
            });

            return {
                title: 'Insertar campo para rellenar',
                minWidth: 350,
                minHeight: 150,
                getModel(editorModel) {
                    const element = editorModel.getSelection().getSelectedElement();

                    if (element && element.getName() === 'input' &&
                        (acceptedTypes[element.getAttribute('type')] || !element.getAttribute('type'))) {
                        return element;
                    }

                    return null;
                },
                onShow() {
                    const element = this.getModel(this.getParentEditor());
                    if (element) {
                        this.setupContent(element);
                    }
                },
                onOk() {
                    const editorOnOk = this.getParentEditor();
                    let element = this.getModel(editor);
                    const isInsertMode = this.getMode(editorOnOk) === CKEDITOR.dialog.CREATION_MODE;

                    if (isInsertMode) {
                        element = editorOnOk.document.createElement('input');
                        element.setAttribute('type', 'text');

                        element.$.addEventListener('mousedown', (event) => {
                            element.$.setAttribute('contenteditable', 'true');
                            event.stopPropagation();
                        }, true);

                        element.$.addEventListener('mouseup', (eventMouse) => {
                            eventMouse.stopPropagation();
                        }, true);

                        element.$.addEventListener('input', (event) => {
                            const text = element.$.value;
                            element.setAttribute('value', text);
                        }, true);

                        element.$.addEventListener('keydown', (event) => {
                            const text = element.$.value;
                            event.stopPropagation();
                        }, true);

                        element.$.addEventListener('propertychange', (event) => {
                            const text = element.$.value;
                            element.setAttribute('value', text);
                        }, true);


                    }

                    const data = { element };

                    if (isInsertMode) {
                        editorOnOk.insertElement(data.element);
                    }

                    this.commitContent(data);

                    // Element might be replaced by commitment.
                    if (!isInsertMode) {
                        editorOnOk.getSelection().selectElement(data.element);
                    }
                },
                onLoad() {
                    this.foreach((contentObj) => {
                        if (contentObj.getValue) {
                            if (!contentObj.setup) {
                                contentObj.setup = autoSetup;
                            }
                            if (!contentObj.commit) {
                                contentObj.commit = autoCommit;
                            }
                        }
                    });
                },
                contents: [{
                    id: 'info',
                    label: editor.lang.forms.textfield.title,
                    title: editor.lang.forms.textfield.title,
                    elements: [
                        {
                            type: 'hbox',
                            widths: ['50%', '50%'],
                            children: [{
                                id: 'size',
                                type: 'text',
                                label: editor.lang.forms.textfield.charWidth,
                                default: '',
                                accessKey: 'C',
                                style: 'width:50px',
                                validate: CKEDITOR.dialog.validate.integer(editor.lang.common.validateNumberFailed)
                            },
                            {
                                id: 'maxLength',
                                type: 'text',
                                label: editor.lang.forms.textfield.maxChars,
                                default: '',
                                accessKey: 'M',
                                style: 'width:50px',
                                validate: CKEDITOR.dialog.validate.integer(editor.lang.common.validateNumberFailed)
                            }],
                            onLoad() {
                                // Repaint the style for IE7 (https://dev.ckeditor.com/ticket/6068)
                                if (CKEDITOR.env.ie7Compat) {
                                    this.getElement().setStyle('zoom', '100%');
                                }
                            }
                        },
                        {
                            id: 'type',
                            type: 'select',
                            label: editor.lang.forms.textfield.type,
                            default: 'text',
                            accessKey: 'M',
                            items: [
                                [editor.lang.forms.textfield.typeEmail, 'email'],
                                [editor.lang.forms.textfield.typeText, 'text'],
                                ['Número', 'number'],
                                ['Fecha', 'date']
                            ],
                            setup(element) {
                                this.setValue(element.getAttribute('type'));
                            },
                            commit(data) {
                                const element = data.element;

                                if (CKEDITOR.env.ie) {
                                    const elementType = element.getAttribute('type');
                                    const myType = this.getValue();

                                    if (elementType !== myType) {
                                        const replace = CKEDITOR.dom.element
                                            .createFromHtml('<input type="' + myType + '"></input>', editor.document);
                                        element.copyAttributes(replace, { type: 1 });
                                        replace.replace(element);
                                        data.element = replace;
                                    }
                                } else {
                                    element.setAttribute('type', this.getValue());
                                }
                            }
                        }]
                }]
            };
        });
    }

    public configureTextFieldNoModal() {

        if (!(CKEDITOR.config.extraPlugins as string).includes('textFieldNoModal')) {
            CKEDITOR.config.extraPlugins = (CKEDITOR.config.extraPlugins as string).concat('textFieldNoModal,');
        }

        delete CKEDITOR.plugins.registered.textFieldNoModal;

        CKEDITOR.plugins.add('textFieldNoModal', {
            icons: '',
            init: (editor: any) => {
                editor.addCommand('insertInput', {
                    exec() {
                        const element = editor.document.createElement('input');
                        element.setAttribute('type', 'text');

                        var randomId = 'I' + Math.floor(Math.random() * (10000-100+1)+100);

                        element.setAttribute('id', randomId);
                        element.setAttribute('name', randomId);

                        element.$.addEventListener('mousedown', (event) => {
                            element.$.setAttribute('contenteditable', 'true');
                            event.stopPropagation();
                        }, true);

                        element.$.addEventListener('mouseup', (eventMouse) => {
                            eventMouse.stopPropagation();
                        }, true);

                        element.$.addEventListener('input', () => {
                            const text = element.$.value;
                            element.setAttribute('value', text);
                        }, true);

                        element.$.addEventListener('keydown', (event) => {
                            const text = element.$.value;
                            event.stopPropagation();
                        }, true);

                        element.$.addEventListener('propertychange', () => {
                            const text = element.$.value;
                            element.setAttribute('value', text);
                        }, true);

                        const data = { element };

                        editor.insertElement(data.element);
                    }
                });

                editor.ui.addButton('InsertInput', {
                    label: $localize`:@@InsertarCampo:Insertar campo para rellenar`,
                    command: 'insertInput',
                    toolbar: 'forms',
                    icon: 'textfield'
                });
            }
        });
    }
}
