import { ApiOperation, ApiProcessorService, ApiRequest } from './api-processor.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TemplateAutomaticLegalAlertsService {

    private basePath = '/api/template-legal-alerts/automatic-alerts';

    constructor(private readonly apiProcessorService: ApiProcessorService) {
    }

    public get(templateId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}/${templateId}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
