<ng-container *ngIf="parentGroup" [formGroup]="parentGroup">
    <div class="form-group-flex fcol min100" formArrayName="{{ controlName }}">
        <div class="form-group-flex min100 gap0" *ngFor="let formGroupContainer of childrenControls.controls; index as i; let last = last">
            <ng-container [formGroup]="formGroupContainer">
                <div class="form-group size-80 pt-8">
                    <mat-form-field>
                        <mat-label>Email</mat-label>
                        <input #formInput matInput formControlName="mailAddress" [id]="'mailAddress' + controlName + i">
                        <mat-error *ngIf="childrenControls.controls[i].get('mailAddress').errors?.maxlength" i18n="@@LongitudMaximaExcedida">Longitud máxima excedida</mat-error>
                        <mat-error *ngIf="childrenControls.controls[i].get('mailAddress').errors?.email" i18n="@@FormatoIncorrectoEmail">El email no contiene un formato correcto</mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group size-20" [hidden]="hideMailManageButtons">
                    <ng-container *ngIf="last; else showDeleteButton">
                        <ctbox-button buttonType="text" buttonIcon="add_circle_outline" buttonText="Añadir email" i18n-buttonText="@@AnnadirEmail" (buttonClick)="add()"></ctbox-button>
                    </ng-container>
                    <ng-template #showDeleteButton>
                        <ctbox-button buttonType="text" buttonIcon="remove_circle_outline" buttonText="Eliminar email" i18n-buttonText="@@EliminarEmail" (buttonClick)="remove(i)"></ctbox-button>
                    </ng-template>
                </div>
            </ng-container>
        </div>
    </div>
</ng-container>