export enum TrainingVideosCodes {
    VideoLanding = 0,
    CreationDocument = 1,
    WorkWithTemplates = 2,
    CreateYourLibraryOfClauses = 3,
    ShareDocuments = 4,
    ReviewDocuments = 5,
    SaveDocumentVersion = 6,
    SignDocuments = 7,
    AlertsTemplates = 8,
    AlertsClauses = 9,
    ManagePermissions = 10
}
