/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { DocumentAdvanceNoticeExtensionDto } from './documentAdvanceNoticeExtensionDto';
import { DocumentDurationAdvanceNoticeDto } from './documentDurationAdvanceNoticeDto';
import { DocumentExtensionDto } from './documentExtensionDto';
import { MasterData } from './masterData';

export interface DocumentSheetDto { 
    id?: string;
    hasWarnings?: boolean;
    contractDate?: Date;
    endDate?: Date;
    startDateDuration?: Date;
    endDateDuration?: Date;
    comment?: string;
    inForceTypeId?: string;
    inForceType?: MasterData;
    documentDurationTypeId?: string;
    documentId?: string;
    documentDurationAdvanceNotice?: DocumentDurationAdvanceNoticeDto;
    documentExtension?: DocumentExtensionDto;
    documentAdvanceNoticeExtension?: DocumentAdvanceNoticeExtensionDto;
}