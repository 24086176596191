/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { DocumentDTO } from '../model/documentDTO';
import { DocumentDTOOperation } from '../model/documentDTOOperation';
import { DocumentSearchSummaryDto } from '../model/documentSearchSummaryDto';
import { DocumentSignatureDTO } from '../model/documentSignatureDTO';
import { DocumentStates } from '../model/documentStates';
import { DocumentsInfoDTO } from '../model/documentsInfoDTO';
import { ErrorDocumentOperationDTO } from '../model/errorDocumentOperationDTO';
import { SharedPropertyDTO } from '../model/sharedPropertyDTO';
import { UserDTO } from '../model/userDTO';
import { UserDocumentSharedInfoDTO } from '../model/userDocumentSharedInfoDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class DocumentService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentCancelForSignaturePreviouslyIdPut(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public documentCancelForSignaturePreviouslyIdPut(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public documentCancelForSignaturePreviouslyIdPut(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public documentCancelForSignaturePreviouslyIdPut(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling documentCancelForSignaturePreviouslyIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/document/cancel-for-signature-previously/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param ids
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentCancelForSignaturePreviouslyMultiplePut(ids?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public documentCancelForSignaturePreviouslyMultiplePut(ids?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public documentCancelForSignaturePreviouslyMultiplePut(ids?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public documentCancelForSignaturePreviouslyMultiplePut(ids?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (ids) {
            ids.forEach((element) => {
                queryParameters = queryParameters.append('ids', <any>element);
            })
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('put',`${this.basePath}/document/cancel-for-signature-previously-multiple`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentCancelSignIdPut(id: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentCancelSignIdPut(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentCancelSignIdPut(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentCancelSignIdPut(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling documentCancelSignIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentDTO>('put',`${this.basePath}/document/cancel-sign/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentCommonFolderGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentDTO>>;
    public documentCommonFolderGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentDTO>>>;
    public documentCommonFolderGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentDTO>>>;
    public documentCommonFolderGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentDTO>>('get',`${this.basePath}/document/common-folder`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentDelete(id?: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public documentDelete(id?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public documentDelete(id?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public documentDelete(id?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (id !== undefined && id !== null) {
            queryParameters = queryParameters.set('id', <any>id);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('delete',`${this.basePath}/document`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentDocumentFromSignatureStateIdPut(id: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentDocumentFromSignatureStateIdPut(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentDocumentFromSignatureStateIdPut(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentDocumentFromSignatureStateIdPut(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling documentDocumentFromSignatureStateIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentDTO>('put',`${this.basePath}/document/document-from-signature-state/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param documentId
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentDocumentIdPatch(documentId: string, body?: Array<DocumentDTOOperation>, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentDocumentIdPatch(documentId: string, body?: Array<DocumentDTOOperation>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentDocumentIdPatch(documentId: string, body?: Array<DocumentDTOOperation>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentDocumentIdPatch(documentId: string, body?: Array<DocumentDTOOperation>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling documentDocumentIdPatch.');
        }


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentDTO>('patch',`${this.basePath}/document/${encodeURIComponent(String(documentId))}`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentDocumentSignaturePut(body?: DocumentDTO, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentDocumentSignaturePut(body?: DocumentDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentDocumentSignaturePut(body?: DocumentDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentDocumentSignaturePut(body?: DocumentDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentDTO>('put',`${this.basePath}/document/document-signature`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param documentState
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentDocumentStateDocumentStateGet(documentState: DocumentStates, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentDTO>>;
    public documentDocumentStateDocumentStateGet(documentState: DocumentStates, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentDTO>>>;
    public documentDocumentStateDocumentStateGet(documentState: DocumentStates, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentDTO>>>;
    public documentDocumentStateDocumentStateGet(documentState: DocumentStates, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentState === null || documentState === undefined) {
            throw new Error('Required parameter documentState was null or undefined when calling documentDocumentStateDocumentStateGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentDTO>>('get',`${this.basePath}/document/document-state/${encodeURIComponent(String(documentState))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentDocumentStatePut(body?: DocumentDTO, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentDocumentStatePut(body?: DocumentDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentDocumentStatePut(body?: DocumentDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentDocumentStatePut(body?: DocumentDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentDTO>('put',`${this.basePath}/document/document-state`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentDocumentsInfoGet(observe?: 'body', reportProgress?: boolean): Observable<DocumentsInfoDTO>;
    public documentDocumentsInfoGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentsInfoDTO>>;
    public documentDocumentsInfoGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentsInfoDTO>>;
    public documentDocumentsInfoGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentsInfoDTO>('get',`${this.basePath}/document/documents-info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentDocumentsSharedGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentDTO>>;
    public documentDocumentsSharedGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentDTO>>>;
    public documentDocumentsSharedGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentDTO>>>;
    public documentDocumentsSharedGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentDTO>>('get',`${this.basePath}/document/documents-shared`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentDocumentsSharedInfoGet(observe?: 'body', reportProgress?: boolean): Observable<UserDocumentSharedInfoDTO>;
    public documentDocumentsSharedInfoGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<UserDocumentSharedInfoDTO>>;
    public documentDocumentsSharedInfoGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<UserDocumentSharedInfoDTO>>;
    public documentDocumentsSharedInfoGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<UserDocumentSharedInfoDTO>('get',`${this.basePath}/document/documents-shared-info`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentDocumentsSheetPut(body?: DocumentDTO, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentDocumentsSheetPut(body?: DocumentDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentDocumentsSheetPut(body?: DocumentDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentDocumentsSheetPut(body?: DocumentDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentDTO>('put',`${this.basePath}/document/documents-sheet`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentErrorDescriptionGet(observe?: 'body', reportProgress?: boolean): Observable<ErrorDocumentOperationDTO>;
    public documentErrorDescriptionGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ErrorDocumentOperationDTO>>;
    public documentErrorDescriptionGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ErrorDocumentOperationDTO>>;
    public documentErrorDescriptionGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ErrorDocumentOperationDTO>('get',`${this.basePath}/document/error-description`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentFinishReviewIdPut(id: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentFinishReviewIdPut(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentFinishReviewIdPut(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentFinishReviewIdPut(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling documentFinishReviewIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentDTO>('put',`${this.basePath}/document/finish-review/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param documentState
     * @param folderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentFolderDocumentStateDocumentStateGet(documentState: DocumentStates, folderId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentDTO>>;
    public documentFolderDocumentStateDocumentStateGet(documentState: DocumentStates, folderId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentDTO>>>;
    public documentFolderDocumentStateDocumentStateGet(documentState: DocumentStates, folderId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentDTO>>>;
    public documentFolderDocumentStateDocumentStateGet(documentState: DocumentStates, folderId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentState === null || documentState === undefined) {
            throw new Error('Required parameter documentState was null or undefined when calling documentFolderDocumentStateDocumentStateGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (folderId !== undefined && folderId !== null) {
            queryParameters = queryParameters.set('folderId', <any>folderId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentDTO>>('get',`${this.basePath}/document/folder/document-state/${encodeURIComponent(String(documentState))}`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param folderId
     * @param documentState
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentFolderFolderIdDocumentStateDocumentStateGet(folderId: string, documentState: DocumentStates, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentDTO>>;
    public documentFolderFolderIdDocumentStateDocumentStateGet(folderId: string, documentState: DocumentStates, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentDTO>>>;
    public documentFolderFolderIdDocumentStateDocumentStateGet(folderId: string, documentState: DocumentStates, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentDTO>>>;
    public documentFolderFolderIdDocumentStateDocumentStateGet(folderId: string, documentState: DocumentStates, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling documentFolderFolderIdDocumentStateDocumentStateGet.');
        }

        if (documentState === null || documentState === undefined) {
            throw new Error('Required parameter documentState was null or undefined when calling documentFolderFolderIdDocumentStateDocumentStateGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentDTO>>('get',`${this.basePath}/document/folder/${encodeURIComponent(String(folderId))}/document-state/${encodeURIComponent(String(documentState))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param folderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentFolderFolderIdGet(folderId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentDTO>>;
    public documentFolderFolderIdGet(folderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentDTO>>>;
    public documentFolderFolderIdGet(folderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentDTO>>>;
    public documentFolderFolderIdGet(folderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling documentFolderFolderIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentDTO>>('get',`${this.basePath}/document/folder/${encodeURIComponent(String(folderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentFolderGet(observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentDTO>>;
    public documentFolderGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentDTO>>>;
    public documentFolderGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentDTO>>>;
    public documentFolderGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentDTO>>('get',`${this.basePath}/document/folder`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param documentStateCode
     * @param sharedPropertyCode
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentGet(documentStateCode?: DocumentStates, sharedPropertyCode?: SharedPropertyDTO, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentDTO>>;
    public documentGet(documentStateCode?: DocumentStates, sharedPropertyCode?: SharedPropertyDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentDTO>>>;
    public documentGet(documentStateCode?: DocumentStates, sharedPropertyCode?: SharedPropertyDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentDTO>>>;
    public documentGet(documentStateCode?: DocumentStates, sharedPropertyCode?: SharedPropertyDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {



        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (documentStateCode !== undefined && documentStateCode !== null) {
            queryParameters = queryParameters.set('documentStateCode', <any>documentStateCode);
        }
        if (sharedPropertyCode !== undefined && sharedPropertyCode !== null) {
            queryParameters = queryParameters.set('sharedPropertyCode', <any>sharedPropertyCode);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<DocumentDTO>>('get',`${this.basePath}/document`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param folderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentIdFolderFolderIdPut(id: string, folderId: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentIdFolderFolderIdPut(id: string, folderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentIdFolderFolderIdPut(id: string, folderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentIdFolderFolderIdPut(id: string, folderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling documentIdFolderFolderIdPut.');
        }

        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling documentIdFolderFolderIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentDTO>('put',`${this.basePath}/document/${encodeURIComponent(String(id))}/folder/${encodeURIComponent(String(folderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling documentIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentDTO>('get',`${this.basePath}/document/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentIdRegisterNotEditableDownloadPost(id: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public documentIdRegisterNotEditableDownloadPost(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public documentIdRegisterNotEditableDownloadPost(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public documentIdRegisterNotEditableDownloadPost(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling documentIdRegisterNotEditableDownloadPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/document/${encodeURIComponent(String(id))}/register-not-editable-download`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentIdToCommonRootFolderPut(id: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentIdToCommonRootFolderPut(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentIdToCommonRootFolderPut(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentIdToCommonRootFolderPut(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling documentIdToCommonRootFolderPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentDTO>('put',`${this.basePath}/document/${encodeURIComponent(String(id))}/to-common-root-folder`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentIdToRootFolderPut(id: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentIdToRootFolderPut(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentIdToRootFolderPut(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentIdToRootFolderPut(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling documentIdToRootFolderPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentDTO>('put',`${this.basePath}/document/${encodeURIComponent(String(id))}/to-root-folder`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentPut(body?: DocumentDTO, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentPut(body?: DocumentDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentPut(body?: DocumentDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentPut(body?: DocumentDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentDTO>('put',`${this.basePath}/document`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentSaveAndReviewedLldPendingPut(body?: DocumentDTO, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentSaveAndReviewedLldPendingPut(body?: DocumentDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentSaveAndReviewedLldPendingPut(body?: DocumentDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentSaveAndReviewedLldPendingPut(body?: DocumentDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentDTO>('put',`${this.basePath}/document/save-and-reviewed-lld-pending`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentSaveAndReviewedPut(body?: DocumentDTO, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentSaveAndReviewedPut(body?: DocumentDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentSaveAndReviewedPut(body?: DocumentDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentSaveAndReviewedPut(body?: DocumentDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentDTO>('put',`${this.basePath}/document/save-and-reviewed`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param searchTerm
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    // public documentSearchGet(searchTerm?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentSearchSummaryDto>>;
    // public documentSearchGet(searchTerm?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentSearchSummaryDto>>>;
    // public documentSearchGet(searchTerm?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentSearchSummaryDto>>>;
    // public documentSearchGet(searchTerm?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {
    public documentSearchGet(searchTerm?: string): Observable<any> {

        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchTerm !== undefined && searchTerm !== null) {
            queryParameters = queryParameters.set('searchTerm', <any>searchTerm);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        return this.httpClient.request<Array<DocumentSearchSummaryDto>>('get',`${this.basePath}/document/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers
            }
        );
    }

    /**
     *
     *
     * @param name
     * @param folderOwnerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentSearchInRootNameNameFolderOwnerIdFolderOwnerIdGet(name: string, folderOwnerId: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentSearchInRootNameNameFolderOwnerIdFolderOwnerIdGet(name: string, folderOwnerId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentSearchInRootNameNameFolderOwnerIdFolderOwnerIdGet(name: string, folderOwnerId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentSearchInRootNameNameFolderOwnerIdFolderOwnerIdGet(name: string, folderOwnerId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling documentSearchInRootNameNameFolderOwnerIdFolderOwnerIdGet.');
        }

        if (folderOwnerId === null || folderOwnerId === undefined) {
            throw new Error('Required parameter folderOwnerId was null or undefined when calling documentSearchInRootNameNameFolderOwnerIdFolderOwnerIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentDTO>('get',`${this.basePath}/document/search-in-root/name/${encodeURIComponent(String(name))}/folderOwnerId/${encodeURIComponent(String(folderOwnerId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param name
     * @param folderOwnerId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentSearchInRootNameNameFolderOwnerIdGet(name: string, folderOwnerId?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentSearchInRootNameNameFolderOwnerIdGet(name: string, folderOwnerId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentSearchInRootNameNameFolderOwnerIdGet(name: string, folderOwnerId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentSearchInRootNameNameFolderOwnerIdGet(name: string, folderOwnerId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling documentSearchInRootNameNameFolderOwnerIdGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (folderOwnerId !== undefined && folderOwnerId !== null) {
            queryParameters = queryParameters.set('folderOwnerId', <any>folderOwnerId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentDTO>('get',`${this.basePath}/document/search-in-root/name/${encodeURIComponent(String(name))}/folderOwnerId`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param name
     * @param folderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentSearchNameNameFolderIdFolderIdGet(name: string, folderId: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentSearchNameNameFolderIdFolderIdGet(name: string, folderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentSearchNameNameFolderIdFolderIdGet(name: string, folderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentSearchNameNameFolderIdFolderIdGet(name: string, folderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling documentSearchNameNameFolderIdFolderIdGet.');
        }

        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling documentSearchNameNameFolderIdFolderIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentDTO>('get',`${this.basePath}/document/search/name/${encodeURIComponent(String(name))}/folderId/${encodeURIComponent(String(folderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param name
     * @param folderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentSearchNameNameFolderIdGet(name: string, folderId?: string, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentSearchNameNameFolderIdGet(name: string, folderId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentSearchNameNameFolderIdGet(name: string, folderId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentSearchNameNameFolderIdGet(name: string, folderId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling documentSearchNameNameFolderIdGet.');
        }


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (folderId !== undefined && folderId !== null) {
            queryParameters = queryParameters.set('folderId', <any>folderId);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<DocumentDTO>('get',`${this.basePath}/document/search/name/${encodeURIComponent(String(name))}/folderId`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentSendToSignPut(body?: DocumentSignatureDTO, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentSendToSignPut(body?: DocumentSignatureDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentSendToSignPut(body?: DocumentSignatureDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentSendToSignPut(body?: DocumentSignatureDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentDTO>('put',`${this.basePath}/document/send-to-sign`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentSendToSignSamePeopleDocumentsPut(body?: Array<string>, observe?: 'body', reportProgress?: boolean): Observable<Array<DocumentDTO>>;
    public documentSendToSignSamePeopleDocumentsPut(body?: Array<string>, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<DocumentDTO>>>;
    public documentSendToSignSamePeopleDocumentsPut(body?: Array<string>, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<DocumentDTO>>>;
    public documentSendToSignSamePeopleDocumentsPut(body?: Array<string>, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<Array<DocumentDTO>>('put',`${this.basePath}/document/send-to-sign-same-people-documents`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param body
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentSendToSignSamePeoplePut(body?: DocumentSignatureDTO, observe?: 'body', reportProgress?: boolean): Observable<DocumentDTO>;
    public documentSendToSignSamePeoplePut(body?: DocumentSignatureDTO, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<DocumentDTO>>;
    public documentSendToSignSamePeoplePut(body?: DocumentSignatureDTO, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<DocumentDTO>>;
    public documentSendToSignSamePeoplePut(body?: DocumentSignatureDTO, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json',
            'text/json',
            'application/_*+json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected != undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        return this.httpClient.request<DocumentDTO>('put',`${this.basePath}/document/send-to-sign-same-people`,
            {
                body: body,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentSomeChangesResolvedIdPut(id: string, observe?: 'body', reportProgress?: boolean): Observable<string>;
    public documentSomeChangesResolvedIdPut(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<string>>;
    public documentSomeChangesResolvedIdPut(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<string>>;
    public documentSomeChangesResolvedIdPut(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling documentSomeChangesResolvedIdPut.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<string>('put',`${this.basePath}/document/some-changes-resolved/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     *
     *
     * @param documentId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public documentUsersInHtmlDocumentIdGet(documentId: string, observe?: 'body', reportProgress?: boolean): Observable<Array<UserDTO>>;
    public documentUsersInHtmlDocumentIdGet(documentId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<UserDTO>>>;
    public documentUsersInHtmlDocumentIdGet(documentId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<UserDTO>>>;
    public documentUsersInHtmlDocumentIdGet(documentId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (documentId === null || documentId === undefined) {
            throw new Error('Required parameter documentId was null or undefined when calling documentUsersInHtmlDocumentIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<UserDTO>>('get',`${this.basePath}/document/users-in-html/${encodeURIComponent(String(documentId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
