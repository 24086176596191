import { View, Locale, IconView } from 'ckeditor5';
import { Directive, Inject } from '@angular/core';
import { RowDropdownViewModel } from './row-dropdown-view.model';

@Directive({
    selector: 'app-row-dropdown-view',
})
export default class RowDropdownView extends View {

    private readonly model: RowDropdownViewModel;
    public isOn: boolean = false;

    constructor(
        @Inject(Locale) locale: Locale,
        @Inject(RowDropdownViewModel) rowDropdownViewModel: RowDropdownViewModel
    ) {
        super(locale);

        this.model = rowDropdownViewModel;

        this.setTemplate({
            tag: 'div',
            attributes: {
                class: 'clickable-container'
            },
            children: [
                this.createIconView(),
                {
                    tag: 'div',
                    attributes: {
                        class: 'text-container'
                    },
                    children: [
                        {
                            tag: 'span',
                            attributes: {
                                class: 'title-text'
                            },
                            children: [this.model.titleText]
                        },
                        {
                            tag: 'span',
                            attributes: {
                                class: 'help-text'
                            },
                            children: [this.model.helpText]
                        }
                    ]
                }
            ]
        });

        this.on('render', () => {
            const element = this.element;
            element.addEventListener('click', () => {
                this.fire('rowDropdownClicked', this.model.name);
            });
        });

    }

    private createIconView(): IconView {
        const iconView = new IconView();
        iconView.content = this.model.iconSvg;
        iconView.extendTemplate({
            attributes: {
                class: 'icon-left'
            }
        });
        return iconView;
    }
}
