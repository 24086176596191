import { EventEmitter, Input, Output, Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, FormBuilder } from '@angular/forms';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { emptyAndWhiteSpacesValidator } from 'src/app/core/shared/validators/formValidators/emptyAndWhiteSpacesValidator';
import { MediaQueryService } from 'src/app/core/shared/services/media-query/media-query.service';

@Component({
    selector: 'app-search-box',
    templateUrl: './search-box.component.html',
})
export class SearchBoxComponent implements OnInit {
    constructor(
        private mediaQueryService: MediaQueryService,
        private readonly genericDialogService: GenericDialogService,
        private formBuilder: FormBuilder
    ) { }

    private _searchTerm = '';
    private _notMatchingElements = false;

    public currentScreenSize$ = this.mediaQueryService.getCurrentScreenSize$();

    @Input() showFindButton = false;
    @Input() customClass: string;
    @Input() searchEmptyLikeClearSearch: boolean = false;
    @Input() showSearchNavigator = false;

    @Input()
    public set searchTerm(value: string) {
        this._searchTerm = value;
    }

    public get searchTerm(): string {
        return this._searchTerm;
    }

    @Input()
    public set notMatchingElements(value: boolean) {
        this._notMatchingElements = value;
    }

    public get notMatchingElements(): boolean {
        return this._notMatchingElements;
    }

    @Input() indexResultOrdered = 0;
    @Input() totalResultOrdered = 0;

    private runSearch = false;

    @Output() doSearch = new EventEmitter<string>();
    @Output() clearSearch = new EventEmitter<void>();
    @Output() searchPreviousResult = new EventEmitter<void>();
    @Output() searchNextResult = new EventEmitter<void>();

    public searchForm: UntypedFormGroup;
    public existsErrors = false;

    public ngOnInit(): void {
        this.initFormGroup();
    }

    public onEnterKey(event: KeyboardEvent): void {
        if (event.key === 'Enter') {
            event.preventDefault();
            this.performSearch();
        }
    }

    public onSearchUpdated(value: string): void {
        this.searchTerm = value;
        this.showClearButton();
    }

    public showPreviousNextButton(): boolean {
        return this.searchTerm !== '' && this.showSearchNavigator && this.runSearch;
    }

    public showClearButton(): boolean {
        return this.searchTerm !== '';
    }

    public onPreviousResultButtonClick(): void {
        this.searchPreviousResult.emit();
    }

    public onNextResultButtonClick(): void {
        this.searchNextResult.emit();
    }

    public onClearButtonClick(): void {
        this.runSearch = false;
        this.searchTerm = '';
        this.searchForm.controls.searchTermInput.setValue(this.searchTerm);
        this.searchForm.controls.searchTermInput.markAsPristine();
        this.searchForm.markAsUntouched();
        this.clearSearch.emit();
    }

    public onSearchButtonClick() {
        this.notMatchingElements = false;
        this.performSearch();
    }

    private performSearch(): void {
        this.runSearch = true;
        this.searchForm.updateValueAndValidity();
        this.existsErrors = this.searchForm.invalid || this.isEmptyOrWhiteSpace();
        if (!this.existsErrors) {
            this.doSearch.emit(this.searchForm.controls.searchTermInput.value);
        } else {

            if (this.searchForm.get('searchTermInput').errors?.onlyWhitespaces || this.isEmptyOrWhiteSpace()) {
                const message = $localize`:@@ErrorBusquedaVaciaMensaje:Introduce un término de búsqueda`;
                this.genericDialogService.showMessage(message);
                return;
            }

            if (this.searchForm.get('searchTermInput').errors?.maxlength)
            {
                const message = $localize`:@@ErrorBusquedaLength:La búsqueda no puede superar los 200 caracteres.`;
                this.genericDialogService.showMessage(message);
                return;
            }

            if (this.searchEmptyLikeClearSearch) {
                const message = $localize`:@@ErrorBusquedaVaciaMensaje:Introduce un término de búsqueda`;
                this.genericDialogService.showMessage(message);
                this.clearSearch.emit();
                return;
            }
        }

        this.searchForm.controls.searchTermInput.markAsPristine();
    }

    private initFormGroup(): void {
        this.searchForm = this.formBuilder.group({
            searchTermInput: [
                '',
                [Validators.maxLength(200),
                emptyAndWhiteSpacesValidator],
            ],
        });

        this.searchForm.controls.searchTermInput.setValue(this.searchTerm);
        this.searchForm.controls.searchTermInput.markAsPristine();
    }

    protected isEmptyOrWhiteSpace(): boolean {
        return this.searchForm.controls.searchTermInput.value.trim() === '';
    }
}
