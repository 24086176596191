import { Injectable } from '@angular/core';

declare let CKEDITOR: any;

@Injectable({
  providedIn: 'root'
})
export class CkeditorFormsTextAreaService {


  configureTextArea() {
    if(CKEDITOR.dialog.exists('textarea')){
      return;
    }

    CKEDITOR.dialog.add( 'textarea', ( editor ) => {

      editor.on( 'doubleclick', (evt) => {
        const element = evt.data.element;
        editor.getSelection().selectElement( element );
      });


      return {
        title: editor.lang.forms.textarea.title,
        minWidth: 350,
        minHeight: 220,
        getModel( editorModel ) {
          const element = editorModel.getSelection().getSelectedElement();

          if ( element && element.getName() === 'textarea' ) {
            return element;
          }

          return null;
        },
        onShow() {
          const element = this.getModel( this.getParentEditor() );

          if ( element ) {
            this.setupContent( element );
          }
        },
        onOk() {
          const editorOnOk = this.getParentEditor();
          let  element = this.getModel( editorOnOk );
          const  isInsertMode = this.getMode( editorOnOk ) === CKEDITOR.dialog.CREATION_MODE;

          if ( isInsertMode ) {
            element = editorOnOk.document.createElement( 'textarea' );
            element.$.addEventListener('mousedown', (event) => {
              element.$.setAttribute('contenteditable', 'true');

              event.stopPropagation();

            }, true);
            element.$.addEventListener('change', (event) => {
              const text = element.$.value;
              // element.setAttribute('value', text);
              element.$.innerText  = text;
              element.$.innerHTML  = text;
            }, true);

          }

          this.commitContent( element );

          if ( isInsertMode ) {
            editor.insertElement( element );
          }
        },
        contents: [ {
          id: 'info',
          label: editor.lang.forms.textarea.title,
          title: editor.lang.forms.textarea.title,
          elements: [
          {
            type: 'hbox',
            widths: [ '50%', '50%' ],
            children: [ {
              id: 'cols',
              type: 'text',
              label: editor.lang.forms.textarea.cols,
              default: '',
              accessKey: 'C',
              style: 'width:50px',
              validate: CKEDITOR.dialog.validate.integer( editor.lang.common.validateNumberFailed ),
              setup( element ) {
                const value = element.hasAttribute( 'cols' ) && element.getAttribute( 'cols' );
                this.setValue( value || '' );
              },
              commit( element ) {
                if ( this.getValue() ) {
                  element.setAttribute( 'cols', this.getValue() );
                } else {
                  element.removeAttribute( 'cols' );
                }
              }
            },
            {
              id: 'rows',
              type: 'text',
              label: editor.lang.forms.textarea.rows,
              default: '',
              accessKey: 'R',
              style: 'width:50px',
              validate: CKEDITOR.dialog.validate.integer( editor.lang.common.validateNumberFailed ),
              setup( element ) {
                const value = element.hasAttribute( 'rows' ) && element.getAttribute( 'rows' );
                this.setValue( value || '' );
              },
              commit( element ) {
                if ( this.getValue() ) {
                  element.setAttribute( 'rows', this.getValue() );
                } else {
                  element.removeAttribute( 'rows' );
                }
              }
            } ]
          },
          {
            id: 'value',
            type: 'textarea',
            label: editor.lang.forms.textfield.value,
            default: '',
            setup( element ) {
              this.setValue( element.$.defaultValue );
            },
            commit( element ) {
              element.$.value = element.$.defaultValue = this.getValue();
            }
          },
            {
            id: 'required',
            type: 'checkbox',
            label: editor.lang.forms.textfield.required,
            default: '',
            accessKey: 'Q',
            value: 'required',
            setup: CKEDITOR.plugins.forms._setupRequiredAttribute,
            commit( element ) {
              if ( this.getValue() ) {
                element.setAttribute( 'required', 'required' );
              } else {
                element.removeAttribute( 'required' );
              }
            }
          } ]
        } ]
      };
    } );
  }

}
