import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ITemplatesConvertService } from './templates-convert.service.interface';

@Injectable({
    providedIn: 'root'
})
export class TemplatesConvertService extends ITemplatesConvertService {

    private templateName = new BehaviorSubject<string>('');
    private templateContent = new BehaviorSubject<string>('');
    private fileExtension = new BehaviorSubject<string>('');
    private file = new BehaviorSubject<File>(null);

    public updateTemplateName(templateName: string) {
        this.templateName.next(templateName);
    }

    public getCurrentTemplateName(): string {
        return this.templateName.getValue();
    }

    public updateTemplateContent(templateContent: string) {
        this.templateContent.next(templateContent);
    }

    public getCurrentTemplateContent(): string {
        return this.templateContent.getValue();
    }

    public getNotEditableFile(): File {
        return this.file.getValue();
    }

    public updateNotEditableFile(notEditableFile: File) {
        this.file.next(notEditableFile);
    }

    public updateTemplateFileExtension(extension: string) {
        return this.fileExtension.next(extension);
    }

    public getNotEditableFileExtension(): string {
        return this.fileExtension.getValue();
    }
}
