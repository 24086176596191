import { Injectable } from '@angular/core';
import { Editor } from 'ckeditor5';
import { SignaturePlugin } from '../../plugins/signature/signature-plugin';
import { GlobalConstant } from '../../models/base/global-constant';

@Injectable({
    providedIn: 'root'
})
export class SignatureDataViewToModelConverterService {

    public configureConverters(editor: Editor) {
        editor.conversion.for("upcast").elementToElement({
            view: {
                name: "div",
                classes: [SignaturePlugin.SIGNATURE_CLASS],
            },
            model: (viewElement, { writer: modelWriter }) => {
                const id = viewElement.getAttribute(SignaturePlugin.ID);
                const role = viewElement.getAttribute(SignaturePlugin.ATTRIBUTE_ROLE_IS_STORED);
                const embeddedIn = viewElement.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN);

                return modelWriter.createElement(SignaturePlugin.MODEL_ENTITIES.class.dataView,
                    {
                        'id': id,
                        'signature-title': role,
                        'data-embedded-in': embeddedIn
                    });
            },
        });
    }
}
