import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Configuration } from '../configuration';
import { CompareVersionIdsDTO } from '../model/compareVersionIdsDTO';
import { IJsonPatchDocument } from '../model/json-patch.model';
import { ITemplateRequestModel } from '../model/template-request.model';
import { TemplateDTO } from '../model/templateDTO';
import { ApiProcessorService, ApiRequest, ApiOperation } from './api-processor.service';
import { RequestQueryParamsService } from './requestQueryParams.service';
import { ExportResultFormat, FilesExportDto } from '../model/filesExportDto';
import { FilesDownloadDto } from '../model/filesDownloadDto';

@Injectable()
export class ManagerTemplatesService {

    protected basePath = '/api/manager-management/templates';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(
        private readonly requestQueryParamsService: RequestQueryParamsService,
        private readonly apiProcessorService: ApiProcessorService) {
    }

    public get(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl =  `${ this.basePath }/${id}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public getTemplates(templateRequestModel: ITemplateRequestModel): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        const queryParams = this.requestQueryParamsService.getQueryParamsByRequest(templateRequestModel);
        apiRequest.endpointUrl =  `${ this.basePath }?${queryParams}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public patch(id: string, jsonPatchDocument: Array<IJsonPatchDocument>): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PATCH;
        apiRequest.endpointUrl = `${this.basePath}/${id}`;
        apiRequest.body = jsonPatchDocument;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public post(template: TemplateDTO): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = template;
        apiRequest.endpointUrl = this.basePath;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public validate( file: File): Observable<any> {

        const filesFormData: FormData = new FormData();
        filesFormData.append('file', file, file.name);

        const url = `${this.basePath}/validate`;

        return this.apiProcessorService.postActionAsFormData(filesFormData, url);
    }

    public upload( file: File): Observable<any> {

        const filesFormData: FormData = new FormData();
        filesFormData.append('file', file, file.name);

        const url = `${this.basePath}/send-to-update`;

        return this.apiProcessorService.postActionAsFormData(filesFormData, url);
    }

    public uploadNewVersion( file: File): Observable<any> {

        const filesFormData: FormData = new FormData();
        filesFormData.append('file', file, file.name);

        const url = `${this.basePath}/send-to-update-new-version`;

        return this.apiProcessorService.postActionAsFormData(filesFormData, url);
    }

    public put(template: TemplateDTO): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PUT;
        apiRequest.body = template;
        apiRequest.endpointUrl = this.basePath;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public deleteTemplate(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl =  `${ this.basePath }/${id}`;
        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public delete(ids: string[]): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = JSON.stringify(ids);
        apiRequest.endpointUrl = `${ this.basePath }/bulk-delete`;
        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public compareHtmlVersions(templateId: string, compareVersionIdsDTO: CompareVersionIdsDTO): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = compareVersionIdsDTO;
        apiRequest.endpointUrl =  `${ this.basePath }/${templateId}/compare-versions`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public generateExportFile(fileExportDto: FilesExportDto): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${this.basePath}/export`;
        apiRequest.body = fileExportDto;

        if (fileExportDto.exportSettings.exportResultFormat === ExportResultFormat.HierarchyFolderZip) {
            apiRequest.responseType = 'blob';
        }

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public generateWordExportFile(folderId: string, fileDownloadDto: FilesDownloadDto): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${this.basePath}/${folderId}/download-files-to-update`;
        apiRequest.body = fileDownloadDto;
        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
