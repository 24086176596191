import { EventEmitter, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { FolderItemInformationDto, OperationType, TemplateFolderDto } from 'src/app/api';
import { TemplateFolderDTOOperation } from 'src/app/api/model/templateFolderDTOOperation';
import { ITemplatesFolderTreeService } from '../templates-folder-tree/template-folder-tree/templates-folder-tree.service.interface';
import { ITemplatesFolderService } from './templates-folder.service.interface';

@Injectable({
    providedIn: 'root'
})
export class TemplatesFolderService extends ITemplatesFolderService {

    private currentFolderName = new BehaviorSubject<string>('');
    private currentFolderId = new BehaviorSubject<string>('');
    private onTemplateFolderNeedToBeReload$ = new EventEmitter<void>();
    private onTemplatesListObsolete$ = new EventEmitter<void>();

    constructor(@Inject('ITemplatesFolderTreeService') private templateFolderTreeService: ITemplatesFolderTreeService) {
        super();
    }

    public setCurrentFolderName(folderName: string): void {
        return this.currentFolderName.next(folderName);
    }

    public getCurrentFolderName(): string {
        return this.getCorrectedFolderName(this.currentFolderName.getValue());
    }

    public getCorrectedFolderName(originalFolderName: string): string {
        if (originalFolderName === '' || originalFolderName === null) {
            return this.templateFolderTreeService.getRootNodeName();
        }

        return originalFolderName;
    }

    public setCurrentFolderId(folderId: string): void {
        return this.currentFolderId.next(folderId);
    }

    public getCurrentFolderId(): Observable<string> {
        return this.currentFolderId.asObservable();
    }

    public getCurrentFolderIdValue(): string {
        return this.currentFolderId.getValue();
    }

    public isCurrentFolderValid(nodeId: string): boolean {
        return nodeId && nodeId !== this.templateFolderTreeService.getRootNodeId();
    }

    public getTemplateFoldersNeedToBeReloadSubscription(): Observable<void> {
        return this.onTemplateFolderNeedToBeReload$.asObservable();
    }

    public notifyTemplateFoldersNeedToBeReload(): void {
        this.onTemplateFolderNeedToBeReload$.emit();
    }

    public getTemplatesListNeedToBeReloadSubscription(): Observable<void> {
        return this.onTemplatesListObsolete$.asObservable();
    }

    public notifyTemplatesListNeedToBeReload(): void {
        this.onTemplatesListObsolete$.emit();
    }

    public getOperationReplaceTemplateFolder(valueToChange: string, newValue: any): TemplateFolderDTOOperation[] {
        const operations: TemplateFolderDTOOperation[] = [];
        const operation: TemplateFolderDTOOperation = {
            operationType: OperationType.NUMBER_0,
            op: 'replace',
            path: valueToChange,
            value: newValue
        };

        operations.push(operation);
        return operations;
    }

    public getFileIdsInFolderRecusively(folderId: string, folders: TemplateFolderDto[]): FolderItemInformationDto[] {
        const content = folders.find(folder => folder.id === folderId)?.content;
        const fileIds: FolderItemInformationDto[] = [];
        if (content) {
            fileIds.push(...folders.find(folder => folder.id === folderId).content);
        }

        const childrenFolders = folders.filter( folder => folder.templateFolderParentId === folderId);
        childrenFolders.forEach(  (childFolder: TemplateFolderDto) => {
            fileIds.push(...this.getFileIdsInFolderRecusively(childFolder.id, folders));
        });

        return fileIds;
    }
}
