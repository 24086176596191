import { SelectionModel } from '@angular/cdk/collections';
import { Injectable } from '@angular/core';
import { DocumentFolderDto, UserFolderPermissionsDto } from 'src/app/api';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { NodeTreeNodeType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-node-type.enum';
import { SortAlphabetical } from 'src/app/shared/pipes/sort-alphabetical.pipe';
import { IDocumentsFolderTreeService } from './documents-folder-tree.service.interface';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class DocumentsFolderTreeService extends IDocumentsFolderTreeService {

    private currentExpansionModel = new BehaviorSubject<SelectionModel<string>>(new SelectionModel<string>(true));
    private pipe = new SortAlphabetical();

    private myDocumentsNodeName =  $localize`:@@DocumentoCarpetaRaizMisDocumentosNombre:Mis documentos`;
    private ourDocumentsNodeName =  $localize`:@@DocumentoCarpetaRaizNuestrosDocumentosNombre:Nuestros documentos`;
    private sharedNodeName =  $localize`:@@DocumentoCarpetaRaizCompartidosNombre:Compartidos`;

    private myDocumentsNodeId = '0';
    private ourDocumentsNodeId = '1';
    private sharedNodeId = '2';

    constructor() {
        super();
    }

    public getMyDocumentsNodeId(): string {
        return this.myDocumentsNodeId;
    }

    public getMyDocumentsNodeName(): string {
        return this.myDocumentsNodeName;
    }

    public getOurDocumentsNodeName(): string {
        return this.ourDocumentsNodeName;
    }

    public getOurDocumentsNodeId(): string {
        return this.ourDocumentsNodeId;
    }

    public getSharedNodeId(): string {
        return this.sharedNodeId;
    }

    public getSharedNodeName(): string {
        return this.sharedNodeName;
    }

    public setCurrentExpansionModel(selectionModel: SelectionModel<string>): void {
        this.currentExpansionModel.next(selectionModel);
    }

    public getCurrentExpansionModel(): Observable<SelectionModel<string>> {
        return this.currentExpansionModel.asObservable();
    }

    public createMyDocumentsNode(myDocuments: DocumentFolderDto[], rootNodeActions: NodeTreeActionType[],
                                 nodeActions: NodeTreeActionType[], userId: string): FileNode {

        const additionalData = userId;
        const myDocumentsRootNode: FileNode = this.createRootNode(this.myDocumentsNodeId, this.myDocumentsNodeName,
            rootNodeActions, NodeTreeNodeType.MyDocuments, additionalData);

        myDocuments.forEach(folderDTO => {
            const childNode = this.transformFolderToNode(folderDTO, nodeActions);
            myDocumentsRootNode.children.push(childNode);
        });

        myDocumentsRootNode.children = this.pipe.transform(myDocumentsRootNode.children, 'value');

        return myDocumentsRootNode;
    }

    public createOurDocumentsNode(ourDocuments: DocumentFolderDto[], rootNodeActions: NodeTreeActionType[],
                                  nodeActions: NodeTreeActionType[]): FileNode {

        const ourDocumentsRootNode: FileNode = this.createRootNode(this.ourDocumentsNodeId, this.ourDocumentsNodeName,
            rootNodeActions, NodeTreeNodeType.OurDocuments);

        ourDocuments.forEach(folderDTO => {
            const childNode = this.transformFolderToNode(folderDTO, nodeActions);
            ourDocumentsRootNode.children.push(childNode);
        });

        ourDocumentsRootNode.children = this.pipe.transform(ourDocumentsRootNode.children, 'value');

        return ourDocumentsRootNode;
    }

    public createCompleteTree(myDocuments: FileNode, ourDocuments: FileNode, sharedNodeActions: NodeTreeActionType[]): FileNode[] {

        const tree: FileNode[] = [];

        if (myDocuments) {
            tree.push(myDocuments);
        }

        if (ourDocuments) {
            tree.push(ourDocuments);
        }

        if (sharedNodeActions.length > 0)
        {
            const sharedRootNode: FileNode = this.createRootNode(this.sharedNodeId, this.sharedNodeName,
                sharedNodeActions, NodeTreeNodeType.Shared);

            tree.push(sharedRootNode);
        }

        return tree;
    }

    private createRootNode(nodeId: string, nodeName: string, nodeActions: NodeTreeActionType[], nodeType: NodeTreeNodeType,
                           additionalData?: any): FileNode {
        return {
            id: nodeId,
            value: nodeName,
            children: [],
            type: nodeType,
            parentId: '',
            actions: nodeActions,
            isChecked: true,
            additionalData
        };
    }

    private transformFolderToNode(folder: DocumentFolderDto, nodeActions: NodeTreeActionType[]): FileNode {
        const node: FileNode = {
            id: folder.id,
            value: folder.name,
            type: NodeTreeNodeType.Folder,
            children: [],
            parentId: folder.documentFolderParentId,
            actions:  this.getAvailableActions(folder.userPermissions, nodeActions),
            isChecked: true,
            additionalData: folder.ownerId
        };

        folder.childrenFolder.forEach(childFolder => {
            const child = this.transformFolderToNode(childFolder, nodeActions);
            node.children.push(child);
        });
        node.children = this.pipe.transform(node.children, 'value');
        return node;
    }

    private getAvailableActions(permissions: UserFolderPermissionsDto,
                                availableActionsForNode: NodeTreeActionType[]): NodeTreeActionType[] {
        const actions: NodeTreeActionType[] = [];
        if (permissions.canCreateChildren && availableActionsForNode.includes(NodeTreeActionType.Create)) {
            actions.push(NodeTreeActionType.Create);
        }
        if (permissions.canDelete  && availableActionsForNode.includes(NodeTreeActionType.Delete)) {
            actions.push(NodeTreeActionType.Delete);
        }
        if (permissions.canEditName  && availableActionsForNode.includes(NodeTreeActionType.Rename)) {
            actions.push(NodeTreeActionType.Rename);
        }
        if (permissions.canSeeContent  && availableActionsForNode.includes(NodeTreeActionType.Selected)) {
            actions.push(NodeTreeActionType.Selected);
        }
        if (permissions.canMoveFrom  && availableActionsForNode.includes(NodeTreeActionType.Move)) {
            actions.push(NodeTreeActionType.Move);
        }
        if (permissions.canMoveTo  && availableActionsForNode.includes(NodeTreeActionType.MoveTo)) {
            actions.push(NodeTreeActionType.MoveTo);
        }
        if (permissions.canCreateContent  && availableActionsForNode.includes(NodeTreeActionType.CanCreateContent)) {
            actions.push(NodeTreeActionType.CanCreateContent);
        }
        if (permissions.canSeeContent  && availableActionsForNode.includes(NodeTreeActionType.CanSeeContent)) {
            actions.push(NodeTreeActionType.CanSeeContent);
        }

        return actions;
    }
}
