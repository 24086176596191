import { Injectable } from '@angular/core';
import { TemplateFolderDto } from 'src/app/api';

@Injectable()
export class TemplateFolderStorage {
    private folders: TemplateFolderDto[];

    public setFolders(folders: TemplateFolderDto[]): void {
        this.folders = folders;
    }

    public getTemplateFolders() {
        return this.folders;
    }
}
