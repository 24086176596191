import { Directive, EventEmitter, OnDestroy } from "@angular/core";
import { Plugin, Editor, ContextualBalloon, ButtonView, Locale, viewToModelPositionOutsideModelElement, Schema, Conversion, ClickObserver, ViewElement } from "ckeditor5";
import { PluginUtilsService } from "../../../utils/plugin-utils.service";
import { ToolbarButtonModel } from "../../models/base/toolbar-button-model";
import DoubleClickObserver from "../../../utils/double-click-observer";

type ObserverType = typeof ClickObserver | typeof DoubleClickObserver;

@Directive()
export abstract class BasePlugin extends Plugin implements OnDestroy {
    public static contextEditor: string;

    private onDestroy$ = new EventEmitter<void>();
    protected balloon!: ContextualBalloon;
    protected pluginUtils: PluginUtilsService;
    protected schema: Schema;
    protected conversion: Conversion;
    protected button: ButtonView;

    protected abstract commands: { [key: string]: any } ;
    protected abstract mappers: string[] ;
    protected abstract toolbarButton: ToolbarButtonModel;

    constructor(editor: Editor) {
        super(editor);
        this.pluginUtils = new PluginUtilsService();
        this.schema = editor.model.schema;
        this.conversion = editor.conversion
    }

    protected abstract defineSchema(): void;
    protected abstract defineConverters(): void
    protected abstract editorInteractions(): void;

    public init(): void {
        this.defineSchema();
        this.defineConverters();
        this.defineCommands();
        this.defineMapper();
        this.defineUI();
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    protected toolbarExecuteOperation() {};

    protected defineCommands(): void {
        Object.entries(this.commands).forEach(([commandName, CommandClass]) => {
            this.editor.commands.add(commandName, new CommandClass(this.editor));
        });
    }

    protected defineMapper(): void {
        this.mappers.forEach(mapperClass => {
            this.editor.editing.mapper.on(
                'viewToModelPosition',
                viewToModelPositionOutsideModelElement(this.editor.model, (viewElement: ViewElement) =>!!viewElement && viewElement.hasClass(mapperClass))
            );
        });
    }

    protected defineUI(): void {
        this.editorInteractions();
        this.toolbarInteractions(this.toolbarButton);
    }

    protected setupEditorObserver(observer: ObserverType): void {
        this.editor.editing.view.addObserver(observer);
        this.balloon = this.editor.plugins.get(ContextualBalloon);
    }

    protected toolbarInteractions(toolbarButton: ToolbarButtonModel ): void {
        this.editor.ui.componentFactory.add(toolbarButton.pluginToolbarElementName, (locale: Locale) => {
            return  this.button =  this.createButtonToolbar(locale, toolbarButton);
        });
    }

    protected getSelectionParent(): ViewElement | undefined {
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;

        return viewDocument.selection.focus!.getAncestors()
            .reverse()
            .find((node: ViewElement): node is ViewElement => node.is('element'));
    }

    private createButtonToolbar(locale: Locale, toolbarButton: ToolbarButtonModel): ButtonView
    {
        const button = new ButtonView(locale);

        button.set({
            label: toolbarButton.tooltip,
            tooltip: toolbarButton.hasTooltip,
            withText: toolbarButton.hasText
        });

        if(!!toolbarButton.icon) {
            button.icon = toolbarButton.icon;
        }

        button.labelView.text = toolbarButton.buttonText;
        button.on("execute", () => { this.toolbarExecuteOperation(); });
        return button;
    }
}
