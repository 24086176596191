import { SelectionModel } from '@angular/cdk/collections';
import { KeyValue } from '@angular/common';
import { Directive, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatSort } from '@angular/material/sort';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { BehaviorSubject } from 'rxjs';
import { cloneDeep } from 'lodash';
import { CtboxSearchListComponent } from '../ctbox-search-list/ctbox-search-list.component';
import { IPageData, IRequest, ISelectedButton, IUserPreferences } from './models';

@Directive()
export abstract class CtboxGenericListComponentDirective<T> {
    public selectedItems: ISelectedButton[] = [];
    public isAnyItemListSelected = false;
    public selection = new SelectionModel<T>(true, []);
    public dataTable: MatTableDataSource<T> = null;
    public pageData: IPageData;
    public hiddenGearEntries: Array<string> = [];
    public isExporting = false;
    public isLoading = false;
    public abstract dialog: MatDialog;
    public abstract idProperty: string;
    public userPreferencesName = '';
    public visibleFields: { [key: string]: {value: boolean, exportExcel: boolean, valueName: string, widthColum?: number, groupColumn?: string }; };
    public columnAnimation: string;

    public selectedRowChange: BehaviorSubject<any> = new BehaviorSubject([]);

    public notesGroupedByParentId: {};
    
    protected abstract currentRequest: IRequest;
    protected currentPage = 1;
    protected userPreferences: IUserPreferences;
    protected EMPTY_GUID = '00000000-0000-0000-0000-000000000000';
    protected paginator: MatPaginator;

    protected filterDataByCustomFilters: (data: T) => boolean;

    protected isElementDisabled: (data: T) => boolean;

    protected pageSize: number;
    protected PAGE_SIZE_DEFAULT = 25;

    @ViewChild(MatPaginator, { static: false }) set newPaginator(value: MatPaginator) {
        if (value) {
            this.paginator = value;
            this.paginator.pageSize = this.pageSize;
        }
    }

    @ViewChild(MatSort, { static: false }) set sort(newSort) {
        if (newSort && this.dataTable && !Object.is(this.dataTable.sort, newSort)) {
            this.dataTable.sort = newSort;
        }
    }

    @ViewChild(CtboxSearchListComponent, { static: false }) public searchList: CtboxSearchListComponent<T>;

    constructor() {
        this.pageSize = this.PAGE_SIZE_DEFAULT;
    }

    public isAllSelected(): any {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataTable.data.length;
        return numSelected === numRows;
    }

    public resetSearchValue(): void {
        this.searchList.clearSearchField();
    }

    public masterToggle($event: any): void {
        this.selectedItems.length = 0;
        if ($event.checked) {
            this.dataTable.filteredData.forEach((element: T, index: number) => {
                if (element[this.idProperty] && !this.checkIsElementDisabled(element)) {
                    this.selectedItems.push({ id: element[this.idProperty], index: index });
                    this.selection.select(element);
                }
            });
            this.isAnyItemListSelected = this.selectedItems.length > 0;
        } else {
            this.selection.clear();
            this.isAnyItemListSelected = false;
        }
        this.selectedRowChange.next($event.checked);
    }

    public statusRow(row?: any): string {
        
        if (!row) {
            return `${this.isAllSelected() ? 'deselect' : 'selected'}`;
        }
        else if (row.recognized === true) {
            return `${this.selection.isSelected(row) ? 'selected recognized' : 'deselect recognized'}`;
        }
        else if (row.recognized === false) {
            return `${this.selection.isSelected(row) ? 'selected unrecognized' : 'deselect unrecognized'}`;
        } 

        return `${this.selection.isSelected(row) ? 'selected' : 'deselect'}`;
    }

    public selectRow($event: any, selection: SelectionModel<any>, item: any, i: number): void {
        if ($event.checked) {
            this.selectedItems.push({ id: item.id, index: i });
        }
        else {
            const indexToDelete = this.selectedItems.findIndex(
                x => x.id === item.id &&
                    x.index === i
            );

            this.selectedItems.splice(indexToDelete, 1);
        }
        this.isAnyItemListSelected = this.selectedItems.length > 0;
        selection.toggle(item);
        this.selectedRowChange.next($event.checked);
    }

    public changeVisibleField(name: string, event: Event): void {
        if (this.visibleFields[name].value) {
            this.columnAnimation = name;
        }

        this.visibleFields = cloneDeep(this.visibleFields);
        this.changeAnimationClass(name);
        this.dataTable._updateChangeSubscription();
    }

    public columnAnimationClass(name: string): any {
        if (!this.visibleFields[name].value) {
            return 'hidden';
        } else if (this.columnAnimation !== undefined && this.columnAnimation === name) {
            return 'visibleAnimation';
        }
    }

    public changeAnimationClass(name: string): void | any {
        if (!this.visibleFields[name].value) {
            return 'hidden';
        } else {
            return 'visibleAnimation';
        }
    }

    public getGroupColumn(groupColumn: string): any {
        let colspan = 0;
        for (const key in this.visibleFields) {
            if (Object.prototype.hasOwnProperty.call(this.visibleFields, key)) {
                const element = this.visibleFields[key];
                if (element.groupColumn === groupColumn && element.value) {
                    colspan++;
                }
            }
        }
        return colspan;
    }

    public stopClickPropagation(event: Event): void {
        event.stopPropagation();
    }

    public initializeVisibleFields(): void {
        this.userPreferences = {
            id: this.EMPTY_GUID,
            listCode: this.userPreferencesName,
            preferences: undefined
        };

        const expectedPreferences = this.getUserPreferencesValues();
        const userPreferencesFromStorage = sessionStorage.getItem(this.userPreferencesName);
        if (userPreferencesFromStorage === null || this.hasPreferencesStructureChanged(expectedPreferences, JSON.parse(userPreferencesFromStorage))) {
            this.getUserPreferences(expectedPreferences).then(() =>
                this.saveUserPreferencesSessionStorage()
            );
        } else {
            this.userPreferences = JSON.parse(userPreferencesFromStorage);
            this.visibleFields = JSON.parse(this.userPreferences.preferences).visibleFields;
            this.pageSize = JSON.parse(this.userPreferences.preferences).pageSize;
        }
    }

    public updateUserPreferences(): void {
        this.pageSize = this.paginator ? this.paginator.pageSize : this.PAGE_SIZE_DEFAULT;
        this.userPreferences.preferences = JSON.stringify(this.getUserPreferencesValues());
        this.saveUserPreferencesSessionStorage();
    }

    public updatePageSize(): void {
        this.pageSize = this.paginator ? this.paginator.pageSize : this.PAGE_SIZE_DEFAULT;
    }

    public filterHasValues(): boolean {
        const filter = JSON.parse(this.dataTable.filter);
        for (const key in filter) {
            if (filter[key] && Object.keys(filter[key]).length > 0) {
                return true;
            }
        }

        return false;
    }

    public loadSelectedPage(selectedPage: number): void {
        if (this.currentPage !== selectedPage) {
            const range = this.currentRequest.range.split('-').map(val => Number.parseInt(val, 10));
            const lowerRange = range[0];
            const upperRange = range[1];
            const difference = upperRange - lowerRange + 1;

            this.currentPage = selectedPage;
            this.currentRequest.range = `${(difference) * (selectedPage - 1)}-${((difference) * (selectedPage)) - 1}`;
            this.reloadTableContent();
        }
    }

    public changeElementsPerPage(amount: number): void {
        this.currentPage = 1;
        this.currentRequest.range = `0-${amount - 1}`;
        this.reloadTableContent();
    }

    public tableSearchFilterPredicate(): (data: any, filter: string) => boolean {
        return (data: any, filter: string): boolean => {
            let filterListResult = true;
          
            let searchResult = true;
            if (this.searchList) {
                searchResult = this.searchList.getFilterPredicate(data, filter);
            }

            let filterDataByCustomFilters = true;
            if (this.filterDataByCustomFilters !== null && typeof (this.filterDataByCustomFilters) !== 'undefined') {
                filterDataByCustomFilters = this.filterDataByCustomFilters(data);
            }
          
            return filterListResult && searchResult && filterDataByCustomFilters;
        };
    }

    public hasElementsCustomSearch(): (searchTerm: Array<any>, dataObj: any, key: string) => boolean {
        return (searchTerm: Array<any>, dataObj: any, key: string): boolean => {
            if (!searchTerm || searchTerm.length === 0) {
                return true;
            }
            return searchTerm.some(x => x === (dataObj[key] && dataObj[key].length > 0));
        };
    }

    public originalOrder = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    }

    public isKeyVisible(key: string): boolean {
        if (typeof (key) === 'undefined' || key === null || key === '') {
            return false;
        }

        return this.hiddenGearEntries.findIndex(val => val === key) === -1;
    }

    public abstract deleteSelection(): void;
    protected abstract reloadTableContent(): void;
    protected abstract parseValueToExportExcel(column: string, data: T): number | string;

    protected initializeRequestTracker(): void {
        this.currentRequest = {};
        this.currentRequest.requestAll = true;
    }

    protected saveUserPreferencesSessionStorage(): void {
        sessionStorage.setItem(this.userPreferencesName, JSON.stringify(this.userPreferences));
    }

    protected formatDate(date: Date): string {
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();
        const year = date.getFullYear();

        if (month.length < 2) {
            month = '0' + month;
        }

        if (day.length < 2) {
            day = '0' + day;
        }

        return [day, month, year].join('/');
    }

    private async getUserPreferences(expectedPreferences: any): Promise<void> {
        this.pageSize = this.PAGE_SIZE_DEFAULT;
        this.userPreferences.preferences = JSON.stringify(expectedPreferences);
        return Promise.resolve();
    }

    private getElementsToExportExcel(): { column: string, columnName: string, width: number }[] {
        const values = [];
        for (const key in this.visibleFields) {
            if (Object.prototype.hasOwnProperty.call(this.visibleFields, key)) {
                const element = this.visibleFields[key];
                if (element.value && element.exportExcel) {
                    const value = { column: key, columnName: element.valueName, width: element.widthColum };
                    values.push(value);
                }
            }
        }

        return values;
    }

    private getArrayDataToExportExcel(): any[] {
        const filter = (data: T) => {
            const columnsToExportExcel = this.getElementsToExportExcel().map(x => x.column);
            const values = [];
            columnsToExportExcel.forEach((column: string) => {
                values.push(this.parseValueToExportExcel(column, data));
            });

            return values;
        };

        return this.dataTable.filteredData.map(filter);
    }

    private updateVisibleFields(newVisibleFields: { [key: string]: any }): void {
        for (const key in newVisibleFields) {
            const visibleFieldAttributes = this.visibleFields[key];
            if (visibleFieldAttributes) {
                for (const attribute in newVisibleFields[key]) {
                    if (this.visibleFields[key].hasOwnProperty(attribute)) {
                        this.visibleFields[key][attribute] = newVisibleFields[key][attribute];
                    }
                }
            }
        }
    }

    private getUserPreferencesValues(): any {
        const preferences = {} as any;
        preferences.visibleFields = this.visibleFields;
        preferences.pageSize = this.pageSize;
        return preferences;
    }

    private hasPreferencesStructureChanged(expectedPreferences: any, givenPreferences: IUserPreferences): boolean {
        const expectedKeys = Object.keys(expectedPreferences.visibleFields);
        const givenKeys = Object.keys(JSON.parse(givenPreferences.preferences).visibleFields);
        return expectedKeys.some(x => !givenKeys.includes(x)) ||
            givenKeys.some(x => !expectedKeys.includes(x));
    }

    private checkIsElementDisabled(element: T): boolean {
        if (this.isElementDisabled === undefined || this.isElementDisabled === null) {
            return false;
        }

        return this.isElementDisabled(element);
    }
}
