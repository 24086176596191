import { Component, OnInit, Inject, OnDestroy, EventEmitter, Input,ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MatLegacyRadioChange as MatRadioChange } from '@angular/material/legacy-radio';
import { invalidCharactersValidator } from 'src/app/core/shared/validators/formValidators/invalidCharactersValidator';
import { ExportSettings } from './model/export-settings.model';
import { ExportFileExtension, ExportType } from 'src/app/api/model/filesExportDto';

@Component({
  selector: 'ctbox-exporter',
  templateUrl: './ctbox-export.component.html',
  styleUrls: ['./ctbox-export.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class CtboxExportComponent implements OnInit, OnDestroy {

    @Input() hasSelectedText = 'false';

    public get exportFileExtension(): typeof ExportFileExtension {
        return ExportFileExtension;
    }

    public get exportType(): typeof ExportType {
        return ExportType;
    }

    public exportForm: UntypedFormGroup;

    public values: ExportSettings = {
        exportFileExtension: ExportFileExtension.Docx,
        exportType: ExportType.CompleteDocument,
        comment: ''
    };

    protected MAX_LENGTH_COMMENT = 350; //TODO ver si hay limite en el servicio, sino quitar.

    private onDestroy$ = new EventEmitter<void>();

    constructor(@Inject(MAT_DIALOG_DATA) public data) {
        this.hasSelectedText = data.hasSelectedText;

    }

    public ngOnInit() {
        this.initFormModel();

    }
    public ngOnDestroy() {
        this.onDestroy$.emit();
    }

    public initFormModel(): void {
        this.exportForm = new UntypedFormGroup(
            {
                comments: new UntypedFormControl(null, [Validators.maxLength(this.MAX_LENGTH_COMMENT), invalidCharactersValidator]),
            }
        );

        this.exportForm.addControl('options', new UntypedFormControl(null, [Validators.required]));
        this.exportForm.addControl('types', new UntypedFormControl(null, [Validators.required]));

        this.values.exportType = this.hasSelectedText ? ExportType.SelectedText : ExportType.CompleteDocument;
    }

    public optionChange(event: MatRadioChange){
        const exportType: number = +event.value;
        this.values.exportType = exportType;
    }

    public typeChange(event: MatRadioChange){
        const exportFileExtension: number = +event.value;
        this.values.exportFileExtension = exportFileExtension;
    }
}
