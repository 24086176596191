import { BaseEntityDto } from "./baseEntityDto";
import { FolderItemInformationDto } from "./folderItemInformationDto";
import { UserFolderPermissionsDto } from "./userFolderPermissionsDto";

export interface ClauseFolderDto extends BaseEntityDto {
    name?: string;
    content?: FolderItemInformationDto[];
    clauseFolderParentId?: string;
    userFolderPermission?: UserFolderPermissionsDto;
    nameHighlighted?: string;
    childrenFolder?: Array<ClauseFolderDto>;
    order?: number;
}
