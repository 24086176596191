import { Component, EventEmitter, Inject, Input, Output } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
    selector: 'app-clause-document-revision-send-to-sign-modal',
    styleUrls: ['./clause-document-revision-send-to-sign-modal.component.scss'],
    templateUrl: './clause-document-revision-send-to-sign-modal.component.html'
})
export class ClauseDocumentRevisionSendToSignModalComponent {

    @Input() numDocumentsSendToSign: number;
    @Input() numDocumentsNotReadyToSign: number;
    @Input() numDocumentsReadyToSign: number;
    @Input() expirationDate: Date;
    @Input() expirationDays: number;

    @Output() newExpirationDate = new EventEmitter<Date>();

    public lastOpenedDialog: MatDialogRef<any>;
    public tiempoExpiracion = 'dias';
    private NotExpirateYear = 9999;

    constructor(@Inject(MAT_DIALOG_DATA) public data) {
        this.numDocumentsSendToSign = data.dataSource.numDocumentsSendToSign;
        this.numDocumentsNotReadyToSign = data.dataSource.numDocumentsNotReadyToSign;
        this.numDocumentsReadyToSign = data.dataSource.numDocumentsReadyToSign;
        this.expirationDate = data.dataSource.expirationDate;
        this.expirationDays = data.dataSource.expirationDays;
    }

    public acceptSign() {
        const newDate = new Date();
        if (this.tiempoExpiracion === 'dias') {
            newDate.setDate(Number(this.expirationDays) + newDate.getDate());
        } else {
            newDate.setFullYear(this.NotExpirateYear);
        }
        this.newExpirationDate.next(newDate);
        this.lastOpenedDialog.close('Aceptar');
    }

    public cancelSign() {
        this.lastOpenedDialog.close('Cancel');
    }

    public close() {
        this.lastOpenedDialog.close('Close');
    }

    public hasTwoOptionsEnabled(): boolean {
        return this.hasOneOrMoreDocumentsReady() && this.hasOneOrMoreDocumentsNotReady();
    }

    public hasOneOrMoreDocumentsReady(): boolean {
        return this.numDocumentsReadyToSign > 0;
    }

    public hasOneOrMoreDocumentsNotReady(): boolean {
        return this.numDocumentsNotReadyToSign > 0;
    }
}
