import { TemplateSheetDto } from 'src/app/api/model/cmc/templateSheetDto';
import { Injectable } from '@angular/core';
import { TemplateDTO } from 'src/app/api';
import { LockedFileResultStatus } from '../../models/locked-file-result-status';
import { FileStatusCodes } from 'src/app/core/cmc/enums/file-status.enum';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})

export class TemplatesChangesService {
    private name = new BehaviorSubject<string>('');
    private sheet = new Subject<TemplateSheetDto>();
    private templateLock = new BehaviorSubject<LockedFileResultStatus>(LockedFileResultStatus.successful);
    private templateIsObsolete = new Subject<string>();
    private template = new BehaviorSubject<TemplateDTO>(null);
    private templateStatusCode = new BehaviorSubject<FileStatusCodes>(FileStatusCodes.PENDING);
    private templateFilledContent = new BehaviorSubject<string>('');
    private templateFilledContentWithHeader = new BehaviorSubject<string>('');

    public getTemplateNameSubscription(): Observable<string> {
        return this.name.asObservable();
    }

    public setTemplateName(newName: string): void {
        return this.name.next(newName);
    }

    public getTemplateSheetSubscription(): Observable<TemplateSheetDto> {
        return this.sheet.asObservable();
    }

    public setTemplateSheet(templateSheet: TemplateSheetDto): void {
        return this.sheet.next(templateSheet);
    }

    public getTemplateLocked(): Observable<LockedFileResultStatus> {
        return this.templateLock.asObservable();
    }

    public setTemplateLocked(templateLock: LockedFileResultStatus): void {
        this.templateLock.next(templateLock);
    }

    public getTemplateNeedToBeReloadSubscription(): Observable<string> {
        return this.templateIsObsolete.asObservable();
    }

    public notifyTemplateNeedToBeReload(templateId: string): void {
        return this.templateIsObsolete.next(templateId);
    }

    public getTemplateSubscription(): Observable<TemplateDTO> {
        return this.template;
    }

    public setTemplate(template: TemplateDTO): void {
        return this.template.next(template);
    }

    public cleanTemplate(): void {
        this.template.next(null)
    }

    public setTemplateStatusCode(code: FileStatusCodes): void {
        return this.templateStatusCode.next(code);
    }

    public getTemplateStatusCode(): Observable<FileStatusCodes> {
        return this.templateStatusCode.asObservable();
    }

    public getTemplateFilledContentValue(): string {
        return this.templateFilledContent.getValue();
    }

    public setTemplateFilledContent(filledContent: string): void {
        return this.templateFilledContent.next(filledContent);
    }

    public getTemplateFilledContentWithHeaderValue(): string {
        return this.templateFilledContentWithHeader.getValue();
    }

    public setTemplateFilledContentWithHeaderValue(filledContentWithHeader: string): void {
        return this.templateFilledContentWithHeader.next(filledContentWithHeader);
    }

}
