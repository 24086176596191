import { Pipe, PipeTransform } from '@angular/core';
import { formatBytes } from 'src/app/core/shared/utils/file-size-extension';

@Pipe({
    name: 'fileSizeExtensionPipe'
})
export class FileSizeExtensionPipe implements PipeTransform {

    transform(value: number, ...args: unknown[]): string {
        return formatBytes(value);
    }
}
