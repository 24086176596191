<form [formGroup]="form">
    <div class="ai-selector">
        <div *ngIf="!aISelection || !aISelection.aiSelectionType">
            <p>Seleccione funcionalidad:</p>
            <ctbox-button customClass="ai" buttonType="text" buttonIconSvg="selector" buttonText="Crear" (buttonClick)="setAiSelectionType('CREATE')"></ctbox-button>
            <ctbox-button customClass="ai" buttonType="text" buttonIconSvg="hand" buttonText="Resumir" (buttonClick)="setAiSelectionType('SUMMARIZE')"></ctbox-button>
            <ctbox-button customClass="ai" buttonType="text" buttonIconSvg="scale-balanced" buttonText="Traducir" (buttonClick)="setAiSelectionType('TRANSLATE')"></ctbox-button>
            <ctbox-button customClass="ai" buttonType="text" buttonIconSvg="editable" buttonText="Editar" (buttonClick)="setAiSelectionType('FIX')"></ctbox-button>
            <ctbox-button customClass="ai" buttonType="text" buttonIconSvg="comment" buttonText="Enlaces" (buttonClick)="setAiSelectionType('EXTRACTLINKS')"></ctbox-button>
        </div>
        <div *ngIf="aISelection && aISelection.aiSelectionType">
            <div [ngSwitch]="GetAiType()">
                <div *ngSwitchCase="'CREATE'">
                    <p class="aiP">Creación de borrador</p>
                    <mat-form-field class="coment size-100 aiSpace">
                        <mat-label>Seleccione un tipo de documento</mat-label>
                        <mat-select [(value)]="this.aISelection.documentType">
                        <mat-option *ngFor="let option of documentTypes" [value]="option.value">
                            {{ option.viewValue }}
                        </mat-option>
                        </mat-select>
                    </mat-form-field>
                    <div *ngIf="this.aISelection.documentType">
                        <div [ngSwitch]="this.aISelection.documentType">
                            <div *ngSwitchCase="'Contratos y acuerdos'">
                                <p class="aiP">Para que podamos preparar el borrador de documento más adaptado a tu caso, por favor, responde a estas preguntas:</p>
                                <mat-form-field class="size-100 aiSpace">
                                    <mat-label>¿Cuál es el objeto del contrato?                        </mat-label>
                                    <textarea matInput #formMessage (focusout)="setDocumentPurpose()" rows="2" formControlName="documentPurpose"></textarea>
                                </mat-form-field>
                                <mat-form-field class="size-100 aiSpace">
                                    <mat-label>¿Quiénes son las partes intervinientes?                        </mat-label>
                                    <textarea matInput #formMessage (focusout)="setDocumentParts()" rows="2" formControlName="documentParts"></textarea>
                                </mat-form-field>
                                <mat-form-field class="size-100 aiSpace">
                                    <mat-label>¿Cuál es la duración o vigencia del contrato?</mat-label>
                                    <textarea matInput #formMessage (focusout)="setDocumentDuration()" rows="2" formControlName="documentDuration"></textarea>
                                </mat-form-field>
                                <mat-form-field class="size-100 aiSpace">
                                    <mat-label>¿Cuáles son las obligaciones y contraprestaciones principales de las partes?</mat-label>
                                    <textarea matInput #formMessage (focusout)="setDocumentObligations()" rows="2" formControlName="documentObligations"></textarea>
                                </mat-form-field>
                                <mat-form-field class="size-100 aiSpace">
                                    <mat-label>Describe qué otros elementos esenciales o especialidades del documento quieres que tengamos en consideración</mat-label>
                                    <textarea matInput #formMessage (focusout)="setDocumentOther()" rows="2" formControlName="documentOther"></textarea>
                                </mat-form-field>
                            </div>
                            <div *ngSwitchCase="'Poderes notariales'">
                                <mat-form-field class="size-100 aiSpace">
                                    <mat-label>Describe el objeto del documento que deseas que elabore</mat-label>
                                    <textarea matInput #formMessage (focusout)="setDocumentPurpose()" rows="2" formControlName="documentPurpose"></textarea>
                                </mat-form-field>
                                <mat-form-field class="size-100 aiSpace">
                                    <mat-label>¿Quiénes son las partes intervinientes?                        </mat-label>
                                    <textarea matInput #formMessage (focusout)="setDocumentParts()" rows="2" formControlName="documentParts"></textarea>
                                </mat-form-field>
                                <mat-form-field class="size-100 aiSpace">
                                    <mat-label>Indica qué otros elementos esenciales o especialidades del documento quieres que tengamos en consideración</mat-label>
                                    <textarea matInput #formMessage (focusout)="setDocumentOther()" rows="2" formControlName="documentOther"></textarea>
                                </mat-form-field>
                            </div>
                            <div *ngSwitchCase="'Acuerdos de confidencialidad (NDA)'">
                                <mat-form-field class="size-100 aiSpace">
                                    <mat-label>Describe el objeto del documento que deseas que elabore</mat-label>
                                    <textarea matInput #formMessage (focusout)="setDocumentPurpose()" rows="2" formControlName="documentPurpose"></textarea>
                                </mat-form-field>
                                <mat-form-field class="size-100 aiSpace">
                                    <mat-label>¿Quiénes son las partes intervinientes?                        </mat-label>
                                    <textarea matInput #formMessage (focusout)="setDocumentParts()" rows="2" formControlName="documentParts"></textarea>
                                </mat-form-field>
                                <mat-form-field class="size-100 aiSpace">
                                    <mat-label>Indica qué otros elementos esenciales o especialidades del documento quieres que tengamos en consideración</mat-label>
                                    <textarea matInput #formMessage (focusout)="setDocumentOther()" rows="2" formControlName="documentOther"></textarea>
                                </mat-form-field>
                            </div>
                            <div *ngSwitchDefault >
                                <mat-form-field class="size-100 aiSpace">
                                    <mat-label>Describe el objeto del documento que deseas que elabore</mat-label>
                                    <textarea matInput #formMessage (focusout)="setDocumentPurpose()" rows="2" formControlName="documentPurpose"></textarea>
                                </mat-form-field>
                                <mat-form-field class="size-100 aiSpace">
                                    <mat-label>Indica qué otros elementos esenciales o especialidades del documento quieres que tengamos en consideración</mat-label>
                                    <textarea matInput #formMessage (focusout)="setDocumentOther()" rows="2" formControlName="documentOther"></textarea>
                                </mat-form-field>
                            </div>
                        </div>
                    </div>
                </div>
                <p class="aiP" *ngSwitchCase="'SUMMARIZE'">Se creará un resumen del texto</p>
                <p class="aiP" *ngSwitchCase="'TRANSLATE'">Introduce el idioma al que deseas traducir el texto:</p>
                <p class="aiP" *ngSwitchCase="'FIX'">Describe quieres arreglar (o dejalo vacio para hacer un arreglo generico):</p>
                <p class="aiP" *ngSwitchCase="'EXTRACTLINKS'">Se extraerán tanto las normas (links) existentes en el documento, como las normas que podrian afectar al texto</p>
            </div>
            <mat-form-field class="size-100" *ngIf="GetAiType() !== 'SUMMARIZE' && GetAiType() !== 'EXTRACTLINKS' && GetAiType() !== 'CREATE'">
                <mat-label>Descripción</mat-label>
                <textarea matInput #formMessage (focusout)="getDescription()" rows="2" formControlName="description" name="description"></textarea>
            </mat-form-field>
            <ctbox-button buttonType="secondary" buttonIconSvg="editable" buttonText="Volver" (buttonClick)="setAiSelectionType('')"></ctbox-button>
        </div>
    </div>
</form>
