import { AbstractControl, ValidationErrors } from '@angular/forms';
import { isValueEmptyValidator } from 'src/app/core/shared/validators/valueEmptyValidator';
import { ValidatorRegexConstant } from '../validator-regex-constant';

export function emailFormValidator(control: AbstractControl): ValidationErrors | null {
    if (control === null || isValueEmptyValidator(control.value)) {
        return null;
    }

    return ValidatorRegexConstant.CUSTOM_EMAIL_REGEX.test(control.value) ? null : { email: true };
}

export const exportEmailPattern = (): RegExp => ValidatorRegexConstant.CUSTOM_EMAIL_REGEX;
