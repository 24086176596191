import { Conversion, Editor, Element, DowncastConversionApi } from 'ckeditor5';
import { Injectable } from "@angular/core";
import { SelectPlugin } from '../../plugins/select/select-plugin';

@Injectable({
    providedIn: "root",
})
export class SelectModelToDataViewConverterService {

    public configureConverter(editor: Editor): void {
        this.selectContainerConversion(editor.conversion);
        this.contentConversion(editor.conversion);
        this.optionsConverter(editor);
    }

    private selectContainerConversion(conversion: Conversion): void {
        conversion.for("dataDowncast").add(downcastDispatcher => {
            downcastDispatcher.on(`insert:${SelectPlugin.MODEL_ENTITIES.container.model}`, (_evt, data, conversionApi: DowncastConversionApi) => {
                const consumable = conversionApi.consumable;
                if (!consumable.consume(data.item, 'insert')) {
                    return;
                }

                const modelItem: Element = data.item;
                const id = modelItem.getAttribute(SelectPlugin.ATTRIBUTE_ID);

                const viewPosition = conversionApi.mapper.toViewPosition(data.range.start);
                const firstChild = modelItem.getChild(SelectPlugin.SELECT_POSITION);
                if(!firstChild.is('element')) {
                    return;
                }

                const selectDataView = conversionApi.writer.createContainerElement('select',
                    {
                        class: [SelectPlugin.MODEL_ENTITIES.container.editionView],
                        id
                    });

                conversionApi.mapper.bindElements(data.item, selectDataView);
                conversionApi.writer.insert(viewPosition, selectDataView);
            });
        });
    }

    private contentConversion(conversion: Conversion): void {
        conversion.for("dataDowncast").elementToStructure({
            model: SelectPlugin.MODEL_ENTITIES.content.model,
            view: (_modelItem, { writer }) => writer.createSlot()
        });
    }

    private optionsConverter(editor: Editor): void {
        const conversion = editor.conversion;
        conversion.for('dataDowncast').elementToElement({
                model: SelectPlugin.MODEL_ENTITIES.option.model,
                view: (modelElement: Element, { writer }) => {
                    const value = modelElement.getAttribute(SelectPlugin.ATTRIBUTE_VALUE);
                    const isSelected = modelElement.getAttribute(SelectPlugin.ATTRIBUTE_SELECTED);
                    const childText = !!value? [writer.createText(value.toString())] : [];

                    const optionElement = writer.createContainerElement(SelectPlugin.MODEL_ENTITIES.option.dataView, {
                       value: value
                    }, childText);

                    if (!!isSelected) {
                        writer.setAttribute(SelectPlugin.ATTRIBUTE_SELECTED, isSelected, optionElement);
                    }

                    return optionElement;
                }
        });
    }
}
