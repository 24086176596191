import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class RouteStateService {

    private pathParamOpenPopupState = new BehaviorSubject<string>('');
    private pathParamReturnUrlState = new BehaviorSubject<string>('');
    private pathParamEmailState = new BehaviorSubject<string>('');

    pathParamOpenPopup: Observable<string>;
    pathParamReturnUrl: Observable<string>;
    pathParamEmail: Observable<string>;

    constructor() {
        this.pathParamOpenPopup = this.pathParamOpenPopupState.asObservable();
        this.pathParamReturnUrl = this.pathParamReturnUrlState.asObservable();
        this.pathParamEmail = this.pathParamEmailState.asObservable();
    }

    updateParamOpenPopupState(newOpenPopup: string) {
        this.pathParamOpenPopupState.next(newOpenPopup);
    }

    updateParamReturnUrl(newParamUrl: string) {
        this.pathParamReturnUrlState.next(newParamUrl);
    }

    updateParamEmail(newEmail) {
        this.pathParamEmailState.next(newEmail);
    }
}
