/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { SignerDTO } from './signerDTO';

export interface SignerNotifyResponseDTO { 
    docGUI?: string;
    fileName?: string;
    docStatus?: string;
    signers?: Array<SignerDTO>;
    downloaded?: boolean;
}