import { HttpHeaders } from '@angular/common/http';
import { UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { ClauseFolderDto } from '../../model/clause-folder-dto.model';
import { IJsonPatchDocument } from '../../model/json-patch.model';
import { ExpandRequestModel } from '../../model/request-model/expand-request.model';
import { ApiProcessorService, ApiRequest, ApiOperation, ApiResponse } from '../api-processor.service';
import { RequestQueryParamsService } from '../requestQueryParams.service';
import { Configuration } from 'src/config/configuration';

export abstract class BaseClauseFolderService {

    public abstract basePath: string;
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(
        protected readonly apiProcessorService: ApiProcessorService,
        protected readonly mapRequestQueryParams: RequestQueryParamsService) {
    }

    public get(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}/${id}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public getAll(loadContent: boolean = false): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;

        let params = '';

        if (loadContent) {
            const mapRequest: ExpandRequestModel = {
                expands: ['content']
            };
            params = '?' + this.mapRequestQueryParams.getQueryParamsByExpandRequest(mapRequest);
        }

        apiRequest.endpointUrl = `${this.basePath}` + params;

        return this.apiProcessorService.proccessRequest<ClauseFolderDto[]>(apiRequest);
    }

    public delete(id: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl =  `${ this.basePath }/${id}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public create(clauseFolder: ClauseFolderDto): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = clauseFolder;
        apiRequest.endpointUrl = this.basePath;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public patch(id: string, jsonPatchDocument: Array<IJsonPatchDocument>): Observable<ApiResponse<ClauseFolderDto> | UrlTree>{
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PATCH;
        apiRequest.endpointUrl = `${this.basePath}/${id}`;
        apiRequest.body = jsonPatchDocument;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
