/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { ExpirationExternalMailDto } from './expirationExternalMailDto';
import { ExpirationMemberMailDto } from './expirationMemberMailDto';
import { ExpirationNoticeConfigurationDto } from './expirationNoticeConfigurationDto';
import { MasterData } from './masterData';

export interface ExpirationDto { 
    id?: string;
    date?: Date;
    expirationTypeId?: string;
    expirationType?: MasterData;
    documentId?: string;
    expirationMemberMails?: Array<ExpirationMemberMailDto>;
    expirationExternalMails?: Array<ExpirationExternalMailDto>;
    expirationNoticeConfigurations?: Array<ExpirationNoticeConfigurationDto>;
}