import { Injectable } from '@angular/core';
import { ApiProcessorService } from '../api-processor.service';
import { BaseLegalAlertsContentService } from '../base/base-legal-alerts-content.service';

@Injectable()
export class LegalAlertsContentCMCService extends BaseLegalAlertsContentService {

    protected override basePath = '/api/manager-management/alerts-content';

    constructor(
        protected readonly apiProcessorService: ApiProcessorService
    ) { 
        super(apiProcessorService);
    }
}
