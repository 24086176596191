import { Component, HostBinding, Input, OnInit, ViewChild, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { VersionInfo } from 'src/app/api/model/versionInfo';
import { CompareHtmlVersionResultDTO } from 'src/app/api/model/compareHtmlVersionResultDTO';
import { CtboxVersionComparatorComponent } from './version-comparator/version-comparator.component';

@Component({
    selector: 'app-ctbox-versions',
    templateUrl: './ctbox-versions.component.html'
})

export class CtboxVersionsComponent implements OnInit, OnChanges {

    @ViewChild('sideversion', { static: false }) public sideversion!: MatSidenav;
    @HostBinding('class') componentClass: string;

    @Input() versions: VersionInfo[];
    @Input() currentVersionHtml: string;
    @Input() currentVersionId: string;
    @Input() compareHtmlVersionResult: CompareHtmlVersionResultDTO;
    @Input() latestVersionId: string;

    @Output() notifyNewVersionSelected = new EventEmitter<string>();
    @Output() notifyUpdateVersion = new EventEmitter<any>();
    @Output() notifyCompareHtmlVersions = new EventEmitter<string>();
    @Output() notifyExportHtmlComparison = new EventEmitter<string>();

    public isFolded = false;
    public buttonIconToggle = 'view_list';
    public htmlVersion = '';
    public currentVersionInfo: VersionInfo;
    public versionsOrdered: VersionInfo[];
    public versionsAvailableToCompare: VersionInfo[];

    public showComparerVersionButton = true;
    private currentVersionSelectedToCompare: VersionInfo;

    constructor(private readonly genericDialogService: GenericDialogService) { }

    public ngOnInit(): void {
        this.componentClass = 'content-main with-tabs-title-tabs with-sidenav';
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.versions && changes.versions.currentValue) {
            this.initializeVersions();
            if (this.currentVersionInfo) {
                const versionToSelect = this.versionsOrdered.find(vo => vo.id === this.currentVersionInfo.id);
                versionToSelect.isSelected = true;
            }
        }

        if (changes && changes.currentVersionHtml && changes.currentVersionHtml.currentValue) {
            this.htmlVersion = changes.currentVersionHtml.currentValue;
        }

        if (changes && changes.currentVersionId && changes.currentVersionId.currentValue) {
            const currentVersionId = changes.currentVersionId.currentValue;
            this.versionsOrdered.find(v => v.isSelected).isSelected = false;
            this.versionsOrdered.find(v => v.id === currentVersionId).isSelected = true;
            this.showComparerVersionButton = this.latestVersionId === changes.currentVersionId.currentValue;
        }

        if (changes && changes.compareHtmlVersionResult && changes.compareHtmlVersionResult.currentValue) {
            this.showVersionCompareResult(changes.compareHtmlVersionResult.currentValue);
        }
    }

    public onNotifyVersionInfoHasChanged(value: { title: string, comment: string; }): void {
        const titleHasChanged: boolean = this.currentVersionInfo.title !== value.title;
        const commentHasChanged: boolean = this.currentVersionInfo.comment !== value.comment;

        if (commentHasChanged) {
            this.currentVersionInfo.comment = value.comment;
        }

        if (titleHasChanged) {
            this.currentVersionInfo.title = value.title;
        }

        this.notifyUpdateVersion.emit({ versionInfo: this.currentVersionInfo, titleHasChanged, commentHasChanged });
    }

    public onNotifyNewVersionSelected(id: string): void {
        this.currentVersionInfo = this.versionsOrdered.find(v => v.id === id);
        this.notifyNewVersionSelected.emit(id);
    }

    public onClickShowVersionsCompareResult(versionInfo: VersionInfo): void {
        this.currentVersionSelectedToCompare = versionInfo;
        this.notifyCompareHtmlVersions.emit(versionInfo.id);
    }

    public showVersionCompareResult(compareHtmlVersionResultDTO: CompareHtmlVersionResultDTO): void {
        const modalTitle = $localize`:@@CompareVersions:Comparador de versiones`;
        const config = this.genericDialogService.getBigDialogConfig();
        const data: any = {
            template: CtboxVersionComparatorComponent,
            dialogTitle: modalTitle,
            compareHtmlVersionResult: compareHtmlVersionResultDTO,
            latestVersion: this.versionsOrdered[0],
            currentVersionSelected: this.currentVersionSelectedToCompare,
            displayButtonBar: true,
            dialogButton: $localize`:@@ExportarComparacion:Exportar`,
            dialogCloseButon: $localize`:@@CerrarComparacion:Cerrar`,
            displayCloseOption: true,
            secondaryButtonContentObservableName: 'isReviewDisabledSubscription',
            isDraggableContent: false,
            isDraggableDialog: true
        };

        this.genericDialogService.openTemplateWithConfigAndData(data.template, config, data).afterClosed()
            .subscribe((result) => {
                if (!result) {
                    return;
                }
                this.notifyExportHtmlComparison.emit(compareHtmlVersionResultDTO.diffHtml);
            });
    }

    private initializeVersions(): void {
        this.versionsOrdered = this.sortVersions(this.versions);
        this.formatVersionDates();
        this.versionsOrdered = this.insertLabelVersion(this.versionsOrdered);
        this.versionsAvailableToCompare = this.versionsOrdered.slice(1);
    }

    private formatVersionDates(): void {
        this.versionsOrdered.forEach(version => {
            version.date = this.stringToDate(version.version.toString());
        });
    }

    private sortVersions(versions: VersionInfo[]): any {
        return versions.sort((item1, item2) => {
            if (item1.version === item2.version) {
                return 0;
            }
            return (item1.version > item2.version) ? -1 : 1;
        });
    }

    private stringToDate(date: string): Date {
        const year = parseInt(date.substring(0, 4), 10);
        const month = parseInt(date.substring(4, 6), 10) - 1;
        const day = parseInt(date.substring(6, 8), 10);
        return new Date(year, month, day);
    }

    private insertLabelVersion(versions: VersionInfo[]): VersionInfo[] {
        for (let i = 0; i < versions.length; i++) {
            if (i === 0) {
                versions[i].label = versions[i].date.toLocaleDateString('es-ES');
            }
            else if (i > 0 && i <= versions.length - 2) {
                versions[i].label = `${versions[i].date.toLocaleDateString('es-ES')} ${$localize`:@@hasta: hasta`}  ${this.subtractOneDay(versions[i - 1].date).toLocaleDateString('es-ES')}`;
            }
            else {
                versions[i].label = `${$localize`:@@OriginalHasta: Original hasta`} ${this.subtractOneDay(versions[i - 1].date).toLocaleDateString('es-ES')}`;
            }
        }

        return versions;
    }

    private subtractOneDay(date: Date): Date {
        return new Date(date.setDate(date.getDate() - 1));
    }
}
