import { AbstractControl } from '@angular/forms';

export function noOnlyWhiteSpacesValidator(control: AbstractControl) {
    const noText = '';
    const textInControl = (control.value as string);
    if (textInControl === null || textInControl === undefined) {
        return { onlyWhitespaces: { value: control.value } };
    }
    const hasOnlyWhiteSpaces = noText === textInControl.trim();
    return hasOnlyWhiteSpaces ? { onlyWhitespaces: { value: control.value } } : null;
}
