import { Editor, Item, TreeWalkerValue, Writer } from "ckeditor5";
import { InputUtilsService } from "../../utils/input/input-utils.service";
import { LinkedFieldUtilsService } from "../../utils/linked-field/linked-field-utils.service";
import { BaseInputModel } from "../../models/input/base-input-model";
import { BaseAddEditLinkedFieldCommand } from "./base-add-edit-linked-field-command";
import { ShortTextInput } from "../../models/input/short-text-input-model";

export default class EditLinkedFieldCommand extends BaseAddEditLinkedFieldCommand {

    public labelDataModel: string;

    private inputUtilsService: InputUtilsService;
    private linkedFieldUtilsService: LinkedFieldUtilsService;

    constructor(editor: Editor) {
        super(editor);
        this.inputUtilsService = new InputUtilsService();
        this.linkedFieldUtilsService = new LinkedFieldUtilsService();
    }

    public override execute(model: ShortTextInput): void {
        const editor = this.editor;

        editor.model.change((writer: Writer)  => {
            const inputLinked = this.inputUtilsService.getInputParentElementModelWithAlias(editor, model.alias);
            const inputLinkedType = inputLinked.item.getAttribute('input-type').toString();

            if (!this.inputUtilsService.isTypeValid(inputLinkedType)) {
                return null;
            }

            const range = writer.createRangeIn(editor.model.document.getRoot()!);

            const elementToDelete = this.getElementWithId(range, model.id)?.item;

            if(!elementToDelete) {
                return;
            }

            this.selectOnlyElementToDelete(writer, elementToDelete);
            writer.remove(elementToDelete);

            this.createNewStructureFromParentWithOldId(model, inputLinked, writer, inputLinkedType, editor);
        } );

        this.validateLinkedField(model.id);
    }

    public override refresh(): void {
        super.refresh();

        if (!this.isEnabled) {
            this.enableCommandIfCursorInsideInputContent();
        }
    }

    private createNewStructureFromParentWithOldId( model: BaseInputModel, parent: TreeWalkerValue, writer: Writer, inputParentType: string, editor: Editor): void {
        const inputParentModel = this.linkedFieldUtilsService.getInputModelWithElement(parent.item);
        const newModel = inputParentModel;
        newModel.id = model.id;

        const inputLinkedFieldModel = this.linkedFieldUtilsService.createLinkedFieldStructureModel(writer, inputParentType, newModel);
        editor.model.insertObject(inputLinkedFieldModel, null, null, { setSelection: 'after' });
    }

    private selectOnlyElementToDelete(writer: Writer, elementToDelete: Item): void {
        const rangeToDelete = writer.createRange(
            writer.createPositionAt(elementToDelete, 0),
            writer.createPositionAt(elementToDelete, 'end')
        );

        writer.setSelection(rangeToDelete);
    }
}
