<form [formGroup]="formUser" class="wf-user" [ngClass]="modeEdition">
    <div class="wf-user-item person" [ngClass]="{'editable' : isEditable }">
        <ng-container *ngIf="isEditable">
            <p class="wf-user-title">
                <mat-icon class="info">person</mat-icon><span i18n="@@Datos">Datos</span>
            </p>
            <div class="wf-user-form">
                <div class="form-group-flex size-100">
                    <mat-form-field>
                        <mat-label i18n="@@CompartidosNombreCompletoCabecera">Nombre y Apellidos</mat-label>
                        <input matInput [formControlName]="fields.name" [name]="fields.name" [readonly]="this.isBlocked()" (keypress)="controlKeyEnter($event)">
                        <mat-error>
                            <span *ngIf="formUser.controls.name.errors?.required" i18n="@@ValidacionMensajeNombre">Debes introducir un nombre</span>
                            <span *ngIf="formUser.controls.name.errors?.maxlength" i18n="@@ValidacionMensajeNombreLongitud">La longitud no puede superar {{maxNameLength}} caracteres.</span>
                            <span *ngIf="formUser.controls.name.errors?.invalidName"><span i18n="@@caracteresInvalidos">Caracteres inválidos introducidos: </span> {{formUser.controls.name.errors.invalidName}}</span>
                        </mat-error>
                    </mat-form-field>
                </div>
                <div class="form-group-flex size-100">
                    <mat-form-field>
                        <mat-label i18n="@@CompartidosEmailCabecera">Email</mat-label>
                        <input matInput [formControlName]="fields.email" [name]="fields.email" [readonly]="this.isBlocked()" (keypress)="controlKeyEnter($event)">
                        <mat-error *ngIf="formUser.controls[fields.email].invalid " i18n="@@WorkflowEmailInvalido">Email no válido</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!isEditable">
            <div class="wf-user-turn">
                <div class="wf-user-order">
                    <div [matBadge]="index" matBadgeSize="medium" class="wf-user-order" [ngClass]="modeEdition"></div>
                </div>
                <div class="wf-user-info">
                    <div class="wf-user-name">
                        <div class="wf-user-label" i18n="@@CompartidosNombreCompletoCabecera">Nombre y Apellidos</div>
                        <div class="wf-user-value">{{user.fullName}}</div>
                    </div>
                    <div class="wf-user-email">
                        <div class="wf-user-label" i18n="@@CompartidosEmailCabecera">Email</div>
                        <div class="wf-user-value">{{user.email}}</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
    <div class="wf-user-item permissions" [ngClass]="{'editable' : isEditable }">
        <p class="wf-user-title">
            <mat-icon class="info">checklist</mat-icon><span i18n="@@CompartidosPermisosCabecera">Permisos</span>
        </p>
        <ng-container *ngIf="isEditable">
            <div class="wf-user-form">
                <div class="form-group-flex gap0 fcol">
                    <mat-checkbox checked disabled><label i18n="@@PermisosWorkflowLecturaDescripcion">Lectura</label></mat-checkbox>
                    <mat-checkbox #fillDataPermision [formControlName]="fields.fillDataPermision" [name]="fields.fillDataPermision"><label i18n="@@CompletarDatos">Completar datos</label></mat-checkbox>
                    <mat-checkbox #commentPermission [formControlName]="fields.commentPermission" [name]="fields.commentPermission"><label i18n="@@AñadirComentarios">Añadir comentarios</label></mat-checkbox>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="!isEditable">
            <div class="wf-user-data">
                <div *ngIf="showLiteralOnlyRead()" class="wf-user-value" i18n="@@PermisosWorkflowLecturaDescripcion">Lectura</div>
                <div class="wf-user-value" *ngFor="let permission of user.permissionType">
                    {{this.getDescriptionPermission(permission?.code)}}
                </div>
            </div>
        </ng-container>
        <div class="form-group-flex gap0 center size-100">
            <p class="wf-user-title">
                <mat-icon class="info">timer</mat-icon><span i18n="@@DiasDisponibles">Días disponibles</span>
            </p>
            <mat-form-field class="days" [ngClass]="{'readonly': isDaysDisabled && !isEditable}">
                <input #expirationDateInput matInput [formControlName]="fields.expirationDays" [name]="fields.expirationDays" required (focusout)="finishExpirationDaysEdit()" [readonly]="(isDaysDisabled && !isEditable) || this.isBlocked()" (keypress)="controlKeyEnter($event)">
                <mat-error *ngIf="formUser.controls[fields.expirationDays].invalid" i18n="@@ValidacionDiasExpiraciónMensaje">Debes introducir un número entero entre 1 y 365</mat-error>
            </mat-form-field>
            <span class="wf-user-days" i18n="@@dias">días</span>
            <ctbox-button *ngIf="!workFlowStarted && !isEditable && !isBlocked()" buttonColor="secondary" buttonIcon="edit" (buttonClick)="editDaysMode()"></ctbox-button>
        </div>
    </div>
    <div *ngIf="!workFlowStarted" class="wf-user-item message  editable">
        <div class="wf-user-head">
            <p class="wf-user-title">
                <mat-icon class="info">mail</mat-icon> Mensaje
                <ctbox-button *ngIf="!isEmailEditable && !isEditable && !isBlocked()" buttonColor="secondary" buttonIcon="edit" (buttonClick)="editMessageMode()"></ctbox-button>
            </p>
            <div class="wf-user-buttons">
                <ctbox-button *ngIf="hasSeveralUser()" buttonIcon="delete" buttonColor="delete" (buttonClick)="deleteUser(user)"></ctbox-button>
            </div>
        </div>
        <ng-container *ngIf="(isMessageDisabled || this.isBlocked())">
            <div class="wf-user-data">
                <div class="wf-user-label">Asunto</div>
                <div class="wf-user-value">
                    {{ this.user.subject }}
                </div>
            </div>
            <div class="wf-user-data">
                <div class="wf-user-label">Texto</div>
                <div class="wf-user-value">
                    {{ this.user.emailBody }}
                </div>
            </div>
        </ng-container>
        <div class="wf-user-form" *ngIf="!(isMessageDisabled || this.isBlocked())">
            <div class="form-group-flex size-100">
                <mat-form-field [ngClass]="{'readonly': isMessageDisabled}">
                    <mat-label>Asunto</mat-label>
                    <input matInput #formSubject [readonly]="isMessageDisabled || this.isBlocked()" [formControlName]="fields.subject" [name]="fields.subject" (focusout)="finishSubjectEdit()" (keypress)="controlKeyEnter($event)">
                </mat-form-field>
            </div>
            <div class="form-group-flex size-100">
                <mat-form-field [ngClass]="{'readonly': isMessageDisabled}">
                    <mat-label>Mensaje</mat-label>
                    <textarea matInput #formMessage [readonly]="isMessageDisabled || this.isBlocked()" rows="3" [formControlName]="fields.message" [name]="fields.message" (focusout)="finishMessageEdit()"></textarea>
                </mat-form-field>
            </div>
        </div>
    </div>
    <div *ngIf="workFlowStarted" class="wf-user-item">
        <ctbox-workflow-state [workFlowTitle]="workFlowTitle" [user]="user" (resumeWorkflowClicked)="resumeWorkflow()"></ctbox-workflow-state>
    </div>
</form>
