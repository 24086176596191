import { Component } from '@angular/core';

@Component({
  selector: 'app-documents-review-contextual-help',
  templateUrl: './documents-review-contextual-help.component.html'
})
export class DocumentsReviewContextualHelpComponent {

  public active: any;
  public activeSub: any;

  ACTIONS_TAB = 0;
  ORGANIZATION_TAB = 1;

  MYDOCUMENT_TAB = 0;
  OURDOCUMENT_TAB = 1;
  SHAREDBYME_TAB = 2;
  SHAREDWITHME_TAB = 3;

  constructor() {
    // This is intentional
  }
}
