import { ApiOperation, ApiProcessorService, ApiRequest } from './api-processor.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

@Injectable()
export class TemplateUserLegalAlertsService {

    private basePath = '/api/template-legal-alerts';

    constructor(private readonly apiProcessorService: ApiProcessorService) {
    }

    public activate(templateId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `${this.basePath}/activate/${templateId}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }

    public deactivate(templateId: string): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.DELETE;
        apiRequest.endpointUrl = `${this.basePath}/deactivate/${templateId}`;

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
