import { NodeTreeAction } from 'src/app/shared/components/ctbox-tree/models/node-tree-action.model';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { Directive, EventEmitter, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { FileFlatNode } from 'src/app/shared/components/ctbox-tree/models/file-flat-node.model';
import { ClauseFolderDto, ClauseFolderSearchService, FolderResultDto, SearchClauseFoldersResultDto, UserInfoDTO } from 'src/app/api';
import { AuthorizeService } from 'src/app/core/shared/services/authorize/authorize.service';
import { SelectionModel } from '@angular/cdk/collections';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { NodeTreeNodeType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-node-type.enum';
import { FolderPostOperationActions } from '../../../../core/shared/enums/folder-post-operation-actions.enum';
import { IClausesFolderService } from 'src/app/core/shared/services/clauses/clauses-folder/clauses-folder.service.interface';
import { IClausesFolderHierarchyTreeActionsService } from 'src/app/core/shared/services/clauses/clauses-folder-tree/clauses-folder-with-content/clauses-folder-with-content.actions.service.interface';
import { IClausesFolderTreeService } from 'src/app/core/shared/services/clauses/clauses-folder-tree/clauses-folder-tree.service.interface';
import { takeUntil } from 'rxjs/operators';
import { ForceSynchronize } from 'src/app/shared/components/ctbox-tree/models/forceSynchronize.model';
import { Subject } from 'rxjs';
import { NIL as NIL_UUID } from 'uuid';
import { IJsonPatchDocument, Op } from 'src/app/api/model/json-patch.model';
import { FolderPatchModel, PatchAction } from 'src/app/api/model/folderPatchModel';
import { ClausesChangesService } from 'src/app/core/shared/services/clauses/clauses-changes.service';
import { ClauseFolderService } from 'src/app/api/api/standard/clause-folder.service';
import { BaseFileFolderDirective } from 'src/app/shared/components/base-components/folder/base-file-folder';

@Directive()
export abstract class BaseClauseFolderDirective extends BaseFileFolderDirective implements OnInit, OnDestroy {

    @Input() foldersNodeActions: NodeTreeActionType[] = [NodeTreeActionType.Download, NodeTreeActionType.Selected];
    @Input() clausesNodeActions: NodeTreeActionType[] = [NodeTreeActionType.Selected, NodeTreeActionType.Checked, NodeTreeActionType.Move];

    public synchronizeExpansionInTree$ = new Subject<ForceSynchronize>();

    protected rootNodeId = '0';
    protected clauseFolders: ClauseFolderDto[];
    protected clauseTreeSelectionModel = new SelectionModel<string>(true, [this.rootNodeId]);
    protected userInfo: UserInfoDTO;
    protected selectedNode: FileFlatNode;
    protected initialNodes: string[] = [];
    protected clauseFolderHierarchyTree: FileNode[];
    protected currentCheckedNodeIds: string[] = [];
    protected currentNodeId: string;
    protected expandedFolderId: string;
    protected isSearchActive = false;
    protected currentSearchTerm = '';
    protected noElementFoundInSearch = false;
    protected onDestroy$ = new EventEmitter<void>();

    protected results: FolderResultDto[];
    protected folders: ClauseFolderDto[];

    protected resultOrdered: FolderResultDto[];
    protected indexResultOrdered = -1;
    protected totalResultOrdered = 0;
    protected patternExpression = /<em class="highlight">/g;

    private readonly emptyFolderMessage =
        $localize`:@@NuevaCarpetaPlantillaErrorNombreVacioMensaje:Introduce un nombre para la carpeta, por favor.`;

    protected readonly moveToRootFolder =
        $localize`:@@NoSePuedeMoverElementoARaiz:No es posible incluir este elemento en la carpeta raíz.`;

    public constructor(
        private readonly loginService: AuthorizeService,
        @Inject('IClausesFolderTreeService') protected readonly clausesFolderTreeService: IClausesFolderTreeService,
        @Inject('IClausesFolderHierarchyTreeActionsService')
        protected readonly clauseFolderHierarchyTreeActionsService: IClausesFolderHierarchyTreeActionsService,
        protected readonly clauseFolderService: IClausesFolderService,
        protected readonly genericDialogService: GenericDialogService,
        protected readonly clauseFolderSearchService: ClauseFolderSearchService,
        protected readonly apiClauseFolders: ClauseFolderService,
        protected readonly clausesChangesService: ClausesChangesService,
    ) {
        super(genericDialogService);
    }

    public ngOnInit(): void {
        this.loginService.getUserInfo()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((userInfo: UserInfoDTO) => {
                this.userInfo = userInfo;
            });

        this.clauseFolderService.setCurrentFolderId('');

        this.clauseFolderService.getCurrentFolderId()
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((currentFolderId: string) => {
                const rootNodeId = this.clausesFolderTreeService.getRootNodeId();
                if (currentFolderId === '0' || currentFolderId === undefined || currentFolderId === '') {
                    this.clauseTreeSelectionModel = new SelectionModel<string>(true, [rootNodeId]);
                    const forceSynchronize: ForceSynchronize = { force: true, keepOldExpanded: false };
                    this.synchronizeExpansionInTree$.next(forceSynchronize);
                } else {
                    this.currentNodeId = currentFolderId;
                    this.clauseTreeSelectionModel = new SelectionModel<string>(true, [rootNodeId, currentFolderId]);
                }
            });

        this.expandedFolderId = history.state?.expandedFolderId;
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public searchPreviousResult(): void {
        let isOverflow = false;
        if (this.indexResultOrdered > 0) {
            this.indexResultOrdered--;
        } else {
            this.indexResultOrdered = this.resultOrdered.length - 1;
            isOverflow = true;
        }

        this.navigateToResult(isOverflow);
    }

    public searchNextResult(): void {
        let isOverflow = false;
        if (this.indexResultOrdered < this.resultOrdered.length - 1) {
            this.indexResultOrdered++;
        } else {
            this.indexResultOrdered = 0;
            isOverflow = true;
        }

        this.navigateToResult(isOverflow);
    }

    public abstract performSearch(searchTerm: string): void;

    protected abstract refreshSearch(searchTerm: string): void;
    protected abstract loadTree(): void;
    protected abstract loadFolderTree(clausesFolders: ClauseFolderDto[]): void;
    protected abstract doActionFromNodeEvent(nodeEvent: NodeTreeAction): void;

    protected refresh(): void {
        if (this.isSearchActive) {
            this.refreshSearch(this.currentSearchTerm);
        } else {
            this.refreshTree();
        }
    }

    protected refreshTree(): void {
        this.loadTree();
    }

    protected checked(nodeAction: NodeTreeAction): void {
        this.currentCheckedNodeIds = nodeAction.additionalParam.checkedNodes;
        this.clauseFolderHierarchyTree.forEach((rootNode: FileNode) => {
            this.setFoldersDownloadActions(rootNode);
        });
    }

    protected createFolder(nodeAction: NodeTreeAction): void {
        if (nodeAction.node.value.trim() === '') {
            this.genericDialogService.showMessage(this.emptyFolderMessage).afterClosed();
            return;
        }

        const clauseFolder: ClauseFolderDto = {
            name: nodeAction.node.value,
            clauseFolderParentId: nodeAction.node.parentId === '0' ? NIL_UUID : nodeAction.node.parentId,
        };

        this.apiClauseFolders.create(clauseFolder).subscribe((createdFolder: ClauseFolderDto) => {
            nodeAction.node.id = createdFolder.id;
            this.refreshTree();
            this.sendTreeOperationCallbackSuccessful(nodeAction);
        }, (error: any) => {
            this.manageError(error).then((actionAfter: FolderPostOperationActions) => {
                this.doActionAfter(actionAfter, nodeAction);
            });
        });
    }

    protected renameFolder(nodeAction: NodeTreeAction): void {
        const { node } = nodeAction;

        const jsonPatchDocument: IJsonPatchDocument = {
            op: Op.REPLACE,
            path: '/name',
            value: node.value
        };

        const folderPatchModel: FolderPatchModel = {
            nodeAction,
            patchId: node.id,
            jsonPatchDocument,
            patchAction: PatchAction.rename
        };

        this.patchClauseFolder(folderPatchModel);
    }

    protected patchClauseFolder(folderPatchModel: FolderPatchModel) {
        const { node } = folderPatchModel.nodeAction;
        let nodeDestination: FileNode;
        if (folderPatchModel.patchAction === PatchAction.move) {
            nodeDestination = folderPatchModel.nodeAction.additionalParam.destinationNode;
        }

        this.apiClauseFolders.patch(folderPatchModel.patchId, [folderPatchModel.jsonPatchDocument]).subscribe(() => {
            this.sendTreeOperationCallbackSuccessful(folderPatchModel.nodeAction);
            if (folderPatchModel.patchAction === PatchAction.move) {
                this.showModalAfterFolderMoved(node, nodeDestination, folderPatchModel);
            }
            else {
                this.refreshTree();
                this.doActionAfter(FolderPostOperationActions.NO_ACTION, folderPatchModel.nodeAction);
            }

        }, (error: any) => {
            this.manageError(error).then((actionAfter: FolderPostOperationActions) => {
                this.doActionAfter(actionAfter, folderPatchModel.nodeAction);
            });
        });
    }

    protected doActionAfter(actionAfter: FolderPostOperationActions, nodeAction: NodeTreeAction) {
        switch (actionAfter) {
            case FolderPostOperationActions.NO_ACTION:
                this.sendTreeOperationCallbackFail(nodeAction);
                break;
            case FolderPostOperationActions.CANCEL:
            case FolderPostOperationActions.REFRESH:
                this.refreshTree();
                break;
        }
    }

    protected sendTreeOperationCallbackSuccessful(response: NodeTreeAction) {
        if (!response.successfulAction) {
            return;
        }

        response.successfulAction(response.node);
    }

    protected sendTreeOperationCallbackFail(response: NodeTreeAction) {
        if (!response.failAction) {
            return;
        }

        response.failAction(response.node);
    }

    protected manageError(error: any): Promise<FolderPostOperationActions> {
        return super.handleCommonError(error);
    }

    protected showModalAfterFolderMoved(node: FileFlatNode, nodeDestination: FileNode, folderPatchModel: FolderPatchModel) {
        const message = $localize`:@@MoverClausulaACarpetaClausulaMensaje: Se ha movido la clásula «${node.value}» a la carpeta «${nodeDestination.value}»`;
        const buttonText = $localize`:@@Cerrar:Cerrar`;
        this.genericDialogService.showMessage(message, buttonText).afterClosed().subscribe(() => {
            this.doActionAfter(FolderPostOperationActions.NO_ACTION, folderPatchModel.nodeAction);
            this.refreshTree();
        });
    }

    protected clearSearch(): void {
        this.noElementFoundInSearch = true;
        this.isSearchActive = false;
        this.currentSearchTerm = '';
        this.refresh();
    }

    protected searchInTree(searchTerm: string, loadContent: boolean) {
        this.currentSearchTerm = searchTerm;
        this.isSearchActive = !!this.currentSearchTerm.trim();

        this.clauseFolderSearchService.searchManagement(searchTerm, loadContent).subscribe(
            (searchFoldersResults: SearchClauseFoldersResultDto) => {
                this.results = searchFoldersResults.folderResults;
                this.noElementFoundInSearch = this.results.length < 1;
                this.folders = this.clauseFolderHierarchyTreeActionsService.highlightFolderOrContent(searchFoldersResults);

                this.resultOrdered = this.sortResultsAccordingToTheTree(this.clauseFolderHierarchyTree[0], this.results);
                this.totalResultOrdered = this.resultOrdered.length;
                this.indexResultOrdered = -1;

                if (!this.noElementFoundInSearch) {
                    this.clauseTreeSelectionModel = this.clauseFolderHierarchyTreeActionsService
                        .expandFoundResult(this.clauseTreeSelectionModel, this.folders, this.results);
                }

                this.clauseFolders = this.folders;
                this.loadFolderTree(this.folders);
                const forceSynchronize: ForceSynchronize = { force: true };
                this.synchronizeExpansionInTree$.next(forceSynchronize);
                if (this.noElementFoundInSearch) {
                    const message = $localize`:@@NoSeHanEncontradoResultadosEnLaBusqueda:No se han encontrado resultados para esta búsqueda.`;
                    this.genericDialogService.showMessage(message);
                    return;
                }

                this.waitRenderingResultsAndFocusInFirstResult();
            }
        );
    }

    protected refreshSearchInTree(searchTerm: string, loadContent: boolean) {
        this.currentSearchTerm = searchTerm;
        this.isSearchActive = !!this.currentSearchTerm.trim();

        this.clauseFolderSearchService.searchManagement(searchTerm, loadContent).subscribe(
            (searchFoldersResults: SearchClauseFoldersResultDto) => {
                this.results = searchFoldersResults.folderResults;
                this.noElementFoundInSearch = this.results.length < 1;
                this.folders = this.clauseFolderHierarchyTreeActionsService.highlightFolderOrContent(searchFoldersResults);

                this.clauseFolders = this.folders;
                this.loadFolderTree(this.folders);
                this.clausesChangesService.notifyClauseListNeedToBeReload();
                const forceSynchronize: ForceSynchronize = { force: true };
                this.synchronizeExpansionInTree$.next(forceSynchronize);
            }
        );
    }

    private navigateToResult(isOverflow: boolean): void {
        const folders = this.clauseFolderHierarchyTreeActionsService.highlightNavigateNextFolderOrContent(this.folders, this.results,
            this.resultOrdered[this.indexResultOrdered], isOverflow);
        const nodeToFocus = document.querySelector(`mat-tree-node[data-nodeid="${this.resultOrdered[this.indexResultOrdered].id}"]`);
        if (nodeToFocus !== null) {
            nodeToFocus.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
        this.clauseFolders = folders;
        this.loadFolderTree(folders);
    }

    private setFoldersDownloadActions(node: FileNode): void {
        if (node.type === NodeTreeNodeType.Clause) {
            return;
        }

        if (node.id !== this.clausesFolderTreeService.getRootNodeId()) {
            const hasAnyChildrenClauseChecked = this.hasAnyChildrenClauseChecked(node);
            if (hasAnyChildrenClauseChecked && !node.actions.includes(NodeTreeActionType.DownloadSelected)) {
                node.actions.push(NodeTreeActionType.DownloadSelected);
            } else if (!hasAnyChildrenClauseChecked && node.actions.includes(NodeTreeActionType.DownloadSelected)) {
                node.actions.splice(node.actions.indexOf(NodeTreeActionType.DownloadSelected), 1);
            }

            if (hasAnyChildrenClauseChecked && !node.actions.includes(NodeTreeActionType.DownloadSelectedUpdate)) {
                node.actions.push(NodeTreeActionType.DownloadSelectedUpdate);
            } else if (!hasAnyChildrenClauseChecked && node.actions.includes(NodeTreeActionType.DownloadSelectedUpdate)) {
                node.actions.splice(node.actions.indexOf(NodeTreeActionType.DownloadSelectedUpdate), 1);
            }
        }

        node.children.forEach((childNode: FileNode) => {
            this.setFoldersDownloadActions(childNode);
        });
    }

    private hasAnyChildrenClauseChecked(node: FileNode): boolean {
        if (node.children.some(child => this.currentCheckedNodeIds.includes(child.id) && child.type === NodeTreeNodeType.Clause)) {
            return true;
        }

        for (const child of node.children) {
            const hasAnyChildrenChecked = this.hasAnyChildrenClauseChecked(child);
            if (hasAnyChildrenChecked) {
                return true;
            }
        }

        return false;
    }

    private sortResultsAccordingToTheTree(templateFolderHierarchyTree: FileNode, unsortedResults: FolderResultDto[]): FolderResultDto[] {
        const sortedResults = [];

        const result = unsortedResults.find(resultUnsorted => resultUnsorted.id === templateFolderHierarchyTree.id);
        if (result) {
            const matches = result.searchResultHighlight.name.match(this.patternExpression);

            if (matches?.length > 1) {

                const multipleResults = [];

                for (let i = 0; i < matches.length; i++) {
                    let newNameReplacematches = result.searchResultHighlight.name;
                    newNameReplacematches = newNameReplacematches.replace(this.patternExpression, (match: string) => {
                        return match;
                    });
                    multipleResults.push(newNameReplacematches);
                }

                multipleResults.forEach(text => {
                    const newResult = JSON.parse(JSON.stringify(result));
                    newResult.searchResultHighlight.name = text;
                    sortedResults.push(newResult);
                });
            } else {
                sortedResults.push(result);
            }
        }

        if (templateFolderHierarchyTree.children?.length > 0) {
            templateFolderHierarchyTree.children.forEach((child: FileNode) => {
                const childrenResults = this.sortResultsAccordingToTheTree(child, unsortedResults);
                sortedResults.push(...childrenResults);
            });
        }

        return sortedResults;
    }

    private waitRenderingResultsAndFocusInFirstResult() {
        setTimeout(() => {
            const firstResult = document.querySelector('*[class="highlight"]');
            firstResult.scrollIntoView({ block: 'nearest' });
        });
    }
}
