import { Injectable } from '@angular/core';
import { UserDTO } from 'src/app/api';
import { IClausesDocumentRevisionListService } from './clauses-document-revision-list.service.interface';

@Injectable({
    providedIn: 'root'
})
export class ClausesDocumentRevisionListService extends IClausesDocumentRevisionListService {

    public getAuthorSurnameToOrder(user: UserDTO): string {
        if (user === null) {
            return ''.toLocaleLowerCase();
        }

        return user.surname.trim().toLocaleLowerCase();
    }
}
