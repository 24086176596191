export enum ClauseCreationType {
    edit = 0,
    empty = 1,
    fromFile = 2,
    fromLld = 3,
    fromSmartform = 4
}

export enum ClauseCreationOrigin {
    clause = 1,
    document = 2,
    annexed = 3,
}
