import { Component, EventEmitter, Input, Output } from '@angular/core';
import { DocumentWorkflowDto } from 'src/app/api';
import { WorkflowStatus } from 'src/app/core/standard/enums/workflow/workflow-status.enum';

@Component({
    selector: 'ctbox-workflow-state',
    templateUrl: './ctbox-workflow-state.component.html',
    styleUrls: ['./ctbox-workflow-state.component.scss']
})
export class CtboxWorkflowStateComponent {

    @Input() public workFlowTitle: string;
    @Input() public user: DocumentWorkflowDto;

    @Output() resumeWorkflowClicked = new EventEmitter<void>();

    public setStateName(): void | string {
        switch (this.user.workflowStatus.code) {
            case WorkflowStatus.Approved: return $localize`:@@WorkflowStatusApproved:Aprobado`;
            case WorkflowStatus.Expired: return $localize`:@@WorkflowStatusExpired:Expirado`;
            case WorkflowStatus.NotStarted: return $localize`:@@WorkflowStatusNotStarted:No iniciado`;
            case WorkflowStatus.Pending: return $localize`:@@WorkflowStatusPending:Pendiente`;
            case WorkflowStatus.Refused: return $localize`:@@WorkflowStatusRefused:Rechazado`;
            default: return $localize`:@@WorkflowStatusNotStarted:No iniciado`;
        }
    }

    public setStateClass(): void | string {
        switch (this.user.workflowStatus.code) {
            case WorkflowStatus.Approved: return 'approved';
            case WorkflowStatus.Expired: return 'expired';
            case WorkflowStatus.NotStarted: return 'not-started';
            case WorkflowStatus.Pending: return 'pending';
            case WorkflowStatus.Refused: return 'refused';
            default: return 'not-started';
        }
    }

    public canResumeWorkflow(): boolean {

        return this.user.workflowStatus.code === WorkflowStatus.Expired || this.user.workflowStatus.code === WorkflowStatus.Refused;
    }

    public resumeWorkflow() {
        this.resumeWorkflowClicked.emit();
    }

    public canDisplayDate(): boolean {
        return this.user.workflowStatus.code === WorkflowStatus.Pending || this.user.workflowStatus.code === WorkflowStatus.Expired;
    }

}
