
import { SignatureModel } from '../../models/signature/signature-model';
import { BaseCommand } from '../base-command';
import { SignaturePlugin } from '../../plugins/signature/signature-plugin';

export default class AddSignatureCommand extends BaseCommand {

    public labelDataModel = SignaturePlugin.SIGNATURE_IN_EDITOR;

    public override execute(value: SignatureModel): void {
        const editor = this.editor;

        editor.model.change( writer => {
            const signature = writer.createElement('signature', {
                'signature-title': value.role,
                'id': value.id
            });

            editor.model.insertObject( signature, null, null, {setSelection: 'after'} );
        } );
    }
}
