import { Injectable } from '@angular/core';
import { DocumentFolderDto, LastModificationFoldersService, PermissionUserDocumentFolderDto } from 'src/app/api';
import { AdministrationPermissionFolder } from '../../../models/administration-permission-folder.model';

@Injectable({
    providedIn: 'root'
})
export abstract class IAdministrationPermissionDocumentsFoldersService {

    abstract generateFoldersFromDocumentFolders(documentFolders: DocumentFolderDto[],
                                                permissionFoldersAccess: PermissionUserDocumentFolderDto[]):
                                                AdministrationPermissionFolder[];
    abstract getDocumentFoldersPermissions(permissionsFolder: AdministrationPermissionFolder[],
                                           userId: string): PermissionUserDocumentFolderDto[];
    abstract hasNewChangesDocumentFolders(lastModificationFoldersService: LastModificationFoldersService): Promise<boolean>;
    abstract updateDocumentFoldersChangesDate(): void;
}
