import { Directive, Inject } from '@angular/core';
import { ButtonView, Locale, LabelView, DropdownView, createDropdown, Collection, ListDropdownButtonDefinition, ViewModel, addListToDropdown, focusChildOnDropdownOpen, View, ViewCollection } from 'ckeditor5';
import BaseView from '../base-view.directive';
import FormRowView from '../../../utils/form-row-view.directive';
import ImageView from '../view/image-view.directive';
import { BaseInputModel } from '../../models/input/base-input-model';

@Directive({
    selector: 'app-add-radio-dialog-form-view',
})
export default class LinkedFieldDialogView extends BaseView {

    public aliasInfoFirstView: LabelView;
    public aliasInfoSecondView: LabelView;
    public aliasView: LabelView;
    public aliasInputView: DropdownView;

    public withoutAliasInfoFirstView: LabelView;
    public withoutAliasInfoSecondView: LabelView;
    public withoutAliasInfoThreeView: LabelView;
    public aliasImage: View;

    public foundAlias: string[] = [];

    private saveButtonView: ButtonView;
    private saveButton2View: ButtonView;
    private cancelButtonView: ButtonView;

    private labelAlias = $localize`:@@AliasEtiqueta:Alias`;
    private labelAliasInfoFirst = $localize`:@@InformacionEtiquetaAlias1:Selecciona el alias de un campo ya existente para crear campos vinculados entre sí.`;
    private labelAliasInfoSecond = $localize`:@@InformacionEtiquetaAlias2:Los campos vinculados se rellenan de forma simultánea con sólo rellenar uno de ellos.`;
    private labelWithoutAliasInfoFirst = $localize`:@@InformacionEtiquetaSinAlias1:Los campos vinculados se rellenan de forma simultánea con sólo rellenar uno de ellos.`;
    private labelWithoutAliasInfoSecond = $localize`:@@InformacionEtiquetaSinAlias2:Los campos vinculados se rellenan de forma simultánea con sólo rellenar uno de ellos.`;
    private labelWithoutAliasInfoThree = $localize`:@@InformacionEtiquetaSinAlias3:Los campos vinculados se rellenan de forma simultánea con sólo rellenar uno de ellos.`;
    private altTextImage = $localize`:@@TextoAlternativoImagenCrearAlias:Imagen crear alias`;

    private imageSrc = "assets/images/crear_alias.png"

    private inputModelToEdit: BaseInputModel = {
        id: '',
        alias: ''
    };
    private isEditing = false;

    private rowInfoFirst: FormRowView;
    private rowInfoSecond: FormRowView;
    private rowAlias: FormRowView;
    private rowInfoWithoutAliasFirst: FormRowView;
    private rowInfoWithoutAliasAliasSecond: FormRowView;
    private rowInfoWithoutAliasThree: FormRowView;
    private rowImage: FormRowView;
    private rowButtons: FormRowView;
    private rowButtonSave: FormRowView;

    private itemsDialog: ViewCollection;
    private itemsWithoutAliasDialog: ViewCollection;

    constructor(
        @Inject(Locale) locale?: Locale,
        @Inject(BaseInputModel) linkedFieldModel?: BaseInputModel
    ) {
        super(locale);

        this.initializeLinkedFieldModel(linkedFieldModel);

        this.initializeViews();
        this.initializeButtons();
        this.initializeRows(locale);
        this.inicializeItemsDialog();

        this.updateFields();

        this.setTemplateForm("ck-linked-field-form");
    }

    public set model(value: BaseInputModel) {
        if(!value) {
            return;
        }

        this.inputModelToEdit =  JSON.parse(JSON.stringify(value));
        this.isEditing = !!this.inputModelToEdit.id;
        this.updateFields();
    }

    public get model(): BaseInputModel {
        this.inputModelToEdit.alias = this.aliasInputView.buttonView.label;

        return this.inputModelToEdit;
    }

    public get id(): string {
        return this.inputModelToEdit.id;
    }

    public resetFormStatus(): void {
        this.inputModelToEdit = {
            id: '',
            alias: ''
        }
        this.isEditing = false;

        this.updateAllAliasInSelectorView();
        this.updateFields();
    }

    public isInCreation(): boolean {
        return !this.isEditing;
    }

    private initializeLinkedFieldModel(defaultLinkedFieldModel?: BaseInputModel): void {
        if (!!defaultLinkedFieldModel) {
            this.inputModelToEdit = JSON.parse(JSON.stringify(defaultLinkedFieldModel));
        } else {
            this.resetFormStatus();
        }

        this.isEditing = !!this.inputModelToEdit.id;
    }

    private updateFields() {
        this.getAllAlias();
        if (this.aliasInputView && this.inputModelToEdit.alias) {
            this.aliasInputView.buttonView.label = this.inputModelToEdit.alias;
        } else {
            this.aliasInputView.buttonView.label = this.foundAlias[0];
        }
        this.setItemsInDialog();
    }

    private getAllAlias(): void {
        const inputPlugins = Array.from(document.querySelectorAll('.input-plugin:not(.ck-pagination-view .input-plugin)'));

        this.foundAlias = [];

        inputPlugins.forEach((plugin) => {
            const alias = plugin.getAttribute('data-alias');
            const isLinked = plugin.getAttribute('data-is-linked');

            if (alias && isLinked === 'false' && !alias.startsWith('clauseAlias-')) {
                this.foundAlias.push(alias);
            }
        });
    }

    private updateAllAliasInSelectorView(): void {
        this.getAllAlias();

        if(!this.rowAlias) {
            this.aliasInputView = this.createSelector(this.foundAlias);
            return;
        }
        this.rowAlias.children.remove(this.aliasInputView);
        this.aliasInputView = this.createSelector(this.foundAlias);
        this.rowAlias.children.add(this.aliasInputView);
    }

    private initializeViews(): void {
        this.aliasInfoFirstView = this.createLabel(this.labelAliasInfoFirst);
        this.aliasInfoSecondView = this.createLabel(this.labelAliasInfoSecond);
        this.aliasView = this.createLabel(this.labelAlias);
        this.updateAllAliasInSelectorView();

        this.withoutAliasInfoFirstView = this.createLabel(this.labelWithoutAliasInfoFirst);
        this.withoutAliasInfoSecondView = this.createLabel(this.labelWithoutAliasInfoSecond);
        this.withoutAliasInfoThreeView = this.createLabel(this.labelWithoutAliasInfoThree);
        this.aliasImage = new ImageView(this.locale, this.imageSrc, this.altTextImage);
    }

    private initializeButtons(): void {
        const showTextInButton = true;
        this.saveButtonView = this.createButton(this.submitButtonMessage, "ck-button-save input-refillable", null, showTextInButton);
        this.saveButtonView.type = "submit";

        this.saveButton2View = this.createButton(this.submitButtonMessage, "ck-button-save input-refillable", null, showTextInButton);
        this.saveButton2View.delegate("execute").to(this, "cancel");

        this.cancelButtonView = this.createButton(this.cancelButtonMessage, "ck-button-cancel input-refillable", null, showTextInButton);
        this.cancelButtonView.delegate("execute").to(this, "cancel");
    }

    private initializeRows(locale: Locale): void {
        this.rowInfoFirst = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.aliasInfoFirstView
            ],
            class: 'alias-info'
        });

        this.rowInfoSecond = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.aliasInfoSecondView
            ],
            class: 'alias-info'
        });

        this.rowAlias = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.aliasView,
                this.aliasInputView,
            ],
            class: 'alias-dropdown'
        });

        this.rowInfoWithoutAliasFirst = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.withoutAliasInfoFirstView
            ],
            class: 'alias-info'
        });

        this.rowInfoWithoutAliasAliasSecond = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.withoutAliasInfoSecondView
            ],
            class: 'alias-info'
        });

        this.rowInfoWithoutAliasThree = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.withoutAliasInfoThreeView
            ],
            class: 'alias-info'
        });

        this.rowImage = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.aliasImage
            ],
            class: 'alias-image'
        });

        this.rowButtons = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.saveButtonView,
                this.cancelButtonView
            ],
            class: 'footer-buttons'
        });

        this.rowButtonSave = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.saveButton2View
            ],
            class: 'footer-button-save'
        });
    }

    private inicializeItemsDialog(): void {
        this.items = new ViewCollection<View<HTMLElement>>();
        this.itemsDialog = this.createCollection([
            this.rowInfoFirst,
            this.rowInfoSecond,
            this.rowAlias,
            this.rowButtons
        ]);
        this.itemsWithoutAliasDialog = this.createCollection([
            this.rowInfoWithoutAliasFirst,
            this.rowInfoWithoutAliasAliasSecond,
            this.rowInfoWithoutAliasThree,
            this.rowImage,
            this.rowButtonSave
        ]);
    }

    private setItemsInDialog(): void {
        if(this.foundAlias.length > 0) {
            this.items.clear();
            this.items.addMany(this.itemsDialog);
        } else {
            this.items.clear();
            this.items.addMany(this.itemsWithoutAliasDialog);
        }
    }

    private createLabel(labelContent: string): LabelView {
        const label = new LabelView();
        label.text = labelContent;
        label.extendTemplate({
            attributes: {
                class: 'label-input'
            }
        });
        return label;
    }

    private createSelector(listItemSelector: string[], defaultValue?: string): any {
        const dropdown = createDropdown(this.locale);
        const items = new Collection<ListDropdownButtonDefinition>();
        dropdown.buttonView.set({
            withText: true
        });

        listItemSelector.forEach((item) => {
            const value = item;

            items.add({
                type: 'button',
                model: new ViewModel({
                    withText: true,
                    label: value,
                })
            });
        });

        if (defaultValue) {
            dropdown.buttonView.label = defaultValue;
        } else if (listItemSelector.length) {
            dropdown.buttonView.label = listItemSelector[0] ?? '';
        }

        addListToDropdown(dropdown, items);

        this.listenTo(dropdown, 'execute', evt => {
            const choice: any = evt.source;
            const text = choice.element.innerText;
            if (!!text) {
                dropdown.buttonView.label = text;
            } else {
                dropdown.buttonView.label = "";
            }
        });

        focusChildOnDropdownOpen(dropdown, () => {
            const selectedItem = dropdown.listView.items.find(item =>
                Array.from((item as any).children).some(child =>
                    child instanceof ButtonView && child.label === dropdown.buttonView.label
                )
            ) || dropdown.listView.items[0];

            return selectedItem;
        });

        return dropdown;
    }
}

export type AddLinkedFieldFormValidatorCallback = (form: LinkedFieldDialogView) => string | undefined;
