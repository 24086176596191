export abstract class ITemplatesConvertService {
    abstract updateTemplateName(templateName: string);
    abstract getCurrentTemplateName(): string ;
    abstract updateTemplateContent(templateContent: string);
    abstract getCurrentTemplateContent(): string ;
    abstract updateTemplateFileExtension(extension: string);
    abstract getNotEditableFileExtension(): string;
    abstract updateNotEditableFile(file: File);
    abstract getNotEditableFile(): File;
}
