/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AnnexedDTO } from './annexedDTO';
import { DocumentFileDTO } from './documentFileDTO';
import { DocumentFolderDto } from './documentFolderDto';
import { DocumentSharedDTO } from './documentSharedDTO';
import { DocumentSheetDto } from './documentSheetDto';
import { DocumentSignatureDTO } from './documentSignatureDTO';
import { DocumentVersionDTO } from './documentVersionDTO';
import { DocumentWorkflowDto } from './documentWorkflowDto';
import { ExpirationWarningDto } from './expirationWarningDto';
import { MasterData } from './masterData';
import { OriginTemplateVersionDTO } from './originTemplateVersionDTO';
import { UserDTO } from './userDTO';
import { UserPermissionsOnDocumentDTO } from './userPermissionsOnDocumentDTO';
import { WarningChangesDTO } from './warningChangesDTO';

export interface ContractDTO {
    id?: string;
    name: string;
    html?: string;
    creationTimeStamp?: Date;
    modificationTimeStamp?: Date;
    creationUser?: UserDTO;
    lastModificationUser?: UserDTO;
    buyerId?: string;
    buyer?: UserDTO;
    userPermissions?: UserPermissionsOnDocumentDTO;
    currentReviewerUserId?: string;
    iddOrigin?: string;
    warningChanges?: WarningChangesDTO;
    originTemplateVersion?: OriginTemplateVersionDTO;
    documentTypeId?: string;
    documentTypes?: MasterData;
    documentSheet?: DocumentSheetDto;
    documentFolderId?: string;
    documentFolder?: DocumentFolderDto;
    documentShared?: Array<DocumentSharedDTO>;
    documentVersion?: Array<DocumentVersionDTO>;
    hasCurrentUserSameBuyer?: boolean;
    documentSignatureId?: string;
    documentSignature?: DocumentSignatureDTO;
    sharedByUser?: UserDTO;
    folderOwnerUserId?: string;
    folderOwnerUser?: UserDTO;
    documentStatusId?: string;
    documentStatus?: MasterData;
    hasRelatedDocuments?: boolean;
    documentFile?: DocumentFileDTO;
    expirationWarning?: ExpirationWarningDto;
    workflow?: Array<DocumentWorkflowDto>;
    hasWorkflow?: boolean;
    annexes?: Array<AnnexedDTO>;
    comments?: string;
}
