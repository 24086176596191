import { SelectionModel } from '@angular/cdk/collections';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ClauseFolderDto, UserFolderPermissionsDto } from 'src/app/api';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { NodeTreeNodeType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-node-type.enum';
import { FileNode } from 'src/app/shared/components/ctbox-tree/models/file-node.model';
import { SortTypeAlphabetical } from 'src/app/shared/pipes/sort-type-alphabetical.pipe';

export abstract class BaseClauseTreeService {

    protected currentExpansionModel = new BehaviorSubject<SelectionModel<string>>(new SelectionModel<string>(true));
    protected rootNodeId = '0';
    protected pipe = new SortTypeAlphabetical();
    protected rootNodeName = $localize`:@@NombreRaizClausulas:Cláusulas de mi corporación`;
    protected readonly EMPTY_GUID = '00000000-0000-0000-0000-000000000000';

    protected nodeTreeActionsMapper = new Map<NodeTreeActionType, string>()

    public constructor() {
        this.initializeNodeTreeActionsMapper();
    }

    protected currentClauseFolderHierarchyTree = new Subject<ClauseFolderDto[]>();

    public getRootNodeName(): string {
        return this.rootNodeName;
    }

    public getRootNodeId(): string {
        return this.rootNodeId;
    }

    public setCurrentExpansionModel(selectionModel: SelectionModel<string>): void {
        this.currentExpansionModel.next(selectionModel);
    }

    public getCurrentExpansionModel(): Observable<SelectionModel<string>> {
        return this.currentExpansionModel.asObservable();
    }

    public setCurrentClausesFolderHiearchyTree(clauseFolderHiearchyTree: ClauseFolderDto[]): void {
        this.currentClauseFolderHierarchyTree.next(clauseFolderHiearchyTree);
    }

    public getCurrentClausesFolderHiearchyTree(): Observable<ClauseFolderDto[]> {
        return this.currentClauseFolderHierarchyTree.asObservable();
    }

    protected createRootNode(permissions: NodeTreeActionType[]): FileNode {
        return {
            id: this.rootNodeId,
            value: this.rootNodeName,
            children: [],
            type: NodeTreeNodeType.Folder,
            parentId: '',
            actions: permissions,
            isChecked: true
        };
    }

    protected getNodeActionsFilteredByUserPermissions(nodeActions: NodeTreeActionType[], userPermission: UserFolderPermissionsDto): NodeTreeActionType[] {
        return nodeActions.map(na =>  {
            if (this.nodeTreeActionsMapper.has(na)) {
                const property = this.nodeTreeActionsMapper.get(na);
                if (userPermission[property]) {
                    return na;
                }
            } else {
                return na;
            }
        }).filter(Boolean);
    }

    private initializeNodeTreeActionsMapper(): void {
        this.nodeTreeActionsMapper.set(NodeTreeActionType.Rename, 'canEditName');
        this.nodeTreeActionsMapper.set(NodeTreeActionType.Create, 'canCreateChildren');
        this.nodeTreeActionsMapper.set(NodeTreeActionType.Delete, 'canDelete');
        this.nodeTreeActionsMapper.set(NodeTreeActionType.CanSeeContent, 'canSeeContent');
        this.nodeTreeActionsMapper.set(NodeTreeActionType.CanCreateContent, 'canCreateContent');
        this.nodeTreeActionsMapper.set(NodeTreeActionType.Move, 'canMoveFrom');
        this.nodeTreeActionsMapper.set(NodeTreeActionType.MoveTo, 'canMoveTo');
    }
}
