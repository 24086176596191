export class BaseInputModel {
    id?: string;
    name?: string;
    help?: string;
    type?: string;
    value?: string | Date | number;
    alias?: string;
    isValid?: boolean;
    isLinked?: boolean;
    embeddedIn?: string;
}
