import { isValueEmptyValidator } from 'src/app/core/shared/validators/valueEmptyValidator';
import { ValidatorRegexConstant } from './validator-regex-constant';

export function IBANValidator(iban: string): boolean {
    if (isValueEmptyValidator(iban)) {
        return false;
    }

    return ValidatorRegexConstant.CUSTOM_IBAN_REGEX.test(iban);
}
