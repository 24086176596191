<form [formGroup]="signatureForm" class="form-sign">
    <mat-form-field class="with-hint">
        <mat-label i18n="@@RolFirmante">Rol del firmante</mat-label>
        <input #name [(ngModel)]="signature.role" matInput formControlName="role">
        <mat-error>
            <p *ngIf="signatureForm.invalid && signatureForm.get('role').hasError('maxlength')" i18n="@@ValidacionRolDemasiadoLargoMensaje">El rol no puede tener más de 50 caracteres.</p>
            <p *ngIf="signatureForm.invalid && signatureForm.get('role').hasError('invalidCharacters')" i18n="@@ValidacionRolCaracteresInvalidosMensaje">Hay caracteres inválidos en el rol. Utiliza solo caracteres alfanuméricos</p>
            <p *ngIf="signatureForm.invalid && signatureForm.get('role').hasError('valueNotInGroup')" i18n="@@RolRepetidoInsertarFirmaMensaje">Este rol ya está en el documento, añada otro</p>
        </mat-error>
    </mat-form-field>
</form>