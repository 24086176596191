import { Injectable } from "@angular/core";
import { ApiOperation, ApiProcessorService, ApiRequest } from "./api-processor.service";
import { ReportConfigurationDto } from "../model/cmc/reportConfigurationDto";
import { Observable } from "rxjs";

@Injectable()
export class ReportsService {

    protected basePath = '/api/report-management/reports/generate';

    constructor(private readonly apiProcessorService: ApiProcessorService) {  }

    public generate(reportConfiguration: ReportConfigurationDto): Observable<any> {
        const apiRequest: ApiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = reportConfiguration;
        apiRequest.endpointUrl = `${ this.basePath }`;
        apiRequest.responseType = 'blob';

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
