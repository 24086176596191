<div class="wf-state ctbox-elevation">
    <div class="wf-state-title">
        <ng-containter i18n="@@EstadoDe">Estado de </ng-containter> {{workFlowTitle}}
    </div>
    <div class="wf-state-mode">
        <div class="wf-state-{{setStateClass()}}">{{setStateName()}}</div>
        <ctbox-button *ngIf="canResumeWorkflow()" (buttonClick)="resumeWorkflow()" buttonType="secondary" buttonIcon="replay" customClass="filled" buttonText="Reenviar"></ctbox-button>
    </div>
    <div class="wf-state-date" *ngIf="canDisplayDate()">
        <div class="wf-state-label" i18n="@@FechaExpiracion">Fecha de expiración: </div>
        <div class="wf-state-value">{{ this.user.expirationDate | dateDayOnlyFormat}}</div>
    </div>
</div>