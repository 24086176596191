<div class="c-help">
    <div class="c-help-header">
        <h3 class="c-help-header-title c-help-draft">Borradores</h3>
        <p class="c-help-header-text">En Borradores podrás consultar y gestionar los documentos que estás creando en primera fase, pendientes de completar.</p>
    </div>
    <div class="c-help-content">
        <mat-tab-group mat-align-tabs="start" class="c-help draft">
            <mat-tab>
                <ng-template mat-tab-label>Acciones a realizar</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonIcon="circle" buttonColor="state" customClass="filled no-border draft xsmall"></ctbox-button></div>
                                <p><strong>Cambiar estado:</strong> Para cambiar el estado de los documentos creados por ti.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIconSvg="editable" buttonColor="info"></ctbox-button></div>
                                <p><strong>Editar:</strong> Para editar el contenido de un documento.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="share" buttonColor="info"></ctbox-button></div>
                                <p><strong>Compartir:</strong> Permite compartir documentos que se quieran revisar o negociar con el control de cambios y podrán realizar comentarios.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIconSvg="signature" buttonColor="info"></ctbox-button></div>
                                <p><strong>Enviar a firma:</strong> Permite enviar a firmar los documentos o comprobar los datos del envío actual.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="delete" buttonColor="delete"></ctbox-button></div>
                                <p><strong>Borrar:</strong> Para eliminar documentos.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="download" buttonColor="info"></ctbox-button></div>
                                <p><strong>Descargar:</strong> Para descargar documentos.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Organización de documentos</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <h4 class="c-help-draft">Mis documentos</h4>
                                </div>
                                <p>Éste es tu espacio personal. Tanto las carpetas como los documentos son sólo visibles para ti. Si quieres que el resto de compañeros vea tus documentos muévelos a Nuestros documentos.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img">
                                    <h4 class="c-help-draft">Nuestros documentos</h4>
                                </div>
                                <p>Éste es el espacio corporativo de tu compañía. Estos documentos serán visibles sólo para los usuarios que tengan permisos de acceso en estas carpetas.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>
