import { Pipe, PipeTransform } from '@angular/core';
import moment from 'moment';

@Pipe({
    name: 'dateDayOnlyFormat'
})
export class DateDayOnlyFormatPipe implements PipeTransform {
    transform(value: Date): string {
        if(value == null || !moment(value).isValid()){
             return "";
        }
        return moment(value).format('DD/MM/YYYY');
    }
}
