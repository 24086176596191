<form [formGroup]="versionForm" class="save-as version-add-comment">
    <p class="info-message" *ngIf="message">
        {{message}}
    </p>
    <mat-checkbox (change)="changeOption($event.checked)" *ngIf="stringToBoolean(hasCheck)">
        <mat-label class="form-group-label"><span i18n="@@GenerarVersion">Generar versión</span>: {{version}}</mat-label>
    </mat-checkbox>
    <div class="form-group-block">
        <div class="form-group-flex fcol coment">
            <mat-label class="form-group-label" i18n="@@TituloVersion">Título de la versión</mat-label>
            <mat-form-field class="size-100" [ngClass]="{'disabled': !isBlocked()}">
                <input matInput [formControlName]="'name'" [name]="values.title" (keypress)="controlKeyEnter($event)" [readonly]="!isBlocked()">
                <mat-error>
                    <span *ngIf="versionForm.controls.name.errors?.maxlength" i18n="@@ValidacionMensajeNombreLongitud">La longitud no puede superar {{MAX_LENGTH_NAME}} caracteres.</span>
                    <span *ngIf="versionForm.controls.name.errors?.invalidCharacters"><span i18n="@@caracteresInvalidos">Caracteres inválidos introducidos: </span>
                        {{versionForm.controls.name.errors.invalidCharacters}}
                    </span>
                </mat-error>
            </mat-form-field>
        </div>
        <div class="form-group-flex fcol coment">
            <mat-label class="form-group-label" i18n="@@Comentario">Comentario</mat-label>
            <mat-form-field class="coment" [ngClass]="{'disabled': !isBlocked()}">
                <textarea matInput [formControlName]="'comments'" [name]="values.comment" (keypress)="controlKeyEnter($event)" [readonly]="!isBlocked()"></textarea>
                <mat-error>
                    <span *ngIf="versionForm.controls.comments.errors?.maxlength" i18n="@@ValidacionMensajeNombreLongitud">La longitud no puede superar {{MAX_LENGTH_COMMENT}} caracteres.</span>
                    <span *ngIf="versionForm.controls.comments.errors?.invalidCharacters"><span i18n="@@caracteresInvalidos">Caracteres inválidos introducidos: </span>
                        {{versionForm.controls.comments.errors.invalidCharacters}}
                    </span>
                </mat-error>
            </mat-form-field>
        </div>
    </div>
</form>
