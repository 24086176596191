import { BaseEntityDto } from "./baseEntityDto";
import { EmbeddedTemplateVersionClauseVersionDto } from "./embeddedTemplateVersionClauseVersionDto";

export interface TemplateVersionDTO extends BaseEntityDto {
    templateId: string;
    title?: string;
    html?: string;
    comment?: string;
    version?: number;
    embeddedTemplateVersionClauseVersions?: EmbeddedTemplateVersionClauseVersionDto[];
    embededClausesChanged?: boolean;
    hasBeenInForce?: boolean;
}
