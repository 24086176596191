import { Injectable } from '@angular/core';
import { ClauseFolderDto, LastModificationFoldersService } from 'src/app/api';
import { AdministrationPermissionFolder } from '../../../models/administration-permission-folder.model';

@Injectable({
    providedIn: 'root'
})
export abstract class IAdministrationPermissionClausesFoldersService {

    abstract generateFoldersFromClauseFolders(clauseFolders: ClauseFolderDto[]): AdministrationPermissionFolder[];
    abstract hasNewChangesClauseFolders(lastModificationFoldersService: LastModificationFoldersService): Promise<boolean>;
    abstract updateClauseFolderChangesDate(): void;
}
