import { Injectable } from '@angular/core';
import { Conversion } from 'ckeditor5';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';
import { RadioDataViewUtilsService } from '../../utils/radio/radio-data-view-utils.service';

@Injectable({
    providedIn: 'root'
})
export class RadioModelToDataViewConverterService {

    private dataUtilsService: RadioDataViewUtilsService;

    constructor() {
        this.dataUtilsService = new RadioDataViewUtilsService();
    }

    public configureConverters(conversion: Conversion) {
        this.containerConversion(conversion);
        this.attributeOptionInContainerConversion(conversion);
        this.optionConversion(conversion);
        this.inputConversion(conversion);
        this.contentConversion(conversion);
        this.descriptionConversion(conversion);
    }

    private contentConversion(conversion: Conversion) {
        conversion.for("dataDowncast").elementToStructure({
            model: RadioPlugin.MODEL_ENTITIES.content.model,
            view: (modelItem, { writer }) => writer.createSlot()
        });
    }

    private descriptionConversion (conversion: Conversion) {
        conversion.for("dataDowncast").elementToElement({
            model: RadioPlugin.MODEL_ENTITIES.description.model,
            view: (_modelItem, { writer }) => this.dataUtilsService.createDescriptionElementDataView(writer),
        });
    }

    private inputConversion(conversion: Conversion) {
        conversion.for("dataDowncast").elementToElement({
            model: RadioPlugin.MODEL_ENTITIES.input.model,
            view: (modelItem, { writer }) => this.dataUtilsService.createRadiusElementDataView(modelItem, writer),
        });
    }

    private optionConversion(conversion: Conversion) {
        conversion.for("dataDowncast").elementToElement({
            model: RadioPlugin.MODEL_ENTITIES.option.model,
            view: (modelItem, { writer }) => this.dataUtilsService.createOptionDataView(modelItem, writer),
        });
    }

    private attributeOptionInContainerConversion(conversion: Conversion) {
        conversion.for('dataDowncast').attributeToAttribute({
            model: RadioPlugin.MODEL_ENTITIES.optionCount.model,
            view: RadioPlugin.MODEL_ENTITIES.optionCount.dataView
        });
    }

    private containerConversion(conversion: Conversion) {
        conversion.for("dataDowncast").add(downcastDispatcher => {
            downcastDispatcher.on(`insert:${RadioPlugin.MODEL_ENTITIES.container.model}`, (_evt, data, conversionApi) => {
                if (!conversionApi.consumable.consume(data.item, 'insert')) {
                    return;
                }

                const modelItem = data.item;
                const id = modelItem.getAttribute("id");

                const viewPosition = conversionApi.mapper.toViewPosition(data.range.start);
                const linkRadiusDataView = conversionApi.writer.createEmptyElement('a',
                    {
                        class: [RadioPlugin.CONTAINER_CLASS_SIBLING_LINK_DATA_VIEW],
                        id
                    });
                const radiusDataView = this.dataUtilsService.createRadiusDataView(modelItem, conversionApi.writer);

                conversionApi.mapper.bindElements(data.item, radiusDataView);

                conversionApi.writer.insert(viewPosition, radiusDataView);
                conversionApi.writer.insert(viewPosition, linkRadiusDataView);
            });
        });
    }
}
