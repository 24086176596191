import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';
import { DocumentStatusCodes, DocumentStatusValues } from 'src/app/core/standard/enums/documents/document-status.enum';
import { DocumentTypeCodes } from 'src/app/core/shared/enums/document-type-codes.enum';
import {
    DocumentFolderDto, DocumentDTO, DocumentStatus,
    MasterData, SignatureStatus, UserDTO, UserInfoDTO, UserPermissionsOnDocumentDTO, DocumentsInfoDTO, DocumentFileDTO
} from 'src/app/api/model/models';
import { IDocumentListService } from './document-list.service.interface';
import { WorkflowStatus } from 'src/app/core/standard/enums/workflow/workflow-status.enum';
import { DocumentService } from 'src/app/api';
import { IDocumentsFolderService } from '../documents-folder/documents-folder.service.interface';

@Injectable({
    providedIn: 'root'
})
export class DocumentListService extends IDocumentListService {

    private totalDocumentObservable = new Subject<DocumentsInfoDTO>();

    constructor(private documentsFolderService: IDocumentsFolderService,
                private documentService: DocumentService) {
        super();
    }

    public getAuthorSurnameToOrder(user: UserDTO): string {
        if (user === null) {
            return ''.toLocaleLowerCase();
        }

        return user.surname.trim().toLocaleLowerCase();
    }

    public getSignerSurnameToOrder(document: DocumentDTO): string {
        if (document.documentSignature === null || document.documentSignature === undefined) {
            return ''.toLocaleLowerCase();
        }

        const FIRST_USER = 0;
        const firstUser = document.documentSignature.signaturesUsers[FIRST_USER];
        if (firstUser.fullName === null) {
            return ''.toLocaleLowerCase();
        }

        return firstUser.fullName.trim().toLocaleLowerCase();
    }

    public getAlertsToOrder(document: DocumentDTO): number {
        let totalAlerts = 0;
        if (document?.warningChanges?.hasTemplateLLDPendingRevision && document?.userPermissions?.canSeeTemplateLldChanges) {
            totalAlerts++;
        }
        if (document?.warningChanges?.hasTemplatePendingRevision && document?.userPermissions?.canSeeTemplateChanges) {
            totalAlerts++;
        }
        if (document?.warningChanges?.hasClausesPendingRevision && document?.userPermissions?.canSeeClauseChanges) {
            totalAlerts++;
        }
        if (document?.documentSignature && document?.documentSignature.forSignaturePreviously && document?.userPermissions?.canSendToSign) {
            totalAlerts += 4;
        }
        if (document?.expirationWarning?.currentExpiration) {
            totalAlerts += 8;
        }
        return totalAlerts;
    }

    public getDocumentStatusToOrder(documentStatus: MasterData): DocumentStatusCodes {
        return documentStatus?.code;
    }

    public all(): string {
        return 'documents';
    }

    public getTabToNavigate(state: DocumentStatusCodes): string {
        return state ? DocumentStatusValues[state] : this.all();
    }

    public myDocumentsTab(): string {
        return 'myDocuments';
    }

    public ourDocumentsTab(): string {
        return 'ourDocuments';
    }

    public sharedWithMeTab(): string {
        return 'sharedWithMe';
    }

    public sharedByMeTab(): string {
        return 'sharedByMeTab';
    }

    public getSignatureUserSigned(): SignatureStatus {
        return SignatureStatus.NUMBER_1;
    }

    public getSignatureUserUnsigned(): SignatureStatus {
        return SignatureStatus.NUMBER_2;
    }

    public getSignatureUserRejectd(): SignatureStatus {
        return SignatureStatus.NUMBER_0;
    }

    public getDocumentSignatureSigned(): DocumentStatus {
        return DocumentStatus.NUMBER_0;
    }

    public getDocumentSignatureUnsigned(): DocumentStatus {
        return DocumentStatus.NUMBER_2;
    }

    public getDocumentSignatureReject(): DocumentStatus {
        return DocumentStatus.NUMBER_1;
    }

    public getContractFolderToOrder(contractFolder: DocumentFolderDto, currentUserHasSameBuyer: boolean,
                                    isCommon: boolean): string {
        const folderName = contractFolder ? contractFolder.name : '';

        return this.documentsFolderService.getCorrectedFolderName(folderName, currentUserHasSameBuyer, isCommon);
    }

    public getActionToOrder(userPermissions: UserPermissionsOnDocumentDTO): number {
        let actionsValue = 0;

        if (userPermissions === null) {
            return actionsValue;
        }

        if (userPermissions.canEdit) {
            actionsValue += 2;
        }

        if (userPermissions.canShare) {
            actionsValue += 5;
        }

        if (userPermissions.canSendToSign) {
            actionsValue += 7;
        }

        if (userPermissions.canDeleteDocument) {
            actionsValue += 11;
        }

        if (userPermissions.canChangeState) {
            actionsValue += 21;
        }

        return actionsValue;
    }

    public getIconToOrder(document: DocumentDTO, userinfo: UserInfoDTO): number {
        let iconValue = 0;

        if (userinfo === null) {
            return iconValue;
        }

        if (document.creationUser.id === userinfo.guid) {
            iconValue += 2;
        }

        if (document?.buyerId === userinfo?.adminGuid) {
            iconValue += 3;
        }

        return iconValue;
    }

    public getIconTypeToOrder(document: DocumentDTO): number {
        if (document?.documentTypes?.code === DocumentTypeCodes.Contract && !document?.hasRelatedDocuments) {
            return -1;
        }
        return document?.documentTypes?.code;
    }

    public getExpirationDate(document: DocumentDTO, userInfo: UserInfoDTO): Date {
        if (document?.documentStatus?.code === DocumentStatusCodes.ForSignature) {
            return document.documentSignature?.expiredDate;
        }

        if (document?.hasWorkflow && document?.workflow.length > 0) {
            return document.workflow.find(p => p.workflowStatus.code === WorkflowStatus.Pending)?.expirationDate;
        }

        if (document?.documentShared?.length > 0) {
            const currentUserShared = document.documentShared.find(sharedUser => sharedUser.isRevisor &&
                sharedUser.expiredDate && sharedUser.email === userInfo?.email);

            return currentUserShared?.expiredDate;
        }

        return null;
    }

    public isDocumentEditable(document: DocumentDTO): boolean {
        return document && (!document.documentFile || document.documentFile?.isEditable);
    }

    public isDocumentFileEditable(documentFile: DocumentFileDTO): boolean {
        return !documentFile || documentFile?.isEditable;
    }

    public isDocumentSignedWithSignatureService(document: DocumentDTO): boolean {
        return document?.documentStatus?.code === DocumentStatusCodes.Signed &&
            document?.documentSignature?.docStatus === this.getDocumentSignatureSigned();
    }

    public showChangeStateOption(document: DocumentDTO): boolean {
        return document.userPermissions && document.userPermissions.canChangeState
            && document.documentStatus.code !== DocumentStatusCodes.ForSignature
            && document.documentStatus.code !== DocumentStatusCodes.Signed
            && !document.hasWorkflow;
    }

    public setDocumentInfo(documentInfo: DocumentsInfoDTO): void {
        this.totalDocumentObservable.next(documentInfo);
    }

    public getDocumentInfoObservable(): Observable<DocumentsInfoDTO> {
        return this.totalDocumentObservable.asObservable();
    }

    public retrieveDocumentInfo(): void {
        this.documentService.documentDocumentsInfoGet().subscribe((data: DocumentsInfoDTO) => {
            this.totalDocumentObservable.next(data);
        });
    }

}
