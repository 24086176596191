import { Injectable } from "@angular/core";
import { DomEventData, Editor, Item, ViewContainerElement, Writer } from "ckeditor5";
import { InputUtilsService } from "../input/input-utils.service";
import { GlobalConstant } from "../../models/base/global-constant";
import { InputPlugin } from "../../plugins/input/input-plugin";
import { BaseInputModel } from "../../models/input/base-input-model";
import { ShortTextInput } from "../../models/input/short-text-input-model";
import { PluginUtilsService } from "../../../utils/plugin-utils.service";

@Injectable({
    providedIn: "root",
})
export class LinkedFieldUtilsService {

    private inputUtilsService: InputUtilsService;
    private pluginUtils: PluginUtilsService;
    private ID_LINKED_FIELD_PREFFIX = 'ck-linked-field-';

    constructor() {
        this.inputUtilsService = new InputUtilsService();
        this.pluginUtils = new PluginUtilsService();
    }

    public getInputModelWithDataDom(editor: Editor, data: DomEventData) : BaseInputModel {
        const elementClicked = data.domTarget;
        const isInputContainer = (' ' + elementClicked.getAttribute('class') + ' ').includes(` ${InputPlugin.MODEL_ENTITIES.container.editionView} `);
        const isInputContent =  (' ' + elementClicked.getAttribute('class') + ' ').includes(` ${InputPlugin.MODEL_ENTITIES.content.editionView} `);

        if (isInputContainer) {
            return this.getInputModelWithElementView(elementClicked);
        } else if (isInputContent) {
            return this.getInputModelWithElementView(elementClicked.parentElement);
        } else {
            const parentElement = this.pluginUtils.getSelectedContainerWithClass(editor, InputPlugin.MODEL_ENTITIES.container.editionView);
            return !!parentElement ? this.getInputModelWithElementView(parentElement) : null ;
        }
    }

    public getInputModelWithElement(inputModel: Item): BaseInputModel {
        const hasPattern = inputModel.hasAttribute(InputPlugin.MODEL_ENTITIES.pattern.model);
        const hasTransform = inputModel.hasAttribute(InputPlugin.MODEL_ENTITIES.transform.model);

        const model = (hasPattern || hasTransform) ? new ShortTextInput() : new BaseInputModel();

        model.alias = inputModel.getAttribute(InputPlugin.MODEL_ENTITIES.alias.model).toString();
        model.type = inputModel.getAttribute(InputPlugin.MODEL_ENTITIES.type.model).toString();
        model.isValid = inputModel.getAttribute(InputPlugin.MODEL_ENTITIES.isValid.model) === true;
        model.id = inputModel.getAttribute(GlobalConstant.ATTRIBUTE_ID).toString()
        model.help = inputModel.getAttribute(InputPlugin.MODEL_ENTITIES.help.model).toString();
        model.value = this.inputUtilsService.getInputValue(inputModel);
        model.isLinked = inputModel.getAttribute(InputPlugin.MODEL_ENTITIES.isLinked.model) === true;
        model.embeddedIn = inputModel.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN) !== undefined
                           ? inputModel.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN).toString()
                           : undefined;

        if (model instanceof  ShortTextInput) {
            (model as ShortTextInput).pattern = inputModel.getAttribute(InputPlugin.MODEL_ENTITIES.pattern.model).toString();
            (model as ShortTextInput).transform = inputModel.getAttribute(InputPlugin.MODEL_ENTITIES.transform.model).toString();
        }

        return model;
    }

    public getInputModelWithElementView(modelHtml: HTMLElement | ViewContainerElement): BaseInputModel {
        const hasPattern = modelHtml.hasAttribute(InputPlugin.MODEL_ENTITIES.pattern.editionView);
        const hasTransform = modelHtml.hasAttribute(InputPlugin.MODEL_ENTITIES.transform.editionView);

        const model = (hasPattern || hasTransform) ? new ShortTextInput() : new BaseInputModel();

        model.alias = modelHtml.getAttribute(InputPlugin.MODEL_ENTITIES.alias.editionView).toString();
        model.type = modelHtml.getAttribute(InputPlugin.MODEL_ENTITIES.type.editionView).toString();
        model.isValid = modelHtml.getAttribute(InputPlugin.MODEL_ENTITIES.isValid.editionView) === 'true';
        model.id = modelHtml.getAttribute(GlobalConstant.ATTRIBUTE_ID).toString();
        model.help = modelHtml.getAttribute(InputPlugin.MODEL_ENTITIES.help.editionView).toString();
        model.value = modelHtml.getAttribute(GlobalConstant.ATTRIBUTE_VALUE).toString();
        model.isLinked = modelHtml.getAttribute(InputPlugin.MODEL_ENTITIES.isLinked.editionView) === 'true';
        model.embeddedIn = modelHtml.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN) !== undefined
                           ? modelHtml.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN).toString()
                           : undefined;

        if (model instanceof  ShortTextInput) {
            (model as ShortTextInput).pattern = modelHtml.getAttribute(InputPlugin.MODEL_ENTITIES.pattern.editionView).toString();
            (model as ShortTextInput).transform = modelHtml.getAttribute(InputPlugin.MODEL_ENTITIES.transform.editionView).toString();
        }

        return model;
    }

    public createLinkedFieldStructureModel(writer: Writer, typeLinkedField: string, linkedFieldModel: BaseInputModel) {
        const modelElement = this.createLinkedFieldElementModel(writer, typeLinkedField, linkedFieldModel);
        const nameModelElement = this.createLinkedFieldContentElementModel(writer, linkedFieldModel);
        writer.insert(nameModelElement, modelElement, 0);

        return modelElement;
    }

    public updateAliasAttributes(description: string, prefix: string): string {
        const parser = new DOMParser();
        const doc = parser.parseFromString(description, 'text/html');

        const elements = doc.querySelectorAll('[data-alias]');
        elements.forEach(element => {
            const alias = element.getAttribute('data-alias');
            if (alias) {
                element.setAttribute('data-alias', `${prefix}${alias}`);
            }
        });

        return doc.body.innerHTML;
    }

    private createLinkedFieldElementModel(writer: Writer, typeInput: string, linkedFieldModel: BaseInputModel) {
        const name = linkedFieldModel.id;

        const attributes: Record<string, any> = {
            'alias': linkedFieldModel.alias,
            'input-type': typeInput,
            'is-valid': linkedFieldModel.isValid === undefined ? linkedFieldModel.isValid : true,
            'id': !!linkedFieldModel.id ? linkedFieldModel.id : this.generateId(),
            'input-help': linkedFieldModel.help,
            'value': linkedFieldModel.value,
            'name': name,  // TODO  Nop. No se puede usar name en model, esta reservado para el nombre de la etiqueta. Usar data-name si hace falta, pero esto es un id...
            'is-linked': true
        };

        if (linkedFieldModel instanceof ShortTextInput) {
            attributes['pattern'] = !!linkedFieldModel.pattern ? linkedFieldModel.pattern : "undefined";
            attributes['transform'] = !!linkedFieldModel.transform ? linkedFieldModel.transform : "undefined";
        }

        return writer.createElement(InputPlugin.MODEL_ENTITIES.container.model, attributes);
    }

    private generateId(): string {
        return this.pluginUtils.generateId(this.ID_LINKED_FIELD_PREFFIX);
    }

    private createLinkedFieldContentElementModel(writer: Writer, inputModel: BaseInputModel) {
        const nameModelElement = writer.createElement(InputPlugin.MODEL_ENTITIES.content.model);
        writer.insertText(this.getValue(inputModel.value),nameModelElement, 0);

        return nameModelElement;
    }

    private getValue(value: string | Date | number): string {
        return value?.toLocaleString();
    }
}
