import {
    Component, EventEmitter, Input, Output, AfterViewInit, ChangeDetectionStrategy,
    ChangeDetectorRef, OnInit, OnChanges, SimpleChanges
} from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { SelectionModel } from '@angular/cdk/collections';
import { CtboxGenericListComponentDirective } from '../ctbox-generic-list/ctbox-generic-list.component';

@Component({
    selector: 'ctbox-selection-list',
    templateUrl: './ctbox-selection-list.component.html',
    styleUrls: ['./ctbox-selection-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CtboxSelectionListComponent<T, K> extends CtboxGenericListComponentDirective<T> implements OnInit, AfterViewInit, OnChanges {
    public anySelectedElement = false;
    public selection: SelectionModel<T>;
    public displayedColumns: Array<string>;
    public isTableEmpty = false;
    public currentRequest: T;

    public visibleColumns: { [key: string]: { isVisible: boolean }; } = {};
    public externalCustomFilterMatcheckboxOptions: ICustomFilterMatcheckboxOptions<T>;
    public columnsDefinitionValues: Array<IColumnDefinition>;

    public isOnlySelectedCheckboxDisabled = true;
    public isFilterByOnlySelectedCheckboxChecked = false;

    private lastTerm = '';
    private selectedIds: Set<T>;

    @Input() public set columnsDefinition(values: Array<IColumnDefinition>) {
        if (values) {
            this.columnsDefinitionValues = values;
            values.forEach((columnDefinition: IColumnDefinition) => {
                this.visibleColumns[columnDefinition.name] = { isVisible: true };
            });
        }
    }

    @Input() public set customFilterMatcheckboxOptions(values: ICustomFilterMatcheckboxOptions<T>) {
        if (values) {
            this.externalCustomFilterMatcheckboxOptions = values;
        }
    }

    @Input() public data: Array<T>;
    @Input() public listLabel: string;
    @Input() public required = false;
    @Input() public footerDefinition: IFooterDefinition<K>;
    @Input() public emptyTableMessage = '';
    @Input() public displaySearchbox = false;
    @Input() public searchboxLabel: string;
    @Input() public displayCheckAll = false;
    @Input() public displayCheckAllLabel: string;
    @Input() public debounceTime: number;
    @Input() public listName: string;
    @Input() public displayAllSelected = true;
    @Input() public defaultSelectedElementsKeys = new Array<T>();
    @Input() public idProperty = 'id';
    @Input() public additionalClass: string;
    @Input() public isDisabled: boolean;
    @Input() public isRowDisabled: (element: T) => boolean;
    @Input() public toolTipCheckCircle = 'Seleccionar una opción';
    @Input() public showOnlySelectedCheckbox: boolean;

    @Output() public selectedElements: EventEmitter<Array<T>>;
    @Output() public inputNavigationEnd: EventEmitter<void>;
    @Output() public elementToggled: EventEmitter<Array<{element: T, newState: boolean}>>;

    public constructor(public dialog: MatDialog, private changeDetectorRef: ChangeDetectorRef) {
        super();
        this.PAGE_SIZE_DEFAULT = 10;
        this.pageSize = this.PAGE_SIZE_DEFAULT;
        this.selectedElements = new EventEmitter();
        this.inputNavigationEnd = new EventEmitter();
        this.elementToggled = new EventEmitter();

        this.debounceTime = 500;
    }

    public ngOnInit(): void {
        this.filterDataByCustomFilters = this.customTableSearchFilterPredicate;
    }

    public ngAfterViewInit(): void {
        this.initializeTable();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes['defaultSelectedElementsKeys'] &&
            changes['defaultSelectedElementsKeys'].currentValue) {
            this.setSelectionDataTable();
        }
    }

    public updateDataInmediately(updatedData: T[]): void {
        this.data = updatedData;
        this.initializeTable();
    }

    public deleteSelection(): void {
        this.selection.clear();
        this.isOnlySelectedCheckboxDisabled = true;
        this.isFilterByOnlySelectedCheckboxChecked = false;
        this.refreshDataTableFilter();
        this.changeDetectorRef.detectChanges();
    }

    protected parseValueToExportExcel(column: string, data: T): number | string {
        return '';
    }

    protected reloadTableContent(): void {
        //this is intencional
    }

    public isAllSelected(): boolean {
        let isAllSelected = true;
        this.dataTable.filteredData
                .forEach((element: T) => {
                    isAllSelected = isAllSelected && this.selection.isSelected(element);
                });

        return isAllSelected;
    }

    public listReachedEnd(): void {
        this.inputNavigationEnd.emit();
    }

    public masterToggle(): void {
        let toggledElements = new Array<{element: T, newState: boolean}>();

        if (this.isAllSelected()) {
            this.selection.clear();
            toggledElements = this.dataTable.filteredData.map((x: T) => ({element: x, newState: false}));
            this.isFilterByOnlySelectedCheckboxChecked = false;
            this.refreshDataTableFilter();
        } else {
            this.dataTable.filteredData
                .filter((x: T) => !this.isRowDisabled || (this.isRowDisabled && !this.isRowDisabled(x)))
                .forEach((element: T) => {
                    this.selection.select(element);
                    toggledElements.push({element, newState: true});
                }
            );
        }

        this.isOnlySelectedCheckboxDisabled = !this.selection.hasValue();

        this.elementToggled.emit(toggledElements);
        this.sendEventWithSelectedElements();
    }

    public checkboxLabel(row?: any): string {
        if (!row) {
            return `${this.isAllSelected() ? 'select' : 'deselect'} all`;
        }
        return `${this.selection.isSelected(row) ? 'deselect' : 'select'} row ${row.position + 1}`;
    }

    public toggleElement(row: any): void {
        this.selection.toggle(row);
        this.refreshDataTableFilter();
        const newState = this.selection.isSelected(row);
        this.isOnlySelectedCheckboxDisabled = !this.selection.hasValue();
        this.elementToggled.emit([{element: row, newState}]);
        this.sendEventWithSelectedElements();
    }

    public onOnlySelectedCheckboxChanged(isChecked: boolean): void {
        this.isFilterByOnlySelectedCheckboxChecked = isChecked;
        this.refreshDataTableFilter();
    }

    public onNotifyCustomFilterCheckBoxChange(): void {
        this.refreshDataTableFilter();
    }

    protected customTableSearchFilterPredicate(data: T): boolean {
        let isFilterByOnlySelectedCheckBox = true;

        if (this.isFilterByOnlySelectedCheckboxChecked) {
            isFilterByOnlySelectedCheckBox = this.selectedIds && this.selectedIds.has(data[this.idProperty]);
        }

        let externalFilterDataSourceByCustomFilters = true;

        return isFilterByOnlySelectedCheckBox && externalFilterDataSourceByCustomFilters;
    }

    private setSelectionDataTable(): void {
        if (!!!this.dataTable)
        {
            return;
        }

        const filteredIds = new Set(this.defaultSelectedElementsKeys);

        if (this.defaultSelectedElementsKeys) {
            this.isOnlySelectedCheckboxDisabled = this.defaultSelectedElementsKeys.length <= 0;
        }

        this.selection = new SelectionModel<any>(
            true,
            this.dataTable.data.filter(
                (element: T) => filteredIds.has(element[this.idProperty])
            )
        );
    }

    private sendEventWithSelectedElements(): void {
        this.selectedElements.emit(this.selection.selected);
    }

    private initializeTable(): void {
        this.dataTable = new MatTableDataSource<T>(this.data);
        this.setSelectionDataTable();
        this.dataTable.paginator = this.paginator;
        this.dataTable.sort = this.sort;
        const remainingColumns = Object.keys(this.visibleColumns);
        this.displayedColumns = ['select', ...remainingColumns];
        this.dataTable.filterPredicate = this.tableSearchFilterPredicate();
        this.dataTable.filter = this.lastTerm;
        this.setSelectionDataTable();

        if (this.isDisabled) {
            this.deleteSelection();
        }

        this.isTableEmpty = this.dataTable.data.length === 0;
        this.changeDetectorRef.markForCheck();
    }

    private refreshDataTableFilter(): void {
        let filter = {};

        if (this.dataTable.filter) {
            filter = JSON.parse(this.dataTable.filter);
        }

        this.selectedIds = new Set(this.selection.selected.map(x => x[this.idProperty]));

        this.dataTable.filter = JSON.stringify(filter);
    }
}

interface ITableBaseStructure {
    title: string;
    name: string;
}

export interface IColumnDefinition extends ITableBaseStructure {
    toolTip?: string;
    type: string;
    propertyName?: string;
    hasFilters?: boolean;
    filterOptions?: IFilterOptions;
}

export interface IFooterDefinition<T> extends ITableBaseStructure {
    type: string;
    value: T;
}

export interface IFilterOptions {
    options: Array<{viewValue: string, value: any, checked: boolean}>;
    optionsByInput: boolean;
    customSearch: (searchTerm: Array<string>, dataObj: any, key?: string) => boolean;
    customGetValue: (obj: any, key: string) => boolean;
}

export interface ICustomFilterMatcheckboxOptions<T> {
    customTableSearchFilterPredicate?: (data: T) => boolean;
    customFilterMatcheckboxs: ICustomFilterMatcheckbox[];
}

export interface ICustomFilterMatcheckbox {
    matlabel: string;
    checked: boolean;
    onChangeFunction: (isChecked: boolean) => void;
}
