import { Writer } from "ckeditor5";
import { BaseSelectCommand } from "./base-select-command";

export default class DeleteSelectCommand extends BaseSelectCommand {

    public override execute(id: string): void {
        const editor = this.editor;

        editor.model.change( (writer:Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);
            const selectModel = this.getElementWithId(range, id);
            if (!!selectModel) {
                writer.remove(selectModel.item);
            }
		} );
    }

    public override refresh(): void {

        super.refresh();
        if (this.isEnabled) {
            return;
        }

        this.enableCommandIfCursorInsideInputContent();
    }
}
