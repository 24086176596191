/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
export interface UserFolderPermissionsDto {
    canEditName?: boolean;
    canCreateChildren?: boolean;
    canDelete?: boolean;
    canSee?: boolean;
    canSeeContent?: boolean;
    canCreateContent?: boolean;
    canMoveFrom?: boolean;
    canMoveTo?: boolean;
    canMoveToRoot?: boolean;
}
