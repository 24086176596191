import { BaseEntityDto } from '../baseEntityDto';
import { FolderItemInformationDto } from '../folderItemInformationDto';
import { UserFolderPermissionsDto } from '../userFolderPermissionsDto';

export interface TemplateFolderDto extends BaseEntityDto {
    name?: string;
    content?: FolderItemInformationDto[];
    templateFolderParentId?: string;
    userFolderPermission?: UserFolderPermissionsDto;
    nameHighlighted?: string;
}
