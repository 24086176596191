import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';

@Component({
    selector: 'three-states-toggle',
    templateUrl: './three-states-toggle.component.html'
})
export class ThreeStatesToggleComponent implements OnInit, OnChanges {
    @Input() value: boolean;
    @Input() disabled: boolean;
    @Input() toggleClass: string;
    @Output() valueChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    public intValue: number;

    public ngOnInit(): void {
        this.setIntValues();
        this.setToggleClass();
    }

    public ngOnChanges(_changes: SimpleChanges): void {
        this.setIntValues();
        this.setToggleClass();
    }

    public toggleValue(intValue: number | null): void {

        if (intValue === null || intValue === undefined) {
            return;
        }

        this.intValue = intValue;

        if (this.intValue === 0) {
            this.value = false;
        } else {
            this.value = true;
        }

        this.setToggleClass();
        this.valueChange.emit(this.value);
    }

    private setIntValues(): void {
        if (this.value === true) {
            this.intValue = 100;
        } else if (this.value === false) {
            this.intValue = 0;
        } else {
            this.intValue = 50;
        }
    }

    private setToggleClass(): void {
        if (this.disabled) {
            this.toggleClass = 'state-none';
            return;
        }
        if (this.value === true) {
            this.toggleClass = 'state-true';
        } else if (this.value === false) {
            this.toggleClass = 'state-false';
        } else {
            this.toggleClass = 'state-none';
        }
    }
}
