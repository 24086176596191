import { Observable } from 'rxjs';
import { ClauseFolderDto, FolderItemInformationDto, OperationClauseFolderDto } from 'src/app/api';

export abstract class IClausesFolderService {

    abstract setCurrentFolderName(folderName: string): void;
    abstract getCurrentFolderName(): string;
    abstract getCorrectedFolderName(originalFolderName: string): string;

    abstract setCurrentFolderId(folderId: string): void;
    abstract getCurrentFolderId(): Observable<string>;

    abstract getOperationReplaceClauseFolder(valueToChange: string, newValue: any ): OperationClauseFolderDto[];

    abstract isCurrentFolderValid(nodeId: string): boolean;
    abstract getFileIdsInFolderRecusively(folderId: string, folders: ClauseFolderDto[]): FolderItemInformationDto[];

}
