
export enum Op {
    REMOVE = 'remove',
    ADD = 'add',
    REPLACE = 'replace',
    MOVE = 'move',
    COPY = 'copy',
    TEST = 'test'
}

export interface IJsonPatchDocument {
    op: Op;
    path: string;
    value?: any;
}
