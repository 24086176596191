export enum NotificationState {
    Info = 'info',
    Success = 'success',
    Warning = 'warning',
    Error = 'error',
    Alert = 'alerts',
    Forbid = 'forbid',
    Lock = 'lock',
    Update = 'update',
    Visibility = 'visibility',
    Default = '',
    VisibilityOff = 'visibility_off'
}
