/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export interface ErrorDocumentOperationDTO {
    readonly nameUsedAndCanNotBeOverwrittenMessage?: string;
    readonly nameUsedAndCanBeOverwrittenMessage?: string;
    readonly userHasNoPermissionsForOperationMessage?: string;
    readonly isNotFoundMessage?: string;
    readonly isMovementForbiddenMessage?: string;
    readonly isMovementInAnnexedForbidden?: string;
    readonly signatureUserAreWrong?: string;
    readonly isDeletedForbiddenMessage?: string;
    readonly isNameNotValidMessage?: string;
    readonly isInCommonRootModifyingNotAllowedMessage?: string;
    readonly isInMyDocumentRootModifyingNotAllowedMessage?: string;
    readonly userHasNoPermissionInFolderMessage?: string;
    readonly userInOriginFolderHasNoPermissionInDestinyFolder?: string;
    readonly movementNotAllowedFromOurDocumentsToMyDocumentsMessage?: string;
    readonly signatureIsRepitedMessage?: string;
    readonly genericErrorMessage?: string;
}
