import { Item, Writer } from "ckeditor5";
import { InputBaseCommand } from "./input-base-command";

export default class DeleteInputCommand extends InputBaseCommand {

    public override execute(id: string): void {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);

            const inputModel: Item = this.getElementWithId(range, id)?.item;

            writer.remove(inputModel);
		});
    }

    public override refresh(): void {
        super.refresh();

        if (!this.isEnabled) {
            this.enableCommandIfCursorInsideInputContent();
        }
    }
}
