import { Injectable } from "@angular/core";
import { IHtmlEventsService } from "./html-events.service.interface";

@Injectable({
    providedIn: 'root'
})
export class HtmlEventsService extends IHtmlEventsService {

    public eventComment(documentInstance: Document, event: string) {
        const elementComment: Element = documentInstance.getElementById('dCm');
        if (elementComment) {
            elementComment.removeAttribute('href');
            elementComment.addEventListener(event, () => {
                this.commentHideShow(documentInstance);
            });
        }
    }

    private commentHideShow(documentInstance: Document) {
        let elementComment = documentInstance.getElementById('dCm');
        const refCnnClass  = documentInstance.getElementsByClassName('ccn');
        const refCnnOffClass  = documentInstance.getElementsByClassName('ccnOff');
        const currentStyle = elementComment.className;
        if (!!refCnnClass && refCnnClass.length > 0) {
            this.changeClassComment(refCnnClass, currentStyle);
        }
        if (!!refCnnOffClass && refCnnOffClass.length > 0) {
            this.changeClassComment(refCnnOffClass, currentStyle);
        }
        elementComment.className = (currentStyle=='dCmO') ? 'dCmC' : 'dCmO';
    }

    private changeClassComment(refTags: HTMLCollectionOf<Element>, currentStyle: string) {
        if (currentStyle=="dCmO") {
            for (let i = 0; i < refTags.length; ++i) {
                if (refTags[i].className=='ccn') {
                    refTags[i].classList.replace('ccn', 'ccnOff');
                }
            }
        }
        if (currentStyle=="dCmC") {
            for (let i = 0; i < refTags.length; ++i) {
                if (refTags[i].className=='ccnOff') {
                    refTags[i].classList.replace('ccnOff', 'ccn')
                }
            }
        }
    }
}
