import { Editor, Writer } from "ckeditor5";
import { InputUtilsService } from "../../utils/input/input-utils.service";
import { LinkedFieldUtilsService } from "../../utils/linked-field/linked-field-utils.service";
import { GlobalConstant } from "../../models/base/global-constant";
import { BaseAddEditLinkedFieldCommand } from "./base-add-edit-linked-field-command";

export default class AddLinkedFieldCommand extends BaseAddEditLinkedFieldCommand {

    private inputUtilsService: InputUtilsService;
    private linkedFieldUtilsService: LinkedFieldUtilsService;

    constructor(editor: Editor) {
        super(editor);
        this.inputUtilsService = new InputUtilsService();

        this.linkedFieldUtilsService = new LinkedFieldUtilsService();
    }

    public override execute(alias: string): void {
        const editor = this.editor;
        let id;

        editor.model.change((writer: Writer) => {
            const inputParent = this.inputUtilsService.getInputParentElementModelWithAlias(editor, alias);
            const inputParentType = inputParent.item.getAttribute('input-type').toString();

            if (!this.inputUtilsService.isTypeValid(inputParentType)) {
                return null;
            }

            const inputParentModel = this.linkedFieldUtilsService.getInputModelWithElement(inputParent.item);
            inputParentModel.id = '';
            const inputLinkedFieldModel = this.linkedFieldUtilsService.createLinkedFieldStructureModel(writer, inputParentType, inputParentModel);
            id = inputLinkedFieldModel.getAttribute(GlobalConstant.ATTRIBUTE_ID);
            editor.model.insertObject(inputLinkedFieldModel, null, null, {setSelection: 'after'});
        } );

        if (id) {
            this.validateLinkedField(id);
        }
    }
}
