import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable }  from 'rxjs';
import { Configuration } from '../configuration';
import { ApiOperation, ApiProcessorService, ApiRequest } from './api-processor.service';
import { TemplateVersionDTO } from '../model/models';
import { DatePipe } from '@angular/common';
import { IJsonPatchDocument } from '../model/json-patch.model';

@Injectable()
export class ManagerTemplateVersionService {

    protected basePath = '/api/manager-management/template-versions';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(private readonly apiProcessorService: ApiProcessorService) {
    }

    public createTemplateVersion(templateVersion: TemplateVersionDTO): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.body = templateVersion;
        apiRequest.endpointUrl = this.basePath;

        return this.apiProcessorService.proccessRequest<TemplateVersionDTO[]>(apiRequest);
    }

    public getTemplateVersion(templateVersionId: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = `${this.basePath}/${templateVersionId}`;

        return this.apiProcessorService.proccessRequest<TemplateVersionDTO[]>(apiRequest);
    }

    public patchTemplateVersion(templateVersionId: string, jsonPatchDocument: Array<IJsonPatchDocument>): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.PATCH;
        apiRequest.body = jsonPatchDocument;
        apiRequest.endpointUrl = `${this.basePath}/${templateVersionId}`;

        return this.apiProcessorService.proccessRequest<TemplateVersionDTO[]>(apiRequest);
    }

    public generateNewTemplateVersion(): string {
        const datePipe = new DatePipe("en-US");
        return datePipe.transform(new Date(), 'yyyyMMdd').toString();
    }
}
