import { InputDataViewUtilsService } from '../../utils/input/input-data-view-utils.service';
import { Directive, Inject } from "@angular/core";
import { ButtonView, createLabeledInputText, InputTextView, LabeledFieldView, Locale, createDropdown, Collection, DropdownView, ViewModel, addListToDropdown, LabelView, ListDropdownButtonDefinition, focusChildOnDropdownOpen } from "ckeditor5";
import FormRowView from "../../../utils/form-row-view.directive";
import { UI_CLASSES } from '../styles/styles-constants';
import InfoView from '../view/info-view.directive';
import { InputPlugin } from '../../plugins/input/input-plugin';
import BaseView from '../base-view.directive';
import { GlobalConstant } from '../../models/base/global-constant';
import { ShortTextInput } from '../../models/input/short-text-input-model';

@Directive({
    selector: "app-input-dialog-form-view",
})
export default class InputDialogView extends BaseView {

    public aliasInputView: LabeledFieldView<InputTextView>;
    public errorTextDeleteAlias: LabelView;
    public helpInputView: LabeledFieldView<InputTextView>;
    public infoTextView: InfoView;
    public textErrorRemoveAlias: InfoView;
    public patternInputView: DropdownView;
    public transformInputView: DropdownView;

    public static readonly PATTERNS: { [key: string]: string } = {
        [GlobalConstant.UNDEFINED]: $localize`:@@EtiquetaNoDefinido:<No definido>`,
        [GlobalConstant.PHONE]: $localize`:@@EtiquetaTelefono:Teléfono (+34 916020000)`,
        [GlobalConstant.EMAIL]: $localize`:@@EtiquetaEmail:Email`,
        [GlobalConstant.DNI]: $localize`:@@EtiquetaDNI:DNI`,
        [GlobalConstant.NIE]: $localize`:@@EtiquetaNIE:NIE`,
        [GlobalConstant.NIF]: $localize`:@@EtiquetaNIF:NIF`,
        [GlobalConstant.IBAN]: $localize`:@@EtiquetaIBAN:IBAN`
    };

    public static readonly TRANSFORMS: { [key: string]: string } = {
        [GlobalConstant.UNDEFINED]: $localize`:@@EtiquetaNoDefinido:<No definido>`,
        [GlobalConstant.UPPERCASE]: $localize`:@@EtiquetaMayusculas:Mayúsculas`,
        [GlobalConstant.LOWERCASE]: $localize`:@@EtiquetaMinusculas:Minúsculas`,
        [GlobalConstant.CAMEL]: $localize`:@@EtiquetaCapitales:Capitales`,
    };

    public aliasButtonView: ButtonView;
    private saveButtonView: ButtonView;
    private cancelButtonView: ButtonView;

    private ID_INPUT_PREFFIX = 'ck-labeled-field-view-';

    private labelAlias = $localize`:@@PluginInputEdicionEtiquetaAlias:Crear Alias `;
    private labelHelpNote = $localize`:@@PluginInputEdicionEtiquetaTextAyuda:Texto de Ayuda `;
    private labelPattern = $localize`:@@PluginInputEdicionEtiquetaPatron:Patrón `;
    private labelTransformacion = $localize`:@@PluginInputEdicionEtiquetaTransformacion:Transformación `;
    private labelDeleteAlias = $localize`:@@PluginDeleteAliasEtiqueta:Eliminar alias actual`;
    private labelDeleteAliasErrorText = $localize`:@@PluginDeleteAliasErrorEtiqueta:Para eliminar debes primero desvincular o borrar los campos vinculados.`;

    private tooltipText = $localize`:@@PluginInputFormularioBotonInformacionCampoAlias:Define un alias para crear campos vinculados a este.`;

    private validatorsAliasField: Array<InputDialogFormViewCallback>;
    private validatorsHelpField: Array<InputDialogFormViewCallback>;
    private validatorDeleteAlias: Array<InputDialogFormViewDeleteAliasCallback>;

    private defaultValue = $localize`:@@EtiquetaNoDefinido:<No definido>`;

    private dataUtils: InputDataViewUtilsService;

    private inputModelToEdit: ShortTextInput = {
        id: ''
    };
    private isEditing = false;

    constructor(
        @Inject(Array<InputDialogFormViewCallback>) validatorsAliasField: Array<InputDialogFormViewCallback>,
        @Inject(Array<InputDialogFormViewCallback>) validatorsHelpField: Array<InputDialogFormViewCallback>,
        @Inject(Array<InputDialogFormViewDeleteAliasCallback>) validatorDeleteAlias: Array<InputDialogFormViewDeleteAliasCallback>,
        @Inject(Locale) locale: Locale,
        @Inject(ShortTextInput) defaultInputModel?: ShortTextInput,
    ) {
        super(locale);
        this.dataUtils = new InputDataViewUtilsService();

        this.initializeInputModel(defaultInputModel);
        this.initializeValidators(validatorsAliasField, validatorsHelpField, validatorDeleteAlias);
        this.initializeViews(locale);
        this.initializeButtons();
        this.initializeRows(locale);

        this.setTemplateForm("ck-input-form");
        this.setEvents();
    }

    private initializeInputModel(defaultInputModel?: ShortTextInput): void {
        if (!!defaultInputModel) {
            this.inputModelToEdit = JSON.parse(JSON.stringify(defaultInputModel));
        }
        this.isEditing = !!this.inputModelToEdit.id;
    }

    private initializeValidators(validatorsAliasField: Array<InputDialogFormViewCallback>, validatorsHelpField: Array<InputDialogFormViewCallback>, validatorDeleteAlias: Array<InputDialogFormViewDeleteAliasCallback>,): void {
        this.validatorsAliasField = validatorsAliasField;
        this.validatorsHelpField = validatorsHelpField;
        this.validatorDeleteAlias = validatorDeleteAlias;
    }

    private initializeViews(locale: Locale): void {
        this.aliasInputView = this.createValueInput(this.labelAlias, this.inputModelToEdit?.alias);
        this.infoTextView = new InfoView(locale, this.tooltipText, UI_CLASSES.SVG_ICONS.ICON_INFO);
        this.helpInputView = this.createValueInput(this.labelHelpNote, this.inputModelToEdit?.help);
        this.patternInputView = this.createSelector(InputDialogView.PATTERNS, this.defaultValue);
        this.transformInputView = this.createSelector(InputDialogView.TRANSFORMS, this.defaultValue);
    }

    private initializeButtons(): void {
        this.aliasButtonView = this.createButton(this.labelAlias, 'ck-rounded-button');
        this.aliasButtonView.delegate("execute").to(this, "create-alias");

        this.deleteButtonView = this.createButton(this.labelDeleteAlias, "ck-button-delete", UI_CLASSES.SVG_ICONS.DELETE);
        this.deleteButtonView.delegate("execute").to(this, "delete-alias");

        this.patternInputViewHandler();

        const showTextInButton = true;
        this.saveButtonView = this.createButton(this.submitButtonMessage, "ck-button-save input-refillable", null, showTextInButton);
        this.saveButtonView.type = "submit";

        this.cancelButtonView = this.createButton(this.cancelButtonMessage, "ck-button-cancel input-refillable", null, showTextInButton);
        this.cancelButtonView.delegate("execute").to(this, "cancel");
    }

    private initializeRows(locale: Locale): void {
        const rowAlias = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.aliasButtonView,
                this.aliasInputView,
                this.infoTextView,
                this.deleteButtonView
            ],
            class: 'create-alias'
        });

        this.errorTextDeleteAlias = new LabelView();
        this.errorTextDeleteAlias.text = '';
        this.errorTextDeleteAlias.extendTemplate({
            attributes: {
                class: 'text-error-delete-alias'
            }
        });

        const rowAliasErrorText = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.errorTextDeleteAlias
            ],
            class : 'text-error-delete-alias'
        });

        const rowHelp = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.helpInputView,
            ],
            class : 'label-help'
        });

        const patternView = new LabelView();
        patternView.text = this.labelPattern;
        patternView.extendTemplate({
            attributes: {
                class: 'label-input'
            }
        });

        const rowPattern = new FormRowView(locale, {
            labelView: undefined,
            children: [
                patternView,
                this.patternInputView,
            ],
            class: 'pattern-dropdown'
        });

        const transformView = new LabelView();
        transformView.text = this.labelTransformacion;
        transformView.extendTemplate({
            attributes: {
                class: 'label-input'
            }
        });

        const rowTransform = new FormRowView(locale, {
            labelView: undefined,
            children: [
                transformView,
                this.transformInputView,
            ],
            class: 'transform-dropdown'
        });

        const rowButtons = new FormRowView(locale, {
            labelView: undefined,
            children: [
                this.saveButtonView,
                this.cancelButtonView
            ],
            class: 'footer-buttons'
        });

        this.items = this.createCollection([
            rowAlias,
            rowAliasErrorText,
            rowHelp,
            rowPattern,
            rowTransform,
            rowButtons
        ]);
    }

    public override focus(): void {
        if (this.inputModelToEdit.help !== "") {
            this.helpInputView.focus();
        }
        const inputElement = this.helpInputView.fieldView.element as HTMLInputElement;
        this.moveToFirstPositionElement(inputElement);
    }

    public get id(): string {
        return this.inputModelToEdit.id!;
    }

    public get inputName(): string {
        return this.inputModelToEdit.name!;
    }

    public set inputModel(value: ShortTextInput) {
        if (!value) {
            return;
        }

        this.inputModelToEdit =  JSON.parse(JSON.stringify(value));
        this.isEditing = !!this.inputModelToEdit.id;
        this.updateFields();
    }

    public getInputModel(): ShortTextInput {
        this.inputModelToEdit.alias = this.dataUtils.getValue(this.aliasInputView.fieldView.element.value);
        this.inputModelToEdit.help = this.dataUtils.getValue(this.helpInputView.fieldView.element.value);
        this.inputModelToEdit.pattern = this.getKeyByValue(InputDialogView.PATTERNS, this.patternInputView.buttonView.label)
        this.inputModelToEdit.transform = this.getKeyByValue(InputDialogView.TRANSFORMS, this.transformInputView.buttonView.label)

        this.inputModelToEdit.type = InputPlugin.TEXT_TYPE;
        if (this.isInCreation()) {
            this.inputModelToEdit.id = this.generateId(this.inputModelToEdit.id);
        }
        this.inputModelToEdit.isValid = true;
        this.inputModelToEdit.isLinked = false;

        return this.inputModelToEdit;
    }

    public isValidField(fieldInputView: LabeledFieldView<InputTextView>, validatorField: Array<InputDialogFormViewCallback>): boolean {
        for (const validator of validatorField) {
            const errorText = validator(this);

            if (errorText) {
                fieldInputView.errorText = errorText;
                return false;
            }
        }

        fieldInputView.errorText = '';
        return true;
    }

    public isValidAllValidatorsField(): boolean {
        const aliasValid = this.isValidField(this.aliasInputView, this.validatorsAliasField);
        const helpValid = this.isValidField(this.helpInputView, this.validatorsHelpField);
        return aliasValid && helpValid;
    }

    public isInCreation(): boolean {
        return !this.isEditing;
    }

    public selectDefaultField(): void {
        if (!this.helpInputView?.fieldView) {
            return;
        }

        this.helpInputView.fieldView.select();
    }

    public resetValidation(): void {
        this.helpInputView.errorText = null;
        this.aliasInputView.errorText = null;
        this.errorTextDeleteAlias.text = '';
    }

    public resetFormStatus(): void {
        this.resetValidation();

        this.aliasButtonView.label = this.labelAlias;

        this.inputModelToEdit.alias = '';
        this.inputModelToEdit.help = '';
        this.inputModelToEdit.id = '';
        this.inputModelToEdit.isValid = false;
        this.inputModelToEdit.name = '';
        this.inputModelToEdit.transform = GlobalConstant.UNDEFINED;
        this.inputModelToEdit.type = InputPlugin.TEXT_TYPE;
        this.inputModelToEdit.value = '';
        this.inputModelToEdit.pattern =  GlobalConstant.UNDEFINED;

        this.isEditing = false;

        this.updateFields();
    }

    private moveToFirstPositionElement(element: HTMLInputElement): void {
        if (element) {
            element.setSelectionRange(0, 0);
        }
    }

    private setEvents(): void {
        this.aliasInputView.fieldView.element.addEventListener('blur', this.handleAliasInputBlur.bind(this));
        this.helpInputView.fieldView.element.addEventListener('blur', this.handleHelpInputBlur.bind(this));

        this.on("create-alias", this.handleCreateAlias.bind(this));
        this.on("delete-alias", this.handleDeleteAlias.bind(this));
    }

    private handleAliasInputBlur(event: FocusEvent): void {
        const relatedElement = event.relatedTarget;

        if (relatedElement && relatedElement === this.saveButtonView.element) {
            return;
        }

        const aliasIsValid = this.isValidField(this.aliasInputView, this.validatorsAliasField);
        if (aliasIsValid) {
            this.updateAliasView();
            this.toggleInfoText();
        }

        this.checkPatternDropdown(relatedElement);
        this.checkTransformDropdown(relatedElement);
    }

    private handleHelpInputBlur(event: FocusEvent): void {
        this.isValidField(this.helpInputView, this.validatorsHelpField);
    }

    private updateAliasView(): void {
        this.aliasButtonView.isVisible = true;
        this.aliasInputView.element.hidden = true;
        this.aliasInputView.fieldView.element.hidden = true;

        const hasAlias = this.aliasInputView.fieldView.element.value !== '';
        this.deleteButtonView.isVisible = hasAlias;
        this.aliasButtonView.label = hasAlias ? this.aliasInputView.fieldView.element.value : this.labelAlias;
    }

    private toggleInfoText(): void {
        const hasAlias = this.aliasInputView.fieldView.element.value !== '';
        if (hasAlias) {
            this.infoTextView.element.classList.add('ck-icon-info-hidden');
        } else {
            this.infoTextView.element.classList.remove('ck-icon-info-hidden');
        }
    }

    private checkPatternDropdown(relatedElement: EventTarget | null): void {
        if (relatedElement && (relatedElement as HTMLElement).parentElement?.parentElement.classList.contains('pattern-dropdown')) {
            this.patternInputView.isOpen = true;
        }
    }

    private checkTransformDropdown(relatedElement: EventTarget | null): void {
        if (relatedElement && (relatedElement as HTMLElement).parentElement?.parentElement.classList.contains('transform-dropdown')) {
            this.transformInputView.isOpen = true;
        }
    }

    private handleCreateAlias(): void {
        this.aliasButtonView.isVisible = false;
        this.deleteButtonView.isVisible = false;
        this.aliasInputView.element.hidden = false;
        this.aliasInputView.fieldView.element.hidden = false;
        this.infoTextView.element.classList.add('ck-icon-info-hidden');
    }

    private handleDeleteAlias(): void {
        const aliasIsNotValid = this.deleteAliasValidator();
        if (!!aliasIsNotValid) {
            this.errorTextDeleteAlias.text = this.labelDeleteAliasErrorText;
            return;
        }
        this.errorTextDeleteAlias.text = '';
        this.aliasButtonView.label = this.labelAlias;
        this.aliasInputView.fieldView.element.value = '';
        this.aliasButtonView.isVisible = true;
        this.deleteButtonView.isVisible = false;
        this.aliasInputView.element.hidden = true;
        this.aliasInputView.fieldView.element.hidden = true;
        this.infoTextView.element.classList.remove('ck-icon-info-hidden');
    }

    private deleteAliasValidator(): boolean {
        for (const validator of this.validatorDeleteAlias) {
            if (validator(this)) {
                return true;
            }
        }
        return false;
    }

    private updateFields() {
        this.infoTextView.element.classList.remove('ck-icon-info-hidden');
        this.aliasButtonView.isVisible = true;
        this.deleteButtonView.isVisible = false;
        this.aliasInputView.element.hidden = true;
        this.aliasInputView.fieldView.element.hidden = true;
        this.aliasInputView.fieldView.element.value = this.inputModelToEdit.alias;
        this.helpInputView.fieldView.element.value = this.inputModelToEdit.help;
        this.patternInputView.buttonView.label = InputDialogView.PATTERNS[this.inputModelToEdit.pattern];
        this.transformInputView.buttonView.label = InputDialogView.TRANSFORMS[this.inputModelToEdit.transform];
        this.transformInputView.buttonView.isEnabled = this.patternInputView.buttonView.label === this.defaultValue;
    }

    private createValueInput(label: string, defaultValue?: string): LabeledFieldView<InputTextView> {
        const labeledInput = new LabeledFieldView(
            this.locale,
            createLabeledInputText
        );

        labeledInput.label = label;
        if (defaultValue) {
            labeledInput.fieldView.value = defaultValue;
        }

        labeledInput.fieldView.on("input", (value: any) => {
            this.resetValidation();
        });

        return labeledInput;
    }

    private createSelector(listItemSelector: { [key: string]: string }, defaultValue?: string): any {
        const dropdown = createDropdown(this.locale);
        const items = new Collection<ListDropdownButtonDefinition>();
        dropdown.buttonView.set({
            withText: true
        });

        if (defaultValue) {
            dropdown.buttonView.label = listItemSelector['undefined'];
        }

        for (const key in listItemSelector) {
            if (listItemSelector.hasOwnProperty(key)) {
                const value = listItemSelector[key];

                items.add({
                    type: 'button',
                    model: new ViewModel({
                        withText: true,
                        label: value,
                    })
                });
            }
        }

        addListToDropdown(dropdown, items);

        this.listenTo(dropdown, 'execute', evt => {
            const choice: any = evt.source;
            const text = choice.element.innerText;
            if (!!text) {
                dropdown.buttonView.label = text;
            } else {
                dropdown.buttonView.label = "";
            }
        });

        focusChildOnDropdownOpen(dropdown, () => {
            const selectedItem = dropdown.listView.items.find(item =>
                Array.from((item as any).children).some(child =>
                    child instanceof ButtonView && child.label === dropdown.buttonView.label
                )
            ) || dropdown.listView.items[0];

            return selectedItem;
        });

        return dropdown;
    }

    private generateId(currentId: string | null | undefined): string {
        return !!currentId ? currentId : `${this.ID_INPUT_PREFFIX}${crypto.randomUUID()}`;
    }

    private patternInputViewHandler(): void {
        this.patternInputView.on('execute', (event: any) => {
            const value = event.source.label;
            if (value === this.defaultValue) {
                this.transformInputView.buttonView.isEnabled = true;
                return;
            }
            this.transformInputView.buttonView.label = this.defaultValue;
            this.transformInputView.buttonView.isEnabled = false;
        });
    }

    private getKeyByValue(list: { [key: string]: string }, valueToFind: string): string | undefined {
        const entry = Object.entries(list).find(([key, value]) => value === valueToFind);
        return entry ? entry[0] : undefined;
    }
}

export type InputDialogFormViewCallback = (form: InputDialogView) => string | undefined;
export type InputDialogFormViewDeleteAliasCallback = (form: InputDialogView) => boolean | undefined;
