import { Range, TreeWalkerValue, Writer } from 'ckeditor5';
import { RadioModel } from '../../models/radio/radio-model';
import { RadioBaseCommand } from './radio-base-command';
import { RadioPlugin } from '../../plugins/radio/radio-plugin';
import { GlobalConstant } from '../../models/base/global-constant';

export default class RemoveOptionsCommand extends RadioBaseCommand {

    public override execute(model: RadioModel, totalOptionsInRadioGroup: number): void {
        const editor = this.editor;

        editor.model.change((writer: Writer) => {
            const range = writer.createRangeIn(editor.model.document.getRoot()!);

            const radioModel: TreeWalkerValue | null = this.getElementWithId(range, model.id!);

            this.movePosition(writer, totalOptionsInRadioGroup, model.optionsCount!, radioModel!);

            if (totalOptionsInRadioGroup > 0) {
                writer.setAttribute(RadioPlugin.MODEL_ENTITIES.optionCount.model, totalOptionsInRadioGroup, radioModel!.item);
            } else {
                writer.remove(radioModel!.item);
            }

        });
    }

    private movePosition(writer: Writer, totalOptionsInRadioGroup: number, optionsCount: number, radioModel: TreeWalkerValue): void {
        for (let positionToMove = optionsCount - 1; positionToMove >= totalOptionsInRadioGroup; positionToMove--) {
            this.moveOptionsOutsideRadio(radioModel, positionToMove, writer);
        }
    }
}
