import { Pipe, PipeTransform } from '@angular/core';
import { TemplateFolderDTO } from 'src/app/api/model/templateFolderDTO';
import { FileNode } from '../components/ctbox-tree/models/file-node.model';
import { NodeTreeNodeType } from '../components/ctbox-tree/enums/node-tree-node-type.enum';

@Pipe({
    name: 'nodeFromTemplateFolder'
})

export class FileNodeFromTemplateFolder implements PipeTransform {

    transform(data: TemplateFolderDTO): FileNode {
        const fileNode = new FileNode();
        fileNode.id = data.id;
        fileNode.value = data.name;
        fileNode.parentId = data.templateFolderParentId;
        fileNode.type = NodeTreeNodeType.Folder;
        fileNode.actions = [];
        fileNode.children.forEach((child: TemplateFolderDTO) => {
            fileNode.children.push(this.transform(child));
        });
        return fileNode;
    }
}

