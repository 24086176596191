import { Directive, Inject } from "@angular/core";
import { LabeledFieldView, createLabeledInputText, ButtonView, icons, Locale, InputTextView
} from "ckeditor5";
import BaseView from "../base-view.directive";

@Directive({
    selector: "app-signature-balloon-form-view",
})
export default class SignatureBalloonFormView extends BaseView {

    private currentId = "";

    public roleInputView: LabeledFieldView<InputTextView>;
    private saveButtonView: ButtonView;
    private cancelButtonView: ButtonView;

    private readonly validators: Array<SignatureFormValidatorCallback>;

    private readonly addRol = $localize`:@@PluginFirmasInfoModal:Añadir Rol`;
    private readonly insertButton = $localize`:@@PluginFirmasInsertarBotonModal:Insertar`;
    private readonly cancelButton = $localize`:@@PluginFirmasCancelarBotonModal:Cancelar`;

    constructor(
        @Inject(Array<SignatureFormValidatorCallback>)
        validators: Array<SignatureFormValidatorCallback>,
        @Inject(Locale) locale?: Locale,
        @Inject(String) defaultValue?: string,
        @Inject(String) defaultId?: string
    ) {
        super(locale);

        this.validators = validators;

        this.roleInputView = this.createRoleInput(this.addRol, defaultValue);
        this.currentId = this.generateId(defaultId);

        this.saveButtonView = this.createButton(this.insertButton, "ck-button-save", icons.check);
        this.saveButtonView.type = "submit";

        this.cancelButtonView = this.createButton(this.cancelButton, "ck-button-cancel", icons.cancel);

        this.cancelButtonView.delegate("execute").to(this, "cancel");

        this.items = this.createCollection([ this.roleInputView, this.saveButtonView, this.cancelButtonView ]);

        this.setTemplateForm('ck-signature-balloon');
    }

    public isValid(): boolean {
        this.resetFormStatus();

        for (const validator of this.validators) {
            const errorText = validator(this);

            if (errorText) {
                this.roleInputView.errorText = errorText;
                return false;
            }
        }

        return true;
    }

    public resetFormStatus(): void {
        this.roleInputView.errorText = null;
    }

    public get role(): string | null {
        const { element } = this.roleInputView.fieldView;

        if (!element) {
            return null;
        }

        return element.value.trim();
    }

    public set role(value: string | null) {
        const { element } = this.roleInputView.fieldView;

        if (!element) {
            return;
        }

        element.value = !!value ? value : "";
    }

    public get signatureId(): string {
        return this.currentId;
    }

    public set signatureId(value: string | null | undefined) {
        this.currentId = this.generateId(value);
    }

    private createRoleInput(label: string, defaultValue?: string): LabeledFieldView<InputTextView> {
        const labeledInput = new LabeledFieldView(
            this.locale,
            createLabeledInputText
        );

        labeledInput.label = label;
        if (defaultValue) {
            labeledInput.fieldView.value = defaultValue;
        }

        labeledInput.fieldView.on("input", (value: any) => {
            this.resetFormStatus();
        });

        return labeledInput;
    }

    private generateId(currentId: string | null | undefined): string {
        return !!currentId ? currentId : `sign-${crypto.randomUUID()}`;
    }
}

export type SignatureFormValidatorCallback = (form: SignatureBalloonFormView) => string | undefined;
