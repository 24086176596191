import { Injectable } from '@angular/core';
import { LastModificationFoldersService, PermissionsUserByUserCmcDto } from 'src/app/api';
import { PermissionUserHierarchy } from '../../../models/permission-user-hierarchy';

@Injectable({
    providedIn: 'root'
})
export abstract class IAdministrationPermissionTemplatesFoldersService {

    abstract generateFoldersFromPermissionUserHierarchy(currentUserId: string,
                                                        permissionsUserByUser: PermissionsUserByUserCmcDto[]): PermissionUserHierarchy[];
    abstract hasNewChangesTemplateFolders(lastModificationFoldersService: LastModificationFoldersService): Promise<boolean>;
    abstract updateTemplateFolderChangesDate(): void;
}
