<ng-container *ngIf="parentGroup" [formGroup]="parentGroup">
    <mat-form-field class="{{classInput}}">
        <mat-label *ngIf="showLabel" i18n="@@Nombre">Nombre</mat-label>
        <input #nameInput matInput formControlName="name" i18n="@@IntroduceUnNombre" placeholder="Introduce un nombre" required (blur)="blur($event)">
        <button mat-button *ngIf="showCancelButton()" matSuffix mat-icon-button aria-label="Clear" id="cancelEditFolderName" (click)="cancelName($event)">
            <mat-icon>close</mat-icon>
          </button>
        <mat-error *ngIf="parentGroup.controls.name.errors?.required" i18n="@@ValidacionMensajeNombre">Debes introducir un nombre</mat-error>
        <mat-error *ngIf="parentGroup.controls.name.errors?.maxlength" i18n="@@ValidacionMensajeNombreLongitud">La longitud no puede superar {{maxLength}} caracteres.</mat-error>
        <mat-error *ngIf="parentGroup.controls.name.errors?.invalidCharacters && !parentGroup.controls.name.errors?.maxlength"><span i18n="@@caracteresInvalidos">Caracteres inválidos introducidos: </span> {{parentGroup.controls.name.errors.invalidCharacters}}</mat-error>
    </mat-form-field>
</ng-container>
