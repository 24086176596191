import { Injectable } from '@angular/core';
import { ApiProcessorService } from '../api-processor.service';
import { BaseClauseFolderService } from '../base/base-clause-folder.service';
import { RequestQueryParamsService } from '../requestQueryParams.service';

@Injectable()
export class ClauseFolderService extends BaseClauseFolderService {
    
    public override basePath = '/api/clause-folder';

    constructor(
        protected readonly mapRequestQueryParams: RequestQueryParamsService,
        protected readonly apiProcessorService: ApiProcessorService,
    ) {
        super(apiProcessorService, mapRequestQueryParams);
    }
}
