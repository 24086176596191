import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable, Optional } from '@angular/core';
import { Router, UrlTree } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Configuration } from '../configuration';
import { BASE_PATH } from '../variables';

@Injectable({
    providedIn: 'root'
})
export class ApiProcessorService {
    public constructor(
        @Optional() @Inject(BASE_PATH) private readonly basePath: string,
        @Optional() private readonly configuration: Configuration,
        private readonly http: HttpClient,
        private readonly router: Router) {
    }

    public proccessRequest<T>(request: ApiRequest): Observable<ApiResponse<T> | UrlTree> {
        if (!request.headers) {
            request.headers = new HttpHeaders({ 'Content-Type': 'application/json' });
        }

        if (!request.responseType) {
            request.responseType = 'json';
        }

        const requestHeaders = request.headers;
        return this.http.request<ApiResponse<T>>(request.operation, `${this.basePath}${request.endpointUrl}`, {
            body: request.body, headers: requestHeaders, withCredentials: true, responseType: request.responseType,
            params: request.params
        }).pipe(catchError(this.handleError<ApiResponse<T>>()));
    }

    public postActionAsFormData(formData: FormData, url: string) {
        return this.http.post(`${this.basePath}${url}`, formData, { withCredentials: true });
    }

    private handleError<T>(): (arg: any) => Observable<T | UrlTree> {
        return (error: any): Observable<T> => {
            if (error && error.status === 401) {
                return new Observable((observer) => observer.error(this.router.parseUrl('/home')));
            }

            return throwError(error);
        };
    }
}

export class ApiResponse<T> {
    public response: Response;
    public data: T;
}

export class ApiRequest {
    public operation: ApiOperation;
    public endpointUrl: string;
    public body?: any;
    public headers: HttpHeaders = null;
    public responseType?: any;
    public params?: HttpParams;
}

export enum ApiOperation {
    POST = 'POST',
    PUT = 'PUT',
    DELETE = 'DELETE',
    GET = 'GET',
    PATCH = 'PATCH'
}
