import { NodeTreeActionType } from '../enums/node-tree-action-type.enum';
import { NodeTreeNodeType } from '../enums/node-tree-node-type.enum';

export class FileFlatNode {
    public expandable: boolean;
    public level: number;
    public id: string;
    public value: string;
    public type: NodeTreeNodeType;
    public parentId: string;
    public isChecked: boolean;
    public permissions: NodeTreeActionType[];
    public root: boolean;
    public highlightSelected: boolean;
    public additionalData?: any;
    public valueHighlighted?: string;

    public isSelected(): boolean {
        return this.highlightSelected;
    }
}
