import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { IUploadedFilesResult } from 'src/app/core/shared/models/uploaded-files-result.model';
import { IErrorFile } from 'src/app/shared/components/stepper/error-file.model';
import * as lodash from 'lodash';
import { IFileStepper } from 'src/app/shared/components/upload-file/file-stepper.model';

@Injectable({
    providedIn: 'root',
  })
export class FileStepperDataService {

    private selectedFilesSubject = new BehaviorSubject<IFileStepper[]>([]);
    private addedFileSubject = new Subject<IFileStepper[]>();
    private removedFileSubject = new Subject<IFileStepper>();
    private selectedFolderIdSubject = new BehaviorSubject<string>('');
    private uploadedFilesResultSubject = new BehaviorSubject<IUploadedFilesResult>(null);
    private canGenerateVersion = new BehaviorSubject<boolean>(false);
    private defaultValueGenerateVersion = new BehaviorSubject<boolean>(false);
    private canNamesRepeat = new BehaviorSubject<boolean>(false);
    private errorInFiles = new BehaviorSubject<IErrorFile[]>([]);

    public setFilesSelected(files: IFileStepper[]): void {
      this.selectedFilesSubject.next(files);
    }

    public getFilesSelected(): Observable<IFileStepper[]> {
      return this.selectedFilesSubject.asObservable();
    }

    public getFilesSelectedValue(): IFileStepper[] {
        return lodash.cloneDeep(this.selectedFilesSubject.getValue());
    }

    public setAddFilesSelected(files: IFileStepper[]): void {
        this.addedFileSubject.next(files);
    }

    public getAddFilesSelected(): Observable<IFileStepper[]> {
        return this.addedFileSubject.asObservable();
    }

    public setRemoveFile(file: IFileStepper): void {
        this.removedFileSubject.next(file);
    }

    public getRemoveFile(): Observable<IFileStepper> {
        return this.removedFileSubject.asObservable();
    }

    public setSelectedFolderId(folderId: string): void {
        this.selectedFolderIdSubject.next(folderId);
    }

    public getSelectedFolderId(): Observable<any> {
        return this.selectedFolderIdSubject.asObservable();
    }

    public setResultUploadedFiles(uploadedFilesResult: IUploadedFilesResult): void {
        this.uploadedFilesResultSubject.next(uploadedFilesResult);
    }

    public getResultUploadedFiles(): Observable<any> {
        return this.uploadedFilesResultSubject.asObservable();
    }

    public setCanGenerateVersion(canGenerateVersion: boolean): void {
        this.canGenerateVersion.next(canGenerateVersion);
    }

    public getCanGenerateVersion(): Observable<boolean> {
        return this.canGenerateVersion.asObservable();
    }

    public getCanGenerateVersionValue(): boolean {
        return lodash.cloneDeep(this.canGenerateVersion.getValue());
    }

    public setDefaultValueGenerateVersion(defaultValueGenerateVersion: boolean): void {
        this.defaultValueGenerateVersion.next(defaultValueGenerateVersion);
    }

    public getDefaultValueGenerateVersion(): Observable<boolean> {
        return this.defaultValueGenerateVersion.asObservable();
    }

    public setCanNamesRepeat(canNamesRepeat: boolean): void {
        this.canNamesRepeat.next(canNamesRepeat);
    }

    public getCanNamesRepeat(): Observable<any> {
        return this.canNamesRepeat.asObservable();
    }

    public getCanNamesRepeatValue(): boolean {
        return lodash.cloneDeep(this.canNamesRepeat.getValue());
    }

    public setErrorInFiles(errors: IErrorFile[]): void {
      this.errorInFiles.next(errors);
    }

    public getErrorInFiles(): Observable<any> {
      return this.errorInFiles.asObservable();
    }

    public getErrorInFilesValue(): IErrorFile[] {
        return lodash.cloneDeep(this.errorInFiles.getValue());
    }

    public reset(): void {
        this.selectedFilesSubject.next([]);
        this.selectedFolderIdSubject.next('');
        this.uploadedFilesResultSubject.next(null);
        this.canGenerateVersion.next(false);
        this.canNamesRepeat.next(false);
        this.errorInFiles.next([]);
    }
}
