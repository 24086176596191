/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { MailUnsubcriptionsStatusDTO } from '../model/mailUnsubcriptionsStatusDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';


@Injectable()
export class NotificationService {

    protected basePath = '/';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     * @param consumes string[] mime-types
     * @return true: consumes contains 'multipart/form-data', false: otherwise
     */
    private canConsumeForm(consumes: string[]): boolean {
        const form = 'multipart/form-data';
        for (const consume of consumes) {
            if (form === consume) {
                return true;
            }
        }
        return false;
    }


    /**
     * 
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationsEmailGet(email: string, observe?: 'body', reportProgress?: boolean): Observable<MailUnsubcriptionsStatusDTO>;
    public notificationsEmailGet(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<MailUnsubcriptionsStatusDTO>>;
    public notificationsEmailGet(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<MailUnsubcriptionsStatusDTO>>;
    public notificationsEmailGet(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling notificationsEmailGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<MailUnsubcriptionsStatusDTO>('get',`${this.basePath}/notifications/${encodeURIComponent(String(email))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationsSubscribeAlertClausesEmailEmailPost(email: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public notificationsSubscribeAlertClausesEmailEmailPost(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public notificationsSubscribeAlertClausesEmailEmailPost(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public notificationsSubscribeAlertClausesEmailEmailPost(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling notificationsSubscribeAlertClausesEmailEmailPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/notifications/subscribeAlertClausesEmail/${encodeURIComponent(String(email))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationsSubscribeAlertDocumentsEmailEmailPost(email: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public notificationsSubscribeAlertDocumentsEmailEmailPost(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public notificationsSubscribeAlertDocumentsEmailEmailPost(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public notificationsSubscribeAlertDocumentsEmailEmailPost(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling notificationsSubscribeAlertDocumentsEmailEmailPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/notifications/subscribeAlertDocumentsEmail/${encodeURIComponent(String(email))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationsSubscribeAlertTemplatesEmailEmailPost(email: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public notificationsSubscribeAlertTemplatesEmailEmailPost(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public notificationsSubscribeAlertTemplatesEmailEmailPost(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public notificationsSubscribeAlertTemplatesEmailEmailPost(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling notificationsSubscribeAlertTemplatesEmailEmailPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/notifications/subscribeAlertTemplatesEmail/${encodeURIComponent(String(email))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationsUnsubscribeAlertClausesEmailEmailPost(email: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public notificationsUnsubscribeAlertClausesEmailEmailPost(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public notificationsUnsubscribeAlertClausesEmailEmailPost(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public notificationsUnsubscribeAlertClausesEmailEmailPost(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling notificationsUnsubscribeAlertClausesEmailEmailPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/notifications/unsubscribeAlertClausesEmail/${encodeURIComponent(String(email))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationsUnsubscribeAlertDocumentsEmailEmailPost(email: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public notificationsUnsubscribeAlertDocumentsEmailEmailPost(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public notificationsUnsubscribeAlertDocumentsEmailEmailPost(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public notificationsUnsubscribeAlertDocumentsEmailEmailPost(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling notificationsUnsubscribeAlertDocumentsEmailEmailPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/notifications/unsubscribeAlertDocumentsEmail/${encodeURIComponent(String(email))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * 
     * 
     * @param email 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public notificationsUnsubscribeAlertTemplatesEmailEmailPost(email: string, observe?: 'body', reportProgress?: boolean): Observable<any>;
    public notificationsUnsubscribeAlertTemplatesEmailEmailPost(email: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<any>>;
    public notificationsUnsubscribeAlertTemplatesEmailEmailPost(email: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<any>>;
    public notificationsUnsubscribeAlertTemplatesEmailEmailPost(email: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (email === null || email === undefined) {
            throw new Error('Required parameter email was null or undefined when calling notificationsUnsubscribeAlertTemplatesEmailEmailPost.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        let httpHeaderAccepts: string[] = [
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<any>('post',`${this.basePath}/notifications/unsubscribeAlertTemplatesEmail/${encodeURIComponent(String(email))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
