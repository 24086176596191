export abstract class ISmartFormConvertService {
    abstract updateSmartFormName(SmartFormName: string);
    abstract getCurrentSmartFormName(): string;
    abstract updateSmartFormContent(SmartFormContent: string);
    abstract getCurrentSmartFormContent(): string;
    abstract updateSmartFormFileExtension(extension: string);
    abstract getNotEditableFileExtension(): string;
    abstract updateNotEditableFile(file: File);
    abstract getNotEditableFile(): File;
}
