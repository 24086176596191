import { Component, Inject, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { invalidCharactersValidator } from 'src/app/core/shared/validators/formValidators/invalidCharactersValidator';

@Component({
    selector: 'app-ctbox-workflow-info',
    templateUrl: './ctbox-workflow-info.component.html',
    styleUrls: ['./ctbox-workflow-info.component.scss']
})
export class CtboxWorkflowInfoComponent implements OnInit {

    @Input() public parentGroup: UntypedFormGroup;
    @Input() public controlName: string;
    @Input() public isRejectWorkflow: boolean;
    @Input() public isCancelWorkflow: boolean;

    public comment = '';
    public form: UntypedFormGroup;

    constructor(@Inject(MAT_DIALOG_DATA) public data) {
        this.isRejectWorkflow = data?.dataSource?.isRejectWorkflow;
        this.isCancelWorkflow = data?.dataSource?.isCancelWorkflow;
    }

    public ngOnInit(): void {
        this.initializeFormGroup();
    }

    private initializeFormGroup(): void {
        this.form = new UntypedFormGroup(
            {
                comment: new UntypedFormControl('', [Validators.maxLength(500), invalidCharactersValidator]),
            }
        );
    }

    public getComment(): void {
        this.comment = this.form.controls.comment.value;
    }

}
