import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { PagesWithContextualHelp } from 'src/app/core/shared/enums/pages-with-contextual-help.enum';
import { IContextualHelpService } from 'src/app/core/shared/services/contextual-help/contextual-help.service.interface';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { MyDocumentsContextualHelpComponent } from '../my-documents-contextual-help/my-documents-contextual-help.component';
import { DocumentsDraftContextualHelpComponent } from '../documents-draft-contextual-help/documents-draft-contextual-help.component';
import { DocumentsReviewContextualHelpComponent } from '../documents-review-contextual-help/documents-review-contextual-help.component';
import { DocumentsCompleteContextualHelpComponent } from '../documents-complete-contextual-help/documents-complete-contextual-help.component';
import { DocumentsSendToSignContextualHelpComponent } from '../documents-send-to-sign-contextual-help/documents-send-to-sign-contextual-help.component';
import { DocumentsSignedContextualHelpComponent } from '../documents-signed-contextual-help/documents-signed-contextual-help.component';
import { DocumentsMainEditionContextualHelpComponent } from '../documents-main-edition-contextual-help/documents-main-edition-contextual-help.component';
import { TemplatesEditionContextualHelpComponent } from '../templates-edition-contextual-help/templates-edition-contextual-help.component';
import { ClausesEditionContextualHelpComponent } from '../clauses-edition-contextual-help/clauses-edition-contextual-help.component';
import { MyClausesContextualHelpComponent } from '../my-clauses-contextual-help/my-clauses-contextual-help.component';
import { MyTemplatesContextualHelpComponent } from '../my-templates-contextual-help/my-templates-contextual-help.component';
import { AdministrationMainContextualHelpComponent } from '../administration-main-contextual-help/administration-main-contextual-help.component';

@Component({
    selector: 'app-contextual-help',
    templateUrl: './contextual-help.component.html',
    styleUrls: ['./contextual-help.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class ContextualHelpComponent implements OnInit {

    @Input() currentComponentSelector: string;

    public currentShowPage: PagesWithContextualHelp;
    public existHelp: boolean;

    constructor(public genericDialogService: GenericDialogService,
        public contextualHelpService: IContextualHelpService) {
    }

    public ngOnInit(): void {

        this.currentShowPage = this.contextualHelpService.getContextualPageToShow(this.currentComponentSelector);
        this.existHelp = this.currentShowPage !== null;
    }

    public openContextualHelpModal() {
        const config = this.genericDialogService.getBigDialogConfig();
        const data: any = {
            displayCloseOption: true,
        };

        switch (this.currentShowPage) {
            case PagesWithContextualHelp.AdministrationLibrary:
                this.genericDialogService.openTemplateWithConfigAndData(AdministrationMainContextualHelpComponent, config, data);
                break;
            case PagesWithContextualHelp.ClausesList:
                this.genericDialogService.openTemplateWithConfigAndData(MyClausesContextualHelpComponent, config, data);
                break;
            case PagesWithContextualHelp.ClausesEdition:
                this.genericDialogService.openTemplateWithConfigAndData(ClausesEditionContextualHelpComponent, config, data);
                break;
            case PagesWithContextualHelp.TemplatesList:
                this.genericDialogService.openTemplateWithConfigAndData(MyTemplatesContextualHelpComponent, config, data);
                break;
            case PagesWithContextualHelp.TemplatesEdition:
                this.genericDialogService.openTemplateWithConfigAndData(TemplatesEditionContextualHelpComponent, config, data);
                break;
            case PagesWithContextualHelp.ContractsListAll:
                this.genericDialogService.openTemplateWithConfigAndData(MyDocumentsContextualHelpComponent, config, data);
                break;
            case PagesWithContextualHelp.ContractsEdition:
                this.genericDialogService.openTemplateWithConfigAndData(DocumentsMainEditionContextualHelpComponent, config, data);
                break;
            case PagesWithContextualHelp.ContractsListDraft:
                this.genericDialogService.openTemplateWithConfigAndData(DocumentsDraftContextualHelpComponent, config, data);
                break;
            case PagesWithContextualHelp.ContractsListReview:
                this.genericDialogService.openTemplateWithConfigAndData(DocumentsReviewContextualHelpComponent, config, data);
                break;
            case PagesWithContextualHelp.ContractsListComplete:
                this.genericDialogService.openTemplateWithConfigAndData(DocumentsCompleteContextualHelpComponent, config, data);
                break;
            case PagesWithContextualHelp.ContractsListSendToSign:
                this.genericDialogService.openTemplateWithConfigAndData(DocumentsSendToSignContextualHelpComponent, config, data);
                break;
            case PagesWithContextualHelp.ContractsListSigned:
                this.genericDialogService.openTemplateWithConfigAndData(DocumentsSignedContextualHelpComponent, config, data);
                break;
        }
    }

}
