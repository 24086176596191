<div class="c-help">
    <div class="c-help-header">
        <h3 class="c-help-header-title c-help-signing">Enviados a firma</h3>
        <p class="c-help-header-text">En Enviados a firma podrás consultar y gestionar los documentos ya completados pendientes de ser firmados por las partes intervinientes.</p>
    </div>
    <div class="c-help-content">
        <mat-tab-group mat-align-tabs="start" class="c-help signing">
            <mat-tab>
                <ng-template mat-tab-label>Acciones a realizar</ng-template>
                <div class="c-help-tab-body">
                    <div class="c-help-block">
                        <ul class="c-help-list">
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonType="secondary" buttonIcon="circle" buttonColor="state" customClass="filled no-border signing xsmall"></ctbox-button></div>
                                <p>Muestra el estado del documento</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIconSvg="signature" buttonColor="info"></ctbox-button></div>
                                <p><strong>Estado de firma:</strong> Muestra el estado del documento.</p>
                            </li>
                            <li class="c-help-list-flexrow">
                                <div class="c-help-img"><ctbox-button buttonIcon="download" buttonColor="info"></ctbox-button></div>
                                <p><strong>Descargar:</strong> Para descargar documentos.</p>
                            </li>
                        </ul>
                    </div>
                </div>
            </mat-tab>
            <mat-tab>
                <ng-template mat-tab-label>Organización de documentos</ng-template>
                <div class="c-help-tab-body">
                    <ul class="c-help-list">
                        <li class="c-help-list-flexrow">
                            <div class="c-help-img">
                                <h4 class="c-help-signing">Mis documentos</h4>
                            </div>
                            <p>Éste es tu espacio personal. Tanto las carpetas como los documentos son sólo visibles para ti. Si quieres que el resto de compañeros vea tus documentos muévelos a Nuestros documentos.</p>
                        </li>
                        <li class="c-help-list-flexrow">
                            <div class="c-help-img">
                                <h4 class="c-help-signing">Nuestros documentos</h4>
                            </div>
                            <p>Éste es el espacio corporativo de tu compañía. Estos documentos serán visibles sólo para los usuarios que tengan permisos de acceso en estas carpetas.</p>
                        </li>
                        <li class="c-help-list-flexrow">
                            <div class="c-help-img">
                                <h4 class="c-help-signing">Pendientes de mi firma</h4>
                            </div>
                            <p>Tendrás el listado completo de los documentos pendientes de tu firma.</p>
                        </li>
                    </ul>
                </div>
            </mat-tab>
        </mat-tab-group>
    </div>
</div>