import { FileFlatNode } from 'src/app/shared/components/ctbox-tree/models/file-flat-node.model';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-ctbox-tree-action-image',
  templateUrl: './ctbox-tree-action-image.component.html'
})
export class CtboxTreeActionImageComponent implements OnInit {

    @Input() node: FileFlatNode;

    constructor() { }

    ngOnInit(): void {
    }

}
