import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'app-ctbox-not-found',
    templateUrl: './ctbox-not-found.component.html'
})
export class NotFoundComponent {

    constructor(private router: Router) { }

    public goToInit(): void {
        this.router.navigate(['/']);
    }
}
