import {
    Directive, Input, TemplateRef, ViewContainerRef, OnChanges, SimpleChanges
} from '@angular/core';

@Directive({
    selector: '[appReload]'
})
export class ReloadDirective implements OnChanges {

    @Input() appReload: number;

    constructor(private templateRef: TemplateRef<any>,
                private viewContainerRef: ViewContainerRef) {

        this.viewContainerRef.createEmbeddedView(templateRef);
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.appReload && changes.appReload.previousValue !== undefined &&
            changes.appReload.currentValue !== changes.appReload.previousValue) {
            this.viewContainerRef.clear();
            this.viewContainerRef.createEmbeddedView(this.templateRef);
        }
    }

}
