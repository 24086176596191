export enum DocumentCreationType {
    edit = 0,
    empty = 1,
    fromFile = 2,
    fromLld = 3,
    fromSmartform = 4,
    fromTemplate = 5
}

export enum DocumentCreationOrigin {
    template = 1,
    document = 2,
    annexed = 3,
}

export enum DocumentDestinationType {
    template = 1,
    document = 2,
    annexed = 3,
}
