import { DowncastWriter, Editor } from 'ckeditor5';
import { PluginUtilsService } from '../../../utils/plugin-utils.service';
import { InputEditionViewUtilsService } from '../../utils/input/input-edition-view-utils.service';
import { BaseLinkedFieldCommand } from './base-linked-field-command';

export abstract class BaseAddEditLinkedFieldCommand extends BaseLinkedFieldCommand {

    protected utilsService: PluginUtilsService;
    protected inputEditionUtilsService: InputEditionViewUtilsService;

    constructor(editor: Editor) {
        super(editor);
        this.utilsService = new PluginUtilsService();
        this.inputEditionUtilsService = new InputEditionViewUtilsService();
    }

    protected validateLinkedField(id: string): void {
        const contentElement = this.utilsService.getElementView(this.editor, id);

        const editingView = this.editor.editing.view;
        editingView.change((writer: DowncastWriter) => {
            this.inputEditionUtilsService.applyValidationToInputEditingView(writer, contentElement);
        });
    }
}
