import {Plugin} from "ckeditor5";
import { CkeditorUser } from "./ckeditor-user.model";
import { User } from "ckeditor5-premium-features";
/**
 * The `UsersIntegration` lets you manage user data and permissions.
 *
 * This is an essential feature when many users work on the same document.
 *
 * To read more about it, visit the CKEditor 5 documentation: https://ckeditor.com/docs/ckeditor5/latest/features/collaboration/users.html.
 */
export class UsersIntegration extends Plugin {
	static get requires() {
		return ['Users'];
	}

	static get pluginName() {
		return 'UsersIntegration';
	}

    private users: User[];
    private usersPlugin: any;

    public init(): void {
        this.usersPlugin = this.editor.plugins.get('Users');

        this.users = [];
        const me = this.users[0];

		for (const user of this.users) {
			(this.usersPlugin as any).addUser(user);
		}

        //(this.usersPlugin as any).useAnonymousUser();
    }

    public setUsers(usersInDocument: User[],  currentUserId: string) {
        this.users = [];

        usersInDocument.forEach((user: User) =>
			this.users.push(user),
        );

		for (const user of this.users) {
            (this.usersPlugin as any).addUser(user);
		}

        const me = this.users.find(user => user.id == currentUserId)
        if(!me) {
            (this.usersPlugin as any).useAnonymousUser();
            return;
        }

		(this.usersPlugin as any).defineMe(me.id);
	}
}
