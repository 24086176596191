import { Pipe, PipeTransform } from '@angular/core';
import { TableOfContentsDTO, TableOfContentsNodeDTO } from 'src/app/api';
import { FileNode } from '../components/ctbox-tree/models/file-node.model';
import { NodeTreeAction } from '../components/ctbox-tree/models/node-tree-action.model';
import { NodeTreeNodeType } from '../components/ctbox-tree/enums/node-tree-node-type.enum';

@Pipe({
    name: 'nodeFromTableOfContent'
})

export class FileNodeFromTableOfContent implements PipeTransform {

    transform(data: TableOfContentsDTO[], actions: NodeTreeAction): FileNode[] {
        const root: FileNode[] = [];
        data.forEach((node: TableOfContentsNodeDTO) => {
            root.push(this.transformNode(node, actions));
        });
        return root;
    }

    transformNode(data: TableOfContentsNodeDTO, actions: NodeTreeAction): FileNode {
        const fileNode = new FileNode();
        fileNode.id = data.id;
        fileNode.value = data.description;
        fileNode.parentId = data.parentId;
        fileNode.type = NodeTreeNodeType.Folder;
        fileNode.actions = [];
        fileNode.children.forEach((child: TableOfContentsNodeDTO) => {
            fileNode.children.push(this.transformNode(child, actions));
        });
        return fileNode;
    }

    configureType(data: TableOfContentsNodeDTO): NodeTreeNodeType {
        if (data.contents.length > 0) {
            return NodeTreeNodeType.Folder;
        }
        if (data.isSmartForm) {
            return NodeTreeNodeType.Smartform;
        } else {
            return NodeTreeNodeType.Template;
        }
    }
}

