/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */

export type DocumentStatus = 0 | 1 | 2;

export const DocumentStatus = {
    NUMBER_0: 0 as DocumentStatus,
    NUMBER_1: 1 as DocumentStatus,
    NUMBER_2: 2 as DocumentStatus
};