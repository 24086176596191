import { Directive, ViewChild } from '@angular/core';
import { ClausesPlugin } from '../../wysiwyg-editor/custom-plugins/plugins/clause/clauses-plugin';
import { SignaturePlugin } from '../../wysiwyg-editor/custom-plugins/plugins/signature/signature-plugin';
import { EditorPermissionConfig } from '../../wysiwyg-editor/models/editor-permission-config';
import { EditorPluginConfig, PluginConfig } from '../../wysiwyg-editor/models/editor-plugin-config';
import { WysiwygEditorComponent } from '../../wysiwyg-editor/wysiwyg-editor.component';
import { ContextEditorTypes } from '../../wysiwyg-editor/custom-plugins/models/base/context-editor-types';
import { CommentThreadDataJSON, User } from 'ckeditor5-premium-features';
import { UserInfoDTO } from 'src/app/api';
import { strict } from 'assert';

@Directive()
export abstract class BaseWithEditorDirective {

    public permissionConfig: EditorPermissionConfig = new EditorPermissionConfig();
    public editorPluginConfig: EditorPluginConfig = new EditorPluginConfig();
    public isReadOnly = false;
    public resetUnsavedChangesNotifier = false;

     @ViewChild('editor') public editor: WysiwygEditorComponent;

    constructor() {
        this.editorPluginConfig.pluginsConfig = new Array<PluginConfig>();
    }

    protected configureEditionWithPermission(permissions: any) {
        if (permissions.canEdit) {
            this.enableEdition();
        } else {
            this.disableEdition();
        }

        this.permissionConfig.canComment = permissions.canComment;
        this.permissionConfig.canDeleteComments = permissions.canDeleteComments;
    }


    protected enableEdition() {
        this.permissionConfig.canEdit = true;
    }

    protected disableEdition() {
        this.permissionConfig.canEdit = false;
    }

    protected enableReadOnly() {
        this.isReadOnly = true;
        //TODO quizas activar desactivar plugin de comentarios a comentarios solo lectura
    }

    protected disableReadOnly() {
        this.isReadOnly = false;
    }

    protected notifyResetUnsavedChanges(resetUnsavedChanges: boolean) {
        this.resetUnsavedChangesNotifier = resetUnsavedChanges;
    }

    protected configurePluginsDocumentEditor(permissions: any): void {
        this.editorPluginConfig.contextEditor = ContextEditorTypes.DOCUMENT;
        this.editorPluginConfig.pluginsConfig.push(this.getClausePluginEditorConfig(permissions.canAddClauses));
        this.editorPluginConfig.pluginsConfig.push(this.getSignaturePluginEditorConfig(permissions.canEdit));
    }

    protected configurePluginsTemplateEditor(permissions: any): void {
        this.editorPluginConfig.contextEditor = ContextEditorTypes.TEMPLATE;
        this.editorPluginConfig.pluginsConfig.push(this.getClausePluginEditorConfig(permissions.canAddClauses));
        this.editorPluginConfig.pluginsConfig.push(this.getSignaturePluginEditorConfig(permissions.canEdit));
    }

    protected configurePluginsClauseEditor(): void {
        this.editorPluginConfig.contextEditor = ContextEditorTypes.CLAUSE;
        const clauseAndSignaturePluginsEnabled = false;
        this.editorPluginConfig.pluginsConfig.push(this.getClausePluginEditorConfig(clauseAndSignaturePluginsEnabled));
        this.editorPluginConfig.pluginsConfig.push(this.getSignaturePluginEditorConfig(clauseAndSignaturePluginsEnabled));
    }

    protected getClausePluginEditorConfig(isEnabled: boolean): PluginConfig {
        return {
            isEnabled: isEnabled,
            pluginName: ClausesPlugin.pluginName,
            toolbarTooltip: ClausesPlugin.TOOLBAR_TOOLTIP,
            lockId: ClausesPlugin.ENABLE_DISABLE_LOCKID,
        };
    }

    protected getSignaturePluginEditorConfig(isEnabled: boolean): PluginConfig {
        return {
            isEnabled: isEnabled,
            pluginName: SignaturePlugin.pluginName,
            toolbarTooltip: SignaturePlugin.TOOLBAR_TOOLTIP,
            lockId: SignaturePlugin.ENABLE_DISABLE_LOCKID
        };
    }

    protected hasUnsavedChanges(): boolean {
        return this.editor ? this.editor.hasUnsavedChanges() : false;
    }

    protected getComments(): string {
        const users = this.editor.getUsers();

        const comments = !!this.editor ? this.editor?.getComments() : '';
        return this.setUsersInComments(comments, users);
    }

    protected separateCommentsAndUsers(jsonString: string) {
        if (!jsonString) {
            return [[], []];
        }

        const jsonData: [CommentThreadDataJSON[], User[]] = JSON.parse(jsonString);
        return jsonData;
    }

    protected getUser(userInfo: UserInfoDTO): User {
        const user: any =
        {
            id: userInfo.guid,
            name: `${userInfo.name} ${userInfo.surname}`
        };

        return user
    }

    protected getUsersInComments(comments: string): User[] {
        const [_commentsWithoutUsers, users] = this.separateCommentsAndUsers(comments);
        return users;
    }

    protected getCommentsWithoutUsers(comments: string): string {
        const [commentsWithoutUsers, _] = this.separateCommentsAndUsers(comments);
        if (commentsWithoutUsers?.length < 1) {
            "";
        }
        return JSON.stringify(commentsWithoutUsers);
    }

    protected setUsersInComments(comments: string, users: User[]): string {
        const commentsString = JSON.parse(comments);
        const stringToJson = [commentsString, users];
        //TODO poner en json como un parametro nuevo que lee el de arriba
        return JSON.stringify(stringToJson);
    }

}
