<div class="comparator">
    <div class="comparator-header">
        <div class="comparator-info size-50">
            <div class="comparator-title inforce">
                <div class="comparator-title-label" i18n="@@CurrentVersion">VERSIÓN ACTUAL:</div>
                <div class="comparator-title-name">{{latestVersion.label}}</div>
            </div>
        </div>
        <div class="comparator-info size-50">
            <div class="comparator-title not-inforce">
                <div class="comparator-title-label" i18n="@@PreviousVersion">VERSIÓN ANTERIOR: </div>
                <div class="comparator-title-name">{{currentVersionSelected.label}}</div>
            </div>
        </div>
    </div>
    <div class="comparator-body">
        <div class="comparator-html size-50 inforce">
            <app-html-viewer class="versions-editor test" [html]="compareHtmlVersionResult.diffHtml" [addScrollSm]="true" [replaceFilledInput]="false"></app-html-viewer>
        </div>
        <div class="comparator-html size-50 not-inforce">
            <app-html-viewer class="versions-editor" [html]="compareHtmlVersionResult.originalHtml" [addScrollSm]="true" [replaceFilledInput]="false"></app-html-viewer>
        </div>
    </div>
    <div class="comparator-footer">
        <ctbox-button buttonIcon="square"  buttonText="Texto nuevo" i18n-buttonText="@@TextoNuevo" customClass="filled new-text"></ctbox-button>
        <ctbox-button buttonIcon="square" buttonText="Texto eliminado" i18n-buttonText="@@TextoEliminado" customClass="filled deleted"></ctbox-button>
    </div>
</div>
