<div class="upload-file">
    <div *ngIf="allowDragAndDrop" class="upload-file-drag-area" appDragAndDrop (fileDropped)="onFileDropped($event)">
        <div class="upload-file-drag-text" i18n="@@ArrastraArchivosAEstaZonaOBien">Arrastra archivos a esta zona o bien </div>
        <input class="upload-file-drag-ref" type="file" #fileDropRef id="fileDropRef" (change)="fileBrowseHandler($event.target.files)" accept="{{getAllowedExtensionsAsString()}}" multiple />
        <div class="upload-file-input-area">
            <ctbox-button class="upload-file-input-button" buttonType="secondary" buttonText="Examinar" i18n-buttonText="@@Examinar"></ctbox-button>
            <input #fileAttachments  class="upload-file-input-ref" type="file" (change)="onFilesUploaded($event)" multiple />
        </div>
    </div>
</div>
