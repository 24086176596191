import { Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, OnChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FlatTreeControl } from '@angular/cdk/tree';
import { SelectionModel } from '@angular/cdk/collections';
import { Observable, Subscription } from 'rxjs';
import { FileFlatNode } from '../models/file-flat-node.model';
import { FileNode } from '../models/file-node.model';
import { NodeTreeNodeType } from '../enums/node-tree-node-type.enum';
import { NodeTreeActionType } from '../enums/node-tree-action-type.enum';
import { NodeTreeAction } from '../models/node-tree-action.model';
import { ITreeStandardService } from 'src/app/shared/components/ctbox-tree/service/tree-standard-service.interface';
import { CtboxNodeIdDroppedEvent } from 'src/app/shared/components/ctbox-tree/ctbox-tree-events/ctbox-node-id-dropped-event.component';
import { CtboxValidateNameComponent } from 'src/app/shared/components/ctbox-validate-name/ctbox-validate-name.component';
import { NodeTreeUserAction } from '../enums/node-tree-user-action.enum';

@Component({
    selector: 'ctbox-tree-node',
    templateUrl: './ctbox-tree-node.component.html',
    styleUrls: ['./ctbox-tree-node.component.scss']
})
export class CtboxTreeNodeComponent implements OnInit, OnDestroy, OnChanges {

    @Input() node: FileFlatNode;
    @Input() treeControl: FlatTreeControl<FileFlatNode>;
    @Input() canDropFromOutside: boolean;
    @Input() labelToMarkIsDroppable: string;
    @Input() attributeToReadInDropElement: string;
    @Input() flatNodeMap: Map<FileFlatNode, FileNode>;
    @Input() nestedNodeMap: Map<FileNode, FileFlatNode>;
    @Input() expansionModel: SelectionModel<string>;
    @Input() hasChild: boolean;
    @Input() eventEdit: Observable<string>;
    @Input() isTreeLocked: boolean;
    @Input() classNodeActions: string;
    @Input() movementForbidden: boolean;
    @Input() actions: NodeTreeUserAction[];

    @Output() onNodeAction: EventEmitter<NodeTreeAction> = new EventEmitter<NodeTreeAction>(null);
    @Output() expandNode: EventEmitter<FileFlatNode> = new EventEmitter<FileFlatNode>(null);

    @ViewChild('nodeNameInput') nodeNameInput: CtboxValidateNameComponent;
    @HostBinding('className') componentClass: string;

    public get NodeTreeActionType(): typeof NodeTreeActionType {
        return NodeTreeActionType;
    }

    public get NodeTreeNodeType(): typeof NodeTreeNodeType {
        return NodeTreeNodeType;
    }

    public get NodeTreeUserAction(): typeof NodeTreeUserAction {
        return NodeTreeUserAction;
    }

    public editable = false;
    public nodeForm: UntypedFormGroup;
    public forceShowCheck = false;

    private nodeName: string;
    private eventEditSubscription: Subscription;
    private keyEnterPressed = false;

    constructor(private treeService: ITreeStandardService) {
        this.classNodeActions = this.classNodeActions ? undefined : 'menu';
        this.componentClass = 'mat-tree-node ' + this.classNodeActions;

    }

    public ngOnInit(): void {
        if (!this.node) {
            return;
        }
        this.eventEditSubscription = this.eventEdit.subscribe((nodeId: string) => {
            if (this.node.id !== nodeId) {
                return;
            }
            this.startToEdit();
        });
        this.initFormModel();
        this.nodeForm.controls.name.setValue(this.node?.value);
        this.componentClass = this.componentClass + ' ' + this.node.type + ' level-' + this.node.level;
        this.setAction();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.actions || changes.node) {
            this.setAction();
        }
    }

    public ngOnDestroy() {
        if (this.eventEditSubscription) {
            this.eventEditSubscription.unsubscribe();
        }
    }

    public fileNodeDroppedWithId(nodeEvent: CtboxNodeIdDroppedEvent): void {
        const additionalParam = this.treeService.createAdditionalParamWithDroppedObjectId(nodeEvent.id);
        const nodeAction = this.treeService
            .createNodeTreeActionWithAdditionalParam(nodeEvent.node, NodeTreeActionType.MoveFromOutside, additionalParam);
        this.onNodeAction.emit(nodeAction);
    }

    public setClassFileIcon(node: FileFlatNode) {
        return this.getClassNodeType(node);
    }

    public setClassNode(node: FileFlatNode) {
        try {
            let classType = this.getClassNodeType(node);

            if (!this.hasPermission(NodeTreeActionType.Selected)) {
                classType += ' no-access ';
            }

            const classLevel = 'level-' + this.node.level;
            return classType + ' ' + classLevel + ' ' + this.classNodeActions;
        }
        catch
        {
            return '';
        }
    }

    public getClassNodeType(node: FileFlatNode): string {
        if (!node) {
            return 'folder';
        }
        switch (node.type) {
            case NodeTreeNodeType.Folder: return 'folder';
            case NodeTreeNodeType.Smartform: return 'smartform';
            case NodeTreeNodeType.Clause: return 'clause';
            case NodeTreeNodeType.MyDocuments: return 'documents';
            case NodeTreeNodeType.OurDocuments: return 'documents';
            case NodeTreeNodeType.Shared: return 'documents';
            case NodeTreeNodeType.Template: return 'template';
            default: return 'folder';
        }
    }

    public setIconToolTip(node: FileFlatNode): string {
        switch (node.type) {
            case NodeTreeNodeType.Template:
                if (node.additionalData?.isInCreation) {
                    return $localize`:@@PlantillaEnCreacionShortMessage:Plantilla en creación.`;
                }
                break;
            case NodeTreeNodeType.Clause:
                if (node.additionalData?.isInCreation) {
                    return $localize`:@@ClausulaEnCreacionShortMessage:Cláusula en creación.`;
                }
                break;
        }

        if (node.additionalData?.isOnUpdate) {
            return 'En actualización';
        }

        return '';
    }

    public setIconFile(node: FileFlatNode) {
        switch (node.type) {
            case NodeTreeNodeType.Folder: return 'folder';
            case NodeTreeNodeType.MyDocuments: return 'person';
            case NodeTreeNodeType.OurDocuments: return 'supervisor_account';
            case NodeTreeNodeType.Shared: return 'share';
            case NodeTreeNodeType.Smartform: return 'description';
            case NodeTreeNodeType.Template:
            case NodeTreeNodeType.Clause:
            case NodeTreeNodeType.ToCFile:
                if (node.additionalData?.isInCreation) {
                    return 'quick_reference';
                } else if (node.additionalData?.isOnUpdate) {
                    return 'download';
                }
                return 'description';
        }
    }

    public iconClick(node: FileFlatNode) {
        if (node.expandable) {
            this.expandNode.emit(node);
        }

        if (!this.hasPermission(NodeTreeActionType.Selected)) {
            return;
        }
    }

    public selectNode(node: FileFlatNode) {
        if (!this.hasPermission(NodeTreeActionType.Selected)) {
            return;
        }
        const nodeTreeAction = this.treeService.createNodeTreeAction(node, NodeTreeActionType.Selected);
        this.onNodeAction.emit(nodeTreeAction);
    }

    public hasPermission(actionType: NodeTreeActionType): boolean {
        return this.node?.permissions?.includes(actionType);
    }

    public resetName() {
        this.node.value = this.nodeName;
        this.nodeForm.controls.name.setValue(this.nodeName);
        this.editable = false;
    }

    public editKeyEnter(event: any) {
        this.keyEnterPressed = true;
        this.finishEdit(event);
    }

    public blurEdition(event: any) {
        if (this.keyEnterPressed) {
            return;
        }

        this.finishEdit(event);
    }

    public isDragDisabled(): boolean {
        return !this.hasPermission(NodeTreeActionType.Move) || this.editable || this.isTreeLocked;
    }

    public checkToggle(node: FileFlatNode): void {
        node.isChecked = !node.isChecked;

        const nodeAction = this.treeService.createNodeTreeAction(this.node, NodeTreeActionType.Checked);
        this.onNodeAction.emit(nodeAction);
    }

    public valueToUse(): string {
        if (!!this.node.valueHighlighted) {
            return this.node.valueHighlighted;
        }
        return this.node.value;
    }

    public hasNodeTypeWithCheck(node: FileFlatNode): boolean {
        switch (node.type) {
            case NodeTreeNodeType.Folder:
            case NodeTreeNodeType.MyDocuments:
            case NodeTreeNodeType.OurDocuments:
            case NodeTreeNodeType.Shared:
            case NodeTreeNodeType.ToCFile:
            case NodeTreeNodeType.Smartform:
                return false;
            case NodeTreeNodeType.Clause:
            case NodeTreeNodeType.Clause:
            case NodeTreeNodeType.Template:
                return true;
        }
    }

    private finishEdit(event: any) {
        event.stopPropagation();
        if (this.nodeForm.controls.name.value === this.nodeName || this.nodeForm.controls.name.value.trim() === '') {
            this.editable = false;
            return;
        }

        if (this.isTreeLocked) {
            this.editable = false;
            return;
        }

        if (!this.nodeForm.controls.name.valid) {
            return;
        }

        this.node.value = this.nodeForm.controls.name.value;

        if (this.node.valueHighlighted) {
            this.node.valueHighlighted = this.node.value;
        }

        const additionalParam = null;
        const nodeAction = this.treeService.createNodeTreeActionWithCallbacks(this.node, NodeTreeActionType.Rename, additionalParam,
            this.afterRenameSuccessful, this.afterRenameFail);
        this.onNodeAction.emit(nodeAction);
    }

    private initFormModel() {
        this.nodeForm = new UntypedFormGroup(
            {
                name: new UntypedFormControl(null, [])
            }
        );
    }

    private afterRenameSuccessful = () => {
        this.editable = false;
    }

    private afterRenameFail = () => {
        this.editModeOn();
    }

    private startToEdit() {
        this.nodeName = `${this.node.value}`;
        this.nodeForm.controls.name.setValue(this.nodeName);
        this.editable = true;
        const nodeAction = this.treeService.createNodeTreeAction(this.node, NodeTreeActionType.RenameStart);
        this.onNodeAction.emit(nodeAction);
        setTimeout(() => this.editModeOn()); // Necesario para hacer focus después de que aparezca el input
    }

    private editModeOn() {
        this.nodeNameInput.focusInput();
        this.nodeForm.controls.name.markAsTouched();
        this.keyEnterPressed = false;
    }

    private setAction() {
        this.forceShowCheck = this.actions?.includes(NodeTreeUserAction.Check) &&
            this.node?.permissions.some(a => a === NodeTreeActionType.Checked);
    }
}
