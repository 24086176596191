import { ValidatorRegexConstant } from './validator-regex-constant';
import { isValueEmptyValidator } from 'src/app/core/shared/validators/valueEmptyValidator';

const NIF_LETTERS = 'TRWAGMYFPDXBNJZSQVHLCKE';
const FIRST_CHAR = 0;
const POSITION_CONTROL_LETTER = 8;

export function NIFValidator(nif: string): boolean {
    return !isValueEmptyValidator(nif) &&
    ValidatorRegexConstant.CUSTOM_NIF_REGEX.test(nif) &&
    validateNIF(nif);
}

function control(numbers: string, letter: string, lettersControl): boolean {
    const dniNumber = parseInt(numbers);
    const remainder = dniNumber % lettersControl.length;
    const expectedLetter = lettersControl[remainder];

    return letter.toUpperCase() === expectedLetter || !isNaN(parseInt(letter));
}

function validateNIF(nifValue: string): boolean {
    let nifNumbers;
    if (NIF_LETTERS.includes(nifValue.charAt(FIRST_CHAR))) {
        nifNumbers = nifValue.slice(FIRST_CHAR + 1, POSITION_CONTROL_LETTER);
    } else {
        nifNumbers = nifValue.slice(FIRST_CHAR, POSITION_CONTROL_LETTER);
    }
    const nifControlLetter = nifValue.charAt(POSITION_CONTROL_LETTER);

    return control(nifNumbers, nifControlLetter, NIF_LETTERS);
}
