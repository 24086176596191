import { Editor, Node, Element, Conversion, ModelConsumable } from 'ckeditor5';
import { Injectable } from "@angular/core";
import { InputPlugin } from '../../plugins/input/input-plugin';
import { GlobalConstant } from '../../models/base/global-constant';
import { PluginUtilsService } from '../../../utils/plugin-utils.service';

@Injectable({
    providedIn: "root",
})
export class InputModelToDataViewConverterService {

    private utilsService: PluginUtilsService;
    private attributesMapping = {
        isValid: {
            model: InputPlugin.MODEL_ENTITIES.isValid.model,
            dataView: InputPlugin.MODEL_ENTITIES.isValid.dataView,
        },
        embeddedIn: {
            model: GlobalConstant.ATTRIBUTE_EMBEDDED_IN,
            dataView: GlobalConstant.ATTRIBUTE_EMBEDDED_IN,
        }
    };

    constructor() {
        this.utilsService = new PluginUtilsService();
    }

    public configureConverter(editor: Editor): void {
        const conversion = editor.conversion;

        this.inputConversion(editor);
        this.attributeConversion(conversion);
    }

    private attributeConversion(conversion: Conversion): void {
        for (const key in this.attributesMapping) {
            if (this.attributesMapping.hasOwnProperty(key)) {
                const { model, dataView } = this.attributesMapping[key];
                conversion.for('dataDowncast').attributeToAttribute({
                    model: model,
                    view: dataView,
                });
            }
        }
    }

    private inputConversion(editor: Editor): Conversion {
        const conversion = editor.conversion;
        conversion.for('dataDowncast').elementToElement({
            model: InputPlugin.MODEL_ENTITIES.container.model,
            view: (modelElement: Element, { writer, consumable }) => {

                const child = this.getContentElement(editor, modelElement, consumable);
                const valueInInput = this.utilsService.getTextContent(child);

                const isValid = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.isValid.model) === true;
                const id = modelElement.getAttribute(GlobalConstant.ATTRIBUTE_ID);
                const alias = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.alias.model);
                const helpNote = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.help.model);
                const pattern = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.pattern.model);
                const transform = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.transform.model);
                const isLinked = modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.isLinked.model) === true;
                const embeddedIn = modelElement.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN);

                return writer.createContainerElement('input', {
                    'type': modelElement.getAttribute(InputPlugin.MODEL_ENTITIES.type.model),
                    value: valueInInput,
                    'data-is-valid': isValid,
                    'id': id,
                    'name': id,
                    'class': InputPlugin.MODEL_ENTITIES.container.dataView,
                    'data-alias': alias,
                    'data-input-help': helpNote,
                    'data-pattern': pattern,
                    'data-transform': transform,
                    'data-is-linked': isLinked,
                    'data-embedded-in': embeddedIn
                });
            }
        });
        return conversion;
    }

    private getContentElement(editor: Editor, modelElement: Element, consumable: ModelConsumable): Node | null {
        let nameModelElement: Node | null = null;

        for (const { item } of editor.model.createRangeIn(modelElement)) {
            if (item.is('element', InputPlugin.MODEL_ENTITIES.content.model)) {
                nameModelElement = modelElement.getChild(0)!;
            }
            consumable.consume(item, 'insert');
        }

        const child = !!nameModelElement && (nameModelElement as Element).getChild(0) ? (nameModelElement as Element).getChild(0) : null;
        return child;
    }
}
