import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DocumentStatusService, MasterData } from 'src/app/api';
import { IDocumentStateService } from './document-state.service.interface';

@Injectable({
  providedIn: 'root'
})
export class DocumentStateService extends IDocumentStateService {

    private documentStatus = new BehaviorSubject<MasterData[]>([]);
    private isLoadingStates = false;

    constructor(private readonly documentStatusService: DocumentStatusService) {
        super();
    }

    public getCurrentStates(): Observable<MasterData[]> {
        if (!this.isLoadingStates && this.documentStatus.getValue().length < 1) {
            this.getStatesFromApi();
        }

        return this.documentStatus.asObservable();
    }

    private getStatesFromApi() {
        this.isLoadingStates = true;
        this.documentStatusService.masterdataManagementDocumentStatusGet().subscribe((statuses: MasterData[]) => {
            this.documentStatus.next(statuses.sort((a, b) => {

            return  a.code - b.code;
            }));
            this.isLoadingStates = false;
        }, error => {
            this.isLoadingStates = false;
        });
    }
}
