import { Component } from '@angular/core';
import { AuthorizeService } from 'src/app/core/shared/services/authorize/authorize.service';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { IFileStateService } from 'src/app/core/shared/services/templates/file-state/file-state.interface';
import { FileStateComponentDirective } from '../../file-state.component';
import { FileStatusCodes } from 'src/app/core/cmc/enums/file-status.enum';

@Component({
    selector: 'app-clause-file-state',
    templateUrl: './clause-file-state.component.html'
})
export class ClauseFileStateComponent extends FileStateComponentDirective {

    public titleForDocument = $localize`:@@Clausula:Cláusula`;

    constructor(
        protected genericDialogService: GenericDialogService,
        protected fileStateService: IFileStateService,
        protected readonly loginService: AuthorizeService
    ) {
        super(genericDialogService, fileStateService, loginService);
    }

    protected getTextButton(fileState: FileStatusCodes) {
        return this.fileStateService.getClauseStatusMessage(fileState);
    }
}
