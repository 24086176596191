import { Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { NodeTreeAction } from 'src/app/shared/components/ctbox-tree/models/node-tree-action.model';
import { NodeTreeNodeType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-node-type.enum';
import { FileFlatNode } from 'src/app/shared/components/ctbox-tree/models/file-flat-node.model';
import { NodeTreeActionType } from 'src/app/shared/components/ctbox-tree/enums/node-tree-action-type.enum';
import { takeUntil } from 'rxjs/operators';
import { ClauseFolderDto, ClauseFolderSearchService } from 'src/app/api';
import { AuthorizeService } from 'src/app/core/shared/services/authorize/authorize.service';
import { ClausesChangesService } from 'src/app/core/shared/services/clauses/clauses-changes.service';
import { IClausesFolderTreeService } from 'src/app/core/shared/services/clauses/clauses-folder-tree/clauses-folder-tree.service.interface';
import { IClausesFolderHierarchyTreeActionsService } from 'src/app/core/shared/services/clauses/clauses-folder-tree/clauses-folder-with-content/clauses-folder-with-content.actions.service.interface';
import { IClausesFolderService } from 'src/app/core/shared/services/clauses/clauses-folder/clauses-folder.service.interface';
import { GenericDialogService } from 'src/app/core/shared/services/generic-dialog/generic-dialog.service';
import { ClauseFolderStorage } from 'src/app/core/shared/services/clauses/clause-folder-storage/clause-folder-storage';
import { NodeTreeUserAction } from 'src/app/shared/components/ctbox-tree/enums/node-tree-user-action.enum';
import { BaseClauseFolderDirective } from '../base-components/base-clause-folder';
import { ClauseFolderService } from 'src/app/api/api/standard/clause-folder.service';

@Component({
    selector: 'app-clause-tree-modal',
    templateUrl: './clause-tree-modal.component.html'
})
export class ClauseTreeModalComponent extends BaseClauseFolderDirective implements OnInit, OnDestroy {

    @Input() showClauses: boolean;
    @Input() selectedNodeId: string;

    @Output() checkedNodeTree = new EventEmitter<FileFlatNode>();
    @Output() selectedNodeTree = new EventEmitter<FileFlatNode>();

    public actions: NodeTreeUserAction[] = [NodeTreeUserAction.Menu, NodeTreeUserAction.Check];

    public constructor(
        loginService: AuthorizeService,
        genericDialogService: GenericDialogService,
        @Inject('IClausesFolderTreeService') protected clausesFolderTreeService: IClausesFolderTreeService,
        @Inject('IClausesFolderHierarchyTreeActionsService')
        protected clauseFolderHierarchyTreeActionsService: IClausesFolderHierarchyTreeActionsService,
        protected clauseFolderService: IClausesFolderService,
        protected clauseFolderSearchService: ClauseFolderSearchService,
        protected readonly apiClauseFolders: ClauseFolderService,
        protected readonly clausesFolderStorageService: ClauseFolderStorage,
        protected readonly clausesChangesService: ClausesChangesService,
    ) {
        super(
            loginService,
            clausesFolderTreeService,
            clauseFolderHierarchyTreeActionsService,
            clauseFolderService,
            genericDialogService,
            clauseFolderSearchService,
            apiClauseFolders,
            clausesChangesService
        );
    }

    public ngOnInit(): void {
        super.ngOnInit();

        this.clausesChangesService.getClauseFolderNeedToBeReloadSubscription()
            .pipe(takeUntil(this.onDestroy$)).subscribe(() => {
                this.refresh();
            });

        this.loadTree();
    }

    public ngOnDestroy(): void {
        super.ngOnDestroy();
    }

    public doActionFromNodeEvent(nodeEvent: NodeTreeAction) {
        switch (nodeEvent.typeEvent) {
            case NodeTreeActionType.Selected:
                this.selectNode(nodeEvent);
                break;
            case NodeTreeActionType.Checked:
                this.checked(nodeEvent);
                break;
        }
    }

    public performSearch(searchTerm: string): void {
        const loadContent = true;
        this.searchInTree(searchTerm, loadContent);
    }

    protected refreshSearch(searchTerm: string): void {
        const loadContent = true;
        this.refreshSearchInTree(searchTerm, loadContent);
    }

    protected override checked(nodeAction: NodeTreeAction): void {
        this.checkedNodeTree.emit(nodeAction.node);
    }

    protected override loadFolderTree(clausesFolders: ClauseFolderDto[]): void {
        this.clauseFolders = clausesFolders;
        this.clauseFolderHierarchyTree = this.clauseFolderHierarchyTreeActionsService.create(clausesFolders,
            [NodeTreeActionType.MoveTo], this.foldersNodeActions, this.clausesNodeActions);

        this.initialNodes.push(this.clausesFolderTreeService.getRootNodeId());

        this.clausesFolderStorageService.setFolders(clausesFolders);
        this.clauseFolderHierarchyTreeActionsService.setCurrentClausesFolderHiearchyTree(clausesFolders);
    }

    protected loadTree(): void {
        this.apiClauseFolders.getAll(this.showClauses).subscribe((clausesFolders: ClauseFolderDto[]) => {
            this.loadFolderTree(clausesFolders);
            this.clauseFolders = clausesFolders;
        });
    }

    private selectNode(nodeAction: NodeTreeAction): void {

        if (nodeAction.node.type !== NodeTreeNodeType.Clause) {
            return;
        }

        this.selectedNodeTree.emit(nodeAction.node);
    }
}
