import { Injectable } from "@angular/core";
import { Schema } from "ckeditor5";
import { SelectPlugin } from "../../plugins/select/select-plugin";
import { ALLOW_CONTENT_OF_VALUES, INHERIT_ALL_FROM_VALUES } from "../schema-values";
import { GlobalConstant } from "../../models/base/global-constant";

@Injectable({
    providedIn: 'root'
})
export class SelectSchemaService {

    public defineSchema(schema: Schema): void {
        this.defineContainer(schema);
        this.defineContent(schema);
        this.defineOption(schema);
    }

    private defineContainer(schema: Schema): void {
        schema.register(SelectPlugin.MODEL_ENTITIES.container.model, {
            inheritAllFrom: INHERIT_ALL_FROM_VALUES.INLINE_OBJECT,
            allowAttributes: [
                SelectPlugin.ATTRIBUTE_ID,
                SelectPlugin.ATTRIBUTE_VALUE,
                GlobalConstant.ATTRIBUTE_EMBEDDED_IN,
            ]
        });
    }

    private defineContent(schema: Schema): void {
        schema.register(SelectPlugin.MODEL_ENTITIES.content.model, {
            allowIn: SelectPlugin.MODEL_ENTITIES.container.model,
            allowChildren: ALLOW_CONTENT_OF_VALUES.ROOT,
            isLimit: false,
            isSelectable: true,
            allowAttributes: [
                SelectPlugin.ATTRIBUTE_ID,
                SelectPlugin.ATTRIBUTE_VALUE,
            ]
        });
    }

    private defineOption(schema: Schema): void {
        schema.register(SelectPlugin.MODEL_ENTITIES.option.model, {
            allowIn: SelectPlugin.MODEL_ENTITIES.content.model,
            allowChildren: ALLOW_CONTENT_OF_VALUES.TEXT,
            isLimit: true,
            allowAttributes: [
                SelectPlugin.ATTRIBUTE_VALUE,
                SelectPlugin.ATTRIBUTE_SELECTED
            ]
        });
    }
}
