import { Component, Input, OnInit, ElementRef, ViewChild, ViewEncapsulation, OnDestroy, EventEmitter } from '@angular/core';
import { UserInfoDTO } from 'src/app/api';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthorizeService } from 'src/app/core/shared/services/authorize/authorize.service';
import { Router } from '@angular/router';
import { RouteStateService } from 'src/app/core/shared/services/route-state/route-state.service';
import { PlatformLocation } from '@angular/common';
import { TypeUserService } from 'src/app/core/shared/services/type-user/type-user.service';
import { CustomizeConfiguration } from 'src/config/customisation';
import { takeUntil } from 'rxjs/operators';

declare let MyAccountLoginForm: any;

@Component({
    selector: 'app-login-status',
    templateUrl: './login-status.component.html',
    styleUrls: ['./login-status.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginStatusComponent implements OnInit, OnDestroy {

    @Input() showAccessButton: boolean;

    public canManageLicenses = false;

    pathParam: Observable<string>;
    openPopup = false;
    callBackUrl: string = null;
    predefinedEmail: string = null;
    @ViewChild('modalLoginForm') modalLoginForm: ElementRef;

    public loginStatusUserInfo = new BehaviorSubject<UserInfoDTO>(null);
    public userInfoRefreshedOnce: BehaviorSubject<boolean>;

    public isAdmin = false;

    private onDestroy$ = new EventEmitter<void>();

    constructor(
        private loginService: AuthorizeService,
        private router: Router,
        private routeStateService: RouteStateService,
        private platformLocation: PlatformLocation,
        private readonly customisation: CustomizeConfiguration,
        public typeUserService: TypeUserService) {

        this.userInfoRefreshedOnce = this.loginService.getUserInfoRefreshedOnce();
    }

    public ngOnInit(): void {
        this.routeStateService.pathParamOpenPopup
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(value => {
                this.openPopup = (value === 'true');
            });

        this.routeStateService.pathParamReturnUrl
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(value => {
                this.callBackUrl = value;
            });

        this.routeStateService.pathParamEmail
            .pipe(takeUntil(this.onDestroy$))
            .subscribe(value => {
                this.predefinedEmail = value;
            });

        this.subscribeToUserChanges();
        this.loginService.refreshUserInfo();

        if (this.openPopup && this.predefinedEmail) {
            this.openLoginFormWithRegister(this.callBackUrl, this.predefinedEmail);
        }

        this.canManageLicenses = this.customisation.customizeIdName === 'LALEY';
    }

    public ngOnDestroy(): void {
        this.onDestroy$.emit();
    }

    public hasUserInfoValue(): boolean {
        const userInfo: UserInfoDTO = this.loginStatusUserInfo.getValue();
        return !!userInfo;
    }

    public subscribeToUserChanges(): void {
        this.loginStatusUserInfo
            .pipe(takeUntil(this.onDestroy$))
            .subscribe((value: UserInfoDTO) => {
                if (!this.userInfoRefreshedOnce.getValue()) {
                    return;
                }
                const urlToken = (this.platformLocation as any)?.location.pathname.split('/');
                const isCurrentUrlPublicLanding = urlToken.length > 2 &&
                    urlToken[2] === 'public';

                if (value && !value.hasLicense && !isCurrentUrlPublicLanding) {
                    this.router.navigate(['/home/no-license']);
                }
            });

        this.loginService.getUserInfo().pipe(takeUntil(this.onDestroy$)).subscribe((user: UserInfoDTO) => {
            this.loginStatusUserInfo.next(user);
            this.isAdmin = user?.isAdmin;
        });
    }

    public openLoginFormWithRegister(returnUrl: string = null, predefinedEmail: string = null): void {
        const myAccount = new MyAccountLoginForm({
            url: environment.myAccountExternalLoginUrlWithRegister,
            loginmodel: true,
            data: {
                withoutRegisterMode: false,
                forceOpenLoginPopup: this.loginStatusUserInfo.getValue() == null,
                PredefinedEmail: predefinedEmail !== null ? predefinedEmail : null
            },
            onSuccess: () => {
                this.loginService.refreshUserInfo();
                if (returnUrl !== null) {
                    window.location.href = returnUrl;
                }
            }
        });
        myAccount.display();
        myAccount.show();
    }

    public openLoginForm(forceOpenLoginPopup = false): void {
        const myAccount = new MyAccountLoginForm({
            url: environment.myAccountExternalLoginUrl,
            loginmodel: true,
            data: {
                withoutRegisterMode: true,
                forceOpenLoginPopup: forceOpenLoginPopup || (this.loginStatusUserInfo.getValue() == null)
            },
            onSuccess: () => {
                this.loginService.refreshUserInfo();
            }
        });
        myAccount.display();
        myAccount.show();
    }

    public openMyAccount(): void {
        window.open(environment.myAccountExternalLink);
    }

    public openMyLicenses(): void {
        window.open(environment.myAccountOpenMyLicenses);
    }

    public closeSession(): void {
        const returnUrl = location.protocol + '//' + location.hostname + (location.port ? ':' + location.port : '') + '/es';
        document.location.href = environment.myAccountLogoutUrl + '?returnUrl=' + returnUrl;
    }
}
