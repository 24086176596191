<button mat-button
    class="{{ setMatTypeButton }} {{ customClass }}"
    [ngClass]="{'mat-icon-button-left': (!buttonIconPositionRight && isButtonIcon && buttonText), 'mat-icon-button-right': (buttonText && buttonIconPositionRight && isButtonIcon) }"
    [color]="buttonColor"
    [disabled]="isDisabled"
    disableRipple="true"
    (click)="clickButton($event)">
    <ng-container *ngIf="isButtonIcon && !buttonIconPositionRight">
        <mat-icon *ngIf="buttonIconSvg" svgIcon="{{ buttonIconSvg }}"></mat-icon>
        <mat-icon *ngIf="buttonIcon" class="{{ customClass }}">{{ buttonIcon }}</mat-icon>
    </ng-container>
    <span [ngClass]="getButtonClasses()">
        {{ buttonText }}
    </span>
    <ng-container *ngIf="isButtonIcon && buttonIconPositionRight">
        <mat-icon *ngIf="buttonIconSvg" svgIcon="{{ buttonIconSvg }}"></mat-icon>
        <mat-icon *ngIf="buttonIcon" class="{{ customClass }}">{{ buttonIcon }}</mat-icon>
    </ng-container>
</button>
