import { Directive, Inject } from "@angular/core";
import { ButtonView, Locale } from "ckeditor5";
import BaseView from "../base-view.directive";
import { UI_CLASSES } from "../styles/styles-constants";
import { SelectPlugin } from "../../plugins/select/select-plugin";
import { SelectModel } from "../../models/select/select-model";

@Directive({
  selector: "app-select-balloon-view",
})
export default class SelectBalloonView extends BaseView {

    public editButtonView: ButtonView;
    public deleteButtonView: ButtonView;

    public selectModel: SelectModel =  {
        id: ''
    };

    constructor(@Inject(Locale) locale?: Locale) {
        super(locale);

        this.editButtonView = this.createButton(SelectPlugin.EDIT_COMMAND_NAME, null, UI_CLASSES.SVG_ICONS.EDIT);
        this.deleteButtonView = this.createButton(SelectPlugin.DELETE_OPTION, null, UI_CLASSES.SVG_ICONS.DELETE);

        this.items = this.createCollection([ this.editButtonView, this.deleteButtonView]);

        this.setTemplateForm("ck-select-balloon");
    }

    public get id(): string {
        return this.selectModel.id;
    }

    public set id(value: string) {
        this.selectModel.id = value;
    }

    public resetFormStatus(): void {
        this.selectModel = {
            id: ''
        }
	}
}
