import { Injectable } from "@angular/core";
import { Configuration } from '../configuration';
import { HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { SearchResultDto } from "../model/searchResultDto";
import { ApiOperation, ApiProcessorService, ApiRequest } from "./api-processor.service";

@Injectable()
export class ClauseSearchService {
    basePath: string = '/api/manager-management/clauses/search';
    configuration = new Configuration();
    defaultHeaders = new HttpHeaders();

    constructor(private readonly apiProcessorService: ApiProcessorService) {
    }

    public search(searchTerm: string): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.GET;
        apiRequest.endpointUrl = this.basePath + `?searchTerm=${searchTerm}`;

        return this.apiProcessorService.proccessRequest<SearchResultDto[]>(apiRequest);

    }

}
