<ng-template #dialogcontent>
    <header class="dialog-header">
        <ng-container>
            <div mat-dialog-title class="mat-dialog-title dialog-title">
                <div class="{{dialogTitleClass}}" *ngIf="dialogTitle">{{ dialogTitle }}</div>
            </div>
        </ng-container>
        <div *ngIf="displayCloseOption" class="dialog-close">
            <ctbox-button buttonIcon="close" buttonColor="secondary" customClass="medium" (buttonClick)="clickCloseOption()" [mat-dialog-close]="true"></ctbox-button>
        </div>
    </header>
    <mat-dialog-content class="mat-dialog-content" [ngClass]="{'no-actions': !displayButtonBar, 'single-button': !dialogButton || !dialogCloseButon}" cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
        <ng-content select="body"></ng-content>
    </mat-dialog-content>
    <mat-dialog-actions *ngIf="displayButtonBar" class="mat-dialog-actions" [ngClass]="{'single-button': !dialogButton || !dialogCloseButon}">
        <ctbox-button buttonType="primary" buttonText="{{dialogButton}}" (buttonClick)="acceptClick()" *ngIf="dialogButton"></ctbox-button>
        <ctbox-button buttonType="secondary" buttonText="{{dialogCloseButon}}" (buttonClick)="cancelClick()" *ngIf="dialogCloseButon"></ctbox-button>
    </mat-dialog-actions>
</ng-template>