import { Directive, OnDestroy } from "@angular/core";
import { ClickObserver, ContextualBalloon, Dialog, Editor,  ViewDocumentClickEvent,  ViewDocumentMouseDownEvent, Widget, Writer, Element as CkElement, EventInfo, ViewElement, DomEventData } from "ckeditor5";
import { PluginUtilsService } from "../../../utils/plugin-utils.service";
import { UI_CLASSES } from "../../ui/styles/styles-constants";
import { BasePlugin } from "../base/base-plugin";
import { UserInterfaceService } from "../../ui/user-interface.service";
import { SchemaModel } from "../../models/schema-model";
import { ToolbarButtonModel } from "../../models/base/toolbar-button-model";
import SelectBalloonView from "../../ui/select/select-balloon-view.directive";
import { SelectDataViewToModelConverterService } from "../../converters/select/select-data-view-to-model-converter.service";
import { SelectModelToDataViewConverterService } from "../../converters/select/select-model-to-data-view-converter.service";
import { SelectModelToEditorViewConverterService } from "../../converters/select/select-model-to-editor-view-converter.service";
import { SelectSchemaService } from "../../schema/select/select-schema.service";
import AddSelectCommand from "../../commands/select/add-select-command";
import DeleteSelectCommand from "../../commands/select/delete-select-command";
import EditSelectCommand from "../../commands/select/edit-select-command";
import SelectDialogView, { SelectDialogOptionArrayFormViewCallback, SelectDialogOptionTextFormViewCallback } from "../../ui/select/select-dialog-view.directive";
import { SelectModel } from "../../models/select/select-model";
import DoubleClickObserver, { ViewDocumentDoubleClickEvent } from "../../../utils/double-click-observer";
import { GlobalConstant } from "../../models/base/global-constant";
import { ContextEditorTypes } from "../../models/base/context-editor-types";

@Directive({
    selector: "select-plugin",
})
export class SelectPlugin extends BasePlugin implements OnDestroy {

    public static readonly PLUGIN_NAME = "select-pg";
    public static readonly PLUGIN_MODEL = "select-pg";
    public static readonly PLUGIN_TOOLBAR_BUTTON_NAME = "text-select-pg";

    public static readonly MODEL_ENTITIES:  {[name:string]: SchemaModel} = {
        'container'     :  { model: 'select-pg',                dataView:'select',                  editionView: 'select-plugin'      },
        'content'       :  { model: 'select-content',           dataView:'',                        editionView: 'select-content'},
        'selectValue'   :  { model: 'value',                    dataView:'value',                   editionView: 'value'},
        'option'        :  { model: 'select-option',            dataView:'option',                  editionView: 'option'   },
        'value'         :  { model: 'value',                    dataView:'value',                    editionView: 'value'   },
    }

    public static readonly ID_INPUT_PREFFIX = 'ck-select-';
    public static readonly ATTRIBUTE_ID = "id";
    public static readonly ATTRIBUTE_VALUE = "value";
    public static readonly ATTRIBUTE_SELECTED_INDEX = "selectedIndex";
    public static readonly ATTRIBUTE_SELECTED = "selected";
    public static readonly PRE_PREFIX_ID_SELECT = "sel-";

    public static readonly  VISUAL_SELECTION_MARKER_NAME = 'select-pg';

    public static readonly DELETE_COMMAND_NAME = "delete-select-pg";
    public static readonly EDIT_COMMAND_NAME = "edit-select-pg";
    public static readonly ADD_COMMAND_NAME = "add-select-pg";

    public static readonly DELETE_OPTION = $localize`:@@BorrarSelectorEmbebida:Borrar`;

    public static readonly SELECT_POSITION = 0;

    private readonly MAX_OPTION_LENGTH = 100;

    private modelToEditorViewConverter: SelectModelToEditorViewConverterService;
    private modelToDataViewConverter: SelectModelToDataViewConverterService;
    private dataViewToModelConverter: SelectDataViewToModelConverterService;
    private schemaService: SelectSchemaService;
    private userInterfaceService: UserInterfaceService;
    private selectDialogView: SelectDialogView;

    private dialogTitle = $localize`:@@PluginSelectorTituloDialogo:Insertar desplegable`;
    private selectTooltip = $localize`:@@PluginSelectorBotoneraBotonPrincipalTooltip:Insertar un desplegable`;
    private minOptionsMessage = $localize`:@@PluginSelectorAlMenosUnaOpcion:Añade al menos una opción`;
    private maxLengthOptionMessage = $localize`:@@PluginSelectorNoValidoMas100caracteres:El número máximo de caracteres para este campo es 100`;
    private emptyOptionMessage = $localize`:@@PluginSelectorNoValidoVacio:Introduce un texto para la opción`;
    private optionRepeatedMessage = $localize`:@@PluginSelectorNoValidoOpcionRepetida:Ya existe esa opción`;

    protected toolbarButton: ToolbarButtonModel = {
        icon: UI_CLASSES.SVG_ICONS.SELECT,
        pluginToolbarElementName: SelectPlugin.pluginToolbarElementName,
        tooltip: this.selectTooltip,
        hasTooltip: true,
        hasText: true
    };

    private balloonView: SelectBalloonView;

    protected mappers = [
        SelectPlugin.MODEL_ENTITIES.container.editionView,
        SelectPlugin.MODEL_ENTITIES.content.editionView,
        SelectPlugin.MODEL_ENTITIES.option.editionView
    ];

    protected commands = {
        [SelectPlugin.ADD_COMMAND_NAME]: AddSelectCommand,
        [SelectPlugin.DELETE_COMMAND_NAME]: DeleteSelectCommand,
        [SelectPlugin.EDIT_COMMAND_NAME]: EditSelectCommand
    };

    private stopRender = false;

    constructor(editor: Editor) {
        super(editor);
        this.pluginUtils = new PluginUtilsService();

        this.modelToEditorViewConverter = new SelectModelToEditorViewConverterService();
        this.modelToDataViewConverter = new SelectModelToDataViewConverterService();
        this.dataViewToModelConverter = new SelectDataViewToModelConverterService();

        this.schemaService = new SelectSchemaService();
        this.userInterfaceService = new UserInterfaceService();
    }

    public static get requires() {
        return [Widget, ContextualBalloon, Dialog];
    }

    public static get pluginName(): string {
        return SelectPlugin.PLUGIN_NAME;
    }

    public static get pluginModelName(): string {
        return SelectPlugin.PLUGIN_MODEL;
    }

    public static get pluginToolbarElementName(): string {
        return SelectPlugin.PLUGIN_TOOLBAR_BUTTON_NAME;
    }

    public static get mainToolbarButtonName() {
        return SelectPlugin.PLUGIN_MODEL;
    }

    public static get inputTextToolbarButtonName() {
        return SelectPlugin.PLUGIN_TOOLBAR_BUTTON_NAME;
    }

    public init(): void {
        super.init();
    }

    protected defineSchema(): void {
       this.schemaService.defineSchema(this.schema);
    }

    protected defineConverters(): void {
        this.dataViewToModelConverter.configureConverter(this.editor);
        this.modelToDataViewConverter.configureConverter(this.editor);
        this.modelToEditorViewConverter.configureConverter(this.editor);
    }

    protected editorInteractions(): void {
        super.setupEditorObserver(ClickObserver);
        this.defineObservers();
        this.balloon = this.editor.plugins.get(ContextualBalloon);
        this.enableBalloonActivators();
    }

    protected toolbarExecuteOperation(): void {
        const dialog = this.editor.plugins.get("Dialog");

        if (this.button.isOn) {
            dialog.hide();
            return;
        }
        const context = this;

        this.button.isOn = true;
        const defaultModel: SelectModel = {
            options: []
        };

       this.addSelectDialog(defaultModel);

        dialog.show({
            isModal: true,
            content: this.selectDialogView,
            title: this.dialogTitle,
            onHide() {
                context.button.isOn = false;
            },
            id: "",
        });
    }

    protected enableBalloonActivators(): void {
        const view = this.editor.editing.view;
        const viewDocument = this.editor.editing.view.document;
        this.listenTo<ViewDocumentClickEvent>(viewDocument, 'click', this.handleClickEvent.bind(this));
        this.listenTo<ViewDocumentDoubleClickEvent>(viewDocument, 'dblclick', this.handleDobleClickEvent.bind(this));
        this.listenTo<ViewDocumentMouseDownEvent>(viewDocument, 'mousedown', this.handleMouseDownEvent.bind(this), { priority: "highest" });
        this.listenTo(view, 'render', this.handleRenderEvent.bind(this), {priority: "highest"});
        this.listenTo(viewDocument, 'blur', this.handleBlurEvent.bind(this), {priority: "highest"});
    }

    private actionsForm(editor: Editor, selectDialogView: SelectDialogView, dialog: Dialog): void {

        this.listenTo(selectDialogView, "submit", () => {
            if (!selectDialogView.areAllFieldsValid()) {
                return;
            }

            const selectModel = selectDialogView.selectModel;
            const commandName = selectDialogView.isInCreation() ? SelectPlugin.ADD_COMMAND_NAME : SelectPlugin.EDIT_COMMAND_NAME;
            editor.execute(commandName, selectModel);

            this.userInterfaceService.hideUI(this.editor, this.balloon, this.selectDialogView, SelectPlugin.VISUAL_SELECTION_MARKER_NAME, this);
            dialog.hide();
        });

        this.listenTo(selectDialogView, "cancel", () => {
            this.userInterfaceService.hideUI(this.editor, this.balloon, this.selectDialogView, SelectPlugin.VISUAL_SELECTION_MARKER_NAME, this);
            dialog.hide();
        });
    }

    private areActionsVisible(): boolean {
        return this.userInterfaceService.areActionsVisible(this.balloon, this.selectDialogView)
            || this.userInterfaceService.areActionsVisible(this.balloon, this.balloonView);
    }

    private defineObservers() {
        this.editor.editing.view.addObserver(ClickObserver);
        this.editor.editing.view.addObserver(DoubleClickObserver);
    }

    private createSelectDialog(model: SelectModel): void {
        const editor = this.editor;
        const optionValidator = this.getOptionTextValidators();
        const optionsArrayValidator = this.getOptionArrayValidators();
        const formView = new SelectDialogView(optionValidator, optionsArrayValidator, editor.locale, model);

        const dialog = this.editor.plugins.get("Dialog");
        this.actionsForm(editor, formView, dialog);

        this.selectDialogView = formView;
        this.userInterfaceService.enableUserBalloonInteractions(this.editor, this.balloon, formView, SelectPlugin.VISUAL_SELECTION_MARKER_NAME, this);
    }

    private handleRenderEvent(evt, _data): void {
        if (!this.stopRender) {
            return;
        }

        evt.stop();
        evt.return = true;
    }

    private handleMouseDownEvent(_event: Event, data: DomEventData): void {
        if (!data?.domTarget?.classList?.contains(SelectPlugin.MODEL_ENTITIES.content.editionView)) {
            this.stopRender = false;
            return;
        }

       this.stopRender = true;
    }

    private handleBlurEvent(_event: Event, data: DomEventData): void {
        if (!data?.domTarget?.classList?.contains(SelectPlugin.MODEL_ENTITIES.content.editionView)) {
            return;
        }

        const select = data?.domTarget as HTMLSelectElement;
        if (!!select) {
            this.updateSelectedIndex(select);
        }

        this.stopRender = false;
    }

    private updateSelectedIndex(select: HTMLSelectElement): void {
        const id = select?.getAttribute(SelectPlugin.ATTRIBUTE_ID).substring(SelectPlugin.PRE_PREFIX_ID_SELECT.length);
        const selectedIndex = this.getIndex(select);
        this.editor.editing.model.change((writer: Writer) => {
            const element = this.pluginUtils.getWalkerByWithId(this.editor, writer, id)?.item as CkElement;
            const elementSelect = element.getChild(SelectPlugin.SELECT_POSITION) as CkElement;
            const optionSelected = elementSelect.getChild(selectedIndex);
            Array.from(elementSelect.getChildren()).forEach( (option: CkElement) => {
                writer.setAttribute(SelectPlugin.ATTRIBUTE_SELECTED, false, option);
            });
            writer.setAttribute(SelectPlugin.ATTRIBUTE_SELECTED, true, optionSelected);
        });
    }

    private getIndex(select: HTMLSelectElement): number {
        let index = 0;
        select.childNodes.forEach( (option: HTMLOptionElement) => {
            if (!!option.getAttribute(SelectPlugin.ATTRIBUTE_SELECTED)) {
                index = option.index;
            }
        });
        return index;
    }

    private handleDobleClickEvent(event: EventInfo, data: DomEventData): void {
        const selectModel = this.getModelFromDataEvent(data);

        if (!selectModel) {
            return;
        }

        if (!this.pluginUtils.isElementInteractableInTargetEditor(selectModel.embeddedIn, SelectPlugin.contextEditor, ContextEditorTypes.CLAUSE)) {
            return;
        }

        this.showEditDialog(selectModel);

        data.preventDefault();
        data.stopPropagation();
        event.stop();
        event.return = true;
    }

    private showUI(model?: SelectModel): void {
        if (!model?.id) {
            return;
        }

        if (!this.balloonView) {
            this.createBalloonView(model.id);
        }
        this.balloonView.selectModel = model;

        this.userInterfaceService.addBalloonLeftRight(this.editor, this.balloon, this.balloonView,
            SelectPlugin.MODEL_ENTITIES.container.editionView, SelectPlugin.VISUAL_SELECTION_MARKER_NAME);
    }

    private showEditDialog(selectModel: SelectModel, forceVisible: boolean = false): void {
        this.userInterfaceService.removeBalloonView(this.editor, this.balloon, this.balloonView, SelectPlugin.VISUAL_SELECTION_MARKER_NAME);

        this.pluginUtils.showFakeVisualSelection(this.editor, SelectPlugin.VISUAL_SELECTION_MARKER_NAME);
        this.addSelectDialog(selectModel);

        if (forceVisible) {
            this.balloon.showStack('main');
        }

        this.startUpdatingUI();
    }

    private addSelectDialog(selectModel: SelectModel): void {
        if (!this.selectDialogView) {
            this.createSelectDialog(selectModel);
        }

        if (this.userInterfaceService.isBalloonInPanel(this.balloon, this.balloonView)) {
            const isSameRadiusEditing = this.balloonView.id !== selectModel?.id;
            if (isSameRadiusEditing) {
                this.selectDialogView!.resetFormStatus();
                this.selectDialogView.selectModel = selectModel;
            }
            return;
        }

        this.selectDialogView!.resetFormStatus();
        this.selectDialogView.selectModel = selectModel;

        const dialog = this.editor.plugins.get("Dialog");

        dialog.show({
            isModal: true,
            content: this.selectDialogView,
            title: this.dialogTitle,
            onHide() {

            },
            id: "",
        });
    }

    private startUpdatingUI(): void {
        const editor = this.editor;
        const viewDocument = editor.editing.view.document;

        let prevSelectedRadio = this.pluginUtils.getSelectedContainerWithClass(editor, SelectPlugin.MODEL_ENTITIES.container.editionView);
        let prevSelectionParent = getSelectionParent();

        const update = () => {
            const selectedRadio = this.pluginUtils.getSelectedContainerWithClass(editor, SelectPlugin.MODEL_ENTITIES.container.editionView);
            const selectionParent = getSelectionParent();

            if ((prevSelectedRadio && !selectedRadio) ||
                (!prevSelectedRadio && selectionParent !== prevSelectionParent)) {
                    this.userInterfaceService.hideBalloonUI(this.editor, this.balloon, this.balloonView, SelectPlugin.VISUAL_SELECTION_MARKER_NAME, this);
            } else if (this.areActionsVisible()) {
                this.balloon.updatePosition(this.pluginUtils.getBalloonPositionData(this.editor, SelectPlugin.MODEL_ENTITIES.container.editionView, SelectPlugin.VISUAL_SELECTION_MARKER_NAME));
            }

            prevSelectedRadio = selectedRadio;
            prevSelectionParent = selectionParent;
        };

        function getSelectionParent() {
            return viewDocument.selection.focus!.getAncestors()
                .reverse()
                .find((node): node is ViewElement => node.is('element'));
        }

        this.listenTo(editor.ui, 'update', update);
        this.listenTo(this.balloon, 'change:visibleView', update);
    }

    private handleClickEvent(_event: EventInfo, data: DomEventData): void {
        const selectModel = this.getModelFromDataEvent(data);

        if (!selectModel) {
            return;
        }

        if (!this.pluginUtils.isElementInteractableInTargetEditor(selectModel.embeddedIn, SelectPlugin.contextEditor, ContextEditorTypes.CLAUSE)) {
            return;
        }

        this.showUI(selectModel);
    }

    private createBalloonView(id: string): void {
        this.balloonView = this.getBalloonView(id);
        this.userInterfaceService.enableUserBalloonInteractions(this.editor, this.balloon, this.balloonView,
            SelectPlugin.VISUAL_SELECTION_MARKER_NAME, this);
    }

    private getBalloonView(id: string): SelectBalloonView {
        const editor = this.editor;
        const balloonView = new SelectBalloonView(editor.locale);
        balloonView.id = id;
        this.configureEditButton(balloonView);
        this.configureDeleteButton(editor, balloonView);
        return balloonView;
    }

    private configureEditButton(balloonView: SelectBalloonView): void {
        balloonView.editButtonView.on('execute', () => {
            this.showEditDialog(balloonView.selectModel)
        });
    }

    private configureDeleteButton(editor: Editor, balloonView: SelectBalloonView): void {
        balloonView.deleteButtonView.on('execute', () => {
            editor.execute(SelectPlugin.DELETE_COMMAND_NAME, balloonView.id);
            this.userInterfaceService.hideBalloonUI(this.editor, this.balloon, this.balloonView, SelectPlugin.VISUAL_SELECTION_MARKER_NAME, this);
        });
    }

    private getOptionTextValidators(): Array<SelectDialogOptionTextFormViewCallback> {
        return [
            (form: any, option: string, otherOptions: string[]) => {
                if (!option) {
                    return this.emptyOptionMessage;
                }

                if (option.toLocaleString().length > this.MAX_OPTION_LENGTH) {
                    return this.maxLengthOptionMessage;
                }

                if (otherOptions.find((storedOption: string) => storedOption.toLocaleString() === option.toLocaleString())) {
                    return this.optionRepeatedMessage;
                }

                return '';
            }
        ];
    }

    private getOptionArrayValidators(): Array<SelectDialogOptionArrayFormViewCallback> {
        return [
            (form: any, options: string[]) =>  {
                const siEmpty = options.length < 1;
                if (siEmpty) {
                    return this.minOptionsMessage;
                }

                return '';
            }
        ];
    }

    private getModel(selectEditionContainer: ViewElement): SelectModel {
        const id = selectEditionContainer.getAttribute(SelectPlugin.ATTRIBUTE_ID);
        const embeddedIn = selectEditionContainer.getAttribute(GlobalConstant.ATTRIBUTE_EMBEDDED_IN) ?? '';
        const options: string[] = [];
        const selectionContainer = selectEditionContainer.getChild(SelectPlugin.SELECT_POSITION);
        const selectedIndexText = selectionContainer.is('view:element') ?
                                (selectionContainer as ViewElement).getAttribute(SelectPlugin.ATTRIBUTE_SELECTED_INDEX) : '-1';
        const selectedIndex = parseInt(selectedIndexText);

        for(let i= 0; i < (selectionContainer as ViewElement).childCount; i++ ) {
            options.push(((selectionContainer as ViewElement).getChild(i) as ViewElement).getAttribute('value'));
        }

        const selectModel: SelectModel = {
            id,
            options,
            selectedIndex,
            embeddedIn
        };

        return selectModel;
    }

    private getModelFromDataEvent(data: DomEventData): SelectModel {
        const element = data?.target;
        const selectEditionContainer = this.pluginUtils.findElementAncestorWithClass(element, SelectPlugin.MODEL_ENTITIES.container.editionView);
        if (!selectEditionContainer) {
            return null;
        }

        return this.getModel(selectEditionContainer);;
    }

}
