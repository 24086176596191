import { View, Locale, ViewCollection, LabelView } from 'ckeditor5';
import { Directive, Inject } from '@angular/core';
import { SelectMultipleViewModel } from '../../models/select/select-multiple-view.model';
import SelectOptionView from './select-option-view.directive';

@Directive({
    selector: 'app-select-multiple-view',
})
export default class SelectMultipleView extends View {

    public declare class: string | null;
    public children: ViewCollection;
    public declare _role: string | null;
    public declare _ariaLabelledBy: string | null;

    constructor(
        @Inject(Locale) locale?: Locale,
        @Inject(Object) model?: SelectMultipleViewModel
    ) {
        super(locale);
        this.initializeProperties(model);
        this.initializeAccessibility(model);
        this.initializeTemplate(model);
        this.listenTo(this, 'change', this.handleChangeEvent.bind(this));
    }

    public get options(): string[] {
        const options: string[] = [];

        this.children.forEach((child: SelectOptionView) => {
            options.push(child.value)
        });

        return options;
    }

    public clean(): void {
       this.removeAllOptions();
    }

    public updateModel(model: SelectMultipleViewModel): void {
        this.setTemplateChildrentFromModel(model);
    }

    public addOption(option: string): void {
        const child = this.createChildOption(option);
        this.children.add(child);
    }

    public removeOption(indexToRemove: number): number {
        if(!this.isIndexInOptions(indexToRemove)) {
            return indexToRemove;
        }

        this.children.remove(indexToRemove);
        let newSelectedOption = indexToRemove;
        if(!this.isIndexInOptions(indexToRemove)) {
            newSelectedOption = indexToRemove - 1;
        }

        this.markOptionSelected(newSelectedOption);
        return newSelectedOption;
    }

    public modifyOption(option: string, index: number): void {
        if(index < 0 || index >= this.children.length) {
            return;
        }
        const newChild = this.createChildOption(option);
        this.children.remove(index);
        this.children.add(newChild, index);
    }

    public moveUpOption(indexToMoveUp: number): boolean {
        const indexToFinish = indexToMoveUp - 1;
        if(indexToFinish < 0 || !this.isIndexInOptions(indexToMoveUp)) {
            return false;
        }
        const childToMove = this.children.remove(indexToMoveUp)
        this.children.add(childToMove, indexToFinish);

        return true;
    }

    public moveDownOption(indexToMoveDown: number): boolean {
        const indexToFinish = indexToMoveDown + 1;
        if(indexToFinish > this.children.length - 1 || !this.isIndexInOptions(indexToMoveDown)) {
            return false;
        }
        const childToMove = this.children.remove(indexToMoveDown)
        this.children.add(childToMove, indexToFinish);

        return true;
    }

    private initializeProperties(model?: SelectMultipleViewModel): void {
		this.set('class', model?.class || null);
    }

    private initializeAccessibility( model?: SelectMultipleViewModel, locale?: Locale): void {
        this.set('_role', null);
        this.set('_ariaLabelledBy', null);

        if(!model?.titleText) {
            return;
        }

        const labelView = new LabelView(locale);
        labelView.text = model.titleText;
            this.set({
                _role: 'group',
            });
    }

    private initializeTemplate( model?: SelectMultipleViewModel): void {
        this.children = this.createCollection();

        const bind = this.bindTemplate;
        if(!!model) {
           this.setTemplateChildrentFromModel(model);
        }

        this.setTemplate({
            tag: 'select',
            children: this.children,
            attributes: {
                class: [
                    'ck',
                    'ck-form-multiple_select',
                    bind.to('class')
                ],
                role: bind.to('_role'),
                'aria-labelledby': bind.to('_ariaLabelledBy'),
                multiple: true
            },
            on: {
                change: bind.to( 'change' )
            }
        });
    }

    private handleChangeEvent(_evt, data): void {
        const optionSelected = data.srcElement.selectedIndex;
        this.markOptionSelected(optionSelected);
    }

    private markOptionSelected(optionSelected: any): void {
        this.children.forEach((item: View<HTMLElement>) => {
            const optionView = (item as SelectOptionView);
            if (!optionView) {
                return;
            }
            optionView.isSelected = false;
        });

        if(!this.isIndexInOptions(optionSelected)) {
            return;
        }

        const optionSelectedElement = this.children.get(optionSelected) as SelectOptionView;
        optionSelectedElement.isSelected = true;
    }

    private setTemplateChildrentFromModel(model: SelectMultipleViewModel): void {
        if (!!model?.options){
            model.options.forEach((option: string) => {
                const child = this.createChildOption(option);
                this.children.add(child);
            });
        }
    }

    private createChildOption(option: string): SelectOptionView {
        return new SelectOptionView(this.locale, option);
    }

    private isIndexInOptions(index: number): boolean {
        return index >= 0 && index < this.children.length;
    }

    private removeAllOptions(): void {
        while(this.children.length > 0) {
            this.removeOption(0);
        }
    }
}
