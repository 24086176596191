import { Injectable } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ClauseDTO } from 'src/app/api';

@Injectable({
  providedIn: 'root'
})

export abstract class IClauseCreationService {

    abstract saveOpenFolder(newClause: ClauseDTO): MatDialogRef<any>;
    abstract afterClosed(result: any, newClause: ClauseDTO): Promise<ClauseDTO>;
}
