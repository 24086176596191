export interface FilesExportDto {
    folderId: string;
    exportSettings: ExportSettingsDto;
    fileIds: Array<string>;
}

export interface ExportSettingsDto {
    exportFileExtension: ExportFileExtension;
    exportType: ExportType;
    exportResultFormat: ExportResultFormat;
    comments: string;
    selectedText: string;
}

export enum ExportFileExtension
{
    Docx = 1,
    Pdf = 2,
    Html = 3
}

export enum ExportType
{
    CompleteDocument = 1,
    SelectedText = 2,
    ConsumerDocument = 3
}

export enum ExportResultFormat
{
    SimpleDocument,
    HierarchyFolderZip
}
