import { Injectable } from '@angular/core';
import { DocumentDTO, TemplateDocumentDTO, TemplateDTO } from 'src/app/api';

@Injectable({
    providedIn: 'root'
})
export abstract class IWarningNotificacionService {

    abstract setPendingDocuments(Template: TemplateDTO): void;
    abstract showClausesPendingDocument(document: DocumentDTO): Promise<boolean>;
    abstract showClausesPendingTemplate(template: TemplateDTO): Promise<boolean>;
    abstract showTemplatesPendingPreview(documentId: string): Promise<boolean>;
    abstract showTemplatesPendingPreviewTemplate(templateDocument: TemplateDocumentDTO, fromTemplateDocument?: boolean): Promise<boolean>;
    abstract openTemplateLldRevisionPreviewTemplate(Template: TemplateDTO): void;
    abstract openTemplateLldRevisionPreviewDocument(document: DocumentDTO): void;
}
