import { HttpInterceptor, HttpEvent, HttpResponse, HttpErrorResponse, HttpHandler, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { AuthorizeService } from '../services/authorize/authorize.service';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize, tap } from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  private countRequest = 0;
  timer: number;

  constructor(
    private loginService: AuthorizeService,
    private spinnerService: NgxSpinnerService) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let finished = false;
    this.countRequest++;
    setTimeout(() => {

      if (this.countRequest > 0) {
        this.spinnerService.show();
      }
    }, 1000);

    return next.handle(req).pipe(finalize(() => {
      this.countRequest--;
      if (this.countRequest < 0) {
        this.countRequest = 0;
      }
      finished = this.countRequest < 1;

      if (finished) {
        this.spinnerService.hide();
      }

    })).pipe(tap((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        // do stuff with response if you want
      }
    }, (err: any) => {
      if (err instanceof HttpErrorResponse) {
        if (err.status === 401) {
            this.loginService.clearUserInfo();
        } else if (err.status === 403) {
          this.loginService.forceRefreshUserInfo();
          return throwError(err);
        }
      }
    }));
  }
}
