/**
 * API ContractBox
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: v1
 *
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 *//* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent }                           from '@angular/common/http';
import { CustomHttpUrlEncodingCodec }                        from '../encoder';

import { Observable }                                        from 'rxjs';

import { ErrorTemplateOperationDTO } from '../model/errorTemplateOperationDTO';
import { TemplateDTO } from '../model/templateDTO';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';
import { ExportResultFormat, FilesExportDto } from '../model/filesExportDto';
import { ApiOperation, ApiProcessorService, ApiRequest } from './api-processor.service';


@Injectable()
export class TemplateInquireService {

    protected basePath = '';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();

    constructor(
        private readonly apiProcessorService: ApiProcessorService,
        protected httpClient: HttpClient,
        @Optional()@Inject(BASE_PATH) basePath: string,
        @Optional() configuration: Configuration) {
        if (basePath) {
            this.basePath = basePath;
        }
        if (configuration) {
            this.configuration = configuration;
            this.basePath = basePath || configuration.basePath || this.basePath;
        }
    }

    /**
     *
     *
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesErrorDescriptionGet(observe?: 'body', reportProgress?: boolean): Observable<ErrorTemplateOperationDTO>;
    public templatesErrorDescriptionGet(observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<ErrorTemplateOperationDTO>>;
    public templatesErrorDescriptionGet(observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<ErrorTemplateOperationDTO>>;
    public templatesErrorDescriptionGet(observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<ErrorTemplateOperationDTO>('get', `${this.basePath}/api/inquire-management/error-description`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     *
     *
     * @param folderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesGet(folderId?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TemplateDTO>>;
    public templatesGet(folderId?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TemplateDTO>>>;
    public templatesGet(folderId?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TemplateDTO>>>;
    public templatesGet(folderId?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (folderId !== undefined && folderId !== null) {
            queryParameters = queryParameters.set('folderId',  folderId as any);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TemplateDTO>>('get', `${this.basePath}/api/inquire-management`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     *
     *
     * @param id
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesIdGet(id: string, observe?: 'body', reportProgress?: boolean): Observable<TemplateDTO>;
    public templatesIdGet(id: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateDTO>>;
    public templatesIdGet(id: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateDTO>>;
    public templatesIdGet(id: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (id === null || id === undefined) {
            throw new Error('Required parameter id was null or undefined when calling templatesIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TemplateDTO>('get', `${this.basePath}/api/inquire-management/templates/${encodeURIComponent(String(id))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     *
     *
     * @param searchTerm
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesSearchGet(searchTerm?: string, observe?: 'body', reportProgress?: boolean): Observable<Array<TemplateDTO>>;
    public templatesSearchGet(searchTerm?: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<Array<TemplateDTO>>>;
    public templatesSearchGet(searchTerm?: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<Array<TemplateDTO>>>;
    public templatesSearchGet(searchTerm?: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {


        let queryParameters = new HttpParams({encoder: new CustomHttpUrlEncodingCodec()});
        if (searchTerm !== undefined && searchTerm !== null) {
            queryParameters = queryParameters.set('searchTerm',  searchTerm as any);
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<Array<TemplateDTO>>('get', `${this.basePath}/api/inquire-management/search`,
            {
                params: queryParameters,
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    /**
     *
     *
     * @param name
     * @param folderId
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public templatesSearchNameNameFolderIdFolderIdGet(name: string, folderId: string, observe?: 'body', reportProgress?: boolean): Observable<TemplateDTO>;
    public templatesSearchNameNameFolderIdFolderIdGet(name: string, folderId: string, observe?: 'response', reportProgress?: boolean): Observable<HttpResponse<TemplateDTO>>;
    public templatesSearchNameNameFolderIdFolderIdGet(name: string, folderId: string, observe?: 'events', reportProgress?: boolean): Observable<HttpEvent<TemplateDTO>>;
    public templatesSearchNameNameFolderIdFolderIdGet(name: string, folderId: string, observe: any = 'body', reportProgress: boolean = false ): Observable<any> {

        if (name === null || name === undefined) {
            throw new Error('Required parameter name was null or undefined when calling templatesSearchNameNameFolderIdFolderIdGet.');
        }

        if (folderId === null || folderId === undefined) {
            throw new Error('Required parameter folderId was null or undefined when calling templatesSearchNameNameFolderIdFolderIdGet.');
        }

        let headers = this.defaultHeaders;

        // to determine the Accept header
        const httpHeaderAccepts: string[] = [
            'text/plain',
            'application/json',
            'text/json'
        ];
        const httpHeaderAcceptSelected: string | undefined = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        if (httpHeaderAcceptSelected != undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }

        // to determine the Content-Type header
        const consumes: string[] = [
        ];

        return this.httpClient.request<TemplateDTO>('get', `${this.basePath}/api/inquire-management/search/name/${encodeURIComponent(String(name))}/folderId/${encodeURIComponent(String(folderId))}`,
            {
                withCredentials: this.configuration.withCredentials,
                headers,
                observe,
                reportProgress
            }
        );
    }

    public generateExportFile(fileExport: FilesExportDto): Observable<any> {
        const apiRequest = new ApiRequest();
        apiRequest.operation = ApiOperation.POST;
        apiRequest.endpointUrl = `/api/inquire-management/templates/export`;
        apiRequest.body = fileExport;

        if (fileExport.exportSettings.exportResultFormat === ExportResultFormat.HierarchyFolderZip) {
            apiRequest.responseType = 'blob';
        }

        return this.apiProcessorService.proccessRequest(apiRequest);
    }
}
